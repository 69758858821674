import { Document } from 'eversign-enhance';

import { jsPDF as JsPDF } from 'jspdf';
import { snackbar } from '../../shared/components/Snackbar';
import { Driver, DriversByCustomerIdQuery } from '../../shared/types/generated';
import { READABLE_DOC_STATUS, SignerDataShape } from './document-types';

/**
 * Converts a HTML element to pdf.
 *
 * @param {HTMLElement} HTMLElement - HTML element to convert.
 * @returns {Blob} The pdf blob.
 */
export const HTMLToPdf = async (HTMLElement: HTMLElement): Promise<Blob> =>
  new Promise((resolve) => {
    const doc = new JsPDF();
    doc.html(HTMLElement, {
      /**
       * On load callback.
       *
       * @param {JsPDF} pdfData - JsPDF instance.
       */
      callback: (pdfData) => {
        resolve(pdfData.output('blob'));
      },
      x: 15,
      y: 15,
      width: 170,
      windowWidth: 650,
      html2canvas: {
        letterRendering: true,
      },
    });
  });

/**
 * Converts a HTML element to pdf.
 *
 * @param {HTMLElement} HTMLElement - HTML element to convert.
 */
export const previewPdf = (HTMLElement: HTMLElement): void => {
  const doc = new JsPDF();

  doc.html(HTMLElement, {
    /**
     * On load callback.
     *
     * @param {JsPDF} pdfData - JsPDF instance.
     */
    callback: (pdfData) => {
      const string = pdfData.output('datauristring');
      // eslint-disable-next-line @typescript-eslint/quotes
      const embed = `
      <embed width='100%' height='100%' src=
        ${string} />
      `;

      const x = window.open();
      if (x) {
        x.document.open();
        x.document.write(embed);
        x.document.close();
      }
    },
    x: 15,
    y: 15,
    width: 170,
    windowWidth: 650,
    html2canvas: {
      letterRendering: true,
    },
  });
};

/**
 * @param doc - Document.
 * @returns {boolean} - To know if everybody has signed.
 */
export const allTheSignerHaveSigned = (doc: Document): boolean =>
  Boolean(doc.getSigners().every((signer) => signer.getSigned()));

/**
 * @param doc - Document.
 * @returns {string} - Status readable.
 */
export const printTheDocumentStatus = (
  doc: Document | null | undefined,
): string => {
  if (!doc) {
    return '-';
  }

  if (doc.getIsDraft()) {
    return READABLE_DOC_STATUS.DRAFT;
  }

  if (doc.getIsCompleted()) {
    return READABLE_DOC_STATUS.COMPLETED;
  }

  if (doc.getIsCancelled()) {
    return READABLE_DOC_STATUS.CANCELLED;
  }

  // We infer that some signers left.
  if (!allTheSignerHaveSigned(doc)) {
    return READABLE_DOC_STATUS.IN_PROGRESS;
  }

  // Last status.
  return READABLE_DOC_STATUS.I_NEED_TO_SIGN;
};

interface ShouldAddSignerParams {
  previousSigners: SignerDataShape[];
  signerEmail: string;
  roleSelected?: string;
}

/**
 * @param {ShouldAddSignerParams} data - Signer data.
 * @returns {boolean} Should Add.
 */
export const shouldAddSigner = ({
  signerEmail,
  previousSigners,
  roleSelected,
}: ShouldAddSignerParams): boolean => {
  const sameRole =
    roleSelected && previousSigners.some((e) => e.role === roleSelected);
  const sameEmail = previousSigners.some((e) => e.email === signerEmail);

  const shouldAdd = !sameEmail && !sameRole;

  if (sameEmail) {
    snackbar.error('Cannot add two signers with the same email');
  }

  if (sameRole) {
    snackbar.error('Cannot add two signers with the same role.');
  }

  return shouldAdd;
};

interface HandleDriverSelectionParams {
  driverInput: Driver | null;
  previousSigners: SignerDataShape[];
  driversData?: DriversByCustomerIdQuery;
  selectedRole?: string;
}

interface HandleDriverSelectionReturns {
  selectedDriver?: Driver | null;
  signer?: SignerDataShape | null;
}

/**
 * Driver selection handler.
 *
 * @param {HandleDriverSelectionParams} params - Params.
 * @returns {HandleDriverSelectionReturns} Data.
 */
export const handleDriverSelection = ({
  driverInput,
  driversData,
  previousSigners,
  selectedRole,
}: HandleDriverSelectionParams): HandleDriverSelectionReturns => {
  const driverName = driverInput?.name || '';
  let signer;
  const selectedDriver = driversData?.driversList.items.find(
    (dr) => dr.name === driverName,
  );

  const driverEmail = driverInput?.email;

  const emailExists = previousSigners.some((e) => e.email === driverEmail);

  const sameRole = previousSigners.some((e) => e.role === selectedRole);

  if (emailExists) {
    snackbar.error('Cannot add two signers with the same email');
    return {
      selectedDriver: null,
      signer: null,
    };
  }

  if (sameRole && selectedRole) {
    snackbar.error('Cannot add two signers with the same role.');
    return {
      selectedDriver: null,
      signer: null,
    };
  }

  if (driverEmail && selectedDriver)
    signer = {
      name: selectedDriver.name.trim(),
      lastName: selectedDriver.lastName,
      email: selectedDriver.email.trim(),
      driverId: selectedDriver.id,
      role: undefined,
    };

  return {
    selectedDriver,
    signer,
  };
};
