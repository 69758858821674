import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { CustomerDriverFormProvider } from './CustomerDriverFormProvider';
import { CustomersBackBtn } from './components/CustomersBackBtn';
import { CardBox } from '../../shared/components/card/CardBox';
import { CustomerDriverFormType } from './customer-driver-types';
import {
  useCustomerDetailQuery,
  useUpdateCustomerMutation,
} from '../../shared/types/generated';
import { FormImageContainer } from '../../shared/components/Containers';
import orangeTruckImg from '../../shared/assets/orange-truck.svg';
import { CustomerDriverDetailsForm } from './components/CustomerDriverDetailsForm';
import { CustomerDriverAddressesForm } from './components/CustomerDriverAddressesForm';
import { CDSubmitBtns } from './components/CDSubmitBtns';
import { prepareCustomerDriverDataToUpdate } from './customer-driver-utils';
import { CustomerDriverSubsForm } from './components/CustomerDriverSubsForm';
import { snackbar } from '../../shared/components/Snackbar';
import { clearQueriesFromCache } from '../../shared/utils';
import { CustomerDriverLicenseForm } from './components/CustomerDriverLicenseForm';

/**
 * @returns {JSX.Element} - Update customer driver form.
 */
export const UpdateCustomerDriverForm: React.FC = (): JSX.Element => {
  // Customer id.
  const { id } = useParams<{ id: string }>();

  // React router dom.
  const history = useHistory();

  // Forms.
  const methods = useFormContext<CustomerDriverFormType>();
  const { handleSubmit, setValue } = methods;

  // Retrieve details.
  const { data, loading } = useCustomerDetailQuery({
    variables: { id },
  });

  const [updateCustomer, { loading: loadingUpdate }] =
    useUpdateCustomerMutation({
      /**
       *
       * @param {object} err  - Error.
       */
      onError: (err) => {
        snackbar.error(err.message);
      },
      /**
       * @returns {void} - Nothing.
       */
      onCompleted: () => {
        snackbar.success('Customer updated successfully');
        history.push('/customers');
      },
      /**
       * @param cache - Apollo cache.
       */
      update: clearQueriesFromCache(['customer', 'customersList']),
    });

  // Flag.
  const [once, setOnce] = useState(false);

  // Syncs form with details.
  useEffect(() => {
    if (loading && !data?.customer) {
      return;
    }

    setOnce(true);

    const driver = data?.customer.driverCustomerRelation.items[0];

    setValue('name', driver?.name || '');
    setValue('lastName', driver?.lastName || '');
    setValue('dateOfBirth', driver?.dateOfBirth || '');
    setValue('language', driver?.preferredLanguage.id || '');
    setValue('email', driver?.email || '');
    setValue('phoneNumber', driver?.phoneNumber.number || '');
    setValue('license.number', driver?.licenseNumber || '');
    setValue('license.state', driver?.licenseState || '');

    // Customer data, which are not belong to the driver itself.
    setValue('subscription', data?.customer?.subscription || '');
    setValue(
      'customerAddressRelation',
      data?.customer.address.items.map((address) => ({
        id: address.id,
        state: address.state.id,
        city: address.city,
        apartment: address.apartment,
        street: address.street,
        zipCode: address.zipCode,
      })),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, setValue, once]);

  /**
   * @param {CustomerDriverFormType} inputData - Input data.
   * @returns {void} - Nothing.
   */
  const onSubmit = (inputData: CustomerDriverFormType): void => {
    updateCustomer({
      variables: {
        filter: { id: data?.customer.id },
        data: prepareCustomerDriverDataToUpdate(inputData, data),
      },
    });
  };

  return (
    <>
      <CustomersBackBtn />
      <CardBox>
        <FormImageContainer
          imagePath={orangeTruckImg}
          alt="Orange truck"
          onSubmit={handleSubmit(onSubmit)}
        >
          <CustomerDriverDetailsForm />
          <CustomerDriverLicenseForm />
          <CustomerDriverAddressesForm />
          <CustomerDriverSubsForm />
          <CDSubmitBtns
            submitBtn={{
              text: 'UPDATE',
              loading: loadingUpdate,
            }}
          />
        </FormImageContainer>
      </CardBox>
    </>
  );
};

/**
 * @returns {JSX.Element} - Update customer driver view.
 */
export const UpdateCustomerDriverView: React.FC = (): JSX.Element => (
  <CustomerDriverFormProvider>
    <UpdateCustomerDriverForm />
  </CustomerDriverFormProvider>
);
