import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
import { PaperStyled } from '../../../shared/components/card/Card';
import { StyledTableCell } from '../../../shared/components/table/TableRowStyled';
import { useDriversListQuery } from '../../../shared/types/generated';
import { TableRowLoading } from '../../../shared/components/table/TableRowLoading';
import { usePagination } from '../../../shared/hooks/hooks';
import { Pagination } from '../../../shared/components/table/Pagination';
import { ROUTES } from '../../../routes/routes-model';

/**
 * @returns Component.
 */
export function DriversList(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const defaultPageSize = 10;
  const [{ page, pageSize }, setPage] = usePagination({
    defaultPage: 1,
    defaultPageSize,
  });

  const { data, loading, refetch } = useDriversListQuery({
    variables: {
      skip: (page - 1) * pageSize,
      first: pageSize,
      filter: {
        customer: {
          id: {
            equals: id,
          },
        },
      },
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [page]);

  return (
    <Box paddingTop="30px" paddingBottom="30px">
      <PaperStyled>
        <TableContainer>
          <Box width="100%" padding="30px 30px 30px 30px">
            <Typography variant="h5" component="h2" color="white">
              Drivers list
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                data?.driversList.items?.map((driver, index) => (
                  <TableRow key={driver?.id}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      <Link
                        href={ROUTES.DRIVERS.internalPaths.DRIVER_DETAIL.path.replace(
                          ':id',
                          String(driver?.id),
                        )}
                      >
                        {driver?.name} {driver?.lastName}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      {`+${driver?.phoneNumber?.code} ${driver?.phoneNumber?.number}`}
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRowLoading loading colSpan={3} />
              )}
            </TableBody>
          </Table>
          <Box sx={{ padding: '30px' }}>
            <Pagination
              page={page}
              pageSize={pageSize}
              totalCount={data?.driversList.count || 0}
              onChange={(value: number) => setPage({ page: value })}
            />
          </Box>
        </TableContainer>
      </PaperStyled>
    </Box>
  );
}
