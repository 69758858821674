/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents whole numeric values. */
  BigInt: string;
  /** A date string, such as `2007-12-25`, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string | null | undefined;
  /** A date-time string at UTC, such as `2007-12-25T16:15:30Z`, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string | null | undefined;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: {};
  /** A time string at UTC, such as `16:15:30Z`, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: string;
};

export type Address = {
  __typename?: 'Address';
  _description?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<UsState>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Address create input */
export type AddressCreateInput = {
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  customer: AddressCustomerRelationInput;
  state: AddressStateRelationInput;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Address create many input */
export type AddressCreateManyInput = {
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  customer: AddressCustomerManyRelationInput;
  state: AddressStateManyRelationInput;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Address relation input */
export type AddressCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** Address relation input */
export type AddressCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<Address_CustomerCreateInput>;
};

/** Address relation input */
export type AddressCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<Address_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<Address_CustomerUpdateInput>;
};

/** Address delete input */
export type AddressDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** AddressFieldsPermissions create input */
export type AddressFieldsPermissions = {
  apartment?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['Boolean']>;
};

export type AddressFilter = {
  AND?: Maybe<Array<AddressFilter>>;
  OR?: Maybe<Array<AddressFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  apartment?: Maybe<StringPredicate>;
  archived?: Maybe<DateTimePredicate>;
  city?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  state?: Maybe<UsStateFilter>;
  street?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  zipCode?: Maybe<StringPredicate>;
};

export type AddressGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: AddressGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type AddressGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  apartment?: Maybe<Array<GroupByField>>;
  archived?: Maybe<Array<GroupByField>>;
  city?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  state?: Maybe<UsStateGroupByQuery>;
  street?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  zipCode?: Maybe<Array<GroupByField>>;
};

export type AddressKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** AddressListResponse output */
export type AddressListResponse = {
  __typename?: 'AddressListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Address>;
};

/** AddressManyResponse output */
export type AddressManyResponse = {
  __typename?: 'AddressManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Address>;
};

/** No longer supported. Use `sort` instead. */
export enum AddressOrderBy {
  ApartmentAsc = 'apartment_ASC',
  ApartmentDesc = 'apartment_DESC',
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

/** Address subscription payload */
export type AddressPayload = {
  __typename?: 'AddressPayload';
  mutation: MutationType;
  node?: Maybe<Address>;
  previousValues?: Maybe<Address>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressRelationFilter = {
  every?: Maybe<AddressFilter>;
  none?: Maybe<AddressFilter>;
  some?: Maybe<AddressFilter>;
};

/** UsState create input from addressRelation */
export type AddressRelation_UsStateCreateInput = {
  addressRelation?: Maybe<UsStateAddressRelationRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationRelationInput>;
};

/** UsState update input from addressRelation */
export type AddressRelation_UsStateUpdateInput = {
  addressRelation?: Maybe<UsStateAddressRelationUpdateRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationUpdateRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationUpdateRelationInput>;
};

export type AddressSort = {
  apartment?: Maybe<SortOrder>;
  archived?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  state?: Maybe<UsStateSort>;
  street?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zipCode?: Maybe<SortOrder>;
};

/** Address relation input */
export type AddressStateManyRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
};

/** Address relation input */
export type AddressStateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<AddressRelation_UsStateCreateInput>;
};

/** Address relation input */
export type AddressStateUpdateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<AddressRelation_UsStateCreateInput>;
  disconnect?: Maybe<UsStateKeyFilter>;
  reconnect?: Maybe<UsStateKeyFilter>;
  update?: Maybe<AddressRelation_UsStateUpdateInput>;
};

/** Address subscription filter */
export type AddressSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AddressFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Address update input */
export type AddressUpdateByFilterInput = {
  apartment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  archived?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  city?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  street?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  zipCode?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Address update input */
export type AddressUpdateInput = {
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<AddressCustomerUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<AddressStateUpdateRelationInput>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Customer create input from address */
export type Address_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from address */
export type Address_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

export type Address_PermissionFilter = {
  AND?: Maybe<Array<Address_PermissionFilter>>;
  OR?: Maybe<Array<Address_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  apartment?: Maybe<StringPredicate>;
  archived?: Maybe<DateTimePredicate>;
  city?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  state?: Maybe<UsState_PermissionFilter>;
  street?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  zipCode?: Maybe<StringPredicate>;
};

export type Address_PermissionRelationFilter = {
  every?: Maybe<Address_PermissionFilter>;
  none?: Maybe<Address_PermissionFilter>;
  some?: Maybe<Address_PermissionFilter>;
};

export enum AggregationFunctionType {
  AnyValue = 'ANY_VALUE',
  Avg = 'AVG',
  Count = 'COUNT',
  GroupConcat = 'GROUP_CONCAT',
  Max = 'MAX',
  Min = 'MIN',
  StddevPop = 'STDDEV_POP',
  StddevSamp = 'STDDEV_SAMP',
  Sum = 'SUM',
  VarPop = 'VAR_POP',
  VarSamp = 'VAR_SAMP',
}

export type Alert = {
  __typename?: 'Alert';
  _description?: Maybe<Scalars['String']>;
  alertCustomerAlertRelation?: Maybe<CustomerAlertListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlertAlertCustomerAlertRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerAlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerAlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerAlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerAlertSort>>;
};

/** Alert relation input */
export type AlertAlertCustomerAlertRelationManyRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
};

/** Alert relation input */
export type AlertAlertCustomerAlertRelationRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
  create?: Maybe<Array<Maybe<Alert_CustomerAlertCreateInput>>>;
};

/** Alert relation input */
export type AlertAlertCustomerAlertRelationUpdateRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
  create?: Maybe<Array<Maybe<Alert_CustomerAlertCreateInput>>>;
  disconnect?: Maybe<Array<CustomerAlertKeyFilter>>;
  reconnect?: Maybe<Array<CustomerAlertKeyFilter>>;
  update?: Maybe<Array<Maybe<Alert_CustomerAlertUpdateInput>>>;
};

/** Alert create input */
export type AlertCreateInput = {
  alertCustomerAlertRelation?: Maybe<AlertAlertCustomerAlertRelationRelationInput>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
};

/** Alert create many input */
export type AlertCreateManyInput = {
  alertCustomerAlertRelation?: Maybe<AlertAlertCustomerAlertRelationManyRelationInput>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
};

/** Alert create input from alertCustomerAlertRelation */
export type AlertCustomerAlertRelation_AlertCreateInput = {
  alertCustomerAlertRelation?: Maybe<AlertAlertCustomerAlertRelationRelationInput>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
};

/** Alert update input from alertCustomerAlertRelation */
export type AlertCustomerAlertRelation_AlertUpdateInput = {
  alertCustomerAlertRelation?: Maybe<AlertAlertCustomerAlertRelationUpdateRelationInput>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
};

/** Alert delete input */
export type AlertDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** AlertFieldsPermissions create input */
export type AlertFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  dismissedAt?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type AlertFilter = {
  AND?: Maybe<Array<AlertFilter>>;
  OR?: Maybe<Array<AlertFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  alertCustomerAlertRelation?: Maybe<CustomerAlertRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  dismissedAt?: Maybe<DateTimePredicate>;
  id?: Maybe<IdPredicate>;
  message?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type AlertGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: AlertGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type AlertGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  alertCustomerAlertRelation?: Maybe<CustomerAlertGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  dismissedAt?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  message?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type AlertKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** AlertListResponse output */
export type AlertListResponse = {
  __typename?: 'AlertListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Alert>;
};

/** AlertManyResponse output */
export type AlertManyResponse = {
  __typename?: 'AlertManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Alert>;
};

/** No longer supported. Use `sort` instead. */
export enum AlertOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DismissedAtAsc = 'dismissedAt_ASC',
  DismissedAtDesc = 'dismissedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Alert subscription payload */
export type AlertPayload = {
  __typename?: 'AlertPayload';
  mutation: MutationType;
  node?: Maybe<Alert>;
  previousValues?: Maybe<Alert>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AlertSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  dismissedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Alert subscription filter */
export type AlertSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AlertFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Alert update input */
export type AlertUpdateByFilterInput = {
  dismissedAt?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  message?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** Alert update input */
export type AlertUpdateInput = {
  alertCustomerAlertRelation?: Maybe<AlertAlertCustomerAlertRelationUpdateRelationInput>;
  dismissedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  /** CRITICAL Alert status means that will be automatically dismiss, NON-CRITICAL Alert status can be manually dismiss. */
  status?: Maybe<Scalars['String']>;
  /** For easy classification or alert filter. */
  type?: Maybe<Scalars['String']>;
};

/** CustomerAlert create input from alert */
export type Alert_CustomerAlertCreateInput = {
  alert?: Maybe<CustomerAlertAlertRelationInput>;
  customer?: Maybe<CustomerAlertCustomerRelationInput>;
};

/** CustomerAlert update input from alert */
export type Alert_CustomerAlertUpdateInput = {
  data: CustomerAlertUpdateInput;
  filter?: Maybe<CustomerAlertKeyFilter>;
};

export type Alert_PermissionFilter = {
  AND?: Maybe<Array<Alert_PermissionFilter>>;
  OR?: Maybe<Array<Alert_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  alertCustomerAlertRelation?: Maybe<CustomerAlert_PermissionRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  dismissedAt?: Maybe<DateTimePredicate>;
  id?: Maybe<IdPredicate>;
  message?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApiTokenRolesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
};

/** ApiTokens create input */
export type ApiTokenCreateInput = {
  name: Scalars['String'];
  roles?: Maybe<ApiTokensRolesRelationInput>;
};

/** ApiTokens delete input */
export type ApiTokenDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type ApiTokenFilter = {
  AND?: Maybe<Array<ApiTokenFilter>>;
  OR?: Maybe<Array<ApiTokenFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  roles?: Maybe<RoleRelationFilter>;
  token?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type ApiTokenGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: ApiTokenGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type ApiTokenGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  roles?: Maybe<RoleGroupByQuery>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type ApiTokenKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** ApiTokenListResponse output */
export type ApiTokenListResponse = {
  __typename?: 'ApiTokenListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<ApiToken>;
};

/** ApiTokenManyResponse output */
export type ApiTokenManyResponse = {
  __typename?: 'ApiTokenManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<ApiToken>;
};

/** No longer supported. Use `sort` instead. */
export enum ApiTokenOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TokenAsc = 'token_ASC',
  TokenDesc = 'token_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** ApiTokens subscription payload */
export type ApiTokenPayload = {
  __typename?: 'ApiTokenPayload';
  mutation: MutationType;
  node?: Maybe<ApiToken>;
  previousValues?: Maybe<ApiToken>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiTokenRelationFilter = {
  every?: Maybe<ApiTokenFilter>;
  none?: Maybe<ApiTokenFilter>;
  some?: Maybe<ApiTokenFilter>;
};

/** API Token Response */
export type ApiTokenResponse = {
  __typename?: 'ApiTokenResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** API Token Response */
export type ApiTokenResponseRolesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
};

export type ApiTokenSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** ApiTokens subscription filter */
export type ApiTokenSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ApiTokenFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ApiTokens update input */
export type ApiTokenUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  token?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** ApiTokens update input */
export type ApiTokenUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<ApiTokensRolesUpdateRelationInput>;
};

export type ApiToken_PermissionFilter = {
  AND?: Maybe<Array<ApiToken_PermissionFilter>>;
  OR?: Maybe<Array<ApiToken_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  token?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type ApiToken_PermissionRelationFilter = {
  every?: Maybe<ApiToken_PermissionFilter>;
  none?: Maybe<ApiToken_PermissionFilter>;
  some?: Maybe<ApiToken_PermissionFilter>;
};

/** ApiTokens relation input */
export type ApiTokensRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<ApiTokens_RoleCreateInput>>>;
};

/** ApiTokens relation input */
export type ApiTokensRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<ApiTokens_RoleCreateInput>>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  update?: Maybe<Array<Maybe<ApiTokens_RoleUpdateInput>>>;
};

/** Roles create input from apiTokens */
export type ApiTokens_RoleCreateInput = {
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<PermissionsInput>;
  users?: Maybe<RolesUsersRelationInput>;
};

/** Roles update input from apiTokens */
export type ApiTokens_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: Maybe<RoleKeyFilter>;
};

/** Application */
export type Application = {
  __typename?: 'Application';
  appType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: ApplicationStatusEnum;
};

/** ApplicationDeleteMutationInput */
export type ApplicationDeleteMutationInput = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

/** Application install input */
export type ApplicationInstallInput = {
  appType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<ApplicationStatusEnum>;
};

/** ApplicationListResponse output */
export type ApplicationListResponse = {
  __typename?: 'ApplicationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Application>;
};

/** Application Status Enum */
export enum ApplicationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** Application update input */
export type ApplicationUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ApplicationStatusEnum>;
};

/** Async Session */
export type AsyncSession = {
  __typename?: 'AsyncSession';
  sessionId: Scalars['String'];
};

export type AsyncSessionStatusResponse = {
  __typename?: 'AsyncSessionStatusResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Attorney = {
  __typename?: 'Attorney';
  _description?: Maybe<Scalars['String']>;
  case?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Attorney relation input */
export type AttorneyCaseManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** Attorney relation input */
export type AttorneyCaseRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<AttorneyRelation_CaseCreateInput>;
};

/** Attorney relation input */
export type AttorneyCaseUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<AttorneyRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<AttorneyRelation_CaseUpdateInput>;
};

/** Attorney create input */
export type AttorneyCreateInput = {
  case?: Maybe<AttorneyCaseRelationInput>;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Attorney create many input */
export type AttorneyCreateManyInput = {
  case: AttorneyCaseManyRelationInput;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Attorney delete input */
export type AttorneyDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** AttorneyFieldsPermissions create input */
export type AttorneyFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  fee?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type AttorneyFilter = {
  AND?: Maybe<Array<AttorneyFilter>>;
  OR?: Maybe<Array<AttorneyFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  fee?: Maybe<FloatPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  phone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type AttorneyGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: AttorneyGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type AttorneyGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  case?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  email?: Maybe<Array<GroupByField>>;
  fee?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  phone?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type AttorneyKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** AttorneyListResponse output */
export type AttorneyListResponse = {
  __typename?: 'AttorneyListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Attorney>;
};

/** AttorneyManyResponse output */
export type AttorneyManyResponse = {
  __typename?: 'AttorneyManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Attorney>;
};

/** No longer supported. Use `sort` instead. */
export enum AttorneyOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Attorney subscription payload */
export type AttorneyPayload = {
  __typename?: 'AttorneyPayload';
  mutation: MutationType;
  node?: Maybe<Attorney>;
  previousValues?: Maybe<Attorney>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Case create input from attorneyRelation */
export type AttorneyRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from attorneyRelation */
export type AttorneyRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type AttorneySort = {
  case?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Attorney subscription filter */
export type AttorneySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AttorneyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Attorney update input */
export type AttorneyUpdateByFilterInput = {
  email?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  fee?: Maybe<Array<Maybe<UpdateByFilterFloatInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  phone?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Attorney update input */
export type AttorneyUpdateInput = {
  case?: Maybe<AttorneyCaseUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Attorney_PermissionFilter = {
  AND?: Maybe<Array<Attorney_PermissionFilter>>;
  OR?: Maybe<Array<Attorney_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  fee?: Maybe<FloatPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  phone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** Auth response */
export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpiresAt?: Maybe<Scalars['Int']>;
  idToken?: Maybe<Scalars['String']>;
  idTokenExpiresAt?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type AuthenticationProfile = {
  __typename?: 'AuthenticationProfile';
  _description?: Maybe<Scalars['String']>;
  attributes?: Maybe<AuthenticationProfileAttributes>;
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  databaseName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  managementDomain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticationProfileRolesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
};

/** Authentication Profile Attributes */
export type AuthenticationProfileAttributes = CognitoAuthProfileAttributes;

/** Authentication profile connection options */
export type AuthenticationProfileConnectionOptions = {
  __typename?: 'AuthenticationProfileConnectionOptions';
  facebook?: Maybe<FacebookOptions>;
  github?: Maybe<GithubOptions>;
  google?: Maybe<GoogleOptions>;
};

/** Authentication profile connection options input */
export type AuthenticationProfileConnectionsOptionsInput = {
  facebook?: Maybe<FacebookOptionsInput>;
  github?: Maybe<GithubOptionsInput>;
  google?: Maybe<GoogleOptionsInput>;
};

/** AuthenticationProfiles create input */
export type AuthenticationProfileCreateInput = {
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: Maybe<AuthenticationProfilesRolesRelationInput>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** AuthenticationProfiles create many input */
export type AuthenticationProfileCreateManyInput = {
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: Maybe<AuthenticationProfilesRolesManyRelationInput>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** AuthenticationProfiles delete input */
export type AuthenticationProfileDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type AuthenticationProfileFilter = {
  AND?: Maybe<Array<AuthenticationProfileFilter>>;
  OR?: Maybe<Array<AuthenticationProfileFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  clientId?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  databaseName?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  domain?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  managementDomain?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  roles?: Maybe<RoleRelationFilter>;
  secret?: Maybe<StringPredicate>;
  selfSignUpEnabled?: Maybe<BoolPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type AuthenticationProfileGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: AuthenticationProfileGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type AuthenticationProfileGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  audiences?: Maybe<Array<GroupByField>>;
  clientId?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  databaseName?: Maybe<Array<GroupByField>>;
  domain?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  managementDomain?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  roles?: Maybe<RoleGroupByQuery>;
  secret?: Maybe<Array<GroupByField>>;
  selfSignUpEmailDomains?: Maybe<Array<GroupByField>>;
  selfSignUpEnabled?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type AuthenticationProfileKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** AuthenticationProfileListResponse output */
export type AuthenticationProfileListResponse = {
  __typename?: 'AuthenticationProfileListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<AuthenticationProfile>;
};

/** AuthenticationProfileManyResponse output */
export type AuthenticationProfileManyResponse = {
  __typename?: 'AuthenticationProfileManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<AuthenticationProfile>;
};

/** No longer supported. Use `sort` instead. */
export enum AuthenticationProfileOrderBy {
  ClientIdAsc = 'clientId_ASC',
  ClientIdDesc = 'clientId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DatabaseNameAsc = 'databaseName_ASC',
  DatabaseNameDesc = 'databaseName_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DomainAsc = 'domain_ASC',
  DomainDesc = 'domain_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ManagementDomainAsc = 'managementDomain_ASC',
  ManagementDomainDesc = 'managementDomain_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SecretAsc = 'secret_ASC',
  SecretDesc = 'secret_DESC',
  SelfSignUpEnabledAsc = 'selfSignUpEnabled_ASC',
  SelfSignUpEnabledDesc = 'selfSignUpEnabled_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** AuthenticationProfiles subscription payload */
export type AuthenticationProfilePayload = {
  __typename?: 'AuthenticationProfilePayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationProfile>;
  previousValues?: Maybe<AuthenticationProfile>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthenticationProfileRelationFilter = {
  every?: Maybe<AuthenticationProfileFilter>;
  none?: Maybe<AuthenticationProfileFilter>;
  some?: Maybe<AuthenticationProfileFilter>;
};

export type AuthenticationProfileSort = {
  clientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  databaseName?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  domain?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  managementDomain?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  secret?: Maybe<SortOrder>;
  selfSignUpEnabled?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** AuthenticationProfiles subscription filter */
export type AuthenticationProfileSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AuthenticationProfileFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateByFilterInput = {
  audiences?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  clientId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  databaseName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  domain?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  managementDomain?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  secret?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<UpdateByFilterListStringInput>>>;
  selfSignUpEnabled?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** AuthenticationProfiles update input */
export type AuthenticationProfileUpdateInput = {
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  managementDomain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<AuthenticationProfilesRolesUpdateRelationInput>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type AuthenticationProfile_PermissionFilter = {
  AND?: Maybe<Array<AuthenticationProfile_PermissionFilter>>;
  OR?: Maybe<Array<AuthenticationProfile_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  clientId?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  databaseName?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  domain?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  managementDomain?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  secret?: Maybe<StringPredicate>;
  selfSignUpEnabled?: Maybe<BoolPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type AuthenticationProfile_PermissionRelationFilter = {
  every?: Maybe<AuthenticationProfile_PermissionFilter>;
  none?: Maybe<AuthenticationProfile_PermissionFilter>;
  some?: Maybe<AuthenticationProfile_PermissionFilter>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesManyRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<AuthenticationProfiles_RoleCreateInput>>>;
};

/** AuthenticationProfiles relation input */
export type AuthenticationProfilesRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<AuthenticationProfiles_RoleCreateInput>>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  update?: Maybe<Array<Maybe<AuthenticationProfiles_RoleUpdateInput>>>;
};

/** Roles create input from authenticationProfiles */
export type AuthenticationProfiles_RoleCreateInput = {
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<PermissionsInput>;
  users?: Maybe<RolesUsersRelationInput>;
};

/** Roles update input from authenticationProfiles */
export type AuthenticationProfiles_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: Maybe<RoleKeyFilter>;
};

export type AuthenticationSetting = {
  __typename?: 'AuthenticationSetting';
  _description?: Maybe<Scalars['String']>;
  allowedCallbacks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedLogouts?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedWebOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<AuthenticationProfileConnectionOptions>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticationSettingFilter = {
  AND?: Maybe<Array<AuthenticationSettingFilter>>;
  OR?: Maybe<Array<AuthenticationSettingFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** AuthenticationSettings subscription payload */
export type AuthenticationSettingPayload = {
  __typename?: 'AuthenticationSettingPayload';
  mutation: MutationType;
  node?: Maybe<AuthenticationSetting>;
  previousValues?: Maybe<AuthenticationSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** AuthenticationSettings subscription filter */
export type AuthenticationSettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<AuthenticationSettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** AuthenticationSettings update input */
export type AuthenticationSettingUpdateInput = {
  allowedCallbacks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedLogouts?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedWebOrigins?: Maybe<Array<Maybe<Scalars['String']>>>;
  connections?: Maybe<AuthenticationProfileConnectionsOptionsInput>;
};

/** Users create input from avatar */
export type Avatar_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from avatar */
export type Avatar_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: Maybe<UserKeyFilter>;
};

export type AwsSignInfoResponse = {
  __typename?: 'AwsSignInfoResponse';
  fields: Scalars['JSON'];
  path: Scalars['String'];
  url: Scalars['String'];
};

export type BigIntPredicateHaving = {
  AND?: Maybe<Array<BigIntPredicateHaving>>;
  OR?: Maybe<Array<BigIntPredicateHaving>>;
  equals?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  not_equals?: Maybe<Scalars['BigInt']>;
  not_in?: Maybe<Array<Scalars['BigInt']>>;
};

export type BillingCurrentPlanResponse = {
  __typename?: 'BillingCurrentPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<BillingNextPlanResponse>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<WorkspaceStatus>;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export enum BillingDetailsOrigin {
  Member = 'member',
  Organization = 'organization',
  Workspace = 'workspace',
}

export type BillingDetailsResponse = {
  __typename?: 'BillingDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: BillingDetailsOrigin;
};

export type BillingInvoiceItem = {
  __typename?: 'BillingInvoiceItem';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  endingBalance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoicePdf?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization?: Maybe<BillingInvoiceItemOrganizationInfo>;
  paid?: Maybe<Scalars['Boolean']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<BillingInvoiceItemPlanInfo>;
  project?: Maybe<BillingInvoiceItemProjectInfo>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type BillingInvoiceItemOrganizationInfo = {
  __typename?: 'BillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemPlanInfo = {
  __typename?: 'BillingInvoiceItemPlanInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BillingInvoiceItemProjectInfo = {
  __typename?: 'BillingInvoiceItemProjectInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum BillingInvoicesListFilterType {
  Customer = 'CUSTOMER',
  Project = 'PROJECT',
}

/** BillingInvoicesListResponse output */
export type BillingInvoicesListResponse = {
  __typename?: 'BillingInvoicesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingInvoiceItem>;
};

export type BillingLimitMetricItem = {
  __typename?: 'BillingLimitMetricItem';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  tooltip?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type BillingMetricOverageItem = {
  __typename?: 'BillingMetricOverageItem';
  value?: Maybe<Scalars['Float']>;
  warning?: Maybe<Scalars['String']>;
};

export type BillingMetricUsageItem = {
  __typename?: 'BillingMetricUsageItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  overage?: Maybe<BillingMetricOverageItem>;
  value?: Maybe<Scalars['Float']>;
};

export type BillingMetricUsageQuotaItem = {
  __typename?: 'BillingMetricUsageQuotaItem';
  limitMetric?: Maybe<BillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

/** BillingMetricUsageQuotasListResponse output */
export type BillingMetricUsageQuotasListResponse = {
  __typename?: 'BillingMetricUsageQuotasListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingMetricUsageQuotaItem>;
};

export type BillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** BillingMetricUsagesListResponse output */
export type BillingMetricUsagesListResponse = {
  __typename?: 'BillingMetricUsagesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<BillingMetricUsageItem>;
};

export type BillingNextPlanResponse = {
  __typename?: 'BillingNextPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type BillingPlanBaseInfo = {
  __typename?: 'BillingPlanBaseInfo';
  capacity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  featuresTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<BillingPlanLimitMetricItem>>>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type BillingPlanLimitMetricItem = {
  __typename?: 'BillingPlanLimitMetricItem';
  displayName?: Maybe<Scalars['String']>;
  hardLimit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
};

/** BillingPlanUpdateMutationInput */
export type BillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
  projectID: Scalars['ID'];
};

export type BoolPredicate = {
  equals?: Maybe<Scalars['Boolean']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  not_equals?: Maybe<Scalars['Boolean']>;
};

export type BoolPredicateHaving = {
  AND?: Maybe<Array<BoolPredicateHaving>>;
  OR?: Maybe<Array<BoolPredicateHaving>>;
  equals?: Maybe<Scalars['Boolean']>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  not_equals?: Maybe<Scalars['Boolean']>;
};

export type Case = {
  __typename?: 'Case';
  _description?: Maybe<Scalars['String']>;
  attorneyRelation?: Maybe<Attorney>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseFollowerListResponse>;
  caseCaseViolationCodeRelation?: Maybe<CaseViolationCodeListResponse>;
  caseCommentRelation?: Maybe<CaseCommentListResponse>;
  caseDocumentRelation?: Maybe<CaseDocumentListResponse>;
  caseHistoryRelation?: Maybe<CaseHistoryListResponse>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseLogListResponse>;
  caseStatusLogRelation?: Maybe<CaseStatusLogListResponse>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  crash?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  dataQsStatus?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  driver?: Maybe<Driver>;
  id?: Maybe<Scalars['ID']>;
  inspectionCitationState?: Maybe<UsState>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<NotificationObjectListResponse>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseCaseCaseFollowerRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFollowerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseFollowerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseFollowerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseFollowerSort>>;
};

export type CaseCaseCaseViolationCodeRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseViolationCodeSort>>;
};

export type CaseCaseCommentRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentSort>>;
};

export type CaseCaseDocumentRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseDocumentSort>>;
};

export type CaseCaseHistoryRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseHistoryGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseHistoryOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseHistorySort>>;
};

export type CaseCaseLogRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseLogSort>>;
};

export type CaseCaseStatusLogRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseStatusLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseStatusLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseStatusLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseStatusLogSort>>;
};

export type CaseNotificationObjectRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationObjectFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationObjectGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationObjectOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationObjectSort>>;
};

/** Case relation input */
export type CaseAttorneyRelationManyRelationInput = {
  connect?: Maybe<AttorneyKeyFilter>;
};

/** Case relation input */
export type CaseAttorneyRelationRelationInput = {
  connect?: Maybe<AttorneyKeyFilter>;
  create?: Maybe<Case_AttorneyCreateInput>;
};

/** Case relation input */
export type CaseAttorneyRelationUpdateRelationInput = {
  connect?: Maybe<AttorneyKeyFilter>;
  create?: Maybe<Case_AttorneyCreateInput>;
  disconnect?: Maybe<AttorneyKeyFilter>;
  reconnect?: Maybe<AttorneyKeyFilter>;
  update?: Maybe<Case_AttorneyUpdateInput>;
};

/** Case relation input */
export type CaseCaseCaseFollowerRelationManyRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
};

/** Case relation input */
export type CaseCaseCaseFollowerRelationRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_CaseFollowerCreateInput>>>;
};

/** Case relation input */
export type CaseCaseCaseFollowerRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_CaseFollowerCreateInput>>>;
  disconnect?: Maybe<Array<CaseFollowerKeyFilter>>;
  reconnect?: Maybe<Array<CaseFollowerKeyFilter>>;
  update?: Maybe<Array<Maybe<Case_CaseFollowerUpdateInput>>>;
};

/** Case relation input */
export type CaseCaseCaseViolationCodeRelationManyRelationInput = {
  connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
};

/** Case relation input */
export type CaseCaseCaseViolationCodeRelationRelationInput = {
  connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_CaseViolationCodeCreateInput>>>;
};

/** Case relation input */
export type CaseCaseCaseViolationCodeRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_CaseViolationCodeCreateInput>>>;
  disconnect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  reconnect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  update?: Maybe<Array<Maybe<Case_CaseViolationCodeUpdateInput>>>;
};

/** Case relation input */
export type CaseCaseCommentRelationManyRelationInput = {
  connect?: Maybe<Array<CaseCommentKeyFilter>>;
};

/** Case relation input */
export type CaseCaseCommentRelationRelationInput = {
  connect?: Maybe<Array<CaseCommentKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseCommentCreateInput>>>;
};

/** Case relation input */
export type CaseCaseCommentRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseCommentKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseCommentCreateInput>>>;
  disconnect?: Maybe<Array<CaseCommentKeyFilter>>;
  reconnect?: Maybe<Array<CaseCommentKeyFilter>>;
  update?: Maybe<Array<Maybe<CaseRelation_CaseCommentUpdateInput>>>;
};

/** Case relation input */
export type CaseCaseDocumentRelationManyRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
};

/** Case relation input */
export type CaseCaseDocumentRelationRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseDocumentCreateInput>>>;
};

/** Case relation input */
export type CaseCaseDocumentRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseDocumentCreateInput>>>;
  disconnect?: Maybe<Array<CaseDocumentKeyFilter>>;
  reconnect?: Maybe<Array<CaseDocumentKeyFilter>>;
  update?: Maybe<Array<Maybe<CaseRelation_CaseDocumentUpdateInput>>>;
};

/** Case create input from caseCaseFollowerRelation */
export type CaseCaseFollowerRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseCaseFollowerRelation */
export type CaseCaseFollowerRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case relation input */
export type CaseCaseHistoryRelationManyRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
};

/** Case relation input */
export type CaseCaseHistoryRelationRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseHistoryCreateInput>>>;
};

/** Case relation input */
export type CaseCaseHistoryRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseHistoryCreateInput>>>;
  disconnect?: Maybe<Array<CaseHistoryKeyFilter>>;
  reconnect?: Maybe<Array<CaseHistoryKeyFilter>>;
  update?: Maybe<Array<Maybe<CaseRelation_CaseHistoryUpdateInput>>>;
};

/** Case relation input */
export type CaseCaseLogRelationManyRelationInput = {
  connect?: Maybe<Array<CaseLogKeyFilter>>;
};

/** Case relation input */
export type CaseCaseLogRelationRelationInput = {
  connect?: Maybe<Array<CaseLogKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseLogCreateInput>>>;
};

/** Case relation input */
export type CaseCaseLogRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseLogKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseLogCreateInput>>>;
  disconnect?: Maybe<Array<CaseLogKeyFilter>>;
  reconnect?: Maybe<Array<CaseLogKeyFilter>>;
  update?: Maybe<Array<Maybe<CaseRelation_CaseLogUpdateInput>>>;
};

/** Case relation input */
export type CaseCaseStatusLogRelationManyRelationInput = {
  connect?: Maybe<Array<CaseStatusLogKeyFilter>>;
};

/** Case relation input */
export type CaseCaseStatusLogRelationRelationInput = {
  connect?: Maybe<Array<CaseStatusLogKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseStatusLogCreateInput>>>;
};

/** Case relation input */
export type CaseCaseStatusLogRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseStatusLogKeyFilter>>;
  create?: Maybe<Array<Maybe<CaseRelation_CaseStatusLogCreateInput>>>;
  disconnect?: Maybe<Array<CaseStatusLogKeyFilter>>;
  reconnect?: Maybe<Array<CaseStatusLogKeyFilter>>;
  update?: Maybe<Array<Maybe<CaseRelation_CaseStatusLogUpdateInput>>>;
};

/** Case create input from caseCaseViolationCodeRelation */
export type CaseCaseViolationCodeRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseCaseViolationCodeRelation */
export type CaseCaseViolationCodeRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseComment = {
  __typename?: 'CaseComment';
  _description?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentReplyListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CaseCommentRepliesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentReplyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentReplyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentReplyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentReplySort>>;
};

/** CaseCommentReply create input from caseCommentCaseCommentReplyRelation */
export type CaseCommentCaseCommentReplyRelation_CaseCommentReplyCreateInput = {
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentReplyCaseCommentCaseCommentReplyRelationRelationInput>;
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<CaseCommentReplyUserRelationInput>;
};

/** CaseCommentReply update input from caseCommentCaseCommentReplyRelation */
export type CaseCommentCaseCommentReplyRelation_CaseCommentReplyUpdateInput = {
  data: CaseCommentReplyUpdateInput;
  filter?: Maybe<CaseCommentReplyKeyFilter>;
};

/** CaseComment relation input */
export type CaseCommentCaseRelationManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseComment relation input */
export type CaseCommentCaseRelationRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCommentRelation_CaseCreateInput>;
};

/** CaseComment relation input */
export type CaseCommentCaseRelationUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCommentRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseCommentRelation_CaseUpdateInput>;
};

/** CaseComment create input */
export type CaseCommentCreateInput = {
  caseRelation?: Maybe<CaseCommentCaseRelationRelationInput>;
  comment?: Maybe<Scalars['String']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesRelationInput>;
};

/** CaseComment create many input */
export type CaseCommentCreateManyInput = {
  caseRelation: CaseCommentCaseRelationManyRelationInput;
  comment?: Maybe<Scalars['String']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesManyRelationInput>;
};

/** CaseComment delete input */
export type CaseCommentDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseCommentFieldsPermissions create input */
export type CaseCommentFieldsPermissions = {
  comment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseCommentFilter = {
  AND?: Maybe<Array<CaseCommentFilter>>;
  OR?: Maybe<Array<CaseCommentFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<CaseFilter>;
  comment?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  mode?: Maybe<StringPredicate>;
  replies?: Maybe<CaseCommentReplyRelationFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseCommentGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseCommentGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseCommentGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseRelation?: Maybe<CaseGroupByQuery>;
  comment?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  mode?: Maybe<Array<GroupByField>>;
  replies?: Maybe<CaseCommentReplyGroupByQuery>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CaseCommentKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseCommentListResponse output */
export type CaseCommentListResponse = {
  __typename?: 'CaseCommentListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseComment>;
};

/** CaseCommentManyResponse output */
export type CaseCommentManyResponse = {
  __typename?: 'CaseCommentManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseComment>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseCommentOrderBy {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ModeAsc = 'mode_ASC',
  ModeDesc = 'mode_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseComment subscription payload */
export type CaseCommentPayload = {
  __typename?: 'CaseCommentPayload';
  mutation: MutationType;
  node?: Maybe<CaseComment>;
  previousValues?: Maybe<CaseComment>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseCommentRelationFilter = {
  every?: Maybe<CaseCommentFilter>;
  none?: Maybe<CaseCommentFilter>;
  some?: Maybe<CaseCommentFilter>;
};

/** Case create input from caseCommentRelation */
export type CaseCommentRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseCommentRelation */
export type CaseCommentRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** CaseComment relation input */
export type CaseCommentRepliesManyRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
};

/** CaseComment relation input */
export type CaseCommentRepliesRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  create?: Maybe<
    Array<
      Maybe<CaseCommentCaseCommentReplyRelation_CaseCommentReplyCreateInput>
    >
  >;
};

/** CaseComment relation input */
export type CaseCommentRepliesUpdateRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  create?: Maybe<
    Array<
      Maybe<CaseCommentCaseCommentReplyRelation_CaseCommentReplyCreateInput>
    >
  >;
  disconnect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  reconnect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  update?: Maybe<
    Array<
      Maybe<CaseCommentCaseCommentReplyRelation_CaseCommentReplyUpdateInput>
    >
  >;
};

export type CaseCommentReply = {
  __typename?: 'CaseCommentReply';
  _description?: Maybe<Scalars['String']>;
  caseCommentCaseCommentReplyRelation?: Maybe<CaseComment>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** CaseCommentReply relation input */
export type CaseCommentReplyCaseCommentCaseCommentReplyRelationManyRelationInput =
  {
    connect?: Maybe<CaseCommentKeyFilter>;
  };

/** CaseCommentReply relation input */
export type CaseCommentReplyCaseCommentCaseCommentReplyRelationRelationInput = {
  connect?: Maybe<CaseCommentKeyFilter>;
  create?: Maybe<Replies_CaseCommentCreateInput>;
};

/** CaseCommentReply relation input */
export type CaseCommentReplyCaseCommentCaseCommentReplyRelationUpdateRelationInput =
  {
    connect?: Maybe<CaseCommentKeyFilter>;
    create?: Maybe<Replies_CaseCommentCreateInput>;
    disconnect?: Maybe<CaseCommentKeyFilter>;
    reconnect?: Maybe<CaseCommentKeyFilter>;
    update?: Maybe<Replies_CaseCommentUpdateInput>;
  };

/** CaseCommentReply create input */
export type CaseCommentReplyCreateInput = {
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentReplyCaseCommentCaseCommentReplyRelationRelationInput>;
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<CaseCommentReplyUserRelationInput>;
};

/** CaseCommentReply create many input */
export type CaseCommentReplyCreateManyInput = {
  caseCommentCaseCommentReplyRelation: CaseCommentReplyCaseCommentCaseCommentReplyRelationManyRelationInput;
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<CaseCommentReplyUserManyRelationInput>;
};

/** CaseCommentReply delete input */
export type CaseCommentReplyDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseCommentReplyFieldsPermissions create input */
export type CaseCommentReplyFieldsPermissions = {
  comment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseCommentReplyFilter = {
  AND?: Maybe<Array<CaseCommentReplyFilter>>;
  OR?: Maybe<Array<CaseCommentReplyFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentFilter>;
  comment?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<UserFilter>;
};

export type CaseCommentReplyGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseCommentReplyGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseCommentReplyGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentGroupByQuery>;
  comment?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  user?: Maybe<UserGroupByQuery>;
};

export type CaseCommentReplyKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseCommentReplyListResponse output */
export type CaseCommentReplyListResponse = {
  __typename?: 'CaseCommentReplyListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseCommentReply>;
};

/** CaseCommentReplyManyResponse output */
export type CaseCommentReplyManyResponse = {
  __typename?: 'CaseCommentReplyManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseCommentReply>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseCommentReplyOrderBy {
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseCommentReply subscription payload */
export type CaseCommentReplyPayload = {
  __typename?: 'CaseCommentReplyPayload';
  mutation: MutationType;
  node?: Maybe<CaseCommentReply>;
  previousValues?: Maybe<CaseCommentReply>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseCommentReplyRelationFilter = {
  every?: Maybe<CaseCommentReplyFilter>;
  none?: Maybe<CaseCommentReplyFilter>;
  some?: Maybe<CaseCommentReplyFilter>;
};

export type CaseCommentReplySort = {
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentSort>;
  comment?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserSort>;
};

/** CaseCommentReply subscription filter */
export type CaseCommentReplySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseCommentReplyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseCommentReply update input */
export type CaseCommentReplyUpdateByFilterInput = {
  comment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** CaseCommentReply update input */
export type CaseCommentReplyUpdateInput = {
  caseCommentCaseCommentReplyRelation?: Maybe<CaseCommentReplyCaseCommentCaseCommentReplyRelationUpdateRelationInput>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  user?: Maybe<CaseCommentReplyUserUpdateRelationInput>;
};

/** CaseCommentReply relation input */
export type CaseCommentReplyUserManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** CaseCommentReply relation input */
export type CaseCommentReplyUserRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<UserCaseCommentReplyRelation_UserCreateInput>;
};

/** CaseCommentReply relation input */
export type CaseCommentReplyUserUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<UserCaseCommentReplyRelation_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<UserCaseCommentReplyRelation_UserUpdateInput>;
};

export type CaseCommentReply_PermissionFilter = {
  AND?: Maybe<Array<CaseCommentReply_PermissionFilter>>;
  OR?: Maybe<Array<CaseCommentReply_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseCommentCaseCommentReplyRelation?: Maybe<CaseComment_PermissionFilter>;
  comment?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<User_PermissionFilter>;
};

export type CaseCommentReply_PermissionRelationFilter = {
  every?: Maybe<CaseCommentReply_PermissionFilter>;
  none?: Maybe<CaseCommentReply_PermissionFilter>;
  some?: Maybe<CaseCommentReply_PermissionFilter>;
};

export type CaseCommentSort = {
  caseRelation?: Maybe<CaseSort>;
  comment?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mode?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CaseComment subscription filter */
export type CaseCommentSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseCommentFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseComment update input */
export type CaseCommentUpdateByFilterInput = {
  comment?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  mode?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** CaseComment update input */
export type CaseCommentUpdateInput = {
  caseRelation?: Maybe<CaseCommentCaseRelationUpdateRelationInput>;
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesUpdateRelationInput>;
};

export type CaseComment_PermissionFilter = {
  AND?: Maybe<Array<CaseComment_PermissionFilter>>;
  OR?: Maybe<Array<CaseComment_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case_PermissionFilter>;
  comment?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  mode?: Maybe<StringPredicate>;
  replies?: Maybe<CaseCommentReply_PermissionRelationFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseComment_PermissionRelationFilter = {
  every?: Maybe<CaseComment_PermissionFilter>;
  none?: Maybe<CaseComment_PermissionFilter>;
  some?: Maybe<CaseComment_PermissionFilter>;
};

/** Case create input */
export type CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case create many input */
export type CaseCreateManyInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationManyRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationManyRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationManyRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationManyRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationManyRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationManyRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationManyRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationManyRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerManyRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver: CaseDriverManyRelationInput;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateManyRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationManyRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case relation input */
export type CaseCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** Case relation input */
export type CaseCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerCasesRelation_CustomerCreateInput>;
};

/** Case relation input */
export type CaseCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerCasesRelation_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<CustomerCasesRelation_CustomerUpdateInput>;
};

/** Case delete input */
export type CaseDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseDocument = {
  __typename?: 'CaseDocument';
  _description?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CaseDocument relation input */
export type CaseDocumentCaseRelationManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseDocument relation input */
export type CaseDocumentCaseRelationRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseDocumentRelation_CaseCreateInput>;
};

/** CaseDocument relation input */
export type CaseDocumentCaseRelationUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseDocumentRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseDocumentRelation_CaseUpdateInput>;
};

/** CaseDocument create input */
export type CaseDocumentCreateInput = {
  caseRelation?: Maybe<CaseDocumentCaseRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CaseDocumentFileRelationInput>;
  type?: Maybe<Scalars['String']>;
};

/** CaseDocument create many input */
export type CaseDocumentCreateManyInput = {
  caseRelation: CaseDocumentCaseRelationManyRelationInput;
  description?: Maybe<Scalars['String']>;
  file: CaseDocumentFileManyRelationInput;
  type?: Maybe<Scalars['String']>;
};

/** CaseDocument delete input */
export type CaseDocumentDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseDocumentDeleteManagementFilter = {
  caseDocumentId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CaseDocumentDeleteManagementResult = {
  __typename?: 'CaseDocumentDeleteManagementResult';
  success: Scalars['Boolean'];
};

/** CaseDocumentFieldsPermissions create input */
export type CaseDocumentFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

/** CaseDocument relation input */
export type CaseDocumentFileManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** CaseDocument relation input */
export type CaseDocumentFileRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CaseDocument_File_FileCreateInput>;
};

/** CaseDocument relation input */
export type CaseDocumentFileUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CaseDocument_File_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<CaseDocument_File_FileUpdateInput>;
};

export type CaseDocumentFilter = {
  AND?: Maybe<Array<CaseDocumentFilter>>;
  OR?: Maybe<Array<CaseDocumentFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  file?: Maybe<FileFilter>;
  id?: Maybe<IdPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseDocumentGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseDocumentGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseDocumentGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseRelation?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  description?: Maybe<Array<GroupByField>>;
  file?: Maybe<FileGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  type?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CaseDocumentKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseDocumentListResponse output */
export type CaseDocumentListResponse = {
  __typename?: 'CaseDocumentListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseDocument>;
};

/** CaseDocumentManyResponse output */
export type CaseDocumentManyResponse = {
  __typename?: 'CaseDocumentManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseDocument>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseDocumentOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseDocument subscription payload */
export type CaseDocumentPayload = {
  __typename?: 'CaseDocumentPayload';
  mutation: MutationType;
  node?: Maybe<CaseDocument>;
  previousValues?: Maybe<CaseDocument>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseDocumentRelationFilter = {
  every?: Maybe<CaseDocumentFilter>;
  none?: Maybe<CaseDocumentFilter>;
  some?: Maybe<CaseDocumentFilter>;
};

/** Case create input from caseDocumentRelation */
export type CaseDocumentRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseDocumentRelation */
export type CaseDocumentRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseDocumentSort = {
  caseRelation?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  file?: Maybe<FileSort>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CaseDocument subscription filter */
export type CaseDocumentSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseDocumentFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseDocument update input */
export type CaseDocumentUpdateByFilterInput = {
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  type?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** CaseDocument update input */
export type CaseDocumentUpdateInput = {
  caseRelation?: Maybe<CaseDocumentCaseRelationUpdateRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CaseDocumentFileUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

export type CaseDocument_PermissionFilter = {
  AND?: Maybe<Array<CaseDocument_PermissionFilter>>;
  OR?: Maybe<Array<CaseDocument_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  file?: Maybe<File_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  type?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseDocument_PermissionRelationFilter = {
  every?: Maybe<CaseDocument_PermissionFilter>;
  none?: Maybe<CaseDocument_PermissionFilter>;
  some?: Maybe<CaseDocument_PermissionFilter>;
};

/** Files create input from caseDocument_file */
export type CaseDocument_File_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from caseDocument_file */
export type CaseDocument_File_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Case relation input */
export type CaseDriverManyRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
};

/** Case relation input */
export type CaseDriverRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<CaseDriverRelation_DriverCreateInput>;
};

/** Driver create input from caseDriverRelation */
export type CaseDriverRelation_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer: DriverCustomerRelationInput;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage: DriverPreferredLanguageRelationInput;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from caseDriverRelation */
export type CaseDriverRelation_DriverUpdateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationUpdateRelationInput>;
  customer?: Maybe<DriverCustomerUpdateRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationUpdateRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Case relation input */
export type CaseDriverUpdateRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<CaseDriverRelation_DriverCreateInput>;
  disconnect?: Maybe<DriverKeyFilter>;
  reconnect?: Maybe<DriverKeyFilter>;
  update?: Maybe<CaseDriverRelation_DriverUpdateInput>;
};

/** CaseFieldsPermissions create input */
export type CaseFieldsPermissions = {
  briefStatement?: Maybe<Scalars['Boolean']>;
  caseID?: Maybe<Scalars['Boolean']>;
  citationArraignment?: Maybe<Scalars['Boolean']>;
  citationCourtNameAddress?: Maybe<Scalars['Boolean']>;
  citationDueDate?: Maybe<Scalars['Boolean']>;
  citationNumbers?: Maybe<Scalars['Boolean']>;
  citationStatus?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['Boolean']>;
  crash?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  dataQsStatus?: Maybe<Scalars['Boolean']>;
  inspectionDate?: Maybe<Scalars['Boolean']>;
  inspectionReportNumber?: Maybe<Scalars['Boolean']>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  updatedAtAll?: Maybe<Scalars['Boolean']>;
  updatedAtByAgent?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<Scalars['Boolean']>;
};

export type CaseFilter = {
  AND?: Maybe<Array<CaseFilter>>;
  OR?: Maybe<Array<CaseFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  attorneyRelation?: Maybe<AttorneyFilter>;
  briefStatement?: Maybe<StringPredicate>;
  caseCaseFollowerRelation?: Maybe<CaseFollowerRelationFilter>;
  caseCaseViolationCodeRelation?: Maybe<CaseViolationCodeRelationFilter>;
  caseCommentRelation?: Maybe<CaseCommentRelationFilter>;
  caseDocumentRelation?: Maybe<CaseDocumentRelationFilter>;
  caseHistoryRelation?: Maybe<CaseHistoryRelationFilter>;
  caseID?: Maybe<StringPredicate>;
  caseLogRelation?: Maybe<CaseLogRelationFilter>;
  caseStatusLogRelation?: Maybe<CaseStatusLogRelationFilter>;
  citationArraignment?: Maybe<DatePredicate>;
  citationCourtNameAddress?: Maybe<StringPredicate>;
  citationDueDate?: Maybe<DatePredicate>;
  citationNumbers?: Maybe<StringPredicate>;
  citationStatus?: Maybe<StringPredicate>;
  code?: Maybe<IntPredicate>;
  crash?: Maybe<BoolPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  dataQsStatus?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<DriverFilter>;
  id?: Maybe<IdPredicate>;
  inspectionCitationState?: Maybe<UsStateFilter>;
  inspectionDate?: Maybe<DatePredicate>;
  inspectionReportNumber?: Maybe<StringPredicate>;
  notificationObjectRelation?: Maybe<NotificationObjectRelationFilter>;
  roadsideInspection?: Maybe<BoolPredicate>;
  sortScore?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  trafficCitation?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  updatedAtAll?: Maybe<DateTimePredicate>;
  updatedAtByAgent?: Maybe<DateTimePredicate>;
  updatedBy?: Maybe<StringPredicate>;
};

export type CaseFollower = {
  __typename?: 'CaseFollower';
  _description?: Maybe<Scalars['String']>;
  case?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  follower?: Maybe<User>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CaseFollower relation input */
export type CaseFollowerCaseManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseFollower relation input */
export type CaseFollowerCaseRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCaseFollowerRelation_CaseCreateInput>;
};

/** CaseFollower relation input */
export type CaseFollowerCaseUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCaseFollowerRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseCaseFollowerRelation_CaseUpdateInput>;
};

/** CaseFollower create input */
export type CaseFollowerCreateInput = {
  case?: Maybe<CaseFollowerCaseRelationInput>;
  follower?: Maybe<CaseFollowerFollowerRelationInput>;
};

/** CaseFollower create many input */
export type CaseFollowerCreateManyInput = {
  case: CaseFollowerCaseManyRelationInput;
  follower: CaseFollowerFollowerManyRelationInput;
};

/** CaseFollower delete input */
export type CaseFollowerDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseFollowerFieldsPermissions create input */
export type CaseFollowerFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseFollowerFilter = {
  AND?: Maybe<Array<CaseFollowerFilter>>;
  OR?: Maybe<Array<CaseFollowerFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  follower?: Maybe<UserFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** CaseFollower relation input */
export type CaseFollowerFollowerManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** CaseFollower relation input */
export type CaseFollowerFollowerRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<UserCaseFollowerRelation_UserCreateInput>;
};

/** CaseFollower relation input */
export type CaseFollowerFollowerUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<UserCaseFollowerRelation_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<UserCaseFollowerRelation_UserUpdateInput>;
};

export type CaseFollowerGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseFollowerGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseFollowerGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  case?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  follower?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CaseFollowerKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseFollowerListResponse output */
export type CaseFollowerListResponse = {
  __typename?: 'CaseFollowerListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseFollower>;
};

/** CaseFollowerManyResponse output */
export type CaseFollowerManyResponse = {
  __typename?: 'CaseFollowerManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseFollower>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseFollowerOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseFollower subscription payload */
export type CaseFollowerPayload = {
  __typename?: 'CaseFollowerPayload';
  mutation: MutationType;
  node?: Maybe<CaseFollower>;
  previousValues?: Maybe<CaseFollower>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseFollowerRelationFilter = {
  every?: Maybe<CaseFollowerFilter>;
  none?: Maybe<CaseFollowerFilter>;
  some?: Maybe<CaseFollowerFilter>;
};

export type CaseFollowerSort = {
  case?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  follower?: Maybe<UserSort>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CaseFollower subscription filter */
export type CaseFollowerSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseFollowerFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseFollower update input */
export type CaseFollowerUpdateInput = {
  case?: Maybe<CaseFollowerCaseUpdateRelationInput>;
  follower?: Maybe<CaseFollowerFollowerUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseFollower_PermissionFilter = {
  AND?: Maybe<Array<CaseFollower_PermissionFilter>>;
  OR?: Maybe<Array<CaseFollower_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  follower?: Maybe<User_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseFollower_PermissionRelationFilter = {
  every?: Maybe<CaseFollower_PermissionFilter>;
  none?: Maybe<CaseFollower_PermissionFilter>;
  some?: Maybe<CaseFollower_PermissionFilter>;
};

export type CaseGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  attorneyRelation?: Maybe<AttorneyGroupByQuery>;
  briefStatement?: Maybe<Array<GroupByField>>;
  caseCaseFollowerRelation?: Maybe<CaseFollowerGroupByQuery>;
  caseCaseViolationCodeRelation?: Maybe<CaseViolationCodeGroupByQuery>;
  caseCommentRelation?: Maybe<CaseCommentGroupByQuery>;
  caseDocumentRelation?: Maybe<CaseDocumentGroupByQuery>;
  caseHistoryRelation?: Maybe<CaseHistoryGroupByQuery>;
  caseID?: Maybe<Array<GroupByField>>;
  caseLogRelation?: Maybe<CaseLogGroupByQuery>;
  caseStatusLogRelation?: Maybe<CaseStatusLogGroupByQuery>;
  citationArraignment?: Maybe<Array<GroupByField>>;
  citationCourtNameAddress?: Maybe<Array<GroupByField>>;
  citationDueDate?: Maybe<Array<GroupByField>>;
  citationNumbers?: Maybe<Array<GroupByField>>;
  citationStatus?: Maybe<Array<GroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  crash?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  dataQsStatus?: Maybe<Array<GroupByField>>;
  driver?: Maybe<DriverGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  inspectionCitationState?: Maybe<UsStateGroupByQuery>;
  inspectionDate?: Maybe<Array<GroupByField>>;
  inspectionReportNumber?: Maybe<Array<GroupByField>>;
  notificationObjectRelation?: Maybe<NotificationObjectGroupByQuery>;
  roadsideInspection?: Maybe<Array<GroupByField>>;
  sortScore?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  trafficCitation?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  updatedAtAll?: Maybe<Array<GroupByField>>;
  updatedAtByAgent?: Maybe<Array<GroupByField>>;
  updatedBy?: Maybe<Array<GroupByField>>;
};

export type CaseHistory = {
  __typename?: 'CaseHistory';
  _description?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userRelation?: Maybe<User>;
};

/** CaseHistory relation input */
export type CaseHistoryCaseRelationManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseHistory relation input */
export type CaseHistoryCaseRelationRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseHistoryRelation_CaseCreateInput>;
};

/** CaseHistory relation input */
export type CaseHistoryCaseRelationUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseHistoryRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseHistoryRelation_CaseUpdateInput>;
};

/** CaseHistory create input */
export type CaseHistoryCreateInput = {
  caseRelation?: Maybe<CaseHistoryCaseRelationRelationInput>;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  userRelation?: Maybe<CaseHistoryUserRelationRelationInput>;
};

/** CaseHistory create many input */
export type CaseHistoryCreateManyInput = {
  caseRelation: CaseHistoryCaseRelationManyRelationInput;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  userRelation?: Maybe<CaseHistoryUserRelationManyRelationInput>;
};

/** CaseHistory delete input */
export type CaseHistoryDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseHistoryFieldsPermissions create input */
export type CaseHistoryFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['Boolean']>;
  newData?: Maybe<Scalars['Boolean']>;
  olderData?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseHistoryFilter = {
  AND?: Maybe<Array<CaseHistoryFilter>>;
  OR?: Maybe<Array<CaseHistoryFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  message?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userRelation?: Maybe<UserFilter>;
};

export type CaseHistoryGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseHistoryGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseHistoryGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseRelation?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  message?: Maybe<Array<GroupByField>>;
  newData?: Maybe<Array<GroupByField>>;
  olderData?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  userRelation?: Maybe<UserGroupByQuery>;
};

export type CaseHistoryKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseHistoryListResponse output */
export type CaseHistoryListResponse = {
  __typename?: 'CaseHistoryListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseHistory>;
};

/** CaseHistoryManyResponse output */
export type CaseHistoryManyResponse = {
  __typename?: 'CaseHistoryManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseHistory>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseHistoryOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  NewDataAsc = 'newData_ASC',
  NewDataDesc = 'newData_DESC',
  OlderDataAsc = 'olderData_ASC',
  OlderDataDesc = 'olderData_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseHistory subscription payload */
export type CaseHistoryPayload = {
  __typename?: 'CaseHistoryPayload';
  mutation: MutationType;
  node?: Maybe<CaseHistory>;
  previousValues?: Maybe<CaseHistory>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseHistoryRelationFilter = {
  every?: Maybe<CaseHistoryFilter>;
  none?: Maybe<CaseHistoryFilter>;
  some?: Maybe<CaseHistoryFilter>;
};

/** Case create input from caseHistoryRelation */
export type CaseHistoryRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseHistoryRelation */
export type CaseHistoryRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Users create input from caseHistoryRelation */
export type CaseHistoryRelation_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from caseHistoryRelation */
export type CaseHistoryRelation_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CaseHistorySort = {
  caseRelation?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userRelation?: Maybe<UserSort>;
};

/** CaseHistory subscription filter */
export type CaseHistorySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseHistoryFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseHistory update input */
export type CaseHistoryUpdateByFilterInput = {
  message?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  newData?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  olderData?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** CaseHistory update input */
export type CaseHistoryUpdateInput = {
  caseRelation?: Maybe<CaseHistoryCaseRelationUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  userRelation?: Maybe<CaseHistoryUserRelationUpdateRelationInput>;
};

/** CaseHistory relation input */
export type CaseHistoryUserRelationManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** CaseHistory relation input */
export type CaseHistoryUserRelationRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<CaseHistoryRelation_UserCreateInput>;
};

/** CaseHistory relation input */
export type CaseHistoryUserRelationUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<CaseHistoryRelation_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<CaseHistoryRelation_UserUpdateInput>;
};

export type CaseHistory_PermissionFilter = {
  AND?: Maybe<Array<CaseHistory_PermissionFilter>>;
  OR?: Maybe<Array<CaseHistory_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  message?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userRelation?: Maybe<User_PermissionFilter>;
};

export type CaseHistory_PermissionRelationFilter = {
  every?: Maybe<CaseHistory_PermissionFilter>;
  none?: Maybe<CaseHistory_PermissionFilter>;
  some?: Maybe<CaseHistory_PermissionFilter>;
};

/** Case relation input */
export type CaseInspectionCitationStateManyRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
};

/** Case relation input */
export type CaseInspectionCitationStateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<CaseStateRelation_UsStateCreateInput>;
};

/** Case relation input */
export type CaseInspectionCitationStateUpdateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<CaseStateRelation_UsStateCreateInput>;
  disconnect?: Maybe<UsStateKeyFilter>;
  reconnect?: Maybe<UsStateKeyFilter>;
  update?: Maybe<CaseStateRelation_UsStateUpdateInput>;
};

export type CaseKeyFilter = {
  caseID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseListResponse output */
export type CaseListResponse = {
  __typename?: 'CaseListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Case>;
};

export type CaseLog = {
  __typename?: 'CaseLog';
  _description?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CaseLog relation input */
export type CaseLogCaseRelationManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseLog relation input */
export type CaseLogCaseRelationRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseLogRelation_CaseCreateInput>;
};

/** CaseLog relation input */
export type CaseLogCaseRelationUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseLogRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseLogRelation_CaseUpdateInput>;
};

/** CaseLog create input */
export type CaseLogCreateInput = {
  caseRelation?: Maybe<CaseLogCaseRelationRelationInput>;
  field?: Maybe<Scalars['String']>;
};

/** CaseLog create many input */
export type CaseLogCreateManyInput = {
  caseRelation: CaseLogCaseRelationManyRelationInput;
  field?: Maybe<Scalars['String']>;
};

/** CaseLog delete input */
export type CaseLogDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseLogFieldsPermissions create input */
export type CaseLogFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  field?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseLogFilter = {
  AND?: Maybe<Array<CaseLogFilter>>;
  OR?: Maybe<Array<CaseLogFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  field?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseLogGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseLogGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseLogGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseRelation?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  field?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CaseLogKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseLogListResponse output */
export type CaseLogListResponse = {
  __typename?: 'CaseLogListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseLog>;
};

/** CaseLogManyResponse output */
export type CaseLogManyResponse = {
  __typename?: 'CaseLogManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseLog>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseLogOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  FieldAsc = 'field_ASC',
  FieldDesc = 'field_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseLog subscription payload */
export type CaseLogPayload = {
  __typename?: 'CaseLogPayload';
  mutation: MutationType;
  node?: Maybe<CaseLog>;
  previousValues?: Maybe<CaseLog>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseLogRelationFilter = {
  every?: Maybe<CaseLogFilter>;
  none?: Maybe<CaseLogFilter>;
  some?: Maybe<CaseLogFilter>;
};

/** Case create input from caseLogRelation */
export type CaseLogRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseLogRelation */
export type CaseLogRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseLogSort = {
  caseRelation?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  field?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CaseLog subscription filter */
export type CaseLogSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseLogFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseLog update input */
export type CaseLogUpdateByFilterInput = {
  field?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** CaseLog update input */
export type CaseLogUpdateInput = {
  caseRelation?: Maybe<CaseLogCaseRelationUpdateRelationInput>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseLog_PermissionFilter = {
  AND?: Maybe<Array<CaseLog_PermissionFilter>>;
  OR?: Maybe<Array<CaseLog_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  field?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseLog_PermissionRelationFilter = {
  every?: Maybe<CaseLog_PermissionFilter>;
  none?: Maybe<CaseLog_PermissionFilter>;
  some?: Maybe<CaseLog_PermissionFilter>;
};

/** CaseManyResponse output */
export type CaseManyResponse = {
  __typename?: 'CaseManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Case>;
};

/** Case relation input */
export type CaseNotificationObjectRelationManyRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
};

/** Case relation input */
export type CaseNotificationObjectRelationRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_NotificationObjectCreateInput>>>;
};

/** Case relation input */
export type CaseNotificationObjectRelationUpdateRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
  create?: Maybe<Array<Maybe<Case_NotificationObjectCreateInput>>>;
  disconnect?: Maybe<Array<NotificationObjectKeyFilter>>;
  reconnect?: Maybe<Array<NotificationObjectKeyFilter>>;
  update?: Maybe<Array<Maybe<Case_NotificationObjectUpdateInput>>>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseOrderBy {
  BriefStatementAsc = 'briefStatement_ASC',
  BriefStatementDesc = 'briefStatement_DESC',
  CaseIdAsc = 'caseID_ASC',
  CaseIdDesc = 'caseID_DESC',
  CitationArraignmentAsc = 'citationArraignment_ASC',
  CitationArraignmentDesc = 'citationArraignment_DESC',
  CitationCourtNameAddressAsc = 'citationCourtNameAddress_ASC',
  CitationCourtNameAddressDesc = 'citationCourtNameAddress_DESC',
  CitationDueDateAsc = 'citationDueDate_ASC',
  CitationDueDateDesc = 'citationDueDate_DESC',
  CitationNumbersAsc = 'citationNumbers_ASC',
  CitationNumbersDesc = 'citationNumbers_DESC',
  CitationStatusAsc = 'citationStatus_ASC',
  CitationStatusDesc = 'citationStatus_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CrashAsc = 'crash_ASC',
  CrashDesc = 'crash_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DataQsStatusAsc = 'dataQsStatus_ASC',
  DataQsStatusDesc = 'dataQsStatus_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InspectionDateAsc = 'inspectionDate_ASC',
  InspectionDateDesc = 'inspectionDate_DESC',
  InspectionReportNumberAsc = 'inspectionReportNumber_ASC',
  InspectionReportNumberDesc = 'inspectionReportNumber_DESC',
  RoadsideInspectionAsc = 'roadsideInspection_ASC',
  RoadsideInspectionDesc = 'roadsideInspection_DESC',
  SortScoreAsc = 'sortScore_ASC',
  SortScoreDesc = 'sortScore_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TrafficCitationAsc = 'trafficCitation_ASC',
  TrafficCitationDesc = 'trafficCitation_DESC',
  UpdatedAtAllAsc = 'updatedAtAll_ASC',
  UpdatedAtAllDesc = 'updatedAtAll_DESC',
  UpdatedAtByAgentAsc = 'updatedAtByAgent_ASC',
  UpdatedAtByAgentDesc = 'updatedAtByAgent_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
}

/** Case subscription payload */
export type CasePayload = {
  __typename?: 'CasePayload';
  mutation: MutationType;
  node?: Maybe<Case>;
  previousValues?: Maybe<Case>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseRelationFilter = {
  every?: Maybe<CaseFilter>;
  none?: Maybe<CaseFilter>;
  some?: Maybe<CaseFilter>;
};

/** CaseComment create input from caseRelation */
export type CaseRelation_CaseCommentCreateInput = {
  caseRelation?: Maybe<CaseCommentCaseRelationRelationInput>;
  comment?: Maybe<Scalars['String']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesRelationInput>;
};

/** CaseComment update input from caseRelation */
export type CaseRelation_CaseCommentUpdateInput = {
  data: CaseCommentUpdateInput;
  filter?: Maybe<CaseCommentKeyFilter>;
};

/** CaseDocument create input from caseRelation */
export type CaseRelation_CaseDocumentCreateInput = {
  caseRelation?: Maybe<CaseDocumentCaseRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file: CaseDocumentFileRelationInput;
  type?: Maybe<Scalars['String']>;
};

/** CaseDocument update input from caseRelation */
export type CaseRelation_CaseDocumentUpdateInput = {
  data: CaseDocumentUpdateInput;
  filter?: Maybe<CaseDocumentKeyFilter>;
};

/** CaseHistory create input from caseRelation */
export type CaseRelation_CaseHistoryCreateInput = {
  caseRelation?: Maybe<CaseHistoryCaseRelationRelationInput>;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  userRelation?: Maybe<CaseHistoryUserRelationRelationInput>;
};

/** CaseHistory update input from caseRelation */
export type CaseRelation_CaseHistoryUpdateInput = {
  data: CaseHistoryUpdateInput;
  filter?: Maybe<CaseHistoryKeyFilter>;
};

/** CaseLog create input from caseRelation */
export type CaseRelation_CaseLogCreateInput = {
  caseRelation?: Maybe<CaseLogCaseRelationRelationInput>;
  field?: Maybe<Scalars['String']>;
};

/** CaseLog update input from caseRelation */
export type CaseRelation_CaseLogUpdateInput = {
  data: CaseLogUpdateInput;
  filter?: Maybe<CaseLogKeyFilter>;
};

/** CaseStatusLog create input from caseRelation */
export type CaseRelation_CaseStatusLogCreateInput = {
  caseRelation?: Maybe<CaseStatusLogCaseRelationRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** CaseStatusLog update input from caseRelation */
export type CaseRelation_CaseStatusLogUpdateInput = {
  data: CaseStatusLogUpdateInput;
  filter?: Maybe<CaseStatusLogKeyFilter>;
};

export type CaseSort = {
  attorneyRelation?: Maybe<AttorneySort>;
  briefStatement?: Maybe<SortOrder>;
  caseID?: Maybe<SortOrder>;
  citationArraignment?: Maybe<SortOrder>;
  citationCourtNameAddress?: Maybe<SortOrder>;
  citationDueDate?: Maybe<SortOrder>;
  citationNumbers?: Maybe<SortOrder>;
  citationStatus?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  crash?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  dataQsStatus?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  driver?: Maybe<DriverSort>;
  id?: Maybe<SortOrder>;
  inspectionCitationState?: Maybe<UsStateSort>;
  inspectionDate?: Maybe<SortOrder>;
  inspectionReportNumber?: Maybe<SortOrder>;
  roadsideInspection?: Maybe<SortOrder>;
  sortScore?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  trafficCitation?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  updatedAtAll?: Maybe<SortOrder>;
  updatedAtByAgent?: Maybe<SortOrder>;
  updatedBy?: Maybe<SortOrder>;
};

/** UsState create input from caseStateRelation */
export type CaseStateRelation_UsStateCreateInput = {
  addressRelation?: Maybe<UsStateAddressRelationRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationRelationInput>;
};

/** UsState update input from caseStateRelation */
export type CaseStateRelation_UsStateUpdateInput = {
  addressRelation?: Maybe<UsStateAddressRelationUpdateRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationUpdateRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationUpdateRelationInput>;
};

export type CaseStatusLog = {
  __typename?: 'CaseStatusLog';
  _description?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CaseStatusLog relation input */
export type CaseStatusLogCaseRelationManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseStatusLog relation input */
export type CaseStatusLogCaseRelationRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseStatusLogRelation_CaseCreateInput>;
};

/** CaseStatusLog relation input */
export type CaseStatusLogCaseRelationUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseStatusLogRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseStatusLogRelation_CaseUpdateInput>;
};

/** CaseStatusLog create input */
export type CaseStatusLogCreateInput = {
  caseRelation?: Maybe<CaseStatusLogCaseRelationRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** CaseStatusLog create many input */
export type CaseStatusLogCreateManyInput = {
  caseRelation: CaseStatusLogCaseRelationManyRelationInput;
  status?: Maybe<Scalars['String']>;
};

/** CaseStatusLog delete input */
export type CaseStatusLogDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseStatusLogFieldsPermissions create input */
export type CaseStatusLogFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseStatusLogFilter = {
  AND?: Maybe<Array<CaseStatusLogFilter>>;
  OR?: Maybe<Array<CaseStatusLogFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  status?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseStatusLogGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseStatusLogGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseStatusLogGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseRelation?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CaseStatusLogKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseStatusLogListResponse output */
export type CaseStatusLogListResponse = {
  __typename?: 'CaseStatusLogListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseStatusLog>;
};

/** CaseStatusLogManyResponse output */
export type CaseStatusLogManyResponse = {
  __typename?: 'CaseStatusLogManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseStatusLog>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseStatusLogOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseStatusLog subscription payload */
export type CaseStatusLogPayload = {
  __typename?: 'CaseStatusLogPayload';
  mutation: MutationType;
  node?: Maybe<CaseStatusLog>;
  previousValues?: Maybe<CaseStatusLog>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseStatusLogRelationFilter = {
  every?: Maybe<CaseStatusLogFilter>;
  none?: Maybe<CaseStatusLogFilter>;
  some?: Maybe<CaseStatusLogFilter>;
};

/** Case create input from caseStatusLogRelation */
export type CaseStatusLogRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from caseStatusLogRelation */
export type CaseStatusLogRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseStatusLogSort = {
  caseRelation?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CaseStatusLog subscription filter */
export type CaseStatusLogSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseStatusLogFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseStatusLog update input */
export type CaseStatusLogUpdateByFilterInput = {
  status?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** CaseStatusLog update input */
export type CaseStatusLogUpdateInput = {
  caseRelation?: Maybe<CaseStatusLogCaseRelationUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type CaseStatusLog_PermissionFilter = {
  AND?: Maybe<Array<CaseStatusLog_PermissionFilter>>;
  OR?: Maybe<Array<CaseStatusLog_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseRelation?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  status?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CaseStatusLog_PermissionRelationFilter = {
  every?: Maybe<CaseStatusLog_PermissionFilter>;
  none?: Maybe<CaseStatusLog_PermissionFilter>;
  some?: Maybe<CaseStatusLog_PermissionFilter>;
};

/** Case subscription filter */
export type CaseSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Case update input */
export type CaseUpdateByFilterInput = {
  briefStatement?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  caseID?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  citationArraignment?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  citationCourtNameAddress?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  citationDueDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  citationNumbers?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  citationStatus?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  code?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  crash?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  dataQsStatus?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  inspectionDate?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  inspectionReportNumber?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  roadsideInspection?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  sortScore?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  trafficCitation?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  updatedAtAll?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  updatedAtByAgent?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  updatedBy?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Case update input */
export type CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CaseViolationCode = {
  __typename?: 'CaseViolationCode';
  _description?: Maybe<Scalars['String']>;
  case?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  violationCode?: Maybe<ViolationCode>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeCaseManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeCaseRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCaseViolationCodeRelation_CaseCreateInput>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeCaseUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<CaseCaseViolationCodeRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<CaseCaseViolationCodeRelation_CaseUpdateInput>;
};

/** CaseViolationCode create input */
export type CaseViolationCodeCreateInput = {
  case?: Maybe<CaseViolationCodeCaseRelationInput>;
  violationCode?: Maybe<CaseViolationCodeViolationCodeRelationInput>;
};

/** CaseViolationCode create many input */
export type CaseViolationCodeCreateManyInput = {
  case: CaseViolationCodeCaseManyRelationInput;
  violationCode: CaseViolationCodeViolationCodeManyRelationInput;
};

/** CaseViolationCode delete input */
export type CaseViolationCodeDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CaseViolationCodeFieldsPermissions create input */
export type CaseViolationCodeFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CaseViolationCodeFilter = {
  AND?: Maybe<Array<CaseViolationCodeFilter>>;
  OR?: Maybe<Array<CaseViolationCodeFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  violationCode?: Maybe<ViolationCodeFilter>;
};

export type CaseViolationCodeGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CaseViolationCodeGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CaseViolationCodeGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  case?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  violationCode?: Maybe<ViolationCodeGroupByQuery>;
};

export type CaseViolationCodeKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CaseViolationCodeListResponse output */
export type CaseViolationCodeListResponse = {
  __typename?: 'CaseViolationCodeListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CaseViolationCode>;
};

/** CaseViolationCodeManyResponse output */
export type CaseViolationCodeManyResponse = {
  __typename?: 'CaseViolationCodeManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CaseViolationCode>;
};

/** No longer supported. Use `sort` instead. */
export enum CaseViolationCodeOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CaseViolationCode subscription payload */
export type CaseViolationCodePayload = {
  __typename?: 'CaseViolationCodePayload';
  mutation: MutationType;
  node?: Maybe<CaseViolationCode>;
  previousValues?: Maybe<CaseViolationCode>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaseViolationCodeRelationFilter = {
  every?: Maybe<CaseViolationCodeFilter>;
  none?: Maybe<CaseViolationCodeFilter>;
  some?: Maybe<CaseViolationCodeFilter>;
};

export type CaseViolationCodeSort = {
  case?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  violationCode?: Maybe<ViolationCodeSort>;
};

/** CaseViolationCode subscription filter */
export type CaseViolationCodeSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CaseViolationCodeFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CaseViolationCode update input */
export type CaseViolationCodeUpdateInput = {
  case?: Maybe<CaseViolationCodeCaseUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  violationCode?: Maybe<CaseViolationCodeViolationCodeUpdateRelationInput>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeViolationCodeManyRelationInput = {
  connect?: Maybe<ViolationCodeKeyFilter>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeViolationCodeRelationInput = {
  connect?: Maybe<ViolationCodeKeyFilter>;
  create?: Maybe<ViolationCodeCaseViolationCodeRelation_ViolationCodeCreateInput>;
};

/** CaseViolationCode relation input */
export type CaseViolationCodeViolationCodeUpdateRelationInput = {
  connect?: Maybe<ViolationCodeKeyFilter>;
  create?: Maybe<ViolationCodeCaseViolationCodeRelation_ViolationCodeCreateInput>;
  disconnect?: Maybe<ViolationCodeKeyFilter>;
  reconnect?: Maybe<ViolationCodeKeyFilter>;
  update?: Maybe<ViolationCodeCaseViolationCodeRelation_ViolationCodeUpdateInput>;
};

export type CaseViolationCode_PermissionFilter = {
  AND?: Maybe<Array<CaseViolationCode_PermissionFilter>>;
  OR?: Maybe<Array<CaseViolationCode_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  violationCode?: Maybe<ViolationCode_PermissionFilter>;
};

export type CaseViolationCode_PermissionRelationFilter = {
  every?: Maybe<CaseViolationCode_PermissionFilter>;
  none?: Maybe<CaseViolationCode_PermissionFilter>;
  some?: Maybe<CaseViolationCode_PermissionFilter>;
};

/** Attorney create input from case */
export type Case_AttorneyCreateInput = {
  case?: Maybe<AttorneyCaseRelationInput>;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Attorney update input from case */
export type Case_AttorneyUpdateInput = {
  case?: Maybe<AttorneyCaseUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** CaseFollower create input from case */
export type Case_CaseFollowerCreateInput = {
  case?: Maybe<CaseFollowerCaseRelationInput>;
  follower: CaseFollowerFollowerRelationInput;
};

/** CaseFollower update input from case */
export type Case_CaseFollowerUpdateInput = {
  data: CaseFollowerUpdateInput;
  filter?: Maybe<CaseFollowerKeyFilter>;
};

/** CaseViolationCode create input from case */
export type Case_CaseViolationCodeCreateInput = {
  case?: Maybe<CaseViolationCodeCaseRelationInput>;
  violationCode: CaseViolationCodeViolationCodeRelationInput;
};

/** CaseViolationCode update input from case */
export type Case_CaseViolationCodeUpdateInput = {
  data: CaseViolationCodeUpdateInput;
  filter?: Maybe<CaseViolationCodeKeyFilter>;
};

/** NotificationObject create input from case */
export type Case_NotificationObjectCreateInput = {
  case?: Maybe<NotificationObjectCaseRelationInput>;
  customer?: Maybe<NotificationObjectCustomerRelationInput>;
  notification: NotificationObjectNotificationRelationInput;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** NotificationObject update input from case */
export type Case_NotificationObjectUpdateInput = {
  data: NotificationObjectUpdateInput;
  filter?: Maybe<NotificationObjectKeyFilter>;
};

export type Case_PermissionFilter = {
  AND?: Maybe<Array<Case_PermissionFilter>>;
  OR?: Maybe<Array<Case_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  attorneyRelation?: Maybe<Attorney_PermissionFilter>;
  briefStatement?: Maybe<StringPredicate>;
  caseCaseFollowerRelation?: Maybe<CaseFollower_PermissionRelationFilter>;
  caseCaseViolationCodeRelation?: Maybe<CaseViolationCode_PermissionRelationFilter>;
  caseCommentRelation?: Maybe<CaseComment_PermissionRelationFilter>;
  caseDocumentRelation?: Maybe<CaseDocument_PermissionRelationFilter>;
  caseHistoryRelation?: Maybe<CaseHistory_PermissionRelationFilter>;
  caseID?: Maybe<StringPredicate>;
  caseLogRelation?: Maybe<CaseLog_PermissionRelationFilter>;
  caseStatusLogRelation?: Maybe<CaseStatusLog_PermissionRelationFilter>;
  citationArraignment?: Maybe<DatePredicate>;
  citationCourtNameAddress?: Maybe<StringPredicate>;
  citationDueDate?: Maybe<DatePredicate>;
  citationNumbers?: Maybe<StringPredicate>;
  citationStatus?: Maybe<StringPredicate>;
  code?: Maybe<IntPredicate>;
  crash?: Maybe<BoolPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  dataQsStatus?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<Driver_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  inspectionCitationState?: Maybe<UsState_PermissionFilter>;
  inspectionDate?: Maybe<DatePredicate>;
  inspectionReportNumber?: Maybe<StringPredicate>;
  notificationObjectRelation?: Maybe<NotificationObject_PermissionRelationFilter>;
  roadsideInspection?: Maybe<BoolPredicate>;
  sortScore?: Maybe<IntPredicate>;
  status?: Maybe<StringPredicate>;
  trafficCitation?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  updatedAtAll?: Maybe<DateTimePredicate>;
  updatedAtByAgent?: Maybe<DateTimePredicate>;
  updatedBy?: Maybe<StringPredicate>;
};

export type Case_PermissionRelationFilter = {
  every?: Maybe<Case_PermissionFilter>;
  none?: Maybe<Case_PermissionFilter>;
  some?: Maybe<Case_PermissionFilter>;
};

/** ChangePasswordInput */
export type ChangePasswordInput = {
  authProfileId: Scalars['ID'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type CiCdMigration = {
  __typename?: 'CiCdMigration';
  _description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CiCdMigrations create input */
export type CiCdMigrationCreateInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** CiCdMigrations create many input */
export type CiCdMigrationCreateManyInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** CiCdMigrations delete input */
export type CiCdMigrationDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CiCdMigrationFieldsPermissions create input */
export type CiCdMigrationFieldsPermissions = {
  code?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CiCdMigrationFilter = {
  AND?: Maybe<Array<CiCdMigrationFilter>>;
  OR?: Maybe<Array<CiCdMigrationFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CiCdMigrationGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CiCdMigrationGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CiCdMigrationGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CiCdMigrationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** CiCdMigrationListResponse output */
export type CiCdMigrationListResponse = {
  __typename?: 'CiCdMigrationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CiCdMigration>;
};

/** CiCdMigrationManyResponse output */
export type CiCdMigrationManyResponse = {
  __typename?: 'CiCdMigrationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CiCdMigration>;
};

/** No longer supported. Use `sort` instead. */
export enum CiCdMigrationOrderBy {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CiCdMigrations subscription payload */
export type CiCdMigrationPayload = {
  __typename?: 'CiCdMigrationPayload';
  mutation: MutationType;
  node?: Maybe<CiCdMigration>;
  previousValues?: Maybe<CiCdMigration>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CiCdMigrationSort = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CiCdMigrations subscription filter */
export type CiCdMigrationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CiCdMigrationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateByFilterInput = {
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** CiCdMigrations update input */
export type CiCdMigrationUpdateInput = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CiCdMigration_PermissionFilter = {
  AND?: Maybe<Array<CiCdMigration_PermissionFilter>>;
  OR?: Maybe<Array<CiCdMigration_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CitationCopy = {
  __typename?: 'CitationCopy';
  _description?: Maybe<Scalars['String']>;
  citation?: Maybe<File>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CitationCopy relation input */
export type CitationCopyCitationManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** CitationCopy relation input */
export type CitationCopyCitationRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CitationCopy_Citation_FileCreateInput>;
};

/** CitationCopy relation input */
export type CitationCopyCitationUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CitationCopy_Citation_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<CitationCopy_Citation_FileUpdateInput>;
};

/** CitationCopy create input */
export type CitationCopyCreateInput = {
  citation?: Maybe<CitationCopyCitationRelationInput>;
};

/** CitationCopy create many input */
export type CitationCopyCreateManyInput = {
  citation: CitationCopyCitationManyRelationInput;
};

/** CitationCopy delete input */
export type CitationCopyDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CitationCopyFieldsPermissions create input */
export type CitationCopyFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CitationCopyFilter = {
  AND?: Maybe<Array<CitationCopyFilter>>;
  OR?: Maybe<Array<CitationCopyFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  citation?: Maybe<FileFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CitationCopyGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CitationCopyGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CitationCopyGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  citation?: Maybe<FileGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CitationCopyKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CitationCopyListResponse output */
export type CitationCopyListResponse = {
  __typename?: 'CitationCopyListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CitationCopy>;
};

/** CitationCopyManyResponse output */
export type CitationCopyManyResponse = {
  __typename?: 'CitationCopyManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CitationCopy>;
};

/** No longer supported. Use `sort` instead. */
export enum CitationCopyOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CitationCopy subscription payload */
export type CitationCopyPayload = {
  __typename?: 'CitationCopyPayload';
  mutation: MutationType;
  node?: Maybe<CitationCopy>;
  previousValues?: Maybe<CitationCopy>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CitationCopyRelationFilter = {
  every?: Maybe<CitationCopyFilter>;
  none?: Maybe<CitationCopyFilter>;
  some?: Maybe<CitationCopyFilter>;
};

export type CitationCopySort = {
  citation?: Maybe<FileSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CitationCopy subscription filter */
export type CitationCopySubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CitationCopyFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CitationCopy update input */
export type CitationCopyUpdateInput = {
  citation?: Maybe<CitationCopyCitationUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

export type CitationCopy_PermissionFilter = {
  AND?: Maybe<Array<CitationCopy_PermissionFilter>>;
  OR?: Maybe<Array<CitationCopy_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  citation?: Maybe<File_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CitationCopy_PermissionRelationFilter = {
  every?: Maybe<CitationCopy_PermissionFilter>;
  none?: Maybe<CitationCopy_PermissionFilter>;
  some?: Maybe<CitationCopy_PermissionFilter>;
};

/** Files create input from citationCopy_citation */
export type CitationCopy_Citation_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from citationCopy_citation */
export type CitationCopy_Citation_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** CitationCopy create input from citation */
export type Citation_CitationCopyCreateInput = {
  citation?: Maybe<CitationCopyCitationRelationInput>;
};

/** CitationCopy update input from citation */
export type Citation_CitationCopyUpdateInput = {
  data: CitationCopyUpdateInput;
  filter?: Maybe<CitationCopyKeyFilter>;
};

/** Authentication Profile Attributes for Cognito */
export type CognitoAuthProfileAttributes = {
  __typename?: 'CognitoAuthProfileAttributes';
  clientAuthDomain?: Maybe<Scalars['String']>;
};

/** Computed field mode */
export enum ComputedFieldMode {
  Stored = 'STORED',
  Virtual = 'VIRTUAL',
}

/** Custom Table Field Type */
export type CustomTableField = {
  __typename?: 'CustomTableField';
  computedMode?: Maybe<ComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  _description?: Maybe<Scalars['String']>;
  address?: Maybe<AddressListResponse>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCasesRelation?: Maybe<CaseListResponse>;
  customerCustomerAlertRelation?: Maybe<CustomerAlertListResponse>;
  customerDocumentRelation?: Maybe<CustomerDocumentListResponse>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  driverCustomerRelation?: Maybe<DriverListResponse>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<NotificationObjectListResponse>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

export type CustomerAddressArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AddressGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AddressOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AddressSort>>;
};

export type CustomerCustomerCasesRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseSort>>;
};

export type CustomerCustomerCustomerAlertRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerAlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerAlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerAlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerAlertSort>>;
};

export type CustomerCustomerDocumentRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerDocumentSort>>;
};

export type CustomerDriverCustomerRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverSort>>;
};

export type CustomerNotificationObjectCustomerRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationObjectFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationObjectGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationObjectOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationObjectSort>>;
};

/** Customer relation input */
export type CustomerAddressManyRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
};

/** Customer relation input */
export type CustomerAddressRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_AddressCreateInput>>>;
};

/** Customer relation input */
export type CustomerAddressUpdateRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_AddressCreateInput>>>;
  disconnect?: Maybe<Array<AddressKeyFilter>>;
  reconnect?: Maybe<Array<AddressKeyFilter>>;
  update?: Maybe<Array<Maybe<Customer_AddressUpdateInput>>>;
};

export type CustomerAlert = {
  __typename?: 'CustomerAlert';
  _description?: Maybe<Scalars['String']>;
  alert?: Maybe<Alert>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CustomerAlert relation input */
export type CustomerAlertAlertManyRelationInput = {
  connect?: Maybe<AlertKeyFilter>;
};

/** CustomerAlert relation input */
export type CustomerAlertAlertRelationInput = {
  connect?: Maybe<AlertKeyFilter>;
  create?: Maybe<AlertCustomerAlertRelation_AlertCreateInput>;
};

/** CustomerAlert relation input */
export type CustomerAlertAlertUpdateRelationInput = {
  connect?: Maybe<AlertKeyFilter>;
  create?: Maybe<AlertCustomerAlertRelation_AlertCreateInput>;
  disconnect?: Maybe<AlertKeyFilter>;
  reconnect?: Maybe<AlertKeyFilter>;
  update?: Maybe<AlertCustomerAlertRelation_AlertUpdateInput>;
};

/** CustomerAlert create input */
export type CustomerAlertCreateInput = {
  alert?: Maybe<CustomerAlertAlertRelationInput>;
  customer?: Maybe<CustomerAlertCustomerRelationInput>;
};

/** CustomerAlert create many input */
export type CustomerAlertCreateManyInput = {
  alert: CustomerAlertAlertManyRelationInput;
  customer: CustomerAlertCustomerManyRelationInput;
};

/** CustomerAlert relation input */
export type CustomerAlertCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** CustomerAlert relation input */
export type CustomerAlertCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerCustomerAlertRelation_CustomerCreateInput>;
};

/** CustomerAlert relation input */
export type CustomerAlertCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerCustomerAlertRelation_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<CustomerCustomerAlertRelation_CustomerUpdateInput>;
};

/** CustomerAlert delete input */
export type CustomerAlertDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CustomerAlertFieldsPermissions create input */
export type CustomerAlertFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CustomerAlertFilter = {
  AND?: Maybe<Array<CustomerAlertFilter>>;
  OR?: Maybe<Array<CustomerAlertFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  alert?: Maybe<AlertFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CustomerAlertGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CustomerAlertGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CustomerAlertGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  alert?: Maybe<AlertGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CustomerAlertKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CustomerAlertListResponse output */
export type CustomerAlertListResponse = {
  __typename?: 'CustomerAlertListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CustomerAlert>;
};

/** CustomerAlertManyResponse output */
export type CustomerAlertManyResponse = {
  __typename?: 'CustomerAlertManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CustomerAlert>;
};

/** No longer supported. Use `sort` instead. */
export enum CustomerAlertOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CustomerAlert subscription payload */
export type CustomerAlertPayload = {
  __typename?: 'CustomerAlertPayload';
  mutation: MutationType;
  node?: Maybe<CustomerAlert>;
  previousValues?: Maybe<CustomerAlert>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerAlertRelationFilter = {
  every?: Maybe<CustomerAlertFilter>;
  none?: Maybe<CustomerAlertFilter>;
  some?: Maybe<CustomerAlertFilter>;
};

export type CustomerAlertSort = {
  alert?: Maybe<AlertSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CustomerAlert subscription filter */
export type CustomerAlertSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CustomerAlertFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CustomerAlert update input */
export type CustomerAlertUpdateInput = {
  alert?: Maybe<CustomerAlertAlertUpdateRelationInput>;
  customer?: Maybe<CustomerAlertCustomerUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerAlert_PermissionFilter = {
  AND?: Maybe<Array<CustomerAlert_PermissionFilter>>;
  OR?: Maybe<Array<CustomerAlert_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  alert?: Maybe<Alert_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CustomerAlert_PermissionRelationFilter = {
  every?: Maybe<CustomerAlert_PermissionFilter>;
  none?: Maybe<CustomerAlert_PermissionFilter>;
  some?: Maybe<CustomerAlert_PermissionFilter>;
};

/** Customer create input from customerCasesRelation */
export type CustomerCasesRelation_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from customerCasesRelation */
export type CustomerCasesRelation_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** Customer create input */
export type CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer create many input */
export type CustomerCreateManyInput = {
  address?: Maybe<CustomerAddressManyRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationManyRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationManyRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationManyRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationManyRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationManyRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserManyRelationInput>;
};

/** Customer create input from customerCustomerAlertRelation */
export type CustomerCustomerAlertRelation_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from customerCustomerAlertRelation */
export type CustomerCustomerAlertRelation_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** Customer relation input */
export type CustomerCustomerCasesRelationManyRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
};

/** Customer relation input */
export type CustomerCustomerCasesRelationRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_CaseCreateInput>>>;
};

/** Customer relation input */
export type CustomerCustomerCasesRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_CaseCreateInput>>>;
  disconnect?: Maybe<Array<CaseKeyFilter>>;
  reconnect?: Maybe<Array<CaseKeyFilter>>;
  update?: Maybe<Array<Maybe<Customer_CaseUpdateInput>>>;
};

/** Customer relation input */
export type CustomerCustomerCustomerAlertRelationManyRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
};

/** Customer relation input */
export type CustomerCustomerCustomerAlertRelationRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_CustomerAlertCreateInput>>>;
};

/** Customer relation input */
export type CustomerCustomerCustomerAlertRelationUpdateRelationInput = {
  connect?: Maybe<Array<CustomerAlertKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_CustomerAlertCreateInput>>>;
  disconnect?: Maybe<Array<CustomerAlertKeyFilter>>;
  reconnect?: Maybe<Array<CustomerAlertKeyFilter>>;
  update?: Maybe<Array<Maybe<Customer_CustomerAlertUpdateInput>>>;
};

/** Customer relation input */
export type CustomerCustomerDocumentRelationManyRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
};

/** Customer relation input */
export type CustomerCustomerDocumentRelationRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<CustomerRelation_CustomerDocumentCreateInput>>>;
};

/** Customer relation input */
export type CustomerCustomerDocumentRelationUpdateRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<CustomerRelation_CustomerDocumentCreateInput>>>;
  disconnect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  reconnect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  update?: Maybe<Array<Maybe<CustomerRelation_CustomerDocumentUpdateInput>>>;
};

/** Customer delete input */
export type CustomerDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerDocument = {
  __typename?: 'CustomerDocument';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerRelation?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** CustomerDocument create input */
export type CustomerDocumentCreateInput = {
  customerRelation?: Maybe<CustomerDocumentCustomerRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CustomerDocumentFileRelationInput>;
};

/** CustomerDocument create many input */
export type CustomerDocumentCreateManyInput = {
  customerRelation?: Maybe<CustomerDocumentCustomerRelationManyRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CustomerDocumentFileManyRelationInput>;
};

/** CustomerDocument relation input */
export type CustomerDocumentCustomerRelationManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** CustomerDocument relation input */
export type CustomerDocumentCustomerRelationRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerDocumentRelation_CustomerCreateInput>;
};

/** CustomerDocument relation input */
export type CustomerDocumentCustomerRelationUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<CustomerDocumentRelation_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<CustomerDocumentRelation_CustomerUpdateInput>;
};

/** CustomerDocument delete input */
export type CustomerDocumentDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** CustomerDocumentFieldsPermissions create input */
export type CustomerDocumentFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

/** CustomerDocument relation input */
export type CustomerDocumentFileManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** CustomerDocument relation input */
export type CustomerDocumentFileRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CustomerDocument_File_FileCreateInput>;
};

/** CustomerDocument relation input */
export type CustomerDocumentFileUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<CustomerDocument_File_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<CustomerDocument_File_FileUpdateInput>;
};

export type CustomerDocumentFilter = {
  AND?: Maybe<Array<CustomerDocumentFilter>>;
  OR?: Maybe<Array<CustomerDocumentFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customerRelation?: Maybe<CustomerFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  file?: Maybe<FileFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CustomerDocumentGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CustomerDocumentGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CustomerDocumentGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customerRelation?: Maybe<CustomerGroupByQuery>;
  description?: Maybe<Array<GroupByField>>;
  file?: Maybe<FileGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type CustomerDocumentKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** CustomerDocumentListResponse output */
export type CustomerDocumentListResponse = {
  __typename?: 'CustomerDocumentListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<CustomerDocument>;
};

/** CustomerDocumentManyResponse output */
export type CustomerDocumentManyResponse = {
  __typename?: 'CustomerDocumentManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<CustomerDocument>;
};

/** No longer supported. Use `sort` instead. */
export enum CustomerDocumentOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** CustomerDocument subscription payload */
export type CustomerDocumentPayload = {
  __typename?: 'CustomerDocumentPayload';
  mutation: MutationType;
  node?: Maybe<CustomerDocument>;
  previousValues?: Maybe<CustomerDocument>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerDocumentRelationFilter = {
  every?: Maybe<CustomerDocumentFilter>;
  none?: Maybe<CustomerDocumentFilter>;
  some?: Maybe<CustomerDocumentFilter>;
};

/** Customer create input from customerDocumentRelation */
export type CustomerDocumentRelation_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from customerDocumentRelation */
export type CustomerDocumentRelation_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

export type CustomerDocumentSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customerRelation?: Maybe<CustomerSort>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  file?: Maybe<FileSort>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** CustomerDocument subscription filter */
export type CustomerDocumentSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CustomerDocumentFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** CustomerDocument update input */
export type CustomerDocumentUpdateByFilterInput = {
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** CustomerDocument update input */
export type CustomerDocumentUpdateInput = {
  customerRelation?: Maybe<CustomerDocumentCustomerRelationUpdateRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CustomerDocumentFileUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerDocument_PermissionFilter = {
  AND?: Maybe<Array<CustomerDocument_PermissionFilter>>;
  OR?: Maybe<Array<CustomerDocument_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customerRelation?: Maybe<Customer_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  file?: Maybe<File_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type CustomerDocument_PermissionRelationFilter = {
  every?: Maybe<CustomerDocument_PermissionFilter>;
  none?: Maybe<CustomerDocument_PermissionFilter>;
  some?: Maybe<CustomerDocument_PermissionFilter>;
};

/** Files create input from customerDocument_file */
export type CustomerDocument_File_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from customerDocument_file */
export type CustomerDocument_File_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Customer relation input */
export type CustomerDriverCustomerRelationManyRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
};

/** Customer relation input */
export type CustomerDriverCustomerRelationRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_DriverCreateInput>>>;
};

/** Customer relation input */
export type CustomerDriverCustomerRelationUpdateRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_DriverCreateInput>>>;
  disconnect?: Maybe<Array<DriverKeyFilter>>;
  reconnect?: Maybe<Array<DriverKeyFilter>>;
  update?: Maybe<Array<Maybe<Customer_DriverUpdateInput>>>;
};

/** CustomerFieldsPermissions create input */
export type CustomerFieldsPermissions = {
  archived?: Maybe<Scalars['Boolean']>;
  contactName?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  customerID?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['Boolean']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type CustomerFilter = {
  AND?: Maybe<Array<CustomerFilter>>;
  OR?: Maybe<Array<CustomerFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  address?: Maybe<AddressRelationFilter>;
  archived?: Maybe<DateTimePredicate>;
  contactName?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customerCasesRelation?: Maybe<CaseRelationFilter>;
  customerCustomerAlertRelation?: Maybe<CustomerAlertRelationFilter>;
  customerDocumentRelation?: Maybe<CustomerDocumentRelationFilter>;
  customerID?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driverCustomerRelation?: Maybe<DriverRelationFilter>;
  email?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  isCustomerDriver?: Maybe<BoolPredicate>;
  name?: Maybe<StringPredicate>;
  notificationObjectCustomerRelation?: Maybe<NotificationObjectRelationFilter>;
  phoneNumber?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  subscription?: Maybe<StringPredicate>;
  subscriptionUpgradeRequestDate?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<UserFilter>;
};

export type CustomerGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: CustomerGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type CustomerGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  address?: Maybe<AddressGroupByQuery>;
  archived?: Maybe<Array<GroupByField>>;
  contactName?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customerCasesRelation?: Maybe<CaseGroupByQuery>;
  customerCustomerAlertRelation?: Maybe<CustomerAlertGroupByQuery>;
  customerDocumentRelation?: Maybe<CustomerDocumentGroupByQuery>;
  customerID?: Maybe<Array<GroupByField>>;
  driverCustomerRelation?: Maybe<DriverGroupByQuery>;
  email?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  isCustomerDriver?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  notificationObjectCustomerRelation?: Maybe<NotificationObjectGroupByQuery>;
  phoneNumber?: Maybe<Array<GroupByField>>;
  status?: Maybe<Array<GroupByField>>;
  subscription?: Maybe<Array<GroupByField>>;
  subscriptionUpgradeRequestDate?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  user?: Maybe<UserGroupByQuery>;
};

export type CustomerKeyFilter = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** CustomerListResponse output */
export type CustomerListResponse = {
  __typename?: 'CustomerListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Customer>;
};

/** CustomerManyResponse output */
export type CustomerManyResponse = {
  __typename?: 'CustomerManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Customer>;
};

/** Customer relation input */
export type CustomerNotificationObjectCustomerRelationManyRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
};

/** Customer relation input */
export type CustomerNotificationObjectCustomerRelationRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_NotificationObjectCreateInput>>>;
};

/** Customer relation input */
export type CustomerNotificationObjectCustomerRelationUpdateRelationInput = {
  connect?: Maybe<Array<NotificationObjectKeyFilter>>;
  create?: Maybe<Array<Maybe<Customer_NotificationObjectCreateInput>>>;
  disconnect?: Maybe<Array<NotificationObjectKeyFilter>>;
  reconnect?: Maybe<Array<NotificationObjectKeyFilter>>;
  update?: Maybe<Array<Maybe<Customer_NotificationObjectUpdateInput>>>;
};

/** No longer supported. Use `sort` instead. */
export enum CustomerOrderBy {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerIdAsc = 'customerID_ASC',
  CustomerIdDesc = 'customerID_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsCustomerDriverAsc = 'isCustomerDriver_ASC',
  IsCustomerDriverDesc = 'isCustomerDriver_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SubscriptionUpgradeRequestDateAsc = 'subscriptionUpgradeRequestDate_ASC',
  SubscriptionUpgradeRequestDateDesc = 'subscriptionUpgradeRequestDate_DESC',
  SubscriptionAsc = 'subscription_ASC',
  SubscriptionDesc = 'subscription_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Customer subscription payload */
export type CustomerPayload = {
  __typename?: 'CustomerPayload';
  mutation: MutationType;
  node?: Maybe<Customer>;
  previousValues?: Maybe<Customer>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** CustomerDocument create input from customerRelation */
export type CustomerRelation_CustomerDocumentCreateInput = {
  customerRelation?: Maybe<CustomerDocumentCustomerRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CustomerDocumentFileRelationInput>;
};

/** CustomerDocument update input from customerRelation */
export type CustomerRelation_CustomerDocumentUpdateInput = {
  data: CustomerDocumentUpdateInput;
  filter?: Maybe<CustomerDocumentKeyFilter>;
};

export type CustomerSort = {
  archived?: Maybe<SortOrder>;
  contactName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customerID?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isCustomerDriver?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subscription?: Maybe<SortOrder>;
  subscriptionUpgradeRequestDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserSort>;
};

/** Customer subscription filter */
export type CustomerSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<CustomerFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Customer update input */
export type CustomerUpdateByFilterInput = {
  archived?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  contactName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  customerID?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  email?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isCustomerDriver?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  phoneNumber?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  subscription?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  subscriptionUpgradeRequestDate?: Maybe<
    Array<Maybe<UpdateByFilterDateTimeInput>>
  >;
};

/** Customer update input */
export type CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** Customer relation input */
export type CustomerUserManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** Customer relation input */
export type CustomerUserRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<Customer_UserCreateInput>;
};

/** Customer relation input */
export type CustomerUserUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<Customer_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<Customer_UserUpdateInput>;
};

/** Address create input from customer */
export type Customer_AddressCreateInput = {
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<AddressCustomerRelationInput>;
  state: AddressStateRelationInput;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Address update input from customer */
export type Customer_AddressUpdateInput = {
  data: AddressUpdateInput;
  filter?: Maybe<AddressKeyFilter>;
};

/** Case create input from customer */
export type Customer_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver: CaseDriverRelationInput;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from customer */
export type Customer_CaseUpdateInput = {
  data: CaseUpdateInput;
  filter?: Maybe<CaseKeyFilter>;
};

/** CustomerAlert create input from customer */
export type Customer_CustomerAlertCreateInput = {
  alert: CustomerAlertAlertRelationInput;
  customer?: Maybe<CustomerAlertCustomerRelationInput>;
};

/** CustomerAlert update input from customer */
export type Customer_CustomerAlertUpdateInput = {
  data: CustomerAlertUpdateInput;
  filter?: Maybe<CustomerAlertKeyFilter>;
};

/** Driver create input from customer */
export type Customer_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer?: Maybe<DriverCustomerRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from customer */
export type Customer_DriverUpdateInput = {
  data: DriverUpdateInput;
  filter?: Maybe<DriverKeyFilter>;
};

/** NotificationObject create input from customer */
export type Customer_NotificationObjectCreateInput = {
  case?: Maybe<NotificationObjectCaseRelationInput>;
  customer?: Maybe<NotificationObjectCustomerRelationInput>;
  notification?: Maybe<NotificationObjectNotificationRelationInput>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** NotificationObject update input from customer */
export type Customer_NotificationObjectUpdateInput = {
  data: NotificationObjectUpdateInput;
  filter?: Maybe<NotificationObjectKeyFilter>;
};

export type Customer_PermissionFilter = {
  AND?: Maybe<Array<Customer_PermissionFilter>>;
  OR?: Maybe<Array<Customer_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  address?: Maybe<Address_PermissionRelationFilter>;
  archived?: Maybe<DateTimePredicate>;
  contactName?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customerCasesRelation?: Maybe<Case_PermissionRelationFilter>;
  customerCustomerAlertRelation?: Maybe<CustomerAlert_PermissionRelationFilter>;
  customerDocumentRelation?: Maybe<CustomerDocument_PermissionRelationFilter>;
  customerID?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driverCustomerRelation?: Maybe<Driver_PermissionRelationFilter>;
  email?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  isCustomerDriver?: Maybe<BoolPredicate>;
  name?: Maybe<StringPredicate>;
  notificationObjectCustomerRelation?: Maybe<NotificationObject_PermissionRelationFilter>;
  phoneNumber?: Maybe<StringPredicate>;
  status?: Maybe<StringPredicate>;
  subscription?: Maybe<StringPredicate>;
  subscriptionUpgradeRequestDate?: Maybe<DateTimePredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<User_PermissionFilter>;
};

/** Users create input from customer */
export type Customer_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from customer */
export type Customer_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Date Field Attributes */
export type DateFieldTypeAttributes = {
  __typename?: 'DateFieldTypeAttributes';
  format: Scalars['String'];
};

export enum DatePartFunctionType {
  Date = 'DATE',
  Day = 'DAY',
  DayName = 'DAY_NAME',
  DayOfMonth = 'DAY_OF_MONTH',
  DayOfWeek = 'DAY_OF_WEEK',
  DayOfYear = 'DAY_OF_YEAR',
  Hour = 'HOUR',
  LastDay = 'LAST_DAY',
  Microsecond = 'MICROSECOND',
  Minute = 'MINUTE',
  Month = 'MONTH',
  MonthName = 'MONTH_NAME',
  Quarter = 'QUARTER',
  Second = 'SECOND',
  Time = 'TIME',
  Week = 'WEEK',
  WeekDay = 'WEEK_DAY',
  WeekOfYear = 'WEEK_OF_YEAR',
  Year = 'YEAR',
  YearWeek = 'YEAR_WEEK',
}

export type DatePredicate = {
  equals?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  not_equals?: Maybe<Scalars['Date']>;
  not_in?: Maybe<Array<Scalars['Date']>>;
  relative?: Maybe<DateRelativePredicates>;
};

export type DatePredicateHaving = {
  AND?: Maybe<Array<DatePredicateHaving>>;
  OR?: Maybe<Array<DatePredicateHaving>>;
  equals?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  not_equals?: Maybe<Scalars['Date']>;
  not_in?: Maybe<Array<Scalars['Date']>>;
};

export type DateRelativePredicateType = {
  op?: Maybe<RelativePredicateOpEnum>;
  unit?: Maybe<RelativePredicateUnitEnum>;
  value: Scalars['String'];
};

export type DateRelativePredicates = {
  gt?: Maybe<DateRelativePredicateType>;
  gte?: Maybe<DateRelativePredicateType>;
  lt?: Maybe<DateRelativePredicateType>;
  lte?: Maybe<DateRelativePredicateType>;
};

export type DateTimePredicate = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not_equals?: Maybe<Scalars['DateTime']>;
  not_in?: Maybe<Array<Scalars['DateTime']>>;
  relative?: Maybe<DateRelativePredicates>;
};

export type DateTimePredicateHaving = {
  AND?: Maybe<Array<DateTimePredicateHaving>>;
  OR?: Maybe<Array<DateTimePredicateHaving>>;
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not_equals?: Maybe<Scalars['DateTime']>;
  not_in?: Maybe<Array<Scalars['DateTime']>>;
};

/** Date Type Format Enum */
export enum DateTypeFormatEnum {
  Date = 'DATE',
  Datetime = 'DATETIME',
}

/** DeployDataResponse */
export type DeployDataResponse = {
  __typename?: 'DeployDataResponse';
  buildName: Scalars['String'];
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
};

export enum DeployModeEnum {
  Full = 'FULL',
  Functions = 'FUNCTIONS',
  Migrations = 'MIGRATIONS',
  OnlyPlugins = 'ONLY_PLUGINS',
  OnlyProject = 'ONLY_PROJECT',
}

/** DeployOptions */
export type DeployOptions = {
  extensionNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  mode?: Maybe<DeployModeEnum>;
  nodeVersion?: Maybe<Scalars['String']>;
  pluginNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum DeployStatusEnum {
  Compiling = 'compiling',
  CompleteError = 'complete_error',
  CompleteSuccess = 'complete_success',
  Deploying = 'deploying',
  Initialize = 'initialize',
  Preparing = 'preparing',
}

/** DeployStatusResult */
export type DeployStatusResult = {
  __typename?: 'DeployStatusResult';
  message?: Maybe<Scalars['String']>;
  status: DeployStatusEnum;
};

/** DeployingBuildInput */
export type DeployingBuildInput = {
  buildName: Scalars['String'];
  options?: Maybe<DeployOptions>;
};

export type DeploymentAbItemResponse = {
  __typename?: 'DeploymentABItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  userDeploy?: Maybe<SystemMemberAccountInfo>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
};

export type DocumentDraft = {
  __typename?: 'DocumentDraft';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
  documentTitle?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  editorState?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  signers?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** DocumentDraft create input */
export type DocumentDraftCreateInput = {
  customer?: Maybe<Scalars['String']>;
  documentTitle?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  editorState?: Maybe<Scalars['JSON']>;
  signers?: Maybe<Scalars['JSON']>;
};

/** DocumentDraft create many input */
export type DocumentDraftCreateManyInput = {
  customer?: Maybe<Scalars['String']>;
  documentTitle?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  editorState?: Maybe<Scalars['JSON']>;
  signers?: Maybe<Scalars['JSON']>;
};

/** DocumentDraft delete input */
export type DocumentDraftDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** DocumentDraftFieldsPermissions create input */
export type DocumentDraftFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<Scalars['Boolean']>;
  documentTitle?: Maybe<Scalars['Boolean']>;
  driver?: Maybe<Scalars['Boolean']>;
  editorState?: Maybe<Scalars['Boolean']>;
  signers?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type DocumentDraftFilter = {
  AND?: Maybe<Array<DocumentDraftFilter>>;
  OR?: Maybe<Array<DocumentDraftFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  documentTitle?: Maybe<StringPredicate>;
  driver?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DocumentDraftGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: DocumentDraftGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type DocumentDraftGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<Array<GroupByField>>;
  documentTitle?: Maybe<Array<GroupByField>>;
  driver?: Maybe<Array<GroupByField>>;
  editorState?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  signers?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type DocumentDraftKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** DocumentDraftListResponse output */
export type DocumentDraftListResponse = {
  __typename?: 'DocumentDraftListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<DocumentDraft>;
};

/** DocumentDraftManyResponse output */
export type DocumentDraftManyResponse = {
  __typename?: 'DocumentDraftManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<DocumentDraft>;
};

/** No longer supported. Use `sort` instead. */
export enum DocumentDraftOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerAsc = 'customer_ASC',
  CustomerDesc = 'customer_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DocumentTitleAsc = 'documentTitle_ASC',
  DocumentTitleDesc = 'documentTitle_DESC',
  DriverAsc = 'driver_ASC',
  DriverDesc = 'driver_DESC',
  EditorStateAsc = 'editorState_ASC',
  EditorStateDesc = 'editorState_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SignersAsc = 'signers_ASC',
  SignersDesc = 'signers_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** DocumentDraft subscription payload */
export type DocumentDraftPayload = {
  __typename?: 'DocumentDraftPayload';
  mutation: MutationType;
  node?: Maybe<DocumentDraft>;
  previousValues?: Maybe<DocumentDraft>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentDraftSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  documentTitle?: Maybe<SortOrder>;
  driver?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** DocumentDraft subscription filter */
export type DocumentDraftSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DocumentDraftFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** DocumentDraft update input */
export type DocumentDraftUpdateByFilterInput = {
  customer?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  documentTitle?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  driver?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  editorState?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  signers?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** DocumentDraft update input */
export type DocumentDraftUpdateInput = {
  customer?: Maybe<Scalars['String']>;
  documentTitle?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['String']>;
  editorState?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  signers?: Maybe<Scalars['JSON']>;
};

export type DocumentDraft_PermissionFilter = {
  AND?: Maybe<Array<DocumentDraft_PermissionFilter>>;
  OR?: Maybe<Array<DocumentDraft_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  documentTitle?: Maybe<StringPredicate>;
  driver?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type Driver = {
  __typename?: 'Driver';
  _description?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<CaseListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Int']>;
  driverEverSignDocRelation?: Maybe<EverSignDocListResponse>;
  driverLicenseRelation?: Maybe<DriverLicenseListResponse>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<MvrLogListResponse>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhone>;
  preferredLanguage?: Maybe<DriverLanguage>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverCaseDriverRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseSort>>;
};

export type DriverDriverEverSignDocRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EverSignDocFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EverSignDocGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EverSignDocOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EverSignDocSort>>;
};

export type DriverDriverLicenseRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLicenseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLicenseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLicenseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLicenseSort>>;
};

export type DriverMvrLogRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MvrLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MvrLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<MvrLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MvrLogSort>>;
};

/** Driver relation input */
export type DriverCaseDriverRelationManyRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
};

/** Driver relation input */
export type DriverCaseDriverRelationRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_CaseCreateInput>>>;
};

/** Driver relation input */
export type DriverCaseDriverRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_CaseCreateInput>>>;
  disconnect?: Maybe<Array<CaseKeyFilter>>;
  reconnect?: Maybe<Array<CaseKeyFilter>>;
  update?: Maybe<Array<Maybe<Driver_CaseUpdateInput>>>;
};

/** Driver create input */
export type DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer?: Maybe<DriverCustomerRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver create many input */
export type DriverCreateManyInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationManyRelationInput>;
  customer: DriverCustomerManyRelationInput;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationManyRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationManyRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationManyRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage: DriverPreferredLanguageManyRelationInput;
  status?: Maybe<Scalars['String']>;
};

/** Driver relation input */
export type DriverCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** Driver relation input */
export type DriverCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<DriverCustomerRelation_CustomerCreateInput>;
};

/** Customer create input from driverCustomerRelation */
export type DriverCustomerRelation_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from driverCustomerRelation */
export type DriverCustomerRelation_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** Driver relation input */
export type DriverCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<DriverCustomerRelation_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<DriverCustomerRelation_CustomerUpdateInput>;
};

/** Driver delete input */
export type DriverDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** Driver relation input */
export type DriverDriverEverSignDocRelationManyRelationInput = {
  connect?: Maybe<Array<EverSignDocKeyFilter>>;
};

/** Driver relation input */
export type DriverDriverEverSignDocRelationRelationInput = {
  connect?: Maybe<Array<EverSignDocKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_EverSignDocCreateInput>>>;
};

/** Driver relation input */
export type DriverDriverEverSignDocRelationUpdateRelationInput = {
  connect?: Maybe<Array<EverSignDocKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_EverSignDocCreateInput>>>;
  disconnect?: Maybe<Array<EverSignDocKeyFilter>>;
  reconnect?: Maybe<Array<EverSignDocKeyFilter>>;
  update?: Maybe<Array<Maybe<Driver_EverSignDocUpdateInput>>>;
};

/** Driver relation input */
export type DriverDriverLicenseRelationManyRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
};

/** Driver relation input */
export type DriverDriverLicenseRelationRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
  create?: Maybe<Array<Maybe<DriverRelation_DriverLicenseCreateInput>>>;
};

/** Driver relation input */
export type DriverDriverLicenseRelationUpdateRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
  create?: Maybe<Array<Maybe<DriverRelation_DriverLicenseCreateInput>>>;
  disconnect?: Maybe<Array<DriverLicenseKeyFilter>>;
  reconnect?: Maybe<Array<DriverLicenseKeyFilter>>;
  update?: Maybe<Array<Maybe<DriverRelation_DriverLicenseUpdateInput>>>;
};

/** Driver create input from driverEverSignDocRelation */
export type DriverEverSignDocRelation_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer?: Maybe<DriverCustomerRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from driverEverSignDocRelation */
export type DriverEverSignDocRelation_DriverUpdateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationUpdateRelationInput>;
  customer?: Maybe<DriverCustomerUpdateRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationUpdateRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** DriverFieldsPermissions create input */
export type DriverFieldsPermissions = {
  archived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  dateOfBirth?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['Boolean']>;
  licenseState?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type DriverFilter = {
  AND?: Maybe<Array<DriverFilter>>;
  OR?: Maybe<Array<DriverFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  archived?: Maybe<DateTimePredicate>;
  caseDriverRelation?: Maybe<CaseRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  dateOfBirth?: Maybe<DatePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driverEverSignDocRelation?: Maybe<EverSignDocRelationFilter>;
  driverLicenseRelation?: Maybe<DriverLicenseRelationFilter>;
  email?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  lastName?: Maybe<StringPredicate>;
  licenseNumber?: Maybe<StringPredicate>;
  licenseState?: Maybe<StringPredicate>;
  mvrLogRelation?: Maybe<MvrLogRelationFilter>;
  name?: Maybe<StringPredicate>;
  phoneNumber?: Maybe<SmartPhonePredicate>;
  preferredLanguage?: Maybe<DriverLanguageFilter>;
  status?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DriverGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: DriverGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type DriverGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  archived?: Maybe<Array<GroupByField>>;
  caseDriverRelation?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  dateOfBirth?: Maybe<Array<GroupByField>>;
  driverEverSignDocRelation?: Maybe<EverSignDocGroupByQuery>;
  driverLicenseRelation?: Maybe<DriverLicenseGroupByQuery>;
  email?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  lastName?: Maybe<Array<GroupByField>>;
  licenseNumber?: Maybe<Array<GroupByField>>;
  licenseState?: Maybe<Array<GroupByField>>;
  mvrLogRelation?: Maybe<MvrLogGroupByQuery>;
  name?: Maybe<Array<GroupByField>>;
  phoneNumber?: Maybe<SmartPhoneGroupByQuery>;
  preferredLanguage?: Maybe<DriverLanguageGroupByQuery>;
  status?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type DriverKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

export type DriverLanguage = {
  __typename?: 'DriverLanguage';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  driverPreferredLanguageRelation?: Maybe<DriverListResponse>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DriverLanguageDriverPreferredLanguageRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverSort>>;
};

/** DriverLanguage create input */
export type DriverLanguageCreateInput = {
  driverPreferredLanguageRelation?: Maybe<DriverLanguageDriverPreferredLanguageRelationRelationInput>;
  name: Scalars['String'];
};

/** DriverLanguage create many input */
export type DriverLanguageCreateManyInput = {
  driverPreferredLanguageRelation?: Maybe<DriverLanguageDriverPreferredLanguageRelationManyRelationInput>;
  name: Scalars['String'];
};

/** DriverLanguage delete input */
export type DriverLanguageDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** DriverLanguage relation input */
export type DriverLanguageDriverPreferredLanguageRelationManyRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
};

/** DriverLanguage relation input */
export type DriverLanguageDriverPreferredLanguageRelationRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
  create?: Maybe<Array<Maybe<PreferredLanguage_DriverCreateInput>>>;
};

/** DriverLanguage relation input */
export type DriverLanguageDriverPreferredLanguageRelationUpdateRelationInput = {
  connect?: Maybe<Array<DriverKeyFilter>>;
  create?: Maybe<Array<Maybe<PreferredLanguage_DriverCreateInput>>>;
  disconnect?: Maybe<Array<DriverKeyFilter>>;
  reconnect?: Maybe<Array<DriverKeyFilter>>;
  update?: Maybe<Array<Maybe<PreferredLanguage_DriverUpdateInput>>>;
};

/** DriverLanguageFieldsPermissions create input */
export type DriverLanguageFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type DriverLanguageFilter = {
  AND?: Maybe<Array<DriverLanguageFilter>>;
  OR?: Maybe<Array<DriverLanguageFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driverPreferredLanguageRelation?: Maybe<DriverRelationFilter>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DriverLanguageGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: DriverLanguageGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type DriverLanguageGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  driverPreferredLanguageRelation?: Maybe<DriverGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type DriverLanguageKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** DriverLanguageListResponse output */
export type DriverLanguageListResponse = {
  __typename?: 'DriverLanguageListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<DriverLanguage>;
};

/** DriverLanguageManyResponse output */
export type DriverLanguageManyResponse = {
  __typename?: 'DriverLanguageManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<DriverLanguage>;
};

/** No longer supported. Use `sort` instead. */
export enum DriverLanguageOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** DriverLanguage subscription payload */
export type DriverLanguagePayload = {
  __typename?: 'DriverLanguagePayload';
  mutation: MutationType;
  node?: Maybe<DriverLanguage>;
  previousValues?: Maybe<DriverLanguage>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DriverLanguageSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** DriverLanguage subscription filter */
export type DriverLanguageSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DriverLanguageFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** DriverLanguage update input */
export type DriverLanguageUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** DriverLanguage update input */
export type DriverLanguageUpdateInput = {
  driverPreferredLanguageRelation?: Maybe<DriverLanguageDriverPreferredLanguageRelationUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type DriverLanguage_PermissionFilter = {
  AND?: Maybe<Array<DriverLanguage_PermissionFilter>>;
  OR?: Maybe<Array<DriverLanguage_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driverPreferredLanguageRelation?: Maybe<Driver_PermissionRelationFilter>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DriverLicense = {
  __typename?: 'DriverLicense';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  driverRelation?: Maybe<Driver>;
  file?: Maybe<File>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** DriverLicense create input */
export type DriverLicenseCreateInput = {
  driverRelation?: Maybe<DriverLicenseDriverRelationRelationInput>;
  file?: Maybe<DriverLicenseFileRelationInput>;
};

/** DriverLicense create many input */
export type DriverLicenseCreateManyInput = {
  driverRelation: DriverLicenseDriverRelationManyRelationInput;
  file: DriverLicenseFileManyRelationInput;
};

/** DriverLicense delete input */
export type DriverLicenseDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** DriverLicense relation input */
export type DriverLicenseDriverRelationManyRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
};

/** DriverLicense relation input */
export type DriverLicenseDriverRelationRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<DriverLicenseRelation_DriverCreateInput>;
};

/** DriverLicense relation input */
export type DriverLicenseDriverRelationUpdateRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<DriverLicenseRelation_DriverCreateInput>;
  disconnect?: Maybe<DriverKeyFilter>;
  reconnect?: Maybe<DriverKeyFilter>;
  update?: Maybe<DriverLicenseRelation_DriverUpdateInput>;
};

/** DriverLicenseFieldsPermissions create input */
export type DriverLicenseFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

/** DriverLicense relation input */
export type DriverLicenseFileManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** DriverLicense relation input */
export type DriverLicenseFileRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<DriverLicense_File_FileCreateInput>;
};

/** DriverLicense relation input */
export type DriverLicenseFileUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<DriverLicense_File_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<DriverLicense_File_FileUpdateInput>;
};

export type DriverLicenseFilter = {
  AND?: Maybe<Array<DriverLicenseFilter>>;
  OR?: Maybe<Array<DriverLicenseFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driverRelation?: Maybe<DriverFilter>;
  file?: Maybe<FileFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DriverLicenseGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: DriverLicenseGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type DriverLicenseGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  driverRelation?: Maybe<DriverGroupByQuery>;
  file?: Maybe<FileGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type DriverLicenseKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** DriverLicenseListResponse output */
export type DriverLicenseListResponse = {
  __typename?: 'DriverLicenseListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<DriverLicense>;
};

/** DriverLicenseManyResponse output */
export type DriverLicenseManyResponse = {
  __typename?: 'DriverLicenseManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<DriverLicense>;
};

/** No longer supported. Use `sort` instead. */
export enum DriverLicenseOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** DriverLicense subscription payload */
export type DriverLicensePayload = {
  __typename?: 'DriverLicensePayload';
  mutation: MutationType;
  node?: Maybe<DriverLicense>;
  previousValues?: Maybe<DriverLicense>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DriverLicenseRelationFilter = {
  every?: Maybe<DriverLicenseFilter>;
  none?: Maybe<DriverLicenseFilter>;
  some?: Maybe<DriverLicenseFilter>;
};

/** Driver create input from driverLicenseRelation */
export type DriverLicenseRelation_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer?: Maybe<DriverCustomerRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from driverLicenseRelation */
export type DriverLicenseRelation_DriverUpdateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationUpdateRelationInput>;
  customer?: Maybe<DriverCustomerUpdateRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationUpdateRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
};

export type DriverLicenseSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  driverRelation?: Maybe<DriverSort>;
  file?: Maybe<FileSort>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** DriverLicense subscription filter */
export type DriverLicenseSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DriverLicenseFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** DriverLicense update input */
export type DriverLicenseUpdateInput = {
  driverRelation?: Maybe<DriverLicenseDriverRelationUpdateRelationInput>;
  file?: Maybe<DriverLicenseFileUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

export type DriverLicense_PermissionFilter = {
  AND?: Maybe<Array<DriverLicense_PermissionFilter>>;
  OR?: Maybe<Array<DriverLicense_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driverRelation?: Maybe<Driver_PermissionFilter>;
  file?: Maybe<File_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type DriverLicense_PermissionRelationFilter = {
  every?: Maybe<DriverLicense_PermissionFilter>;
  none?: Maybe<DriverLicense_PermissionFilter>;
  some?: Maybe<DriverLicense_PermissionFilter>;
};

/** Files create input from driverLicense_file */
export type DriverLicense_File_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from driverLicense_file */
export type DriverLicense_File_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** DriverListResponse output */
export type DriverListResponse = {
  __typename?: 'DriverListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Driver>;
};

/** DriverManyResponse output */
export type DriverManyResponse = {
  __typename?: 'DriverManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Driver>;
};

/** Driver relation input */
export type DriverMvrLogRelationManyRelationInput = {
  connect?: Maybe<Array<MvrLogKeyFilter>>;
};

/** Driver relation input */
export type DriverMvrLogRelationRelationInput = {
  connect?: Maybe<Array<MvrLogKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_MvrLogCreateInput>>>;
};

/** Driver relation input */
export type DriverMvrLogRelationUpdateRelationInput = {
  connect?: Maybe<Array<MvrLogKeyFilter>>;
  create?: Maybe<Array<Maybe<Driver_MvrLogCreateInput>>>;
  disconnect?: Maybe<Array<MvrLogKeyFilter>>;
  reconnect?: Maybe<Array<MvrLogKeyFilter>>;
  update?: Maybe<Array<Maybe<Driver_MvrLogUpdateInput>>>;
};

/** No longer supported. Use `sort` instead. */
export enum DriverOrderBy {
  ArchivedAsc = 'archived_ASC',
  ArchivedDesc = 'archived_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateOfBirthAsc = 'dateOfBirth_ASC',
  DateOfBirthDesc = 'dateOfBirth_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  LicenseNumberAsc = 'licenseNumber_ASC',
  LicenseNumberDesc = 'licenseNumber_DESC',
  LicenseStateAsc = 'licenseState_ASC',
  LicenseStateDesc = 'licenseState_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberCodeAsc = 'phoneNumber_code_ASC',
  PhoneNumberCodeDesc = 'phoneNumber_code_DESC',
  PhoneNumberNumberAsc = 'phoneNumber_number_ASC',
  PhoneNumberNumberDesc = 'phoneNumber_number_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Driver subscription payload */
export type DriverPayload = {
  __typename?: 'DriverPayload';
  mutation: MutationType;
  node?: Maybe<Driver>;
  previousValues?: Maybe<Driver>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Driver relation input */
export type DriverPreferredLanguageManyRelationInput = {
  connect?: Maybe<DriverLanguageKeyFilter>;
};

/** Driver relation input */
export type DriverPreferredLanguageRelationInput = {
  connect?: Maybe<DriverLanguageKeyFilter>;
  create?: Maybe<DriverPreferredLanguageRelation_DriverLanguageCreateInput>;
};

/** DriverLanguage create input from driverPreferredLanguageRelation */
export type DriverPreferredLanguageRelation_DriverLanguageCreateInput = {
  driverPreferredLanguageRelation?: Maybe<DriverLanguageDriverPreferredLanguageRelationRelationInput>;
  name: Scalars['String'];
};

/** DriverLanguage update input from driverPreferredLanguageRelation */
export type DriverPreferredLanguageRelation_DriverLanguageUpdateInput = {
  driverPreferredLanguageRelation?: Maybe<DriverLanguageDriverPreferredLanguageRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
};

/** Driver relation input */
export type DriverPreferredLanguageUpdateRelationInput = {
  connect?: Maybe<DriverLanguageKeyFilter>;
  create?: Maybe<DriverPreferredLanguageRelation_DriverLanguageCreateInput>;
  disconnect?: Maybe<DriverLanguageKeyFilter>;
  reconnect?: Maybe<DriverLanguageKeyFilter>;
  update?: Maybe<DriverPreferredLanguageRelation_DriverLanguageUpdateInput>;
};

export type DriverRelationFilter = {
  every?: Maybe<DriverFilter>;
  none?: Maybe<DriverFilter>;
  some?: Maybe<DriverFilter>;
};

/** DriverLicense create input from driverRelation */
export type DriverRelation_DriverLicenseCreateInput = {
  driverRelation?: Maybe<DriverLicenseDriverRelationRelationInput>;
  file?: Maybe<DriverLicenseFileRelationInput>;
};

/** DriverLicense update input from driverRelation */
export type DriverRelation_DriverLicenseUpdateInput = {
  data: DriverLicenseUpdateInput;
  filter?: Maybe<DriverLicenseKeyFilter>;
};

export type DriverSort = {
  archived?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  dateOfBirth?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  licenseNumber?: Maybe<SortOrder>;
  licenseState?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SmartPhoneSort>;
  preferredLanguage?: Maybe<DriverLanguageSort>;
  status?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Driver subscription filter */
export type DriverSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<DriverFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Driver update input */
export type DriverUpdateByFilterInput = {
  archived?: Maybe<Array<Maybe<UpdateByFilterDateTimeInput>>>;
  dateOfBirth?: Maybe<Array<Maybe<UpdateByFilterDateInput>>>;
  email?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  lastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  licenseNumber?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  licenseState?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** Driver update input */
export type DriverUpdateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationUpdateRelationInput>;
  customer?: Maybe<DriverCustomerUpdateRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationUpdateRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Case create input from driver */
export type Driver_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from driver */
export type Driver_CaseUpdateInput = {
  data: CaseUpdateInput;
  filter?: Maybe<CaseKeyFilter>;
};

/** EverSignDoc create input from driver */
export type Driver_EverSignDocCreateInput = {
  driver?: Maybe<EverSignDocDriverRelationInput>;
  hash: Scalars['String'];
};

/** EverSignDoc update input from driver */
export type Driver_EverSignDocUpdateInput = {
  data: EverSignDocUpdateInput;
  filter?: Maybe<EverSignDocKeyFilter>;
};

/** MVRLog create input from driver */
export type Driver_MvrLogCreateInput = {
  driver?: Maybe<MvrLogDriverRelationInput>;
  error?: Maybe<Scalars['JSON']>;
  /** The response might be successful, but sometimes returns nothing */
  response?: Maybe<Scalars['JSON']>;
  /** Values sent it to the MRV's API */
  values?: Maybe<Scalars['JSON']>;
};

/** MVRLog update input from driver */
export type Driver_MvrLogUpdateInput = {
  data: MvrLogUpdateInput;
  filter?: Maybe<MvrLogKeyFilter>;
};

export type Driver_PermissionFilter = {
  AND?: Maybe<Array<Driver_PermissionFilter>>;
  OR?: Maybe<Array<Driver_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  archived?: Maybe<DateTimePredicate>;
  caseDriverRelation?: Maybe<Case_PermissionRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  dateOfBirth?: Maybe<DatePredicate>;
  deletedAt?: Maybe<IntPredicate>;
  driverEverSignDocRelation?: Maybe<EverSignDoc_PermissionRelationFilter>;
  driverLicenseRelation?: Maybe<DriverLicense_PermissionRelationFilter>;
  email?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  lastName?: Maybe<StringPredicate>;
  licenseNumber?: Maybe<StringPredicate>;
  licenseState?: Maybe<StringPredicate>;
  mvrLogRelation?: Maybe<MvrLog_PermissionRelationFilter>;
  name?: Maybe<StringPredicate>;
  phoneNumber?: Maybe<SmartPhonePredicate>;
  preferredLanguage?: Maybe<DriverLanguage_PermissionFilter>;
  status?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type Driver_PermissionRelationFilter = {
  every?: Maybe<Driver_PermissionFilter>;
  none?: Maybe<Driver_PermissionFilter>;
  some?: Maybe<Driver_PermissionFilter>;
};

export type EnvironmentBackupItem = {
  __typename?: 'EnvironmentBackupItem';
  name: Scalars['String'];
  size: Scalars['Float'];
};

export type EnvironmentItem = {
  __typename?: 'EnvironmentItem';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** EnvironmentSetupInput */
export type EnvironmentSetupInput = {
  deleteLock?: Maybe<Scalars['Boolean']>;
  introspection?: Maybe<Scalars['Boolean']>;
};

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

/** EnvironmentVariables create input */
export type EnvironmentVariableCreateInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariables create many input */
export type EnvironmentVariableCreateManyInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** EnvironmentVariables delete input */
export type EnvironmentVariableDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type EnvironmentVariableFilter = {
  AND?: Maybe<Array<EnvironmentVariableFilter>>;
  OR?: Maybe<Array<EnvironmentVariableFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  value?: Maybe<StringPredicate>;
};

export type EnvironmentVariableGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: EnvironmentVariableGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type EnvironmentVariableGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  value?: Maybe<Array<GroupByField>>;
};

export type EnvironmentVariableKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** EnvironmentVariableListResponse output */
export type EnvironmentVariableListResponse = {
  __typename?: 'EnvironmentVariableListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<EnvironmentVariable>;
};

/** EnvironmentVariableManyResponse output */
export type EnvironmentVariableManyResponse = {
  __typename?: 'EnvironmentVariableManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentVariable>;
};

/** No longer supported. Use `sort` instead. */
export enum EnvironmentVariableOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** EnvironmentVariables subscription payload */
export type EnvironmentVariablePayload = {
  __typename?: 'EnvironmentVariablePayload';
  mutation: MutationType;
  node?: Maybe<EnvironmentVariable>;
  previousValues?: Maybe<EnvironmentVariable>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EnvironmentVariableSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

/** EnvironmentVariables subscription filter */
export type EnvironmentVariableSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<EnvironmentVariableFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateByFilterInput = {
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  value?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** EnvironmentVariables update input */
export type EnvironmentVariableUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EverSignDoc = {
  __typename?: 'EverSignDoc';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  driver?: Maybe<Driver>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** EverSignDoc create input */
export type EverSignDocCreateInput = {
  driver?: Maybe<EverSignDocDriverRelationInput>;
  hash: Scalars['String'];
};

/** EverSignDoc create many input */
export type EverSignDocCreateManyInput = {
  driver: EverSignDocDriverManyRelationInput;
  hash: Scalars['String'];
};

/** EverSignDoc delete input */
export type EverSignDocDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** EverSignDoc relation input */
export type EverSignDocDriverManyRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
};

/** EverSignDoc relation input */
export type EverSignDocDriverRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<DriverEverSignDocRelation_DriverCreateInput>;
};

/** EverSignDoc relation input */
export type EverSignDocDriverUpdateRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<DriverEverSignDocRelation_DriverCreateInput>;
  disconnect?: Maybe<DriverKeyFilter>;
  reconnect?: Maybe<DriverKeyFilter>;
  update?: Maybe<DriverEverSignDocRelation_DriverUpdateInput>;
};

/** EverSignDocFieldsPermissions create input */
export type EverSignDocFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  hash?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type EverSignDocFilter = {
  AND?: Maybe<Array<EverSignDocFilter>>;
  OR?: Maybe<Array<EverSignDocFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<DriverFilter>;
  hash?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type EverSignDocGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: EverSignDocGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type EverSignDocGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  driver?: Maybe<DriverGroupByQuery>;
  hash?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type EverSignDocKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** EverSignDocListResponse output */
export type EverSignDocListResponse = {
  __typename?: 'EverSignDocListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<EverSignDoc>;
};

/** EverSignDocManyResponse output */
export type EverSignDocManyResponse = {
  __typename?: 'EverSignDocManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EverSignDoc>;
};

/** No longer supported. Use `sort` instead. */
export enum EverSignDocOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** EverSignDoc subscription payload */
export type EverSignDocPayload = {
  __typename?: 'EverSignDocPayload';
  mutation: MutationType;
  node?: Maybe<EverSignDoc>;
  previousValues?: Maybe<EverSignDoc>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EverSignDocRelationFilter = {
  every?: Maybe<EverSignDocFilter>;
  none?: Maybe<EverSignDocFilter>;
  some?: Maybe<EverSignDocFilter>;
};

export type EverSignDocSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  driver?: Maybe<DriverSort>;
  hash?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** EverSignDoc subscription filter */
export type EverSignDocSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<EverSignDocFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** EverSignDoc update input */
export type EverSignDocUpdateByFilterInput = {
  hash?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** EverSignDoc update input */
export type EverSignDocUpdateInput = {
  driver?: Maybe<EverSignDocDriverUpdateRelationInput>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type EverSignDoc_PermissionFilter = {
  AND?: Maybe<Array<EverSignDoc_PermissionFilter>>;
  OR?: Maybe<Array<EverSignDoc_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<Driver_PermissionFilter>;
  hash?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type EverSignDoc_PermissionRelationFilter = {
  every?: Maybe<EverSignDoc_PermissionFilter>;
  none?: Maybe<EverSignDoc_PermissionFilter>;
  some?: Maybe<EverSignDoc_PermissionFilter>;
};

export type Evidence = {
  __typename?: 'Evidence';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  evidence?: Maybe<File>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Evidence create input */
export type EvidenceCreateInput = {
  evidence?: Maybe<EvidenceEvidenceRelationInput>;
};

/** Evidence create many input */
export type EvidenceCreateManyInput = {
  evidence: EvidenceEvidenceManyRelationInput;
};

/** Evidence delete input */
export type EvidenceDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** Evidence relation input */
export type EvidenceEvidenceManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** Evidence relation input */
export type EvidenceEvidenceRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Evidence_Evidence_FileCreateInput>;
};

/** Evidence relation input */
export type EvidenceEvidenceUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Evidence_Evidence_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<Evidence_Evidence_FileUpdateInput>;
};

/** EvidenceFieldsPermissions create input */
export type EvidenceFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type EvidenceFilter = {
  AND?: Maybe<Array<EvidenceFilter>>;
  OR?: Maybe<Array<EvidenceFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  evidence?: Maybe<FileFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type EvidenceGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: EvidenceGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type EvidenceGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  evidence?: Maybe<FileGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type EvidenceKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** EvidenceListResponse output */
export type EvidenceListResponse = {
  __typename?: 'EvidenceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Evidence>;
};

/** EvidenceManyResponse output */
export type EvidenceManyResponse = {
  __typename?: 'EvidenceManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Evidence>;
};

/** No longer supported. Use `sort` instead. */
export enum EvidenceOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Evidence subscription payload */
export type EvidencePayload = {
  __typename?: 'EvidencePayload';
  mutation: MutationType;
  node?: Maybe<Evidence>;
  previousValues?: Maybe<Evidence>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EvidenceRelationFilter = {
  every?: Maybe<EvidenceFilter>;
  none?: Maybe<EvidenceFilter>;
  some?: Maybe<EvidenceFilter>;
};

export type EvidenceSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  evidence?: Maybe<FileSort>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Evidence subscription filter */
export type EvidenceSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<EvidenceFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Evidence update input */
export type EvidenceUpdateInput = {
  evidence?: Maybe<EvidenceEvidenceUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
};

/** Evidence create input from evidence */
export type Evidence_EvidenceCreateInput = {
  evidence?: Maybe<EvidenceEvidenceRelationInput>;
};

/** Evidence update input from evidence */
export type Evidence_EvidenceUpdateInput = {
  data: EvidenceUpdateInput;
  filter?: Maybe<EvidenceKeyFilter>;
};

export type Evidence_PermissionFilter = {
  AND?: Maybe<Array<Evidence_PermissionFilter>>;
  OR?: Maybe<Array<Evidence_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  evidence?: Maybe<File_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type Evidence_PermissionRelationFilter = {
  every?: Maybe<Evidence_PermissionFilter>;
  none?: Maybe<Evidence_PermissionFilter>;
  some?: Maybe<Evidence_PermissionFilter>;
};

/** Files create input from evidence_evidence */
export type Evidence_Evidence_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from evidence_evidence */
export type Evidence_Evidence_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** Facebook connection params */
export type FacebookOptions = {
  __typename?: 'FacebookOptions';
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Facebook connection params input */
export type FacebookOptionsInput = {
  app_id: Scalars['String'];
  app_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** FeatureFlags create input */
export type FeatureFlagCreateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** FeatureFlags create many input */
export type FeatureFlagCreateManyInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** FeatureFlags delete input */
export type FeatureFlagDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** FeatureFlagFieldsPermissions create input */
export type FeatureFlagFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type FeatureFlagFilter = {
  AND?: Maybe<Array<FeatureFlagFilter>>;
  OR?: Maybe<Array<FeatureFlagFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  enabled?: Maybe<BoolPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type FeatureFlagGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: FeatureFlagGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type FeatureFlagGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  enabled?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type FeatureFlagKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** FeatureFlagListResponse output */
export type FeatureFlagListResponse = {
  __typename?: 'FeatureFlagListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<FeatureFlag>;
};

/** FeatureFlagManyResponse output */
export type FeatureFlagManyResponse = {
  __typename?: 'FeatureFlagManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<FeatureFlag>;
};

/** No longer supported. Use `sort` instead. */
export enum FeatureFlagOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** FeatureFlags subscription payload */
export type FeatureFlagPayload = {
  __typename?: 'FeatureFlagPayload';
  mutation: MutationType;
  node?: Maybe<FeatureFlag>;
  previousValues?: Maybe<FeatureFlag>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FeatureFlagSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  enabled?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** FeatureFlags subscription filter */
export type FeatureFlagSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FeatureFlagFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** FeatureFlags update input */
export type FeatureFlagUpdateByFilterInput = {
  enabled?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** FeatureFlags update input */
export type FeatureFlagUpdateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type FeatureFlag_PermissionFilter = {
  AND?: Maybe<Array<FeatureFlag_PermissionFilter>>;
  OR?: Maybe<Array<FeatureFlag_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  enabled?: Maybe<BoolPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** Field Data Features */
export type FieldDataFeatures = {
  __typename?: 'FieldDataFeatures';
  create: Scalars['Boolean'];
  sort: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field Schema Features */
export type FieldSchemaFeatures = {
  __typename?: 'FieldSchemaFeatures';
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field types */
export enum FieldType {
  Date = 'DATE',
  File = 'FILE',
  Geo = 'GEO',
  Id = 'ID',
  Json = 'JSON',
  MissingRelation = 'MISSING_RELATION',
  Number = 'NUMBER',
  OneWayRelation = 'ONE_WAY_RELATION',
  Relation = 'RELATION',
  Smart = 'SMART',
  Switch = 'SWITCH',
  Text = 'TEXT',
  Uuid = 'UUID',
}

/** Field Type Attributes */
export type FieldTypeAttributes =
  | DateFieldTypeAttributes
  | FileFieldTypeAttributes
  | GeoFieldTypeAttributes
  | MissingRelationFieldTypeAttributes
  | NumberFieldTypeAttributes
  | SmartFieldTypeAttributes
  | SwitchFieldTypeAttributes
  | TextFieldTypeAttributes
  | UuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type FieldTypeAttributesInput = {
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  fieldSize?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  listOptions?: Maybe<Array<Scalars['String']>>;
  maxSize?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

export type File = {
  __typename?: 'File';
  _description?: Maybe<Scalars['String']>;
  caseDocument_file?: Maybe<CaseDocumentListResponse>;
  citationCopy_citation?: Maybe<CitationCopyListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerDocument_file?: Maybe<CustomerDocumentListResponse>;
  deletedAt?: Maybe<Scalars['Int']>;
  /** No longer supported. Use `downloadUrl` instead. */
  downloadStorageUrl?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  driverLicense_file?: Maybe<DriverLicenseListResponse>;
  evidence_evidence?: Maybe<EvidenceListResponse>;
  /** No longer supported. Use `system.fileUploadSignInfo.AwsSignInfoResponse.fields` instead. */
  fields?: Maybe<Scalars['JSON']>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  previewUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  settings_landingPageImage?: Maybe<SettingListResponse>;
  settings_menuBarLogo?: Maybe<SettingListResponse>;
  shareUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** No longer supported */
  uploadUrl?: Maybe<Scalars['String']>;
  /** No longer supported */
  uploaded?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<UserListResponse>;
};

export type FileCaseDocument_FileArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseDocumentSort>>;
};

export type FileCitationCopy_CitationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CitationCopyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CitationCopyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CitationCopyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CitationCopySort>>;
};

export type FileCustomerDocument_FileArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerDocumentSort>>;
};

export type FileDriverLicense_FileArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLicenseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLicenseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLicenseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLicenseSort>>;
};

export type FileEvidence_EvidenceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EvidenceFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EvidenceGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EvidenceOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EvidenceSort>>;
};

export type FileSettings_LandingPageImageArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<SettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<SettingSort>>;
};

export type FileSettings_MenuBarLogoArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<SettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<SettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<SettingSort>>;
};

export type FileUsers_AvatarArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UserSort>>;
};

/** Files create input */
export type FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files create many input */
export type FileCreateManyInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileManyRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationManyRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileManyRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileManyRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceManyRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarManyRelationInput>;
};

/** Files delete input */
export type FileDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** File Field Attributes */
export type FileFieldTypeAttributes = {
  __typename?: 'FileFieldTypeAttributes';
  expiration?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

/** FileFieldsPermissions create input */
export type FileFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  downloadUrl?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['Boolean']>;
  fileId?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['Boolean']>;
  mods?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<Scalars['Boolean']>;
  public?: Maybe<Scalars['Boolean']>;
  shareUrl?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  uploadUrl?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
};

export type FileFilter = {
  AND?: Maybe<Array<FileFilter>>;
  OR?: Maybe<Array<FileFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseDocument_file?: Maybe<CaseDocumentRelationFilter>;
  citationCopy_citation?: Maybe<CitationCopyRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customerDocument_file?: Maybe<CustomerDocumentRelationFilter>;
  deletedAt?: Maybe<IntPredicate>;
  downloadUrl?: Maybe<StringPredicate>;
  driverLicense_file?: Maybe<DriverLicenseRelationFilter>;
  evidence_evidence?: Maybe<EvidenceRelationFilter>;
  fileId?: Maybe<StringPredicate>;
  filename?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  provider?: Maybe<StringPredicate>;
  public?: Maybe<BoolPredicate>;
  settings_landingPageImage?: Maybe<SettingRelationFilter>;
  settings_menuBarLogo?: Maybe<SettingRelationFilter>;
  shareUrl?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  uploadUrl?: Maybe<StringPredicate>;
  uploaded?: Maybe<BoolPredicate>;
  users_avatar?: Maybe<UserRelationFilter>;
};

export type FileGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: FileGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type FileGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  caseDocument_file?: Maybe<CaseDocumentGroupByQuery>;
  citationCopy_citation?: Maybe<CitationCopyGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customerDocument_file?: Maybe<CustomerDocumentGroupByQuery>;
  downloadUrl?: Maybe<Array<GroupByField>>;
  driverLicense_file?: Maybe<DriverLicenseGroupByQuery>;
  evidence_evidence?: Maybe<EvidenceGroupByQuery>;
  fields?: Maybe<Array<GroupByField>>;
  fileId?: Maybe<Array<GroupByField>>;
  filename?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  meta?: Maybe<Array<GroupByField>>;
  mods?: Maybe<Array<GroupByField>>;
  provider?: Maybe<Array<GroupByField>>;
  public?: Maybe<Array<GroupByField>>;
  settings_landingPageImage?: Maybe<SettingGroupByQuery>;
  settings_menuBarLogo?: Maybe<SettingGroupByQuery>;
  shareUrl?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  uploadUrl?: Maybe<Array<GroupByField>>;
  uploaded?: Maybe<Array<GroupByField>>;
  users_avatar?: Maybe<UserGroupByQuery>;
};

export type FileKeyFilter = {
  fileId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** FileListResponse output */
export type FileListResponse = {
  __typename?: 'FileListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<File>;
};

/** FileManyResponse output */
export type FileManyResponse = {
  __typename?: 'FileManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<File>;
};

/** No longer supported. Use `sort` instead. */
export enum FileOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DownloadUrlAsc = 'downloadUrl_ASC',
  DownloadUrlDesc = 'downloadUrl_DESC',
  FieldsAsc = 'fields_ASC',
  FieldsDesc = 'fields_DESC',
  FileIdAsc = 'fileId_ASC',
  FileIdDesc = 'fileId_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MetaAsc = 'meta_ASC',
  MetaDesc = 'meta_DESC',
  ModsAsc = 'mods_ASC',
  ModsDesc = 'mods_DESC',
  ProviderAsc = 'provider_ASC',
  ProviderDesc = 'provider_DESC',
  PublicAsc = 'public_ASC',
  PublicDesc = 'public_DESC',
  ShareUrlAsc = 'shareUrl_ASC',
  ShareUrlDesc = 'shareUrl_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UploadUrlAsc = 'uploadUrl_ASC',
  UploadUrlDesc = 'uploadUrl_DESC',
  UploadedAsc = 'uploaded_ASC',
  UploadedDesc = 'uploaded_DESC',
}

/** Files subscription payload */
export type FilePayload = {
  __typename?: 'FilePayload';
  mutation: MutationType;
  node?: Maybe<File>;
  previousValues?: Maybe<File>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FileSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  downloadUrl?: Maybe<SortOrder>;
  fileId?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  provider?: Maybe<SortOrder>;
  public?: Maybe<SortOrder>;
  shareUrl?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploadUrl?: Maybe<SortOrder>;
  uploaded?: Maybe<SortOrder>;
};

export type FileStackSignInfoResponse = {
  __typename?: 'FileStackSignInfoResponse';
  apiKey: Scalars['String'];
  path: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
};

/** Files subscription filter */
export type FileSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FileFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** File Type Format Enum */
export enum FileTypeFormatEnum {
  File = 'FILE',
  Image = 'IMAGE',
}

/** Files update input */
export type FileUpdateByFilterInput = {
  downloadUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  fields?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  filename?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  meta?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  mods?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  provider?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  public?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  shareUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  uploadUrl?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  uploaded?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** Files update input */
export type FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

export type FileUploadInfoResponse = {
  __typename?: 'FileUploadInfoResponse';
  apiKey: Scalars['String'];
  path: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
};

/** CaseDocument create input from file */
export type File_CaseDocumentCreateInput = {
  caseRelation?: Maybe<CaseDocumentCaseRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CaseDocumentFileRelationInput>;
  type?: Maybe<Scalars['String']>;
};

/** CaseDocument update input from file */
export type File_CaseDocumentUpdateInput = {
  data: CaseDocumentUpdateInput;
  filter?: Maybe<CaseDocumentKeyFilter>;
};

/** CustomerDocument create input from file */
export type File_CustomerDocumentCreateInput = {
  customerRelation?: Maybe<CustomerDocumentCustomerRelationRelationInput>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<CustomerDocumentFileRelationInput>;
};

/** CustomerDocument update input from file */
export type File_CustomerDocumentUpdateInput = {
  data: CustomerDocumentUpdateInput;
  filter?: Maybe<CustomerDocumentKeyFilter>;
};

/** DriverLicense create input from file */
export type File_DriverLicenseCreateInput = {
  driverRelation: DriverLicenseDriverRelationRelationInput;
  file?: Maybe<DriverLicenseFileRelationInput>;
};

/** DriverLicense update input from file */
export type File_DriverLicenseUpdateInput = {
  data: DriverLicenseUpdateInput;
  filter?: Maybe<DriverLicenseKeyFilter>;
};

export type File_PermissionFilter = {
  AND?: Maybe<Array<File_PermissionFilter>>;
  OR?: Maybe<Array<File_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  caseDocument_file?: Maybe<CaseDocument_PermissionRelationFilter>;
  citationCopy_citation?: Maybe<CitationCopy_PermissionRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customerDocument_file?: Maybe<CustomerDocument_PermissionRelationFilter>;
  deletedAt?: Maybe<IntPredicate>;
  downloadUrl?: Maybe<StringPredicate>;
  driverLicense_file?: Maybe<DriverLicense_PermissionRelationFilter>;
  evidence_evidence?: Maybe<Evidence_PermissionRelationFilter>;
  fileId?: Maybe<StringPredicate>;
  filename?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  provider?: Maybe<StringPredicate>;
  public?: Maybe<BoolPredicate>;
  settings_landingPageImage?: Maybe<Setting_PermissionRelationFilter>;
  settings_menuBarLogo?: Maybe<Setting_PermissionRelationFilter>;
  shareUrl?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  uploadUrl?: Maybe<StringPredicate>;
  uploaded?: Maybe<BoolPredicate>;
  users_avatar?: Maybe<User_PermissionRelationFilter>;
};

/** Files relation input */
export type FilesCaseDocument_FileManyRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
};

/** Files relation input */
export type FilesCaseDocument_FileRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_CaseDocumentCreateInput>>>;
};

/** Files relation input */
export type FilesCaseDocument_FileUpdateRelationInput = {
  connect?: Maybe<Array<CaseDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_CaseDocumentCreateInput>>>;
  disconnect?: Maybe<Array<CaseDocumentKeyFilter>>;
  reconnect?: Maybe<Array<CaseDocumentKeyFilter>>;
  update?: Maybe<Array<Maybe<File_CaseDocumentUpdateInput>>>;
};

/** Files relation input */
export type FilesCitationCopy_CitationManyRelationInput = {
  connect?: Maybe<Array<CitationCopyKeyFilter>>;
};

/** Files relation input */
export type FilesCitationCopy_CitationRelationInput = {
  connect?: Maybe<Array<CitationCopyKeyFilter>>;
  create?: Maybe<Array<Maybe<Citation_CitationCopyCreateInput>>>;
};

/** Files relation input */
export type FilesCitationCopy_CitationUpdateRelationInput = {
  connect?: Maybe<Array<CitationCopyKeyFilter>>;
  create?: Maybe<Array<Maybe<Citation_CitationCopyCreateInput>>>;
  disconnect?: Maybe<Array<CitationCopyKeyFilter>>;
  reconnect?: Maybe<Array<CitationCopyKeyFilter>>;
  update?: Maybe<Array<Maybe<Citation_CitationCopyUpdateInput>>>;
};

/** Files relation input */
export type FilesCustomerDocument_FileManyRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
};

/** Files relation input */
export type FilesCustomerDocument_FileRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_CustomerDocumentCreateInput>>>;
};

/** Files relation input */
export type FilesCustomerDocument_FileUpdateRelationInput = {
  connect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  create?: Maybe<Array<Maybe<File_CustomerDocumentCreateInput>>>;
  disconnect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  reconnect?: Maybe<Array<CustomerDocumentKeyFilter>>;
  update?: Maybe<Array<Maybe<File_CustomerDocumentUpdateInput>>>;
};

/** Files relation input */
export type FilesDriverLicense_FileManyRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
};

/** Files relation input */
export type FilesDriverLicense_FileRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
  create?: Maybe<Array<Maybe<File_DriverLicenseCreateInput>>>;
};

/** Files relation input */
export type FilesDriverLicense_FileUpdateRelationInput = {
  connect?: Maybe<Array<DriverLicenseKeyFilter>>;
  create?: Maybe<Array<Maybe<File_DriverLicenseCreateInput>>>;
  disconnect?: Maybe<Array<DriverLicenseKeyFilter>>;
  reconnect?: Maybe<Array<DriverLicenseKeyFilter>>;
  update?: Maybe<Array<Maybe<File_DriverLicenseUpdateInput>>>;
};

/** Files relation input */
export type FilesEvidence_EvidenceManyRelationInput = {
  connect?: Maybe<Array<EvidenceKeyFilter>>;
};

/** Files relation input */
export type FilesEvidence_EvidenceRelationInput = {
  connect?: Maybe<Array<EvidenceKeyFilter>>;
  create?: Maybe<Array<Maybe<Evidence_EvidenceCreateInput>>>;
};

/** Files relation input */
export type FilesEvidence_EvidenceUpdateRelationInput = {
  connect?: Maybe<Array<EvidenceKeyFilter>>;
  create?: Maybe<Array<Maybe<Evidence_EvidenceCreateInput>>>;
  disconnect?: Maybe<Array<EvidenceKeyFilter>>;
  reconnect?: Maybe<Array<EvidenceKeyFilter>>;
  update?: Maybe<Array<Maybe<Evidence_EvidenceUpdateInput>>>;
};

export type FilesSetting = {
  __typename?: 'FilesSetting';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  filemanagername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** FilesSettings create input */
export type FilesSettingCreateInput = {
  filemanagername?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

/** FilesSettings create many input */
export type FilesSettingCreateManyInput = {
  filemanagername?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

/** FilesSettings delete input */
export type FilesSettingDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** FilesSettingFieldsPermissions create input */
export type FilesSettingFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  filemanagername?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type FilesSettingFilter = {
  AND?: Maybe<Array<FilesSettingFilter>>;
  OR?: Maybe<Array<FilesSettingFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  filemanagername?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  isEnabled?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type FilesSettingGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: FilesSettingGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type FilesSettingGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  filemanagername?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  isEnabled?: Maybe<Array<GroupByField>>;
  settings?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type FilesSettingKeyFilter = {
  filemanagername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** FilesSettingListResponse output */
export type FilesSettingListResponse = {
  __typename?: 'FilesSettingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<FilesSetting>;
};

/** FilesSettingManyResponse output */
export type FilesSettingManyResponse = {
  __typename?: 'FilesSettingManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<FilesSetting>;
};

/** No longer supported. Use `sort` instead. */
export enum FilesSettingOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  FilemanagernameAsc = 'filemanagername_ASC',
  FilemanagernameDesc = 'filemanagername_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  SettingsAsc = 'settings_ASC',
  SettingsDesc = 'settings_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** FilesSettings subscription payload */
export type FilesSettingPayload = {
  __typename?: 'FilesSettingPayload';
  mutation: MutationType;
  node?: Maybe<FilesSetting>;
  previousValues?: Maybe<FilesSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilesSettingSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  filemanagername?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isEnabled?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** FilesSettings subscription filter */
export type FilesSettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<FilesSettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** FilesSettings update input */
export type FilesSettingUpdateByFilterInput = {
  filemanagername?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  isEnabled?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  settings?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** FilesSettings update input */
export type FilesSettingUpdateInput = {
  filemanagername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type FilesSetting_PermissionFilter = {
  AND?: Maybe<Array<FilesSetting_PermissionFilter>>;
  OR?: Maybe<Array<FilesSetting_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  filemanagername?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  isEnabled?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** Files relation input */
export type FilesUsers_AvatarManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** Files relation input */
export type FilesUsers_AvatarRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Avatar_UserCreateInput>>>;
};

/** Files relation input */
export type FilesUsers_AvatarUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Avatar_UserCreateInput>>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  update?: Maybe<Array<Maybe<Avatar_UserUpdateInput>>>;
};

export type FloatPredicate = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not_equals?: Maybe<Scalars['Float']>;
  not_in?: Maybe<Array<Scalars['Float']>>;
};

export type FloatPredicateHaving = {
  AND?: Maybe<Array<FloatPredicateHaving>>;
  OR?: Maybe<Array<FloatPredicateHaving>>;
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not_equals?: Maybe<Scalars['Float']>;
  not_in?: Maybe<Array<Scalars['Float']>>;
};

/** CaseFollower create input from follower */
export type Follower_CaseFollowerCreateInput = {
  case?: Maybe<CaseFollowerCaseRelationInput>;
  follower?: Maybe<CaseFollowerFollowerRelationInput>;
};

/** CaseFollower update input from follower */
export type Follower_CaseFollowerUpdateInput = {
  data: CaseFollowerUpdateInput;
  filter?: Maybe<CaseFollowerKeyFilter>;
};

export type FrontendUtilizationAbResponse = {
  __typename?: 'FrontendUtilizationABResponse';
  CDN?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

/** FunctionInfo */
export type FunctionInfo = {
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
};

/** FunctionInfoCheck */
export type FunctionInfoCheck = {
  __typename?: 'FunctionInfoCheck';
  version?: Maybe<Scalars['String']>;
};

/** FunctionInfoFilter */
export type FunctionInfoFilter = {
  description?: Maybe<Scalars['String']>;
  functionType?: Maybe<FunctionType>;
  name?: Maybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export enum FunctionInfoOrderBy {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FunctionTypeAsc = 'functionType_ASC',
  FunctionTypeDesc = 'functionType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** FunctionListResponse output */
export type FunctionListResponse = {
  __typename?: 'FunctionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<FunctionInfo>;
};

/** FunctionResolverInfo */
export type FunctionResolverInfo = FunctionInfo & {
  __typename?: 'FunctionResolverInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  gqlType: Scalars['String'];
  name: Scalars['String'];
};

/** FunctionTaskInfo */
export type FunctionTaskInfo = FunctionInfo & {
  __typename?: 'FunctionTaskInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
  scheduleExpression?: Maybe<Scalars['String']>;
};

/** FunctionTriggerInfo */
export type FunctionTriggerInfo = FunctionInfo & {
  __typename?: 'FunctionTriggerInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  name: Scalars['String'];
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
};

/** FunctionType */
export enum FunctionType {
  Resolver = 'resolver',
  Schedule = 'schedule',
  Task = 'task',
  Trigger = 'trigger',
  Webhook = 'webhook',
}

/** FunctionWebhookInfo */
export type FunctionWebhookInfo = FunctionInfo & {
  __typename?: 'FunctionWebhookInfo';
  application?: Maybe<Application>;
  description?: Maybe<Scalars['String']>;
  functionType: FunctionType;
  httpMethod: Scalars['String'];
  name: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
};

/** Geo Field Attributes */
export type GeoFieldTypeAttributes = {
  __typename?: 'GeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

/** Github connection params */
export type GithubOptions = {
  __typename?: 'GithubOptions';
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Github connection params input */
export type GithubOptionsInput = {
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Google connection params */
export type GoogleOptions = {
  __typename?: 'GoogleOptions';
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** Google connection params input */
export type GoogleOptionsInput = {
  client_id: Scalars['String'];
  client_secret: Scalars['String'];
  enabled: Scalars['Boolean'];
};

/** GraphQLCreateFileCustomInput */
export type GraphQlCreateFileCustomInput = {
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

/** GraphQLCreateFileItemInput */
export type GraphQlCreateFileItemInput = {
  create?: Maybe<GraphQlCreateFileCustomInput>;
};

/** GraphQLFileItemResponse */
export type GraphQlFileItemResponse = {
  __typename?: 'GraphQLFileItemResponse';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type GroupByField = {
  as?: Maybe<Scalars['String']>;
  fn?: Maybe<Array<Maybe<GroupByFieldFunction>>>;
};

export type GroupByFieldFunction = {
  abs?: Maybe<Scalars['Boolean']>;
  aggregate?: Maybe<AggregationFunctionType>;
  ascii?: Maybe<Scalars['Boolean']>;
  bitLength?: Maybe<Scalars['Boolean']>;
  ceil?: Maybe<Scalars['Boolean']>;
  charLength?: Maybe<Scalars['Boolean']>;
  crc32?: Maybe<Scalars['Boolean']>;
  datePart?: Maybe<DatePartFunctionType>;
  distinct?: Maybe<Scalars['Boolean']>;
  floor?: Maybe<Scalars['Boolean']>;
  hex?: Maybe<Scalars['Boolean']>;
  ifNull?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
  left?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Boolean']>;
  like?: Maybe<PatternFunctionArguments>;
  locate?: Maybe<LocateFunctionArguments>;
  lower?: Maybe<Scalars['Boolean']>;
  lpad?: Maybe<StringPadFunctionArguments>;
  ltrim?: Maybe<Scalars['Boolean']>;
  mod?: Maybe<Scalars['Int']>;
  notLike?: Maybe<PatternFunctionArguments>;
  nullIf?: Maybe<Scalars['String']>;
  replace?: Maybe<ReplaceFunctionArguments>;
  reverse?: Maybe<Scalars['Boolean']>;
  right?: Maybe<Scalars['Int']>;
  round?: Maybe<Scalars['Int']>;
  rpad?: Maybe<StringPadFunctionArguments>;
  rtrim?: Maybe<Scalars['Boolean']>;
  sign?: Maybe<Scalars['Boolean']>;
  substring?: Maybe<SubstringFunctionArguments>;
  trim?: Maybe<TrimFunctionArguments>;
  truncate?: Maybe<Scalars['Int']>;
  upper?: Maybe<Scalars['Boolean']>;
};

export type GroupByResponse = {
  __typename?: 'GroupByResponse';
  AddressGroup: AddressListResponse;
  AlertGroup: AlertListResponse;
  ApiTokenGroup: ApiTokenListResponse;
  AttorneyGroup: AttorneyListResponse;
  AuthenticationProfileGroup: AuthenticationProfileListResponse;
  BigInt?: Maybe<Scalars['BigInt']>;
  Boolean?: Maybe<Scalars['Boolean']>;
  CaseCommentGroup: CaseCommentListResponse;
  CaseCommentReplyGroup: CaseCommentReplyListResponse;
  CaseDocumentGroup: CaseDocumentListResponse;
  CaseFollowerGroup: CaseFollowerListResponse;
  CaseGroup: CaseListResponse;
  CaseHistoryGroup: CaseHistoryListResponse;
  CaseLogGroup: CaseLogListResponse;
  CaseStatusLogGroup: CaseStatusLogListResponse;
  CaseViolationCodeGroup: CaseViolationCodeListResponse;
  CiCdMigrationGroup: CiCdMigrationListResponse;
  CitationCopyGroup: CitationCopyListResponse;
  CustomerAlertGroup: CustomerAlertListResponse;
  CustomerDocumentGroup: CustomerDocumentListResponse;
  CustomerGroup: CustomerListResponse;
  Date?: Maybe<Scalars['Date']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  DocumentDraftGroup: DocumentDraftListResponse;
  DriverGroup: DriverListResponse;
  DriverLanguageGroup: DriverLanguageListResponse;
  DriverLicenseGroup: DriverLicenseListResponse;
  EnvironmentVariableGroup: EnvironmentVariableListResponse;
  EverSignDocGroup: EverSignDocListResponse;
  EvidenceGroup: EvidenceListResponse;
  FeatureFlagGroup: FeatureFlagListResponse;
  FileGroup: FileListResponse;
  FilesSettingGroup: FilesSettingListResponse;
  Float?: Maybe<Scalars['Float']>;
  GroupIds?: Maybe<Array<Scalars['ID']>>;
  ID?: Maybe<Scalars['ID']>;
  Int?: Maybe<Scalars['Int']>;
  JSON?: Maybe<Scalars['JSON']>;
  MVRLogGroup: MvrLogListResponse;
  NotificationGroup: NotificationListResponse;
  NotificationLogGroup: NotificationLogListResponse;
  NotificationObjectGroup: NotificationObjectListResponse;
  NotificationSettingGroup: NotificationSettingListResponse;
  RoleGroup: RoleListResponse;
  String?: Maybe<Scalars['String']>;
  UsStateGroup: UsStateListResponse;
  UserGroup: UserListResponse;
  ViolationCodeGroup: ViolationCodeListResponse;
};

export type GroupByResponseAddressGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AddressGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AddressOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AddressSort>>;
};

export type GroupByResponseAlertGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AlertSort>>;
};

export type GroupByResponseApiTokenGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApiTokenFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ApiTokenSort>>;
};

export type GroupByResponseAttorneyGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AttorneyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AttorneyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AttorneyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AttorneySort>>;
};

export type GroupByResponseAuthenticationProfileGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AuthenticationProfileFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
};

export type GroupByResponseCaseCommentGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentSort>>;
};

export type GroupByResponseCaseCommentReplyGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentReplyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentReplyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentReplyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentReplySort>>;
};

export type GroupByResponseCaseDocumentGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseDocumentSort>>;
};

export type GroupByResponseCaseFollowerGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFollowerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseFollowerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseFollowerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseFollowerSort>>;
};

export type GroupByResponseCaseGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseSort>>;
};

export type GroupByResponseCaseHistoryGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseHistoryGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseHistoryOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseHistorySort>>;
};

export type GroupByResponseCaseLogGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseLogSort>>;
};

export type GroupByResponseCaseStatusLogGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseStatusLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseStatusLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseStatusLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseStatusLogSort>>;
};

export type GroupByResponseCaseViolationCodeGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseViolationCodeSort>>;
};

export type GroupByResponseCiCdMigrationGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CiCdMigrationFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CiCdMigrationGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CiCdMigrationOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CiCdMigrationSort>>;
};

export type GroupByResponseCitationCopyGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CitationCopyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CitationCopyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CitationCopyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CitationCopySort>>;
};

export type GroupByResponseCustomerAlertGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerAlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerAlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerAlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerAlertSort>>;
};

export type GroupByResponseCustomerDocumentGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerDocumentSort>>;
};

export type GroupByResponseCustomerGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerSort>>;
};

export type GroupByResponseDocumentDraftGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentDraftFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentDraftGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DocumentDraftOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DocumentDraftSort>>;
};

export type GroupByResponseDriverGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverSort>>;
};

export type GroupByResponseDriverLanguageGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLanguageFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLanguageGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLanguageOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLanguageSort>>;
};

export type GroupByResponseDriverLicenseGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLicenseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLicenseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLicenseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLicenseSort>>;
};

export type GroupByResponseEnvironmentVariableGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EnvironmentVariableFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EnvironmentVariableGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EnvironmentVariableOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EnvironmentVariableSort>>;
};

export type GroupByResponseEverSignDocGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EverSignDocFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EverSignDocGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EverSignDocOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EverSignDocSort>>;
};

export type GroupByResponseEvidenceGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EvidenceFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EvidenceGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EvidenceOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EvidenceSort>>;
};

export type GroupByResponseFeatureFlagGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FeatureFlagFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FeatureFlagGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FeatureFlagSort>>;
};

export type GroupByResponseFileGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FileFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FileGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FileOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FileSort>>;
};

export type GroupByResponseFilesSettingGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FilesSettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FilesSettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FilesSettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FilesSettingSort>>;
};

export type GroupByResponseMvrLogGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MvrLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MvrLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<MvrLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MvrLogSort>>;
};

export type GroupByResponseNotificationGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationSort>>;
};

export type GroupByResponseNotificationLogGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationLogSort>>;
};

export type GroupByResponseNotificationObjectGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationObjectFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationObjectGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationObjectOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationObjectSort>>;
};

export type GroupByResponseNotificationSettingGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationSettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationSettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationSettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationSettingSort>>;
};

export type GroupByResponseRoleGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
};

export type GroupByResponseUsStateGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UsStateFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UsStateGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UsStateOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UsStateSort>>;
};

export type GroupByResponseUserGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UserSort>>;
};

export type GroupByResponseViolationCodeGroupArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ViolationCodeSort>>;
};

export type GroupBySort = {
  alias: Scalars['String'];
  direction: SortOrder;
};

export type GroupIdentifiersGroupByField = {
  as: Scalars['String'];
};

export type Having = {
  AND?: Maybe<Array<Having>>;
  OR?: Maybe<Array<Having>>;
  alias?: Maybe<Scalars['String']>;
  bigint?: Maybe<BigIntPredicateHaving>;
  bool?: Maybe<BoolPredicateHaving>;
  date?: Maybe<DatePredicateHaving>;
  datetime?: Maybe<DateTimePredicateHaving>;
  float?: Maybe<FloatPredicateHaving>;
  id?: Maybe<IdPredicateHaving>;
  int?: Maybe<IntPredicateHaving>;
  string?: Maybe<StringPredicateHaving>;
};

export type IdPredicate = {
  contains?: Maybe<Scalars['ID']>;
  ends_with?: Maybe<Scalars['ID']>;
  equals?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  not_contains?: Maybe<Scalars['ID']>;
  not_ends_with?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
  not_in?: Maybe<Array<Scalars['ID']>>;
  not_starts_with?: Maybe<Scalars['ID']>;
  starts_with?: Maybe<Scalars['ID']>;
};

export type IdPredicateHaving = {
  AND?: Maybe<Array<IdPredicateHaving>>;
  OR?: Maybe<Array<IdPredicateHaving>>;
  contains?: Maybe<Scalars['ID']>;
  ends_with?: Maybe<Scalars['ID']>;
  equals?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  not_contains?: Maybe<Scalars['ID']>;
  not_ends_with?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
  not_in?: Maybe<Array<Scalars['ID']>>;
  not_starts_with?: Maybe<Scalars['ID']>;
  starts_with?: Maybe<Scalars['ID']>;
};

/** ImportCSV */
export type ImportCsv = {
  data: ImportCsvData;
  tableId: Scalars['String'];
};

export type ImportCsvData = {
  fields?: Maybe<Scalars['String']>;
  hasHeader?: Maybe<Scalars['Boolean']>;
  matchField?: Maybe<Scalars['String']>;
  overwrite?: Maybe<Scalars['Boolean']>;
  tableFields?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

/** ImportedTable */
export type ImportedTable = {
  __typename?: 'ImportedTable';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Inbox Events List Filter */
export type InboxEventsListFilter = {
  isCompleted?: Maybe<IntPredicate>;
};

/** Table Create Index Input */
export type IndexCreateInput = {
  columns: Array<TableIndexColumnInput>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  tableId: Scalars['ID'];
  type: TableIndexType;
};

/** Table Delete Index Input */
export type IndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Index Input */
export type IndexUpdateInput = {
  columns?: Maybe<Array<TableIndexColumnInput>>;
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TableIndexType>;
};

/** Case create input from inspectionCitationState */
export type InspectionCitationState_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from inspectionCitationState */
export type InspectionCitationState_CaseUpdateInput = {
  data: CaseUpdateInput;
  filter?: Maybe<CaseKeyFilter>;
};

export type IntPredicate = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not_equals?: Maybe<Scalars['Int']>;
  not_in?: Maybe<Array<Scalars['Int']>>;
};

export type IntPredicateHaving = {
  AND?: Maybe<Array<IntPredicateHaving>>;
  OR?: Maybe<Array<IntPredicateHaving>>;
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not_equals?: Maybe<Scalars['Int']>;
  not_in?: Maybe<Array<Scalars['Int']>>;
};

export type IntrospectionQueryResponse = {
  __typename?: 'IntrospectionQueryResponse';
  url: Scalars['String'];
};

export enum InvitationRoleEnum {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Viewer = 'VIEWER',
}

/** Invited By Name */
export type InvitedByName = {
  __typename?: 'InvitedByName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
};

/** InvokeData */
export type InvokeData = {
  functionName: Scalars['String'];
  inputArgs?: Maybe<Scalars['String']>;
  nodeVersion?: Maybe<Scalars['String']>;
};

/** InvokeFunctionResponse */
export type InvokeFunctionResponse = {
  __typename?: 'InvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** IsSocialUser */
export type IsSocialUser = {
  email: Scalars['String'];
};

export type IsSocialUserResponse = {
  __typename?: 'IsSocialUserResponse';
  isSocialUser?: Maybe<Scalars['Boolean']>;
};

export type LocateFunctionArguments = {
  pos?: Maybe<Scalars['Int']>;
  str: Scalars['String'];
};

/** LoginResponse */
export type LoginResponse = {
  __typename?: 'LoginResponse';
  auth?: Maybe<Auth>;
  success?: Maybe<Scalars['Boolean']>;
  workspaces?: Maybe<Array<WorkspaceInfo>>;
};

export type MvrLog = {
  __typename?: 'MVRLog';
  _description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  driver?: Maybe<Driver>;
  error?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  /** The response might be successful, but sometimes returns nothing */
  response?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Values sent it to the MRV's API */
  values?: Maybe<Scalars['JSON']>;
};

/** MVRLog create input */
export type MvrLogCreateInput = {
  driver?: Maybe<MvrLogDriverRelationInput>;
  error?: Maybe<Scalars['JSON']>;
  /** The response might be successful, but sometimes returns nothing */
  response?: Maybe<Scalars['JSON']>;
  /** Values sent it to the MRV's API */
  values?: Maybe<Scalars['JSON']>;
};

/** MVRLog create many input */
export type MvrLogCreateManyInput = {
  driver: MvrLogDriverManyRelationInput;
  error?: Maybe<Scalars['JSON']>;
  /** The response might be successful, but sometimes returns nothing */
  response?: Maybe<Scalars['JSON']>;
  /** Values sent it to the MRV's API */
  values?: Maybe<Scalars['JSON']>;
};

/** MVRLog delete input */
export type MvrLogDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** MVRLog relation input */
export type MvrLogDriverManyRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
};

/** MVRLog relation input */
export type MvrLogDriverRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<MvrLogRelation_DriverCreateInput>;
};

/** MVRLog relation input */
export type MvrLogDriverUpdateRelationInput = {
  connect?: Maybe<DriverKeyFilter>;
  create?: Maybe<MvrLogRelation_DriverCreateInput>;
  disconnect?: Maybe<DriverKeyFilter>;
  reconnect?: Maybe<DriverKeyFilter>;
  update?: Maybe<MvrLogRelation_DriverUpdateInput>;
};

/** MVRLogFieldsPermissions create input */
export type MvrLogFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  values?: Maybe<Scalars['Boolean']>;
};

export type MvrLogFilter = {
  AND?: Maybe<Array<MvrLogFilter>>;
  OR?: Maybe<Array<MvrLogFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<DriverFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type MvrLogGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: MvrLogGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type MvrLogGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  driver?: Maybe<DriverGroupByQuery>;
  error?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  response?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  values?: Maybe<Array<GroupByField>>;
};

export type MvrLogKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** MVRLogListResponse output */
export type MvrLogListResponse = {
  __typename?: 'MVRLogListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<MvrLog>;
};

/** MVRLogManyResponse output */
export type MvrLogManyResponse = {
  __typename?: 'MVRLogManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<MvrLog>;
};

/** No longer supported. Use `sort` instead. */
export enum MvrLogOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ErrorAsc = 'error_ASC',
  ErrorDesc = 'error_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ResponseAsc = 'response_ASC',
  ResponseDesc = 'response_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValuesAsc = 'values_ASC',
  ValuesDesc = 'values_DESC',
}

/** MVRLog subscription payload */
export type MvrLogPayload = {
  __typename?: 'MVRLogPayload';
  mutation: MutationType;
  node?: Maybe<MvrLog>;
  previousValues?: Maybe<MvrLog>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MvrLogRelationFilter = {
  every?: Maybe<MvrLogFilter>;
  none?: Maybe<MvrLogFilter>;
  some?: Maybe<MvrLogFilter>;
};

export type MvrLogSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  driver?: Maybe<DriverSort>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** MVRLog subscription filter */
export type MvrLogSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<MvrLogFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** MVRLog update input */
export type MvrLogUpdateByFilterInput = {
  error?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  response?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  values?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
};

/** MVRLog update input */
export type MvrLogUpdateInput = {
  driver?: Maybe<MvrLogDriverUpdateRelationInput>;
  error?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  /** The response might be successful, but sometimes returns nothing */
  response?: Maybe<Scalars['JSON']>;
  /** Values sent it to the MRV's API */
  values?: Maybe<Scalars['JSON']>;
};

export type MvrLog_PermissionFilter = {
  AND?: Maybe<Array<MvrLog_PermissionFilter>>;
  OR?: Maybe<Array<MvrLog_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  driver?: Maybe<Driver_PermissionFilter>;
  id?: Maybe<IdPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type MvrLog_PermissionRelationFilter = {
  every?: Maybe<MvrLog_PermissionFilter>;
  none?: Maybe<MvrLog_PermissionFilter>;
  some?: Maybe<MvrLog_PermissionFilter>;
};

/** MissingRelation */
export type MissingRelation = {
  __typename?: 'MissingRelation';
  table: Scalars['String'];
};

/** MissingRelation Field Attributes */
export type MissingRelationFieldTypeAttributes = {
  __typename?: 'MissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ImportCSV?: Maybe<AsyncSession>;
  IsSocialUser?: Maybe<IsSocialUserResponse>;
  addressCreate: Address;
  addressCreateMany: AddressManyResponse;
  addressDelete?: Maybe<SuccessResponse>;
  addressDeleteByFilter?: Maybe<SuccessResponse>;
  addressDestroy?: Maybe<SuccessResponse>;
  addressDestroyByFilter?: Maybe<SuccessResponse>;
  addressRestore: Address;
  addressUpdate: Address;
  addressUpdateByFilter: AddressManyResponse;
  alertCreate: Alert;
  alertCreateMany: AlertManyResponse;
  alertDelete?: Maybe<SuccessResponse>;
  alertDeleteByFilter?: Maybe<SuccessResponse>;
  alertDestroy?: Maybe<SuccessResponse>;
  alertDestroyByFilter?: Maybe<SuccessResponse>;
  alertRestore: Alert;
  alertUpdate: Alert;
  alertUpdateByFilter: AlertManyResponse;
  apiTokenCreate: ApiTokenResponse;
  apiTokenDelete?: Maybe<SuccessResponse>;
  apiTokenDeleteByFilter?: Maybe<SuccessResponse>;
  apiTokenDestroy?: Maybe<SuccessResponse>;
  apiTokenDestroyByFilter?: Maybe<SuccessResponse>;
  apiTokenRestore: ApiToken;
  apiTokenUpdate: ApiToken;
  apiTokenUpdateByFilter: ApiTokenManyResponse;
  /** @deprecated No longer supported. Use `system.applicationDelete` instead. */
  applicationDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.applicationInstall` instead. */
  applicationInstall?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationUpdate` instead. */
  applicationUpdate?: Maybe<Application>;
  attorneyCreate: Attorney;
  attorneyCreateMany: AttorneyManyResponse;
  attorneyDelete?: Maybe<SuccessResponse>;
  attorneyDeleteByFilter?: Maybe<SuccessResponse>;
  attorneyDestroy?: Maybe<SuccessResponse>;
  attorneyDestroyByFilter?: Maybe<SuccessResponse>;
  attorneyRestore: Attorney;
  attorneyUpdate: Attorney;
  attorneyUpdateByFilter: AttorneyManyResponse;
  authenticationProfileCreate: AuthenticationProfile;
  authenticationProfileCreateMany: AuthenticationProfileManyResponse;
  authenticationProfileDelete?: Maybe<SuccessResponse>;
  authenticationProfileDeleteByFilter?: Maybe<SuccessResponse>;
  authenticationProfileDestroy?: Maybe<SuccessResponse>;
  authenticationProfileDestroyByFilter?: Maybe<SuccessResponse>;
  authenticationProfileRestore: AuthenticationProfile;
  authenticationProfileUpdate: AuthenticationProfile;
  authenticationProfileUpdateByFilter: AuthenticationProfileManyResponse;
  authenticationSettingsUpdate: AuthenticationSetting;
  /** @deprecated No longer supported. Use `system.billingPlanUpdate` instead. */
  billingPlanUpdate?: Maybe<BillingCurrentPlanResponse>;
  caseCommentCreate: CaseComment;
  caseCommentCreateMany: CaseCommentManyResponse;
  caseCommentDelete?: Maybe<SuccessResponse>;
  caseCommentDeleteByFilter?: Maybe<SuccessResponse>;
  caseCommentDestroy?: Maybe<SuccessResponse>;
  caseCommentDestroyByFilter?: Maybe<SuccessResponse>;
  caseCommentReplyCreate: CaseCommentReply;
  caseCommentReplyCreateMany: CaseCommentReplyManyResponse;
  caseCommentReplyDelete?: Maybe<SuccessResponse>;
  caseCommentReplyDeleteByFilter?: Maybe<SuccessResponse>;
  caseCommentReplyDestroy?: Maybe<SuccessResponse>;
  caseCommentReplyDestroyByFilter?: Maybe<SuccessResponse>;
  caseCommentReplyRestore: CaseCommentReply;
  caseCommentReplyUpdate: CaseCommentReply;
  caseCommentReplyUpdateByFilter: CaseCommentReplyManyResponse;
  caseCommentRestore: CaseComment;
  caseCommentUpdate: CaseComment;
  caseCommentUpdateByFilter: CaseCommentManyResponse;
  caseCreate: Case;
  caseCreateMany: CaseManyResponse;
  caseDelete?: Maybe<SuccessResponse>;
  caseDeleteByFilter?: Maybe<SuccessResponse>;
  caseDestroy?: Maybe<SuccessResponse>;
  caseDestroyByFilter?: Maybe<SuccessResponse>;
  caseDocumentCreate: CaseDocument;
  caseDocumentCreateMany: CaseDocumentManyResponse;
  caseDocumentDelete?: Maybe<SuccessResponse>;
  caseDocumentDeleteByFilter?: Maybe<SuccessResponse>;
  caseDocumentDeleteManagement?: Maybe<CaseDocumentDeleteManagementResult>;
  caseDocumentDestroy?: Maybe<SuccessResponse>;
  caseDocumentDestroyByFilter?: Maybe<SuccessResponse>;
  caseDocumentRestore: CaseDocument;
  caseDocumentUpdate: CaseDocument;
  caseDocumentUpdateByFilter: CaseDocumentManyResponse;
  caseFollowerCreate: CaseFollower;
  caseFollowerCreateMany: CaseFollowerManyResponse;
  caseFollowerDelete?: Maybe<SuccessResponse>;
  caseFollowerDeleteByFilter?: Maybe<SuccessResponse>;
  caseFollowerDestroy?: Maybe<SuccessResponse>;
  caseFollowerDestroyByFilter?: Maybe<SuccessResponse>;
  caseFollowerRestore: CaseFollower;
  caseFollowerUpdate: CaseFollower;
  caseHistoryCreate: CaseHistory;
  caseHistoryCreateMany: CaseHistoryManyResponse;
  caseHistoryDelete?: Maybe<SuccessResponse>;
  caseHistoryDeleteByFilter?: Maybe<SuccessResponse>;
  caseHistoryDestroy?: Maybe<SuccessResponse>;
  caseHistoryDestroyByFilter?: Maybe<SuccessResponse>;
  caseHistoryRestore: CaseHistory;
  caseHistoryUpdate: CaseHistory;
  caseHistoryUpdateByFilter: CaseHistoryManyResponse;
  caseLogCreate: CaseLog;
  caseLogCreateMany: CaseLogManyResponse;
  caseLogDelete?: Maybe<SuccessResponse>;
  caseLogDeleteByFilter?: Maybe<SuccessResponse>;
  caseLogDestroy?: Maybe<SuccessResponse>;
  caseLogDestroyByFilter?: Maybe<SuccessResponse>;
  caseLogRestore: CaseLog;
  caseLogUpdate: CaseLog;
  caseLogUpdateByFilter: CaseLogManyResponse;
  caseRestore: Case;
  caseStatusLogCreate: CaseStatusLog;
  caseStatusLogCreateMany: CaseStatusLogManyResponse;
  caseStatusLogDelete?: Maybe<SuccessResponse>;
  caseStatusLogDeleteByFilter?: Maybe<SuccessResponse>;
  caseStatusLogDestroy?: Maybe<SuccessResponse>;
  caseStatusLogDestroyByFilter?: Maybe<SuccessResponse>;
  caseStatusLogRestore: CaseStatusLog;
  caseStatusLogUpdate: CaseStatusLog;
  caseStatusLogUpdateByFilter: CaseStatusLogManyResponse;
  caseUpdate: Case;
  caseUpdateByFilter: CaseManyResponse;
  caseViolationCodeCreate: CaseViolationCode;
  caseViolationCodeCreateMany: CaseViolationCodeManyResponse;
  caseViolationCodeDelete?: Maybe<SuccessResponse>;
  caseViolationCodeDeleteByFilter?: Maybe<SuccessResponse>;
  caseViolationCodeDestroy?: Maybe<SuccessResponse>;
  caseViolationCodeDestroyByFilter?: Maybe<SuccessResponse>;
  caseViolationCodeRestore: CaseViolationCode;
  caseViolationCodeUpdate: CaseViolationCode;
  ciCdMigrationCreate: CiCdMigration;
  ciCdMigrationCreateMany: CiCdMigrationManyResponse;
  ciCdMigrationDelete?: Maybe<SuccessResponse>;
  ciCdMigrationDeleteByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationDestroy?: Maybe<SuccessResponse>;
  ciCdMigrationDestroyByFilter?: Maybe<SuccessResponse>;
  ciCdMigrationRestore: CiCdMigration;
  ciCdMigrationUpdate: CiCdMigration;
  ciCdMigrationUpdateByFilter: CiCdMigrationManyResponse;
  citationCopyCreate: CitationCopy;
  citationCopyCreateMany: CitationCopyManyResponse;
  citationCopyDelete?: Maybe<SuccessResponse>;
  citationCopyDeleteByFilter?: Maybe<SuccessResponse>;
  citationCopyDestroy?: Maybe<SuccessResponse>;
  citationCopyDestroyByFilter?: Maybe<SuccessResponse>;
  citationCopyRestore: CitationCopy;
  citationCopyUpdate: CitationCopy;
  createUserAuth0: User;
  customerAlertCreate: CustomerAlert;
  customerAlertCreateMany: CustomerAlertManyResponse;
  customerAlertDelete?: Maybe<SuccessResponse>;
  customerAlertDeleteByFilter?: Maybe<SuccessResponse>;
  customerAlertDestroy?: Maybe<SuccessResponse>;
  customerAlertDestroyByFilter?: Maybe<SuccessResponse>;
  customerAlertRestore: CustomerAlert;
  customerAlertUpdate: CustomerAlert;
  customerCreate: Customer;
  customerCreateMany: CustomerManyResponse;
  customerDelete?: Maybe<SuccessResponse>;
  customerDeleteByFilter?: Maybe<SuccessResponse>;
  customerDestroy?: Maybe<SuccessResponse>;
  customerDestroyByFilter?: Maybe<SuccessResponse>;
  customerDocumentCreate: CustomerDocument;
  customerDocumentCreateMany: CustomerDocumentManyResponse;
  customerDocumentDelete?: Maybe<SuccessResponse>;
  customerDocumentDeleteByFilter?: Maybe<SuccessResponse>;
  customerDocumentDestroy?: Maybe<SuccessResponse>;
  customerDocumentDestroyByFilter?: Maybe<SuccessResponse>;
  customerDocumentRestore: CustomerDocument;
  customerDocumentUpdate: CustomerDocument;
  customerDocumentUpdateByFilter: CustomerDocumentManyResponse;
  customerRestore: Customer;
  customerUpdate: Customer;
  customerUpdateByFilter: CustomerManyResponse;
  /** @deprecated No longer supported. Use `system.deploy` instead. */
  deploy?: Maybe<Scalars['Boolean']>;
  documentDraftCreate: DocumentDraft;
  documentDraftCreateMany: DocumentDraftManyResponse;
  documentDraftDelete?: Maybe<SuccessResponse>;
  documentDraftDeleteByFilter?: Maybe<SuccessResponse>;
  documentDraftDestroy?: Maybe<SuccessResponse>;
  documentDraftDestroyByFilter?: Maybe<SuccessResponse>;
  documentDraftRestore: DocumentDraft;
  documentDraftUpdate: DocumentDraft;
  documentDraftUpdateByFilter: DocumentDraftManyResponse;
  driverCreate: Driver;
  driverCreateMany: DriverManyResponse;
  driverDelete?: Maybe<SuccessResponse>;
  driverDeleteByFilter?: Maybe<SuccessResponse>;
  driverDestroy?: Maybe<SuccessResponse>;
  driverDestroyByFilter?: Maybe<SuccessResponse>;
  driverLanguageCreate: DriverLanguage;
  driverLanguageCreateMany: DriverLanguageManyResponse;
  driverLanguageDelete?: Maybe<SuccessResponse>;
  driverLanguageDeleteByFilter?: Maybe<SuccessResponse>;
  driverLanguageDestroy?: Maybe<SuccessResponse>;
  driverLanguageDestroyByFilter?: Maybe<SuccessResponse>;
  driverLanguageRestore: DriverLanguage;
  driverLanguageUpdate: DriverLanguage;
  driverLanguageUpdateByFilter: DriverLanguageManyResponse;
  driverLicenseCreate: DriverLicense;
  driverLicenseCreateMany: DriverLicenseManyResponse;
  driverLicenseDelete?: Maybe<SuccessResponse>;
  driverLicenseDeleteByFilter?: Maybe<SuccessResponse>;
  driverLicenseDestroy?: Maybe<SuccessResponse>;
  driverLicenseDestroyByFilter?: Maybe<SuccessResponse>;
  driverLicenseRestore: DriverLicense;
  driverLicenseUpdate: DriverLicense;
  driverRestore: Driver;
  driverUpdate: Driver;
  driverUpdateByFilter: DriverManyResponse;
  environmentVariableCreate: EnvironmentVariable;
  environmentVariableCreateMany: EnvironmentVariableManyResponse;
  environmentVariableDelete?: Maybe<SuccessResponse>;
  environmentVariableDeleteByFilter?: Maybe<SuccessResponse>;
  environmentVariableDestroy?: Maybe<SuccessResponse>;
  environmentVariableDestroyByFilter?: Maybe<SuccessResponse>;
  environmentVariableRestore: EnvironmentVariable;
  environmentVariableUpdate: EnvironmentVariable;
  environmentVariableUpdateByFilter: EnvironmentVariableManyResponse;
  everSignDocCreate: EverSignDoc;
  everSignDocCreateMany: EverSignDocManyResponse;
  everSignDocDelete?: Maybe<SuccessResponse>;
  everSignDocDeleteByFilter?: Maybe<SuccessResponse>;
  everSignDocDestroy?: Maybe<SuccessResponse>;
  everSignDocDestroyByFilter?: Maybe<SuccessResponse>;
  everSignDocRestore: EverSignDoc;
  everSignDocUpdate: EverSignDoc;
  everSignDocUpdateByFilter: EverSignDocManyResponse;
  evidenceCreate: Evidence;
  evidenceCreateMany: EvidenceManyResponse;
  evidenceDelete?: Maybe<SuccessResponse>;
  evidenceDeleteByFilter?: Maybe<SuccessResponse>;
  evidenceDestroy?: Maybe<SuccessResponse>;
  evidenceDestroyByFilter?: Maybe<SuccessResponse>;
  evidenceRestore: Evidence;
  evidenceUpdate: Evidence;
  featureFlagCreate: FeatureFlag;
  featureFlagCreateMany: FeatureFlagManyResponse;
  featureFlagDelete?: Maybe<SuccessResponse>;
  featureFlagDeleteByFilter?: Maybe<SuccessResponse>;
  featureFlagDestroy?: Maybe<SuccessResponse>;
  featureFlagDestroyByFilter?: Maybe<SuccessResponse>;
  featureFlagRestore: FeatureFlag;
  featureFlagUpdate: FeatureFlag;
  featureFlagUpdateByFilter: FeatureFlagManyResponse;
  /** @deprecated No longer supported. Use `system.fieldCreate` instead. */
  fieldCreate: TableField;
  /** @deprecated No longer supported. Use `system.fieldDelete` instead. */
  fieldDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.fieldUpdate` instead. */
  fieldUpdate: TableField;
  /** @deprecated No longer supported. Use `system.fieldUpdatePosition` instead. */
  fieldUpdatePosition: SuccessResponse;
  fileCreate: File;
  fileCreateMany: FileManyResponse;
  fileDelete?: Maybe<SuccessResponse>;
  fileDeleteByFilter?: Maybe<SuccessResponse>;
  fileDestroy?: Maybe<SuccessResponse>;
  fileDestroyByFilter?: Maybe<SuccessResponse>;
  fileRestore: File;
  fileUpdate: File;
  fileUpdateByFilter: FileManyResponse;
  filesSettingCreate: FilesSetting;
  filesSettingCreateMany: FilesSettingManyResponse;
  filesSettingDelete?: Maybe<SuccessResponse>;
  filesSettingDeleteByFilter?: Maybe<SuccessResponse>;
  filesSettingDestroy?: Maybe<SuccessResponse>;
  filesSettingDestroyByFilter?: Maybe<SuccessResponse>;
  filesSettingRestore: FilesSetting;
  filesSettingUpdate: FilesSetting;
  filesSettingUpdateByFilter: FilesSettingManyResponse;
  indexCreate: TableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: TableIndex;
  /** @deprecated No longer supported. Use `system.invoke` instead. */
  invoke?: Maybe<InvokeFunctionResponse>;
  mVRLogCreate: MvrLog;
  mVRLogCreateMany: MvrLogManyResponse;
  mVRLogDelete?: Maybe<SuccessResponse>;
  mVRLogDeleteByFilter?: Maybe<SuccessResponse>;
  mVRLogDestroy?: Maybe<SuccessResponse>;
  mVRLogDestroyByFilter?: Maybe<SuccessResponse>;
  mVRLogRestore: MvrLog;
  mVRLogUpdate: MvrLog;
  mVRLogUpdateByFilter: MvrLogManyResponse;
  notificationCreate: Notification;
  notificationCreateMany: NotificationManyResponse;
  notificationDelete?: Maybe<SuccessResponse>;
  notificationDeleteByFilter?: Maybe<SuccessResponse>;
  notificationDestroy?: Maybe<SuccessResponse>;
  notificationDestroyByFilter?: Maybe<SuccessResponse>;
  notificationLogCreate: NotificationLog;
  notificationLogCreateMany: NotificationLogManyResponse;
  notificationLogDelete?: Maybe<SuccessResponse>;
  notificationLogDeleteByFilter?: Maybe<SuccessResponse>;
  notificationLogDestroy?: Maybe<SuccessResponse>;
  notificationLogDestroyByFilter?: Maybe<SuccessResponse>;
  notificationLogRestore: NotificationLog;
  notificationLogUpdate: NotificationLog;
  notificationLogUpdateByFilter: NotificationLogManyResponse;
  notificationObjectCreate: NotificationObject;
  notificationObjectCreateMany: NotificationObjectManyResponse;
  notificationObjectDelete?: Maybe<SuccessResponse>;
  notificationObjectDeleteByFilter?: Maybe<SuccessResponse>;
  notificationObjectDestroy?: Maybe<SuccessResponse>;
  notificationObjectDestroyByFilter?: Maybe<SuccessResponse>;
  notificationObjectRestore: NotificationObject;
  notificationObjectUpdate: NotificationObject;
  notificationObjectUpdateByFilter: NotificationObjectManyResponse;
  notificationRestore: Notification;
  notificationSettingCreate: NotificationSetting;
  notificationSettingCreateMany: NotificationSettingManyResponse;
  notificationSettingDelete?: Maybe<SuccessResponse>;
  notificationSettingDeleteByFilter?: Maybe<SuccessResponse>;
  notificationSettingDestroy?: Maybe<SuccessResponse>;
  notificationSettingDestroyByFilter?: Maybe<SuccessResponse>;
  notificationSettingRestore: NotificationSetting;
  notificationSettingUpdate: NotificationSetting;
  notificationSettingUpdateByFilter: NotificationSettingManyResponse;
  notificationUpdate: Notification;
  notificationUpdateByFilter: NotificationManyResponse;
  /** @deprecated No longer supported. Use `system.prepareDeploy` instead. */
  prepareDeploy: DeployDataResponse;
  roleCreate: Role;
  roleCreateMany: RoleManyResponse;
  roleDelete?: Maybe<SuccessResponse>;
  roleDeleteByFilter?: Maybe<SuccessResponse>;
  roleDestroy?: Maybe<SuccessResponse>;
  roleDestroyByFilter?: Maybe<SuccessResponse>;
  roleRestore: Role;
  roleUpdate: Role;
  roleUpdateByFilter: RoleManyResponse;
  sendEmail?: Maybe<SendEmailResult>;
  sendInvitationTo8base?: Maybe<SuccessResponse>;
  settingsUpdate: Setting;
  system?: Maybe<SystemMutation>;
  /** @deprecated No longer supported. Use `system.tableCreate` instead. */
  tableCreate: Table;
  /** @deprecated No longer supported. Use `system.tableDelete` instead. */
  tableDelete: SuccessResponse;
  /** @deprecated No longer supported. Use `system.tableUpdate` instead. */
  tableUpdate: Table;
  usStateCreate: UsState;
  usStateCreateMany: UsStateManyResponse;
  usStateDelete?: Maybe<SuccessResponse>;
  usStateDeleteByFilter?: Maybe<SuccessResponse>;
  usStateDestroy?: Maybe<SuccessResponse>;
  usStateDestroyByFilter?: Maybe<SuccessResponse>;
  usStateRestore: UsState;
  usStateUpdate: UsState;
  usStateUpdateByFilter: UsStateManyResponse;
  userChangePassword?: Maybe<SuccessResponse>;
  userCreate: User;
  userCreateMany: UserManyResponse;
  userDelete?: Maybe<SuccessResponse>;
  userDeleteByFilter?: Maybe<SuccessResponse>;
  userDestroy?: Maybe<SuccessResponse>;
  userDestroyByFilter?: Maybe<SuccessResponse>;
  userLogin?: Maybe<LoginResponse>;
  userPasswordForgot?: Maybe<SuccessResponse>;
  userPasswordForgotConfirm?: Maybe<SuccessResponse>;
  userRefreshToken?: Maybe<Auth>;
  userRestore: User;
  /** @deprecated No longer supported. Use `userSignUpWithToken` instead. */
  userSignUp: User;
  /** @deprecated No longer supported. Use `userVerificationEmailResend` instead. */
  userSignUpResend?: Maybe<SuccessResponse>;
  userSignUpWithPassword: User;
  userSignUpWithToken: User;
  userUpdate: User;
  userUpdateByFilter: UserManyResponse;
  userVerificationEmailResend?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.viewCreate` instead. */
  viewCreate: Table;
  viewUpdate: Table;
  violationCodeCreate: ViolationCode;
  violationCodeCreateMany: ViolationCodeManyResponse;
  violationCodeDelete?: Maybe<SuccessResponse>;
  violationCodeDeleteByFilter?: Maybe<SuccessResponse>;
  violationCodeDestroy?: Maybe<SuccessResponse>;
  violationCodeDestroyByFilter?: Maybe<SuccessResponse>;
  violationCodeRestore: ViolationCode;
  violationCodeUpdate: ViolationCode;
  violationCodeUpdateByFilter: ViolationCodeManyResponse;
  /** @deprecated No longer supported. Use `system.workspaceCreate` instead. */
  workspaceCreate?: Maybe<WorkspaceCreateResponse>;
  /** @deprecated No longer supported. Use `system.workspaceCreateAsync` instead. */
  workspaceCreateAsync?: Maybe<WorkspaceCreateResponse>;
  workspaceDelete?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceLeave` instead. */
  workspaceLeave?: Maybe<SuccessResponse>;
  /** @deprecated No longer supported. Use `system.workspaceUpdate` instead. */
  workspaceUpdate?: Maybe<WorkspaceUpdateResponse>;
};

export type MutationImportCsvArgs = {
  data: ImportCsv;
};

export type MutationIsSocialUserArgs = {
  data: IsSocialUser;
};

export type MutationAddressCreateArgs = {
  data: AddressCreateInput;
};

export type MutationAddressCreateManyArgs = {
  data: Array<Maybe<AddressCreateManyInput>>;
};

export type MutationAddressDeleteArgs = {
  data?: Maybe<AddressDeleteInput>;
  filter?: Maybe<AddressKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAddressDeleteByFilterArgs = {
  filter: AddressFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAddressDestroyArgs = {
  filter?: Maybe<AddressKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAddressDestroyByFilterArgs = {
  filter: AddressFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAddressRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAddressUpdateArgs = {
  data: AddressUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AddressKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAddressUpdateByFilterArgs = {
  data: AddressUpdateByFilterInput;
  filter?: Maybe<AddressFilter>;
};

export type MutationAlertCreateArgs = {
  data: AlertCreateInput;
};

export type MutationAlertCreateManyArgs = {
  data: Array<Maybe<AlertCreateManyInput>>;
};

export type MutationAlertDeleteArgs = {
  data?: Maybe<AlertDeleteInput>;
  filter?: Maybe<AlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAlertDeleteByFilterArgs = {
  filter: AlertFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAlertDestroyArgs = {
  filter?: Maybe<AlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAlertDestroyByFilterArgs = {
  filter: AlertFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAlertRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAlertUpdateArgs = {
  data: AlertUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAlertUpdateByFilterArgs = {
  data: AlertUpdateByFilterInput;
  filter?: Maybe<AlertFilter>;
};

export type MutationApiTokenCreateArgs = {
  data: ApiTokenCreateInput;
};

export type MutationApiTokenDeleteArgs = {
  data?: Maybe<ApiTokenDeleteInput>;
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDeleteByFilterArgs = {
  filter: ApiTokenFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyArgs = {
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenDestroyByFilterArgs = {
  filter: ApiTokenFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenRestoreArgs = {
  id: Scalars['String'];
};

export type MutationApiTokenUpdateArgs = {
  data: ApiTokenUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ApiTokenKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationApiTokenUpdateByFilterArgs = {
  data: ApiTokenUpdateByFilterInput;
  filter?: Maybe<ApiTokenFilter>;
};

export type MutationApplicationDeleteArgs = {
  data: ApplicationDeleteMutationInput;
};

export type MutationApplicationInstallArgs = {
  data: ApplicationInstallInput;
};

export type MutationApplicationUpdateArgs = {
  data: ApplicationUpdateInput;
};

export type MutationAttorneyCreateArgs = {
  data: AttorneyCreateInput;
};

export type MutationAttorneyCreateManyArgs = {
  data: Array<Maybe<AttorneyCreateManyInput>>;
};

export type MutationAttorneyDeleteArgs = {
  data?: Maybe<AttorneyDeleteInput>;
  filter?: Maybe<AttorneyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAttorneyDeleteByFilterArgs = {
  filter: AttorneyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAttorneyDestroyArgs = {
  filter?: Maybe<AttorneyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAttorneyDestroyByFilterArgs = {
  filter: AttorneyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAttorneyRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAttorneyUpdateArgs = {
  data: AttorneyUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AttorneyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAttorneyUpdateByFilterArgs = {
  data: AttorneyUpdateByFilterInput;
  filter?: Maybe<AttorneyFilter>;
};

export type MutationAuthenticationProfileCreateArgs = {
  data: AuthenticationProfileCreateInput;
};

export type MutationAuthenticationProfileCreateManyArgs = {
  data: Array<Maybe<AuthenticationProfileCreateManyInput>>;
};

export type MutationAuthenticationProfileDeleteArgs = {
  data?: Maybe<AuthenticationProfileDeleteInput>;
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDeleteByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyArgs = {
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileDestroyByFilterArgs = {
  filter: AuthenticationProfileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationAuthenticationProfileUpdateArgs = {
  data: AuthenticationProfileUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AuthenticationProfileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationAuthenticationProfileUpdateByFilterArgs = {
  data: AuthenticationProfileUpdateByFilterInput;
  filter?: Maybe<AuthenticationProfileFilter>;
};

export type MutationAuthenticationSettingsUpdateArgs = {
  data: AuthenticationSettingUpdateInput;
};

export type MutationBillingPlanUpdateArgs = {
  data: BillingPlanUpdateMutationInput;
};

export type MutationCaseCommentCreateArgs = {
  data: CaseCommentCreateInput;
};

export type MutationCaseCommentCreateManyArgs = {
  data: Array<Maybe<CaseCommentCreateManyInput>>;
};

export type MutationCaseCommentDeleteArgs = {
  data?: Maybe<CaseCommentDeleteInput>;
  filter?: Maybe<CaseCommentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentDeleteByFilterArgs = {
  filter: CaseCommentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentDestroyArgs = {
  filter?: Maybe<CaseCommentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentDestroyByFilterArgs = {
  filter: CaseCommentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyCreateArgs = {
  data: CaseCommentReplyCreateInput;
};

export type MutationCaseCommentReplyCreateManyArgs = {
  data: Array<Maybe<CaseCommentReplyCreateManyInput>>;
};

export type MutationCaseCommentReplyDeleteArgs = {
  data?: Maybe<CaseCommentReplyDeleteInput>;
  filter?: Maybe<CaseCommentReplyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyDeleteByFilterArgs = {
  filter: CaseCommentReplyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyDestroyArgs = {
  filter?: Maybe<CaseCommentReplyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyDestroyByFilterArgs = {
  filter: CaseCommentReplyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseCommentReplyUpdateArgs = {
  data: CaseCommentReplyUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseCommentReplyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentReplyUpdateByFilterArgs = {
  data: CaseCommentReplyUpdateByFilterInput;
  filter?: Maybe<CaseCommentReplyFilter>;
};

export type MutationCaseCommentRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseCommentUpdateArgs = {
  data: CaseCommentUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseCommentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseCommentUpdateByFilterArgs = {
  data: CaseCommentUpdateByFilterInput;
  filter?: Maybe<CaseCommentFilter>;
};

export type MutationCaseCreateArgs = {
  data: CaseCreateInput;
};

export type MutationCaseCreateManyArgs = {
  data: Array<Maybe<CaseCreateManyInput>>;
};

export type MutationCaseDeleteArgs = {
  data?: Maybe<CaseDeleteInput>;
  filter?: Maybe<CaseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDeleteByFilterArgs = {
  filter: CaseFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDestroyArgs = {
  filter?: Maybe<CaseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDestroyByFilterArgs = {
  filter: CaseFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentCreateArgs = {
  data: CaseDocumentCreateInput;
};

export type MutationCaseDocumentCreateManyArgs = {
  data: Array<Maybe<CaseDocumentCreateManyInput>>;
};

export type MutationCaseDocumentDeleteArgs = {
  data?: Maybe<CaseDocumentDeleteInput>;
  filter?: Maybe<CaseDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentDeleteByFilterArgs = {
  filter: CaseDocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentDeleteManagementArgs = {
  filter: CaseDocumentDeleteManagementFilter;
};

export type MutationCaseDocumentDestroyArgs = {
  filter?: Maybe<CaseDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentDestroyByFilterArgs = {
  filter: CaseDocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseDocumentUpdateArgs = {
  data: CaseDocumentUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseDocumentUpdateByFilterArgs = {
  data: CaseDocumentUpdateByFilterInput;
  filter?: Maybe<CaseDocumentFilter>;
};

export type MutationCaseFollowerCreateArgs = {
  data: CaseFollowerCreateInput;
};

export type MutationCaseFollowerCreateManyArgs = {
  data: Array<Maybe<CaseFollowerCreateManyInput>>;
};

export type MutationCaseFollowerDeleteArgs = {
  data?: Maybe<CaseFollowerDeleteInput>;
  filter?: Maybe<CaseFollowerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseFollowerDeleteByFilterArgs = {
  filter: CaseFollowerFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseFollowerDestroyArgs = {
  filter?: Maybe<CaseFollowerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseFollowerDestroyByFilterArgs = {
  filter: CaseFollowerFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseFollowerRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseFollowerUpdateArgs = {
  data: CaseFollowerUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseFollowerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryCreateArgs = {
  data: CaseHistoryCreateInput;
};

export type MutationCaseHistoryCreateManyArgs = {
  data: Array<Maybe<CaseHistoryCreateManyInput>>;
};

export type MutationCaseHistoryDeleteArgs = {
  data?: Maybe<CaseHistoryDeleteInput>;
  filter?: Maybe<CaseHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryDeleteByFilterArgs = {
  filter: CaseHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryDestroyArgs = {
  filter?: Maybe<CaseHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryDestroyByFilterArgs = {
  filter: CaseHistoryFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseHistoryUpdateArgs = {
  data: CaseHistoryUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseHistoryKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseHistoryUpdateByFilterArgs = {
  data: CaseHistoryUpdateByFilterInput;
  filter?: Maybe<CaseHistoryFilter>;
};

export type MutationCaseLogCreateArgs = {
  data: CaseLogCreateInput;
};

export type MutationCaseLogCreateManyArgs = {
  data: Array<Maybe<CaseLogCreateManyInput>>;
};

export type MutationCaseLogDeleteArgs = {
  data?: Maybe<CaseLogDeleteInput>;
  filter?: Maybe<CaseLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseLogDeleteByFilterArgs = {
  filter: CaseLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseLogDestroyArgs = {
  filter?: Maybe<CaseLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseLogDestroyByFilterArgs = {
  filter: CaseLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseLogRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseLogUpdateArgs = {
  data: CaseLogUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseLogUpdateByFilterArgs = {
  data: CaseLogUpdateByFilterInput;
  filter?: Maybe<CaseLogFilter>;
};

export type MutationCaseRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseStatusLogCreateArgs = {
  data: CaseStatusLogCreateInput;
};

export type MutationCaseStatusLogCreateManyArgs = {
  data: Array<Maybe<CaseStatusLogCreateManyInput>>;
};

export type MutationCaseStatusLogDeleteArgs = {
  data?: Maybe<CaseStatusLogDeleteInput>;
  filter?: Maybe<CaseStatusLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseStatusLogDeleteByFilterArgs = {
  filter: CaseStatusLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseStatusLogDestroyArgs = {
  filter?: Maybe<CaseStatusLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseStatusLogDestroyByFilterArgs = {
  filter: CaseStatusLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseStatusLogRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseStatusLogUpdateArgs = {
  data: CaseStatusLogUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseStatusLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseStatusLogUpdateByFilterArgs = {
  data: CaseStatusLogUpdateByFilterInput;
  filter?: Maybe<CaseStatusLogFilter>;
};

export type MutationCaseUpdateArgs = {
  data: CaseUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseUpdateByFilterArgs = {
  data: CaseUpdateByFilterInput;
  filter?: Maybe<CaseFilter>;
};

export type MutationCaseViolationCodeCreateArgs = {
  data: CaseViolationCodeCreateInput;
};

export type MutationCaseViolationCodeCreateManyArgs = {
  data: Array<Maybe<CaseViolationCodeCreateManyInput>>;
};

export type MutationCaseViolationCodeDeleteArgs = {
  data?: Maybe<CaseViolationCodeDeleteInput>;
  filter?: Maybe<CaseViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseViolationCodeDeleteByFilterArgs = {
  filter: CaseViolationCodeFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseViolationCodeDestroyArgs = {
  filter?: Maybe<CaseViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseViolationCodeDestroyByFilterArgs = {
  filter: CaseViolationCodeFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCaseViolationCodeRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCaseViolationCodeUpdateArgs = {
  data: CaseViolationCodeUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CaseViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationCreateArgs = {
  data: CiCdMigrationCreateInput;
};

export type MutationCiCdMigrationCreateManyArgs = {
  data: Array<Maybe<CiCdMigrationCreateManyInput>>;
};

export type MutationCiCdMigrationDeleteArgs = {
  data?: Maybe<CiCdMigrationDeleteInput>;
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDeleteByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyArgs = {
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationDestroyByFilterArgs = {
  filter: CiCdMigrationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCiCdMigrationUpdateArgs = {
  data: CiCdMigrationUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CiCdMigrationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCiCdMigrationUpdateByFilterArgs = {
  data: CiCdMigrationUpdateByFilterInput;
  filter?: Maybe<CiCdMigrationFilter>;
};

export type MutationCitationCopyCreateArgs = {
  data: CitationCopyCreateInput;
};

export type MutationCitationCopyCreateManyArgs = {
  data: Array<Maybe<CitationCopyCreateManyInput>>;
};

export type MutationCitationCopyDeleteArgs = {
  data?: Maybe<CitationCopyDeleteInput>;
  filter?: Maybe<CitationCopyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCitationCopyDeleteByFilterArgs = {
  filter: CitationCopyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCitationCopyDestroyArgs = {
  filter?: Maybe<CitationCopyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCitationCopyDestroyByFilterArgs = {
  filter: CitationCopyFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCitationCopyRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCitationCopyUpdateArgs = {
  data: CitationCopyUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CitationCopyKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateUserAuth0Args = {
  user: UserCreateInput;
};

export type MutationCustomerAlertCreateArgs = {
  data: CustomerAlertCreateInput;
};

export type MutationCustomerAlertCreateManyArgs = {
  data: Array<Maybe<CustomerAlertCreateManyInput>>;
};

export type MutationCustomerAlertDeleteArgs = {
  data?: Maybe<CustomerAlertDeleteInput>;
  filter?: Maybe<CustomerAlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerAlertDeleteByFilterArgs = {
  filter: CustomerAlertFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerAlertDestroyArgs = {
  filter?: Maybe<CustomerAlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerAlertDestroyByFilterArgs = {
  filter: CustomerAlertFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerAlertRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCustomerAlertUpdateArgs = {
  data: CustomerAlertUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerAlertKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerCreateArgs = {
  data: CustomerCreateInput;
};

export type MutationCustomerCreateManyArgs = {
  data: Array<Maybe<CustomerCreateManyInput>>;
};

export type MutationCustomerDeleteArgs = {
  data?: Maybe<CustomerDeleteInput>;
  filter?: Maybe<CustomerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDeleteByFilterArgs = {
  filter: CustomerFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDestroyArgs = {
  filter?: Maybe<CustomerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDestroyByFilterArgs = {
  filter: CustomerFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentCreateArgs = {
  data: CustomerDocumentCreateInput;
};

export type MutationCustomerDocumentCreateManyArgs = {
  data: Array<Maybe<CustomerDocumentCreateManyInput>>;
};

export type MutationCustomerDocumentDeleteArgs = {
  data?: Maybe<CustomerDocumentDeleteInput>;
  filter?: Maybe<CustomerDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentDeleteByFilterArgs = {
  filter: CustomerDocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentDestroyArgs = {
  filter?: Maybe<CustomerDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentDestroyByFilterArgs = {
  filter: CustomerDocumentFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCustomerDocumentUpdateArgs = {
  data: CustomerDocumentUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerDocumentKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerDocumentUpdateByFilterArgs = {
  data: CustomerDocumentUpdateByFilterInput;
  filter?: Maybe<CustomerDocumentFilter>;
};

export type MutationCustomerRestoreArgs = {
  id: Scalars['String'];
};

export type MutationCustomerUpdateArgs = {
  data: CustomerUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CustomerKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationCustomerUpdateByFilterArgs = {
  data: CustomerUpdateByFilterInput;
  filter?: Maybe<CustomerFilter>;
};

export type MutationDeployArgs = {
  data?: Maybe<DeployingBuildInput>;
};

export type MutationDocumentDraftCreateArgs = {
  data: DocumentDraftCreateInput;
};

export type MutationDocumentDraftCreateManyArgs = {
  data: Array<Maybe<DocumentDraftCreateManyInput>>;
};

export type MutationDocumentDraftDeleteArgs = {
  data?: Maybe<DocumentDraftDeleteInput>;
  filter?: Maybe<DocumentDraftKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDraftDeleteByFilterArgs = {
  filter: DocumentDraftFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDraftDestroyArgs = {
  filter?: Maybe<DocumentDraftKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDraftDestroyByFilterArgs = {
  filter: DocumentDraftFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDraftRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDocumentDraftUpdateArgs = {
  data: DocumentDraftUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DocumentDraftKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDocumentDraftUpdateByFilterArgs = {
  data: DocumentDraftUpdateByFilterInput;
  filter?: Maybe<DocumentDraftFilter>;
};

export type MutationDriverCreateArgs = {
  data: DriverCreateInput;
};

export type MutationDriverCreateManyArgs = {
  data: Array<Maybe<DriverCreateManyInput>>;
};

export type MutationDriverDeleteArgs = {
  data?: Maybe<DriverDeleteInput>;
  filter?: Maybe<DriverKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverDeleteByFilterArgs = {
  filter: DriverFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverDestroyArgs = {
  filter?: Maybe<DriverKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverDestroyByFilterArgs = {
  filter: DriverFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageCreateArgs = {
  data: DriverLanguageCreateInput;
};

export type MutationDriverLanguageCreateManyArgs = {
  data: Array<Maybe<DriverLanguageCreateManyInput>>;
};

export type MutationDriverLanguageDeleteArgs = {
  data?: Maybe<DriverLanguageDeleteInput>;
  filter?: Maybe<DriverLanguageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageDeleteByFilterArgs = {
  filter: DriverLanguageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageDestroyArgs = {
  filter?: Maybe<DriverLanguageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageDestroyByFilterArgs = {
  filter: DriverLanguageFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDriverLanguageUpdateArgs = {
  data: DriverLanguageUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DriverLanguageKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLanguageUpdateByFilterArgs = {
  data: DriverLanguageUpdateByFilterInput;
  filter?: Maybe<DriverLanguageFilter>;
};

export type MutationDriverLicenseCreateArgs = {
  data: DriverLicenseCreateInput;
};

export type MutationDriverLicenseCreateManyArgs = {
  data: Array<Maybe<DriverLicenseCreateManyInput>>;
};

export type MutationDriverLicenseDeleteArgs = {
  data?: Maybe<DriverLicenseDeleteInput>;
  filter?: Maybe<DriverLicenseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLicenseDeleteByFilterArgs = {
  filter: DriverLicenseFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLicenseDestroyArgs = {
  filter?: Maybe<DriverLicenseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLicenseDestroyByFilterArgs = {
  filter: DriverLicenseFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverLicenseRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDriverLicenseUpdateArgs = {
  data: DriverLicenseUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DriverLicenseKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverRestoreArgs = {
  id: Scalars['String'];
};

export type MutationDriverUpdateArgs = {
  data: DriverUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<DriverKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationDriverUpdateByFilterArgs = {
  data: DriverUpdateByFilterInput;
  filter?: Maybe<DriverFilter>;
};

export type MutationEnvironmentVariableCreateArgs = {
  data: EnvironmentVariableCreateInput;
};

export type MutationEnvironmentVariableCreateManyArgs = {
  data: Array<Maybe<EnvironmentVariableCreateManyInput>>;
};

export type MutationEnvironmentVariableDeleteArgs = {
  data?: Maybe<EnvironmentVariableDeleteInput>;
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDeleteByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyArgs = {
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableDestroyByFilterArgs = {
  filter: EnvironmentVariableFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEnvironmentVariableUpdateArgs = {
  data: EnvironmentVariableUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<EnvironmentVariableKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEnvironmentVariableUpdateByFilterArgs = {
  data: EnvironmentVariableUpdateByFilterInput;
  filter?: Maybe<EnvironmentVariableFilter>;
};

export type MutationEverSignDocCreateArgs = {
  data: EverSignDocCreateInput;
};

export type MutationEverSignDocCreateManyArgs = {
  data: Array<Maybe<EverSignDocCreateManyInput>>;
};

export type MutationEverSignDocDeleteArgs = {
  data?: Maybe<EverSignDocDeleteInput>;
  filter?: Maybe<EverSignDocKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEverSignDocDeleteByFilterArgs = {
  filter: EverSignDocFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEverSignDocDestroyArgs = {
  filter?: Maybe<EverSignDocKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEverSignDocDestroyByFilterArgs = {
  filter: EverSignDocFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEverSignDocRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEverSignDocUpdateArgs = {
  data: EverSignDocUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<EverSignDocKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEverSignDocUpdateByFilterArgs = {
  data: EverSignDocUpdateByFilterInput;
  filter?: Maybe<EverSignDocFilter>;
};

export type MutationEvidenceCreateArgs = {
  data: EvidenceCreateInput;
};

export type MutationEvidenceCreateManyArgs = {
  data: Array<Maybe<EvidenceCreateManyInput>>;
};

export type MutationEvidenceDeleteArgs = {
  data?: Maybe<EvidenceDeleteInput>;
  filter?: Maybe<EvidenceKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEvidenceDeleteByFilterArgs = {
  filter: EvidenceFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEvidenceDestroyArgs = {
  filter?: Maybe<EvidenceKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEvidenceDestroyByFilterArgs = {
  filter: EvidenceFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationEvidenceRestoreArgs = {
  id: Scalars['String'];
};

export type MutationEvidenceUpdateArgs = {
  data: EvidenceUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<EvidenceKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagCreateArgs = {
  data: FeatureFlagCreateInput;
};

export type MutationFeatureFlagCreateManyArgs = {
  data: Array<Maybe<FeatureFlagCreateManyInput>>;
};

export type MutationFeatureFlagDeleteArgs = {
  data?: Maybe<FeatureFlagDeleteInput>;
  filter?: Maybe<FeatureFlagKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagDeleteByFilterArgs = {
  filter: FeatureFlagFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagDestroyArgs = {
  filter?: Maybe<FeatureFlagKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagDestroyByFilterArgs = {
  filter: FeatureFlagFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFeatureFlagUpdateArgs = {
  data: FeatureFlagUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FeatureFlagKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFeatureFlagUpdateByFilterArgs = {
  data: FeatureFlagUpdateByFilterInput;
  filter?: Maybe<FeatureFlagFilter>;
};

export type MutationFieldCreateArgs = {
  data: TableFieldCreateInput;
};

export type MutationFieldDeleteArgs = {
  data: TableFieldDeleteInput;
};

export type MutationFieldUpdateArgs = {
  data: TableFieldUpdateInput;
};

export type MutationFieldUpdatePositionArgs = {
  data: TableFieldPositionUpdateInput;
};

export type MutationFileCreateArgs = {
  data: FileCreateInput;
};

export type MutationFileCreateManyArgs = {
  data: Array<Maybe<FileCreateManyInput>>;
};

export type MutationFileDeleteArgs = {
  data?: Maybe<FileDeleteInput>;
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDeleteByFilterArgs = {
  filter: FileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDestroyArgs = {
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileDestroyByFilterArgs = {
  filter: FileFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFileUpdateArgs = {
  data: FileUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FileKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFileUpdateByFilterArgs = {
  data: FileUpdateByFilterInput;
  filter?: Maybe<FileFilter>;
};

export type MutationFilesSettingCreateArgs = {
  data: FilesSettingCreateInput;
};

export type MutationFilesSettingCreateManyArgs = {
  data: Array<Maybe<FilesSettingCreateManyInput>>;
};

export type MutationFilesSettingDeleteArgs = {
  data?: Maybe<FilesSettingDeleteInput>;
  filter?: Maybe<FilesSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDeleteByFilterArgs = {
  filter: FilesSettingFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDestroyArgs = {
  filter?: Maybe<FilesSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFilesSettingDestroyByFilterArgs = {
  filter: FilesSettingFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFilesSettingRestoreArgs = {
  id: Scalars['String'];
};

export type MutationFilesSettingUpdateArgs = {
  data: FilesSettingUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FilesSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationFilesSettingUpdateByFilterArgs = {
  data: FilesSettingUpdateByFilterInput;
  filter?: Maybe<FilesSettingFilter>;
};

export type MutationIndexCreateArgs = {
  data: IndexCreateInput;
};

export type MutationIndexDeleteArgs = {
  data: IndexDeleteInput;
};

export type MutationIndexUpdateArgs = {
  data: IndexUpdateInput;
};

export type MutationInvokeArgs = {
  data?: Maybe<InvokeData>;
};

export type MutationMVrLogCreateArgs = {
  data: MvrLogCreateInput;
};

export type MutationMVrLogCreateManyArgs = {
  data: Array<Maybe<MvrLogCreateManyInput>>;
};

export type MutationMVrLogDeleteArgs = {
  data?: Maybe<MvrLogDeleteInput>;
  filter?: Maybe<MvrLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMVrLogDeleteByFilterArgs = {
  filter: MvrLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMVrLogDestroyArgs = {
  filter?: Maybe<MvrLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMVrLogDestroyByFilterArgs = {
  filter: MvrLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMVrLogRestoreArgs = {
  id: Scalars['String'];
};

export type MutationMVrLogUpdateArgs = {
  data: MvrLogUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<MvrLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationMVrLogUpdateByFilterArgs = {
  data: MvrLogUpdateByFilterInput;
  filter?: Maybe<MvrLogFilter>;
};

export type MutationNotificationCreateArgs = {
  data: NotificationCreateInput;
};

export type MutationNotificationCreateManyArgs = {
  data: Array<Maybe<NotificationCreateManyInput>>;
};

export type MutationNotificationDeleteArgs = {
  data?: Maybe<NotificationDeleteInput>;
  filter?: Maybe<NotificationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationDeleteByFilterArgs = {
  filter: NotificationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationDestroyArgs = {
  filter?: Maybe<NotificationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationDestroyByFilterArgs = {
  filter: NotificationFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogCreateArgs = {
  data: NotificationLogCreateInput;
};

export type MutationNotificationLogCreateManyArgs = {
  data: Array<Maybe<NotificationLogCreateManyInput>>;
};

export type MutationNotificationLogDeleteArgs = {
  data?: Maybe<NotificationLogDeleteInput>;
  filter?: Maybe<NotificationLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogDeleteByFilterArgs = {
  filter: NotificationLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogDestroyArgs = {
  filter?: Maybe<NotificationLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogDestroyByFilterArgs = {
  filter: NotificationLogFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNotificationLogUpdateArgs = {
  data: NotificationLogUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<NotificationLogKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationLogUpdateByFilterArgs = {
  data: NotificationLogUpdateByFilterInput;
  filter?: Maybe<NotificationLogFilter>;
};

export type MutationNotificationObjectCreateArgs = {
  data: NotificationObjectCreateInput;
};

export type MutationNotificationObjectCreateManyArgs = {
  data: Array<Maybe<NotificationObjectCreateManyInput>>;
};

export type MutationNotificationObjectDeleteArgs = {
  data?: Maybe<NotificationObjectDeleteInput>;
  filter?: Maybe<NotificationObjectKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationObjectDeleteByFilterArgs = {
  filter: NotificationObjectFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationObjectDestroyArgs = {
  filter?: Maybe<NotificationObjectKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationObjectDestroyByFilterArgs = {
  filter: NotificationObjectFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationObjectRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNotificationObjectUpdateArgs = {
  data: NotificationObjectUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<NotificationObjectKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationObjectUpdateByFilterArgs = {
  data: NotificationObjectUpdateByFilterInput;
  filter?: Maybe<NotificationObjectFilter>;
};

export type MutationNotificationRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNotificationSettingCreateArgs = {
  data: NotificationSettingCreateInput;
};

export type MutationNotificationSettingCreateManyArgs = {
  data: Array<Maybe<NotificationSettingCreateManyInput>>;
};

export type MutationNotificationSettingDeleteArgs = {
  data?: Maybe<NotificationSettingDeleteInput>;
  filter?: Maybe<NotificationSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationSettingDeleteByFilterArgs = {
  filter: NotificationSettingFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationSettingDestroyArgs = {
  filter?: Maybe<NotificationSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationSettingDestroyByFilterArgs = {
  filter: NotificationSettingFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationSettingRestoreArgs = {
  id: Scalars['String'];
};

export type MutationNotificationSettingUpdateArgs = {
  data: NotificationSettingUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<NotificationSettingKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationSettingUpdateByFilterArgs = {
  data: NotificationSettingUpdateByFilterInput;
  filter?: Maybe<NotificationSettingFilter>;
};

export type MutationNotificationUpdateArgs = {
  data: NotificationUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<NotificationKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationNotificationUpdateByFilterArgs = {
  data: NotificationUpdateByFilterInput;
  filter?: Maybe<NotificationFilter>;
};

export type MutationRoleCreateArgs = {
  data: RoleCreateInput;
};

export type MutationRoleCreateManyArgs = {
  data: Array<Maybe<RoleCreateManyInput>>;
};

export type MutationRoleDeleteArgs = {
  data?: Maybe<RoleDeleteInput>;
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDeleteByFilterArgs = {
  filter: RoleFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyArgs = {
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleDestroyByFilterArgs = {
  filter: RoleFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleRestoreArgs = {
  id: Scalars['String'];
};

export type MutationRoleUpdateArgs = {
  data: RoleUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<RoleKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationRoleUpdateByFilterArgs = {
  data: RoleUpdateByFilterInput;
  filter?: Maybe<RoleFilter>;
};

export type MutationSendEmailArgs = {
  recipient: Scalars['String'];
};

export type MutationSendInvitationTo8baseArgs = {
  inviteEmail: Scalars['String'];
};

export type MutationSettingsUpdateArgs = {
  data: SettingUpdateInput;
};

export type MutationTableCreateArgs = {
  data: TableCreateInput;
};

export type MutationTableDeleteArgs = {
  data: TableDeleteInput;
};

export type MutationTableUpdateArgs = {
  data: TableUpdateInput;
};

export type MutationUsStateCreateArgs = {
  data: UsStateCreateInput;
};

export type MutationUsStateCreateManyArgs = {
  data: Array<Maybe<UsStateCreateManyInput>>;
};

export type MutationUsStateDeleteArgs = {
  data?: Maybe<UsStateDeleteInput>;
  filter?: Maybe<UsStateKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUsStateDeleteByFilterArgs = {
  filter: UsStateFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUsStateDestroyArgs = {
  filter?: Maybe<UsStateKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUsStateDestroyByFilterArgs = {
  filter: UsStateFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUsStateRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUsStateUpdateArgs = {
  data: UsStateUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UsStateKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUsStateUpdateByFilterArgs = {
  data: UsStateUpdateByFilterInput;
  filter?: Maybe<UsStateFilter>;
};

export type MutationUserChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationUserCreateArgs = {
  data: UserCreateInput;
};

export type MutationUserCreateManyArgs = {
  data: Array<Maybe<UserCreateManyInput>>;
};

export type MutationUserDeleteArgs = {
  data?: Maybe<UserDeleteInput>;
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDeleteByFilterArgs = {
  filter: UserFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDestroyArgs = {
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserDestroyByFilterArgs = {
  filter: UserFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserLoginArgs = {
  data: UserLoginInput;
};

export type MutationUserPasswordForgotArgs = {
  data: PasswordForgotInput;
};

export type MutationUserPasswordForgotConfirmArgs = {
  data: PasswordForgotConfirmInput;
};

export type MutationUserRefreshTokenArgs = {
  data: RefreshTokenInput;
};

export type MutationUserRestoreArgs = {
  id: Scalars['String'];
};

export type MutationUserSignUpArgs = {
  authProfileId?: Maybe<Scalars['ID']>;
  user: UserCreateInput;
};

export type MutationUserSignUpResendArgs = {
  data: SignUpResendInput;
};

export type MutationUserSignUpWithPasswordArgs = {
  authProfileId?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
  user: UserCreateInput;
};

export type MutationUserSignUpWithTokenArgs = {
  authProfileId?: Maybe<Scalars['ID']>;
  user: UserCreateInput;
};

export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<UserKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationUserUpdateByFilterArgs = {
  data: UserUpdateByFilterInput;
  filter?: Maybe<UserFilter>;
};

export type MutationUserVerificationEmailResendArgs = {
  authProfileId?: Maybe<Scalars['ID']>;
  data: VerificationEmailResendInput;
};

export type MutationViewCreateArgs = {
  data: ViewCreateInput;
};

export type MutationViewUpdateArgs = {
  data: ViewUpdateInput;
};

export type MutationViolationCodeCreateArgs = {
  data: ViolationCodeCreateInput;
};

export type MutationViolationCodeCreateManyArgs = {
  data: Array<Maybe<ViolationCodeCreateManyInput>>;
};

export type MutationViolationCodeDeleteArgs = {
  data?: Maybe<ViolationCodeDeleteInput>;
  filter?: Maybe<ViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationViolationCodeDeleteByFilterArgs = {
  filter: ViolationCodeFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationViolationCodeDestroyArgs = {
  filter?: Maybe<ViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationViolationCodeDestroyByFilterArgs = {
  filter: ViolationCodeFilter;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationViolationCodeRestoreArgs = {
  id: Scalars['String'];
};

export type MutationViolationCodeUpdateArgs = {
  data: ViolationCodeUpdateInput;
  destroyDetached?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ViolationCodeKeyFilter>;
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationViolationCodeUpdateByFilterArgs = {
  data: ViolationCodeUpdateByFilterInput;
  filter?: Maybe<ViolationCodeFilter>;
};

export type MutationWorkspaceCreateArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceCreateAsyncArgs = {
  data: WorkspaceCreateMutationInput;
};

export type MutationWorkspaceDeleteArgs = {
  data: WorkspaceDeleteMutationInput;
};

export type MutationWorkspaceLeaveArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export type MutationWorkspaceUpdateArgs = {
  data: WorkspaceUpdateMutationInput;
};

export enum MutationType {
  Create = 'create',
  Delete = 'delete',
  Destroy = 'destroy',
  Update = 'update',
}

/** Driver create input from mvrLogRelation */
export type MvrLogRelation_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer?: Maybe<DriverCustomerRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from mvrLogRelation */
export type MvrLogRelation_DriverUpdateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationUpdateRelationInput>;
  customer?: Maybe<DriverCustomerUpdateRelationInput>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationUpdateRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationUpdateRelationInput>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  _description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  object?: Maybe<NotificationObject>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** Notification create input */
export type NotificationCreateInput = {
  content: Scalars['String'];
  object?: Maybe<NotificationObjectRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  user?: Maybe<NotificationUserRelationInput>;
};

/** Notification create many input */
export type NotificationCreateManyInput = {
  content: Scalars['String'];
  object?: Maybe<NotificationObjectManyRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  user: NotificationUserManyRelationInput;
};

/** Notification delete input */
export type NotificationDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** NotificationFieldsPermissions create input */
export type NotificationFieldsPermissions = {
  content?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type NotificationFilter = {
  AND?: Maybe<Array<NotificationFilter>>;
  OR?: Maybe<Array<NotificationFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  content?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  object?: Maybe<NotificationObjectFilter>;
  status?: Maybe<StringPredicate>;
  title?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<UserFilter>;
};

export type NotificationGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: NotificationGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type NotificationGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  content?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  object?: Maybe<NotificationObjectGroupByQuery>;
  status?: Maybe<Array<GroupByField>>;
  title?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  user?: Maybe<UserGroupByQuery>;
};

export type NotificationKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** NotificationListResponse output */
export type NotificationListResponse = {
  __typename?: 'NotificationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Notification>;
};

export type NotificationLog = {
  __typename?: 'NotificationLog';
  _description?: Maybe<Scalars['String']>;
  case?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  emailError?: Maybe<Scalars['JSON']>;
  emailResponse?: Maybe<Scalars['JSON']>;
  explicitEmailResponse?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  notificationError?: Maybe<Scalars['JSON']>;
  notificationResponse?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  recipientId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** NotificationLog create input */
export type NotificationLogCreateInput = {
  case?: Maybe<Scalars['JSON']>;
  emailError?: Maybe<Scalars['JSON']>;
  emailResponse?: Maybe<Scalars['JSON']>;
  explicitEmailResponse?: Maybe<Scalars['JSON']>;
  notificationError?: Maybe<Scalars['JSON']>;
  notificationResponse?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  recipientId?: Maybe<Scalars['String']>;
};

/** NotificationLog create many input */
export type NotificationLogCreateManyInput = {
  case?: Maybe<Scalars['JSON']>;
  emailError?: Maybe<Scalars['JSON']>;
  emailResponse?: Maybe<Scalars['JSON']>;
  explicitEmailResponse?: Maybe<Scalars['JSON']>;
  notificationError?: Maybe<Scalars['JSON']>;
  notificationResponse?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  recipientId?: Maybe<Scalars['String']>;
};

/** NotificationLog delete input */
export type NotificationLogDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** NotificationLogFieldsPermissions create input */
export type NotificationLogFieldsPermissions = {
  case?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  emailError?: Maybe<Scalars['Boolean']>;
  emailResponse?: Maybe<Scalars['Boolean']>;
  explicitEmailResponse?: Maybe<Scalars['Boolean']>;
  notificationError?: Maybe<Scalars['Boolean']>;
  notificationResponse?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['Boolean']>;
  recipientId?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type NotificationLogFilter = {
  AND?: Maybe<Array<NotificationLogFilter>>;
  OR?: Maybe<Array<NotificationLogFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  recipientId?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type NotificationLogGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: NotificationLogGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type NotificationLogGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  case?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  emailError?: Maybe<Array<GroupByField>>;
  emailResponse?: Maybe<Array<GroupByField>>;
  explicitEmailResponse?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  notificationError?: Maybe<Array<GroupByField>>;
  notificationResponse?: Maybe<Array<GroupByField>>;
  recipient?: Maybe<Array<GroupByField>>;
  recipientId?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type NotificationLogKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** NotificationLogListResponse output */
export type NotificationLogListResponse = {
  __typename?: 'NotificationLogListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<NotificationLog>;
};

/** NotificationLogManyResponse output */
export type NotificationLogManyResponse = {
  __typename?: 'NotificationLogManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<NotificationLog>;
};

/** No longer supported. Use `sort` instead. */
export enum NotificationLogOrderBy {
  CaseAsc = 'case_ASC',
  CaseDesc = 'case_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailErrorAsc = 'emailError_ASC',
  EmailErrorDesc = 'emailError_DESC',
  EmailResponseAsc = 'emailResponse_ASC',
  EmailResponseDesc = 'emailResponse_DESC',
  ExplicitEmailResponseAsc = 'explicitEmailResponse_ASC',
  ExplicitEmailResponseDesc = 'explicitEmailResponse_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NotificationErrorAsc = 'notificationError_ASC',
  NotificationErrorDesc = 'notificationError_DESC',
  NotificationResponseAsc = 'notificationResponse_ASC',
  NotificationResponseDesc = 'notificationResponse_DESC',
  RecipientIdAsc = 'recipientId_ASC',
  RecipientIdDesc = 'recipientId_DESC',
  RecipientAsc = 'recipient_ASC',
  RecipientDesc = 'recipient_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** NotificationLog subscription payload */
export type NotificationLogPayload = {
  __typename?: 'NotificationLogPayload';
  mutation: MutationType;
  node?: Maybe<NotificationLog>;
  previousValues?: Maybe<NotificationLog>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationLogSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  recipientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** NotificationLog subscription filter */
export type NotificationLogSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<NotificationLogFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** NotificationLog update input */
export type NotificationLogUpdateByFilterInput = {
  case?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  emailError?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  emailResponse?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  explicitEmailResponse?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  notificationError?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  notificationResponse?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  recipient?: Maybe<Array<Maybe<UpdateByFilterJsonInput>>>;
  recipientId?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** NotificationLog update input */
export type NotificationLogUpdateInput = {
  case?: Maybe<Scalars['JSON']>;
  emailError?: Maybe<Scalars['JSON']>;
  emailResponse?: Maybe<Scalars['JSON']>;
  explicitEmailResponse?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  notificationError?: Maybe<Scalars['JSON']>;
  notificationResponse?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  recipientId?: Maybe<Scalars['String']>;
};

export type NotificationLog_PermissionFilter = {
  AND?: Maybe<Array<NotificationLog_PermissionFilter>>;
  OR?: Maybe<Array<NotificationLog_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  recipientId?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

/** NotificationManyResponse output */
export type NotificationManyResponse = {
  __typename?: 'NotificationManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Notification>;
};

export type NotificationObject = {
  __typename?: 'NotificationObject';
  _description?: Maybe<Scalars['String']>;
  case?: Maybe<Case>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  notification?: Maybe<Notification>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** NotificationObject relation input */
export type NotificationObjectCaseManyRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
};

/** NotificationObject relation input */
export type NotificationObjectCaseRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<NotificationObjectRelation_CaseCreateInput>;
};

/** NotificationObject relation input */
export type NotificationObjectCaseUpdateRelationInput = {
  connect?: Maybe<CaseKeyFilter>;
  create?: Maybe<NotificationObjectRelation_CaseCreateInput>;
  disconnect?: Maybe<CaseKeyFilter>;
  reconnect?: Maybe<CaseKeyFilter>;
  update?: Maybe<NotificationObjectRelation_CaseUpdateInput>;
};

/** NotificationObject create input */
export type NotificationObjectCreateInput = {
  case?: Maybe<NotificationObjectCaseRelationInput>;
  customer?: Maybe<NotificationObjectCustomerRelationInput>;
  notification?: Maybe<NotificationObjectNotificationRelationInput>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** NotificationObject create many input */
export type NotificationObjectCreateManyInput = {
  case?: Maybe<NotificationObjectCaseManyRelationInput>;
  customer?: Maybe<NotificationObjectCustomerManyRelationInput>;
  notification: NotificationObjectNotificationManyRelationInput;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** NotificationObject relation input */
export type NotificationObjectCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** NotificationObject relation input */
export type NotificationObjectCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<NotificationObjectCustomerRelation_CustomerCreateInput>;
};

/** Customer create input from notificationObjectCustomerRelation */
export type NotificationObjectCustomerRelation_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from notificationObjectCustomerRelation */
export type NotificationObjectCustomerRelation_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** NotificationObject relation input */
export type NotificationObjectCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<NotificationObjectCustomerRelation_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<NotificationObjectCustomerRelation_CustomerUpdateInput>;
};

/** NotificationObject delete input */
export type NotificationObjectDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** NotificationObjectFieldsPermissions create input */
export type NotificationObjectFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type NotificationObjectFilter = {
  AND?: Maybe<Array<NotificationObjectFilter>>;
  OR?: Maybe<Array<NotificationObjectFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<CaseFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  notification?: Maybe<NotificationFilter>;
  resource?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type NotificationObjectGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: NotificationObjectGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type NotificationObjectGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  case?: Maybe<CaseGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  notification?: Maybe<NotificationGroupByQuery>;
  resource?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

export type NotificationObjectKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** NotificationObjectListResponse output */
export type NotificationObjectListResponse = {
  __typename?: 'NotificationObjectListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<NotificationObject>;
};

/** Notification relation input */
export type NotificationObjectManyRelationInput = {
  connect?: Maybe<NotificationObjectKeyFilter>;
};

/** NotificationObjectManyResponse output */
export type NotificationObjectManyResponse = {
  __typename?: 'NotificationObjectManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<NotificationObject>;
};

/** NotificationObject relation input */
export type NotificationObjectNotificationManyRelationInput = {
  connect?: Maybe<NotificationKeyFilter>;
};

/** NotificationObject relation input */
export type NotificationObjectNotificationRelationInput = {
  connect?: Maybe<NotificationKeyFilter>;
  create?: Maybe<Object_NotificationCreateInput>;
};

/** NotificationObject relation input */
export type NotificationObjectNotificationUpdateRelationInput = {
  connect?: Maybe<NotificationKeyFilter>;
  create?: Maybe<Object_NotificationCreateInput>;
  disconnect?: Maybe<NotificationKeyFilter>;
  reconnect?: Maybe<NotificationKeyFilter>;
  update?: Maybe<Object_NotificationUpdateInput>;
};

/** No longer supported. Use `sort` instead. */
export enum NotificationObjectOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ResourceAsc = 'resource_ASC',
  ResourceDesc = 'resource_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** NotificationObject subscription payload */
export type NotificationObjectPayload = {
  __typename?: 'NotificationObjectPayload';
  mutation: MutationType;
  node?: Maybe<NotificationObject>;
  previousValues?: Maybe<NotificationObject>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationObjectRelationFilter = {
  every?: Maybe<NotificationObjectFilter>;
  none?: Maybe<NotificationObjectFilter>;
  some?: Maybe<NotificationObjectFilter>;
};

/** Notification relation input */
export type NotificationObjectRelationInput = {
  connect?: Maybe<NotificationObjectKeyFilter>;
  create?: Maybe<Notification_NotificationObjectCreateInput>;
};

/** Case create input from notificationObjectRelation */
export type NotificationObjectRelation_CaseCreateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationRelationInput>;
  briefStatement: Scalars['String'];
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationRelationInput>;
  roadsideInspection: Scalars['Boolean'];
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation: Scalars['Boolean'];
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Case update input from notificationObjectRelation */
export type NotificationObjectRelation_CaseUpdateInput = {
  attorneyRelation?: Maybe<CaseAttorneyRelationUpdateRelationInput>;
  briefStatement?: Maybe<Scalars['String']>;
  caseCaseFollowerRelation?: Maybe<CaseCaseCaseFollowerRelationUpdateRelationInput>;
  caseCaseViolationCodeRelation?: Maybe<CaseCaseCaseViolationCodeRelationUpdateRelationInput>;
  caseCommentRelation?: Maybe<CaseCaseCommentRelationUpdateRelationInput>;
  caseDocumentRelation?: Maybe<CaseCaseDocumentRelationUpdateRelationInput>;
  caseHistoryRelation?: Maybe<CaseCaseHistoryRelationUpdateRelationInput>;
  caseID?: Maybe<Scalars['String']>;
  caseLogRelation?: Maybe<CaseCaseLogRelationUpdateRelationInput>;
  caseStatusLogRelation?: Maybe<CaseCaseStatusLogRelationUpdateRelationInput>;
  citationArraignment?: Maybe<Scalars['Date']>;
  citationCourtNameAddress?: Maybe<Scalars['String']>;
  citationDueDate?: Maybe<Scalars['Date']>;
  citationNumbers?: Maybe<Scalars['String']>;
  citationStatus?: Maybe<Scalars['String']>;
  crash?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CaseCustomerUpdateRelationInput>;
  dataQsStatus?: Maybe<Scalars['String']>;
  driver?: Maybe<CaseDriverUpdateRelationInput>;
  inspectionCitationState?: Maybe<CaseInspectionCitationStateUpdateRelationInput>;
  inspectionDate?: Maybe<Scalars['Date']>;
  inspectionReportNumber?: Maybe<Scalars['String']>;
  notificationObjectRelation?: Maybe<CaseNotificationObjectRelationUpdateRelationInput>;
  roadsideInspection?: Maybe<Scalars['Boolean']>;
  sortScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  trafficCitation?: Maybe<Scalars['Boolean']>;
  /** This field is to sort, it's a reference to the field caseLogRelation. */
  updatedAtAll?: Maybe<Scalars['DateTime']>;
  updatedAtByAgent?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type NotificationObjectSort = {
  case?: Maybe<CaseSort>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notification?: Maybe<NotificationSort>;
  resource?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** NotificationObject subscription filter */
export type NotificationObjectSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<NotificationObjectFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** NotificationObject update input */
export type NotificationObjectUpdateByFilterInput = {
  resource?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
};

/** NotificationObject update input */
export type NotificationObjectUpdateInput = {
  case?: Maybe<NotificationObjectCaseUpdateRelationInput>;
  customer?: Maybe<NotificationObjectCustomerUpdateRelationInput>;
  id?: Maybe<Scalars['ID']>;
  notification?: Maybe<NotificationObjectNotificationUpdateRelationInput>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** Notification relation input */
export type NotificationObjectUpdateRelationInput = {
  connect?: Maybe<NotificationObjectKeyFilter>;
  create?: Maybe<Notification_NotificationObjectCreateInput>;
  disconnect?: Maybe<NotificationObjectKeyFilter>;
  reconnect?: Maybe<NotificationObjectKeyFilter>;
  update?: Maybe<Notification_NotificationObjectUpdateInput>;
};

export type NotificationObject_PermissionFilter = {
  AND?: Maybe<Array<NotificationObject_PermissionFilter>>;
  OR?: Maybe<Array<NotificationObject_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  case?: Maybe<Case_PermissionFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  notification?: Maybe<Notification_PermissionFilter>;
  resource?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type NotificationObject_PermissionRelationFilter = {
  every?: Maybe<NotificationObject_PermissionFilter>;
  none?: Maybe<NotificationObject_PermissionFilter>;
  some?: Maybe<NotificationObject_PermissionFilter>;
};

/** No longer supported. Use `sort` instead. */
export enum NotificationOrderBy {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Notification subscription payload */
export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  mutation: MutationType;
  node?: Maybe<Notification>;
  previousValues?: Maybe<Notification>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationRelationFilter = {
  every?: Maybe<NotificationFilter>;
  none?: Maybe<NotificationFilter>;
  some?: Maybe<NotificationFilter>;
};

/** Users create input from notificationRelation */
export type NotificationRelation_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from notificationRelation */
export type NotificationRelation_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  _description?: Maybe<Scalars['String']>;
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

/** NotificationSetting create input */
export type NotificationSettingCreateInput = {
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  user?: Maybe<NotificationSettingUserRelationInput>;
};

/** NotificationSetting create many input */
export type NotificationSettingCreateManyInput = {
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  user: NotificationSettingUserManyRelationInput;
};

/** NotificationSetting delete input */
export type NotificationSettingDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** NotificationSettingFieldsPermissions create input */
export type NotificationSettingFieldsPermissions = {
  allNotifications?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type NotificationSettingFilter = {
  AND?: Maybe<Array<NotificationSettingFilter>>;
  OR?: Maybe<Array<NotificationSettingFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  allNotifications?: Maybe<BoolPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  onlyNewComments?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<UserFilter>;
};

export type NotificationSettingGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: NotificationSettingGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type NotificationSettingGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  allNotifications?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  onlyNewComments?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  user?: Maybe<UserGroupByQuery>;
};

export type NotificationSettingKeyFilter = {
  id?: Maybe<Scalars['ID']>;
};

/** NotificationSettingListResponse output */
export type NotificationSettingListResponse = {
  __typename?: 'NotificationSettingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<NotificationSetting>;
};

/** NotificationSettingManyResponse output */
export type NotificationSettingManyResponse = {
  __typename?: 'NotificationSettingManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<NotificationSetting>;
};

/** No longer supported. Use `sort` instead. */
export enum NotificationSettingOrderBy {
  AllNotificationsAsc = 'allNotifications_ASC',
  AllNotificationsDesc = 'allNotifications_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OnlyNewCommentsAsc = 'onlyNewComments_ASC',
  OnlyNewCommentsDesc = 'onlyNewComments_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** NotificationSetting subscription payload */
export type NotificationSettingPayload = {
  __typename?: 'NotificationSettingPayload';
  mutation: MutationType;
  node?: Maybe<NotificationSetting>;
  previousValues?: Maybe<NotificationSetting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationSettingSort = {
  allNotifications?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  onlyNewComments?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserSort>;
};

/** NotificationSetting subscription filter */
export type NotificationSettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<NotificationSettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** NotificationSetting update input */
export type NotificationSettingUpdateByFilterInput = {
  allNotifications?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  onlyNewComments?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
};

/** NotificationSetting update input */
export type NotificationSettingUpdateInput = {
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  user?: Maybe<NotificationSettingUserUpdateRelationInput>;
};

/** NotificationSetting relation input */
export type NotificationSettingUserManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** NotificationSetting relation input */
export type NotificationSettingUserRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<NotificationSetting_UserCreateInput>;
};

/** NotificationSetting relation input */
export type NotificationSettingUserUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<NotificationSetting_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<NotificationSetting_UserUpdateInput>;
};

export type NotificationSetting_PermissionFilter = {
  AND?: Maybe<Array<NotificationSetting_PermissionFilter>>;
  OR?: Maybe<Array<NotificationSetting_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  allNotifications?: Maybe<BoolPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  onlyNewComments?: Maybe<BoolPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<User_PermissionFilter>;
};

/** Users create input from notificationSetting */
export type NotificationSetting_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from notificationSetting */
export type NotificationSetting_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

export type NotificationSort = {
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  object?: Maybe<NotificationObjectSort>;
  status?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  user?: Maybe<UserSort>;
};

export enum NotificationStatusType {
  Done = 'done',
}

/** Notification subscription filter */
export type NotificationSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<NotificationFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Notification update input */
export type NotificationUpdateByFilterInput = {
  content?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  title?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Notification update input */
export type NotificationUpdateInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  object?: Maybe<NotificationObjectUpdateRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<NotificationUserUpdateRelationInput>;
};

/** Notification relation input */
export type NotificationUserManyRelationInput = {
  connect?: Maybe<UserKeyFilter>;
};

/** Notification relation input */
export type NotificationUserRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<NotificationRelation_UserCreateInput>;
};

/** Notification relation input */
export type NotificationUserUpdateRelationInput = {
  connect?: Maybe<UserKeyFilter>;
  create?: Maybe<NotificationRelation_UserCreateInput>;
  disconnect?: Maybe<UserKeyFilter>;
  reconnect?: Maybe<UserKeyFilter>;
  update?: Maybe<NotificationRelation_UserUpdateInput>;
};

/** NotificationObject create input from notification */
export type Notification_NotificationObjectCreateInput = {
  case?: Maybe<NotificationObjectCaseRelationInput>;
  customer?: Maybe<NotificationObjectCustomerRelationInput>;
  notification?: Maybe<NotificationObjectNotificationRelationInput>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

/** NotificationObject update input from notification */
export type Notification_NotificationObjectUpdateInput = {
  case?: Maybe<NotificationObjectCaseUpdateRelationInput>;
  customer?: Maybe<NotificationObjectCustomerUpdateRelationInput>;
  notification?: Maybe<NotificationObjectNotificationUpdateRelationInput>;
  /** To identify the cause of the notification */
  resource?: Maybe<Scalars['String']>;
};

export type Notification_PermissionFilter = {
  AND?: Maybe<Array<Notification_PermissionFilter>>;
  OR?: Maybe<Array<Notification_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  content?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  object?: Maybe<NotificationObject_PermissionFilter>;
  status?: Maybe<StringPredicate>;
  title?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  user?: Maybe<User_PermissionFilter>;
};

export type Notification_PermissionRelationFilter = {
  every?: Maybe<Notification_PermissionFilter>;
  none?: Maybe<Notification_PermissionFilter>;
  some?: Maybe<Notification_PermissionFilter>;
};

/** Number Field Attributes */
export type NumberFieldTypeAttributes = {
  __typename?: 'NumberFieldTypeAttributes';
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  isBigInt?: Maybe<Scalars['Boolean']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
};

/** Number Type Format Enum */
export enum NumberTypeFormatEnum {
  Currency = 'CURRENCY',
  Fraction = 'FRACTION',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Scientific = 'SCIENTIFIC',
}

/** Notification create input from object */
export type Object_NotificationCreateInput = {
  content: Scalars['String'];
  object?: Maybe<NotificationObjectRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  user: NotificationUserRelationInput;
};

/** Notification update input from object */
export type Object_NotificationUpdateInput = {
  content?: Maybe<Scalars['String']>;
  object?: Maybe<NotificationObjectUpdateRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<NotificationUserUpdateRelationInput>;
};

export type OrganizationUserInvitationResponse = {
  __typename?: 'OrganizationUserInvitationResponse';
  invitationId: Scalars['String'];
};

/** PasswordForgotConfirmInput */
export type PasswordForgotConfirmInput = {
  authProfileId: Scalars['ID'];
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

/** PasswordForgotInput */
export type PasswordForgotInput = {
  authProfileId: Scalars['ID'];
  email: Scalars['String'];
};

export type PatternFunctionArguments = {
  escape?: Maybe<Scalars['String']>;
  pattern: Scalars['String'];
};

export type Permission = {
  __typename?: 'Permission';
  _description?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionFilter = {
  AND?: Maybe<Array<PermissionFilter>>;
  OR?: Maybe<Array<PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  appId?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  resource?: Maybe<StringPredicate>;
  resourceType?: Maybe<StringPredicate>;
  role?: Maybe<RoleFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type PermissionGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  appId?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  permission?: Maybe<Array<GroupByField>>;
  resource?: Maybe<Array<GroupByField>>;
  resourceType?: Maybe<Array<GroupByField>>;
  role?: Maybe<RoleGroupByQuery>;
  updatedAt?: Maybe<Array<GroupByField>>;
};

/** Permission Filter */
export type PermissionInputFilter = {
  action?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<PermissionResourceTypeEnum>;
};

/** PermissionListResponse output */
export type PermissionListResponse = {
  __typename?: 'PermissionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Permission>;
};

/** Permissions subscription payload */
export type PermissionPayload = {
  __typename?: 'PermissionPayload';
  mutation: MutationType;
  node?: Maybe<Permission>;
  previousValues?: Maybe<Permission>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PermissionRelationFilter = {
  every?: Maybe<PermissionFilter>;
  none?: Maybe<PermissionFilter>;
  some?: Maybe<PermissionFilter>;
};

export enum PermissionResourceTypeEnum {
  Custom = 'custom',
  Data = 'data',
}

/** Permissions subscription filter */
export type PermissionSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<PermissionFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

export type Permission_PermissionFilter = {
  AND?: Maybe<Array<Permission_PermissionFilter>>;
  OR?: Maybe<Array<Permission_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  appId?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  resource?: Maybe<StringPredicate>;
  resourceType?: Maybe<StringPredicate>;
  role?: Maybe<Role_PermissionFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
};

export type Permission_PermissionRelationFilter = {
  every?: Maybe<Permission_PermissionFilter>;
  none?: Maybe<Permission_PermissionFilter>;
  some?: Maybe<Permission_PermissionFilter>;
};

/** custom permissions input */
export type PermissionsCustom = {
  api?: Maybe<PermissionsCustomApi>;
  data?: Maybe<PermissionsCustomData>;
  logic?: Maybe<PermissionsCustomLogic>;
  settings?: Maybe<PermissionsCustomSettings>;
  users?: Maybe<PermissionsCustomUsers>;
};

export type PermissionsCustomApi = {
  caseDocumentDeleteManagement?: Maybe<PermissionsCustomApiResolver>;
  createUserAuth0?: Maybe<PermissionsCustomApiResolver>;
  sendEmail?: Maybe<PermissionsCustomApiResolver>;
};

export type PermissionsCustomApiResolver = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomData = {
  schemaManagement?: Maybe<PermissionsCustomDataSchemaManagement>;
  viewerAccess?: Maybe<PermissionsCustomDataViewerAccess>;
};

export type PermissionsCustomDataSchemaManagement = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomDataViewerAccess = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogic = {
  deploy?: Maybe<PermissionsCustomLogicDeploy>;
  invoke?: Maybe<PermissionsCustomLogicInvoke>;
  logs?: Maybe<PermissionsCustomLogicLogs>;
  view?: Maybe<PermissionsCustomLogicView>;
};

export type PermissionsCustomLogicDeploy = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicInvoke = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicLogs = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomLogicView = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomSettings = {
  workspaceAdministration?: Maybe<PermissionsCustomSettingsWorkspaceAdministration>;
};

export type PermissionsCustomSettingsWorkspaceAdministration = {
  allow: Scalars['Boolean'];
};

export type PermissionsCustomUsers = {
  teamAdministration?: Maybe<PermissionsCustomUsersTeamAdministration>;
};

export type PermissionsCustomUsersTeamAdministration = {
  allow: Scalars['Boolean'];
};

/** Schema tables permissions input */
export type PermissionsData = {
  Address?: Maybe<PermissionsDataAddress>;
  Alert?: Maybe<PermissionsDataAlert>;
  Attorney?: Maybe<PermissionsDataAttorney>;
  Case?: Maybe<PermissionsDataCase>;
  CaseComment?: Maybe<PermissionsDataCaseComment>;
  CaseCommentReply?: Maybe<PermissionsDataCaseCommentReply>;
  CaseDocument?: Maybe<PermissionsDataCaseDocument>;
  CaseFollower?: Maybe<PermissionsDataCaseFollower>;
  CaseHistory?: Maybe<PermissionsDataCaseHistory>;
  CaseLog?: Maybe<PermissionsDataCaseLog>;
  CaseStatusLog?: Maybe<PermissionsDataCaseStatusLog>;
  CaseViolationCode?: Maybe<PermissionsDataCaseViolationCode>;
  CiCdMigrations?: Maybe<PermissionsDataCiCdMigrations>;
  CitationCopy?: Maybe<PermissionsDataCitationCopy>;
  Customer?: Maybe<PermissionsDataCustomer>;
  CustomerAlert?: Maybe<PermissionsDataCustomerAlert>;
  CustomerDocument?: Maybe<PermissionsDataCustomerDocument>;
  DocumentDraft?: Maybe<PermissionsDataDocumentDraft>;
  Driver?: Maybe<PermissionsDataDriver>;
  DriverLanguage?: Maybe<PermissionsDataDriverLanguage>;
  DriverLicense?: Maybe<PermissionsDataDriverLicense>;
  EverSignDoc?: Maybe<PermissionsDataEverSignDoc>;
  Evidence?: Maybe<PermissionsDataEvidence>;
  FeatureFlags?: Maybe<PermissionsDataFeatureFlags>;
  Files?: Maybe<PermissionsDataFiles>;
  FilesSettings?: Maybe<PermissionsDataFilesSettings>;
  MVRLog?: Maybe<PermissionsDataMvrLog>;
  Notification?: Maybe<PermissionsDataNotification>;
  NotificationLog?: Maybe<PermissionsDataNotificationLog>;
  NotificationObject?: Maybe<PermissionsDataNotificationObject>;
  NotificationSetting?: Maybe<PermissionsDataNotificationSetting>;
  Roles?: Maybe<PermissionsDataRoles>;
  UsState?: Maybe<PermissionsDataUsState>;
  Users?: Maybe<PermissionsDataUsers>;
  ViolationCode?: Maybe<PermissionsDataViolationCode>;
};

export type PermissionsDataAddress = {
  create?: Maybe<PermissionsDataAddressCreate>;
  delete?: Maybe<PermissionsDataAddressDelete>;
  destroy?: Maybe<PermissionsDataAddressDestroy>;
  read?: Maybe<PermissionsDataAddressRead>;
  update?: Maybe<PermissionsDataAddressUpdate>;
};

export type PermissionsDataAddressCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAddressDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataAddressDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAddressRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AddressFieldsPermissions>;
  filter?: Maybe<Address_PermissionFilter>;
};

export type PermissionsDataAddressUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AddressFieldsPermissions>;
  filter?: Maybe<Address_PermissionFilter>;
};

export type PermissionsDataAlert = {
  create?: Maybe<PermissionsDataAlertCreate>;
  delete?: Maybe<PermissionsDataAlertDelete>;
  destroy?: Maybe<PermissionsDataAlertDestroy>;
  read?: Maybe<PermissionsDataAlertRead>;
  update?: Maybe<PermissionsDataAlertUpdate>;
};

export type PermissionsDataAlertCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAlertDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataAlertDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAlertRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AlertFieldsPermissions>;
  filter?: Maybe<Alert_PermissionFilter>;
};

export type PermissionsDataAlertUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AlertFieldsPermissions>;
  filter?: Maybe<Alert_PermissionFilter>;
};

export type PermissionsDataAttorney = {
  create?: Maybe<PermissionsDataAttorneyCreate>;
  delete?: Maybe<PermissionsDataAttorneyDelete>;
  destroy?: Maybe<PermissionsDataAttorneyDestroy>;
  read?: Maybe<PermissionsDataAttorneyRead>;
  update?: Maybe<PermissionsDataAttorneyUpdate>;
};

export type PermissionsDataAttorneyCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAttorneyDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataAttorneyDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataAttorneyRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AttorneyFieldsPermissions>;
  filter?: Maybe<Attorney_PermissionFilter>;
};

export type PermissionsDataAttorneyUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<AttorneyFieldsPermissions>;
  filter?: Maybe<Attorney_PermissionFilter>;
};

export type PermissionsDataCase = {
  create?: Maybe<PermissionsDataCaseCreate>;
  delete?: Maybe<PermissionsDataCaseDelete>;
  destroy?: Maybe<PermissionsDataCaseDestroy>;
  read?: Maybe<PermissionsDataCaseRead>;
  update?: Maybe<PermissionsDataCaseUpdate>;
};

export type PermissionsDataCaseComment = {
  create?: Maybe<PermissionsDataCaseCommentCreate>;
  delete?: Maybe<PermissionsDataCaseCommentDelete>;
  destroy?: Maybe<PermissionsDataCaseCommentDestroy>;
  read?: Maybe<PermissionsDataCaseCommentRead>;
  update?: Maybe<PermissionsDataCaseCommentUpdate>;
};

export type PermissionsDataCaseCommentCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseCommentDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseCommentDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseCommentRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseCommentFieldsPermissions>;
  filter?: Maybe<CaseComment_PermissionFilter>;
};

export type PermissionsDataCaseCommentReply = {
  create?: Maybe<PermissionsDataCaseCommentReplyCreate>;
  delete?: Maybe<PermissionsDataCaseCommentReplyDelete>;
  destroy?: Maybe<PermissionsDataCaseCommentReplyDestroy>;
  read?: Maybe<PermissionsDataCaseCommentReplyRead>;
  update?: Maybe<PermissionsDataCaseCommentReplyUpdate>;
};

export type PermissionsDataCaseCommentReplyCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseCommentReplyDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseCommentReplyDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseCommentReplyRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseCommentReplyFieldsPermissions>;
  filter?: Maybe<CaseCommentReply_PermissionFilter>;
};

export type PermissionsDataCaseCommentReplyUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseCommentReplyFieldsPermissions>;
  filter?: Maybe<CaseCommentReply_PermissionFilter>;
};

export type PermissionsDataCaseCommentUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseCommentFieldsPermissions>;
  filter?: Maybe<CaseComment_PermissionFilter>;
};

export type PermissionsDataCaseCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseDocument = {
  create?: Maybe<PermissionsDataCaseDocumentCreate>;
  delete?: Maybe<PermissionsDataCaseDocumentDelete>;
  destroy?: Maybe<PermissionsDataCaseDocumentDestroy>;
  read?: Maybe<PermissionsDataCaseDocumentRead>;
  update?: Maybe<PermissionsDataCaseDocumentUpdate>;
};

export type PermissionsDataCaseDocumentCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseDocumentDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseDocumentDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseDocumentRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseDocumentFieldsPermissions>;
  filter?: Maybe<CaseDocument_PermissionFilter>;
};

export type PermissionsDataCaseDocumentUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseDocumentFieldsPermissions>;
  filter?: Maybe<CaseDocument_PermissionFilter>;
};

export type PermissionsDataCaseFollower = {
  create?: Maybe<PermissionsDataCaseFollowerCreate>;
  delete?: Maybe<PermissionsDataCaseFollowerDelete>;
  destroy?: Maybe<PermissionsDataCaseFollowerDestroy>;
  read?: Maybe<PermissionsDataCaseFollowerRead>;
  update?: Maybe<PermissionsDataCaseFollowerUpdate>;
};

export type PermissionsDataCaseFollowerCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseFollowerDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseFollowerDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseFollowerRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseFollowerFieldsPermissions>;
  filter?: Maybe<CaseFollower_PermissionFilter>;
};

export type PermissionsDataCaseFollowerUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseFollowerFieldsPermissions>;
  filter?: Maybe<CaseFollower_PermissionFilter>;
};

export type PermissionsDataCaseHistory = {
  create?: Maybe<PermissionsDataCaseHistoryCreate>;
  delete?: Maybe<PermissionsDataCaseHistoryDelete>;
  destroy?: Maybe<PermissionsDataCaseHistoryDestroy>;
  read?: Maybe<PermissionsDataCaseHistoryRead>;
  update?: Maybe<PermissionsDataCaseHistoryUpdate>;
};

export type PermissionsDataCaseHistoryCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseHistoryDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseHistoryDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseHistoryRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseHistoryFieldsPermissions>;
  filter?: Maybe<CaseHistory_PermissionFilter>;
};

export type PermissionsDataCaseHistoryUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseHistoryFieldsPermissions>;
  filter?: Maybe<CaseHistory_PermissionFilter>;
};

export type PermissionsDataCaseLog = {
  create?: Maybe<PermissionsDataCaseLogCreate>;
  delete?: Maybe<PermissionsDataCaseLogDelete>;
  destroy?: Maybe<PermissionsDataCaseLogDestroy>;
  read?: Maybe<PermissionsDataCaseLogRead>;
  update?: Maybe<PermissionsDataCaseLogUpdate>;
};

export type PermissionsDataCaseLogCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseLogDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseLogDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseLogRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseLogFieldsPermissions>;
  filter?: Maybe<CaseLog_PermissionFilter>;
};

export type PermissionsDataCaseLogUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseLogFieldsPermissions>;
  filter?: Maybe<CaseLog_PermissionFilter>;
};

export type PermissionsDataCaseRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseFieldsPermissions>;
  filter?: Maybe<Case_PermissionFilter>;
};

export type PermissionsDataCaseStatusLog = {
  create?: Maybe<PermissionsDataCaseStatusLogCreate>;
  delete?: Maybe<PermissionsDataCaseStatusLogDelete>;
  destroy?: Maybe<PermissionsDataCaseStatusLogDestroy>;
  read?: Maybe<PermissionsDataCaseStatusLogRead>;
  update?: Maybe<PermissionsDataCaseStatusLogUpdate>;
};

export type PermissionsDataCaseStatusLogCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseStatusLogDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseStatusLogDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseStatusLogRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseStatusLogFieldsPermissions>;
  filter?: Maybe<CaseStatusLog_PermissionFilter>;
};

export type PermissionsDataCaseStatusLogUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseStatusLogFieldsPermissions>;
  filter?: Maybe<CaseStatusLog_PermissionFilter>;
};

export type PermissionsDataCaseUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseFieldsPermissions>;
  filter?: Maybe<Case_PermissionFilter>;
};

export type PermissionsDataCaseViolationCode = {
  create?: Maybe<PermissionsDataCaseViolationCodeCreate>;
  delete?: Maybe<PermissionsDataCaseViolationCodeDelete>;
  destroy?: Maybe<PermissionsDataCaseViolationCodeDestroy>;
  read?: Maybe<PermissionsDataCaseViolationCodeRead>;
  update?: Maybe<PermissionsDataCaseViolationCodeUpdate>;
};

export type PermissionsDataCaseViolationCodeCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseViolationCodeDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCaseViolationCodeDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCaseViolationCodeRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseViolationCodeFieldsPermissions>;
  filter?: Maybe<CaseViolationCode_PermissionFilter>;
};

export type PermissionsDataCaseViolationCodeUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CaseViolationCodeFieldsPermissions>;
  filter?: Maybe<CaseViolationCode_PermissionFilter>;
};

export type PermissionsDataCiCdMigrations = {
  create?: Maybe<PermissionsDataCiCdMigrationsCreate>;
  delete?: Maybe<PermissionsDataCiCdMigrationsDelete>;
  destroy?: Maybe<PermissionsDataCiCdMigrationsDestroy>;
  read?: Maybe<PermissionsDataCiCdMigrationsRead>;
  update?: Maybe<PermissionsDataCiCdMigrationsUpdate>;
};

export type PermissionsDataCiCdMigrationsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrationsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCiCdMigrationsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCiCdMigrationsRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CiCdMigrationFieldsPermissions>;
  filter?: Maybe<CiCdMigration_PermissionFilter>;
};

export type PermissionsDataCiCdMigrationsUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CiCdMigrationFieldsPermissions>;
  filter?: Maybe<CiCdMigration_PermissionFilter>;
};

export type PermissionsDataCitationCopy = {
  create?: Maybe<PermissionsDataCitationCopyCreate>;
  delete?: Maybe<PermissionsDataCitationCopyDelete>;
  destroy?: Maybe<PermissionsDataCitationCopyDestroy>;
  read?: Maybe<PermissionsDataCitationCopyRead>;
  update?: Maybe<PermissionsDataCitationCopyUpdate>;
};

export type PermissionsDataCitationCopyCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCitationCopyDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCitationCopyDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCitationCopyRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CitationCopyFieldsPermissions>;
  filter?: Maybe<CitationCopy_PermissionFilter>;
};

export type PermissionsDataCitationCopyUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CitationCopyFieldsPermissions>;
  filter?: Maybe<CitationCopy_PermissionFilter>;
};

export type PermissionsDataCustomer = {
  create?: Maybe<PermissionsDataCustomerCreate>;
  delete?: Maybe<PermissionsDataCustomerDelete>;
  destroy?: Maybe<PermissionsDataCustomerDestroy>;
  read?: Maybe<PermissionsDataCustomerRead>;
  update?: Maybe<PermissionsDataCustomerUpdate>;
};

export type PermissionsDataCustomerAlert = {
  create?: Maybe<PermissionsDataCustomerAlertCreate>;
  delete?: Maybe<PermissionsDataCustomerAlertDelete>;
  destroy?: Maybe<PermissionsDataCustomerAlertDestroy>;
  read?: Maybe<PermissionsDataCustomerAlertRead>;
  update?: Maybe<PermissionsDataCustomerAlertUpdate>;
};

export type PermissionsDataCustomerAlertCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerAlertDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCustomerAlertDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerAlertRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerAlertFieldsPermissions>;
  filter?: Maybe<CustomerAlert_PermissionFilter>;
};

export type PermissionsDataCustomerAlertUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerAlertFieldsPermissions>;
  filter?: Maybe<CustomerAlert_PermissionFilter>;
};

export type PermissionsDataCustomerCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCustomerDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerDocument = {
  create?: Maybe<PermissionsDataCustomerDocumentCreate>;
  delete?: Maybe<PermissionsDataCustomerDocumentDelete>;
  destroy?: Maybe<PermissionsDataCustomerDocumentDestroy>;
  read?: Maybe<PermissionsDataCustomerDocumentRead>;
  update?: Maybe<PermissionsDataCustomerDocumentUpdate>;
};

export type PermissionsDataCustomerDocumentCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerDocumentDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataCustomerDocumentDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataCustomerDocumentRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerDocumentFieldsPermissions>;
  filter?: Maybe<CustomerDocument_PermissionFilter>;
};

export type PermissionsDataCustomerDocumentUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerDocumentFieldsPermissions>;
  filter?: Maybe<CustomerDocument_PermissionFilter>;
};

export type PermissionsDataCustomerRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerFieldsPermissions>;
  filter?: Maybe<Customer_PermissionFilter>;
};

export type PermissionsDataCustomerUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<CustomerFieldsPermissions>;
  filter?: Maybe<Customer_PermissionFilter>;
};

export type PermissionsDataDocumentDraft = {
  create?: Maybe<PermissionsDataDocumentDraftCreate>;
  delete?: Maybe<PermissionsDataDocumentDraftDelete>;
  destroy?: Maybe<PermissionsDataDocumentDraftDestroy>;
  read?: Maybe<PermissionsDataDocumentDraftRead>;
  update?: Maybe<PermissionsDataDocumentDraftUpdate>;
};

export type PermissionsDataDocumentDraftCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDocumentDraftDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDocumentDraftDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDocumentDraftRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DocumentDraftFieldsPermissions>;
  filter?: Maybe<DocumentDraft_PermissionFilter>;
};

export type PermissionsDataDocumentDraftUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DocumentDraftFieldsPermissions>;
  filter?: Maybe<DocumentDraft_PermissionFilter>;
};

export type PermissionsDataDriver = {
  create?: Maybe<PermissionsDataDriverCreate>;
  delete?: Maybe<PermissionsDataDriverDelete>;
  destroy?: Maybe<PermissionsDataDriverDestroy>;
  read?: Maybe<PermissionsDataDriverRead>;
  update?: Maybe<PermissionsDataDriverUpdate>;
};

export type PermissionsDataDriverCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDriverDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverLanguage = {
  create?: Maybe<PermissionsDataDriverLanguageCreate>;
  delete?: Maybe<PermissionsDataDriverLanguageDelete>;
  destroy?: Maybe<PermissionsDataDriverLanguageDestroy>;
  read?: Maybe<PermissionsDataDriverLanguageRead>;
  update?: Maybe<PermissionsDataDriverLanguageUpdate>;
};

export type PermissionsDataDriverLanguageCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverLanguageDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDriverLanguageDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverLanguageRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverLanguageFieldsPermissions>;
  filter?: Maybe<DriverLanguage_PermissionFilter>;
};

export type PermissionsDataDriverLanguageUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverLanguageFieldsPermissions>;
  filter?: Maybe<DriverLanguage_PermissionFilter>;
};

export type PermissionsDataDriverLicense = {
  create?: Maybe<PermissionsDataDriverLicenseCreate>;
  delete?: Maybe<PermissionsDataDriverLicenseDelete>;
  destroy?: Maybe<PermissionsDataDriverLicenseDestroy>;
  read?: Maybe<PermissionsDataDriverLicenseRead>;
  update?: Maybe<PermissionsDataDriverLicenseUpdate>;
};

export type PermissionsDataDriverLicenseCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverLicenseDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataDriverLicenseDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataDriverLicenseRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverLicenseFieldsPermissions>;
  filter?: Maybe<DriverLicense_PermissionFilter>;
};

export type PermissionsDataDriverLicenseUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverLicenseFieldsPermissions>;
  filter?: Maybe<DriverLicense_PermissionFilter>;
};

export type PermissionsDataDriverRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverFieldsPermissions>;
  filter?: Maybe<Driver_PermissionFilter>;
};

export type PermissionsDataDriverUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<DriverFieldsPermissions>;
  filter?: Maybe<Driver_PermissionFilter>;
};

export type PermissionsDataEverSignDoc = {
  create?: Maybe<PermissionsDataEverSignDocCreate>;
  delete?: Maybe<PermissionsDataEverSignDocDelete>;
  destroy?: Maybe<PermissionsDataEverSignDocDestroy>;
  read?: Maybe<PermissionsDataEverSignDocRead>;
  update?: Maybe<PermissionsDataEverSignDocUpdate>;
};

export type PermissionsDataEverSignDocCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEverSignDocDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataEverSignDocDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEverSignDocRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<EverSignDocFieldsPermissions>;
  filter?: Maybe<EverSignDoc_PermissionFilter>;
};

export type PermissionsDataEverSignDocUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<EverSignDocFieldsPermissions>;
  filter?: Maybe<EverSignDoc_PermissionFilter>;
};

export type PermissionsDataEvidence = {
  create?: Maybe<PermissionsDataEvidenceCreate>;
  delete?: Maybe<PermissionsDataEvidenceDelete>;
  destroy?: Maybe<PermissionsDataEvidenceDestroy>;
  read?: Maybe<PermissionsDataEvidenceRead>;
  update?: Maybe<PermissionsDataEvidenceUpdate>;
};

export type PermissionsDataEvidenceCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEvidenceDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataEvidenceDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataEvidenceRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<EvidenceFieldsPermissions>;
  filter?: Maybe<Evidence_PermissionFilter>;
};

export type PermissionsDataEvidenceUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<EvidenceFieldsPermissions>;
  filter?: Maybe<Evidence_PermissionFilter>;
};

export type PermissionsDataFeatureFlags = {
  create?: Maybe<PermissionsDataFeatureFlagsCreate>;
  delete?: Maybe<PermissionsDataFeatureFlagsDelete>;
  destroy?: Maybe<PermissionsDataFeatureFlagsDestroy>;
  read?: Maybe<PermissionsDataFeatureFlagsRead>;
  update?: Maybe<PermissionsDataFeatureFlagsUpdate>;
};

export type PermissionsDataFeatureFlagsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFeatureFlagsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFeatureFlagsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFeatureFlagsRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FeatureFlagFieldsPermissions>;
  filter?: Maybe<FeatureFlag_PermissionFilter>;
};

export type PermissionsDataFeatureFlagsUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FeatureFlagFieldsPermissions>;
  filter?: Maybe<FeatureFlag_PermissionFilter>;
};

export type PermissionsDataFiles = {
  create?: Maybe<PermissionsDataFilesCreate>;
  delete?: Maybe<PermissionsDataFilesDelete>;
  destroy?: Maybe<PermissionsDataFilesDestroy>;
  read?: Maybe<PermissionsDataFilesRead>;
  update?: Maybe<PermissionsDataFilesUpdate>;
};

export type PermissionsDataFilesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FileFieldsPermissions>;
  filter?: Maybe<File_PermissionFilter>;
};

export type PermissionsDataFilesSettings = {
  create?: Maybe<PermissionsDataFilesSettingsCreate>;
  delete?: Maybe<PermissionsDataFilesSettingsDelete>;
  destroy?: Maybe<PermissionsDataFilesSettingsDestroy>;
  read?: Maybe<PermissionsDataFilesSettingsRead>;
  update?: Maybe<PermissionsDataFilesSettingsUpdate>;
};

export type PermissionsDataFilesSettingsCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesSettingsDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataFilesSettingsDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataFilesSettingsRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FilesSettingFieldsPermissions>;
  filter?: Maybe<FilesSetting_PermissionFilter>;
};

export type PermissionsDataFilesSettingsUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FilesSettingFieldsPermissions>;
  filter?: Maybe<FilesSetting_PermissionFilter>;
};

export type PermissionsDataFilesUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<FileFieldsPermissions>;
  filter?: Maybe<File_PermissionFilter>;
};

export type PermissionsDataMvrLog = {
  create?: Maybe<PermissionsDataMvrLogCreate>;
  delete?: Maybe<PermissionsDataMvrLogDelete>;
  destroy?: Maybe<PermissionsDataMvrLogDestroy>;
  read?: Maybe<PermissionsDataMvrLogRead>;
  update?: Maybe<PermissionsDataMvrLogUpdate>;
};

export type PermissionsDataMvrLogCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMvrLogDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataMvrLogDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataMvrLogRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<MvrLogFieldsPermissions>;
  filter?: Maybe<MvrLog_PermissionFilter>;
};

export type PermissionsDataMvrLogUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<MvrLogFieldsPermissions>;
  filter?: Maybe<MvrLog_PermissionFilter>;
};

export type PermissionsDataNotification = {
  create?: Maybe<PermissionsDataNotificationCreate>;
  delete?: Maybe<PermissionsDataNotificationDelete>;
  destroy?: Maybe<PermissionsDataNotificationDestroy>;
  read?: Maybe<PermissionsDataNotificationRead>;
  update?: Maybe<PermissionsDataNotificationUpdate>;
};

export type PermissionsDataNotificationCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotificationDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationLog = {
  create?: Maybe<PermissionsDataNotificationLogCreate>;
  delete?: Maybe<PermissionsDataNotificationLogDelete>;
  destroy?: Maybe<PermissionsDataNotificationLogDestroy>;
  read?: Maybe<PermissionsDataNotificationLogRead>;
  update?: Maybe<PermissionsDataNotificationLogUpdate>;
};

export type PermissionsDataNotificationLogCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationLogDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotificationLogDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationLogRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationLogFieldsPermissions>;
  filter?: Maybe<NotificationLog_PermissionFilter>;
};

export type PermissionsDataNotificationLogUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationLogFieldsPermissions>;
  filter?: Maybe<NotificationLog_PermissionFilter>;
};

export type PermissionsDataNotificationObject = {
  create?: Maybe<PermissionsDataNotificationObjectCreate>;
  delete?: Maybe<PermissionsDataNotificationObjectDelete>;
  destroy?: Maybe<PermissionsDataNotificationObjectDestroy>;
  read?: Maybe<PermissionsDataNotificationObjectRead>;
  update?: Maybe<PermissionsDataNotificationObjectUpdate>;
};

export type PermissionsDataNotificationObjectCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationObjectDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotificationObjectDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationObjectRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationObjectFieldsPermissions>;
  filter?: Maybe<NotificationObject_PermissionFilter>;
};

export type PermissionsDataNotificationObjectUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationObjectFieldsPermissions>;
  filter?: Maybe<NotificationObject_PermissionFilter>;
};

export type PermissionsDataNotificationRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationFieldsPermissions>;
  filter?: Maybe<Notification_PermissionFilter>;
};

export type PermissionsDataNotificationSetting = {
  create?: Maybe<PermissionsDataNotificationSettingCreate>;
  delete?: Maybe<PermissionsDataNotificationSettingDelete>;
  destroy?: Maybe<PermissionsDataNotificationSettingDestroy>;
  read?: Maybe<PermissionsDataNotificationSettingRead>;
  update?: Maybe<PermissionsDataNotificationSettingUpdate>;
};

export type PermissionsDataNotificationSettingCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationSettingDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataNotificationSettingDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataNotificationSettingRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationSettingFieldsPermissions>;
  filter?: Maybe<NotificationSetting_PermissionFilter>;
};

export type PermissionsDataNotificationSettingUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationSettingFieldsPermissions>;
  filter?: Maybe<NotificationSetting_PermissionFilter>;
};

export type PermissionsDataNotificationUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<NotificationFieldsPermissions>;
  filter?: Maybe<Notification_PermissionFilter>;
};

export type PermissionsDataRoles = {
  create?: Maybe<PermissionsDataRolesCreate>;
  delete?: Maybe<PermissionsDataRolesDelete>;
  destroy?: Maybe<PermissionsDataRolesDestroy>;
  read?: Maybe<PermissionsDataRolesRead>;
  update?: Maybe<PermissionsDataRolesUpdate>;
};

export type PermissionsDataRolesCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRolesDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataRolesDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataRolesRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<RoleFieldsPermissions>;
  filter?: Maybe<Role_PermissionFilter>;
};

export type PermissionsDataRolesUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<RoleFieldsPermissions>;
  filter?: Maybe<Role_PermissionFilter>;
};

export type PermissionsDataUsState = {
  create?: Maybe<PermissionsDataUsStateCreate>;
  delete?: Maybe<PermissionsDataUsStateDelete>;
  destroy?: Maybe<PermissionsDataUsStateDestroy>;
  read?: Maybe<PermissionsDataUsStateRead>;
  update?: Maybe<PermissionsDataUsStateUpdate>;
};

export type PermissionsDataUsStateCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsStateDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataUsStateDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsStateRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<UsStateFieldsPermissions>;
  filter?: Maybe<UsState_PermissionFilter>;
};

export type PermissionsDataUsStateUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<UsStateFieldsPermissions>;
  filter?: Maybe<UsState_PermissionFilter>;
};

export type PermissionsDataUsers = {
  create?: Maybe<PermissionsDataUsersCreate>;
  delete?: Maybe<PermissionsDataUsersDelete>;
  destroy?: Maybe<PermissionsDataUsersDestroy>;
  read?: Maybe<PermissionsDataUsersRead>;
  update?: Maybe<PermissionsDataUsersUpdate>;
};

export type PermissionsDataUsersCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataUsersDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataUsersRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<UserFieldsPermissions>;
  filter?: Maybe<User_PermissionFilter>;
};

export type PermissionsDataUsersUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<UserFieldsPermissions>;
  filter?: Maybe<User_PermissionFilter>;
};

export type PermissionsDataViolationCode = {
  create?: Maybe<PermissionsDataViolationCodeCreate>;
  delete?: Maybe<PermissionsDataViolationCodeDelete>;
  destroy?: Maybe<PermissionsDataViolationCodeDestroy>;
  read?: Maybe<PermissionsDataViolationCodeRead>;
  update?: Maybe<PermissionsDataViolationCodeUpdate>;
};

export type PermissionsDataViolationCodeCreate = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataViolationCodeDelete = {
  allow: Scalars['Boolean'];
  restore?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
};

export type PermissionsDataViolationCodeDestroy = {
  allow: Scalars['Boolean'];
};

export type PermissionsDataViolationCodeRead = {
  allow: Scalars['Boolean'];
  fields?: Maybe<ViolationCodeFieldsPermissions>;
  filter?: Maybe<ViolationCode_PermissionFilter>;
};

export type PermissionsDataViolationCodeUpdate = {
  allow: Scalars['Boolean'];
  fields?: Maybe<ViolationCodeFieldsPermissions>;
  filter?: Maybe<ViolationCode_PermissionFilter>;
};

/** PermissionsInput create input */
export type PermissionsInput = {
  custom?: Maybe<PermissionsCustom>;
  data?: Maybe<PermissionsData>;
};

/** Driver create input from preferredLanguage */
export type PreferredLanguage_DriverCreateInput = {
  archived?: Maybe<Scalars['DateTime']>;
  caseDriverRelation?: Maybe<DriverCaseDriverRelationRelationInput>;
  customer: DriverCustomerRelationInput;
  dateOfBirth?: Maybe<Scalars['Date']>;
  driverEverSignDocRelation?: Maybe<DriverDriverEverSignDocRelationRelationInput>;
  driverLicenseRelation?: Maybe<DriverDriverLicenseRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseState?: Maybe<Scalars['String']>;
  mvrLogRelation?: Maybe<DriverMvrLogRelationRelationInput>;
  name: Scalars['String'];
  phoneNumber?: Maybe<SmartPhoneInput>;
  preferredLanguage?: Maybe<DriverPreferredLanguageRelationInput>;
  status?: Maybe<Scalars['String']>;
};

/** Driver update input from preferredLanguage */
export type PreferredLanguage_DriverUpdateInput = {
  data: DriverUpdateInput;
  filter?: Maybe<DriverKeyFilter>;
};

export type ProjectItemWs = {
  __typename?: 'ProjectItemWS';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<WorkspaceImage>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addressesList: AddressListResponse;
  alert?: Maybe<Alert>;
  alertsList: AlertListResponse;
  apiToken?: Maybe<ApiToken>;
  apiTokensList: ApiTokenListResponse;
  /** @deprecated No longer supported. Use `system.application` instead. */
  application?: Maybe<Application>;
  /** @deprecated No longer supported. Use `system.applicationsList` instead. */
  applicationsList?: Maybe<ApplicationListResponse>;
  /** @deprecated No longer supported. Use `system.asyncSessionStatus` instead. */
  asyncSessionStatus?: Maybe<AsyncSessionStatusResponse>;
  attorney?: Maybe<Attorney>;
  attorneysList: AttorneyListResponse;
  authenticationProfile?: Maybe<AuthenticationProfile>;
  authenticationProfilesList: AuthenticationProfileListResponse;
  authenticationSettings?: Maybe<AuthenticationSetting>;
  /** @deprecated No longer supported. Use `system.billingCurrentPlan` instead. */
  billingCurrentPlan?: Maybe<BillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<BillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: BillingInvoicesListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsageQuotasList` instead. */
  billingMetricUsageQuotasList: BillingMetricUsageQuotasListResponse;
  /** @deprecated No longer supported. Use `system.billingMetricUsagesList` instead. */
  billingMetricUsagesList: BillingMetricUsagesListResponse;
  case?: Maybe<Case>;
  caseComment?: Maybe<CaseComment>;
  caseCommentRepliesList: CaseCommentReplyListResponse;
  caseCommentReply?: Maybe<CaseCommentReply>;
  caseCommentsList: CaseCommentListResponse;
  caseDocument?: Maybe<CaseDocument>;
  caseDocumentsList: CaseDocumentListResponse;
  caseFollower?: Maybe<CaseFollower>;
  caseFollowersList: CaseFollowerListResponse;
  caseHistoriesList: CaseHistoryListResponse;
  caseHistory?: Maybe<CaseHistory>;
  caseLog?: Maybe<CaseLog>;
  caseLogsList: CaseLogListResponse;
  caseStatusLog?: Maybe<CaseStatusLog>;
  caseStatusLogsList: CaseStatusLogListResponse;
  caseViolationCode?: Maybe<CaseViolationCode>;
  caseViolationCodesList: CaseViolationCodeListResponse;
  casesList: CaseListResponse;
  ciCdMigration?: Maybe<CiCdMigration>;
  ciCdMigrationsList: CiCdMigrationListResponse;
  citationCopiesList: CitationCopyListResponse;
  citationCopy?: Maybe<CitationCopy>;
  companyName?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  customerAlert?: Maybe<CustomerAlert>;
  customerAlertsList: CustomerAlertListResponse;
  customerDocument?: Maybe<CustomerDocument>;
  customerDocumentsList: CustomerDocumentListResponse;
  customersList: CustomerListResponse;
  /** @deprecated No longer supported. Use `system.deployStatus` instead. */
  deployStatus: DeployStatusResult;
  documentDraft?: Maybe<DocumentDraft>;
  documentDraftsList: DocumentDraftListResponse;
  driver?: Maybe<Driver>;
  driverLanguage?: Maybe<DriverLanguage>;
  driverLanguagesList: DriverLanguageListResponse;
  driverLicense?: Maybe<DriverLicense>;
  driverLicensesList: DriverLicenseListResponse;
  driversList: DriverListResponse;
  environmentVariable?: Maybe<EnvironmentVariable>;
  environmentVariablesList: EnvironmentVariableListResponse;
  everSignDoc?: Maybe<EverSignDoc>;
  everSignDocsList: EverSignDocListResponse;
  evidence?: Maybe<Evidence>;
  evidencesList: EvidenceListResponse;
  featureFlag?: Maybe<FeatureFlag>;
  featureFlagsList: FeatureFlagListResponse;
  file?: Maybe<File>;
  /** @deprecated No longer supported. Use `fileUploadSignInfo` instead. */
  fileUploadInfo?: Maybe<FileUploadInfoResponse>;
  filesList: FileListResponse;
  filesSetting?: Maybe<FilesSetting>;
  filesSettingsList: FilesSettingListResponse;
  /** @deprecated No longer supported. Use `system.functionsList` instead. */
  functionsList?: Maybe<FunctionListResponse>;
  functionsVersionCheck?: Maybe<FunctionInfoCheck>;
  isAllowedCallbackURL?: Maybe<IsAllowedCallbackUrlQueryResponse>;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  mVRLog?: Maybe<MvrLog>;
  mVRLogsList: MvrLogListResponse;
  notification?: Maybe<Notification>;
  notificationLog?: Maybe<NotificationLog>;
  notificationLogsList: NotificationLogListResponse;
  notificationObject?: Maybe<NotificationObject>;
  notificationObjectsList: NotificationObjectListResponse;
  notificationSetting?: Maybe<NotificationSetting>;
  notificationSettingsList: NotificationSettingListResponse;
  notificationsList: NotificationListResponse;
  role?: Maybe<Role>;
  rolesList: RoleListResponse;
  settings?: Maybe<Setting>;
  system?: Maybe<SystemQuery>;
  /** @deprecated No longer supported. Use `system.table` instead. */
  table?: Maybe<Table>;
  /** @deprecated No longer supported. Use `system.tableField` instead. */
  tableField?: Maybe<TableField>;
  /** @deprecated No longer supported. Use `system.tablesList` instead. */
  tablesList: TableListResponse;
  usState?: Maybe<UsState>;
  usStatesList: UsStateListResponse;
  user?: Maybe<User>;
  userBillingConfiguration: UserBillingConfigurationResponse;
  userBillingConfigurationArchie: UserBillingConfigurationResponse;
  /** @deprecated No longer supported. Use `system.userInvitationsList` instead. */
  userInvitationsList?: Maybe<UserInvitationList>;
  usersList: UserListResponse;
  viewDryRun?: Maybe<ViewDryRunOutput>;
  violationCode?: Maybe<ViolationCode>;
  violationCodesList: ViolationCodeListResponse;
  /** @deprecated No longer supported. Use `system.workspacesList` instead. */
  workspacesList?: Maybe<WorkspaceListResponse>;
};

export type QueryAddressArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAddressesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AddressGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AddressOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AddressSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAlertArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAlertsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AlertSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryApiTokenArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryApiTokensListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApiTokenFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ApiTokenSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryApplicationArgs = {
  id: Scalars['String'];
};

export type QueryAsyncSessionStatusArgs = {
  sessionId: Scalars['String'];
};

export type QueryAttorneyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAttorneysListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AttorneyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AttorneyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AttorneyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AttorneySort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfileArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryAuthenticationProfilesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AuthenticationProfileFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryBillingCurrentPlanArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};

export type QueryBillingInvoicesListArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  by?: Maybe<BillingInvoicesListFilterType>;
  limit?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type QueryBillingMetricUsageQuotasListArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type QueryBillingMetricUsagesListArgs = {
  filter?: Maybe<BillingMetricUsagesListFilter>;
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type QueryCaseArgs = {
  caseID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseCommentArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseCommentRepliesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentReplyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentReplyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentReplyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentReplySort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseCommentReplyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseCommentsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseDocumentArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseDocumentsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseDocumentSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseFollowerArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseFollowersListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFollowerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseFollowerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseFollowerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseFollowerSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseHistoriesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseHistoryGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseHistoryOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseHistorySort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseHistoryArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseLogArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseLogsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseLogSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseStatusLogArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseStatusLogsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseStatusLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseStatusLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseStatusLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseStatusLogSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseViolationCodeArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCaseViolationCodesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseViolationCodeSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCasesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCiCdMigrationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCiCdMigrationsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CiCdMigrationFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CiCdMigrationGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CiCdMigrationOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CiCdMigrationSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCitationCopiesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CitationCopyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CitationCopyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CitationCopyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CitationCopySort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCitationCopyArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomerArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomerAlertArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomerAlertsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerAlertFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerAlertGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerAlertOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerAlertSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomerDocumentArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomerDocumentsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerDocumentFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerDocumentGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerDocumentOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerDocumentSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryCustomersListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CustomerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CustomerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CustomerSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type QueryDocumentDraftArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDocumentDraftsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentDraftFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DocumentDraftGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DocumentDraftOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DocumentDraftSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriverArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriverLanguageArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriverLanguagesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLanguageFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLanguageGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLanguageOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLanguageSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriverLicenseArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriverLicensesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverLicenseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverLicenseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverLicenseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverLicenseSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryDriversListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DriverFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<DriverGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<DriverOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<DriverSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEnvironmentVariablesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EnvironmentVariableFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EnvironmentVariableGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EnvironmentVariableOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EnvironmentVariableSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEverSignDocArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEverSignDocsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EverSignDocFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EverSignDocGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EverSignDocOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EverSignDocSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEvidenceArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryEvidencesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EvidenceFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<EvidenceGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<EvidenceOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<EvidenceSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFeatureFlagArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFeatureFlagsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FeatureFlagFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FeatureFlagGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FeatureFlagOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FeatureFlagSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFileArgs = {
  fileId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFilesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FileFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FileGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FileOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FileSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFilesSettingArgs = {
  filemanagername?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFilesSettingsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FilesSettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<FilesSettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<FilesSettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<FilesSettingSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryFunctionsListArgs = {
  applicationId?: Maybe<Scalars['String']>;
  filter?: Maybe<FunctionInfoFilter>;
  orderBy?: Maybe<Array<Maybe<FunctionInfoOrderBy>>>;
};

export type QueryIsAllowedCallbackUrlArgs = {
  callbackURL: Scalars['String'];
};

export type QueryLogsArgs = {
  applicationId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type QueryMVrLogArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryMVrLogsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<MvrLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<MvrLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<MvrLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<MvrLogSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationLogArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationLogsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationLogFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationLogGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationLogOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationLogSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationObjectArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationObjectsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationObjectFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationObjectGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationObjectOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationObjectSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationSettingArgs = {
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationSettingsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationSettingFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationSettingGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationSettingOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationSettingSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryNotificationsListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryRoleArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryRolesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryTableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type QueryTablesListArgs = {
  filter?: Maybe<TableListFilter>;
};

export type QueryUsStateArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryUsStatesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UsStateFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UsStateGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UsStateOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UsStateSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryUserArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryUserBillingConfigurationArgs = {
  filterPlanProjects?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
};

export type QueryUserBillingConfigurationArchieArgs = {
  ideaId?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
};

export type QueryUsersListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UserSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryViewDryRunArgs = {
  sql: Scalars['String'];
};

export type QueryViolationCodeArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type QueryViolationCodesListArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ViolationCodeSort>>;
  withDeleted?: Maybe<Scalars['Boolean']>;
};

/** RefreshTokenInput */
export type RefreshTokenInput = {
  authProfileId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
};

/** Relation */
export type Relation = {
  __typename?: 'Relation';
  refField?: Maybe<TableField>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTable: Table;
  relationFieldName?: Maybe<Scalars['String']>;
  relationTableName?: Maybe<Scalars['String']>;
};

/** Relation Create Input */
export type RelationCreateInput = {
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
  refFieldName?: Maybe<Scalars['String']>;
  refTableId: Scalars['ID'];
};

/** Relation Update Input */
export type RelationUpdateInput = {
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTableId?: Maybe<Scalars['ID']>;
};

/** Relative Date Predicate Operation Enum */
export enum RelativePredicateOpEnum {
  Add = 'ADD',
  Sub = 'SUB',
}

/** Relative Date Predicate Unit Enum */
export enum RelativePredicateUnitEnum {
  Day = 'DAY',
  DayHour = 'DAY_HOUR',
  DayMicrosecond = 'DAY_MICROSECOND',
  DayMinute = 'DAY_MINUTE',
  DaySecond = 'DAY_SECOND',
  Hour = 'HOUR',
  HourMicrosecond = 'HOUR_MICROSECOND',
  HourMinute = 'HOUR_MINUTE',
  HourSecond = 'HOUR_SECOND',
  Microsecond = 'MICROSECOND',
  Minute = 'MINUTE',
  MinuteMicrosecond = 'MINUTE_MICROSECOND',
  MinuteSecond = 'MINUTE_SECOND',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Second = 'SECOND',
  SecondMicrosecond = 'SECOND_MICROSECOND',
  Week = 'WEEK',
  Year = 'YEAR',
  YearMonth = 'YEAR_MONTH',
}

export type ReplaceFunctionArguments = {
  from: Scalars['String'];
  to: Scalars['String'];
};

/** CaseComment create input from replies */
export type Replies_CaseCommentCreateInput = {
  caseRelation?: Maybe<CaseCommentCaseRelationRelationInput>;
  comment?: Maybe<Scalars['String']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesRelationInput>;
};

/** CaseComment update input from replies */
export type Replies_CaseCommentUpdateInput = {
  caseRelation?: Maybe<CaseCommentCaseRelationUpdateRelationInput>;
  comment?: Maybe<Scalars['String']>;
  /** PUBLIC is for every user role, INTERNAL are comments by agent or admin users. */
  mode?: Maybe<Scalars['String']>;
  replies?: Maybe<CaseCommentRepliesUpdateRelationInput>;
};

export type ResizeImageDirectiveCropOptions = {
  height: Scalars['Int'];
  offsetX?: Maybe<Scalars['Int']>;
  offsetY?: Maybe<Scalars['Int']>;
  width: Scalars['Int'];
};

export type ResizeImageDirectiveFlipOptions = {
  horizontally?: Maybe<Scalars['Boolean']>;
  vertically?: Maybe<Scalars['Boolean']>;
};

export type ResizeImageDirectiveResizeOptions = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  _description?: Maybe<Scalars['String']>;
  apiTokens?: Maybe<ApiTokenListResponse>;
  authenticationProfiles?: Maybe<AuthenticationProfileListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  membersCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionListResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UserListResponse>;
};

export type RoleApiTokensArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ApiTokenFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<ApiTokenGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<ApiTokenOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<ApiTokenSort>>;
};

export type RoleAuthenticationProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AuthenticationProfileFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AuthenticationProfileGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AuthenticationProfileOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AuthenticationProfileSort>>;
};

export type RolePermissionsArgs = {
  filter?: Maybe<PermissionInputFilter>;
};

export type RoleUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UserSort>>;
};

/** Roles create input */
export type RoleCreateInput = {
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<PermissionsInput>;
  users?: Maybe<RolesUsersRelationInput>;
};

/** Roles create many input */
export type RoleCreateManyInput = {
  apiTokens?: Maybe<RolesApiTokensManyRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesManyRelationInput>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  users?: Maybe<RolesUsersManyRelationInput>;
};

/** Roles delete input */
export type RoleDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Viewer = 'VIEWER',
}

/** RoleFieldsPermissions create input */
export type RoleFieldsPermissions = {
  createdAt?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type RoleFilter = {
  AND?: Maybe<Array<RoleFilter>>;
  OR?: Maybe<Array<RoleFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  apiTokens?: Maybe<ApiTokenRelationFilter>;
  authenticationProfiles?: Maybe<AuthenticationProfileRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  permissions?: Maybe<PermissionRelationFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
  users?: Maybe<UserRelationFilter>;
};

export type RoleGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: RoleGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type RoleGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  apiTokens?: Maybe<ApiTokenGroupByQuery>;
  authenticationProfiles?: Maybe<AuthenticationProfileGroupByQuery>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  description?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  permissions?: Maybe<PermissionGroupByQuery>;
  updatedAt?: Maybe<Array<GroupByField>>;
  users?: Maybe<UserGroupByQuery>;
};

export type RoleKeyFilter = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** RoleListResponse output */
export type RoleListResponse = {
  __typename?: 'RoleListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Role>;
};

/** RoleManyResponse output */
export type RoleManyResponse = {
  __typename?: 'RoleManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<Role>;
};

/** No longer supported. Use `sort` instead. */
export enum RoleOrderBy {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SystemTypeAsc = 'systemType_ASC',
  SystemTypeDesc = 'systemType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Roles subscription payload */
export type RolePayload = {
  __typename?: 'RolePayload';
  mutation: MutationType;
  node?: Maybe<Role>;
  previousValues?: Maybe<Role>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleRelationFilter = {
  every?: Maybe<RoleFilter>;
  none?: Maybe<RoleFilter>;
  some?: Maybe<RoleFilter>;
};

export type RoleSort = {
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** Roles subscription filter */
export type RoleSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<RoleFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Roles update input */
export type RoleUpdateByFilterInput = {
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  systemType?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Roles update input */
export type RoleUpdateInput = {
  apiTokens?: Maybe<RolesApiTokensUpdateRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesUpdateRelationInput>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<PermissionsInput>;
  users?: Maybe<RolesUsersUpdateRelationInput>;
};

export type Role_PermissionFilter = {
  AND?: Maybe<Array<Role_PermissionFilter>>;
  OR?: Maybe<Array<Role_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  apiTokens?: Maybe<ApiToken_PermissionRelationFilter>;
  authenticationProfiles?: Maybe<AuthenticationProfile_PermissionRelationFilter>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  name?: Maybe<StringPredicate>;
  permissions?: Maybe<Permission_PermissionRelationFilter>;
  updatedAt?: Maybe<DateTimePredicate>;
  users?: Maybe<User_PermissionRelationFilter>;
};

export type Role_PermissionRelationFilter = {
  every?: Maybe<Role_PermissionFilter>;
  none?: Maybe<Role_PermissionFilter>;
  some?: Maybe<Role_PermissionFilter>;
};

/** Roles relation input */
export type RolesApiTokensManyRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
};

/** Roles relation input */
export type RolesApiTokensUpdateRelationInput = {
  connect?: Maybe<Array<ApiTokenKeyFilter>>;
  disconnect?: Maybe<Array<ApiTokenKeyFilter>>;
  reconnect?: Maybe<Array<ApiTokenKeyFilter>>;
  update?: Maybe<Array<Maybe<Roles_ApiTokenUpdateInput>>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesManyRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_AuthenticationProfileCreateInput>>>;
};

/** Roles relation input */
export type RolesAuthenticationProfilesUpdateRelationInput = {
  connect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_AuthenticationProfileCreateInput>>>;
  disconnect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  reconnect?: Maybe<Array<AuthenticationProfileKeyFilter>>;
  update?: Maybe<Array<Maybe<Roles_AuthenticationProfileUpdateInput>>>;
};

/** Roles relation input */
export type RolesUsersManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** Roles relation input */
export type RolesUsersRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_UserCreateInput>>>;
};

/** Roles relation input */
export type RolesUsersUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<Roles_UserCreateInput>>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  update?: Maybe<Array<Maybe<Roles_UserUpdateInput>>>;
};

/** ApiTokens update input from roles */
export type Roles_ApiTokenUpdateInput = {
  data: ApiTokenUpdateInput;
  filter?: Maybe<ApiTokenKeyFilter>;
};

/** AuthenticationProfiles create input from roles */
export type Roles_AuthenticationProfileCreateInput = {
  audiences?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId?: Maybe<Scalars['String']>;
  databaseName?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  managementDomain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: Maybe<AuthenticationProfilesRolesRelationInput>;
  secret?: Maybe<Scalars['String']>;
  selfSignUpEmailDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfSignUpEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** AuthenticationProfiles update input from roles */
export type Roles_AuthenticationProfileUpdateInput = {
  data: AuthenticationProfileUpdateInput;
  filter?: Maybe<AuthenticationProfileKeyFilter>;
};

/** Users create input from roles */
export type Roles_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from roles */
export type Roles_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: Maybe<UserKeyFilter>;
};

/** Schema Origin */
export type SchemaOrigin = {
  __typename?: 'SchemaOrigin';
  provider?: Maybe<Scalars['String']>;
  type: SchemaOriginType;
};

/** Schema Origin Type Enum */
export enum SchemaOriginType {
  Local = 'LOCAL',
  Remote = 'REMOTE',
  View = 'VIEW',
}

export type SendEmailResult = {
  __typename?: 'SendEmailResult';
  sent: Scalars['Boolean'];
};

export type Setting = {
  __typename?: 'Setting';
  _description?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  buttonLinkColor?: Maybe<Scalars['String']>;
  containerColor?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  currency?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  landingPageImage?: Maybe<File>;
  language?: Maybe<Scalars['String']>;
  leftNavColor?: Maybe<Scalars['String']>;
  menuBarBGColor?: Maybe<Scalars['String']>;
  menuBarIconsColor?: Maybe<Scalars['String']>;
  menuBarLogo?: Maybe<File>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequireLowercase?: Maybe<Scalars['Boolean']>;
  passwordRequireNumbers?: Maybe<Scalars['Boolean']>;
  passwordRequireSpecial?: Maybe<Scalars['Boolean']>;
  passwordRequireUppercase?: Maybe<Scalars['Boolean']>;
  passwordUpdateInterval?: Maybe<Scalars['Int']>;
  rememberDevice?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userInterfaceStyle?: Maybe<Scalars['String']>;
  vanityUrl?: Maybe<Scalars['String']>;
};

export type SettingFilter = {
  AND?: Maybe<Array<SettingFilter>>;
  OR?: Maybe<Array<SettingFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  bgColor?: Maybe<StringPredicate>;
  buttonLinkColor?: Maybe<StringPredicate>;
  containerColor?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  currency?: Maybe<StringPredicate>;
  dateFormat?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  landingPageImage?: Maybe<FileFilter>;
  language?: Maybe<StringPredicate>;
  leftNavColor?: Maybe<StringPredicate>;
  menuBarBGColor?: Maybe<StringPredicate>;
  menuBarIconsColor?: Maybe<StringPredicate>;
  menuBarLogo?: Maybe<FileFilter>;
  passwordMinLength?: Maybe<IntPredicate>;
  passwordRequireLowercase?: Maybe<BoolPredicate>;
  passwordRequireNumbers?: Maybe<BoolPredicate>;
  passwordRequireSpecial?: Maybe<BoolPredicate>;
  passwordRequireUppercase?: Maybe<BoolPredicate>;
  passwordUpdateInterval?: Maybe<IntPredicate>;
  rememberDevice?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userInterfaceStyle?: Maybe<StringPredicate>;
  vanityUrl?: Maybe<StringPredicate>;
};

export type SettingGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: SettingGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type SettingGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  bgColor?: Maybe<Array<GroupByField>>;
  buttonLinkColor?: Maybe<Array<GroupByField>>;
  containerColor?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  currency?: Maybe<Array<GroupByField>>;
  dateFormat?: Maybe<Array<GroupByField>>;
  landingPageImage?: Maybe<FileGroupByQuery>;
  language?: Maybe<Array<GroupByField>>;
  leftNavColor?: Maybe<Array<GroupByField>>;
  menuBarBGColor?: Maybe<Array<GroupByField>>;
  menuBarIconsColor?: Maybe<Array<GroupByField>>;
  menuBarLogo?: Maybe<FileGroupByQuery>;
  passwordMinLength?: Maybe<Array<GroupByField>>;
  passwordRequireLowercase?: Maybe<Array<GroupByField>>;
  passwordRequireNumbers?: Maybe<Array<GroupByField>>;
  passwordRequireSpecial?: Maybe<Array<GroupByField>>;
  passwordRequireUppercase?: Maybe<Array<GroupByField>>;
  passwordUpdateInterval?: Maybe<Array<GroupByField>>;
  rememberDevice?: Maybe<Array<GroupByField>>;
  timezone?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  userInterfaceStyle?: Maybe<Array<GroupByField>>;
  vanityUrl?: Maybe<Array<GroupByField>>;
};

/** SettingListResponse output */
export type SettingListResponse = {
  __typename?: 'SettingListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<Setting>;
};

/** No longer supported. Use `sort` instead. */
export enum SettingOrderBy {
  BgColorAsc = 'bgColor_ASC',
  BgColorDesc = 'bgColor_DESC',
  ButtonLinkColorAsc = 'buttonLinkColor_ASC',
  ButtonLinkColorDesc = 'buttonLinkColor_DESC',
  ContainerColorAsc = 'containerColor_ASC',
  ContainerColorDesc = 'containerColor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  DateFormatAsc = 'dateFormat_ASC',
  DateFormatDesc = 'dateFormat_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  LeftNavColorAsc = 'leftNavColor_ASC',
  LeftNavColorDesc = 'leftNavColor_DESC',
  MenuBarBgColorAsc = 'menuBarBGColor_ASC',
  MenuBarBgColorDesc = 'menuBarBGColor_DESC',
  MenuBarIconsColorAsc = 'menuBarIconsColor_ASC',
  MenuBarIconsColorDesc = 'menuBarIconsColor_DESC',
  PasswordMinLengthAsc = 'passwordMinLength_ASC',
  PasswordMinLengthDesc = 'passwordMinLength_DESC',
  PasswordRequireLowercaseAsc = 'passwordRequireLowercase_ASC',
  PasswordRequireLowercaseDesc = 'passwordRequireLowercase_DESC',
  PasswordRequireNumbersAsc = 'passwordRequireNumbers_ASC',
  PasswordRequireNumbersDesc = 'passwordRequireNumbers_DESC',
  PasswordRequireSpecialAsc = 'passwordRequireSpecial_ASC',
  PasswordRequireSpecialDesc = 'passwordRequireSpecial_DESC',
  PasswordRequireUppercaseAsc = 'passwordRequireUppercase_ASC',
  PasswordRequireUppercaseDesc = 'passwordRequireUppercase_DESC',
  PasswordUpdateIntervalAsc = 'passwordUpdateInterval_ASC',
  PasswordUpdateIntervalDesc = 'passwordUpdateInterval_DESC',
  RememberDeviceAsc = 'rememberDevice_ASC',
  RememberDeviceDesc = 'rememberDevice_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UserInterfaceStyleAsc = 'userInterfaceStyle_ASC',
  UserInterfaceStyleDesc = 'userInterfaceStyle_DESC',
  VanityUrlAsc = 'vanityUrl_ASC',
  VanityUrlDesc = 'vanityUrl_DESC',
}

/** Settings subscription payload */
export type SettingPayload = {
  __typename?: 'SettingPayload';
  mutation: MutationType;
  node?: Maybe<Setting>;
  previousValues?: Maybe<Setting>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SettingRelationFilter = {
  every?: Maybe<SettingFilter>;
  none?: Maybe<SettingFilter>;
  some?: Maybe<SettingFilter>;
};

export type SettingSort = {
  bgColor?: Maybe<SortOrder>;
  buttonLinkColor?: Maybe<SortOrder>;
  containerColor?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  currency?: Maybe<SortOrder>;
  dateFormat?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
  landingPageImage?: Maybe<FileSort>;
  language?: Maybe<SortOrder>;
  leftNavColor?: Maybe<SortOrder>;
  menuBarBGColor?: Maybe<SortOrder>;
  menuBarIconsColor?: Maybe<SortOrder>;
  menuBarLogo?: Maybe<FileSort>;
  passwordMinLength?: Maybe<SortOrder>;
  passwordRequireLowercase?: Maybe<SortOrder>;
  passwordRequireNumbers?: Maybe<SortOrder>;
  passwordRequireSpecial?: Maybe<SortOrder>;
  passwordRequireUppercase?: Maybe<SortOrder>;
  passwordUpdateInterval?: Maybe<SortOrder>;
  rememberDevice?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userInterfaceStyle?: Maybe<SortOrder>;
  vanityUrl?: Maybe<SortOrder>;
};

/** Settings subscription filter */
export type SettingSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<SettingFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Settings update input */
export type SettingUpdateInput = {
  bgColor?: Maybe<Scalars['String']>;
  buttonLinkColor?: Maybe<Scalars['String']>;
  containerColor?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  leftNavColor?: Maybe<Scalars['String']>;
  menuBarBGColor?: Maybe<Scalars['String']>;
  menuBarIconsColor?: Maybe<Scalars['String']>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordRequireLowercase?: Maybe<Scalars['Boolean']>;
  passwordRequireNumbers?: Maybe<Scalars['Boolean']>;
  passwordRequireSpecial?: Maybe<Scalars['Boolean']>;
  passwordRequireUppercase?: Maybe<Scalars['Boolean']>;
  passwordUpdateInterval?: Maybe<Scalars['Int']>;
  rememberDevice?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userInterfaceStyle?: Maybe<Scalars['String']>;
  vanityUrl?: Maybe<Scalars['String']>;
};

export type Setting_PermissionFilter = {
  AND?: Maybe<Array<Setting_PermissionFilter>>;
  OR?: Maybe<Array<Setting_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  bgColor?: Maybe<StringPredicate>;
  buttonLinkColor?: Maybe<StringPredicate>;
  containerColor?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  currency?: Maybe<StringPredicate>;
  dateFormat?: Maybe<StringPredicate>;
  deletedAt?: Maybe<IntPredicate>;
  landingPageImage?: Maybe<File_PermissionFilter>;
  language?: Maybe<StringPredicate>;
  leftNavColor?: Maybe<StringPredicate>;
  menuBarBGColor?: Maybe<StringPredicate>;
  menuBarIconsColor?: Maybe<StringPredicate>;
  menuBarLogo?: Maybe<File_PermissionFilter>;
  passwordMinLength?: Maybe<IntPredicate>;
  passwordRequireLowercase?: Maybe<BoolPredicate>;
  passwordRequireNumbers?: Maybe<BoolPredicate>;
  passwordRequireSpecial?: Maybe<BoolPredicate>;
  passwordRequireUppercase?: Maybe<BoolPredicate>;
  passwordUpdateInterval?: Maybe<IntPredicate>;
  rememberDevice?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userInterfaceStyle?: Maybe<StringPredicate>;
  vanityUrl?: Maybe<StringPredicate>;
};

export type Setting_PermissionRelationFilter = {
  every?: Maybe<Setting_PermissionFilter>;
  none?: Maybe<Setting_PermissionFilter>;
  some?: Maybe<Setting_PermissionFilter>;
};

/** SignUpResendInput */
export type SignUpResendInput = {
  email: Scalars['String'];
};

/** Smart Field Attributes */
export type SmartFieldTypeAttributes = {
  __typename?: 'SmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<CustomTableField>>>;
};

export type SmartPhone = {
  __typename?: 'SmartPhone';
  code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type SmartPhoneGroupByQuery = {
  code?: Maybe<Array<GroupByField>>;
  number?: Maybe<Array<GroupByField>>;
};

/** SmartPhoneInput create input */
export type SmartPhoneInput = {
  code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type SmartPhonePredicate = {
  code?: Maybe<StringPredicate>;
  number?: Maybe<StringPredicate>;
};

export type SmartPhoneSort = {
  code?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
};

/** Smart Type Format Enum */
export enum SmartTypeFormatEnum {
  Address = 'ADDRESS',
  Phone = 'PHONE',
}

/** SortOrder */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Address create input from state */
export type State_AddressCreateInput = {
  apartment?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<AddressCustomerRelationInput>;
  state?: Maybe<AddressStateRelationInput>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Address update input from state */
export type State_AddressUpdateInput = {
  data: AddressUpdateInput;
  filter?: Maybe<AddressKeyFilter>;
};

/** Users create input from state */
export type State_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from state */
export type State_UserUpdateInput = {
  data: UserUpdateInput;
  filter?: Maybe<UserKeyFilter>;
};

export type StringPadFunctionArguments = {
  len: Scalars['Int'];
  str: Scalars['String'];
};

export type StringPredicate = {
  contains?: Maybe<Scalars['String']>;
  ends_with?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  not_contains?: Maybe<Scalars['String']>;
  not_ends_with?: Maybe<Scalars['String']>;
  not_equals?: Maybe<Scalars['String']>;
  not_in?: Maybe<Array<Scalars['String']>>;
  not_starts_with?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
};

export type StringPredicateHaving = {
  AND?: Maybe<Array<StringPredicateHaving>>;
  OR?: Maybe<Array<StringPredicateHaving>>;
  contains?: Maybe<Scalars['String']>;
  ends_with?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  is_empty?: Maybe<Scalars['Boolean']>;
  is_not_empty?: Maybe<Scalars['Boolean']>;
  not_contains?: Maybe<Scalars['String']>;
  not_ends_with?: Maybe<Scalars['String']>;
  not_equals?: Maybe<Scalars['String']>;
  not_in?: Maybe<Array<Scalars['String']>>;
  not_starts_with?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
};

export enum StringTrimMode {
  Both = 'BOTH',
  Leading = 'LEADING',
  Trailing = 'TRAILING',
}

export type Subscription = {
  __typename?: 'Subscription';
  Address?: Maybe<AddressPayload>;
  Alert?: Maybe<AlertPayload>;
  ApiTokens?: Maybe<ApiTokenPayload>;
  Attorney?: Maybe<AttorneyPayload>;
  AuthenticationProfiles?: Maybe<AuthenticationProfilePayload>;
  AuthenticationSettings?: Maybe<AuthenticationSettingPayload>;
  Case?: Maybe<CasePayload>;
  CaseComment?: Maybe<CaseCommentPayload>;
  CaseCommentReply?: Maybe<CaseCommentReplyPayload>;
  CaseDocument?: Maybe<CaseDocumentPayload>;
  CaseFollower?: Maybe<CaseFollowerPayload>;
  CaseHistory?: Maybe<CaseHistoryPayload>;
  CaseLog?: Maybe<CaseLogPayload>;
  CaseStatusLog?: Maybe<CaseStatusLogPayload>;
  CaseViolationCode?: Maybe<CaseViolationCodePayload>;
  CiCdMigrations?: Maybe<CiCdMigrationPayload>;
  CitationCopy?: Maybe<CitationCopyPayload>;
  Customer?: Maybe<CustomerPayload>;
  CustomerAlert?: Maybe<CustomerAlertPayload>;
  CustomerDocument?: Maybe<CustomerDocumentPayload>;
  DocumentDraft?: Maybe<DocumentDraftPayload>;
  Driver?: Maybe<DriverPayload>;
  DriverLanguage?: Maybe<DriverLanguagePayload>;
  DriverLicense?: Maybe<DriverLicensePayload>;
  EnvironmentVariables?: Maybe<EnvironmentVariablePayload>;
  EverSignDoc?: Maybe<EverSignDocPayload>;
  Evidence?: Maybe<EvidencePayload>;
  FeatureFlags?: Maybe<FeatureFlagPayload>;
  Files?: Maybe<FilePayload>;
  FilesSettings?: Maybe<FilesSettingPayload>;
  MVRLog?: Maybe<MvrLogPayload>;
  Notification?: Maybe<NotificationPayload>;
  NotificationLog?: Maybe<NotificationLogPayload>;
  NotificationObject?: Maybe<NotificationObjectPayload>;
  NotificationSetting?: Maybe<NotificationSettingPayload>;
  Permissions?: Maybe<PermissionPayload>;
  Roles?: Maybe<RolePayload>;
  Settings?: Maybe<SettingPayload>;
  UsState?: Maybe<UsStatePayload>;
  Users?: Maybe<UserPayload>;
  ViolationCode?: Maybe<ViolationCodePayload>;
};

export type SubscriptionAddressArgs = {
  filter?: Maybe<AddressSubscriptionFilter>;
};

export type SubscriptionAlertArgs = {
  filter?: Maybe<AlertSubscriptionFilter>;
};

export type SubscriptionApiTokensArgs = {
  filter?: Maybe<ApiTokenSubscriptionFilter>;
};

export type SubscriptionAttorneyArgs = {
  filter?: Maybe<AttorneySubscriptionFilter>;
};

export type SubscriptionAuthenticationProfilesArgs = {
  filter?: Maybe<AuthenticationProfileSubscriptionFilter>;
};

export type SubscriptionAuthenticationSettingsArgs = {
  filter?: Maybe<AuthenticationSettingSubscriptionFilter>;
};

export type SubscriptionCaseArgs = {
  filter?: Maybe<CaseSubscriptionFilter>;
};

export type SubscriptionCaseCommentArgs = {
  filter?: Maybe<CaseCommentSubscriptionFilter>;
};

export type SubscriptionCaseCommentReplyArgs = {
  filter?: Maybe<CaseCommentReplySubscriptionFilter>;
};

export type SubscriptionCaseDocumentArgs = {
  filter?: Maybe<CaseDocumentSubscriptionFilter>;
};

export type SubscriptionCaseFollowerArgs = {
  filter?: Maybe<CaseFollowerSubscriptionFilter>;
};

export type SubscriptionCaseHistoryArgs = {
  filter?: Maybe<CaseHistorySubscriptionFilter>;
};

export type SubscriptionCaseLogArgs = {
  filter?: Maybe<CaseLogSubscriptionFilter>;
};

export type SubscriptionCaseStatusLogArgs = {
  filter?: Maybe<CaseStatusLogSubscriptionFilter>;
};

export type SubscriptionCaseViolationCodeArgs = {
  filter?: Maybe<CaseViolationCodeSubscriptionFilter>;
};

export type SubscriptionCiCdMigrationsArgs = {
  filter?: Maybe<CiCdMigrationSubscriptionFilter>;
};

export type SubscriptionCitationCopyArgs = {
  filter?: Maybe<CitationCopySubscriptionFilter>;
};

export type SubscriptionCustomerArgs = {
  filter?: Maybe<CustomerSubscriptionFilter>;
};

export type SubscriptionCustomerAlertArgs = {
  filter?: Maybe<CustomerAlertSubscriptionFilter>;
};

export type SubscriptionCustomerDocumentArgs = {
  filter?: Maybe<CustomerDocumentSubscriptionFilter>;
};

export type SubscriptionDocumentDraftArgs = {
  filter?: Maybe<DocumentDraftSubscriptionFilter>;
};

export type SubscriptionDriverArgs = {
  filter?: Maybe<DriverSubscriptionFilter>;
};

export type SubscriptionDriverLanguageArgs = {
  filter?: Maybe<DriverLanguageSubscriptionFilter>;
};

export type SubscriptionDriverLicenseArgs = {
  filter?: Maybe<DriverLicenseSubscriptionFilter>;
};

export type SubscriptionEnvironmentVariablesArgs = {
  filter?: Maybe<EnvironmentVariableSubscriptionFilter>;
};

export type SubscriptionEverSignDocArgs = {
  filter?: Maybe<EverSignDocSubscriptionFilter>;
};

export type SubscriptionEvidenceArgs = {
  filter?: Maybe<EvidenceSubscriptionFilter>;
};

export type SubscriptionFeatureFlagsArgs = {
  filter?: Maybe<FeatureFlagSubscriptionFilter>;
};

export type SubscriptionFilesArgs = {
  filter?: Maybe<FileSubscriptionFilter>;
};

export type SubscriptionFilesSettingsArgs = {
  filter?: Maybe<FilesSettingSubscriptionFilter>;
};

export type SubscriptionMvrLogArgs = {
  filter?: Maybe<MvrLogSubscriptionFilter>;
};

export type SubscriptionNotificationArgs = {
  filter?: Maybe<NotificationSubscriptionFilter>;
};

export type SubscriptionNotificationLogArgs = {
  filter?: Maybe<NotificationLogSubscriptionFilter>;
};

export type SubscriptionNotificationObjectArgs = {
  filter?: Maybe<NotificationObjectSubscriptionFilter>;
};

export type SubscriptionNotificationSettingArgs = {
  filter?: Maybe<NotificationSettingSubscriptionFilter>;
};

export type SubscriptionPermissionsArgs = {
  filter?: Maybe<PermissionSubscriptionFilter>;
};

export type SubscriptionRolesArgs = {
  filter?: Maybe<RoleSubscriptionFilter>;
};

export type SubscriptionSettingsArgs = {
  filter?: Maybe<SettingSubscriptionFilter>;
};

export type SubscriptionUsStateArgs = {
  filter?: Maybe<UsStateSubscriptionFilter>;
};

export type SubscriptionUsersArgs = {
  filter?: Maybe<UserSubscriptionFilter>;
};

export type SubscriptionViolationCodeArgs = {
  filter?: Maybe<ViolationCodeSubscriptionFilter>;
};

export type SubstringFunctionArguments = {
  len?: Maybe<Scalars['Int']>;
  pos: Scalars['Int'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SuccessWithMessageResponse = {
  __typename?: 'SuccessWithMessageResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Switch Field Attributes */
export type SwitchFieldTypeAttributes = {
  __typename?: 'SwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Switch Type Format Enum */
export enum SwitchTypeFormatEnum {
  ActiveInactive = 'ACTIVE_INACTIVE',
  Custom = 'CUSTOM',
  HighLow = 'HIGH_LOW',
  OnOff = 'ON_OFF',
  TrueFalse = 'TRUE_FALSE',
  YesNo = 'YES_NO',
}

export type SystemAiIdeasInput = {
  examples?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

/** Application */
export type SystemApplication = {
  __typename?: 'SystemApplication';
  appType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: SystemApplicationStatusEnum;
};

/** ApplicationDeleteMutationInput */
export type SystemApplicationDeleteMutationInput = {
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

/** Application install input */
export type SystemApplicationInstallInput = {
  appType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<SystemApplicationStatusEnum>;
};

/** SystemApplicationListResponse output */
export type SystemApplicationListResponse = {
  __typename?: 'SystemApplicationListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemApplication>;
};

/** Application Status Enum */
export enum SystemApplicationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** Application update input */
export type SystemApplicationUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SystemApplicationStatusEnum>;
};

export type SystemAsyncSessionStatusResponse = {
  __typename?: 'SystemAsyncSessionStatusResponse';
  result?: Maybe<Scalars['JSON']>;
  status: Scalars['String'];
};

export type SystemAuthProfile = {
  __typename?: 'SystemAuthProfile';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SystemAuthenticationInfo = {
  __typename?: 'SystemAuthenticationInfo';
  authProfile?: Maybe<Array<Maybe<SystemAuthProfile>>>;
  environmentId?: Maybe<Scalars['String']>;
  environmentName?: Maybe<Scalars['String']>;
};

export type SystemBackendUtilizationResponse = {
  __typename?: 'SystemBackendUtilizationResponse';
  authProfiles?: Maybe<Scalars['Int']>;
  databaseRows?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['Int']>;
  fileStorageSize?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  roles?: Maybe<Scalars['Int']>;
  tables?: Maybe<Scalars['Int']>;
};

export type SystemBasicSectionConfigurationForPromptExecutionResponse = {
  __typename?: 'SystemBasicSectionConfigurationForPromptExecutionResponse';
  content?: Maybe<Scalars['JSON']>;
};

export type SystemBasicSectionConfigurationItem = {
  __typename?: 'SystemBasicSectionConfigurationItem';
  criteria: Scalars['String'];
  description: Scalars['String'];
  enabledWith8base?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SystemBasicSectionConfigurationResponse = {
  __typename?: 'SystemBasicSectionConfigurationResponse';
  application_classification?: Maybe<
    Array<Maybe<SystemBasicSectionConfigurationItem>>
  >;
  building_blocks?: Maybe<Array<Maybe<SystemBasicSectionConfigurationItem>>>;
  commercialization_model?: Maybe<
    Array<Maybe<SystemBasicSectionConfigurationItem>>
  >;
};

export type SystemBillingCurrentPlanResponse = {
  __typename?: 'SystemBillingCurrentPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<SystemWorkspaceStatus>;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export enum SystemBillingDetailsOrigin {
  Member = 'member',
  Organization = 'organization',
  Workspace = 'workspace',
}

export type SystemBillingDetailsResponse = {
  __typename?: 'SystemBillingDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: SystemBillingDetailsOrigin;
};

export type SystemBillingInvoiceItem = {
  __typename?: 'SystemBillingInvoiceItem';
  amountDue?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountRemaining?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  endingBalance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoicePdf?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization?: Maybe<SystemBillingInvoiceItemOrganizationInfo>;
  paid?: Maybe<Scalars['Boolean']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<SystemBillingInvoiceItemPlanInfo>;
  project?: Maybe<SystemBillingInvoiceItemProjectInfo>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type SystemBillingInvoiceItemOrganizationInfo = {
  __typename?: 'SystemBillingInvoiceItemOrganizationInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemPlanInfo = {
  __typename?: 'SystemBillingInvoiceItemPlanInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemBillingInvoiceItemProjectInfo = {
  __typename?: 'SystemBillingInvoiceItemProjectInfo';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum SystemBillingInvoicesListFilterType {
  Customer = 'CUSTOMER',
  Project = 'PROJECT',
}

/** SystemBillingInvoicesListResponse output */
export type SystemBillingInvoicesListResponse = {
  __typename?: 'SystemBillingInvoicesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingInvoiceItem>;
};

export type SystemBillingLimitMetricItem = {
  __typename?: 'SystemBillingLimitMetricItem';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  showPriority?: Maybe<Scalars['Int']>;
  tooltip?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type SystemBillingMetricOverageItem = {
  __typename?: 'SystemBillingMetricOverageItem';
  value?: Maybe<Scalars['Float']>;
  warning?: Maybe<Scalars['String']>;
};

export type SystemBillingMetricUsageItem = {
  __typename?: 'SystemBillingMetricUsageItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  overage?: Maybe<SystemBillingMetricOverageItem>;
  value?: Maybe<Scalars['Float']>;
};

export type SystemBillingMetricUsageQuotaItem = {
  __typename?: 'SystemBillingMetricUsageQuotaItem';
  limitMetric?: Maybe<SystemBillingLimitMetricItem>;
  value?: Maybe<Scalars['Float']>;
};

/** SystemBillingMetricUsageQuotasListResponse output */
export type SystemBillingMetricUsageQuotasListResponse = {
  __typename?: 'SystemBillingMetricUsageQuotasListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingMetricUsageQuotaItem>;
};

export type SystemBillingMetricUsagesListFilter = {
  entryDate: DateTimePredicate;
};

/** SystemBillingMetricUsagesListResponse output */
export type SystemBillingMetricUsagesListResponse = {
  __typename?: 'SystemBillingMetricUsagesListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemBillingMetricUsageItem>;
};

export type SystemBillingNextPlanResponse = {
  __typename?: 'SystemBillingNextPlanResponse';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type SystemBillingPlanBaseInfo = {
  __typename?: 'SystemBillingPlanBaseInfo';
  capacity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  featuresTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isLegacy?: Maybe<Scalars['Boolean']>;
  limitMetrics?: Maybe<Array<Maybe<SystemBillingPlanLimitMetricItem>>>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type SystemBillingPlanLimitMetricItem = {
  __typename?: 'SystemBillingPlanLimitMetricItem';
  displayName?: Maybe<Scalars['String']>;
  hardLimit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  overagePrice?: Maybe<Scalars['Int']>;
  softLimit?: Maybe<Scalars['Float']>;
};

/** BillingPlanUpdateMutationInput */
export type SystemBillingPlanUpdateMutationInput = {
  planId: Scalars['ID'];
  projectID: Scalars['ID'];
};

export enum SystemBranchEnvironmentMode {
  Full = 'FULL',
  System = 'SYSTEM',
}

export type SystemCacheEvictResponse = {
  __typename?: 'SystemCacheEvictResponse';
  evicted: Array<Maybe<Scalars['String']>>;
};

export type SystemChangePlanIdeaMutationInput = {
  archieId: Scalars['ID'];
  couponId?: Maybe<Scalars['String']>;
  couponName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newPlan: Scalars['String'];
};

export type SystemChangeRoleIdeaMemberMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
  role: InvitationRoleEnum;
};

export type SystemChangeRoleIdeaMutationInputList = {
  recipients: Array<SystemChangeRoleIdeaMemberMutationInput>;
};

/** Ci Commit Mode */
export enum SystemCiCommitMode {
  Full = 'FULL',
  OnlyMigrations = 'ONLY_MIGRATIONS',
  OnlyProject = 'ONLY_PROJECT',
}

/** Ci Status */
export type SystemCiStatusOutput = {
  __typename?: 'SystemCiStatusOutput';
  migrations?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
};

/** CloudLogs Entry */
export type SystemCloudLogsEntry = {
  __typename?: 'SystemCloudLogsEntry';
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** Computed field mode */
export enum SystemComputedFieldMode {
  Stored = 'STORED',
  Virtual = 'VIRTUAL',
}

/** Custom Table Field Type */
export type SystemCustomTableField = {
  __typename?: 'SystemCustomTableField';
  computedMode?: Maybe<SystemComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<SystemFieldType>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Date Field Attributes */
export type SystemDateFieldTypeAttributes = {
  __typename?: 'SystemDateFieldTypeAttributes';
  format: Scalars['String'];
};

/** Date Type Format Enum */
export enum SystemDateTypeFormatEnum {
  Date = 'DATE',
  Datetime = 'DATETIME',
}

export type SystemDeleteMemberIdeaMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
};

export type SystemDeleteMemberIdeaMutationInputList = {
  members: Array<SystemDeleteMemberIdeaMutationInput>;
};

/** DeployDataResponse */
export type SystemDeployDataResponse = {
  __typename?: 'SystemDeployDataResponse';
  buildName: Scalars['String'];
  uploadBuildUrl: Scalars['String'];
  uploadMetaDataUrl: Scalars['String'];
};

export enum SystemDeployModeEnum {
  Full = 'FULL',
  Functions = 'FUNCTIONS',
  Migrations = 'MIGRATIONS',
  OnlyPlugins = 'ONLY_PLUGINS',
  OnlyProject = 'ONLY_PROJECT',
}

/** DeployOptions */
export type SystemDeployOptions = {
  extensionNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  mode?: Maybe<SystemDeployModeEnum>;
  nodeVersion?: Maybe<Scalars['String']>;
  pluginNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum SystemDeployStatusEnum {
  Compiling = 'compiling',
  CompleteError = 'complete_error',
  CompleteSuccess = 'complete_success',
  Deploying = 'deploying',
  Initialize = 'initialize',
  Preparing = 'preparing',
}

/** SystemDeployStatusResult */
export type SystemDeployStatusResult = {
  __typename?: 'SystemDeployStatusResult';
  message?: Maybe<Scalars['String']>;
  status: SystemDeployStatusEnum;
};

/** DeployingBuildInput */
export type SystemDeployingBuildInput = {
  buildName: Scalars['String'];
  options?: Maybe<SystemDeployOptions>;
};

export type SystemDeploymentAbItemResponse = {
  __typename?: 'SystemDeploymentABItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  userDeploy?: Maybe<SystemMemberAccountInfo>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
};

export type SystemDeploymentProjectItemResponse = {
  __typename?: 'SystemDeploymentProjectItemResponse';
  dateDeploy?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  statusDeploy?: Maybe<Scalars['String']>;
  urlDeploy?: Maybe<Scalars['String']>;
  versionDeploy?: Maybe<Scalars['String']>;
  versionFrontEnd?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

/** SystemDeploymentProjectListResponse output */
export type SystemDeploymentProjectListResponse = {
  __typename?: 'SystemDeploymentProjectListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemDeploymentProjectItemResponse>;
};

/** SystemEnvironmentBackupListResponse output */
export type SystemEnvironmentBackupListResponse = {
  __typename?: 'SystemEnvironmentBackupListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentBackupItem>;
};

export type SystemEnvironmentMember = {
  __typename?: 'SystemEnvironmentMember';
  avatar?: Maybe<SystemEnvironmentMemberAvatar>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<SystemEnvironmentMemberPermissionsList>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<SystemEnvironmentMemberRolesList>;
  status: Scalars['String'];
};

export type SystemEnvironmentMemberAvatar = {
  __typename?: 'SystemEnvironmentMemberAvatar';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberFilter = {
  email?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberPermission = {
  __typename?: 'SystemEnvironmentMemberPermission';
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberPermissionsList = {
  __typename?: 'SystemEnvironmentMemberPermissionsList';
  count: Scalars['Int'];
  items?: Maybe<Array<SystemEnvironmentMemberPermission>>;
};

export type SystemEnvironmentMemberRole = {
  __typename?: 'SystemEnvironmentMemberRole';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemEnvironmentMemberRoleIdFilterPredicate = {
  equals?: Maybe<Scalars['ID']>;
  not_equals?: Maybe<Scalars['ID']>;
};

export type SystemEnvironmentMemberRolesList = {
  __typename?: 'SystemEnvironmentMemberRolesList';
  count: Scalars['Int'];
  items?: Maybe<Array<SystemEnvironmentMemberRole>>;
};

export type SystemEnvironmentMemberStatusFilterPredicate = {
  equals?: Maybe<Scalars['String']>;
  not_equals?: Maybe<Scalars['String']>;
};

export type SystemEnvironmentMemberUpdateData = {
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SystemEnvironmentMembersFilter = {
  email: Scalars['String'];
};

export type SystemEnvironmentMembersListFilter = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roleId?: Maybe<SystemEnvironmentMemberRoleIdFilterPredicate>;
  status?: Maybe<SystemEnvironmentMemberStatusFilterPredicate>;
  workspaceId?: Maybe<Scalars['ID']>;
};

/** SystemEnvironmentMembersListResponse output */
export type SystemEnvironmentMembersListResponse = {
  __typename?: 'SystemEnvironmentMembersListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemEnvironmentMember>;
};

export type SystemEnvironmentMembersListSort = {
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  isOwner?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type SystemEnvironmentProjectItem = {
  __typename?: 'SystemEnvironmentProjectItem';
  fields?: Maybe<Scalars['String']>;
  functions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tables?: Maybe<Scalars['String']>;
  worksApiHost?: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
  workspaceName: Scalars['String'];
};

export enum SystemEnvironmentRelationModeEnum {
  ForeignKey = 'ForeignKey',
  Pivot = 'Pivot',
}

export type SystemEnvironmentRoleBaseInfo = {
  __typename?: 'SystemEnvironmentRoleBaseInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SystemEnvironmentRoleList = {
  __typename?: 'SystemEnvironmentRoleList';
  assignedRoles?: Maybe<Array<Maybe<SystemEnvironmentRoleBaseInfo>>>;
  environmentId: Scalars['String'];
  environmentName: Scalars['String'];
  exists?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<SystemEnvironmentRoleBaseInfo>>>;
};

export type SystemEnvironmentSettings = {
  __typename?: 'SystemEnvironmentSettings';
  deleteLock?: Maybe<Scalars['Boolean']>;
  fileManagementProvider?: Maybe<SystemFileManagerProviderTypeEnum>;
  introspection?: Maybe<Scalars['Boolean']>;
  relationMode?: Maybe<SystemEnvironmentRelationModeEnum>;
};

/** SystemEnvironmentsListResponse output */
export type SystemEnvironmentsListResponse = {
  __typename?: 'SystemEnvironmentsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<EnvironmentItem>;
};

/** SystemEnvironmentsProjectListResponse output */
export type SystemEnvironmentsProjectListResponse = {
  __typename?: 'SystemEnvironmentsProjectListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemEnvironmentProjectItem>;
};

/** Field Data Features */
export type SystemFieldDataFeatures = {
  __typename?: 'SystemFieldDataFeatures';
  create: Scalars['Boolean'];
  sort: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field Schema Features */
export type SystemFieldSchemaFeatures = {
  __typename?: 'SystemFieldSchemaFeatures';
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Field types */
export enum SystemFieldType {
  Date = 'DATE',
  File = 'FILE',
  Geo = 'GEO',
  Id = 'ID',
  Json = 'JSON',
  MissingRelation = 'MISSING_RELATION',
  Number = 'NUMBER',
  OneWayRelation = 'ONE_WAY_RELATION',
  Relation = 'RELATION',
  Smart = 'SMART',
  Switch = 'SWITCH',
  Text = 'TEXT',
  Uuid = 'UUID',
}

/** Field Type Attributes */
export type SystemFieldTypeAttributes =
  | SystemDateFieldTypeAttributes
  | SystemFileFieldTypeAttributes
  | SystemGeoFieldTypeAttributes
  | SystemMissingRelationFieldTypeAttributes
  | SystemNumberFieldTypeAttributes
  | SystemSmartFieldTypeAttributes
  | SystemSwitchFieldTypeAttributes
  | SystemTextFieldTypeAttributes
  | SystemUuidFieldTypeAttributes;

/** Field Type Attributes Input */
export type SystemFieldTypeAttributesInput = {
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  fieldSize?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  isBigInt?: Maybe<Scalars['Boolean']>;
  listOptions?: Maybe<Array<Scalars['String']>>;
  maxSize?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

export type SystemFileBaseInfo = {
  __typename?: 'SystemFileBaseInfo';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** File Field Attributes */
export type SystemFileFieldTypeAttributes = {
  __typename?: 'SystemFileFieldTypeAttributes';
  expiration?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
  maxSize?: Maybe<Scalars['Int']>;
  /** @deprecated Field is deprecated */
  showTitle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated */
  showUrl?: Maybe<Scalars['Boolean']>;
  typeRestrictions?: Maybe<Array<Scalars['String']>>;
};

export enum SystemFileManagerProviderTypeEnum {
  Aws = 'aws',
  Filestack = 'filestack',
  Uploadcare = 'uploadcare',
}

/** File Type Format Enum */
export enum SystemFileTypeFormatEnum {
  File = 'FILE',
  Image = 'IMAGE',
}

/** FileUploadByUrlInput */
export type SystemFileUploadByUrlInput = {
  url: Scalars['String'];
};

/** FileUploadByUrlResponse */
export type SystemFileUploadByUrlResponse = {
  __typename?: 'SystemFileUploadByUrlResponse';
  fileId: Scalars['String'];
  meta: Scalars['JSON'];
};

export type SystemFileUploadSignInfo =
  | AwsSignInfoResponse
  | FileStackSignInfoResponse
  | UploadcareSignInfoResponse;

export type SystemFrontendUtilizationAbResponse = {
  __typename?: 'SystemFrontendUtilizationABResponse';
  CDN?: Maybe<Scalars['Boolean']>;
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

export type SystemFrontendUtilizationResponse = {
  __typename?: 'SystemFrontendUtilizationResponse';
  assets?: Maybe<Scalars['Int']>;
  customStates?: Maybe<Scalars['Int']>;
  functions?: Maybe<Scalars['Int']>;
  layouts?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Scalars['Int']>;
  resources?: Maybe<Scalars['Int']>;
};

/** FunctionInfo */
export type SystemFunctionInfo = {
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
};

/** FunctionInfoCheck */
export type SystemFunctionInfoCheck = {
  __typename?: 'SystemFunctionInfoCheck';
  version?: Maybe<Scalars['String']>;
};

/** FunctionInfoFilter */
export type SystemFunctionInfoFilter = {
  description?: Maybe<Scalars['String']>;
  functionType?: Maybe<SystemFunctionType>;
  name?: Maybe<Scalars['String']>;
};

/** FunctionInfoOrderBy */
export enum SystemFunctionInfoOrderBy {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FunctionTypeAsc = 'functionType_ASC',
  FunctionTypeDesc = 'functionType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

/** SystemFunctionListResponse output */
export type SystemFunctionListResponse = {
  __typename?: 'SystemFunctionListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemFunctionInfo>;
};

/** FunctionResolverInfo */
export type SystemFunctionResolverInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionResolverInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  gqlType: Scalars['String'];
  name: Scalars['String'];
};

/** FunctionTaskInfo */
export type SystemFunctionTaskInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTaskInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
  scheduleExpression?: Maybe<Scalars['String']>;
};

/** FunctionTriggerInfo */
export type SystemFunctionTriggerInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionTriggerInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  name: Scalars['String'];
  operation: Scalars['String'];
  tableName: Scalars['String'];
  type: Scalars['String'];
};

/** FunctionType */
export enum SystemFunctionType {
  Resolver = 'resolver',
  Schedule = 'schedule',
  Task = 'task',
  Trigger = 'trigger',
  Webhook = 'webhook',
}

/** FunctionWebhookInfo */
export type SystemFunctionWebhookInfo = SystemFunctionInfo & {
  __typename?: 'SystemFunctionWebhookInfo';
  application?: Maybe<SystemApplication>;
  description?: Maybe<Scalars['String']>;
  functionType: SystemFunctionType;
  httpMethod: Scalars['String'];
  name: Scalars['String'];
  workspaceFullPath: Scalars['String'];
  workspaceRelativePath: Scalars['String'];
};

/** Diff Environment Input */
export type SystemGenerateEnvironmentOutput = {
  __typename?: 'SystemGenerateEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

/** Geo Field Attributes */
export type SystemGeoFieldTypeAttributes = {
  __typename?: 'SystemGeoFieldTypeAttributes';
  format: Scalars['String'];
  srid?: Maybe<Scalars['Int']>;
};

export type SystemGetCouponResponse = {
  __typename?: 'SystemGetCouponResponse';
  amount_off?: Maybe<Scalars['Float']>;
  duration: Scalars['String'];
  duration_in_months: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Float']>;
  valid: Scalars['Boolean'];
};

export type SystemGetInvitationIdeaResponse = {
  __typename?: 'SystemGetInvitationIdeaResponse';
  archieId: Scalars['ID'];
  ideaId: Scalars['ID'];
  invitationId: Scalars['ID'];
  nickName: Scalars['String'];
  role: InvitationRoleEnum;
  status: Scalars['String'];
};

export type SystemGraphQlArchitecturesResponse = {
  __typename?: 'SystemGraphQLArchitecturesResponse';
  enabling_technologies?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  infrastructure_diagram?: Maybe<Scalars['JSON']>;
  integrations?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBlueprintResponse = {
  __typename?: 'SystemGraphQLBlueprintResponse';
  ai_enablement_ideas?: Maybe<Scalars['JSON']>;
  application_classification?: Maybe<Scalars['JSON']>;
  building_blocks?: Maybe<Scalars['JSON']>;
  commercialization_model?: Maybe<Scalars['JSON']>;
  design_principles?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  insights?: Maybe<Scalars['JSON']>;
  modules?: Maybe<Scalars['JSON']>;
  originalPrompt?: Maybe<Scalars['JSON']>;
  problem_statement?: Maybe<Scalars['JSON']>;
  solution_statement?: Maybe<Scalars['JSON']>;
  user_types?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBuildingBlocksRequirementsResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksRequirementsResponse';
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlBuildingBlocksResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksResponse';
  buildingBlocksRequirements?: Maybe<
    Array<Maybe<SystemGraphQlBuildingBlocksRequirementsResponse>>
  >;
  checked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlBuildingBlocksScreensResponse = {
  __typename?: 'SystemGraphQLBuildingBlocksScreensResponse';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  screensWireframes?: Maybe<
    Array<Maybe<SystemGraphQlScreensWireFramesResponse>>
  >;
  status?: Maybe<Scalars['String']>;
  urlPath?: Maybe<Scalars['String']>;
};

export type SystemGraphQlFeaturesBreakdownResponse = {
  __typename?: 'SystemGraphQLFeaturesBreakdownResponse';
  functionality?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  technicalRequirements?: Maybe<Scalars['JSON']>;
  userInterfaces?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlModulesRequirementsResponse = {
  __typename?: 'SystemGraphQLModulesRequirementsResponse';
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stories?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlModulesResponse = {
  __typename?: 'SystemGraphQLModulesResponse';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modulesRequirements?: Maybe<
    Array<Maybe<SystemGraphQlModulesRequirementsResponse>>
  >;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  uiPattern?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlOverviewResponse = {
  __typename?: 'SystemGraphQLOverviewResponse';
  accessibility_requirements?: Maybe<Scalars['JSON']>;
  application_classification?: Maybe<Scalars['JSON']>;
  commercialization_model?: Maybe<Scalars['JSON']>;
  compliance_requirements?: Maybe<Scalars['JSON']>;
  design_principles?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  performance_requirements?: Maybe<Scalars['JSON']>;
  problem_statement?: Maybe<Scalars['JSON']>;
  security_requirements?: Maybe<Scalars['JSON']>;
  solution_statement?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlScreensWireFramesResponse = {
  __typename?: 'SystemGraphQLScreensWireFramesResponse';
  html?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlSectionResultRegenerateMutationInput = {
  archieId: Scalars['ID'];
  idea: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  package: Scalars['String'];
  provisionalData?: Maybe<Scalars['Boolean']>;
  sectionCategoriesWithIds?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlUserAccountsResponse = {
  __typename?: 'SystemGraphQLUserAccountsResponse';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserInterfacesResponse = {
  __typename?: 'SystemGraphQLUserInterfacesResponse';
  requirements_design_guidelines?: Maybe<Scalars['JSON']>;
  requirements_site_map?: Maybe<Scalars['JSON']>;
  requirements_ui_ux_general?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  wireframe_layout?: Maybe<Scalars['JSON']>;
};

export type SystemGraphQlUserTypeDetailsResponse = {
  __typename?: 'SystemGraphQLUserTypeDetailsResponse';
  comprehensive_needs?: Maybe<Scalars['JSON']>;
  comprehensive_painpoints?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserTypeUseCaseResponse = {
  __typename?: 'SystemGraphQLUserTypeUseCaseResponse';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SystemGraphQlUserTypesResponse = {
  __typename?: 'SystemGraphQLUserTypesResponse';
  description?: Maybe<Scalars['String']>;
  deviceusages?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  softwareproficiency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userTypesDetails?: Maybe<Array<Maybe<SystemGraphQlUserTypeDetailsResponse>>>;
  userTypesUseCases?: Maybe<Array<Maybe<SystemGraphQlUserTypeUseCaseResponse>>>;
};

export type SystemIdeaDetailsResponse = {
  __typename?: 'SystemIdeaDetailsResponse';
  archieId: Scalars['ID'];
  architectures?: Maybe<Array<Maybe<SystemGraphQlArchitecturesResponse>>>;
  blueprints?: Maybe<Array<Maybe<SystemGraphQlBlueprintResponse>>>;
  buildingBlocks?: Maybe<Array<Maybe<SystemGraphQlBuildingBlocksResponse>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detailedProgress?: Maybe<Scalars['JSON']>;
  elevatorDescription?: Maybe<Scalars['String']>;
  featuresBreakdown?: Maybe<
    Array<Maybe<SystemGraphQlFeaturesBreakdownResponse>>
  >;
  id: Scalars['ID'];
  invitations?: Maybe<Array<Maybe<SystemIdeaInvitationBaseResponse>>>;
  members?: Maybe<Array<Maybe<SystemIdeaMemberBaseResponse>>>;
  modules?: Maybe<Array<Maybe<SystemGraphQlModulesResponse>>>;
  nextStep: Scalars['String'];
  nickName: Scalars['String'];
  onboardingStatus: Scalars['JSON'];
  overviews?: Maybe<Array<Maybe<SystemGraphQlOverviewResponse>>>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<SystemPlanInfoIdea>;
  progress: Scalars['Float'];
  screens?: Maybe<Array<Maybe<SystemGraphQlBuildingBlocksScreensResponse>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userInterfaces?: Maybe<Array<Maybe<SystemGraphQlUserInterfacesResponse>>>;
  userTypes?: Maybe<Array<Maybe<SystemGraphQlUserTypesResponse>>>;
};

export type SystemIdeaGeneralCreateMutationInput = {
  description: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
};

export type SystemIdeaGeneralResponse = {
  __typename?: 'SystemIdeaGeneralResponse';
  archieId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  nickName: Scalars['String'];
};

export type SystemIdeaGeneralUpdateMutationInput = {
  description?: Maybe<Scalars['String']>;
  elevatorDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickName?: Maybe<Scalars['String']>;
};

export type SystemIdeaGeneralUpdateOnboardingMutationInput = {
  id: Scalars['ID'];
  onboardingStatus: Scalars['JSON'];
};

export type SystemIdeaImproveMutationInput = {
  description?: Maybe<Scalars['String']>;
};

export type SystemIdeaImproveResponse = {
  __typename?: 'SystemIdeaImproveResponse';
  suggestion: Scalars['String'];
};

export type SystemIdeaImproveWithAiMutationInput = {
  aiIdeas?: Maybe<Array<Maybe<SystemAiIdeasInput>>>;
  archieId: Scalars['ID'];
};

export type SystemIdeaImproveWithAiResponse = {
  __typename?: 'SystemIdeaImproveWithAIResponse';
  expectedBenefits?: Maybe<Array<Maybe<Scalars['String']>>>;
  kpis?: Maybe<Array<Maybe<Scalars['String']>>>;
  statement: Scalars['String'];
};

export type SystemIdeaInvitationBaseResponse = {
  __typename?: 'SystemIdeaInvitationBaseResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  role: InvitationRoleEnum;
};

/** Idea Invitation Member */
export type SystemIdeaInvitationMember = {
  __typename?: 'SystemIdeaInvitationMember';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userAccounts?: Maybe<SystemGraphQlUserAccountsResponse>;
};

export type SystemIdeaItemRespSingle = {
  __typename?: 'SystemIdeaItemRespSingle';
  archieId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  detailedProgress?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  members?: Maybe<Array<Maybe<SystemMemberAccountInfo>>>;
  nextStep: Scalars['String'];
  nickName: Scalars['String'];
  owner?: Maybe<SystemMemberAccountInfo>;
  progress: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** SystemIdeaItemResponse output */
export type SystemIdeaItemResponse = {
  __typename?: 'SystemIdeaItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemIdeaItemRespSingle>;
};

export type SystemIdeaMemberBaseResponse = {
  __typename?: 'SystemIdeaMemberBaseResponse';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId: Scalars['ID'];
  role: InvitationRoleEnum;
};

export type SystemIdeaUserResponse = {
  __typename?: 'SystemIdeaUserResponse';
  ideas: SystemIdeaItemResponse;
};

export type SystemInboxEventDetailsUnion =
  | SystemInboxEventEnvironmentInvitationDetails
  | SystemInboxEventNotificationDetailsType
  | SystemInboxEventOrganizationInvitationDetails;

export type SystemInboxEventEnvironmentInvitationDetails = {
  __typename?: 'SystemInboxEventEnvironmentInvitationDetails';
  environmentName?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  project?: Maybe<SystemInboxEventProject>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  uuid?: Maybe<Scalars['String']>;
  workspace?: Maybe<SystemInboxEventWorkspace>;
};

export type SystemInboxEventInvitedBy = {
  __typename?: 'SystemInboxEventInvitedBy';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SystemInboxEventItem = {
  __typename?: 'SystemInboxEventItem';
  createdAt: Scalars['DateTime'];
  details?: Maybe<SystemInboxEventDetailsUnion>;
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SystemInboxEventTypeEnum>;
};

export type SystemInboxEventNotificationDetailsType = {
  __typename?: 'SystemInboxEventNotificationDetailsType';
  details?: Maybe<Scalars['JSON']>;
  kind?: Maybe<Scalars['String']>;
};

export type SystemInboxEventOrganization = {
  __typename?: 'SystemInboxEventOrganization';
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SystemInboxEventOrganizationInvitationDetails = {
  __typename?: 'SystemInboxEventOrganizationInvitationDetails';
  invitedBy?: Maybe<SystemInboxEventInvitedBy>;
  organization?: Maybe<SystemInboxEventOrganization>;
  status?: Maybe<SystemInboxEventStatusEnum>;
  uuid?: Maybe<Scalars['String']>;
};

export type SystemInboxEventProject = {
  __typename?: 'SystemInboxEventProject';
  apiHost?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum SystemInboxEventStatusEnum {
  Accepted = 'accepted',
  Declined = 'declined',
  Sent = 'sent',
}

export enum SystemInboxEventTypeEnum {
  EnvironmentInvitation = 'EnvironmentInvitation',
  Notification = 'Notification',
  OrganizationInvitation = 'OrganizationInvitation',
}

export type SystemInboxEventWorkspace = {
  __typename?: 'SystemInboxEventWorkspace';
  apiHost?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** SystemInboxEventsListResponse output */
export type SystemInboxEventsListResponse = {
  __typename?: 'SystemInboxEventsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemInboxEventItem>;
};

/** Table Create Index Input */
export type SystemIndexCreateInput = {
  columns: Array<SystemTableIndexColumnInput>;
  force?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  tableId: Scalars['ID'];
  type: TableIndexType;
};

/** Table Delete Index Input */
export type SystemIndexDeleteInput = {
  id: Scalars['ID'];
};

/** Table Update Index Input */
export type SystemIndexUpdateInput = {
  columns?: Maybe<Array<SystemTableIndexColumnInput>>;
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TableIndexType>;
};

/** Idea Invitation Accept/Cancel Response */
export type SystemInvitationAcceptCancelResponse = {
  __typename?: 'SystemInvitationAcceptCancelResponse';
  success: Scalars['Boolean'];
};

/** Idea invitation accept/Cancel input */
export type SystemInvitationIdeaAcceptCancelInput = {
  accepted: Scalars['Boolean'];
  id: Scalars['String'];
};

/** Idea Invitation Cancel Response */
export type SystemInvitationIdeaResendResponse = {
  __typename?: 'SystemInvitationIdeaResendResponse';
  success: Scalars['Boolean'];
};

/** Idea invitation cancel input */
export type SystemInvitationIdeaResendlInput = {
  id: Scalars['String'];
};

/** Invite recipient input */
export type SystemInviteIdeaRecipientInput = {
  email: Scalars['String'];
  ideaId: Scalars['String'];
  role: RoleEnum;
};

/** Invite idea input */
export type SystemInviteMembersIdeaInput = {
  recipients: Array<SystemInviteIdeaRecipientInput>;
};

/** Invite members input */
export type SystemInviteMembersInput = {
  recipients: Array<SystemInviteRecipientInput>;
};

/** Invite recipient input */
export type SystemInviteRecipientInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
};

/** Invited By Name */
export type SystemInvitedByName = {
  __typename?: 'SystemInvitedByName';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  workspaceName?: Maybe<Scalars['String']>;
};

/** InvokeData */
export type SystemInvokeData = {
  functionName: Scalars['String'];
  inputArgs?: Maybe<Scalars['String']>;
  nodeVersion?: Maybe<Scalars['String']>;
};

/** InvokeFunctionResponse */
export type SystemInvokeFunctionResponse = {
  __typename?: 'SystemInvokeFunctionResponse';
  responseData: Scalars['String'];
};

/** LogsListFiltered Response */
export type SystemLogsListFilteredResponse = {
  __typename?: 'SystemLogsListFilteredResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemCloudLogsEntry>>>;
  nextToken?: Maybe<Scalars['String']>;
};

/**
 * Member - list of workspaces you are member of
 * Owner - list of workspaces you are owner of
 * OrganizationUser - list of organizations you are part of
 */
export type SystemMemberAccountDeleteDetails = {
  __typename?: 'SystemMemberAccountDeleteDetails';
  member?: Maybe<Array<Maybe<SystemOrganizationWorkspaceItem>>>;
  organizationUser?: Maybe<Array<Maybe<SystemOrganizationBaseItem>>>;
  owner?: Maybe<Array<Maybe<SystemOrganizationWorkspaceItem>>>;
};

export type SystemMemberAccountDeleteResponse = {
  __typename?: 'SystemMemberAccountDeleteResponse';
  details?: Maybe<SystemMemberAccountDeleteDetails>;
  success: Scalars['Boolean'];
};

export type SystemMemberAccountInfo = {
  __typename?: 'SystemMemberAccountInfo';
  aboutMe?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  authCreateMethod?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlFileItemResponse>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  intendedUse?: Maybe<Scalars['String']>;
  isADemoUser?: Maybe<Scalars['Boolean']>;
  isArchieAdmin?: Maybe<Scalars['Boolean']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  projectDescription?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sourceAccount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SystemMemberAccountUpsertDataInput = {
  aboutMe?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  authCreateMethod?: Maybe<Scalars['String']>;
  avatar?: Maybe<GraphQlCreateFileItemInput>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  intendedUse?: Maybe<Scalars['String']>;
  isADemoUser?: Maybe<Scalars['Boolean']>;
  isArchieAdmin?: Maybe<Scalars['Boolean']>;
  isDeveloper?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  learningMode?: Maybe<Scalars['Boolean']>;
  linkedInUsername?: Maybe<Scalars['String']>;
  projectDescription?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sourceAccount?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  type?: Maybe<SystemUserType>;
  website?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Member Invitation */
export type SystemMemberInvitation = {
  __typename?: 'SystemMemberInvitation';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** Member invitation accept input */
export type SystemMemberInvitationAcceptInput = {
  accepted: Scalars['Boolean'];
  id: Scalars['String'];
};

/** Member Invitation Accept Response */
export type SystemMemberInvitationAcceptResponse = {
  __typename?: 'SystemMemberInvitationAcceptResponse';
  success: Scalars['Boolean'];
};

/** Cancel members invitations input */
export type SystemMemberInvitationCancelInput = {
  email: Scalars['String'];
};

/** Resend member invitation input */
export type SystemMemberInvitationResendInput = {
  email: Scalars['ID'];
};

export type SystemMemberInvitationsList = {
  __typename?: 'SystemMemberInvitationsList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemMemberInvitation>>>;
};

/** MemberPaymentDetailsUpdateMutationInput */
export type SystemMemberPaymentDetailsUpdateMutationInput = {
  cardToken?: Maybe<Scalars['String']>;
};

/** Member Resend Verify Account Email Response */
export type SystemMemberResendVerifyAccountEmailResponse = {
  __typename?: 'SystemMemberResendVerifyAccountEmailResponse';
  success: Scalars['Boolean'];
};

/** Member Resend Verify Account Email input */
export type SystemMemberResendVerifyAccountEmaillInput = {
  email: Scalars['String'];
};

export type SystemMembersIdeaList = {
  __typename?: 'SystemMembersIdeaList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemIdeaInvitationMember>>>;
};

/** MissingRelation Field Attributes */
export type SystemMissingRelationFieldTypeAttributes = {
  __typename?: 'SystemMissingRelationFieldTypeAttributes';
  missingTable: Scalars['String'];
};

export type SystemMutation = {
  __typename?: 'SystemMutation';
  applicationDelete?: Maybe<SuccessResponse>;
  applicationInstall?: Maybe<SystemApplication>;
  applicationUpdate?: Maybe<SystemApplication>;
  billingContextCacheEvict?: Maybe<SystemCacheEvictResponse>;
  billingPlanUpdate?: Maybe<SystemBillingCurrentPlanResponse>;
  changePlanIdea?: Maybe<SystemBillingCurrentPlanResponse>;
  changeRoleIdeaInvitation?: Maybe<SuccessResponse>;
  changeRoleIdeaMember?: Maybe<SuccessResponse>;
  ciCommit?: Maybe<AsyncSession>;
  ciInstall?: Maybe<SuccessResponse>;
  deleteMemberIdea?: Maybe<SuccessResponse>;
  deploy?: Maybe<Scalars['Boolean']>;
  environmentBackup?: Maybe<AsyncSession>;
  environmentBranch?: Maybe<AsyncSession>;
  environmentDelete?: Maybe<SuccessResponse>;
  environmentDeleteAsync?: Maybe<AsyncSession>;
  environmentMemberDelete: SuccessResponse;
  environmentMemberUpdate?: Maybe<SystemEnvironmentMember>;
  environmentRestore?: Maybe<AsyncSession>;
  environmentSetup?: Maybe<SuccessResponse>;
  fieldCreate: SystemTableField;
  fieldDelete: SuccessResponse;
  fieldUpdate: SystemTableField;
  fieldUpdatePosition: SuccessResponse;
  fileUploadByUrl: SystemFileUploadByUrlResponse;
  ideaImprove?: Maybe<SystemIdeaImproveResponse>;
  ideaImproveWithAI?: Maybe<SystemIdeaImproveWithAiResponse>;
  ideaOnboardingUpdate?: Maybe<SystemIdeaGeneralResponse>;
  ideaTransferOwner?: Maybe<SuccessResponse>;
  ideaUserCreate?: Maybe<SystemIdeaGeneralResponse>;
  ideaUserUpdate?: Maybe<SystemIdeaGeneralResponse>;
  indexCreate: SystemTableIndex;
  indexDelete?: Maybe<SuccessResponse>;
  indexUpdate: SystemTableIndex;
  invitationIdeaAcceptCancel: SystemInvitationAcceptCancelResponse;
  invitationIdeaResend: SystemInvitationIdeaResendResponse;
  inviteMembers: Array<Maybe<SystemTeamInvitationDetails>>;
  inviteToIdea?: Maybe<SuccessResponse>;
  invoke?: Maybe<SystemInvokeFunctionResponse>;
  memberAccountDelete?: Maybe<SystemMemberAccountDeleteResponse>;
  memberAccountUpsert?: Maybe<SystemMemberAccountInfo>;
  memberInvitationAccept: SystemMemberInvitationAcceptResponse;
  memberInvitationCancel?: Maybe<SuccessResponse>;
  memberInvitationResend?: Maybe<SuccessResponse>;
  memberPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  memberResendVerifyAccountEmail: SystemMemberResendVerifyAccountEmailResponse;
  notificationUpdate?: Maybe<SuccessResponse>;
  organizationInviteUser?: Maybe<OrganizationUserInvitationResponse>;
  organizationInviteUserAccept?: Maybe<SuccessResponse>;
  organizationInviteUserCancel?: Maybe<SuccessResponse>;
  organizationPaymentDetailsUpdate?: Maybe<SystemPaymentDetailsResponse>;
  organizationProjectUserRemove?: Maybe<SuccessResponse>;
  organizationProjectUserShare?: Maybe<SuccessResponse>;
  organizationUpdate?: Maybe<SystemOrganizationItem>;
  organizationUserRemove?: Maybe<SuccessResponse>;
  organizationUserUpdate?: Maybe<SystemOrganizationUserInfo>;
  partnerCreate?: Maybe<SystemPartnerGeneralResponse>;
  postMessageToQueue?: Maybe<SuccessResponse>;
  prepareDeploy: SystemDeployDataResponse;
  projectCreate?: Maybe<AsyncSession>;
  projectDelete?: Maybe<AsyncSession>;
  projectLeave?: Maybe<SuccessResponse>;
  projectUpdate?: Maybe<SystemProjectUpdateResponse>;
  rolePermissionsCacheEvict?: Maybe<SystemCacheEvictResponse>;
  sectionResultCreate?: Maybe<SystemSectionResultResponse>;
  sectionResultGenerate?: Maybe<SuccessResponse>;
  sectionResultPropagate?: Maybe<SuccessResponse>;
  sectionResultRegenerate?: Maybe<SuccessResponse>;
  sectionResultUpdate?: Maybe<SuccessResponse>;
  tableCreate: SystemTable;
  tableDelete: SuccessResponse;
  tableUpdate: SystemTable;
  viewCreate: SystemTable;
  viewUpdate: SystemTable;
  workspaceCreate?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceCreateAsync?: Maybe<SystemWorkspaceCreateResponse>;
  workspaceDelete?: Maybe<SuccessResponse>;
  workspaceLeave?: Maybe<SuccessResponse>;
  workspaceUpdate?: Maybe<SystemWorkspaceUpdateResponse>;
};

export type SystemMutationApplicationDeleteArgs = {
  data: SystemApplicationDeleteMutationInput;
};

export type SystemMutationApplicationInstallArgs = {
  data: SystemApplicationInstallInput;
};

export type SystemMutationApplicationUpdateArgs = {
  data: SystemApplicationUpdateInput;
};

export type SystemMutationBillingContextCacheEvictArgs = {
  keys: Array<Scalars['String']>;
  projectKeys: Array<Scalars['String']>;
};

export type SystemMutationBillingPlanUpdateArgs = {
  data: SystemBillingPlanUpdateMutationInput;
};

export type SystemMutationChangePlanIdeaArgs = {
  data: SystemChangePlanIdeaMutationInput;
};

export type SystemMutationChangeRoleIdeaInvitationArgs = {
  data: SystemChangeRoleIdeaMutationInputList;
};

export type SystemMutationChangeRoleIdeaMemberArgs = {
  data: SystemChangeRoleIdeaMutationInputList;
};

export type SystemMutationCiCommitArgs = {
  build?: Maybe<Scalars['String']>;
  migrationNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  mode?: Maybe<SystemCiCommitMode>;
  nodeVersion?: Maybe<Scalars['String']>;
};

export type SystemMutationDeleteMemberIdeaArgs = {
  data: SystemDeleteMemberIdeaMutationInputList;
};

export type SystemMutationDeployArgs = {
  data?: Maybe<SystemDeployingBuildInput>;
};

export type SystemMutationEnvironmentBackupArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentBranchArgs = {
  force?: Maybe<Scalars['Boolean']>;
  mode?: Maybe<SystemBranchEnvironmentMode>;
  name: Scalars['String'];
};

export type SystemMutationEnvironmentDeleteArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentDeleteAsyncArgs = {
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentMemberDeleteArgs = {
  filter?: Maybe<SystemEnvironmentMembersFilter>;
};

export type SystemMutationEnvironmentMemberUpdateArgs = {
  data?: Maybe<SystemEnvironmentMemberUpdateData>;
  filter?: Maybe<SystemEnvironmentMembersFilter>;
};

export type SystemMutationEnvironmentRestoreArgs = {
  backup: Scalars['String'];
  environmentName: Scalars['String'];
};

export type SystemMutationEnvironmentSetupArgs = {
  data?: Maybe<EnvironmentSetupInput>;
};

export type SystemMutationFieldCreateArgs = {
  data: SystemTableFieldCreateInput;
};

export type SystemMutationFieldDeleteArgs = {
  data: SystemTableFieldDeleteInput;
};

export type SystemMutationFieldUpdateArgs = {
  data: SystemTableFieldUpdateInput;
};

export type SystemMutationFieldUpdatePositionArgs = {
  data: SystemTableFieldPositionUpdateInput;
};

export type SystemMutationFileUploadByUrlArgs = {
  data: SystemFileUploadByUrlInput;
};

export type SystemMutationIdeaImproveArgs = {
  data: SystemIdeaImproveMutationInput;
};

export type SystemMutationIdeaImproveWithAiArgs = {
  data: SystemIdeaImproveWithAiMutationInput;
};

export type SystemMutationIdeaOnboardingUpdateArgs = {
  data: SystemIdeaGeneralUpdateOnboardingMutationInput;
};

export type SystemMutationIdeaTransferOwnerArgs = {
  data: SystemTransferIdeaOwnerMutationInput;
};

export type SystemMutationIdeaUserCreateArgs = {
  data: SystemIdeaGeneralCreateMutationInput;
};

export type SystemMutationIdeaUserUpdateArgs = {
  data: SystemIdeaGeneralUpdateMutationInput;
};

export type SystemMutationIndexCreateArgs = {
  data: SystemIndexCreateInput;
};

export type SystemMutationIndexDeleteArgs = {
  data: SystemIndexDeleteInput;
};

export type SystemMutationIndexUpdateArgs = {
  data: SystemIndexUpdateInput;
};

export type SystemMutationInvitationIdeaAcceptCancelArgs = {
  data: SystemInvitationIdeaAcceptCancelInput;
};

export type SystemMutationInvitationIdeaResendArgs = {
  data: SystemInvitationIdeaResendlInput;
};

export type SystemMutationInviteMembersArgs = {
  data: SystemInviteMembersInput;
  force?: Maybe<Scalars['Boolean']>;
};

export type SystemMutationInviteToIdeaArgs = {
  data: SystemInviteMembersIdeaInput;
};

export type SystemMutationInvokeArgs = {
  data?: Maybe<SystemInvokeData>;
};

export type SystemMutationMemberAccountUpsertArgs = {
  data?: Maybe<SystemMemberAccountUpsertDataInput>;
};

export type SystemMutationMemberInvitationAcceptArgs = {
  data: SystemMemberInvitationAcceptInput;
};

export type SystemMutationMemberInvitationCancelArgs = {
  data: SystemMemberInvitationCancelInput;
};

export type SystemMutationMemberInvitationResendArgs = {
  data: SystemMemberInvitationResendInput;
};

export type SystemMutationMemberPaymentDetailsUpdateArgs = {
  data: SystemMemberPaymentDetailsUpdateMutationInput;
};

export type SystemMutationMemberResendVerifyAccountEmailArgs = {
  data: SystemMemberResendVerifyAccountEmaillInput;
};

export type SystemMutationNotificationUpdateArgs = {
  id: Scalars['String'];
  status: NotificationStatusType;
};

export type SystemMutationOrganizationInviteUserArgs = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  projectRoles?: Maybe<
    Array<Maybe<SystemOrganizationProjectWithEnvironmentRolesInput>>
  >;
  role: Scalars['String'];
};

export type SystemMutationOrganizationInviteUserAcceptArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationInviteUserCancelArgs = {
  invitationId: Scalars['String'];
};

export type SystemMutationOrganizationPaymentDetailsUpdateArgs = {
  data: SystemOrganizationPaymentDetailsUpdateMutationInput;
};

export type SystemMutationOrganizationProjectUserRemoveArgs = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
};

export type SystemMutationOrganizationProjectUserShareArgs = {
  email: Scalars['String'];
  environmentRoles?: Maybe<
    Array<Maybe<SystemOrganizationProjectEnvironmentRolesInput>>
  >;
  projectId: Scalars['ID'];
};

export type SystemMutationOrganizationUpdateArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SystemOrganizationTypeEnum>;
};

export type SystemMutationOrganizationUserRemoveArgs = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
};

export type SystemMutationOrganizationUserUpdateArgs = {
  email: Scalars['String'];
  organizationId: Scalars['ID'];
  role: Scalars['String'];
};

export type SystemMutationPartnerCreateArgs = {
  data: SystemPartnerGeneralCreateMutationInput;
};

export type SystemMutationPostMessageToQueueArgs = {
  data: SystemPostMessageToQueueInput;
};

export type SystemMutationProjectCreateArgs = {
  data: SystemProjectCreateMutationInput;
};

export type SystemMutationProjectDeleteArgs = {
  projectId: Scalars['ID'];
};

export type SystemMutationProjectLeaveArgs = {
  projectId: Scalars['ID'];
};

export type SystemMutationProjectUpdateArgs = {
  data: SystemProjectUpdateMutationInput;
};

export type SystemMutationRolePermissionsCacheEvictArgs = {
  keys: Array<Scalars['String']>;
};

export type SystemMutationSectionResultCreateArgs = {
  data: SystemSectionResultCreateMutationInput;
};

export type SystemMutationSectionResultGenerateArgs = {
  data: SystemSectionResultGenerateMutationInput;
};

export type SystemMutationSectionResultPropagateArgs = {
  data: SystemSectionResultPropagateUpdateMutationInput;
};

export type SystemMutationSectionResultRegenerateArgs = {
  data: SystemGraphQlSectionResultRegenerateMutationInput;
};

export type SystemMutationSectionResultUpdateArgs = {
  data: SystemSectionResultUpdateMutationInput;
};

export type SystemMutationTableCreateArgs = {
  data: SystemTableCreateInput;
};

export type SystemMutationTableDeleteArgs = {
  data: SystemTableDeleteInput;
};

export type SystemMutationTableUpdateArgs = {
  data: SystemTableUpdateInput;
};

export type SystemMutationViewCreateArgs = {
  data: SystemViewCreateInput;
};

export type SystemMutationViewUpdateArgs = {
  data: SystemViewUpdateInput;
};

export type SystemMutationWorkspaceCreateArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceCreateAsyncArgs = {
  data: SystemWorkspaceCreateMutationInput;
};

export type SystemMutationWorkspaceDeleteArgs = {
  data: SystemWorkspaceDeleteMutationInput;
};

export type SystemMutationWorkspaceLeaveArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export type SystemMutationWorkspaceUpdateArgs = {
  data: SystemWorkspaceUpdateMutationInput;
};

/** Number Field Attributes */
export type SystemNumberFieldTypeAttributes = {
  __typename?: 'SystemNumberFieldTypeAttributes';
  autoIncrement?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  isBigInt?: Maybe<Scalars['Boolean']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Int']>;
};

/** Number Type Format Enum */
export enum SystemNumberTypeFormatEnum {
  Currency = 'CURRENCY',
  Fraction = 'FRACTION',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Scientific = 'SCIENTIFIC',
}

export type SystemOrganizationBaseItem = {
  __typename?: 'SystemOrganizationBaseItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  name: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
};

export type SystemOrganizationInvitation = {
  __typename?: 'SystemOrganizationInvitation';
  accepted?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  emailFrom: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  firstNameFrom?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastNameFrom?: Maybe<Scalars['String']>;
  organization: SystemOrganizationBaseItem;
  role: Scalars['String'];
};

export type SystemOrganizationItem = {
  __typename?: 'SystemOrganizationItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemOrganizationItemImage>;
  name: Scalars['String'];
  type?: Maybe<SystemOrganizationTypeEnum>;
  users?: Maybe<Array<Maybe<SystemOrganizationUserInfo>>>;
};

export type SystemOrganizationItemImage = {
  __typename?: 'SystemOrganizationItemImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** OrganizationPaymentDetailsUpdateMutationInput */
export type SystemOrganizationPaymentDetailsUpdateMutationInput = {
  cardToken: Scalars['String'];
  organizationId: Scalars['ID'];
};

/** environmentId to add to with roles (array of ids). */
export type SystemOrganizationProjectEnvironmentRolesInput = {
  environmentId: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SystemOrganizationProjectItem = {
  __typename?: 'SystemOrganizationProjectItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  kind: Scalars['String'];
  name: Scalars['String'];
  organization?: Maybe<SystemOrganizationBaseItem>;
};

export type SystemOrganizationProjectWithEnvironmentRolesInput = {
  environmentRoles?: Maybe<
    Array<Maybe<SystemOrganizationProjectEnvironmentRolesInput>>
  >;
  projectId: Scalars['ID'];
};

export enum SystemOrganizationTypeEnum {
  Agency = 'agency',
  Community = 'community',
  Company = 'company',
  Individual = 'individual',
}

export type SystemOrganizationUserInfo = {
  __typename?: 'SystemOrganizationUserInfo';
  avatar?: Maybe<GraphQlFileItemResponse>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<SystemOrganizationProjectItem>>>;
  role: Scalars['String'];
  status: Scalars['String'];
};

export type SystemOrganizationWorkspaceItem = {
  __typename?: 'SystemOrganizationWorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemFileBaseInfo>;
  kind: Scalars['String'];
  name: Scalars['String'];
  organization?: Maybe<SystemOrganizationBaseItem>;
};

/** SystemOrganizationsListResponse output */
export type SystemOrganizationsListResponse = {
  __typename?: 'SystemOrganizationsListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemOrganizationBaseItem>;
};

export type SystemPartnerGeneralCreateMutationInput = {
  anythingElse?: Maybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SystemPartnerGeneralResponse = {
  __typename?: 'SystemPartnerGeneralResponse';
  AccountId: Scalars['String'];
  anythingElse?: Maybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type SystemPartnerItemRespSingle = {
  __typename?: 'SystemPartnerItemRespSingle';
  AccountId: Scalars['String'];
  anythingElse?: Maybe<Scalars['String']>;
  blueprintUrl: Scalars['String'];
  companyName: Scalars['String'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

/** SystemPartnerItemResponse output */
export type SystemPartnerItemResponse = {
  __typename?: 'SystemPartnerItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemPartnerItemRespSingle>;
};

export type SystemPartnerResponse = {
  __typename?: 'SystemPartnerResponse';
  partners: SystemPartnerItemResponse;
};

export enum SystemPaymentDetailsOrigin {
  Member = 'member',
  Organization = 'organization',
  Workspace = 'workspace',
}

export enum SystemPaymentDetailsOriginProject {
  Member = 'member',
  Organization = 'organization',
  Workspace = 'workspace',
}

export type SystemPaymentDetailsProjectResponse = {
  __typename?: 'SystemPaymentDetailsProjectResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin?: Maybe<SystemPaymentDetailsOriginProject>;
};

export type SystemPaymentDetailsResponse = {
  __typename?: 'SystemPaymentDetailsResponse';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  origin: SystemPaymentDetailsOrigin;
};

/** Diff Environment Input */
export type SystemPlanEnvironmentOutput = {
  __typename?: 'SystemPlanEnvironmentOutput';
  url?: Maybe<Scalars['String']>;
};

export type SystemPlanForIdeaQueryResponse = {
  __typename?: 'SystemPlanForIdeaQueryResponse';
  id?: Maybe<Scalars['ID']>;
};

export type SystemPlanInfoIdea = {
  __typename?: 'SystemPlanInfoIdea';
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  planName?: Maybe<Scalars['String']>;
};

export type SystemPostMessageToQueueInput = {
  message?: Maybe<Scalars['JSON']>;
  routingKey?: Maybe<Scalars['String']>;
};

/** ProjectCreateMutationInput */
export type SystemProjectCreateMutationInput = {
  authType?: Maybe<Scalars['String']>;
  billingPlanId?: Maybe<Scalars['ID']>;
  cardToken?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  howStart?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
  kind?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
};

export type SystemProjectDeploymentsResponse = {
  __typename?: 'SystemProjectDeploymentsResponse';
  deploymentsProject?: Maybe<SystemDeploymentProjectListResponse>;
};

export type SystemProjectFrontendResponse = {
  __typename?: 'SystemProjectFrontendResponse';
  frontendUtilization?: Maybe<SystemFrontendUtilizationResponse>;
  frontendWorkspaces?: Maybe<SystemWorkspaceListResponse>;
};

export type SystemProjectImage = {
  __typename?: 'SystemProjectImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemProjectItemRespSingle = {
  __typename?: 'SystemProjectItemRespSingle';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemProjectImage>;
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  region?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
};

/** SystemProjectItemResponse output */
export type SystemProjectItemResponse = {
  __typename?: 'SystemProjectItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemProjectItemRespSingle>;
};

export type SystemProjectItemWs = {
  __typename?: 'SystemProjectItemWS';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemWorkspaceImage>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type SystemProjectPlanResponse = {
  __typename?: 'SystemProjectPlanResponse';
  overagesPrice?: Maybe<Scalars['Float']>;
  paymentDetail?: Maybe<SystemPaymentDetailsProjectResponse>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
};

export type SystemProjectQuotasResponse = {
  __typename?: 'SystemProjectQuotasResponse';
  metricUsages?: Maybe<SystemBillingMetricUsagesListResponse>;
  metricUsagesQuotas?: Maybe<SystemBillingMetricUsageQuotasListResponse>;
};

/** ProjectUpdateMutationInput */
export type SystemProjectUpdateMutationInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  name?: Maybe<Scalars['String']>;
};

export type SystemProjectUpdateResponse = {
  __typename?: 'SystemProjectUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

export type SystemProjectUserDetailsResponse = {
  __typename?: 'SystemProjectUserDetailsResponse';
  apiHost: Scalars['String'];
  authenticationInfo?: Maybe<Array<Maybe<SystemAuthenticationInfo>>>;
  backendUtilization?: Maybe<SystemBackendUtilizationResponse>;
  backendWorkspaces?: Maybe<SystemWorkspaceListResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  environmentsProject?: Maybe<SystemEnvironmentsProjectListResponse>;
  id: Scalars['ID'];
  image?: Maybe<SystemProjectImage>;
  kind: Scalars['String'];
  lastAccess?: Maybe<Scalars['DateTime']>;
  metricUsages?: Maybe<SystemBillingMetricUsagesListResponse>;
  metricUsagesQuotas?: Maybe<SystemBillingMetricUsageQuotasListResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  overagesPrice?: Maybe<Scalars['Float']>;
  owner?: Maybe<SystemMemberAccountInfo>;
  paymentDetail?: Maybe<SystemPaymentDetailsProjectResponse>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  region?: Maybe<Scalars['String']>;
  teamMemberCount?: Maybe<Scalars['Int']>;
};

export type SystemProjectUserResponse = {
  __typename?: 'SystemProjectUserResponse';
  myProjects: SystemProjectItemResponse;
  sharedProjects: SystemProjectItemResponse;
};

export type SystemPromptItemRespSingle = {
  __typename?: 'SystemPromptItemRespSingle';
  basicSectionConfiguration?: Maybe<Scalars['JSON']>;
  dependsOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  inputVariables: Scalars['JSON'];
  iterableConfig?: Maybe<Scalars['JSON']>;
  modelId: Scalars['ID'];
  modelName: Scalars['String'];
  name: Scalars['String'];
  outputSchema: Scalars['JSON'];
  package: Scalars['String'];
  sectionCategory?: Maybe<Scalars['String']>;
  templateBody: Scalars['String'];
};

/** SystemPromptItemResponse output */
export type SystemPromptItemResponse = {
  __typename?: 'SystemPromptItemResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemPromptItemRespSingle>;
};

export type SystemPromptsResponse = {
  __typename?: 'SystemPromptsResponse';
  prompts: SystemPromptItemResponse;
};

export type SystemQuery = {
  __typename?: 'SystemQuery';
  application?: Maybe<SystemApplication>;
  applicationsList?: Maybe<SystemApplicationListResponse>;
  asyncSessionStatus?: Maybe<SystemAsyncSessionStatusResponse>;
  basicSectionConfigurationForPromptQuery?: Maybe<SystemBasicSectionConfigurationForPromptExecutionResponse>;
  basicSectionConfigurationQuery?: Maybe<SystemBasicSectionConfigurationResponse>;
  billingCurrentPlan?: Maybe<SystemBillingCurrentPlanResponse>;
  /** @deprecated No longer supported. Use `system.memberPaymentDetails, system.organizationPaymentDetails or system.workspacePaymentDetails` instead. */
  billingDetails?: Maybe<SystemBillingDetailsResponse>;
  /** @deprecated No longer supported. Use `system.memberBillingHistory, system.organizationBillingHistory or system.workspaceBillingHistory` instead. */
  billingInvoicesList: SystemBillingInvoicesListResponse;
  billingMetricUsageQuotasList: SystemBillingMetricUsageQuotasListResponse;
  billingMetricUsagesList: SystemBillingMetricUsagesListResponse;
  ciGenerate?: Maybe<SystemGenerateEnvironmentOutput>;
  ciGenerateAsync?: Maybe<AsyncSession>;
  /** @deprecated No longer supported. Use `ciGenerate` instead. */
  ciPlan?: Maybe<SystemPlanEnvironmentOutput>;
  ciStatus?: Maybe<SystemCiStatusOutput>;
  deployStatus: SystemDeployStatusResult;
  environmentBackupsList?: Maybe<SystemEnvironmentBackupListResponse>;
  environmentMember?: Maybe<SystemEnvironmentMember>;
  environmentMembersList?: Maybe<SystemEnvironmentMembersListResponse>;
  environmentSettings?: Maybe<SystemEnvironmentSettings>;
  environmentsList?: Maybe<SystemEnvironmentsListResponse>;
  fileUploadSignInfo?: Maybe<SystemFileUploadSignInfo>;
  functionsList?: Maybe<SystemFunctionListResponse>;
  functionsVersionCheck?: Maybe<SystemFunctionInfoCheck>;
  getCouponQuery?: Maybe<SystemGetCouponResponse>;
  getCurrentPlanForIdeaQuery?: Maybe<SystemPlanForIdeaQueryResponse>;
  getEnvironmentRoles?: Maybe<Array<Maybe<SystemEnvironmentRoleList>>>;
  getInvitationIdeaQuery?: Maybe<SystemGetInvitationIdeaResponse>;
  ideaDetails?: Maybe<SystemIdeaDetailsResponse>;
  ideaMembers?: Maybe<SystemMembersIdeaList>;
  ideaSectionDetails?: Maybe<SystemIdeaDetailsResponse>;
  ideasListQuery?: Maybe<SystemIdeaUserResponse>;
  ideasUserListQuery?: Maybe<SystemIdeaUserResponse>;
  inboxEventsList?: Maybe<SystemInboxEventsListResponse>;
  introspection?: Maybe<IntrospectionQueryResponse>;
  /** @deprecated No longer supported. Use `system.logsList` instead. */
  logs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated No longer supported. Use `system.logsListFiltered` instead. */
  logsList?: Maybe<Array<Maybe<SystemCloudLogsEntry>>>;
  logsListFiltered?: Maybe<SystemLogsListFilteredResponse>;
  memberAccount?: Maybe<SystemMemberAccountInfo>;
  memberAccountFromEmail?: Maybe<SystemMemberAccountInfo>;
  memberBillingHistory: SystemBillingInvoicesListResponse;
  memberInvitation?: Maybe<SystemMemberInvitation>;
  memberInvitationsList?: Maybe<SystemMemberInvitationsList>;
  memberPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  organizationBillingHistory: SystemBillingInvoicesListResponse;
  organizationById?: Maybe<SystemOrganizationItem>;
  organizationInvitationById?: Maybe<SystemOrganizationInvitation>;
  organizationPaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  organizationsListByUser?: Maybe<SystemOrganizationsListResponse>;
  partnerListQuery?: Maybe<SystemPartnerResponse>;
  projectDeployments?: Maybe<SystemProjectDeploymentsResponse>;
  projectFrontend?: Maybe<SystemProjectFrontendResponse>;
  projectInfoDetails?: Maybe<SystemProjectUserDetailsResponse>;
  projectPlan?: Maybe<SystemProjectPlanResponse>;
  projectQuotas?: Maybe<SystemProjectQuotasResponse>;
  projectsUserListQuery?: Maybe<SystemProjectUserResponse>;
  promptListQuery?: Maybe<SystemPromptsResponse>;
  table?: Maybe<SystemTable>;
  tableField?: Maybe<SystemTableField>;
  tablesList: SystemTableListResponse;
  userBillingConfiguration: SystemUserBillingConfigurationResponse;
  userBillingConfigurationArchie: SystemUserBillingConfigurationResponse;
  userInvitationsList?: Maybe<SystemUserInvitationList>;
  viewDryRun?: Maybe<SystemViewDryRunOutput>;
  workspaceName?: Maybe<Scalars['String']>;
  workspacePaymentDetails?: Maybe<SystemPaymentDetailsResponse>;
  workspacesFrontendList?: Maybe<SystemWorkspaceListResponse>;
  workspacesList?: Maybe<SystemWorkspaceListResponse>;
};

export type SystemQueryApplicationArgs = {
  id: Scalars['String'];
};

export type SystemQueryAsyncSessionStatusArgs = {
  sessionId: Scalars['String'];
};

export type SystemQueryBillingCurrentPlanArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};

export type SystemQueryBillingInvoicesListArgs = {
  after?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['ID']>;
  by?: Maybe<SystemBillingInvoicesListFilterType>;
  limit?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type SystemQueryBillingMetricUsageQuotasListArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type SystemQueryBillingMetricUsagesListArgs = {
  filter?: Maybe<SystemBillingMetricUsagesListFilter>;
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type SystemQueryCiGenerateArgs = {
  sourceEnvironmentId?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<Scalars['String']>>;
  targetEnvironmentId?: Maybe<Scalars['String']>;
};

export type SystemQueryCiGenerateAsyncArgs = {
  sourceEnvironmentId?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<Scalars['String']>>;
  targetEnvironmentId?: Maybe<Scalars['String']>;
};

export type SystemQueryCiPlanArgs = {
  sourceEnvironmentId?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<Scalars['String']>>;
  targetEnvironmentId?: Maybe<Scalars['String']>;
};

export type SystemQueryDeployStatusArgs = {
  buildName: Scalars['String'];
};

export type SystemQueryEnvironmentBackupsListArgs = {
  environmentName?: Maybe<Scalars['String']>;
};

export type SystemQueryEnvironmentMemberArgs = {
  filter?: Maybe<SystemEnvironmentMemberFilter>;
};

export type SystemQueryEnvironmentMembersListArgs = {
  environmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  filter?: Maybe<SystemEnvironmentMembersListFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<SystemEnvironmentMembersListSort>>;
};

export type SystemQueryEnvironmentsListArgs = {
  workspaceId?: Maybe<Scalars['String']>;
};

export type SystemQueryFunctionsListArgs = {
  applicationId?: Maybe<Scalars['String']>;
  filter?: Maybe<SystemFunctionInfoFilter>;
  orderBy?: Maybe<Array<Maybe<SystemFunctionInfoOrderBy>>>;
};

export type SystemQueryGetCouponQueryArgs = {
  couponName: Scalars['String'];
};

export type SystemQueryGetEnvironmentRolesArgs = {
  email?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type SystemQueryGetInvitationIdeaQueryArgs = {
  id: Scalars['ID'];
};

export type SystemQueryIdeaDetailsArgs = {
  ideaId: Scalars['ID'];
};

export type SystemQueryIdeaMembersArgs = {
  id: Scalars['String'];
};

export type SystemQueryIdeaSectionDetailsArgs = {
  ideaId: Scalars['ID'];
  packageIdea: Scalars['ID'];
  sectionCategory: Scalars['ID'];
};

export type SystemQueryInboxEventsListArgs = {
  filter?: Maybe<InboxEventsListFilter>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SystemQueryLogsArgs = {
  applicationId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type SystemQueryLogsListArgs = {
  applicationId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  functionName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type SystemQueryLogsListFilteredArgs = {
  endTime?: Maybe<Scalars['DateTime']>;
  filter?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  resource?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  startToken?: Maybe<Scalars['String']>;
};

export type SystemQueryMemberAccountFromEmailArgs = {
  email: Scalars['String'];
};

export type SystemQueryMemberBillingHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  hideOrganizationInvoices?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
};

export type SystemQueryMemberInvitationArgs = {
  id: Scalars['String'];
};

export type SystemQueryOrganizationBillingHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  hideWorkspaceInvoices?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  skip?: Maybe<Scalars['Int']>;
};

export type SystemQueryOrganizationByIdArgs = {
  organizationId: Scalars['String'];
};

export type SystemQueryOrganizationInvitationByIdArgs = {
  invitationId: Scalars['String'];
};

export type SystemQueryOrganizationPaymentDetailsArgs = {
  organizationId: Scalars['ID'];
};

export type SystemQueryProjectDeploymentsArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectFrontendArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectInfoDetailsArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectPlanArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectQuotasArgs = {
  projectId: Scalars['ID'];
};

export type SystemQueryProjectsUserListQueryArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};

export type SystemQueryTableArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SystemQueryTableFieldArgs = {
  id: Scalars['ID'];
};

export type SystemQueryTablesListArgs = {
  filter?: Maybe<SystemTableListFilter>;
};

export type SystemQueryUserBillingConfigurationArgs = {
  filterPlanProjects?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
};

export type SystemQueryUserBillingConfigurationArchieArgs = {
  ideaId?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
};

export type SystemQueryViewDryRunArgs = {
  sql: Scalars['String'];
};

export type SystemQueryWorkspacePaymentDetailsArgs = {
  projectId?: Maybe<Scalars['ID']>;
};

/** Relation */
export type SystemRelation = {
  __typename?: 'SystemRelation';
  refField?: Maybe<SystemTableField>;
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTable: SystemTable;
  relationFieldName?: Maybe<Scalars['String']>;
  relationTableName?: Maybe<Scalars['String']>;
};

/** Relation Create Input */
export type SystemRelationCreateInput = {
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList: Scalars['Boolean'];
  refFieldIsRequired: Scalars['Boolean'];
  refFieldName?: Maybe<Scalars['String']>;
  refTableId: Scalars['ID'];
};

/** Relation Update Input */
export type SystemRelationUpdateInput = {
  refFieldDisplayName?: Maybe<Scalars['String']>;
  refFieldIsList?: Maybe<Scalars['Boolean']>;
  refFieldIsRequired?: Maybe<Scalars['Boolean']>;
  refFieldName?: Maybe<Scalars['String']>;
  refTableId?: Maybe<Scalars['ID']>;
};

/** Schema Origin */
export type SystemSchemaOrigin = {
  __typename?: 'SystemSchemaOrigin';
  provider?: Maybe<Scalars['String']>;
  type: SystemSchemaOriginType;
};

/** Schema Origin Type Enum */
export enum SystemSchemaOriginType {
  Local = 'LOCAL',
  Remote = 'REMOTE',
  View = 'VIEW',
}

export type SystemSectionResultCreateMutationInput = {
  archieId: Scalars['ID'];
  composedPrompt: Scalars['JSON'];
  content: Scalars['JSON'];
  cost?: Maybe<Scalars['Float']>;
  custom?: Maybe<Scalars['Boolean']>;
  executionTime?: Maybe<Scalars['Int']>;
  failureReason?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  isRegenerate?: Maybe<Scalars['Boolean']>;
  lLMApiCalls?: Maybe<Scalars['Int']>;
  modelId: Scalars['ID'];
  package: Scalars['String'];
  prompt: Scalars['ID'];
  provisionalData?: Maybe<Scalars['Boolean']>;
  sectionCategory: Scalars['String'];
  status?: Maybe<SystemStatusIdea>;
  tokensInput?: Maybe<Scalars['Int']>;
  tokensOutput?: Maybe<Scalars['Int']>;
};

export type SystemSectionResultGenerateMutationInput = {
  archieId: Scalars['ID'];
  contextData?: Maybe<Scalars['JSON']>;
  idea: Scalars['ID'];
  iterableID?: Maybe<Scalars['String']>;
  package: Scalars['String'];
  provisionalData?: Maybe<Scalars['Boolean']>;
  sectionCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SystemSectionResultPropagateUpdateMutationInput = {
  archieId: Scalars['ID'];
  content: Scalars['JSON'];
  package: Scalars['String'];
  sectionCategory: Scalars['String'];
};

export type SystemSectionResultResponse = {
  __typename?: 'SystemSectionResultResponse';
  sectionId: Scalars['ID'];
  sectionResultId: Scalars['ID'];
};

export type SystemSectionResultUpdateMutationInput = {
  archieId: Scalars['ID'];
  overviewId?: Maybe<Scalars['String']>;
  package: Scalars['String'];
  sectionCategories?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

/** Smart Field Attributes */
export type SystemSmartFieldTypeAttributes = {
  __typename?: 'SystemSmartFieldTypeAttributes';
  format: Scalars['String'];
  innerFields?: Maybe<Array<Maybe<SystemCustomTableField>>>;
};

/** Smart Type Format Enum */
export enum SystemSmartTypeFormatEnum {
  Address = 'ADDRESS',
  Phone = 'PHONE',
}

/** StatusIdea */
export enum SystemStatusIdea {
  Completed = 'Completed',
  Locked = 'Locked',
  NeedsAttention = 'NeedsAttention',
  Open = 'Open',
}

/** Switch Field Attributes */
export type SystemSwitchFieldTypeAttributes = {
  __typename?: 'SystemSwitchFieldTypeAttributes';
  format: Scalars['String'];
  listOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Switch Type Format Enum */
export enum SystemSwitchTypeFormatEnum {
  ActiveInactive = 'ACTIVE_INACTIVE',
  Custom = 'CUSTOM',
  HighLow = 'HIGH_LOW',
  OnOff = 'ON_OFF',
  TrueFalse = 'TRUE_FALSE',
  YesNo = 'YES_NO',
}

/** Table */
export type SystemTable = {
  __typename?: 'SystemTable';
  application?: Maybe<SystemApplication>;
  attributes?: Maybe<SystemTableAttributes>;
  dataFeatures: SystemTableDataFeatures;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<SystemTableField>>;
  fieldsForIndexing?: Maybe<Array<Maybe<SystemTableField>>>;
  id: Scalars['ID'];
  indexes?: Maybe<Array<SystemTableIndex>>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  origin: SystemSchemaOrigin;
  schemaFeatures: SystemTableSchemaFeatures;
};

/** Table Attributes */
export type SystemTableAttributes = SystemViewAttributes;

/** Table Create Input */
export type SystemTableCreateInput = {
  applicationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Table Data Features */
export type SystemTableDataFeatures = {
  __typename?: 'SystemTableDataFeatures';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Table Delete Input */
export type SystemTableDeleteInput = {
  id: Scalars['ID'];
};

/** TableField */
export type SystemTableField = {
  __typename?: 'SystemTableField';
  computedMode?: Maybe<SystemComputedFieldMode>;
  dataFeatures: SystemFieldDataFeatures;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: SystemFieldType;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributes>;
  id: Scalars['ID'];
  isList: Scalars['Boolean'];
  isMeta: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  origin: SystemSchemaOrigin;
  relation?: Maybe<SystemRelation>;
  schemaFeatures: SystemFieldSchemaFeatures;
  table: SystemTable;
};

/** Table Field Create Input */
export type SystemTableFieldCreateInput = {
  computedMode?: Maybe<SystemComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: SystemFieldType;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributesInput>;
  force?: Maybe<Scalars['Boolean']>;
  initialValue?: Maybe<Scalars['String']>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  relation?: Maybe<SystemRelationCreateInput>;
  tableId: Scalars['ID'];
};

/** Table Field Delete Input */
export type SystemTableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Position Update Input */
export type SystemTableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Field Update Input */
export type SystemTableFieldUpdateInput = {
  computedMode?: Maybe<SystemComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<SystemFieldType>;
  fieldTypeAttributes?: Maybe<SystemFieldTypeAttributesInput>;
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initialValue?: Maybe<Scalars['String']>;
  isList?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  relation?: Maybe<SystemRelationUpdateInput>;
};

/** Table Index */
export type SystemTableIndex = {
  __typename?: 'SystemTableIndex';
  columns?: Maybe<Array<SystemTableIndexColumn>>;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  table: SystemTable;
  type: Scalars['String'];
};

/** Table Index Column */
export type SystemTableIndexColumn = {
  __typename?: 'SystemTableIndexColumn';
  name: Scalars['String'];
};

/** Table Index Column Input */
export type SystemTableIndexColumnInput = {
  name: Scalars['String'];
};

/** Table List Application Filter */
export type SystemTableListApplicationFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Table List Filter */
export type SystemTableListFilter = {
  applications?: Maybe<Array<Maybe<SystemTableListApplicationFilter>>>;
  onlyUserTables?: Maybe<Scalars['Boolean']>;
  tableNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Table List Response */
export type SystemTableListResponse = {
  __typename?: 'SystemTableListResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemTable>>>;
};

/** Table Schema Create Features */
export type SystemTableSchemaCreateFeatures = {
  __typename?: 'SystemTableSchemaCreateFeatures';
  DATE: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
};

/** Table Schema Features */
export type SystemTableSchemaFeatures = {
  __typename?: 'SystemTableSchemaFeatures';
  computedFields: Scalars['Boolean'];
  create: SystemTableSchemaCreateFeatures;
  update?: Maybe<SystemTableSchemaMetaFieldFeatures>;
};

/** Table Schema Meta Field Features */
export type SystemTableSchemaMetaFieldFeatures = {
  __typename?: 'SystemTableSchemaMetaFieldFeatures';
  displayName: Scalars['Boolean'];
  name: Scalars['Boolean'];
};

/** Table Update Input */
export type SystemTableUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Team Invitation Details */
export type SystemTeamInvitationDetails = {
  __typename?: 'SystemTeamInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** Text Field Attributes */
export type SystemTextFieldTypeAttributes = {
  __typename?: 'SystemTextFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
};

/** Text Type Format Enum */
export enum SystemTextTypeFormatEnum {
  Ein = 'EIN',
  Email = 'EMAIL',
  Html = 'HTML',
  Markdown = 'MARKDOWN',
  Name = 'NAME',
  Unformatted = 'UNFORMATTED',
}

export type SystemTransferIdeaOwnerMutationInput = {
  email: Scalars['String'];
  ideaId: Scalars['ID'];
};

/** UUID Field Attributes */
export type SystemUuidFieldTypeAttributes = {
  __typename?: 'SystemUUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

export type SystemUserBillingConfigurationResponse = {
  __typename?: 'SystemUserBillingConfigurationResponse';
  availablePlans: Array<SystemBillingPlanBaseInfo>;
  /** @deprecated Flag is deprecated */
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  /** @deprecated Flag is deprecated */
  isFreePlanAvailable: Scalars['Boolean'];
};

/** User Invitation Details */
export type SystemUserInvitationDetails = {
  __typename?: 'SystemUserInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<SystemInvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** User Invitation List */
export type SystemUserInvitationList = {
  __typename?: 'SystemUserInvitationList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<SystemUserInvitationDetails>>>;
};

/** User Type */
export enum SystemUserType {
  Agency = 'Agency',
  Corporation = 'Corporation',
  MyOwn = 'MyOwn',
  Team = 'Team',
}

/** View Attributes */
export type SystemViewAttributes = {
  __typename?: 'SystemViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type SystemViewCreateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  query: Scalars['String'];
};

/** Dry Run Response for previewing SQL Views */
export type SystemViewDryRunOutput = {
  __typename?: 'SystemViewDryRunOutput';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Scalars['JSON']>;
  timeMs?: Maybe<Scalars['Int']>;
};

/** View Update Input */
export type SystemViewUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

/** WorkspaceCreateMutationInput */
export type SystemWorkspaceCreateMutationInput = {
  authType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  howStart?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
  kind?: Maybe<SystemWorkspaceKind>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type SystemWorkspaceCreateResponse = {
  __typename?: 'SystemWorkspaceCreateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** WorkspaceDeleteMutationInput */
export type SystemWorkspaceDeleteMutationInput = {
  workspaceId: Scalars['ID'];
};

export type SystemWorkspaceImage = {
  __typename?: 'SystemWorkspaceImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SystemWorkspaceItem = {
  __typename?: 'SystemWorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<SystemWorkspaceImage>;
  is8BaseAuthEnabled?: Maybe<Scalars['Boolean']>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  isOwner: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  lastDeployABInfo?: Maybe<SystemDeploymentAbItemResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<SystemBillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<SystemBillingCurrentPlanResponse>;
  project?: Maybe<SystemProjectItemWs>;
  region?: Maybe<Scalars['String']>;
  summaryABInfo?: Maybe<SystemFrontendUtilizationAbResponse>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  webSocket?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export enum SystemWorkspaceKind {
  Frontend = 'frontend',
  General = 'general',
}

/** SystemWorkspaceListResponse output */
export type SystemWorkspaceListResponse = {
  __typename?: 'SystemWorkspaceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<SystemWorkspaceItem>;
};

export enum SystemWorkspaceStatus {
  Active = 'active',
  Blocked = 'blocked',
  Canceled = 'canceled',
  Canceling = 'canceling',
  Pending = 'pending',
  Suspended = 'suspended',
}

/** WorkspaceUpdateMutationInput */
export type SystemWorkspaceUpdateMutationInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  name?: Maybe<Scalars['String']>;
};

export type SystemWorkspaceUpdateResponse = {
  __typename?: 'SystemWorkspaceUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

/** Table */
export type Table = {
  __typename?: 'Table';
  application?: Maybe<Application>;
  attributes?: Maybe<TableAttributes>;
  dataFeatures: TableDataFeatures;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<TableField>>;
  fieldsForIndexing?: Maybe<Array<Maybe<TableField>>>;
  id: Scalars['ID'];
  indexes?: Maybe<Array<TableIndex>>;
  isSystem: Scalars['Boolean'];
  name: Scalars['String'];
  origin: SchemaOrigin;
  schemaFeatures: TableSchemaFeatures;
};

/** Table Attributes */
export type TableAttributes = ViewAttributes;

/** Table Create Input */
export type TableCreateInput = {
  applicationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Table Data Features */
export type TableDataFeatures = {
  __typename?: 'TableDataFeatures';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Table Delete Input */
export type TableDeleteInput = {
  id: Scalars['ID'];
};

/** TableField */
export type TableField = {
  __typename?: 'TableField';
  computedMode?: Maybe<ComputedFieldMode>;
  dataFeatures: FieldDataFeatures;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  fieldTypeAttributes?: Maybe<FieldTypeAttributes>;
  id: Scalars['ID'];
  isList: Scalars['Boolean'];
  isMeta: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isSystem: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  origin: SchemaOrigin;
  relation?: Maybe<Relation>;
  schemaFeatures: FieldSchemaFeatures;
  table: Table;
};

/** Table Field Create Input */
export type TableFieldCreateInput = {
  computedMode?: Maybe<ComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  fieldTypeAttributes?: Maybe<FieldTypeAttributesInput>;
  force?: Maybe<Scalars['Boolean']>;
  initialValue?: Maybe<Scalars['String']>;
  isList: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isUnique?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  relation?: Maybe<RelationCreateInput>;
  tableId: Scalars['ID'];
};

/** Table Field Delete Input */
export type TableFieldDeleteInput = {
  id: Scalars['ID'];
};

/** Table Field Position Update Input */
export type TableFieldPositionUpdateInput = {
  id: Scalars['ID'];
  newPosition: Scalars['Int'];
};

/** Table Field Update Input */
export type TableFieldUpdateInput = {
  computedMode?: Maybe<ComputedFieldMode>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  expression?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldType>;
  fieldTypeAttributes?: Maybe<FieldTypeAttributesInput>;
  force?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initialValue?: Maybe<Scalars['String']>;
  isList?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  relation?: Maybe<RelationUpdateInput>;
};

/** Table Index */
export type TableIndex = {
  __typename?: 'TableIndex';
  columns?: Maybe<Array<TableIndexColumn>>;
  id: Scalars['ID'];
  isSystem: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  table: Table;
  type: Scalars['String'];
};

/** Table Index Column */
export type TableIndexColumn = {
  __typename?: 'TableIndexColumn';
  name: Scalars['String'];
};

/** Table Index Column Input */
export type TableIndexColumnInput = {
  name: Scalars['String'];
};

export enum TableIndexType {
  Index = 'INDEX',
  Unique = 'UNIQUE',
}

/** Table List Application Filter */
export type TableListApplicationFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Table List Filter */
export type TableListFilter = {
  applications?: Maybe<Array<Maybe<TableListApplicationFilter>>>;
  onlyUserTables?: Maybe<Scalars['Boolean']>;
  tableNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Table List Response */
export type TableListResponse = {
  __typename?: 'TableListResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Table>>>;
};

/** Table Schema Create Features */
export type TableSchemaCreateFeatures = {
  __typename?: 'TableSchemaCreateFeatures';
  DATE: Scalars['Boolean'];
  FILE: Scalars['Boolean'];
  GEO: Scalars['Boolean'];
  ID: Scalars['Boolean'];
  JSON: Scalars['Boolean'];
  MISSING_RELATION: Scalars['Boolean'];
  NUMBER: Scalars['Boolean'];
  ONE_WAY_RELATION: Scalars['Boolean'];
  RELATION: Scalars['Boolean'];
  SMART: Scalars['Boolean'];
  SWITCH: Scalars['Boolean'];
  TEXT: Scalars['Boolean'];
  UUID: Scalars['Boolean'];
};

/** Table Schema Features */
export type TableSchemaFeatures = {
  __typename?: 'TableSchemaFeatures';
  computedFields: Scalars['Boolean'];
  create: TableSchemaCreateFeatures;
  update?: Maybe<TableSchemaMetaFieldFeatures>;
};

/** Table Schema Meta Field Features */
export type TableSchemaMetaFieldFeatures = {
  __typename?: 'TableSchemaMetaFieldFeatures';
  displayName: Scalars['Boolean'];
  name: Scalars['Boolean'];
};

/** Table Update Input */
export type TableUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Text Field Attributes */
export type TextFieldTypeAttributes = {
  __typename?: 'TextFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
  format: Scalars['String'];
};

/** Text Type Format Enum */
export enum TextTypeFormatEnum {
  Ein = 'EIN',
  Email = 'EMAIL',
  Html = 'HTML',
  Markdown = 'MARKDOWN',
  Name = 'NAME',
  Unformatted = 'UNFORMATTED',
}

export type TrimFunctionArguments = {
  mode?: Maybe<StringTrimMode>;
  str: Scalars['String'];
};

/** UUID Field Attributes */
export type UuidFieldTypeAttributes = {
  __typename?: 'UUIDFieldTypeAttributes';
  fieldSize?: Maybe<Scalars['Int']>;
};

export type UpdateByFilterBooleanSwitchInput = {
  invert?: Maybe<Scalars['Boolean']>;
  set?: Maybe<Scalars['Boolean']>;
};

export type UpdateByFilterDateInput = {
  add?: Maybe<UpdateByFilterDatePartsInput>;
  set?: Maybe<Scalars['String']>;
  sub?: Maybe<UpdateByFilterDatePartsInput>;
};

export type UpdateByFilterDatePartsInput = {
  days?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['Int']>;
};

export type UpdateByFilterDateTimeInput = {
  add?: Maybe<UpdateByFilterDateTimePartsInput>;
  set?: Maybe<Scalars['String']>;
  sub?: Maybe<UpdateByFilterDateTimePartsInput>;
};

export type UpdateByFilterDateTimePartsInput = {
  days?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
  microseconds?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  seconds?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['Int']>;
};

export type UpdateByFilterFloatInput = {
  add?: Maybe<Scalars['Float']>;
  div?: Maybe<Scalars['Float']>;
  mod?: Maybe<Scalars['Float']>;
  mult?: Maybe<Scalars['Float']>;
  pow?: Maybe<Scalars['Float']>;
  prec?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Float']>;
  sqrt?: Maybe<Scalars['Boolean']>;
  sub?: Maybe<Scalars['Float']>;
};

export type UpdateByFilterIntInput = {
  add?: Maybe<Scalars['Int']>;
  div?: Maybe<Scalars['Int']>;
  mod?: Maybe<Scalars['Int']>;
  mult?: Maybe<Scalars['Int']>;
  pow?: Maybe<Scalars['Int']>;
  prec?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['Int']>;
  sqrt?: Maybe<Scalars['Boolean']>;
  sub?: Maybe<Scalars['Int']>;
};

export type UpdateByFilterJsonInput = {
  set?: Maybe<Scalars['JSON']>;
};

export type UpdateByFilterListStringInput = {
  insert?: Maybe<UpdateByFilterListStringInsertOperationInput>;
  push?: Maybe<Array<Maybe<Scalars['String']>>>;
  remove?: Maybe<Array<Maybe<Scalars['Int']>>>;
  removeValue?: Maybe<Scalars['String']>;
  set?: Maybe<Array<Maybe<Scalars['String']>>>;
  swap?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unshift?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateByFilterListStringInsertOperationInput = {
  start: Scalars['Int'];
  values: Array<Scalars['String']>;
};

export type UpdateByFilterStringInput = {
  postfix?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  set?: Maybe<Scalars['String']>;
};

export type UpdateByFilterStringSwitchInput = {
  set?: Maybe<Scalars['String']>;
};

/** UpdatedFieldsFilter */
export type UpdatedFieldsFilter = {
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  every?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadcareSignInfoResponse = {
  __typename?: 'UploadcareSignInfoResponse';
  expire: Scalars['String'];
  publicKey: Scalars['String'];
  signature: Scalars['String'];
};

export type UsState = {
  __typename?: 'UsState';
  _description?: Maybe<Scalars['String']>;
  addressRelation?: Maybe<AddressListResponse>;
  caseStateRelation?: Maybe<CaseListResponse>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userRelation?: Maybe<UserListResponse>;
};

export type UsStateAddressRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<AddressGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<AddressOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AddressSort>>;
};

export type UsStateCaseStateRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseSort>>;
};

export type UsStateUserRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<UserGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<UserOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<UserSort>>;
};

/** UsState relation input */
export type UsStateAddressRelationManyRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
};

/** UsState relation input */
export type UsStateAddressRelationRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
  create?: Maybe<Array<Maybe<State_AddressCreateInput>>>;
};

/** UsState relation input */
export type UsStateAddressRelationUpdateRelationInput = {
  connect?: Maybe<Array<AddressKeyFilter>>;
  create?: Maybe<Array<Maybe<State_AddressCreateInput>>>;
  disconnect?: Maybe<Array<AddressKeyFilter>>;
  reconnect?: Maybe<Array<AddressKeyFilter>>;
  update?: Maybe<Array<Maybe<State_AddressUpdateInput>>>;
};

/** UsState relation input */
export type UsStateCaseStateRelationManyRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
};

/** UsState relation input */
export type UsStateCaseStateRelationRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<InspectionCitationState_CaseCreateInput>>>;
};

/** UsState relation input */
export type UsStateCaseStateRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseKeyFilter>>;
  create?: Maybe<Array<Maybe<InspectionCitationState_CaseCreateInput>>>;
  disconnect?: Maybe<Array<CaseKeyFilter>>;
  reconnect?: Maybe<Array<CaseKeyFilter>>;
  update?: Maybe<Array<Maybe<InspectionCitationState_CaseUpdateInput>>>;
};

/** UsState create input */
export type UsStateCreateInput = {
  addressRelation?: Maybe<UsStateAddressRelationRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationRelationInput>;
};

/** UsState create many input */
export type UsStateCreateManyInput = {
  addressRelation?: Maybe<UsStateAddressRelationManyRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationManyRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationManyRelationInput>;
};

/** UsState delete input */
export type UsStateDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** UsStateFieldsPermissions create input */
export type UsStateFieldsPermissions = {
  code?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  idState?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type UsStateFilter = {
  AND?: Maybe<Array<UsStateFilter>>;
  OR?: Maybe<Array<UsStateFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  addressRelation?: Maybe<AddressRelationFilter>;
  caseStateRelation?: Maybe<CaseRelationFilter>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  idState?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userRelation?: Maybe<UserRelationFilter>;
};

export type UsStateGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: UsStateGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type UsStateGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  addressRelation?: Maybe<AddressGroupByQuery>;
  caseStateRelation?: Maybe<CaseGroupByQuery>;
  code?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  id?: Maybe<Array<GroupByField>>;
  idState?: Maybe<Array<GroupByField>>;
  name?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  userRelation?: Maybe<UserGroupByQuery>;
};

export type UsStateKeyFilter = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** UsStateListResponse output */
export type UsStateListResponse = {
  __typename?: 'UsStateListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<UsState>;
};

/** UsStateManyResponse output */
export type UsStateManyResponse = {
  __typename?: 'UsStateManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<UsState>;
};

/** No longer supported. Use `sort` instead. */
export enum UsStateOrderBy {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  IdStateAsc = 'idState_ASC',
  IdStateDesc = 'idState_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** UsState subscription payload */
export type UsStatePayload = {
  __typename?: 'UsStatePayload';
  mutation: MutationType;
  node?: Maybe<UsState>;
  previousValues?: Maybe<UsState>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UsStateSort = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  idState?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** UsState subscription filter */
export type UsStateSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<UsStateFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** UsState update input */
export type UsStateUpdateByFilterInput = {
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  idState?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  name?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** UsState update input */
export type UsStateUpdateInput = {
  addressRelation?: Maybe<UsStateAddressRelationUpdateRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationUpdateRelationInput>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationUpdateRelationInput>;
};

/** UsState relation input */
export type UsStateUserRelationManyRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
};

/** UsState relation input */
export type UsStateUserRelationRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<State_UserCreateInput>>>;
};

/** UsState relation input */
export type UsStateUserRelationUpdateRelationInput = {
  connect?: Maybe<Array<UserKeyFilter>>;
  create?: Maybe<Array<Maybe<State_UserCreateInput>>>;
  disconnect?: Maybe<Array<UserKeyFilter>>;
  reconnect?: Maybe<Array<UserKeyFilter>>;
  update?: Maybe<Array<Maybe<State_UserUpdateInput>>>;
};

export type UsState_PermissionFilter = {
  AND?: Maybe<Array<UsState_PermissionFilter>>;
  OR?: Maybe<Array<UsState_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  addressRelation?: Maybe<Address_PermissionRelationFilter>;
  caseStateRelation?: Maybe<Case_PermissionRelationFilter>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  id?: Maybe<IdPredicate>;
  idState?: Maybe<StringPredicate>;
  name?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userRelation?: Maybe<User_PermissionRelationFilter>;
};

export type User = {
  __typename?: 'User';
  _description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<File>;
  caseHistoryRelation?: Maybe<CaseHistoryListResponse>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  is8base?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<NotificationListResponse>;
  notificationSetting?: Maybe<NotificationSetting>;
  origin?: Maybe<Scalars['String']>;
  permissions?: Maybe<UserPermissionList>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<RoleListResponse>;
  state?: Maybe<UsState>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCaseCommentReplyRelation?: Maybe<CaseCommentReplyListResponse>;
  userCaseFollowerRelation?: Maybe<CaseFollowerListResponse>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserCaseHistoryRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseHistoryGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseHistoryOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseHistorySort>>;
};

export type UserNotificationRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<NotificationGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<NotificationOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<NotificationSort>>;
};

export type UserPermissionsArgs = {
  filter?: Maybe<PermissionInputFilter>;
};

export type UserRolesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RoleFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<RoleGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<RoleOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<RoleSort>>;
};

export type UserUserCaseCommentReplyRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseCommentReplyFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseCommentReplyGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseCommentReplyOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseCommentReplySort>>;
};

export type UserUserCaseFollowerRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseFollowerFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseFollowerGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseFollowerOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseFollowerSort>>;
};

export type UserBillingConfigurationResponse = {
  __typename?: 'UserBillingConfigurationResponse';
  availablePlans: Array<BillingPlanBaseInfo>;
  /** @deprecated Flag is deprecated */
  isCancelSubscriptionAvailable: Scalars['Boolean'];
  /** @deprecated Flag is deprecated */
  isFreePlanAvailable: Scalars['Boolean'];
};

/** Users create input from userCaseCommentReplyRelation */
export type UserCaseCommentReplyRelation_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from userCaseCommentReplyRelation */
export type UserCaseCommentReplyRelation_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users create input from userCaseFollowerRelation */
export type UserCaseFollowerRelation_UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users update input from userCaseFollowerRelation */
export type UserCaseFollowerRelation_UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users create input */
export type UserCreateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesRelationInput>;
  state?: Maybe<UsersStateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users create many input */
export type UserCreateManyInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarManyRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationManyRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerManyRelationInput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationManyRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingManyRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesManyRelationInput>;
  state?: Maybe<UsersStateManyRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationManyRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationManyRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Users delete input */
export type UserDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** UserFieldsPermissions create input */
export type UserFieldsPermissions = {
  address?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['Boolean']>;
  is8base?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['Boolean']>;
  origin?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['Boolean']>;
};

export type UserFilter = {
  AND?: Maybe<Array<UserFilter>>;
  OR?: Maybe<Array<UserFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  address?: Maybe<StringPredicate>;
  avatar?: Maybe<FileFilter>;
  caseHistoryRelation?: Maybe<CaseHistoryRelationFilter>;
  city?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  customer?: Maybe<CustomerFilter>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  firstName?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  is8base?: Maybe<BoolPredicate>;
  is_self?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<StringPredicate>;
  not_self?: Maybe<Scalars['Boolean']>;
  notificationRelation?: Maybe<NotificationRelationFilter>;
  notificationSetting?: Maybe<NotificationSettingFilter>;
  origin?: Maybe<StringPredicate>;
  phoneNumber?: Maybe<StringPredicate>;
  roles?: Maybe<RoleRelationFilter>;
  state?: Maybe<UsStateFilter>;
  status?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userCaseCommentReplyRelation?: Maybe<CaseCommentReplyRelationFilter>;
  userCaseFollowerRelation?: Maybe<CaseFollowerRelationFilter>;
  zipCode?: Maybe<StringPredicate>;
};

export type UserGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: UserGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type UserGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  address?: Maybe<Array<GroupByField>>;
  avatar?: Maybe<FileGroupByQuery>;
  caseHistoryRelation?: Maybe<CaseHistoryGroupByQuery>;
  city?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  customer?: Maybe<CustomerGroupByQuery>;
  email?: Maybe<Array<GroupByField>>;
  firstName?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  is8base?: Maybe<Array<GroupByField>>;
  lastName?: Maybe<Array<GroupByField>>;
  notificationRelation?: Maybe<NotificationGroupByQuery>;
  notificationSetting?: Maybe<NotificationSettingGroupByQuery>;
  origin?: Maybe<Array<GroupByField>>;
  phoneNumber?: Maybe<Array<GroupByField>>;
  roles?: Maybe<RoleGroupByQuery>;
  state?: Maybe<UsStateGroupByQuery>;
  status?: Maybe<Array<GroupByField>>;
  timezone?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  userCaseCommentReplyRelation?: Maybe<CaseCommentReplyGroupByQuery>;
  userCaseFollowerRelation?: Maybe<CaseFollowerGroupByQuery>;
  zipCode?: Maybe<Array<GroupByField>>;
};

/** User Invitation Details */
export type UserInvitationDetails = {
  __typename?: 'UserInvitationDetails';
  accepted?: Maybe<Scalars['Boolean']>;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  apiHost?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<InvitedByName>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  resentOn?: Maybe<Scalars['DateTime']>;
};

/** User Invitation List */
export type UserInvitationList = {
  __typename?: 'UserInvitationList';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserInvitationDetails>>>;
};

export type UserKeyFilter = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** UserListResponse output */
export type UserListResponse = {
  __typename?: 'UserListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<User>;
};

/** UserLoginInput */
export type UserLoginInput = {
  authProfileId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  fromInvitation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

/** UserManyResponse output */
export type UserManyResponse = {
  __typename?: 'UserManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<User>;
};

/** No longer supported. Use `sort` instead. */
export enum UserOrderBy {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  Is8baseAsc = 'is8base_ASC',
  Is8baseDesc = 'is8base_DESC',
  IsOwnerAsc = 'isOwner_ASC',
  IsOwnerDesc = 'isOwner_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  OriginAsc = 'origin_ASC',
  OriginDesc = 'origin_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ZipCodeAsc = 'zipCode_ASC',
  ZipCodeDesc = 'zipCode_DESC',
}

/** Users subscription payload */
export type UserPayload = {
  __typename?: 'UserPayload';
  mutation: MutationType;
  node?: Maybe<User>;
  previousValues?: Maybe<User>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** User Permission */
export type UserPermission = {
  __typename?: 'UserPermission';
  permission?: Maybe<Scalars['JSON']>;
  resource?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

/** User Permission List */
export type UserPermissionList = {
  __typename?: 'UserPermissionList';
  count: Scalars['Int'];
  items?: Maybe<Array<UserPermission>>;
};

export type UserRelationFilter = {
  every?: Maybe<UserFilter>;
  none?: Maybe<UserFilter>;
  some?: Maybe<UserFilter>;
};

/** CaseHistory create input from userRelation */
export type UserRelation_CaseHistoryCreateInput = {
  caseRelation?: Maybe<CaseHistoryCaseRelationRelationInput>;
  message?: Maybe<Scalars['String']>;
  newData?: Maybe<Scalars['JSON']>;
  olderData?: Maybe<Scalars['JSON']>;
  userRelation?: Maybe<CaseHistoryUserRelationRelationInput>;
};

/** CaseHistory update input from userRelation */
export type UserRelation_CaseHistoryUpdateInput = {
  data: CaseHistoryUpdateInput;
  filter?: Maybe<CaseHistoryKeyFilter>;
};

/** UsState create input from userRelation */
export type UserRelation_UsStateCreateInput = {
  addressRelation?: Maybe<UsStateAddressRelationRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationRelationInput>;
};

/** UsState update input from userRelation */
export type UserRelation_UsStateUpdateInput = {
  addressRelation?: Maybe<UsStateAddressRelationUpdateRelationInput>;
  caseStateRelation?: Maybe<UsStateCaseStateRelationUpdateRelationInput>;
  code?: Maybe<Scalars['String']>;
  idState?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userRelation?: Maybe<UsStateUserRelationUpdateRelationInput>;
};

export type UserSort = {
  address?: Maybe<SortOrder>;
  avatar?: Maybe<FileSort>;
  city?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  customer?: Maybe<CustomerSort>;
  deletedAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  is8base?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  notificationSetting?: Maybe<NotificationSettingSort>;
  origin?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  state?: Maybe<UsStateSort>;
  status?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zipCode?: Maybe<SortOrder>;
};

/** Users subscription filter */
export type UserSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<UserFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** Users update input */
export type UserUpdateByFilterInput = {
  address?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  city?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  email?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  firstName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  is8base?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  isOwner?: Maybe<Array<Maybe<UpdateByFilterBooleanSwitchInput>>>;
  lastName?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  origin?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  phoneNumber?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  status?: Maybe<Array<Maybe<UpdateByFilterStringSwitchInput>>>;
  timezone?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  zipCode?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
};

/** Users update input */
export type UserUpdateInput = {
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<UsersAvatarUpdateRelationInput>;
  caseHistoryRelation?: Maybe<UsersCaseHistoryRelationUpdateRelationInput>;
  city?: Maybe<Scalars['String']>;
  customer?: Maybe<UsersCustomerUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  notificationRelation?: Maybe<UsersNotificationRelationUpdateRelationInput>;
  notificationSetting?: Maybe<UsersNotificationSettingUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles?: Maybe<UsersRolesUpdateRelationInput>;
  state?: Maybe<UsersStateUpdateRelationInput>;
  status?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userCaseCommentReplyRelation?: Maybe<UsersUserCaseCommentReplyRelationUpdateRelationInput>;
  userCaseFollowerRelation?: Maybe<UsersUserCaseFollowerRelationUpdateRelationInput>;
  zipCode?: Maybe<Scalars['String']>;
};

/** CaseCommentReply create input from user */
export type User_CaseCommentReplyCreateInput = {
  caseCommentCaseCommentReplyRelation: CaseCommentReplyCaseCommentCaseCommentReplyRelationRelationInput;
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<CaseCommentReplyUserRelationInput>;
};

/** CaseCommentReply update input from user */
export type User_CaseCommentReplyUpdateInput = {
  data: CaseCommentReplyUpdateInput;
  filter?: Maybe<CaseCommentReplyKeyFilter>;
};

/** Customer create input from user */
export type User_CustomerCreateInput = {
  address?: Maybe<CustomerAddressRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserRelationInput>;
};

/** Customer update input from user */
export type User_CustomerUpdateInput = {
  address?: Maybe<CustomerAddressUpdateRelationInput>;
  archived?: Maybe<Scalars['DateTime']>;
  contactName?: Maybe<Scalars['String']>;
  customerCasesRelation?: Maybe<CustomerCustomerCasesRelationUpdateRelationInput>;
  customerCustomerAlertRelation?: Maybe<CustomerCustomerCustomerAlertRelationUpdateRelationInput>;
  customerDocumentRelation?: Maybe<CustomerCustomerDocumentRelationUpdateRelationInput>;
  /** unique 6 - 12 digit number */
  customerID?: Maybe<Scalars['String']>;
  driverCustomerRelation?: Maybe<CustomerDriverCustomerRelationUpdateRelationInput>;
  email?: Maybe<Scalars['String']>;
  /** This block the customer from creating additional drivers */
  isCustomerDriver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notificationObjectCustomerRelation?: Maybe<CustomerNotificationObjectCustomerRelationUpdateRelationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['String']>;
  subscriptionUpgradeRequestDate?: Maybe<Scalars['DateTime']>;
  user?: Maybe<CustomerUserUpdateRelationInput>;
};

/** Notification create input from user */
export type User_NotificationCreateInput = {
  content: Scalars['String'];
  object?: Maybe<NotificationObjectRelationInput>;
  /** Control the status to show or not the notifications on the client. */
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  user?: Maybe<NotificationUserRelationInput>;
};

/** NotificationSetting create input from user */
export type User_NotificationSettingCreateInput = {
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  user?: Maybe<NotificationSettingUserRelationInput>;
};

/** NotificationSetting update input from user */
export type User_NotificationSettingUpdateInput = {
  /** When it's true send all the email notifications. */
  allNotifications?: Maybe<Scalars['Boolean']>;
  /** When it's true the field "allNotifications" must be false. Send only new comments on cases, */
  onlyNewComments?: Maybe<Scalars['Boolean']>;
  user?: Maybe<NotificationSettingUserUpdateRelationInput>;
};

/** Notification update input from user */
export type User_NotificationUpdateInput = {
  data: NotificationUpdateInput;
  filter?: Maybe<NotificationKeyFilter>;
};

export type User_PermissionFilter = {
  AND?: Maybe<Array<User_PermissionFilter>>;
  OR?: Maybe<Array<User_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  address?: Maybe<StringPredicate>;
  avatar?: Maybe<File_PermissionFilter>;
  caseHistoryRelation?: Maybe<CaseHistory_PermissionRelationFilter>;
  city?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  customer?: Maybe<Customer_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  email?: Maybe<StringPredicate>;
  firstName?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  is8base?: Maybe<BoolPredicate>;
  is_self?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<StringPredicate>;
  not_self?: Maybe<Scalars['Boolean']>;
  notificationRelation?: Maybe<Notification_PermissionRelationFilter>;
  notificationSetting?: Maybe<NotificationSetting_PermissionFilter>;
  origin?: Maybe<StringPredicate>;
  phoneNumber?: Maybe<StringPredicate>;
  roles?: Maybe<Role_PermissionRelationFilter>;
  state?: Maybe<UsState_PermissionFilter>;
  status?: Maybe<StringPredicate>;
  timezone?: Maybe<StringPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  userCaseCommentReplyRelation?: Maybe<CaseCommentReply_PermissionRelationFilter>;
  userCaseFollowerRelation?: Maybe<CaseFollower_PermissionRelationFilter>;
  zipCode?: Maybe<StringPredicate>;
};

export type User_PermissionRelationFilter = {
  every?: Maybe<User_PermissionFilter>;
  none?: Maybe<User_PermissionFilter>;
  some?: Maybe<User_PermissionFilter>;
};

/** Users relation input */
export type UsersAvatarManyRelationInput = {
  connect?: Maybe<FileKeyFilter>;
};

/** Users relation input */
export type UsersAvatarRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Users_Avatar_FileCreateInput>;
};

/** Users relation input */
export type UsersAvatarUpdateRelationInput = {
  connect?: Maybe<FileKeyFilter>;
  create?: Maybe<Users_Avatar_FileCreateInput>;
  disconnect?: Maybe<FileKeyFilter>;
  reconnect?: Maybe<FileKeyFilter>;
  update?: Maybe<Users_Avatar_FileUpdateInput>;
};

/** Users relation input */
export type UsersCaseHistoryRelationManyRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
};

/** Users relation input */
export type UsersCaseHistoryRelationRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<UserRelation_CaseHistoryCreateInput>>>;
};

/** Users relation input */
export type UsersCaseHistoryRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseHistoryKeyFilter>>;
  create?: Maybe<Array<Maybe<UserRelation_CaseHistoryCreateInput>>>;
  disconnect?: Maybe<Array<CaseHistoryKeyFilter>>;
  reconnect?: Maybe<Array<CaseHistoryKeyFilter>>;
  update?: Maybe<Array<Maybe<UserRelation_CaseHistoryUpdateInput>>>;
};

/** Users relation input */
export type UsersCustomerManyRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
};

/** Users relation input */
export type UsersCustomerRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<User_CustomerCreateInput>;
};

/** Users relation input */
export type UsersCustomerUpdateRelationInput = {
  connect?: Maybe<CustomerKeyFilter>;
  create?: Maybe<User_CustomerCreateInput>;
  disconnect?: Maybe<CustomerKeyFilter>;
  reconnect?: Maybe<CustomerKeyFilter>;
  update?: Maybe<User_CustomerUpdateInput>;
};

/** Users relation input */
export type UsersNotificationRelationManyRelationInput = {
  connect?: Maybe<Array<NotificationKeyFilter>>;
};

/** Users relation input */
export type UsersNotificationRelationRelationInput = {
  connect?: Maybe<Array<NotificationKeyFilter>>;
  create?: Maybe<Array<Maybe<User_NotificationCreateInput>>>;
};

/** Users relation input */
export type UsersNotificationRelationUpdateRelationInput = {
  connect?: Maybe<Array<NotificationKeyFilter>>;
  create?: Maybe<Array<Maybe<User_NotificationCreateInput>>>;
  disconnect?: Maybe<Array<NotificationKeyFilter>>;
  reconnect?: Maybe<Array<NotificationKeyFilter>>;
  update?: Maybe<Array<Maybe<User_NotificationUpdateInput>>>;
};

/** Users relation input */
export type UsersNotificationSettingManyRelationInput = {
  connect?: Maybe<NotificationSettingKeyFilter>;
};

/** Users relation input */
export type UsersNotificationSettingRelationInput = {
  connect?: Maybe<NotificationSettingKeyFilter>;
  create?: Maybe<User_NotificationSettingCreateInput>;
};

/** Users relation input */
export type UsersNotificationSettingUpdateRelationInput = {
  connect?: Maybe<NotificationSettingKeyFilter>;
  create?: Maybe<User_NotificationSettingCreateInput>;
  disconnect?: Maybe<NotificationSettingKeyFilter>;
  reconnect?: Maybe<NotificationSettingKeyFilter>;
  update?: Maybe<User_NotificationSettingUpdateInput>;
};

/** Users relation input */
export type UsersRolesManyRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
};

/** Users relation input */
export type UsersRolesRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<Users_RoleCreateInput>>>;
};

/** Users relation input */
export type UsersRolesUpdateRelationInput = {
  connect?: Maybe<Array<RoleKeyFilter>>;
  create?: Maybe<Array<Maybe<Users_RoleCreateInput>>>;
  disconnect?: Maybe<Array<RoleKeyFilter>>;
  reconnect?: Maybe<Array<RoleKeyFilter>>;
  update?: Maybe<Array<Maybe<Users_RoleUpdateInput>>>;
};

/** Users relation input */
export type UsersStateManyRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
};

/** Users relation input */
export type UsersStateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<UserRelation_UsStateCreateInput>;
};

/** Users relation input */
export type UsersStateUpdateRelationInput = {
  connect?: Maybe<UsStateKeyFilter>;
  create?: Maybe<UserRelation_UsStateCreateInput>;
  disconnect?: Maybe<UsStateKeyFilter>;
  reconnect?: Maybe<UsStateKeyFilter>;
  update?: Maybe<UserRelation_UsStateUpdateInput>;
};

/** Users relation input */
export type UsersUserCaseCommentReplyRelationManyRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
};

/** Users relation input */
export type UsersUserCaseCommentReplyRelationRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  create?: Maybe<Array<Maybe<User_CaseCommentReplyCreateInput>>>;
};

/** Users relation input */
export type UsersUserCaseCommentReplyRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  create?: Maybe<Array<Maybe<User_CaseCommentReplyCreateInput>>>;
  disconnect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  reconnect?: Maybe<Array<CaseCommentReplyKeyFilter>>;
  update?: Maybe<Array<Maybe<User_CaseCommentReplyUpdateInput>>>;
};

/** Users relation input */
export type UsersUserCaseFollowerRelationManyRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
};

/** Users relation input */
export type UsersUserCaseFollowerRelationRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
  create?: Maybe<Array<Maybe<Follower_CaseFollowerCreateInput>>>;
};

/** Users relation input */
export type UsersUserCaseFollowerRelationUpdateRelationInput = {
  connect?: Maybe<Array<CaseFollowerKeyFilter>>;
  create?: Maybe<Array<Maybe<Follower_CaseFollowerCreateInput>>>;
  disconnect?: Maybe<Array<CaseFollowerKeyFilter>>;
  reconnect?: Maybe<Array<CaseFollowerKeyFilter>>;
  update?: Maybe<Array<Maybe<Follower_CaseFollowerUpdateInput>>>;
};

/** Roles create input from users */
export type Users_RoleCreateInput = {
  apiTokens?: Maybe<RolesApiTokensRelationInput>;
  authenticationProfiles?: Maybe<RolesAuthenticationProfilesRelationInput>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<PermissionsInput>;
  users?: Maybe<RolesUsersRelationInput>;
};

/** Roles update input from users */
export type Users_RoleUpdateInput = {
  data: RoleUpdateInput;
  filter?: Maybe<RoleKeyFilter>;
};

/** Files create input from users_avatar */
export type Users_Avatar_FileCreateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarRelationInput>;
};

/** Files update input from users_avatar */
export type Users_Avatar_FileUpdateInput = {
  caseDocument_file?: Maybe<FilesCaseDocument_FileUpdateRelationInput>;
  citationCopy_citation?: Maybe<FilesCitationCopy_CitationUpdateRelationInput>;
  customerDocument_file?: Maybe<FilesCustomerDocument_FileUpdateRelationInput>;
  driverLicense_file?: Maybe<FilesDriverLicense_FileUpdateRelationInput>;
  evidence_evidence?: Maybe<FilesEvidence_EvidenceUpdateRelationInput>;
  fileId?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  mods?: Maybe<Scalars['JSON']>;
  public?: Maybe<Scalars['Boolean']>;
  users_avatar?: Maybe<FilesUsers_AvatarUpdateRelationInput>;
};

/** VerificationEmailResendInput */
export type VerificationEmailResendInput = {
  email: Scalars['String'];
};

/** View Attributes */
export type ViewAttributes = {
  __typename?: 'ViewAttributes';
  query?: Maybe<Scalars['String']>;
};

/** View Create Input */
export type ViewCreateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  query: Scalars['String'];
};

/** Dry Run Response for previewing SQL Views */
export type ViewDryRunOutput = {
  __typename?: 'ViewDryRunOutput';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Scalars['JSON']>;
  timeMs?: Maybe<Scalars['Int']>;
};

/** View Update Input */
export type ViewUpdateInput = {
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type ViolationCode = {
  __typename?: 'ViolationCode';
  _description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  severityWeight?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  violationCodeCaseViolationCodeRelation?: Maybe<CaseViolationCodeListResponse>;
};

export type ViolationCodeViolationCodeCaseViolationCodeRelationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CaseViolationCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  groupBy?: Maybe<CaseViolationCodeGroupBy>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<Maybe<CaseViolationCodeOrderBy>>>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CaseViolationCodeSort>>;
};

/** ViolationCode create input from violationCodeCaseViolationCodeRelation */
export type ViolationCodeCaseViolationCodeRelation_ViolationCodeCreateInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  severityWeight?: Maybe<Scalars['Int']>;
  violationCodeCaseViolationCodeRelation?: Maybe<ViolationCodeViolationCodeCaseViolationCodeRelationRelationInput>;
};

/** ViolationCode update input from violationCodeCaseViolationCodeRelation */
export type ViolationCodeCaseViolationCodeRelation_ViolationCodeUpdateInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  severityWeight?: Maybe<Scalars['Int']>;
  violationCodeCaseViolationCodeRelation?: Maybe<ViolationCodeViolationCodeCaseViolationCodeRelationUpdateRelationInput>;
};

/** ViolationCode create input */
export type ViolationCodeCreateInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  severityWeight?: Maybe<Scalars['Int']>;
  violationCodeCaseViolationCodeRelation?: Maybe<ViolationCodeViolationCodeCaseViolationCodeRelationRelationInput>;
};

/** ViolationCode create many input */
export type ViolationCodeCreateManyInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  severityWeight?: Maybe<Scalars['Int']>;
  violationCodeCaseViolationCodeRelation?: Maybe<ViolationCodeViolationCodeCaseViolationCodeRelationManyRelationInput>;
};

/** ViolationCode delete input */
export type ViolationCodeDeleteInput = {
  force?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** ViolationCodeFieldsPermissions create input */
export type ViolationCodeFieldsPermissions = {
  code?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['Boolean']>;
  severityWeight?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Boolean']>;
};

export type ViolationCodeFilter = {
  AND?: Maybe<Array<ViolationCodeFilter>>;
  OR?: Maybe<Array<ViolationCodeFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<UserFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  severityWeight?: Maybe<IntPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  violationCodeCaseViolationCodeRelation?: Maybe<CaseViolationCodeRelationFilter>;
};

export type ViolationCodeGroupBy = {
  first?: Maybe<Scalars['Int']>;
  having?: Maybe<Having>;
  last?: Maybe<Scalars['Int']>;
  query: ViolationCodeGroupByQuery;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<GroupBySort>>;
};

export type ViolationCodeGroupByQuery = {
  _group?: Maybe<Array<GroupIdentifiersGroupByField>>;
  code?: Maybe<Array<GroupByField>>;
  createdAt?: Maybe<Array<GroupByField>>;
  createdBy?: Maybe<UserGroupByQuery>;
  description?: Maybe<Array<GroupByField>>;
  id?: Maybe<Array<GroupByField>>;
  severityWeight?: Maybe<Array<GroupByField>>;
  updatedAt?: Maybe<Array<GroupByField>>;
  violationCodeCaseViolationCodeRelation?: Maybe<CaseViolationCodeGroupByQuery>;
};

export type ViolationCodeKeyFilter = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** ViolationCodeListResponse output */
export type ViolationCodeListResponse = {
  __typename?: 'ViolationCodeListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** Aggregated items */
  groups: Array<GroupByResponse>;
  /** List items */
  items: Array<ViolationCode>;
};

/** ViolationCodeManyResponse output */
export type ViolationCodeManyResponse = {
  __typename?: 'ViolationCodeManyResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<ViolationCode>;
};

/** No longer supported. Use `sort` instead. */
export enum ViolationCodeOrderBy {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SeverityWeightAsc = 'severityWeight_ASC',
  SeverityWeightDesc = 'severityWeight_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** ViolationCode subscription payload */
export type ViolationCodePayload = {
  __typename?: 'ViolationCodePayload';
  mutation: MutationType;
  node?: Maybe<ViolationCode>;
  previousValues?: Maybe<ViolationCode>;
  updatedFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ViolationCodeSort = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  createdBy?: Maybe<UserSort>;
  deletedAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  severityWeight?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

/** ViolationCode subscription filter */
export type ViolationCodeSubscriptionFilter = {
  mutation_in?: Maybe<Array<Maybe<MutationType>>>;
  node?: Maybe<ViolationCodeFilter>;
  updatedFields?: Maybe<UpdatedFieldsFilter>;
};

/** ViolationCode update input */
export type ViolationCodeUpdateByFilterInput = {
  code?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  description?: Maybe<Array<Maybe<UpdateByFilterStringInput>>>;
  severityWeight?: Maybe<Array<Maybe<UpdateByFilterIntInput>>>;
};

/** ViolationCode update input */
export type ViolationCodeUpdateInput = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  severityWeight?: Maybe<Scalars['Int']>;
  violationCodeCaseViolationCodeRelation?: Maybe<ViolationCodeViolationCodeCaseViolationCodeRelationUpdateRelationInput>;
};

/** ViolationCode relation input */
export type ViolationCodeViolationCodeCaseViolationCodeRelationManyRelationInput =
  {
    connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  };

/** ViolationCode relation input */
export type ViolationCodeViolationCodeCaseViolationCodeRelationRelationInput = {
  connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
  create?: Maybe<Array<Maybe<ViolationCode_CaseViolationCodeCreateInput>>>;
};

/** ViolationCode relation input */
export type ViolationCodeViolationCodeCaseViolationCodeRelationUpdateRelationInput =
  {
    connect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
    create?: Maybe<Array<Maybe<ViolationCode_CaseViolationCodeCreateInput>>>;
    disconnect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
    reconnect?: Maybe<Array<CaseViolationCodeKeyFilter>>;
    update?: Maybe<Array<Maybe<ViolationCode_CaseViolationCodeUpdateInput>>>;
  };

/** CaseViolationCode create input from violationCode */
export type ViolationCode_CaseViolationCodeCreateInput = {
  case?: Maybe<CaseViolationCodeCaseRelationInput>;
  violationCode?: Maybe<CaseViolationCodeViolationCodeRelationInput>;
};

/** CaseViolationCode update input from violationCode */
export type ViolationCode_CaseViolationCodeUpdateInput = {
  data: CaseViolationCodeUpdateInput;
  filter?: Maybe<CaseViolationCodeKeyFilter>;
};

export type ViolationCode_PermissionFilter = {
  AND?: Maybe<Array<ViolationCode_PermissionFilter>>;
  OR?: Maybe<Array<ViolationCode_PermissionFilter>>;
  _fullText?: Maybe<Scalars['String']>;
  code?: Maybe<StringPredicate>;
  createdAt?: Maybe<DateTimePredicate>;
  createdBy?: Maybe<User_PermissionFilter>;
  deletedAt?: Maybe<IntPredicate>;
  description?: Maybe<StringPredicate>;
  id?: Maybe<IdPredicate>;
  severityWeight?: Maybe<IntPredicate>;
  updatedAt?: Maybe<DateTimePredicate>;
  violationCodeCaseViolationCodeRelation?: Maybe<CaseViolationCode_PermissionRelationFilter>;
};

/** WorkspaceCreateMutationInput */
export type WorkspaceCreateMutationInput = {
  authType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  howStart?: Maybe<Scalars['String']>;
  image?: Maybe<GraphQlCreateFileItemInput>;
  kind?: Maybe<WorkspaceKind>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type WorkspaceCreateResponse = {
  __typename?: 'WorkspaceCreateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** WorkspaceDeleteMutationInput */
export type WorkspaceDeleteMutationInput = {
  workspaceId: Scalars['ID'];
};

export type WorkspaceImage = {
  __typename?: 'WorkspaceImage';
  downloadUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** LoginResponseWorkspace name and id */
export type WorkspaceInfo = {
  __typename?: 'WorkspaceInfo';
  name?: Maybe<Scalars['String']>;
  workspace?: Maybe<Scalars['ID']>;
};

export type WorkspaceItem = {
  __typename?: 'WorkspaceItem';
  apiHost?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<WorkspaceImage>;
  is8BaseAuthEnabled?: Maybe<Scalars['Boolean']>;
  isCiCdEnabled?: Maybe<Scalars['Boolean']>;
  isOwner: Scalars['Boolean'];
  kind?: Maybe<Scalars['String']>;
  lastAccess?: Maybe<Scalars['DateTime']>;
  lastDeployABInfo?: Maybe<DeploymentAbItemResponse>;
  name: Scalars['String'];
  nextPlan?: Maybe<BillingNextPlanResponse>;
  organization?: Maybe<SystemOrganizationBaseItem>;
  owner?: Maybe<SystemMemberAccountInfo>;
  plan?: Maybe<BillingCurrentPlanResponse>;
  project?: Maybe<ProjectItemWs>;
  region?: Maybe<Scalars['String']>;
  summaryABInfo?: Maybe<FrontendUtilizationAbResponse>;
  teamMemberCount?: Maybe<Scalars['Int']>;
  webSocket?: Maybe<Scalars['String']>;
};

/** Workspace Kind */
export enum WorkspaceKind {
  Frontend = 'frontend',
  General = 'general',
}

/** WorkspaceListResponse output */
export type WorkspaceListResponse = {
  __typename?: 'WorkspaceListResponse';
  /** List items count */
  count: Scalars['Int'];
  /** List items */
  items: Array<WorkspaceItem>;
};

export enum WorkspaceStatus {
  Active = 'active',
  Blocked = 'blocked',
  Canceled = 'canceled',
  Canceling = 'canceling',
  Pending = 'pending',
  Suspended = 'suspended',
}

/** WorkspaceUpdateMutationInput */
export type WorkspaceUpdateMutationInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<GraphQlCreateFileItemInput>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceUpdateResponse = {
  __typename?: 'WorkspaceUpdateResponse';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<GraphQlFileItemResponse>;
  name?: Maybe<Scalars['String']>;
};

export type IsAllowedCallbackUrlQueryResponse = {
  __typename?: 'isAllowedCallbackURLQueryResponse';
  isEnabled: Scalars['Boolean'];
};

export type CaseDocumentCreateMutationVariables = Exact<{
  data: CaseDocumentCreateInput;
}>;

export type CaseDocumentCreateMutation = {
  __typename?: 'Mutation';
  caseDocumentCreate: { __typename?: 'CaseDocument'; id: Maybe<string> };
};

export type CaseDocumentsListQueryVariables = Exact<{
  filter: CaseDocumentFilter;
}>;

export type CaseDocumentsListQuery = {
  __typename?: 'Query';
  caseDocumentsList: {
    __typename?: 'CaseDocumentListResponse';
    count: number;
    items: Array<{
      __typename?: 'CaseDocument';
      id: Maybe<string>;
      description: Maybe<string>;
      file: Maybe<{
        __typename?: 'File';
        id: Maybe<string>;
        fileId: Maybe<string>;
        filename: Maybe<string>;
        downloadUrl: Maybe<string>;
      }>;
    }>;
  };
};

export type CaseDocumentUpdateMutationVariables = Exact<{
  data: CaseDocumentUpdateInput;
  id: Scalars['ID'];
}>;

export type CaseDocumentUpdateMutation = {
  __typename?: 'Mutation';
  caseDocumentUpdate: { __typename?: 'CaseDocument'; id: Maybe<string> };
};

export type CaseDocumentDeleteMutationVariables = Exact<{
  filter: CaseDocumentKeyFilter;
}>;

export type CaseDocumentDeleteMutation = {
  __typename?: 'Mutation';
  caseDocumentDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type CaseDocumentDeleteManagementMutationVariables = Exact<{
  filter: CaseDocumentDeleteManagementFilter;
}>;

export type CaseDocumentDeleteManagementMutation = {
  __typename?: 'Mutation';
  caseDocumentDeleteManagement: Maybe<{
    __typename?: 'CaseDocumentDeleteManagementResult';
    success: boolean;
  }>;
};

export type CaseCommentCreateMutationVariables = Exact<{
  data: CaseCommentCreateInput;
}>;

export type CaseCommentCreateMutation = {
  __typename?: 'Mutation';
  caseCommentCreate: {
    __typename?: 'CaseComment';
    id: Maybe<string>;
    comment: Maybe<string>;
  };
};

export type CaseCommentUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: CaseCommentUpdateInput;
}>;

export type CaseCommentUpdateMutation = {
  __typename?: 'Mutation';
  caseCommentUpdate: {
    __typename?: 'CaseComment';
    id: Maybe<string>;
    comment: Maybe<string>;
  };
};

export type CaseCommentsListByCaseQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter: CaseCommentFilter;
}>;

export type CaseCommentsListByCaseQuery = {
  __typename?: 'Query';
  caseCommentsList: {
    __typename?: 'CaseCommentListResponse';
    count: number;
    items: Array<{
      __typename?: 'CaseComment';
      id: Maybe<string>;
      comment: Maybe<string>;
      mode: Maybe<string>;
      createdAt: Maybe<string | null | undefined>;
      caseRelation: Maybe<{ __typename?: 'Case'; id: Maybe<string> }>;
      replies: Maybe<{
        __typename?: 'CaseCommentReplyListResponse';
        items: Array<{
          __typename?: 'CaseCommentReply';
          id: Maybe<string>;
          comment: Maybe<string>;
          createdAt: Maybe<string | null | undefined>;
          user: Maybe<{
            __typename?: 'User';
            firstName: Maybe<string>;
            lastName: Maybe<string>;
            avatar: Maybe<{ __typename?: 'File'; downloadUrl: Maybe<string> }>;
          }>;
          createdBy: Maybe<{
            __typename?: 'User';
            firstName: Maybe<string>;
            lastName: Maybe<string>;
            avatar: Maybe<{ __typename?: 'File'; downloadUrl: Maybe<string> }>;
          }>;
        }>;
      }>;
      createdBy: Maybe<{
        __typename?: 'User';
        id: Maybe<string>;
        firstName: Maybe<string>;
        lastName: Maybe<string>;
        avatar: Maybe<{ __typename?: 'File'; downloadUrl: Maybe<string> }>;
      }>;
    }>;
  };
};

export type CaseCommentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CaseCommentQuery = {
  __typename?: 'Query';
  caseComment: Maybe<{
    __typename?: 'CaseComment';
    id: Maybe<string>;
    comment: Maybe<string>;
  }>;
};

export type CaseFollowersListQueryVariables = Exact<{
  filter: CaseFollowerFilter;
}>;

export type CaseFollowersListQuery = {
  __typename?: 'Query';
  caseFollowersList: {
    __typename?: 'CaseFollowerListResponse';
    count: number;
    items: Array<{
      __typename?: 'CaseFollower';
      id: Maybe<string>;
      follower: Maybe<{
        __typename?: 'User';
        id: Maybe<string>;
        firstName: Maybe<string>;
        lastName: Maybe<string>;
        email: Maybe<string>;
        avatar: Maybe<{ __typename?: 'File'; downloadUrl: Maybe<string> }>;
      }>;
    }>;
  };
};

export type CaseFollowerCreateManyMutationVariables = Exact<{
  data:
    | Array<Maybe<CaseFollowerCreateManyInput>>
    | Maybe<CaseFollowerCreateManyInput>;
}>;

export type CaseFollowerCreateManyMutation = {
  __typename?: 'Mutation';
  caseFollowerCreateMany: {
    __typename?: 'CaseFollowerManyResponse';
    items: Array<{ __typename?: 'CaseFollower'; id: Maybe<string> }>;
  };
};

export type CaseFollowerCreateMutationVariables = Exact<{
  data: CaseFollowerCreateInput;
}>;

export type CaseFollowerCreateMutation = {
  __typename?: 'Mutation';
  caseFollowerCreate: { __typename?: 'CaseFollower'; id: Maybe<string> };
};

export type CaseFollowerUpdateMutationVariables = Exact<{
  filter: CaseFollowerKeyFilter;
  data: CaseFollowerUpdateInput;
}>;

export type CaseFollowerUpdateMutation = {
  __typename?: 'Mutation';
  caseFollowerUpdate: { __typename?: 'CaseFollower'; id: Maybe<string> };
};

export type CaseHistoriesListQueryVariables = Exact<{
  filter?: Maybe<CaseHistoryFilter>;
  orderBy?: Maybe<Array<CaseHistoryOrderBy> | CaseHistoryOrderBy>;
}>;

export type CaseHistoriesListQuery = {
  __typename?: 'Query';
  caseHistoriesList: {
    __typename?: 'CaseHistoryListResponse';
    count: number;
    items: Array<{
      __typename?: 'CaseHistory';
      id: Maybe<string>;
      olderData: Maybe<{}>;
      newData: Maybe<{}>;
      message: Maybe<string>;
      createdAt: Maybe<string | null | undefined>;
      createdBy: Maybe<{
        __typename?: 'User';
        id: Maybe<string>;
        firstName: Maybe<string>;
        lastName: Maybe<string>;
        email: Maybe<string>;
      }>;
    }>;
  };
};

export type GetCasesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CaseFilter>;
  sort?: Maybe<Array<CaseSort> | CaseSort>;
}>;

export type GetCasesQuery = {
  __typename?: 'Query';
  casesList: {
    __typename?: 'CaseListResponse';
    count: number;
    items: Array<{
      __typename?: 'Case';
      id: Maybe<string>;
      citationStatus: Maybe<string>;
      caseID: Maybe<string>;
      code: Maybe<number>;
      citationDueDate: Maybe<string | null | undefined>;
      updatedAtByAgent: Maybe<string | null | undefined>;
      createdAt: Maybe<string | null | undefined>;
      roadsideInspection: Maybe<boolean>;
      trafficCitation: Maybe<boolean>;
      inspectionDate: Maybe<string | null | undefined>;
      inspectionReportNumber: Maybe<string>;
      briefStatement: Maybe<string>;
      dataQsStatus: Maybe<string>;
      status: Maybe<string>;
      updatedAt: Maybe<string | null | undefined>;
      driver: Maybe<{
        __typename?: 'Driver';
        id: Maybe<string>;
        name: Maybe<string>;
        lastName: Maybe<string>;
        email: Maybe<string>;
        licenseState: Maybe<string>;
        licenseNumber: Maybe<string>;
        customer: Maybe<{
          __typename?: 'Customer';
          id: Maybe<string>;
          name: Maybe<string>;
          contactName: Maybe<string>;
          user: Maybe<{
            __typename?: 'User';
            firstName: Maybe<string>;
            lastName: Maybe<string>;
          }>;
        }>;
      }>;
      attorneyRelation: Maybe<{
        __typename?: 'Attorney';
        name: Maybe<string>;
        fee: Maybe<number>;
      }>;
      inspectionCitationState: Maybe<{
        __typename?: 'UsState';
        name: Maybe<string>;
        code: Maybe<string>;
        id: Maybe<string>;
      }>;
      customer: Maybe<{ __typename?: 'Customer'; name: Maybe<string> }>;
    }>;
  };
};

export type GetCaseQueryVariables = Exact<{
  caseId: Scalars['ID'];
}>;

export type GetCaseQuery = {
  __typename?: 'Query';
  case: Maybe<{
    __typename?: 'Case';
    id: Maybe<string>;
    caseID: Maybe<string>;
    citationStatus: Maybe<string>;
    citationCourtNameAddress: Maybe<string>;
    createdAt: Maybe<string | null | undefined>;
    roadsideInspection: Maybe<boolean>;
    trafficCitation: Maybe<boolean>;
    crash: Maybe<boolean>;
    inspectionDate: Maybe<string | null | undefined>;
    inspectionReportNumber: Maybe<string>;
    briefStatement: Maybe<string>;
    citationNumbers: Maybe<string>;
    citationDueDate: Maybe<string | null | undefined>;
    citationArraignment: Maybe<string | null | undefined>;
    updatedAtByAgent: Maybe<string | null | undefined>;
    dataQsStatus: Maybe<string>;
    status: Maybe<string>;
    caseDocumentRelation: Maybe<{
      __typename?: 'CaseDocumentListResponse';
      count: number;
      items: Array<{
        __typename?: 'CaseDocument';
        type: Maybe<string>;
        id: Maybe<string>;
        file: Maybe<{
          __typename?: 'File';
          id: Maybe<string>;
          fileId: Maybe<string>;
          downloadUrl: Maybe<string>;
          filename: Maybe<string>;
        }>;
      }>;
    }>;
    driver: Maybe<{
      __typename?: 'Driver';
      id: Maybe<string>;
      name: Maybe<string>;
      lastName: Maybe<string>;
      email: Maybe<string>;
      phoneNumber: Maybe<{
        __typename?: 'SmartPhone';
        code: Maybe<string>;
        number: Maybe<string>;
      }>;
      preferredLanguage: Maybe<{
        __typename?: 'DriverLanguage';
        name: Maybe<string>;
      }>;
      customer: Maybe<{
        __typename?: 'Customer';
        id: Maybe<string>;
        name: Maybe<string>;
        contactName: Maybe<string>;
        email: Maybe<string>;
        user: Maybe<{ __typename?: 'User'; id: Maybe<string> }>;
        driverCustomerRelation: Maybe<{
          __typename?: 'DriverListResponse';
          items: Array<{
            __typename?: 'Driver';
            id: Maybe<string>;
            name: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
    inspectionCitationState: Maybe<{
      __typename?: 'UsState';
      id: Maybe<string>;
      name: Maybe<string>;
      code: Maybe<string>;
    }>;
    caseCaseViolationCodeRelation: Maybe<{
      __typename?: 'CaseViolationCodeListResponse';
      count: number;
      items: Array<{
        __typename?: 'CaseViolationCode';
        id: Maybe<string>;
        violationCode: Maybe<{
          __typename?: 'ViolationCode';
          id: Maybe<string>;
          code: Maybe<string>;
          severityWeight: Maybe<number>;
          description: Maybe<string>;
        }>;
      }>;
    }>;
    attorneyRelation: Maybe<{
      __typename?: 'Attorney';
      id: Maybe<string>;
      name: Maybe<string>;
      fee: Maybe<number>;
      email: Maybe<string>;
      phone: Maybe<string>;
    }>;
    caseCaseFollowerRelation: Maybe<{
      __typename?: 'CaseFollowerListResponse';
      items: Array<{
        __typename?: 'CaseFollower';
        id: Maybe<string>;
        follower: Maybe<{
          __typename?: 'User';
          email: Maybe<string>;
          firstName: Maybe<string>;
          lastName: Maybe<string>;
          customer: Maybe<{
            __typename?: 'Customer';
            name: Maybe<string>;
            contactName: Maybe<string>;
          }>;
          roles: Maybe<{
            __typename?: 'RoleListResponse';
            items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
          }>;
        }>;
      }>;
    }>;
    customer: Maybe<{ __typename?: 'Customer'; name: Maybe<string> }>;
  }>;
};

export type CreateCaseMutationVariables = Exact<{
  data: CaseCreateInput;
}>;

export type CreateCaseMutation = {
  __typename?: 'Mutation';
  caseCreate: {
    __typename?: 'Case';
    id: Maybe<string>;
    roadsideInspection: Maybe<boolean>;
    trafficCitation: Maybe<boolean>;
    briefStatement: Maybe<string>;
    _description: Maybe<string>;
    createdBy: Maybe<{ __typename?: 'User'; id: Maybe<string> }>;
    driver: Maybe<{
      __typename?: 'Driver';
      id: Maybe<string>;
      name: Maybe<string>;
      email: Maybe<string>;
      customer: Maybe<{
        __typename?: 'Customer';
        user: Maybe<{ __typename?: 'User'; id: Maybe<string> }>;
      }>;
    }>;
  };
};

export type UpdateCaseMutationVariables = Exact<{
  caseInput: CaseUpdateInput;
}>;

export type UpdateCaseMutation = {
  __typename?: 'Mutation';
  caseUpdate: {
    __typename?: 'Case';
    id: Maybe<string>;
    createdAt: Maybe<string | null | undefined>;
    roadsideInspection: Maybe<boolean>;
    trafficCitation: Maybe<boolean>;
    inspectionDate: Maybe<string | null | undefined>;
    inspectionReportNumber: Maybe<string>;
    briefStatement: Maybe<string>;
    _description: Maybe<string>;
    driver: Maybe<{
      __typename?: 'Driver';
      id: Maybe<string>;
      name: Maybe<string>;
      email: Maybe<string>;
    }>;
  };
};

export type DeleteCaseMutationVariables = Exact<{
  deleteInput: CaseDeleteInput;
}>;

export type DeleteCaseMutation = {
  __typename?: 'Mutation';
  caseDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type GetApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetApiKeyQuery = {
  __typename?: 'Query';
  fileUploadInfo: Maybe<{
    __typename?: 'FileUploadInfoResponse';
    apiKey: string;
    policy: string;
    signature: string;
    path: string;
  }>;
};

export type GetCodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCodesQuery = {
  __typename?: 'Query';
  violationCodesList: {
    __typename?: 'ViolationCodeListResponse';
    count: number;
    items: Array<{
      __typename?: 'ViolationCode';
      id: Maybe<string>;
      code: Maybe<string>;
      severityWeight: Maybe<number>;
      description: Maybe<string>;
    }>;
  };
};

export type GetCasesStatusQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CaseFilter>;
  sort?: Maybe<Array<CaseSort> | CaseSort>;
}>;

export type GetCasesStatusQuery = {
  __typename?: 'Query';
  casesList: {
    __typename?: 'CaseListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      statusCase: Maybe<string>;
      status: { __typename?: 'CaseListResponse'; count: number };
    }>;
  };
};

export type GetCasesStatusByMonthQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CaseFilter>;
  sort?: Maybe<Array<CaseSort> | CaseSort>;
}>;

export type GetCasesStatusByMonthQuery = {
  __typename?: 'Query';
  casesList: {
    __typename?: 'CaseListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      month: Maybe<string>;
      cases: {
        __typename?: 'CaseListResponse';
        count: number;
        items: Array<{
          __typename?: 'Case';
          id: Maybe<string>;
          status: Maybe<string>;
        }>;
      };
    }>;
  };
};

export type CreateVCodeMutationVariables = Exact<{
  vCode: ViolationCodeCreateInput;
}>;

export type CreateVCodeMutation = {
  __typename?: 'Mutation';
  violationCodeCreate: {
    __typename?: 'ViolationCode';
    id: Maybe<string>;
    code: Maybe<string>;
    severityWeight: Maybe<number>;
    description: Maybe<string>;
  };
};

export type CasesListCsvQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CaseFilter>;
}>;

export type CasesListCsvQuery = {
  __typename?: 'Query';
  casesList: {
    __typename?: 'CaseListResponse';
    count: number;
    items: Array<{
      __typename?: 'Case';
      id: Maybe<string>;
      caseID: Maybe<string>;
      inspectionDate: Maybe<string | null | undefined>;
      inspectionReportNumber: Maybe<string>;
      citationNumbers: Maybe<string>;
      citationArraignment: Maybe<string | null | undefined>;
      status: Maybe<string>;
      dataQsStatus: Maybe<string>;
      driver: Maybe<{
        __typename?: 'Driver';
        name: Maybe<string>;
        lastName: Maybe<string>;
        customer: Maybe<{ __typename?: 'Customer'; name: Maybe<string> }>;
      }>;
      inspectionCitationState: Maybe<{
        __typename?: 'UsState';
        name: Maybe<string>;
      }>;
      caseCaseViolationCodeRelation: Maybe<{
        __typename?: 'CaseViolationCodeListResponse';
        items: Array<{
          __typename?: 'CaseViolationCode';
          violationCode: Maybe<{
            __typename?: 'ViolationCode';
            code: Maybe<string>;
            description: Maybe<string>;
            severityWeight: Maybe<number>;
          }>;
        }>;
      }>;
      attorneyRelation: Maybe<{
        __typename?: 'Attorney';
        name: Maybe<string>;
        fee: Maybe<number>;
      }>;
      caseCaseFollowerRelation: Maybe<{
        __typename?: 'CaseFollowerListResponse';
        items: Array<{
          __typename?: 'CaseFollower';
          follower: Maybe<{
            __typename?: 'User';
            firstName: Maybe<string>;
            lastName: Maybe<string>;
            roles: Maybe<{
              __typename?: 'RoleListResponse';
              items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
            }>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type CustomerDocumentCreateMutationVariables = Exact<{
  data: CustomerDocumentCreateInput;
}>;

export type CustomerDocumentCreateMutation = {
  __typename?: 'Mutation';
  customerDocumentCreate: {
    __typename?: 'CustomerDocument';
    id: Maybe<string>;
  };
};

export type CustomerDocumentsListQueryVariables = Exact<{
  filter: CustomerDocumentFilter;
}>;

export type CustomerDocumentsListQuery = {
  __typename?: 'Query';
  customerDocumentsList: {
    __typename?: 'CustomerDocumentListResponse';
    count: number;
    items: Array<{
      __typename?: 'CustomerDocument';
      id: Maybe<string>;
      description: Maybe<string>;
      file: Maybe<{
        __typename?: 'File';
        id: Maybe<string>;
        fileId: Maybe<string>;
        filename: Maybe<string>;
        downloadUrl: Maybe<string>;
      }>;
    }>;
  };
};

export type CustomerDocumentUpdateMutationVariables = Exact<{
  data: CustomerDocumentUpdateInput;
  id: Scalars['ID'];
}>;

export type CustomerDocumentUpdateMutation = {
  __typename?: 'Mutation';
  customerDocumentUpdate: {
    __typename?: 'CustomerDocument';
    id: Maybe<string>;
  };
};

export type CustomerDocumentDeleteMutationVariables = Exact<{
  filter: CustomerDocumentKeyFilter;
}>;

export type CustomerDocumentDeleteMutation = {
  __typename?: 'Mutation';
  customerDocumentDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type CreateCustomerMutationVariables = Exact<{
  data: CustomerCreateInput;
}>;

export type CreateCustomerMutation = {
  __typename?: 'Mutation';
  customerCreate: { __typename?: 'Customer'; id: Maybe<string> };
};

export type CustomersNamesAndIdsQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type CustomersNamesAndIdsQuery = {
  __typename?: 'Query';
  customersList: {
    __typename?: 'CustomerListResponse';
    items: Array<{
      __typename?: 'Customer';
      id: Maybe<string>;
      name: Maybe<string>;
    }>;
  };
};

export type CustomersListQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CustomerFilter>;
  sort?: Maybe<Array<CustomerSort> | CustomerSort>;
}>;

export type CustomersListQuery = {
  __typename?: 'Query';
  customersList: {
    __typename?: 'CustomerListResponse';
    count: number;
    items: Array<{
      __typename?: 'Customer';
      id: Maybe<string>;
      name: Maybe<string>;
      contactName: Maybe<string>;
      createdAt: Maybe<string | null | undefined>;
      email: Maybe<string>;
      archived: Maybe<string | null | undefined>;
      status: Maybe<string>;
      customerID: Maybe<string>;
      user: Maybe<{
        __typename?: 'User';
        firstName: Maybe<string>;
        lastName: Maybe<string>;
        roles: Maybe<{
          __typename?: 'RoleListResponse';
          items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
        }>;
      }>;
    }>;
  };
};

export type CustomerDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CustomerDetailQuery = {
  __typename?: 'Query';
  customer: Maybe<{
    __typename?: 'Customer';
    subscription: Maybe<string>;
    id: Maybe<string>;
    name: Maybe<string>;
    contactName: Maybe<string>;
    phoneNumber: Maybe<string>;
    email: Maybe<string>;
    createdAt: Maybe<string | null | undefined>;
    status: Maybe<string>;
    archived: Maybe<string | null | undefined>;
    customerID: Maybe<string>;
    user: Maybe<{
      __typename?: 'User';
      roles: Maybe<{
        __typename?: 'RoleListResponse';
        items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
      }>;
    }>;
    driverCustomerRelation: Maybe<{
      __typename?: 'DriverListResponse';
      count: number;
      items: Array<{
        __typename?: 'Driver';
        id: Maybe<string>;
        name: Maybe<string>;
        lastName: Maybe<string>;
        email: Maybe<string>;
        dateOfBirth: Maybe<string | null | undefined>;
        licenseNumber: Maybe<string>;
        licenseState: Maybe<string>;
        preferredLanguage: Maybe<{
          __typename?: 'DriverLanguage';
          id: Maybe<string>;
          name: Maybe<string>;
        }>;
        phoneNumber: Maybe<{
          __typename?: 'SmartPhone';
          number: Maybe<string>;
          code: Maybe<string>;
        }>;
        caseDriverRelation: Maybe<{
          __typename?: 'CaseListResponse';
          count: number;
          items: Array<{
            __typename?: 'Case';
            id: Maybe<string>;
            code: Maybe<number>;
            caseID: Maybe<string>;
            citationDueDate: Maybe<string | null | undefined>;
            status: Maybe<string>;
            dataQsStatus: Maybe<string>;
            inspectionCitationState: Maybe<{
              __typename?: 'UsState';
              name: Maybe<string>;
              id: Maybe<string>;
            }>;
          }>;
        }>;
      }>;
    }>;
    customerCasesRelation: Maybe<{
      __typename?: 'CaseListResponse';
      count: number;
      items: Array<{
        __typename?: 'Case';
        id: Maybe<string>;
        caseID: Maybe<string>;
        code: Maybe<number>;
        citationDueDate: Maybe<string | null | undefined>;
        dataQsStatus: Maybe<string>;
        createdAt: Maybe<string | null | undefined>;
        inspectionCitationState: Maybe<{
          __typename?: 'UsState';
          name: Maybe<string>;
          code: Maybe<string>;
          id: Maybe<string>;
        }>;
        driver: Maybe<{
          __typename?: 'Driver';
          id: Maybe<string>;
          name: Maybe<string>;
          phoneNumber: Maybe<{
            __typename?: 'SmartPhone';
            code: Maybe<string>;
            number: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
    address: Maybe<{
      __typename?: 'AddressListResponse';
      count: number;
      items: Array<{
        __typename?: 'Address';
        city: Maybe<string>;
        street: Maybe<string>;
        id: Maybe<string>;
        zipCode: Maybe<string>;
        apartment: Maybe<string>;
        state: Maybe<{
          __typename?: 'UsState';
          id: Maybe<string>;
          name: Maybe<string>;
        }>;
      }>;
    }>;
  }>;
};

export type UpdateAddressMutationVariables = Exact<{
  data: CustomerUpdateByFilterInput;
  filter?: Maybe<CustomerFilter>;
}>;

export type UpdateAddressMutation = {
  __typename?: 'Mutation';
  customerUpdateByFilter: {
    __typename?: 'CustomerManyResponse';
    items: Array<{ __typename?: 'Customer'; id: Maybe<string> }>;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  data: CustomerUpdateInput;
  filter?: Maybe<CustomerKeyFilter>;
}>;

export type UpdateCustomerMutation = {
  __typename?: 'Mutation';
  customerUpdate: {
    __typename?: 'Customer';
    id: Maybe<string>;
    name: Maybe<string>;
    contactName: Maybe<string>;
    phoneNumber: Maybe<string>;
    user: Maybe<{ __typename?: 'User'; id: Maybe<string> }>;
    address: Maybe<{
      __typename?: 'AddressListResponse';
      items: Array<{
        __typename?: 'Address';
        id: Maybe<string>;
        city: Maybe<string>;
        street: Maybe<string>;
        zipCode: Maybe<string>;
        state: Maybe<{ __typename?: 'UsState'; id: Maybe<string> }>;
      }>;
    }>;
  };
};

export type DriverListWithMustViolationsQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
}>;

export type DriverListWithMustViolationsQuery = {
  __typename?: 'Query';
  driversList: {
    __typename?: 'DriverListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      id: Maybe<string>;
      name: Maybe<string>;
      lastName: Maybe<string>;
      numberOfViolations: Maybe<number>;
      dateOfLastViolation: Maybe<string | null | undefined>;
    }>;
  };
};

export type CreateDocumentDraftMutationVariables = Exact<{
  data: DocumentDraftCreateInput;
}>;

export type CreateDocumentDraftMutation = {
  __typename?: 'Mutation';
  documentDraftCreate: { __typename?: 'DocumentDraft'; id: Maybe<string> };
};

export type UpdateDocumentDraftMutationVariables = Exact<{
  data: DocumentDraftUpdateInput;
  id?: Maybe<Scalars['ID']>;
}>;

export type UpdateDocumentDraftMutation = {
  __typename?: 'Mutation';
  documentDraftUpdate: { __typename?: 'DocumentDraft'; id: Maybe<string> };
};

export type DeleteDocumentDraftMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type DeleteDocumentDraftMutation = {
  __typename?: 'Mutation';
  documentDraftDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type GetDocumentDraftByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type GetDocumentDraftByIdQuery = {
  __typename?: 'Query';
  documentDraft: Maybe<{
    __typename?: 'DocumentDraft';
    editorState: Maybe<{}>;
    documentTitle: Maybe<string>;
    signers: Maybe<{}>;
    driver: Maybe<string>;
    customer: Maybe<string>;
  }>;
};

export type GetDraftsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDraftsQuery = {
  __typename?: 'Query';
  documentDraftsList: {
    __typename?: 'DocumentDraftListResponse';
    items: Array<{
      __typename?: 'DocumentDraft';
      id: Maybe<string>;
      documentTitle: Maybe<string>;
      driver: Maybe<string>;
      customer: Maybe<string>;
      signers: Maybe<{}>;
    }>;
  };
};

export type EverSignDocCreateManyMutationVariables = Exact<{
  data:
    | Array<Maybe<EverSignDocCreateManyInput>>
    | Maybe<EverSignDocCreateManyInput>;
}>;

export type EverSignDocCreateManyMutation = {
  __typename?: 'Mutation';
  everSignDocCreateMany: {
    __typename?: 'EverSignDocManyResponse';
    count: number;
  };
};

export type EversingDocumentListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EversingDocumentListQuery = {
  __typename?: 'Query';
  everSignDocsList: {
    __typename?: 'EverSignDocListResponse';
    items: Array<{ __typename?: 'EverSignDoc'; hash: Maybe<string> }>;
  };
};

export type DriverLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type DriverLanguageQuery = {
  __typename?: 'Query';
  driverLanguagesList: {
    __typename?: 'DriverLanguageListResponse';
    items: Array<{
      __typename?: 'DriverLanguage';
      id: Maybe<string>;
      name: Maybe<string>;
    }>;
  };
};

export type DriverCreateMutationVariables = Exact<{
  data: DriverCreateInput;
}>;

export type DriverCreateMutation = {
  __typename?: 'Mutation';
  driverCreate: {
    __typename?: 'Driver';
    id: Maybe<string>;
    name: Maybe<string>;
    licenseState: Maybe<string>;
    licenseNumber: Maybe<string>;
    lastName: Maybe<string>;
    dateOfBirth: Maybe<string | null | undefined>;
    phoneNumber: Maybe<{
      __typename?: 'SmartPhone';
      code: Maybe<string>;
      number: Maybe<string>;
    }>;
    preferredLanguage: Maybe<{
      __typename?: 'DriverLanguage';
      id: Maybe<string>;
    }>;
  };
};

export type DriversListQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<DriverFilter>;
  sort?: Maybe<Array<DriverSort> | DriverSort>;
}>;

export type DriversListQuery = {
  __typename?: 'Query';
  driversList: {
    __typename?: 'DriverListResponse';
    count: number;
    items: Array<{
      __typename?: 'Driver';
      id: Maybe<string>;
      name: Maybe<string>;
      email: Maybe<string>;
      lastName: Maybe<string>;
      status: Maybe<string>;
      createdAt: Maybe<string | null | undefined>;
      customer: Maybe<{
        __typename?: 'Customer';
        id: Maybe<string>;
        name: Maybe<string>;
        driverCustomerRelation: Maybe<{
          __typename?: 'DriverListResponse';
          count: number;
        }>;
      }>;
      phoneNumber: Maybe<{
        __typename?: 'SmartPhone';
        code: Maybe<string>;
        number: Maybe<string>;
      }>;
      preferredLanguage: Maybe<{
        __typename?: 'DriverLanguage';
        name: Maybe<string>;
      }>;
      caseDriverRelation: Maybe<{
        __typename?: 'CaseListResponse';
        items: Array<{
          __typename?: 'Case';
          id: Maybe<string>;
          code: Maybe<number>;
          caseID: Maybe<string>;
          createdAt: Maybe<string | null | undefined>;
          citationDueDate: Maybe<string | null | undefined>;
          status: Maybe<string>;
          inspectionCitationState: Maybe<{
            __typename?: 'UsState';
            name: Maybe<string>;
            code: Maybe<string>;
            id: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
  };
};

export type DriverQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type DriverQuery = {
  __typename?: 'Query';
  driver: Maybe<{
    __typename?: 'Driver';
    id: Maybe<string>;
    name: Maybe<string>;
    lastName: Maybe<string>;
    dateOfBirth: Maybe<string | null | undefined>;
    email: Maybe<string>;
    archived: Maybe<string | null | undefined>;
    status: Maybe<string>;
    licenseNumber: Maybe<string>;
    licenseState: Maybe<string>;
    customer: Maybe<{
      __typename?: 'Customer';
      id: Maybe<string>;
      user: Maybe<{
        __typename?: 'User';
        roles: Maybe<{
          __typename?: 'RoleListResponse';
          items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
        }>;
      }>;
      driverCustomerRelation: Maybe<{
        __typename?: 'DriverListResponse';
        count: number;
      }>;
    }>;
    phoneNumber: Maybe<{
      __typename?: 'SmartPhone';
      code: Maybe<string>;
      number: Maybe<string>;
    }>;
    preferredLanguage: Maybe<{
      __typename?: 'DriverLanguage';
      id: Maybe<string>;
      name: Maybe<string>;
    }>;
    caseDriverRelation: Maybe<{
      __typename?: 'CaseListResponse';
      items: Array<{
        __typename?: 'Case';
        id: Maybe<string>;
        code: Maybe<number>;
        caseID: Maybe<string>;
        createdAt: Maybe<string | null | undefined>;
        citationDueDate: Maybe<string | null | undefined>;
        status: Maybe<string>;
        inspectionCitationState: Maybe<{
          __typename?: 'UsState';
          name: Maybe<string>;
          code: Maybe<string>;
          id: Maybe<string>;
        }>;
      }>;
    }>;
  }>;
};

export type DriverUpdateMutationVariables = Exact<{
  data: DriverUpdateInput;
}>;

export type DriverUpdateMutation = {
  __typename?: 'Mutation';
  driverUpdate: {
    __typename?: 'Driver';
    id: Maybe<string>;
    name: Maybe<string>;
    phoneNumber: Maybe<{
      __typename?: 'SmartPhone';
      code: Maybe<string>;
      number: Maybe<string>;
    }>;
    preferredLanguage: Maybe<{
      __typename?: 'DriverLanguage';
      id: Maybe<string>;
    }>;
  };
};

export type DriverDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DriverDeleteMutation = {
  __typename?: 'Mutation';
  driverDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type DriversByCustomerIdQueryVariables = Exact<{
  customerID: Scalars['ID'];
}>;

export type DriversByCustomerIdQuery = {
  __typename?: 'Query';
  driversList: {
    __typename?: 'DriverListResponse';
    items: Array<{
      __typename?: 'Driver';
      id: Maybe<string>;
      name: Maybe<string>;
      lastName: Maybe<string>;
      email: Maybe<string>;
    }>;
  };
};

export type GetDriverDocumentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDriverDocumentsQuery = {
  __typename?: 'Query';
  everSignDocsList: {
    __typename?: 'EverSignDocListResponse';
    items: Array<{
      __typename?: 'EverSignDoc';
      hash: Maybe<string>;
      driver: Maybe<{ __typename?: 'Driver'; id: Maybe<string> }>;
    }>;
  };
};

export type GetDriverLicensesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetDriverLicensesQuery = {
  __typename?: 'Query';
  driverLicensesList: {
    __typename?: 'DriverLicenseListResponse';
    items: Array<{
      __typename?: 'DriverLicense';
      id: Maybe<string>;
      file: Maybe<{
        __typename?: 'File';
        id: Maybe<string>;
        fileId: Maybe<string>;
        downloadUrl: Maybe<string>;
        filename: Maybe<string>;
      }>;
    }>;
  };
};

export type DeleteDriverLicenseMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;

export type DeleteDriverLicenseMutation = {
  __typename?: 'Mutation';
  driverLicenseDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type DriverNumberOfCrashesQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: Maybe<CaseFilter>;
}>;

export type DriverNumberOfCrashesQuery = {
  __typename?: 'Query';
  driver: Maybe<{
    __typename?: 'Driver';
    caseDriverRelation: Maybe<{
      __typename?: 'CaseListResponse';
      count: number;
    }>;
  }>;
};

export type DriverViolationCodeListQueryVariables = Exact<{
  driverId: Scalars['ID'];
  take?: Maybe<Scalars['Int']>;
  caseCreatedAt?: Maybe<Scalars['DateTime']>;
}>;

export type DriverViolationCodeListQuery = {
  __typename?: 'Query';
  violationCodesList: {
    __typename?: 'DriverListResponse';
    groups: Array<{
      __typename?: 'GroupByResponse';
      caseCreatedAt: Maybe<string | null | undefined>;
      caseState: Maybe<string>;
      caseDate: Maybe<string | null | undefined>;
      violationCode: Maybe<string>;
      violationDescription: Maybe<string>;
      violationSeverityWeight: Maybe<number>;
    }>;
  };
  totalViolationCodes: {
    __typename?: 'CaseListResponse';
    groups: Array<{ __typename?: 'GroupByResponse'; count: Maybe<number> }>;
  };
};

export type NotificationsListQueryVariables = Exact<{
  filter: NotificationFilter;
  orderBy?: Maybe<
    Array<Maybe<NotificationOrderBy>> | Maybe<NotificationOrderBy>
  >;
}>;

export type NotificationsListQuery = {
  __typename?: 'Query';
  notificationsList: {
    __typename?: 'NotificationListResponse';
    items: Array<{
      __typename?: 'Notification';
      id: Maybe<string>;
      title: Maybe<string>;
      content: Maybe<string>;
      status: Maybe<string>;
      user: Maybe<{
        __typename?: 'User';
        id: Maybe<string>;
        firstName: Maybe<string>;
        lastName: Maybe<string>;
      }>;
      object: Maybe<{
        __typename?: 'NotificationObject';
        id: Maybe<string>;
        resource: Maybe<string>;
        case: Maybe<{ __typename?: 'Case'; id: Maybe<string> }>;
        customer: Maybe<{ __typename?: 'Customer'; id: Maybe<string> }>;
      }>;
    }>;
  };
};

export type NotificationUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: NotificationUpdateInput;
}>;

export type NotificationUpdateMutation = {
  __typename?: 'Mutation';
  notificationUpdate: { __typename?: 'Notification'; id: Maybe<string> };
};

export type NotificationListSubscriptionSubscriptionVariables = Exact<{
  filter: NotificationFilter;
}>;

export type NotificationListSubscriptionSubscription = {
  __typename?: 'Subscription';
  Notification: Maybe<{
    __typename?: 'NotificationPayload';
    mutation: MutationType;
    node: Maybe<{
      __typename?: 'Notification';
      id: Maybe<string>;
      title: Maybe<string>;
      content: Maybe<string>;
      status: Maybe<string>;
      user: Maybe<{
        __typename?: 'User';
        id: Maybe<string>;
        firstName: Maybe<string>;
        lastName: Maybe<string>;
        email: Maybe<string>;
      }>;
    }>;
  }>;
};

export type UserSessionQueryVariables = Exact<{ [key: string]: never }>;

export type UserSessionQuery = {
  __typename?: 'Query';
  user: Maybe<{
    __typename?: 'User';
    id: Maybe<string>;
    lastName: Maybe<string>;
    firstName: Maybe<string>;
    phoneNumber: Maybe<string>;
    email: Maybe<string>;
    zipCode: Maybe<string>;
    address: Maybe<string>;
    city: Maybe<string>;
    state: Maybe<{
      __typename?: 'UsState';
      id: Maybe<string>;
      name: Maybe<string>;
    }>;
    avatar: Maybe<{
      __typename?: 'File';
      id: Maybe<string>;
      fileId: Maybe<string>;
      filename: Maybe<string>;
      downloadUrl: Maybe<string>;
      shareUrl: Maybe<string>;
    }>;
    roles: Maybe<{
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
    }>;
    customer: Maybe<{
      __typename?: 'Customer';
      id: Maybe<string>;
      name: Maybe<string>;
      contactName: Maybe<string>;
      customerID: Maybe<string>;
      phoneNumber: Maybe<string>;
      subscription: Maybe<string>;
      driverCustomerRelation: Maybe<{
        __typename?: 'DriverListResponse';
        items: Array<{
          __typename?: 'Driver';
          id: Maybe<string>;
          name: Maybe<string>;
          lastName: Maybe<string>;
          email: Maybe<string>;
          createdAt: Maybe<string | null | undefined>;
          dateOfBirth: Maybe<string | null | undefined>;
          phoneNumber: Maybe<{
            __typename?: 'SmartPhone';
            code: Maybe<string>;
            number: Maybe<string>;
          }>;
          preferredLanguage: Maybe<{
            __typename?: 'DriverLanguage';
            name: Maybe<string>;
          }>;
        }>;
      }>;
      address: Maybe<{
        __typename?: 'AddressListResponse';
        items: Array<{
          __typename?: 'Address';
          id: Maybe<string>;
          zipCode: Maybe<string>;
          street: Maybe<string>;
          city: Maybe<string>;
          state: Maybe<{
            __typename?: 'UsState';
            id: Maybe<string>;
            name: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
    notificationSetting: Maybe<{
      __typename?: 'NotificationSetting';
      id: Maybe<string>;
      allNotifications: Maybe<boolean>;
      onlyNewComments: Maybe<boolean>;
    }>;
  }>;
};

export type CustomerUserSessionQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerUserSessionQuery = {
  __typename?: 'Query';
  user: Maybe<{
    __typename?: 'User';
    id: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    customer: Maybe<{
      __typename?: 'Customer';
      driverCustomerRelation: Maybe<{
        __typename?: 'DriverListResponse';
        count: number;
      }>;
    }>;
    roles: Maybe<{
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
    }>;
  }>;
};

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureFlagsQuery = {
  __typename?: 'Query';
  featureFlagsList: {
    __typename?: 'FeatureFlagListResponse';
    items: Array<{
      __typename?: 'FeatureFlag';
      name: Maybe<string>;
      enabled: Maybe<boolean>;
    }>;
  };
};

export type MyAccountUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UserUpdateInput;
}>;

export type MyAccountUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    id: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    phoneNumber: Maybe<string>;
    zipCode: Maybe<string>;
    address: Maybe<string>;
    city: Maybe<string>;
    customer: Maybe<{
      __typename?: 'Customer';
      id: Maybe<string>;
      address: Maybe<{
        __typename?: 'AddressListResponse';
        items: Array<{ __typename?: 'Address'; id: Maybe<string> }>;
      }>;
    }>;
    state: Maybe<{
      __typename?: 'UsState';
      id: Maybe<string>;
      name: Maybe<string>;
    }>;
    avatar: Maybe<{
      __typename?: 'File';
      id: Maybe<string>;
      fileId: Maybe<string>;
      filename: Maybe<string>;
      shareUrl: Maybe<string>;
    }>;
  };
};

export type FileUploadInfoQueryVariables = Exact<{ [key: string]: never }>;

export type FileUploadInfoQuery = {
  __typename?: 'Query';
  fileUploadInfo: Maybe<{
    __typename?: 'FileUploadInfoResponse';
    policy: string;
    signature: string;
    apiKey: string;
    path: string;
  }>;
};

export type FileCreateMutationVariables = Exact<{
  data: FileCreateInput;
}>;

export type FileCreateMutation = {
  __typename?: 'Mutation';
  fileCreate: {
    __typename?: 'File';
    id: Maybe<string>;
    fileId: Maybe<string>;
    filename: Maybe<string>;
    shareUrl: Maybe<string>;
  };
};

export type FileCreateManyMutationVariables = Exact<{
  data: Array<Maybe<FileCreateManyInput>> | Maybe<FileCreateManyInput>;
}>;

export type FileCreateManyMutation = {
  __typename?: 'Mutation';
  fileCreateMany: {
    __typename?: 'FileManyResponse';
    items: Array<{
      __typename?: 'File';
      id: Maybe<string>;
      fileId: Maybe<string>;
    }>;
  };
};

export type FileUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: FileUpdateInput;
}>;

export type FileUpdateMutation = {
  __typename?: 'Mutation';
  fileUpdate: {
    __typename?: 'File';
    id: Maybe<string>;
    fileId: Maybe<string>;
    filename: Maybe<string>;
    shareUrl: Maybe<string>;
  };
};

export type FileDeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FileDeleteMutation = {
  __typename?: 'Mutation';
  fileDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type FileDestroyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FileDestroyMutation = {
  __typename?: 'Mutation';
  fileDestroy: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export type NotificationSettingUpdateMutationVariables = Exact<{
  id: Scalars['ID'];
  data: NotificationSettingUpdateInput;
}>;

export type NotificationSettingUpdateMutation = {
  __typename?: 'Mutation';
  notificationSettingUpdate: {
    __typename?: 'NotificationSetting';
    id: Maybe<string>;
  };
};

export type NotificationSettingCreateMutationVariables = Exact<{
  data: NotificationSettingCreateInput;
}>;

export type NotificationSettingCreateMutation = {
  __typename?: 'Mutation';
  notificationSettingCreate: {
    __typename?: 'NotificationSetting';
    id: Maybe<string>;
  };
};

export type UsStatesListQueryVariables = Exact<{ [key: string]: never }>;

export type UsStatesListQuery = {
  __typename?: 'Query';
  usStatesList: {
    __typename?: 'UsStateListResponse';
    items: Array<{
      __typename?: 'UsState';
      id: Maybe<string>;
      name: Maybe<string>;
      code: Maybe<string>;
    }>;
  };
};

export type CreateUserAuth0MutationVariables = Exact<{
  user: UserCreateInput;
}>;

export type CreateUserAuth0Mutation = {
  __typename?: 'Mutation';
  createUserAuth0: {
    __typename?: 'User';
    email: Maybe<string>;
    firstName: Maybe<string>;
  };
};

export type GetUserListQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilter>;
  sort?: Maybe<Array<UserSort> | UserSort>;
}>;

export type GetUserListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UserListResponse';
    count: number;
    items: Array<{
      __typename?: 'User';
      id: Maybe<string>;
      firstName: Maybe<string>;
      lastName: Maybe<string>;
      email: Maybe<string>;
      status: Maybe<string>;
      roles: Maybe<{
        __typename?: 'RoleListResponse';
        items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
      }>;
    }>;
  };
};

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type UserByEmailQuery = {
  __typename?: 'Query';
  user: Maybe<{ __typename?: 'User'; id: Maybe<string> }>;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: Maybe<{
    __typename?: 'User';
    id: Maybe<string>;
    email: Maybe<string>;
    firstName: Maybe<string>;
    phoneNumber: Maybe<string>;
    status: Maybe<string>;
    roles: Maybe<{
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
    }>;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  user: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'User';
    id: Maybe<string>;
    email: Maybe<string>;
    firstName: Maybe<string>;
    phoneNumber: Maybe<string>;
    status: Maybe<string>;
    roles: Maybe<{
      __typename?: 'RoleListResponse';
      items: Array<{ __typename?: 'Role'; name: Maybe<string> }>;
    }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  userInput?: Maybe<UserDeleteInput>;
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  userDelete: Maybe<{
    __typename?: 'SuccessResponse';
    success: Maybe<boolean>;
  }>;
};

export const CaseDocumentCreateDocument = gql`
  mutation CaseDocumentCreate($data: CaseDocumentCreateInput!) {
    caseDocumentCreate(data: $data) {
      id
    }
  }
`;
export type CaseDocumentCreateMutationFn = Apollo.MutationFunction<
  CaseDocumentCreateMutation,
  CaseDocumentCreateMutationVariables
>;

/**
 * __useCaseDocumentCreateMutation__
 *
 * To run a mutation, you first call `useCaseDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentCreateMutation, { data, loading, error }] = useCaseDocumentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseDocumentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseDocumentCreateMutation,
    CaseDocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseDocumentCreateMutation,
    CaseDocumentCreateMutationVariables
  >(CaseDocumentCreateDocument, options);
}
export type CaseDocumentCreateMutationHookResult = ReturnType<
  typeof useCaseDocumentCreateMutation
>;
export type CaseDocumentCreateMutationResult =
  Apollo.MutationResult<CaseDocumentCreateMutation>;
export type CaseDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  CaseDocumentCreateMutation,
  CaseDocumentCreateMutationVariables
>;
export const CaseDocumentsListDocument = gql`
  query CaseDocumentsList($filter: CaseDocumentFilter!) {
    caseDocumentsList(filter: $filter) {
      count
      items {
        id
        file {
          id
          fileId
          filename
          downloadUrl
        }
        description
      }
    }
  }
`;

/**
 * __useCaseDocumentsListQuery__
 *
 * To run a query within a React component, call `useCaseDocumentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseDocumentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseDocumentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    CaseDocumentsListQuery,
    CaseDocumentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CaseDocumentsListQuery,
    CaseDocumentsListQueryVariables
  >(CaseDocumentsListDocument, options);
}
export function useCaseDocumentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseDocumentsListQuery,
    CaseDocumentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CaseDocumentsListQuery,
    CaseDocumentsListQueryVariables
  >(CaseDocumentsListDocument, options);
}
export type CaseDocumentsListQueryHookResult = ReturnType<
  typeof useCaseDocumentsListQuery
>;
export type CaseDocumentsListLazyQueryHookResult = ReturnType<
  typeof useCaseDocumentsListLazyQuery
>;
export type CaseDocumentsListQueryResult = Apollo.QueryResult<
  CaseDocumentsListQuery,
  CaseDocumentsListQueryVariables
>;
export const CaseDocumentUpdateDocument = gql`
  mutation CaseDocumentUpdate($data: CaseDocumentUpdateInput!, $id: ID!) {
    caseDocumentUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;
export type CaseDocumentUpdateMutationFn = Apollo.MutationFunction<
  CaseDocumentUpdateMutation,
  CaseDocumentUpdateMutationVariables
>;

/**
 * __useCaseDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useCaseDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentUpdateMutation, { data, loading, error }] = useCaseDocumentUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseDocumentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseDocumentUpdateMutation,
    CaseDocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseDocumentUpdateMutation,
    CaseDocumentUpdateMutationVariables
  >(CaseDocumentUpdateDocument, options);
}
export type CaseDocumentUpdateMutationHookResult = ReturnType<
  typeof useCaseDocumentUpdateMutation
>;
export type CaseDocumentUpdateMutationResult =
  Apollo.MutationResult<CaseDocumentUpdateMutation>;
export type CaseDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  CaseDocumentUpdateMutation,
  CaseDocumentUpdateMutationVariables
>;
export const CaseDocumentDeleteDocument = gql`
  mutation CaseDocumentDelete($filter: CaseDocumentKeyFilter!) {
    caseDocumentDelete(filter: $filter) {
      success
    }
  }
`;
export type CaseDocumentDeleteMutationFn = Apollo.MutationFunction<
  CaseDocumentDeleteMutation,
  CaseDocumentDeleteMutationVariables
>;

/**
 * __useCaseDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useCaseDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentDeleteMutation, { data, loading, error }] = useCaseDocumentDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseDocumentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseDocumentDeleteMutation,
    CaseDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseDocumentDeleteMutation,
    CaseDocumentDeleteMutationVariables
  >(CaseDocumentDeleteDocument, options);
}
export type CaseDocumentDeleteMutationHookResult = ReturnType<
  typeof useCaseDocumentDeleteMutation
>;
export type CaseDocumentDeleteMutationResult =
  Apollo.MutationResult<CaseDocumentDeleteMutation>;
export type CaseDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  CaseDocumentDeleteMutation,
  CaseDocumentDeleteMutationVariables
>;
export const CaseDocumentDeleteManagementDocument = gql`
  mutation CaseDocumentDeleteManagement(
    $filter: CaseDocumentDeleteManagementFilter!
  ) {
    caseDocumentDeleteManagement(filter: $filter) {
      success
    }
  }
`;
export type CaseDocumentDeleteManagementMutationFn = Apollo.MutationFunction<
  CaseDocumentDeleteManagementMutation,
  CaseDocumentDeleteManagementMutationVariables
>;

/**
 * __useCaseDocumentDeleteManagementMutation__
 *
 * To run a mutation, you first call `useCaseDocumentDeleteManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseDocumentDeleteManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseDocumentDeleteManagementMutation, { data, loading, error }] = useCaseDocumentDeleteManagementMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseDocumentDeleteManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseDocumentDeleteManagementMutation,
    CaseDocumentDeleteManagementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseDocumentDeleteManagementMutation,
    CaseDocumentDeleteManagementMutationVariables
  >(CaseDocumentDeleteManagementDocument, options);
}
export type CaseDocumentDeleteManagementMutationHookResult = ReturnType<
  typeof useCaseDocumentDeleteManagementMutation
>;
export type CaseDocumentDeleteManagementMutationResult =
  Apollo.MutationResult<CaseDocumentDeleteManagementMutation>;
export type CaseDocumentDeleteManagementMutationOptions =
  Apollo.BaseMutationOptions<
    CaseDocumentDeleteManagementMutation,
    CaseDocumentDeleteManagementMutationVariables
  >;
export const CaseCommentCreateDocument = gql`
  mutation CaseCommentCreate($data: CaseCommentCreateInput!) {
    caseCommentCreate(data: $data) {
      id
      comment
    }
  }
`;
export type CaseCommentCreateMutationFn = Apollo.MutationFunction<
  CaseCommentCreateMutation,
  CaseCommentCreateMutationVariables
>;

/**
 * __useCaseCommentCreateMutation__
 *
 * To run a mutation, you first call `useCaseCommentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCommentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCommentCreateMutation, { data, loading, error }] = useCaseCommentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseCommentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseCommentCreateMutation,
    CaseCommentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseCommentCreateMutation,
    CaseCommentCreateMutationVariables
  >(CaseCommentCreateDocument, options);
}
export type CaseCommentCreateMutationHookResult = ReturnType<
  typeof useCaseCommentCreateMutation
>;
export type CaseCommentCreateMutationResult =
  Apollo.MutationResult<CaseCommentCreateMutation>;
export type CaseCommentCreateMutationOptions = Apollo.BaseMutationOptions<
  CaseCommentCreateMutation,
  CaseCommentCreateMutationVariables
>;
export const CaseCommentUpdateDocument = gql`
  mutation CaseCommentUpdate($id: ID!, $data: CaseCommentUpdateInput!) {
    caseCommentUpdate(filter: { id: $id }, data: $data) {
      id
      comment
    }
  }
`;
export type CaseCommentUpdateMutationFn = Apollo.MutationFunction<
  CaseCommentUpdateMutation,
  CaseCommentUpdateMutationVariables
>;

/**
 * __useCaseCommentUpdateMutation__
 *
 * To run a mutation, you first call `useCaseCommentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseCommentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseCommentUpdateMutation, { data, loading, error }] = useCaseCommentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseCommentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseCommentUpdateMutation,
    CaseCommentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseCommentUpdateMutation,
    CaseCommentUpdateMutationVariables
  >(CaseCommentUpdateDocument, options);
}
export type CaseCommentUpdateMutationHookResult = ReturnType<
  typeof useCaseCommentUpdateMutation
>;
export type CaseCommentUpdateMutationResult =
  Apollo.MutationResult<CaseCommentUpdateMutation>;
export type CaseCommentUpdateMutationOptions = Apollo.BaseMutationOptions<
  CaseCommentUpdateMutation,
  CaseCommentUpdateMutationVariables
>;
export const CaseCommentsListByCaseDocument = gql`
  query caseCommentsListByCase(
    $skip: Int
    $first: Int
    $filter: CaseCommentFilter!
  ) {
    caseCommentsList(
      skip: $skip
      first: $first
      filter: $filter
      orderBy: createdAt_DESC
    ) {
      count
      items {
        id
        comment
        mode
        createdAt
        caseRelation {
          id
        }
        replies {
          items {
            id
            comment
            createdAt
            user {
              firstName
              lastName
              avatar {
                downloadUrl
              }
            }
            createdBy {
              firstName
              lastName
              avatar {
                downloadUrl
              }
            }
          }
        }
        createdBy {
          id
          firstName
          lastName
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;

/**
 * __useCaseCommentsListByCaseQuery__
 *
 * To run a query within a React component, call `useCaseCommentsListByCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseCommentsListByCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseCommentsListByCaseQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseCommentsListByCaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    CaseCommentsListByCaseQuery,
    CaseCommentsListByCaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CaseCommentsListByCaseQuery,
    CaseCommentsListByCaseQueryVariables
  >(CaseCommentsListByCaseDocument, options);
}
export function useCaseCommentsListByCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseCommentsListByCaseQuery,
    CaseCommentsListByCaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CaseCommentsListByCaseQuery,
    CaseCommentsListByCaseQueryVariables
  >(CaseCommentsListByCaseDocument, options);
}
export type CaseCommentsListByCaseQueryHookResult = ReturnType<
  typeof useCaseCommentsListByCaseQuery
>;
export type CaseCommentsListByCaseLazyQueryHookResult = ReturnType<
  typeof useCaseCommentsListByCaseLazyQuery
>;
export type CaseCommentsListByCaseQueryResult = Apollo.QueryResult<
  CaseCommentsListByCaseQuery,
  CaseCommentsListByCaseQueryVariables
>;
export const CaseCommentDocument = gql`
  query CaseComment($id: ID!) {
    caseComment(id: $id) {
      id
      comment
    }
  }
`;

/**
 * __useCaseCommentQuery__
 *
 * To run a query within a React component, call `useCaseCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseCommentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CaseCommentQuery,
    CaseCommentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseCommentQuery, CaseCommentQueryVariables>(
    CaseCommentDocument,
    options,
  );
}
export function useCaseCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseCommentQuery,
    CaseCommentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseCommentQuery, CaseCommentQueryVariables>(
    CaseCommentDocument,
    options,
  );
}
export type CaseCommentQueryHookResult = ReturnType<typeof useCaseCommentQuery>;
export type CaseCommentLazyQueryHookResult = ReturnType<
  typeof useCaseCommentLazyQuery
>;
export type CaseCommentQueryResult = Apollo.QueryResult<
  CaseCommentQuery,
  CaseCommentQueryVariables
>;
export const CaseFollowersListDocument = gql`
  query CaseFollowersList($filter: CaseFollowerFilter!) {
    caseFollowersList(filter: $filter, orderBy: createdAt_DESC) {
      count
      items {
        id
        follower {
          id
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;

/**
 * __useCaseFollowersListQuery__
 *
 * To run a query within a React component, call `useCaseFollowersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseFollowersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseFollowersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseFollowersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    CaseFollowersListQuery,
    CaseFollowersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CaseFollowersListQuery,
    CaseFollowersListQueryVariables
  >(CaseFollowersListDocument, options);
}
export function useCaseFollowersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseFollowersListQuery,
    CaseFollowersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CaseFollowersListQuery,
    CaseFollowersListQueryVariables
  >(CaseFollowersListDocument, options);
}
export type CaseFollowersListQueryHookResult = ReturnType<
  typeof useCaseFollowersListQuery
>;
export type CaseFollowersListLazyQueryHookResult = ReturnType<
  typeof useCaseFollowersListLazyQuery
>;
export type CaseFollowersListQueryResult = Apollo.QueryResult<
  CaseFollowersListQuery,
  CaseFollowersListQueryVariables
>;
export const CaseFollowerCreateManyDocument = gql`
  mutation CaseFollowerCreateMany($data: [CaseFollowerCreateManyInput]!) {
    caseFollowerCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;
export type CaseFollowerCreateManyMutationFn = Apollo.MutationFunction<
  CaseFollowerCreateManyMutation,
  CaseFollowerCreateManyMutationVariables
>;

/**
 * __useCaseFollowerCreateManyMutation__
 *
 * To run a mutation, you first call `useCaseFollowerCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFollowerCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFollowerCreateManyMutation, { data, loading, error }] = useCaseFollowerCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseFollowerCreateManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseFollowerCreateManyMutation,
    CaseFollowerCreateManyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseFollowerCreateManyMutation,
    CaseFollowerCreateManyMutationVariables
  >(CaseFollowerCreateManyDocument, options);
}
export type CaseFollowerCreateManyMutationHookResult = ReturnType<
  typeof useCaseFollowerCreateManyMutation
>;
export type CaseFollowerCreateManyMutationResult =
  Apollo.MutationResult<CaseFollowerCreateManyMutation>;
export type CaseFollowerCreateManyMutationOptions = Apollo.BaseMutationOptions<
  CaseFollowerCreateManyMutation,
  CaseFollowerCreateManyMutationVariables
>;
export const CaseFollowerCreateDocument = gql`
  mutation CaseFollowerCreate($data: CaseFollowerCreateInput!) {
    caseFollowerCreate(data: $data) {
      id
    }
  }
`;
export type CaseFollowerCreateMutationFn = Apollo.MutationFunction<
  CaseFollowerCreateMutation,
  CaseFollowerCreateMutationVariables
>;

/**
 * __useCaseFollowerCreateMutation__
 *
 * To run a mutation, you first call `useCaseFollowerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFollowerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFollowerCreateMutation, { data, loading, error }] = useCaseFollowerCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseFollowerCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseFollowerCreateMutation,
    CaseFollowerCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseFollowerCreateMutation,
    CaseFollowerCreateMutationVariables
  >(CaseFollowerCreateDocument, options);
}
export type CaseFollowerCreateMutationHookResult = ReturnType<
  typeof useCaseFollowerCreateMutation
>;
export type CaseFollowerCreateMutationResult =
  Apollo.MutationResult<CaseFollowerCreateMutation>;
export type CaseFollowerCreateMutationOptions = Apollo.BaseMutationOptions<
  CaseFollowerCreateMutation,
  CaseFollowerCreateMutationVariables
>;
export const CaseFollowerUpdateDocument = gql`
  mutation CaseFollowerUpdate(
    $filter: CaseFollowerKeyFilter!
    $data: CaseFollowerUpdateInput!
  ) {
    caseFollowerUpdate(filter: $filter, data: $data, force: true) {
      id
    }
  }
`;
export type CaseFollowerUpdateMutationFn = Apollo.MutationFunction<
  CaseFollowerUpdateMutation,
  CaseFollowerUpdateMutationVariables
>;

/**
 * __useCaseFollowerUpdateMutation__
 *
 * To run a mutation, you first call `useCaseFollowerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseFollowerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseFollowerUpdateMutation, { data, loading, error }] = useCaseFollowerUpdateMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCaseFollowerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaseFollowerUpdateMutation,
    CaseFollowerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CaseFollowerUpdateMutation,
    CaseFollowerUpdateMutationVariables
  >(CaseFollowerUpdateDocument, options);
}
export type CaseFollowerUpdateMutationHookResult = ReturnType<
  typeof useCaseFollowerUpdateMutation
>;
export type CaseFollowerUpdateMutationResult =
  Apollo.MutationResult<CaseFollowerUpdateMutation>;
export type CaseFollowerUpdateMutationOptions = Apollo.BaseMutationOptions<
  CaseFollowerUpdateMutation,
  CaseFollowerUpdateMutationVariables
>;
export const CaseHistoriesListDocument = gql`
  query CaseHistoriesList(
    $filter: CaseHistoryFilter
    $orderBy: [CaseHistoryOrderBy!]
  ) {
    caseHistoriesList(filter: $filter, orderBy: $orderBy) {
      count
      items {
        id
        olderData
        newData
        message
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

/**
 * __useCaseHistoriesListQuery__
 *
 * To run a query within a React component, call `useCaseHistoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseHistoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseHistoriesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCaseHistoriesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseHistoriesListQuery,
    CaseHistoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CaseHistoriesListQuery,
    CaseHistoriesListQueryVariables
  >(CaseHistoriesListDocument, options);
}
export function useCaseHistoriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseHistoriesListQuery,
    CaseHistoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CaseHistoriesListQuery,
    CaseHistoriesListQueryVariables
  >(CaseHistoriesListDocument, options);
}
export type CaseHistoriesListQueryHookResult = ReturnType<
  typeof useCaseHistoriesListQuery
>;
export type CaseHistoriesListLazyQueryHookResult = ReturnType<
  typeof useCaseHistoriesListLazyQuery
>;
export type CaseHistoriesListQueryResult = Apollo.QueryResult<
  CaseHistoriesListQuery,
  CaseHistoriesListQueryVariables
>;
export const GetCasesDocument = gql`
  query getCases(
    $skip: Int
    $first: Int
    $filter: CaseFilter
    $sort: [CaseSort!]
  ) {
    casesList(skip: $skip, first: $first, filter: $filter, sort: $sort) {
      count
      items {
        id
        citationStatus
        caseID
        code
        citationDueDate
        updatedAtByAgent
        driver {
          id
          name
          lastName
          email
          licenseState
          licenseNumber
          customer {
            id
            name
            contactName
            user {
              firstName
              lastName
            }
          }
        }
        attorneyRelation {
          name
          fee
        }
        createdAt
        roadsideInspection
        trafficCitation
        inspectionCitationState {
          name
          code
          id
        }
        inspectionDate
        inspectionReportNumber
        briefStatement
        dataQsStatus
        status
        updatedAt
        customer {
          name
        }
      }
    }
  }
`;

/**
 * __useGetCasesQuery__
 *
 * To run a query within a React component, call `useGetCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCasesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCasesQuery, GetCasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options,
  );
}
export function useGetCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesQuery,
    GetCasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options,
  );
}
export type GetCasesQueryHookResult = ReturnType<typeof useGetCasesQuery>;
export type GetCasesLazyQueryHookResult = ReturnType<
  typeof useGetCasesLazyQuery
>;
export type GetCasesQueryResult = Apollo.QueryResult<
  GetCasesQuery,
  GetCasesQueryVariables
>;
export const GetCaseDocument = gql`
  query getCase($caseId: ID!) {
    case(id: $caseId) {
      id
      caseID
      citationStatus
      citationCourtNameAddress
      caseDocumentRelation {
        count
        items {
          type
          file {
            id
            fileId
            downloadUrl
            filename
          }
        }
      }
      driver {
        id
        name
        lastName
        email
        phoneNumber {
          code
          number
        }
        preferredLanguage {
          name
        }
        customer {
          id
          name
          contactName
          email
          user {
            id
          }
          driverCustomerRelation {
            items {
              id
              name
            }
          }
        }
      }
      createdAt
      roadsideInspection
      trafficCitation
      crash
      inspectionDate
      inspectionReportNumber
      briefStatement
      citationNumbers
      citationDueDate
      citationArraignment
      updatedAtByAgent
      inspectionCitationState {
        id
      }
      caseDocumentRelation {
        count
        items {
          id
          file {
            id
            fileId
            filename
            downloadUrl
          }
          type
        }
      }
      caseCaseViolationCodeRelation {
        count
        items {
          id
          violationCode {
            id
            code
            severityWeight
            description
          }
        }
      }
      attorneyRelation {
        id
        name
        fee
        email
        phone
      }
      dataQsStatus
      status
      inspectionCitationState {
        name
        code
        id
      }
      caseCaseFollowerRelation {
        items {
          id
          follower {
            email
            firstName
            lastName
            customer {
              name
              contactName
            }
            roles {
              items {
                name
              }
            }
          }
        }
      }
      customer {
        name
      }
    }
  }
`;

/**
 * __useGetCaseQuery__
 *
 * To run a query within a React component, call `useGetCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseQuery({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useGetCaseQuery(
  baseOptions: Apollo.QueryHookOptions<GetCaseQuery, GetCaseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaseQuery, GetCaseQueryVariables>(
    GetCaseDocument,
    options,
  );
}
export function useGetCaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseQuery,
    GetCaseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaseQuery, GetCaseQueryVariables>(
    GetCaseDocument,
    options,
  );
}
export type GetCaseQueryHookResult = ReturnType<typeof useGetCaseQuery>;
export type GetCaseLazyQueryHookResult = ReturnType<typeof useGetCaseLazyQuery>;
export type GetCaseQueryResult = Apollo.QueryResult<
  GetCaseQuery,
  GetCaseQueryVariables
>;
export const CreateCaseDocument = gql`
  mutation createCase($data: CaseCreateInput!) {
    caseCreate(data: $data) {
      id
      roadsideInspection
      trafficCitation
      briefStatement
      createdBy {
        id
      }
      _description
      driver {
        id
        name
        email
        customer {
          user {
            id
          }
        }
      }
    }
  }
`;
export type CreateCaseMutationFn = Apollo.MutationFunction<
  CreateCaseMutation,
  CreateCaseMutationVariables
>;

/**
 * __useCreateCaseMutation__
 *
 * To run a mutation, you first call `useCreateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseMutation, { data, loading, error }] = useCreateCaseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCaseMutation,
    CreateCaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCaseMutation, CreateCaseMutationVariables>(
    CreateCaseDocument,
    options,
  );
}
export type CreateCaseMutationHookResult = ReturnType<
  typeof useCreateCaseMutation
>;
export type CreateCaseMutationResult =
  Apollo.MutationResult<CreateCaseMutation>;
export type CreateCaseMutationOptions = Apollo.BaseMutationOptions<
  CreateCaseMutation,
  CreateCaseMutationVariables
>;
export const UpdateCaseDocument = gql`
  mutation updateCase($caseInput: CaseUpdateInput!) {
    caseUpdate(data: $caseInput, force: true) {
      id
      driver {
        id
        name
        email
      }
      createdAt
      roadsideInspection
      trafficCitation
      inspectionDate
      inspectionReportNumber
      briefStatement
      _description
    }
  }
`;
export type UpdateCaseMutationFn = Apollo.MutationFunction<
  UpdateCaseMutation,
  UpdateCaseMutationVariables
>;

/**
 * __useUpdateCaseMutation__
 *
 * To run a mutation, you first call `useUpdateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseMutation, { data, loading, error }] = useUpdateCaseMutation({
 *   variables: {
 *      caseInput: // value for 'caseInput'
 *   },
 * });
 */
export function useUpdateCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCaseMutation,
    UpdateCaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCaseMutation, UpdateCaseMutationVariables>(
    UpdateCaseDocument,
    options,
  );
}
export type UpdateCaseMutationHookResult = ReturnType<
  typeof useUpdateCaseMutation
>;
export type UpdateCaseMutationResult =
  Apollo.MutationResult<UpdateCaseMutation>;
export type UpdateCaseMutationOptions = Apollo.BaseMutationOptions<
  UpdateCaseMutation,
  UpdateCaseMutationVariables
>;
export const DeleteCaseDocument = gql`
  mutation deleteCase($deleteInput: CaseDeleteInput!) {
    caseDelete(data: $deleteInput) {
      success
    }
  }
`;
export type DeleteCaseMutationFn = Apollo.MutationFunction<
  DeleteCaseMutation,
  DeleteCaseMutationVariables
>;

/**
 * __useDeleteCaseMutation__
 *
 * To run a mutation, you first call `useDeleteCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseMutation, { data, loading, error }] = useDeleteCaseMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useDeleteCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCaseMutation,
    DeleteCaseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCaseMutation, DeleteCaseMutationVariables>(
    DeleteCaseDocument,
    options,
  );
}
export type DeleteCaseMutationHookResult = ReturnType<
  typeof useDeleteCaseMutation
>;
export type DeleteCaseMutationResult =
  Apollo.MutationResult<DeleteCaseMutation>;
export type DeleteCaseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCaseMutation,
  DeleteCaseMutationVariables
>;
export const GetApiKeyDocument = gql`
  query getApiKey {
    fileUploadInfo {
      apiKey
      policy
      signature
      path
    }
  }
`;

/**
 * __useGetApiKeyQuery__
 *
 * To run a query within a React component, call `useGetApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApiKeyQuery,
    GetApiKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(
    GetApiKeyDocument,
    options,
  );
}
export function useGetApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiKeyQuery,
    GetApiKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeyQuery, GetApiKeyQueryVariables>(
    GetApiKeyDocument,
    options,
  );
}
export type GetApiKeyQueryHookResult = ReturnType<typeof useGetApiKeyQuery>;
export type GetApiKeyLazyQueryHookResult = ReturnType<
  typeof useGetApiKeyLazyQuery
>;
export type GetApiKeyQueryResult = Apollo.QueryResult<
  GetApiKeyQuery,
  GetApiKeyQueryVariables
>;
export const GetCodesDocument = gql`
  query getCodes {
    violationCodesList {
      count
      items {
        id
        code
        severityWeight
        description
      }
    }
  }
`;

/**
 * __useGetCodesQuery__
 *
 * To run a query within a React component, call `useGetCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCodesQuery, GetCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCodesQuery, GetCodesQueryVariables>(
    GetCodesDocument,
    options,
  );
}
export function useGetCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCodesQuery,
    GetCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCodesQuery, GetCodesQueryVariables>(
    GetCodesDocument,
    options,
  );
}
export type GetCodesQueryHookResult = ReturnType<typeof useGetCodesQuery>;
export type GetCodesLazyQueryHookResult = ReturnType<
  typeof useGetCodesLazyQuery
>;
export type GetCodesQueryResult = Apollo.QueryResult<
  GetCodesQuery,
  GetCodesQueryVariables
>;
export const GetCasesStatusDocument = gql`
  query getCasesStatus(
    $skip: Int
    $first: Int
    $filter: CaseFilter
    $sort: [CaseSort!]
  ) {
    casesList(
      groupBy: {
        query: { status: { as: "statusCase" }, _group: { as: "status" } }
      }
      skip: $skip
      first: $first
      filter: $filter
      sort: $sort
    ) {
      groups {
        statusCase: String
        status: CaseGroup {
          count
        }
      }
    }
  }
`;

/**
 * __useGetCasesStatusQuery__
 *
 * To run a query within a React component, call `useGetCasesStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesStatusQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCasesStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCasesStatusQuery,
    GetCasesStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCasesStatusQuery, GetCasesStatusQueryVariables>(
    GetCasesStatusDocument,
    options,
  );
}
export function useGetCasesStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesStatusQuery,
    GetCasesStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCasesStatusQuery, GetCasesStatusQueryVariables>(
    GetCasesStatusDocument,
    options,
  );
}
export type GetCasesStatusQueryHookResult = ReturnType<
  typeof useGetCasesStatusQuery
>;
export type GetCasesStatusLazyQueryHookResult = ReturnType<
  typeof useGetCasesStatusLazyQuery
>;
export type GetCasesStatusQueryResult = Apollo.QueryResult<
  GetCasesStatusQuery,
  GetCasesStatusQueryVariables
>;
export const GetCasesStatusByMonthDocument = gql`
  query getCasesStatusByMonth(
    $skip: Int
    $first: Int
    $filter: CaseFilter
    $sort: [CaseSort!]
  ) {
    casesList(
      groupBy: {
        query: {
          createdAt: { as: "month", fn: { datePart: MONTH_NAME } }
          _group: { as: "cases" }
        }
        having: { alias: "month", date: { is_not_empty: true } }
      }
      skip: $skip
      first: $first
      filter: $filter
      sort: $sort
    ) {
      groups {
        month: String
        cases: CaseGroup {
          count
          items {
            id
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetCasesStatusByMonthQuery__
 *
 * To run a query within a React component, call `useGetCasesStatusByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesStatusByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesStatusByMonthQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCasesStatusByMonthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCasesStatusByMonthQuery,
    GetCasesStatusByMonthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCasesStatusByMonthQuery,
    GetCasesStatusByMonthQueryVariables
  >(GetCasesStatusByMonthDocument, options);
}
export function useGetCasesStatusByMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesStatusByMonthQuery,
    GetCasesStatusByMonthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCasesStatusByMonthQuery,
    GetCasesStatusByMonthQueryVariables
  >(GetCasesStatusByMonthDocument, options);
}
export type GetCasesStatusByMonthQueryHookResult = ReturnType<
  typeof useGetCasesStatusByMonthQuery
>;
export type GetCasesStatusByMonthLazyQueryHookResult = ReturnType<
  typeof useGetCasesStatusByMonthLazyQuery
>;
export type GetCasesStatusByMonthQueryResult = Apollo.QueryResult<
  GetCasesStatusByMonthQuery,
  GetCasesStatusByMonthQueryVariables
>;
export const CreateVCodeDocument = gql`
  mutation createVCode($vCode: ViolationCodeCreateInput!) {
    violationCodeCreate(data: $vCode) {
      id
      code
      severityWeight
      description
    }
  }
`;
export type CreateVCodeMutationFn = Apollo.MutationFunction<
  CreateVCodeMutation,
  CreateVCodeMutationVariables
>;

/**
 * __useCreateVCodeMutation__
 *
 * To run a mutation, you first call `useCreateVCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVCodeMutation, { data, loading, error }] = useCreateVCodeMutation({
 *   variables: {
 *      vCode: // value for 'vCode'
 *   },
 * });
 */
export function useCreateVCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVCodeMutation,
    CreateVCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVCodeMutation, CreateVCodeMutationVariables>(
    CreateVCodeDocument,
    options,
  );
}
export type CreateVCodeMutationHookResult = ReturnType<
  typeof useCreateVCodeMutation
>;
export type CreateVCodeMutationResult =
  Apollo.MutationResult<CreateVCodeMutation>;
export type CreateVCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateVCodeMutation,
  CreateVCodeMutationVariables
>;
export const CasesListCsvDocument = gql`
  query CasesListCSV($skip: Int, $first: Int, $filter: CaseFilter) {
    casesList(skip: $skip, first: $first, filter: $filter) {
      count
      items {
        id
        caseID
        inspectionDate
        driver {
          name
          lastName
          customer {
            name
          }
        }
        inspectionReportNumber
        inspectionCitationState {
          name
        }
        citationNumbers
        citationArraignment
        caseCaseViolationCodeRelation {
          items {
            violationCode {
              code
              description
              severityWeight
            }
          }
        }
        attorneyRelation {
          name
          fee
        }
        status
        dataQsStatus
        caseCaseFollowerRelation(
          filter: {
            follower: { roles: { some: { name: { equals: "App Agent" } } } }
          }
        ) {
          items {
            follower {
              firstName
              lastName
              roles {
                items {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCasesListCsvQuery__
 *
 * To run a query within a React component, call `useCasesListCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesListCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesListCsvQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCasesListCsvQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CasesListCsvQuery,
    CasesListCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CasesListCsvQuery, CasesListCsvQueryVariables>(
    CasesListCsvDocument,
    options,
  );
}
export function useCasesListCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CasesListCsvQuery,
    CasesListCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CasesListCsvQuery, CasesListCsvQueryVariables>(
    CasesListCsvDocument,
    options,
  );
}
export type CasesListCsvQueryHookResult = ReturnType<
  typeof useCasesListCsvQuery
>;
export type CasesListCsvLazyQueryHookResult = ReturnType<
  typeof useCasesListCsvLazyQuery
>;
export type CasesListCsvQueryResult = Apollo.QueryResult<
  CasesListCsvQuery,
  CasesListCsvQueryVariables
>;
export const CustomerDocumentCreateDocument = gql`
  mutation CustomerDocumentCreate($data: CustomerDocumentCreateInput!) {
    customerDocumentCreate(data: $data) {
      id
    }
  }
`;
export type CustomerDocumentCreateMutationFn = Apollo.MutationFunction<
  CustomerDocumentCreateMutation,
  CustomerDocumentCreateMutationVariables
>;

/**
 * __useCustomerDocumentCreateMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentCreateMutation, { data, loading, error }] = useCustomerDocumentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomerDocumentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerDocumentCreateMutation,
    CustomerDocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomerDocumentCreateMutation,
    CustomerDocumentCreateMutationVariables
  >(CustomerDocumentCreateDocument, options);
}
export type CustomerDocumentCreateMutationHookResult = ReturnType<
  typeof useCustomerDocumentCreateMutation
>;
export type CustomerDocumentCreateMutationResult =
  Apollo.MutationResult<CustomerDocumentCreateMutation>;
export type CustomerDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  CustomerDocumentCreateMutation,
  CustomerDocumentCreateMutationVariables
>;
export const CustomerDocumentsListDocument = gql`
  query CustomerDocumentsList($filter: CustomerDocumentFilter!) {
    customerDocumentsList(filter: $filter) {
      count
      items {
        id
        file {
          id
          fileId
          filename
          downloadUrl
        }
        description
      }
    }
  }
`;

/**
 * __useCustomerDocumentsListQuery__
 *
 * To run a query within a React component, call `useCustomerDocumentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDocumentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomerDocumentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerDocumentsListQuery,
    CustomerDocumentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomerDocumentsListQuery,
    CustomerDocumentsListQueryVariables
  >(CustomerDocumentsListDocument, options);
}
export function useCustomerDocumentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerDocumentsListQuery,
    CustomerDocumentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerDocumentsListQuery,
    CustomerDocumentsListQueryVariables
  >(CustomerDocumentsListDocument, options);
}
export type CustomerDocumentsListQueryHookResult = ReturnType<
  typeof useCustomerDocumentsListQuery
>;
export type CustomerDocumentsListLazyQueryHookResult = ReturnType<
  typeof useCustomerDocumentsListLazyQuery
>;
export type CustomerDocumentsListQueryResult = Apollo.QueryResult<
  CustomerDocumentsListQuery,
  CustomerDocumentsListQueryVariables
>;
export const CustomerDocumentUpdateDocument = gql`
  mutation CustomerDocumentUpdate(
    $data: CustomerDocumentUpdateInput!
    $id: ID!
  ) {
    customerDocumentUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;
export type CustomerDocumentUpdateMutationFn = Apollo.MutationFunction<
  CustomerDocumentUpdateMutation,
  CustomerDocumentUpdateMutationVariables
>;

/**
 * __useCustomerDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentUpdateMutation, { data, loading, error }] = useCustomerDocumentUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDocumentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerDocumentUpdateMutation,
    CustomerDocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomerDocumentUpdateMutation,
    CustomerDocumentUpdateMutationVariables
  >(CustomerDocumentUpdateDocument, options);
}
export type CustomerDocumentUpdateMutationHookResult = ReturnType<
  typeof useCustomerDocumentUpdateMutation
>;
export type CustomerDocumentUpdateMutationResult =
  Apollo.MutationResult<CustomerDocumentUpdateMutation>;
export type CustomerDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomerDocumentUpdateMutation,
  CustomerDocumentUpdateMutationVariables
>;
export const CustomerDocumentDeleteDocument = gql`
  mutation CustomerDocumentDelete($filter: CustomerDocumentKeyFilter!) {
    customerDocumentDelete(filter: $filter) {
      success
    }
  }
`;
export type CustomerDocumentDeleteMutationFn = Apollo.MutationFunction<
  CustomerDocumentDeleteMutation,
  CustomerDocumentDeleteMutationVariables
>;

/**
 * __useCustomerDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDocumentDeleteMutation, { data, loading, error }] = useCustomerDocumentDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomerDocumentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerDocumentDeleteMutation,
    CustomerDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CustomerDocumentDeleteMutation,
    CustomerDocumentDeleteMutationVariables
  >(CustomerDocumentDeleteDocument, options);
}
export type CustomerDocumentDeleteMutationHookResult = ReturnType<
  typeof useCustomerDocumentDeleteMutation
>;
export type CustomerDocumentDeleteMutationResult =
  Apollo.MutationResult<CustomerDocumentDeleteMutation>;
export type CustomerDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  CustomerDocumentDeleteMutation,
  CustomerDocumentDeleteMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation CreateCustomer($data: CustomerCreateInput!) {
    customerCreate(data: $data) {
      id
    }
  }
`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult =
  Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CustomersNamesAndIdsDocument = gql`
  query CustomersNamesAndIds($name: String!) {
    customersList(first: 5, filter: { name: { contains: $name } }) {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomersNamesAndIdsQuery__
 *
 * To run a query within a React component, call `useCustomersNamesAndIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersNamesAndIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersNamesAndIdsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCustomersNamesAndIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomersNamesAndIdsQuery,
    CustomersNamesAndIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomersNamesAndIdsQuery,
    CustomersNamesAndIdsQueryVariables
  >(CustomersNamesAndIdsDocument, options);
}
export function useCustomersNamesAndIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomersNamesAndIdsQuery,
    CustomersNamesAndIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomersNamesAndIdsQuery,
    CustomersNamesAndIdsQueryVariables
  >(CustomersNamesAndIdsDocument, options);
}
export type CustomersNamesAndIdsQueryHookResult = ReturnType<
  typeof useCustomersNamesAndIdsQuery
>;
export type CustomersNamesAndIdsLazyQueryHookResult = ReturnType<
  typeof useCustomersNamesAndIdsLazyQuery
>;
export type CustomersNamesAndIdsQueryResult = Apollo.QueryResult<
  CustomersNamesAndIdsQuery,
  CustomersNamesAndIdsQueryVariables
>;
export const CustomersListDocument = gql`
  query CustomersList(
    $skip: Int
    $first: Int
    $filter: CustomerFilter
    $sort: [CustomerSort!]
  ) {
    customersList(skip: $skip, first: $first, filter: $filter, sort: $sort) {
      items {
        id
        name
        contactName
        createdAt
        email
        archived
        status
        customerID
        user {
          firstName
          lastName
          roles {
            items {
              name
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useCustomersListQuery__
 *
 * To run a query within a React component, call `useCustomersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCustomersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomersListQuery,
    CustomersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersListQuery, CustomersListQueryVariables>(
    CustomersListDocument,
    options,
  );
}
export function useCustomersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomersListQuery,
    CustomersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersListQuery, CustomersListQueryVariables>(
    CustomersListDocument,
    options,
  );
}
export type CustomersListQueryHookResult = ReturnType<
  typeof useCustomersListQuery
>;
export type CustomersListLazyQueryHookResult = ReturnType<
  typeof useCustomersListLazyQuery
>;
export type CustomersListQueryResult = Apollo.QueryResult<
  CustomersListQuery,
  CustomersListQueryVariables
>;
export const CustomerDetailDocument = gql`
  query CustomerDetail($id: ID!) {
    customer(id: $id) {
      subscription
      id
      name
      contactName
      phoneNumber
      email
      createdAt
      status
      archived
      customerID
      user {
        roles {
          items {
            name
          }
        }
      }
      driverCustomerRelation {
        count
        items {
          id
          name
          lastName
          email
          dateOfBirth
          licenseNumber
          licenseState
          preferredLanguage {
            id
            name
          }
          phoneNumber {
            number
            code
          }
          caseDriverRelation {
            count
            items {
              id
              code
              caseID
              citationDueDate
              inspectionCitationState {
                name
                id
              }
              status
              dataQsStatus
            }
          }
        }
      }
      customerCasesRelation {
        items {
          id
          caseID
          code
          citationDueDate
          dataQsStatus
          inspectionCitationState {
            name
            code
            id
          }
          createdAt
          driver {
            id
            name
            phoneNumber {
              code
              number
            }
          }
        }
        count
      }
      address(filter: { archived: { is_empty: true } }) {
        count
        items {
          city
          state {
            id
            name
          }
          street
          id
          zipCode
          apartment
        }
      }
    }
  }
`;

/**
 * __useCustomerDetailQuery__
 *
 * To run a query within a React component, call `useCustomerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerDetailQuery,
    CustomerDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerDetailQuery, CustomerDetailQueryVariables>(
    CustomerDetailDocument,
    options,
  );
}
export function useCustomerDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerDetailQuery,
    CustomerDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerDetailQuery, CustomerDetailQueryVariables>(
    CustomerDetailDocument,
    options,
  );
}
export type CustomerDetailQueryHookResult = ReturnType<
  typeof useCustomerDetailQuery
>;
export type CustomerDetailLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailLazyQuery
>;
export type CustomerDetailQueryResult = Apollo.QueryResult<
  CustomerDetailQuery,
  CustomerDetailQueryVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress(
    $data: CustomerUpdateByFilterInput!
    $filter: CustomerFilter
  ) {
    customerUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer(
    $data: CustomerUpdateInput!
    $filter: CustomerKeyFilter
  ) {
    customerUpdate(data: $data, filter: $filter) {
      id
      user {
        id
      }
      name
      contactName
      phoneNumber
      address {
        items {
          id
          state {
            id
          }
          city
          street
          zipCode
        }
      }
    }
  }
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const DriverListWithMustViolationsDocument = gql`
  query DriverListWithMustViolations($take: Int) {
    driversList(
      groupBy: {
        first: $take
        sort: [{ alias: "numberOfViolations", direction: DESC }]
        query: {
          id: { as: "id" }
          name: { as: "name" }
          lastName: { as: "lastName" }
          caseDriverRelation: {
            createdAt: { as: "dateOfLastViolation", fn: { aggregate: MAX } }
            caseCaseViolationCodeRelation: {
              id: { as: "numberOfViolations", fn: { aggregate: COUNT } }
            }
          }
          createdAt: { as: "createdAt" }
        }
      }
    ) {
      groups {
        id: String
        name: String
        lastName: String
        numberOfViolations: Int
        dateOfLastViolation: DateTime
      }
    }
  }
`;

/**
 * __useDriverListWithMustViolationsQuery__
 *
 * To run a query within a React component, call `useDriverListWithMustViolationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverListWithMustViolationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverListWithMustViolationsQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useDriverListWithMustViolationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DriverListWithMustViolationsQuery,
    DriverListWithMustViolationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DriverListWithMustViolationsQuery,
    DriverListWithMustViolationsQueryVariables
  >(DriverListWithMustViolationsDocument, options);
}
export function useDriverListWithMustViolationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverListWithMustViolationsQuery,
    DriverListWithMustViolationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DriverListWithMustViolationsQuery,
    DriverListWithMustViolationsQueryVariables
  >(DriverListWithMustViolationsDocument, options);
}
export type DriverListWithMustViolationsQueryHookResult = ReturnType<
  typeof useDriverListWithMustViolationsQuery
>;
export type DriverListWithMustViolationsLazyQueryHookResult = ReturnType<
  typeof useDriverListWithMustViolationsLazyQuery
>;
export type DriverListWithMustViolationsQueryResult = Apollo.QueryResult<
  DriverListWithMustViolationsQuery,
  DriverListWithMustViolationsQueryVariables
>;
export const CreateDocumentDraftDocument = gql`
  mutation createDocumentDraft($data: DocumentDraftCreateInput!) {
    documentDraftCreate(data: $data) {
      id
    }
  }
`;
export type CreateDocumentDraftMutationFn = Apollo.MutationFunction<
  CreateDocumentDraftMutation,
  CreateDocumentDraftMutationVariables
>;

/**
 * __useCreateDocumentDraftMutation__
 *
 * To run a mutation, you first call `useCreateDocumentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentDraftMutation, { data, loading, error }] = useCreateDocumentDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentDraftMutation,
    CreateDocumentDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDocumentDraftMutation,
    CreateDocumentDraftMutationVariables
  >(CreateDocumentDraftDocument, options);
}
export type CreateDocumentDraftMutationHookResult = ReturnType<
  typeof useCreateDocumentDraftMutation
>;
export type CreateDocumentDraftMutationResult =
  Apollo.MutationResult<CreateDocumentDraftMutation>;
export type CreateDocumentDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentDraftMutation,
  CreateDocumentDraftMutationVariables
>;
export const UpdateDocumentDraftDocument = gql`
  mutation updateDocumentDraft($data: DocumentDraftUpdateInput!, $id: ID) {
    documentDraftUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;
export type UpdateDocumentDraftMutationFn = Apollo.MutationFunction<
  UpdateDocumentDraftMutation,
  UpdateDocumentDraftMutationVariables
>;

/**
 * __useUpdateDocumentDraftMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentDraftMutation, { data, loading, error }] = useUpdateDocumentDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentDraftMutation,
    UpdateDocumentDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDocumentDraftMutation,
    UpdateDocumentDraftMutationVariables
  >(UpdateDocumentDraftDocument, options);
}
export type UpdateDocumentDraftMutationHookResult = ReturnType<
  typeof useUpdateDocumentDraftMutation
>;
export type UpdateDocumentDraftMutationResult =
  Apollo.MutationResult<UpdateDocumentDraftMutation>;
export type UpdateDocumentDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentDraftMutation,
  UpdateDocumentDraftMutationVariables
>;
export const DeleteDocumentDraftDocument = gql`
  mutation deleteDocumentDraft($id: ID) {
    documentDraftDelete(filter: { id: $id }) {
      success
    }
  }
`;
export type DeleteDocumentDraftMutationFn = Apollo.MutationFunction<
  DeleteDocumentDraftMutation,
  DeleteDocumentDraftMutationVariables
>;

/**
 * __useDeleteDocumentDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentDraftMutation, { data, loading, error }] = useDeleteDocumentDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentDraftMutation,
    DeleteDocumentDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDocumentDraftMutation,
    DeleteDocumentDraftMutationVariables
  >(DeleteDocumentDraftDocument, options);
}
export type DeleteDocumentDraftMutationHookResult = ReturnType<
  typeof useDeleteDocumentDraftMutation
>;
export type DeleteDocumentDraftMutationResult =
  Apollo.MutationResult<DeleteDocumentDraftMutation>;
export type DeleteDocumentDraftMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentDraftMutation,
  DeleteDocumentDraftMutationVariables
>;
export const GetDocumentDraftByIdDocument = gql`
  query getDocumentDraftById($id: ID) {
    documentDraft(id: $id) {
      editorState
      documentTitle
      signers
      driver
      customer
    }
  }
`;

/**
 * __useGetDocumentDraftByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentDraftByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentDraftByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentDraftByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentDraftByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocumentDraftByIdQuery,
    GetDocumentDraftByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocumentDraftByIdQuery,
    GetDocumentDraftByIdQueryVariables
  >(GetDocumentDraftByIdDocument, options);
}
export function useGetDocumentDraftByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentDraftByIdQuery,
    GetDocumentDraftByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocumentDraftByIdQuery,
    GetDocumentDraftByIdQueryVariables
  >(GetDocumentDraftByIdDocument, options);
}
export type GetDocumentDraftByIdQueryHookResult = ReturnType<
  typeof useGetDocumentDraftByIdQuery
>;
export type GetDocumentDraftByIdLazyQueryHookResult = ReturnType<
  typeof useGetDocumentDraftByIdLazyQuery
>;
export type GetDocumentDraftByIdQueryResult = Apollo.QueryResult<
  GetDocumentDraftByIdQuery,
  GetDocumentDraftByIdQueryVariables
>;
export const GetDraftsDocument = gql`
  query getDrafts {
    documentDraftsList {
      items {
        id
        documentTitle
        driver
        customer
        signers
      }
    }
  }
`;

/**
 * __useGetDraftsQuery__
 *
 * To run a query within a React component, call `useGetDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDraftsQuery,
    GetDraftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options,
  );
}
export function useGetDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftsQuery,
    GetDraftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options,
  );
}
export type GetDraftsQueryHookResult = ReturnType<typeof useGetDraftsQuery>;
export type GetDraftsLazyQueryHookResult = ReturnType<
  typeof useGetDraftsLazyQuery
>;
export type GetDraftsQueryResult = Apollo.QueryResult<
  GetDraftsQuery,
  GetDraftsQueryVariables
>;
export const EverSignDocCreateManyDocument = gql`
  mutation everSignDocCreateMany($data: [EverSignDocCreateManyInput]!) {
    everSignDocCreateMany(data: $data) {
      count
    }
  }
`;
export type EverSignDocCreateManyMutationFn = Apollo.MutationFunction<
  EverSignDocCreateManyMutation,
  EverSignDocCreateManyMutationVariables
>;

/**
 * __useEverSignDocCreateManyMutation__
 *
 * To run a mutation, you first call `useEverSignDocCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEverSignDocCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everSignDocCreateManyMutation, { data, loading, error }] = useEverSignDocCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEverSignDocCreateManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EverSignDocCreateManyMutation,
    EverSignDocCreateManyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EverSignDocCreateManyMutation,
    EverSignDocCreateManyMutationVariables
  >(EverSignDocCreateManyDocument, options);
}
export type EverSignDocCreateManyMutationHookResult = ReturnType<
  typeof useEverSignDocCreateManyMutation
>;
export type EverSignDocCreateManyMutationResult =
  Apollo.MutationResult<EverSignDocCreateManyMutation>;
export type EverSignDocCreateManyMutationOptions = Apollo.BaseMutationOptions<
  EverSignDocCreateManyMutation,
  EverSignDocCreateManyMutationVariables
>;
export const EversingDocumentListDocument = gql`
  query eversingDocumentList {
    everSignDocsList {
      items {
        hash
      }
    }
  }
`;

/**
 * __useEversingDocumentListQuery__
 *
 * To run a query within a React component, call `useEversingDocumentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEversingDocumentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEversingDocumentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEversingDocumentListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EversingDocumentListQuery,
    EversingDocumentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EversingDocumentListQuery,
    EversingDocumentListQueryVariables
  >(EversingDocumentListDocument, options);
}
export function useEversingDocumentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EversingDocumentListQuery,
    EversingDocumentListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EversingDocumentListQuery,
    EversingDocumentListQueryVariables
  >(EversingDocumentListDocument, options);
}
export type EversingDocumentListQueryHookResult = ReturnType<
  typeof useEversingDocumentListQuery
>;
export type EversingDocumentListLazyQueryHookResult = ReturnType<
  typeof useEversingDocumentListLazyQuery
>;
export type EversingDocumentListQueryResult = Apollo.QueryResult<
  EversingDocumentListQuery,
  EversingDocumentListQueryVariables
>;
export const DriverLanguageDocument = gql`
  query DriverLanguage {
    driverLanguagesList {
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useDriverLanguageQuery__
 *
 * To run a query within a React component, call `useDriverLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriverLanguageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DriverLanguageQuery,
    DriverLanguageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriverLanguageQuery, DriverLanguageQueryVariables>(
    DriverLanguageDocument,
    options,
  );
}
export function useDriverLanguageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverLanguageQuery,
    DriverLanguageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriverLanguageQuery, DriverLanguageQueryVariables>(
    DriverLanguageDocument,
    options,
  );
}
export type DriverLanguageQueryHookResult = ReturnType<
  typeof useDriverLanguageQuery
>;
export type DriverLanguageLazyQueryHookResult = ReturnType<
  typeof useDriverLanguageLazyQuery
>;
export type DriverLanguageQueryResult = Apollo.QueryResult<
  DriverLanguageQuery,
  DriverLanguageQueryVariables
>;
export const DriverCreateDocument = gql`
  mutation DriverCreate($data: DriverCreateInput!) {
    driverCreate(data: $data) {
      id
      name
      licenseState
      licenseNumber
      lastName
      dateOfBirth
      phoneNumber {
        code
        number
      }
      preferredLanguage {
        id
      }
    }
  }
`;
export type DriverCreateMutationFn = Apollo.MutationFunction<
  DriverCreateMutation,
  DriverCreateMutationVariables
>;

/**
 * __useDriverCreateMutation__
 *
 * To run a mutation, you first call `useDriverCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDriverCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [driverCreateMutation, { data, loading, error }] = useDriverCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDriverCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DriverCreateMutation,
    DriverCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DriverCreateMutation,
    DriverCreateMutationVariables
  >(DriverCreateDocument, options);
}
export type DriverCreateMutationHookResult = ReturnType<
  typeof useDriverCreateMutation
>;
export type DriverCreateMutationResult =
  Apollo.MutationResult<DriverCreateMutation>;
export type DriverCreateMutationOptions = Apollo.BaseMutationOptions<
  DriverCreateMutation,
  DriverCreateMutationVariables
>;
export const DriversListDocument = gql`
  query DriversList(
    $skip: Int
    $first: Int
    $filter: DriverFilter
    $sort: [DriverSort!]
  ) {
    driversList(skip: $skip, first: $first, filter: $filter, sort: $sort) {
      count
      items {
        id
        name
        email
        lastName
        status
        createdAt
        customer {
          id
          name
          driverCustomerRelation {
            count
          }
        }
        phoneNumber {
          code
          number
        }
        preferredLanguage {
          name
        }
        caseDriverRelation {
          items {
            id
            code
            caseID
            createdAt
            citationDueDate
            status
            inspectionCitationState {
              name
              code
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDriversListQuery__
 *
 * To run a query within a React component, call `useDriversListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDriversListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DriversListQuery,
    DriversListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriversListQuery, DriversListQueryVariables>(
    DriversListDocument,
    options,
  );
}
export function useDriversListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriversListQuery,
    DriversListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriversListQuery, DriversListQueryVariables>(
    DriversListDocument,
    options,
  );
}
export type DriversListQueryHookResult = ReturnType<typeof useDriversListQuery>;
export type DriversListLazyQueryHookResult = ReturnType<
  typeof useDriversListLazyQuery
>;
export type DriversListQueryResult = Apollo.QueryResult<
  DriversListQuery,
  DriversListQueryVariables
>;
export const DriverDocument = gql`
  query Driver($id: ID) {
    driver(id: $id) {
      id
      name
      lastName
      dateOfBirth
      email
      archived
      status
      licenseNumber
      licenseState
      customer {
        id
        user {
          roles {
            items {
              name
            }
          }
        }
        driverCustomerRelation {
          count
        }
      }
      phoneNumber {
        code
        number
      }
      preferredLanguage {
        id
        name
      }
      caseDriverRelation {
        items {
          id
          code
          caseID
          createdAt
          citationDueDate
          status
          inspectionCitationState {
            name
            code
            id
          }
        }
      }
    }
  }
`;

/**
 * __useDriverQuery__
 *
 * To run a query within a React component, call `useDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriverQuery(
  baseOptions?: Apollo.QueryHookOptions<DriverQuery, DriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriverQuery, DriverQueryVariables>(
    DriverDocument,
    options,
  );
}
export function useDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DriverQuery, DriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriverQuery, DriverQueryVariables>(
    DriverDocument,
    options,
  );
}
export type DriverQueryHookResult = ReturnType<typeof useDriverQuery>;
export type DriverLazyQueryHookResult = ReturnType<typeof useDriverLazyQuery>;
export type DriverQueryResult = Apollo.QueryResult<
  DriverQuery,
  DriverQueryVariables
>;
export const DriverUpdateDocument = gql`
  mutation DriverUpdate($data: DriverUpdateInput!) {
    driverUpdate(data: $data) {
      id
      name
      phoneNumber {
        code
        number
      }
      preferredLanguage {
        id
      }
    }
  }
`;
export type DriverUpdateMutationFn = Apollo.MutationFunction<
  DriverUpdateMutation,
  DriverUpdateMutationVariables
>;

/**
 * __useDriverUpdateMutation__
 *
 * To run a mutation, you first call `useDriverUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDriverUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [driverUpdateMutation, { data, loading, error }] = useDriverUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDriverUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DriverUpdateMutation,
    DriverUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DriverUpdateMutation,
    DriverUpdateMutationVariables
  >(DriverUpdateDocument, options);
}
export type DriverUpdateMutationHookResult = ReturnType<
  typeof useDriverUpdateMutation
>;
export type DriverUpdateMutationResult =
  Apollo.MutationResult<DriverUpdateMutation>;
export type DriverUpdateMutationOptions = Apollo.BaseMutationOptions<
  DriverUpdateMutation,
  DriverUpdateMutationVariables
>;
export const DriverDeleteDocument = gql`
  mutation DriverDelete($id: ID!) {
    driverDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
export type DriverDeleteMutationFn = Apollo.MutationFunction<
  DriverDeleteMutation,
  DriverDeleteMutationVariables
>;

/**
 * __useDriverDeleteMutation__
 *
 * To run a mutation, you first call `useDriverDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDriverDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [driverDeleteMutation, { data, loading, error }] = useDriverDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriverDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DriverDeleteMutation,
    DriverDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DriverDeleteMutation,
    DriverDeleteMutationVariables
  >(DriverDeleteDocument, options);
}
export type DriverDeleteMutationHookResult = ReturnType<
  typeof useDriverDeleteMutation
>;
export type DriverDeleteMutationResult =
  Apollo.MutationResult<DriverDeleteMutation>;
export type DriverDeleteMutationOptions = Apollo.BaseMutationOptions<
  DriverDeleteMutation,
  DriverDeleteMutationVariables
>;
export const DriversByCustomerIdDocument = gql`
  query DriversByCustomerId($customerID: ID!) {
    driversList(filter: { customer: { id: { equals: $customerID } } }) {
      items {
        id
        name
        lastName
        email
      }
    }
  }
`;

/**
 * __useDriversByCustomerIdQuery__
 *
 * To run a query within a React component, call `useDriversByCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversByCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversByCustomerIdQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useDriversByCustomerIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriversByCustomerIdQuery,
    DriversByCustomerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DriversByCustomerIdQuery,
    DriversByCustomerIdQueryVariables
  >(DriversByCustomerIdDocument, options);
}
export function useDriversByCustomerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriversByCustomerIdQuery,
    DriversByCustomerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DriversByCustomerIdQuery,
    DriversByCustomerIdQueryVariables
  >(DriversByCustomerIdDocument, options);
}
export type DriversByCustomerIdQueryHookResult = ReturnType<
  typeof useDriversByCustomerIdQuery
>;
export type DriversByCustomerIdLazyQueryHookResult = ReturnType<
  typeof useDriversByCustomerIdLazyQuery
>;
export type DriversByCustomerIdQueryResult = Apollo.QueryResult<
  DriversByCustomerIdQuery,
  DriversByCustomerIdQueryVariables
>;
export const GetDriverDocumentsDocument = gql`
  query getDriverDocuments($id: ID!) {
    everSignDocsList(filter: { driver: { id: { equals: $id } } }) {
      items {
        hash
        driver {
          id
        }
      }
    }
  }
`;

/**
 * __useGetDriverDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDriverDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDriverDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDriverDocumentsQuery,
    GetDriverDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDriverDocumentsQuery,
    GetDriverDocumentsQueryVariables
  >(GetDriverDocumentsDocument, options);
}
export function useGetDriverDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDriverDocumentsQuery,
    GetDriverDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDriverDocumentsQuery,
    GetDriverDocumentsQueryVariables
  >(GetDriverDocumentsDocument, options);
}
export type GetDriverDocumentsQueryHookResult = ReturnType<
  typeof useGetDriverDocumentsQuery
>;
export type GetDriverDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetDriverDocumentsLazyQuery
>;
export type GetDriverDocumentsQueryResult = Apollo.QueryResult<
  GetDriverDocumentsQuery,
  GetDriverDocumentsQueryVariables
>;
export const GetDriverLicensesDocument = gql`
  query getDriverLicenses($id: ID!) {
    driverLicensesList(filter: { driverRelation: { id: { equals: $id } } }) {
      items {
        id
        file {
          id
          fileId
          downloadUrl
          filename
        }
      }
    }
  }
`;

/**
 * __useGetDriverLicensesQuery__
 *
 * To run a query within a React component, call `useGetDriverLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverLicensesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDriverLicensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDriverLicensesQuery,
    GetDriverLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDriverLicensesQuery,
    GetDriverLicensesQueryVariables
  >(GetDriverLicensesDocument, options);
}
export function useGetDriverLicensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDriverLicensesQuery,
    GetDriverLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDriverLicensesQuery,
    GetDriverLicensesQueryVariables
  >(GetDriverLicensesDocument, options);
}
export type GetDriverLicensesQueryHookResult = ReturnType<
  typeof useGetDriverLicensesQuery
>;
export type GetDriverLicensesLazyQueryHookResult = ReturnType<
  typeof useGetDriverLicensesLazyQuery
>;
export type GetDriverLicensesQueryResult = Apollo.QueryResult<
  GetDriverLicensesQuery,
  GetDriverLicensesQueryVariables
>;
export const DeleteDriverLicenseDocument = gql`
  mutation deleteDriverLicense($id: ID) {
    driverLicenseDelete(filter: { id: $id }) {
      success
    }
  }
`;
export type DeleteDriverLicenseMutationFn = Apollo.MutationFunction<
  DeleteDriverLicenseMutation,
  DeleteDriverLicenseMutationVariables
>;

/**
 * __useDeleteDriverLicenseMutation__
 *
 * To run a mutation, you first call `useDeleteDriverLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverLicenseMutation, { data, loading, error }] = useDeleteDriverLicenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriverLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDriverLicenseMutation,
    DeleteDriverLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDriverLicenseMutation,
    DeleteDriverLicenseMutationVariables
  >(DeleteDriverLicenseDocument, options);
}
export type DeleteDriverLicenseMutationHookResult = ReturnType<
  typeof useDeleteDriverLicenseMutation
>;
export type DeleteDriverLicenseMutationResult =
  Apollo.MutationResult<DeleteDriverLicenseMutation>;
export type DeleteDriverLicenseMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverLicenseMutation,
  DeleteDriverLicenseMutationVariables
>;
export const DriverNumberOfCrashesDocument = gql`
  query DriverNumberOfCrashes($id: ID!, $filter: CaseFilter) {
    driver(id: $id) {
      caseDriverRelation(filter: $filter) {
        count
      }
    }
  }
`;

/**
 * __useDriverNumberOfCrashesQuery__
 *
 * To run a query within a React component, call `useDriverNumberOfCrashesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverNumberOfCrashesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverNumberOfCrashesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDriverNumberOfCrashesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriverNumberOfCrashesQuery,
    DriverNumberOfCrashesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DriverNumberOfCrashesQuery,
    DriverNumberOfCrashesQueryVariables
  >(DriverNumberOfCrashesDocument, options);
}
export function useDriverNumberOfCrashesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverNumberOfCrashesQuery,
    DriverNumberOfCrashesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DriverNumberOfCrashesQuery,
    DriverNumberOfCrashesQueryVariables
  >(DriverNumberOfCrashesDocument, options);
}
export type DriverNumberOfCrashesQueryHookResult = ReturnType<
  typeof useDriverNumberOfCrashesQuery
>;
export type DriverNumberOfCrashesLazyQueryHookResult = ReturnType<
  typeof useDriverNumberOfCrashesLazyQuery
>;
export type DriverNumberOfCrashesQueryResult = Apollo.QueryResult<
  DriverNumberOfCrashesQuery,
  DriverNumberOfCrashesQueryVariables
>;
export const DriverViolationCodeListDocument = gql`
  query DriverViolationCodeList(
    $driverId: ID!
    $take: Int
    $caseCreatedAt: DateTime
  ) {
    violationCodesList: driversList(
      filter: { id: { equals: $driverId } }
      groupBy: {
        first: $take
        query: {
          caseDriverRelation: {
            createdAt: { as: "caseCreatedAt" }
            inspectionCitationState: { code: { as: "caseState" } }
            inspectionDate: { as: "caseDate" }
            caseCaseViolationCodeRelation: {
              violationCode: {
                code: { as: "violationCode" }
                description: { as: "violationDescription" }
                severityWeight: { as: "violationSeverityWeight" }
              }
            }
          }
        }
        sort: [{ alias: "caseCreatedAt", direction: DESC }]
        having: {
          AND: [
            { alias: "violationCode", string: { is_not_empty: true } }
            { alias: "caseCreatedAt", datetime: { gte: $caseCreatedAt } }
          ]
        }
      }
    ) {
      groups {
        caseCreatedAt: DateTime
        caseState: String
        caseDate: Date
        violationCode: String
        violationDescription: String
        violationSeverityWeight: Int
      }
    }
    totalViolationCodes: casesList(
      filter: {
        createdAt: { gte: $caseCreatedAt }
        driver: { id: { equals: $driverId } }
      }
      groupBy: {
        query: {
          caseCaseViolationCodeRelation: {
            id: { as: "count", fn: { aggregate: COUNT } }
          }
        }
        having: { alias: "count", int: { gt: 0 } }
      }
    ) {
      groups {
        count: Int
      }
    }
  }
`;

/**
 * __useDriverViolationCodeListQuery__
 *
 * To run a query within a React component, call `useDriverViolationCodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverViolationCodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverViolationCodeListQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      take: // value for 'take'
 *      caseCreatedAt: // value for 'caseCreatedAt'
 *   },
 * });
 */
export function useDriverViolationCodeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    DriverViolationCodeListQuery,
    DriverViolationCodeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DriverViolationCodeListQuery,
    DriverViolationCodeListQueryVariables
  >(DriverViolationCodeListDocument, options);
}
export function useDriverViolationCodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriverViolationCodeListQuery,
    DriverViolationCodeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DriverViolationCodeListQuery,
    DriverViolationCodeListQueryVariables
  >(DriverViolationCodeListDocument, options);
}
export type DriverViolationCodeListQueryHookResult = ReturnType<
  typeof useDriverViolationCodeListQuery
>;
export type DriverViolationCodeListLazyQueryHookResult = ReturnType<
  typeof useDriverViolationCodeListLazyQuery
>;
export type DriverViolationCodeListQueryResult = Apollo.QueryResult<
  DriverViolationCodeListQuery,
  DriverViolationCodeListQueryVariables
>;
export const NotificationsListDocument = gql`
  query NotificationsList(
    $filter: NotificationFilter!
    $orderBy: [NotificationOrderBy]
  ) {
    notificationsList(filter: $filter, orderBy: $orderBy) {
      items {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        status
        object {
          id
          resource
          case {
            id
          }
          customer {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useNotificationsListQuery__
 *
 * To run a query within a React component, call `useNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useNotificationsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotificationsListQuery,
    NotificationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationsListQuery,
    NotificationsListQueryVariables
  >(NotificationsListDocument, options);
}
export function useNotificationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsListQuery,
    NotificationsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationsListQuery,
    NotificationsListQueryVariables
  >(NotificationsListDocument, options);
}
export type NotificationsListQueryHookResult = ReturnType<
  typeof useNotificationsListQuery
>;
export type NotificationsListLazyQueryHookResult = ReturnType<
  typeof useNotificationsListLazyQuery
>;
export type NotificationsListQueryResult = Apollo.QueryResult<
  NotificationsListQuery,
  NotificationsListQueryVariables
>;
export const NotificationUpdateDocument = gql`
  mutation NotificationUpdate($id: ID!, $data: NotificationUpdateInput!) {
    notificationUpdate(filter: { id: $id }, data: $data) {
      id
    }
  }
`;
export type NotificationUpdateMutationFn = Apollo.MutationFunction<
  NotificationUpdateMutation,
  NotificationUpdateMutationVariables
>;

/**
 * __useNotificationUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUpdateMutation, { data, loading, error }] = useNotificationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationUpdateMutation,
    NotificationUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationUpdateMutation,
    NotificationUpdateMutationVariables
  >(NotificationUpdateDocument, options);
}
export type NotificationUpdateMutationHookResult = ReturnType<
  typeof useNotificationUpdateMutation
>;
export type NotificationUpdateMutationResult =
  Apollo.MutationResult<NotificationUpdateMutation>;
export type NotificationUpdateMutationOptions = Apollo.BaseMutationOptions<
  NotificationUpdateMutation,
  NotificationUpdateMutationVariables
>;
export const NotificationListSubscriptionDocument = gql`
  subscription NotificationListSubscription($filter: NotificationFilter!) {
    Notification(filter: { mutation_in: [create], node: $filter }) {
      mutation
      node {
        id
        title
        content
        user {
          id
          firstName
          lastName
          email
        }
        status
      }
    }
  }
`;

/**
 * __useNotificationListSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationListSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationListSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationListSubscriptionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationListSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NotificationListSubscriptionSubscription,
    NotificationListSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationListSubscriptionSubscription,
    NotificationListSubscriptionSubscriptionVariables
  >(NotificationListSubscriptionDocument, options);
}
export type NotificationListSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationListSubscriptionSubscription
>;
export type NotificationListSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<NotificationListSubscriptionSubscription>;
export const UserSessionDocument = gql`
  query UserSession {
    user {
      id
      lastName
      firstName
      phoneNumber
      email
      zipCode
      address
      state {
        id
        name
      }
      city
      avatar {
        id
        fileId
        filename
        downloadUrl
        shareUrl
      }
      roles {
        items {
          name
        }
      }
      customer {
        id
        name
        contactName
        customerID
        phoneNumber
        subscription
        driverCustomerRelation {
          items {
            id
            name
            lastName
            email
            createdAt
            dateOfBirth
            phoneNumber {
              code
              number
            }
            preferredLanguage {
              name
            }
          }
        }
        address {
          items {
            id
            zipCode
            street
            city
            state {
              id
              name
            }
          }
        }
      }
      notificationSetting {
        id
        allNotifications
        onlyNewComments
      }
    }
  }
`;

/**
 * __useUserSessionQuery__
 *
 * To run a query within a React component, call `useUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSessionQuery,
    UserSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSessionQuery, UserSessionQueryVariables>(
    UserSessionDocument,
    options,
  );
}
export function useUserSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSessionQuery,
    UserSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSessionQuery, UserSessionQueryVariables>(
    UserSessionDocument,
    options,
  );
}
export type UserSessionQueryHookResult = ReturnType<typeof useUserSessionQuery>;
export type UserSessionLazyQueryHookResult = ReturnType<
  typeof useUserSessionLazyQuery
>;
export type UserSessionQueryResult = Apollo.QueryResult<
  UserSessionQuery,
  UserSessionQueryVariables
>;
export const CustomerUserSessionDocument = gql`
  query CustomerUserSession {
    user {
      id
      firstName
      lastName
      customer {
        driverCustomerRelation {
          count
        }
      }
      roles {
        items {
          name
        }
      }
    }
  }
`;

/**
 * __useCustomerUserSessionQuery__
 *
 * To run a query within a React component, call `useCustomerUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerUserSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerUserSessionQuery,
    CustomerUserSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CustomerUserSessionQuery,
    CustomerUserSessionQueryVariables
  >(CustomerUserSessionDocument, options);
}
export function useCustomerUserSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerUserSessionQuery,
    CustomerUserSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustomerUserSessionQuery,
    CustomerUserSessionQueryVariables
  >(CustomerUserSessionDocument, options);
}
export type CustomerUserSessionQueryHookResult = ReturnType<
  typeof useCustomerUserSessionQuery
>;
export type CustomerUserSessionLazyQueryHookResult = ReturnType<
  typeof useCustomerUserSessionLazyQuery
>;
export type CustomerUserSessionQueryResult = Apollo.QueryResult<
  CustomerUserSessionQuery,
  CustomerUserSessionQueryVariables
>;
export const FeatureFlagsDocument = gql`
  query FeatureFlags {
    featureFlagsList {
      items {
        name
        enabled
      }
    }
  }
`;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureFlagsQuery,
    FeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
    FeatureFlagsDocument,
    options,
  );
}
export function useFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureFlagsQuery,
    FeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
    FeatureFlagsDocument,
    options,
  );
}
export type FeatureFlagsQueryHookResult = ReturnType<
  typeof useFeatureFlagsQuery
>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useFeatureFlagsLazyQuery
>;
export type FeatureFlagsQueryResult = Apollo.QueryResult<
  FeatureFlagsQuery,
  FeatureFlagsQueryVariables
>;
export const MyAccountUpdateDocument = gql`
  mutation MyAccountUpdate($id: ID!, $data: UserUpdateInput!) {
    userUpdate(data: $data, filter: { id: $id }) {
      id
      firstName
      lastName
      phoneNumber
      zipCode
      address
      customer {
        id
        address {
          items {
            id
          }
        }
      }
      state {
        id
        name
      }
      city
      avatar {
        id
        fileId
        filename
        shareUrl
      }
    }
  }
`;
export type MyAccountUpdateMutationFn = Apollo.MutationFunction<
  MyAccountUpdateMutation,
  MyAccountUpdateMutationVariables
>;

/**
 * __useMyAccountUpdateMutation__
 *
 * To run a mutation, you first call `useMyAccountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMyAccountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [myAccountUpdateMutation, { data, loading, error }] = useMyAccountUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMyAccountUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MyAccountUpdateMutation,
    MyAccountUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MyAccountUpdateMutation,
    MyAccountUpdateMutationVariables
  >(MyAccountUpdateDocument, options);
}
export type MyAccountUpdateMutationHookResult = ReturnType<
  typeof useMyAccountUpdateMutation
>;
export type MyAccountUpdateMutationResult =
  Apollo.MutationResult<MyAccountUpdateMutation>;
export type MyAccountUpdateMutationOptions = Apollo.BaseMutationOptions<
  MyAccountUpdateMutation,
  MyAccountUpdateMutationVariables
>;
export const FileUploadInfoDocument = gql`
  query FileUploadInfo {
    fileUploadInfo {
      policy
      signature
      apiKey
      path
    }
  }
`;

/**
 * __useFileUploadInfoQuery__
 *
 * To run a query within a React component, call `useFileUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileUploadInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FileUploadInfoQuery,
    FileUploadInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileUploadInfoQuery, FileUploadInfoQueryVariables>(
    FileUploadInfoDocument,
    options,
  );
}
export function useFileUploadInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileUploadInfoQuery,
    FileUploadInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileUploadInfoQuery, FileUploadInfoQueryVariables>(
    FileUploadInfoDocument,
    options,
  );
}
export type FileUploadInfoQueryHookResult = ReturnType<
  typeof useFileUploadInfoQuery
>;
export type FileUploadInfoLazyQueryHookResult = ReturnType<
  typeof useFileUploadInfoLazyQuery
>;
export type FileUploadInfoQueryResult = Apollo.QueryResult<
  FileUploadInfoQuery,
  FileUploadInfoQueryVariables
>;
export const FileCreateDocument = gql`
  mutation FileCreate($data: FileCreateInput!) {
    fileCreate(data: $data) {
      id
      fileId
      filename
      shareUrl
    }
  }
`;
export type FileCreateMutationFn = Apollo.MutationFunction<
  FileCreateMutation,
  FileCreateMutationVariables
>;

/**
 * __useFileCreateMutation__
 *
 * To run a mutation, you first call `useFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateMutation, { data, loading, error }] = useFileCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFileCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileCreateMutation,
    FileCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileCreateMutation, FileCreateMutationVariables>(
    FileCreateDocument,
    options,
  );
}
export type FileCreateMutationHookResult = ReturnType<
  typeof useFileCreateMutation
>;
export type FileCreateMutationResult =
  Apollo.MutationResult<FileCreateMutation>;
export type FileCreateMutationOptions = Apollo.BaseMutationOptions<
  FileCreateMutation,
  FileCreateMutationVariables
>;
export const FileCreateManyDocument = gql`
  mutation FileCreateMany($data: [FileCreateManyInput]!) {
    fileCreateMany(data: $data) {
      items {
        id
        fileId
      }
    }
  }
`;
export type FileCreateManyMutationFn = Apollo.MutationFunction<
  FileCreateManyMutation,
  FileCreateManyMutationVariables
>;

/**
 * __useFileCreateManyMutation__
 *
 * To run a mutation, you first call `useFileCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileCreateManyMutation, { data, loading, error }] = useFileCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFileCreateManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileCreateManyMutation,
    FileCreateManyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FileCreateManyMutation,
    FileCreateManyMutationVariables
  >(FileCreateManyDocument, options);
}
export type FileCreateManyMutationHookResult = ReturnType<
  typeof useFileCreateManyMutation
>;
export type FileCreateManyMutationResult =
  Apollo.MutationResult<FileCreateManyMutation>;
export type FileCreateManyMutationOptions = Apollo.BaseMutationOptions<
  FileCreateManyMutation,
  FileCreateManyMutationVariables
>;
export const FileUpdateDocument = gql`
  mutation FileUpdate($id: ID!, $data: FileUpdateInput!) {
    fileUpdate(data: $data, filter: { id: $id }) {
      id
      fileId
      filename
      shareUrl
    }
  }
`;
export type FileUpdateMutationFn = Apollo.MutationFunction<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;

/**
 * __useFileUpdateMutation__
 *
 * To run a mutation, you first call `useFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUpdateMutation, { data, loading, error }] = useFileUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileUpdateMutation,
    FileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileUpdateMutation, FileUpdateMutationVariables>(
    FileUpdateDocument,
    options,
  );
}
export type FileUpdateMutationHookResult = ReturnType<
  typeof useFileUpdateMutation
>;
export type FileUpdateMutationResult =
  Apollo.MutationResult<FileUpdateMutation>;
export type FileUpdateMutationOptions = Apollo.BaseMutationOptions<
  FileUpdateMutation,
  FileUpdateMutationVariables
>;
export const FileDeleteDocument = gql`
  mutation FileDelete($id: ID!) {
    fileDelete(filter: { id: $id }) {
      success
    }
  }
`;
export type FileDeleteMutationFn = Apollo.MutationFunction<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;

/**
 * __useFileDeleteMutation__
 *
 * To run a mutation, you first call `useFileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDeleteMutation, { data, loading, error }] = useFileDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileDeleteMutation,
    FileDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileDeleteMutation, FileDeleteMutationVariables>(
    FileDeleteDocument,
    options,
  );
}
export type FileDeleteMutationHookResult = ReturnType<
  typeof useFileDeleteMutation
>;
export type FileDeleteMutationResult =
  Apollo.MutationResult<FileDeleteMutation>;
export type FileDeleteMutationOptions = Apollo.BaseMutationOptions<
  FileDeleteMutation,
  FileDeleteMutationVariables
>;
export const FileDestroyDocument = gql`
  mutation FileDestroy($id: ID!) {
    fileDestroy(filter: { id: $id }) {
      success
    }
  }
`;
export type FileDestroyMutationFn = Apollo.MutationFunction<
  FileDestroyMutation,
  FileDestroyMutationVariables
>;

/**
 * __useFileDestroyMutation__
 *
 * To run a mutation, you first call `useFileDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileDestroyMutation, { data, loading, error }] = useFileDestroyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FileDestroyMutation,
    FileDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FileDestroyMutation, FileDestroyMutationVariables>(
    FileDestroyDocument,
    options,
  );
}
export type FileDestroyMutationHookResult = ReturnType<
  typeof useFileDestroyMutation
>;
export type FileDestroyMutationResult =
  Apollo.MutationResult<FileDestroyMutation>;
export type FileDestroyMutationOptions = Apollo.BaseMutationOptions<
  FileDestroyMutation,
  FileDestroyMutationVariables
>;
export const NotificationSettingUpdateDocument = gql`
  mutation NotificationSettingUpdate(
    $id: ID!
    $data: NotificationSettingUpdateInput!
  ) {
    notificationSettingUpdate(filter: { id: $id }, data: $data) {
      id
    }
  }
`;
export type NotificationSettingUpdateMutationFn = Apollo.MutationFunction<
  NotificationSettingUpdateMutation,
  NotificationSettingUpdateMutationVariables
>;

/**
 * __useNotificationSettingUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSettingUpdateMutation, { data, loading, error }] = useNotificationSettingUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationSettingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >(NotificationSettingUpdateDocument, options);
}
export type NotificationSettingUpdateMutationHookResult = ReturnType<
  typeof useNotificationSettingUpdateMutation
>;
export type NotificationSettingUpdateMutationResult =
  Apollo.MutationResult<NotificationSettingUpdateMutation>;
export type NotificationSettingUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >;
export const NotificationSettingCreateDocument = gql`
  mutation NotificationSettingCreate($data: NotificationSettingCreateInput!) {
    notificationSettingCreate(data: $data) {
      id
    }
  }
`;
export type NotificationSettingCreateMutationFn = Apollo.MutationFunction<
  NotificationSettingCreateMutation,
  NotificationSettingCreateMutationVariables
>;

/**
 * __useNotificationSettingCreateMutation__
 *
 * To run a mutation, you first call `useNotificationSettingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSettingCreateMutation, { data, loading, error }] = useNotificationSettingCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationSettingCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSettingCreateMutation,
    NotificationSettingCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationSettingCreateMutation,
    NotificationSettingCreateMutationVariables
  >(NotificationSettingCreateDocument, options);
}
export type NotificationSettingCreateMutationHookResult = ReturnType<
  typeof useNotificationSettingCreateMutation
>;
export type NotificationSettingCreateMutationResult =
  Apollo.MutationResult<NotificationSettingCreateMutation>;
export type NotificationSettingCreateMutationOptions =
  Apollo.BaseMutationOptions<
    NotificationSettingCreateMutation,
    NotificationSettingCreateMutationVariables
  >;
export const UsStatesListDocument = gql`
  query UsStatesList {
    usStatesList {
      items {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useUsStatesListQuery__
 *
 * To run a query within a React component, call `useUsStatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsStatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsStatesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsStatesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsStatesListQuery,
    UsStatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsStatesListQuery, UsStatesListQueryVariables>(
    UsStatesListDocument,
    options,
  );
}
export function useUsStatesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsStatesListQuery,
    UsStatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsStatesListQuery, UsStatesListQueryVariables>(
    UsStatesListDocument,
    options,
  );
}
export type UsStatesListQueryHookResult = ReturnType<
  typeof useUsStatesListQuery
>;
export type UsStatesListLazyQueryHookResult = ReturnType<
  typeof useUsStatesListLazyQuery
>;
export type UsStatesListQueryResult = Apollo.QueryResult<
  UsStatesListQuery,
  UsStatesListQueryVariables
>;
export const CreateUserAuth0Document = gql`
  mutation createUserAuth0($user: UserCreateInput!) {
    createUserAuth0(user: $user) {
      email
      firstName
    }
  }
`;
export type CreateUserAuth0MutationFn = Apollo.MutationFunction<
  CreateUserAuth0Mutation,
  CreateUserAuth0MutationVariables
>;

/**
 * __useCreateUserAuth0Mutation__
 *
 * To run a mutation, you first call `useCreateUserAuth0Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAuth0Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAuth0Mutation, { data, loading, error }] = useCreateUserAuth0Mutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserAuth0Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserAuth0Mutation,
    CreateUserAuth0MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserAuth0Mutation,
    CreateUserAuth0MutationVariables
  >(CreateUserAuth0Document, options);
}
export type CreateUserAuth0MutationHookResult = ReturnType<
  typeof useCreateUserAuth0Mutation
>;
export type CreateUserAuth0MutationResult =
  Apollo.MutationResult<CreateUserAuth0Mutation>;
export type CreateUserAuth0MutationOptions = Apollo.BaseMutationOptions<
  CreateUserAuth0Mutation,
  CreateUserAuth0MutationVariables
>;
export const GetUserListDocument = gql`
  query getUserList(
    $skip: Int
    $first: Int
    $filter: UserFilter
    $sort: [UserSort!]
  ) {
    usersList(skip: $skip, first: $first, filter: $filter, sort: $sort) {
      count
      items {
        id
        firstName
        lastName
        email
        status
        roles {
          items {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserListQuery,
    GetUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(
    GetUserListDocument,
    options,
  );
}
export function useGetUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserListQuery,
    GetUserListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(
    GetUserListDocument,
    options,
  );
}
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<
  typeof useGetUserListLazyQuery
>;
export type GetUserListQueryResult = Apollo.QueryResult<
  GetUserListQuery,
  GetUserListQueryVariables
>;
export const UserByEmailDocument = gql`
  query UserByEmail($email: String!) {
    user(email: $email) {
      id
    }
  }
`;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export function useUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserByEmailQuery,
    UserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(
    UserByEmailDocument,
    options,
  );
}
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<
  typeof useUserByEmailLazyQuery
>;
export type UserByEmailQueryResult = Apollo.QueryResult<
  UserByEmailQuery,
  UserByEmailQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      phoneNumber
      roles {
        items {
          name
        }
      }
      status
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($user: UserUpdateInput!) {
    userUpdate(data: $user) {
      id
      email
      firstName
      phoneNumber
      status
      roles {
        items {
          name
        }
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userInput: UserDeleteInput) {
    userDelete(data: $userInput) {
      success
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
