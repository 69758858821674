import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { DocumentTemplateFormType } from '../document-template-types';
import {
  SortOrder,
  useCustomersListQuery,
} from '../../../../shared/types/generated';

/**
 * @returns {JSX.Element} - Component.
 */
export const TemplateCustomersAutocomplete: React.FC = (): JSX.Element => {
  const methods = useFormContext<DocumentTemplateFormType>();
  const [customerKey, setCustomerKey] = useState<string>('');
  const signerLength = useRef<number>(0);
  const { control, formState, setValue, watch, clearErrors } = methods;
  const { errors } = formState;

  const { role, signers } = watch();

  // Get customers data.
  const { data } = useCustomersListQuery({
    variables: {
      sort: {
        name: SortOrder.Asc,
      },
    },
  });

  useEffect(() => {
    // This will clear the customer auto complete each time that a new signer is added.

    if (!signers) return;

    if (signers.length > signerLength.current) {
      const key = (Math.random() + 1).toString(36).substring(7);
      setCustomerKey(key);
    }

    signerLength.current = signers.length;
  }, [signers, signerLength]);

  return (
    <Controller
      control={control}
      name="customerId"
      render={({ field: { onChange } }) => (
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            key={`customer-autocomplete-${customerKey}`}
            disabled={!role}
            clearIcon={null}
            renderOption={(_props, option) => (
              <Box {..._props} key={option.id} component="li">
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.customerId)}
                helperText={errors.customerId?.message}
                label="Customer"
              />
            )}
            options={data?.customersList.items || []}
            getOptionLabel={(e) => e.name}
            onChange={(_: React.SyntheticEvent<Element, Event>, newValue) => {
              onChange(newValue?.id);
              clearErrors('driver');
              setValue('driver', undefined as unknown as Record<string, never>);
            }}
          />
        </FormControl>
      )}
    />
  );
};
