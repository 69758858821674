import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { theme as appTheme } from '../css/theme';

const gray = '#CCCCCC80';

const textInGray = '#18151880';
const textWhite = '#fff';

const StyledPill = styled('span')<{
  color: string;
  size?: 'small' | 'medium' | 'large';
}>(({ color, size = 'medium', theme }) => ({
  background: color,
  color: color === gray ? textInGray : textWhite,
  borderRadius: '14px',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  // eslint-disable-next-line
  fontSize: size === 'small' ? '10px' : size === 'medium' ? '12px' : '14px',
  maxWidth: 150,
  minWidth: 150,
  display: 'inline-block',
  [theme.breakpoints.down('md')]: {
    minWidth: '110px',
    textAlign: 'center',
    // eslint-disable-next-line
    fontSize: size === 'small' ? '8px' : size === 'medium' ? '10px' : '12px',
    padding: theme.spacing(0.5),
  },
}));

const { text: textColor, variant, delete: deleteColor } = appTheme.palette;

const blackColor = '#181518';
const orange = '#FE5722';
const yellow = '#FFB800';

const colors = [
  variant.light,
  blackColor,
  deleteColor.main,
  textColor.primary,
  orange,
  yellow,
  gray,
] as const;

type Indices<T extends readonly unknown[]> = Exclude<
  Partial<T>['length'],
  T['length']
>;

export type PillsTypeProp =
  | Indices<typeof colors>
  | Exclude<keyof typeof colors, keyof []>;

/**
 * @param root0 - Props.
 * @param root0.text - Children text.
 * @param root0.type - Type.
 * @param root0.size - Size.
 * @param root0.sx - SX.
 * @returns Component.
 */
export function Pill({
  text,
  type,
  size = 'large',
  sx,
}: {
  text: string;
  type: PillsTypeProp;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
}): JSX.Element {
  const color = colors[type];
  return (
    <StyledPill color={color} size={size} sx={sx}>
      {text}
    </StyledPill>
  );
}
