import { FormControl, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useHistory } from 'react-router-dom';
import { TicketIcon } from '../../shared/components/icons/SVGIcons';
import {
  useCustomersListQuery,
  useDriversListQuery,
  useGetCasesStatusByMonthQuery,
  useGetCasesStatusQuery,
} from '../../shared/types/generated';
import { DriverType, StatusType } from '../cases/case-types';
import { CustomInput } from '../../shared/components/inputs/CustomInput';
import { createCaseStatusFilter } from './dasboard-utils';
import { CaseStatus } from '../cases/case-models';
import { useUserHasRole } from '../session/session-hooks';
import { DriversAutocomplete } from '../../shared/components/autocompletes/DriversAutocomplete';

type StatusGroupType = {
  __typename?: 'Case' | undefined;
  status: string;
};

type GroupType = {
  __typename?: 'GroupByResponse' | undefined;
  month: string;
  cases: {
    __typename?: 'CaseListResponse' | undefined;
    count: number;
    items: {
      __typename?: 'Case' | undefined;
      status: string;
    }[];
  };
};

const StatusContainer = styled(Box)(() => ({
  height: '120px;',
  width: '100%',
  background: '#FFFFFF',
  boxShadow: '0px 3px 0px #EDEDF6',
  borderRadius: ' 8px',
  display: 'flex',
  justifyItems: 'center',
  cursor: 'pointer',
}));

const StatisticsTitle = styled(Typography)(() => ({
  color: '#3A526A',
  fontSize: '18px',
  fontWeight: 700,
  marginTop: '0px',
}));

/**
 * @returns {JSX.Element} - Dash board view.
 */
export const Dashboard: React.FC = () => {
  const [driver, setDriver] = useState<DriverType>();
  const [customer, setCustomer] = useState<string>();
  const [date, setDate] = useState('');

  const isCustomer = useUserHasRole([
    'CUSTOMER',
    'CUSTOMER_BASIC',
    'CUSTOMER_ELITE',
    'CUSTOMER_PREMIUM',
  ]);
  const history = useHistory();

  const filter = {
    driver,
    startDate: date,
    customerId: customer,
  };

  const { data, refetch } = useGetCasesStatusByMonthQuery({
    variables: {
      filter: createCaseStatusFilter({
        ...filter,
      }),
    },
  });

  const { data: statusData, refetch: refetchStatus } = useGetCasesStatusQuery({
    variables: {
      filter: createCaseStatusFilter({
        ...filter,
      }),
    },
  });

  const { refetch: refetchDrivers } = useDriversListQuery();

  const {
    data: customersData,
    refetch: refetchCustomer,
    loading: loadingCustomers,
  } = useCustomersListQuery();

  /**
   * @param {string} status - Status.
   * @returns {StatusType | undefined} - Count status.
   */
  const getCountStatus = (status: string): StatusType | undefined => {
    const countStatus = statusData?.casesList.groups.find(
      (s) => s.statusCase === status,
    );
    return countStatus;
  };

  /**
   * @param {string} status - Status.
   * @param {GroupType} group - Group Data.
   * @returns {StatusGroupType[]} - Case Status group.
   */
  const getStatusGroup = (
    status: string,
    group: GroupType,
  ): StatusGroupType[] => {
    const statusGroup = group.cases.items.filter((c) => {
      if (c.status === status) return true;
      return false;
    });
    return statusGroup;
  };

  const customers = customersData?.customersList.items;
  const casesReports = data?.casesList.groups.map((group) => {
    const newCases = getStatusGroup(CaseStatus.NEW, group);
    const inProgressCases = getStatusGroup(CaseStatus.IN_PROGRESS, group);
    const closedCases = getStatusGroup(CaseStatus.CLOSED, group);
    const inProgressCasesDOL = getStatusGroup(
      CaseStatus.IN_PROGRESS_DOL,
      group,
    );
    const inReviewCases = getStatusGroup(CaseStatus.IN_REVIEW, group);
    const awaitingCases = getStatusGroup(CaseStatus.AWAITING_PM_OR_PW, group);
    const awatingClientCases = getStatusGroup(
      CaseStatus.AWAITING_RES_CLIENT,
      group,
    );

    return {
      name: group.month,
      NEW: newCases.length,
      CLOSED: closedCases.length,
      IN_PROGRESS: inProgressCases.length,
      IN_PROGRESS_DOL: inProgressCasesDOL.length,
      IN_REVIEW: inReviewCases.length,
      AWAITING: awaitingCases.length,
      AWAITING_CLIENT: awatingClientCases.length,
    };
  });

  useEffect(() => {
    refetch();
    refetchStatus();
    refetchDrivers();
    refetchCustomer();
  }, [refetch, refetchStatus, refetchDrivers, refetchCustomer]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5} display="flex" alignItems="center">
          <StatisticsTitle variant="h2">
            General statistics of case handling
          </StatisticsTitle>
        </Grid>
        <Grid item xs={12} md={7} my={3}>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={{ xs: 2, md: 4 }}
          >
            <Box width="100%">
              <DriversAutocomplete
                label="Drivers"
                maxItems={10}
                onDriverSelected={(_driver) => {
                  setDriver(_driver as DriverType);
                }}
              />
            </Box>
            {isCustomer ? null : (
              <FormControl
                disabled={loadingCustomers}
                style={{ width: '100%' }}
                variant="outlined"
              >
                <CustomInput
                  style={{ margin: '0px' }}
                  onChange={(e) => {
                    const customerSelected = customers?.find(
                      (c) => c.id === e.target.value,
                    );
                    if (customerSelected) setCustomer(customerSelected.id);
                    else {
                      setCustomer(undefined);
                    }
                  }}
                  select
                  label="Customers"
                >
                  <MenuItem>Select...</MenuItem>
                  {customers?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </CustomInput>
              </FormControl>
            )}
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
              }}
            >
              <CustomInput
                style={{ margin: '0px' }}
                label="Date"
                onChange={(e) => {
                  setDate(e.target.value ?? '');
                }}
                type="date"
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.NEW}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#5BC2BA',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  New cases
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.NEW)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.IN_PROGRESS}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#181518',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  In progress cases
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.IN_PROGRESS)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.IN_PROGRESS_DOL}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#181518',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  In progress cases DOL
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.IN_PROGRESS_DOL)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.IN_REVIEW}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#FFB800',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  In review cases
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.IN_REVIEW)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.AWAITING_PM_OR_PW}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#FE5722',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  Awaiting cases
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.AWAITING_PM_OR_PW)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.AWAITING_RES_CLIENT}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#FE5722',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  Awaiting client
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.AWAITING_RES_CLIENT)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12} md={3}>
          <StatusContainer
            onClick={() => {
              history.push(`/cases?status=${CaseStatus.CLOSED}`);
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  backgroundColor: '#CCCCCC80',
                  borderRadius: '50%',
                  height: '60px',
                  padding: '10px 12px 10px 12px',
                  margin: '0px 15px',
                }}
              >
                <TicketIcon sx={{ fontSize: 40, color: '#fff' }} />
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="h2"
                  sx={{ fontSize: '16px', marginBottom: '10px' }}
                >
                  Closed cases
                </Typography>
                <Typography variant="body1">
                  {getCountStatus(CaseStatus.CLOSED)?.status.count}
                </Typography>
              </Box>
            </Box>
          </StatusContainer>
        </Grid>
        <Grid item xs={12}>
          <Box mt="40px" display="flex" justifyContent="space-between">
            <Typography color="primary" sx={{ fontSize: '18px' }}>
              Cases report
            </Typography>
            <Box display="flex" flexDirection="row">
              <li
                color="primary"
                style={{
                  fontSize: '18px',
                  color: '#5BC2BA',
                  margin: '0px 10px',
                }}
              >
                New Cases
              </li>
              <li
                color="primary"
                style={{ fontSize: '18px', color: '#CCCCCC80' }}
              >
                Closed cases
              </li>
            </Box>
          </Box>
          <ResponsiveContainer height={350}>
            <BarChart data={casesReports}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="NEW" barSize={16} radius={7} fill="#5BC2BA" />
              <Bar
                dataKey="IN_PROGRESS"
                barSize={16}
                radius={7}
                fill="#181518"
              />
              <Bar dataKey="CLOSED" barSize={16} radius={7} fill="#18151880" />
              <Bar
                dataKey="IN_PROGRESS_DOL"
                barSize={16}
                radius={7}
                fill="#181518"
              />
              <Bar dataKey="IN_REVIEW" barSize={16} radius={7} fill="#FFB800" />
              <Bar dataKey="AWAITING" barSize={16} radius={7} fill="#FE5722" />
              <Bar
                dataKey="AWAITING_CLIENT"
                barSize={16}
                radius={7}
                fill="#FE5722"
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
