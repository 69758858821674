import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LeftArrowIcon from '../../shared/assets/images/left-arrow.svg';
import { WEBHOOK_EMAIL_RESET_PASSWORD_AUTH0 } from '../../shared/constants';

const Main = styled('main')({
  display: 'table',
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: '#E5E5E5',
});

const Container = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
});

const Wrapper = styled('div')({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '300px',
  borderRadius: '7px',
  backgroundColor: '#fff',
  overflow: 'hidden',
});

const Logo = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  margin: '0',
  objectFit: 'contain',
});

const HeaderBlur = styled('div')({
  opacity: 0.4,
  transition: '1s ease 1s',
  display: 'block',
});

const HeaderContainer = styled('div')({
  backgroundColor: '#3A526A',
  padding: '11px',
  position: 'relative',
});

const GoBackButton = styled('span')({
  left: '14px',
  boxSizing: 'content-box',
  background: '#fff',
  borderRadius: '100px',
  height: '10px',
  width: '10px',
  padding: '0',
  position: 'absolute',
  top: '14px',
  right: '14px',
  boxShadow: '0 1px 3px rgb(0 0 0 / 10%)',
  color: '#333',
  zIndex: '100',
  border: '6px solid #fff',
  cursor: 'pointer',
  lineHeight: '0',
});

const Body = styled('div')({
  padding: '20px',
});

const Text = styled('p')({
  fontSize: '13px',
  textAlign: 'center',
  marginBottom: '15px',
  lineHeight: '1.8',
  color: 'rgba(0,0,0,0.54)',
});

const InputContainer = styled('div')({
  borderRadius: '3px',
  border: '1px solid #f1f1f1',
  position: 'relative',
  background: '#f1f1f1',
  transition: 'border-color 0.8s',
});

const Input = styled('input')({
  border: '0',
  padding: '0 14px',
  right: '0',
  height: '40px',
  fontSize: '13px',
  width: '100%',
  borderRadius: '0 2px 2px 0',
  boxSizing: 'border-box',
  position: 'relative',
  color: 'rgba(0,0,0,0.87)',
});

const SubmitButton = styled('button')({
  backgroundColor: 'rgb(91, 194, 186)',
  display: 'block',
  border: '0',
  padding: '14px',
  boxSizing: 'border-box',
  width: '100%',
  overflow: 'hidden',
  borderRadius: '0 0 5px 5px',
  transition: '0.2s ease-in-out',
  color: '#fff',
  letterSpacing: '1px',
  fontSize: '14px',
  textTransform: 'uppercase',
  '-ms-flex-negative': '0',
  flexShrink: '0',
  '-ms-flex-positive': '0',
  flexGrow: '0',
  '-ms-flex-preferred-size': 'auto',
  flexBasis: 'auto',
  cursor: 'pointer',
  height: '70px',
});

const TextError = styled('span')({
  color: '#f00',
  fontSize: '12px',
  marginTop: '5px',
  whiteSpace: 'nowrap',
});

const SuccessfulWrapper = styled('div')({
  height: 382.938,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
});

const SuccessfulText = styled('p')({
  textAlign: 'center',
  maring: 0,
});

/**
 * @returns {JSX.Element} - Component.
 */
export function AuthRecoverPassword(): JSX.Element {
  const [email, setEmail] = useState<{
    value: string;
    isValid: boolean;
  }>({
    value: '',
    isValid: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const history = useHistory();

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   * @returns {void} - Nothing.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const isValid = yup.string().email().isValidSync(value);
    setEmail({
      value,
      isValid,
    });
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   * @returns {Promise<void>} - Nothing.
   */
  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    setIsSubmitted(true);
    setLoading(true);

    if (!email.value.length || !email.isValid) return;
    try {
      // Send the email.
      await fetch(WEBHOOK_EMAIL_RESET_PASSWORD_AUTH0, {
        method: 'POST',
        body: JSON.stringify({
          email: email.value,
        }),
      });

      setSuccess(true); // Show the successful page.
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Delay for 3 seconds
      history.push('/auth'); // Redict to login page
    } catch (error) {
      // Error...
      console.log(error);
    }

    setLoading(false);
  };

  /**
   * @returns {void} - Nothing.
   */
  const GoBack = (): void => {
    history.push('/auth');
  };

  return (
    <Main>
      <Container>
        <Wrapper>
          {!success ? (
            <div>
              <HeaderContainer>
                <GoBackButton onClick={GoBack}>
                  <img src={LeftArrowIcon} alt="Left Arrow Icon" />
                </GoBackButton>
                <HeaderBlur />
                <Logo
                  src="https://main.durcr9xm9ia67.amplifyapp.com/Asset.png"
                  alt="FCP"
                />
              </HeaderContainer>
              <form onSubmit={onSubmit}>
                <Body>
                  <Text>
                    Please enter your email address. We will send you an email
                    to reset your password.
                  </Text>
                  <InputContainer>
                    <Input
                      type="email"
                      name="email"
                      placeholder="yours@example.com"
                      onChange={handleChange}
                    />
                  </InputContainer>
                  {!email.value.length && isSubmitted ? (
                    <TextError>Email can not be blank</TextError>
                  ) : null}
                  {email.value.length && !email.isValid && isSubmitted ? (
                    <TextError>Email is invalid</TextError>
                  ) : null}
                </Body>
                <SubmitButton type="submit" disabled={loading}>
                  {loading && (
                    <CircularProgress size={12} style={{ marginRight: 5 }} />
                  )}
                  <span>SEND EMAIL</span>
                </SubmitButton>
              </form>
            </div>
          ) : (
            <SuccessfulWrapper>
              <div style={{ textAlign: 'center' }}>
                <CheckCircleOutlineIcon
                  style={{ color: '#a0bb51', fontSize: '90px' }}
                />
                <SuccessfulText>
                  check your email to reset your password
                </SuccessfulText>
              </div>
            </SuccessfulWrapper>
          )}
        </Wrapper>
      </Container>
    </Main>
  );
}
