import { FC, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import { YellowButton, SplitButton } from '../../../shared/components/buttons';
import { DocumentDialog } from './DocumentDialog';
import { OptionsCreateDocument } from '../document-types';

const DropdownOptions = ['by Attachment', 'Templates', 'Custom'];

type Options = 'Send' | 'Navigation' | 'View';

interface Params {
  options: Options[];
  viewText?: string;
  sendText?: string;
  hideBtnView?: boolean;
  hideBtnSend?: boolean;
  hideBtnAux?: boolean;
  onViewClick?: () => unknown;
  onSendClick?: () => unknown;
  onAuxClick?: () => unknown;
  disableButtons?: boolean;
}

/**
 * Document Button Navigation.
 *
 * @param {string[]} options - Options.
 * @returns {JSX.Element} - Customers View.
 */
export const DocumentButtonNavigation: FC<Params> = ({
  options,
  viewText = 'Show Document',
  sendText = 'Send Document',
  hideBtnView = false,
  hideBtnSend = false,
  hideBtnAux = false,
  onViewClick,
  onSendClick,
  onAuxClick,
  disableButtons,
}): JSX.Element => {
  const history = useHistory();
  const disabled = typeof disableButtons === 'boolean' && disableButtons;

  const { sendDisplay, navigationDisplay, viewDisplay } = useMemo(
    () => ({
      navigationDisplay: options.some((e) => e === 'Navigation')
        ? 'flex'
        : 'none',
      sendDisplay: options.some((e) => e === 'Send') ? 'flex' : 'none',
      viewDisplay: options.some((e) => e === 'View') ? 'flex' : 'none',
    }),
    [options],
  );

  /**
   * Handle Split Button Click.
   *
   * @param {string} value - String.
   * @returns {void} Void.
   */
  const handleSplitButtonSelection = (value: string): void => {
    if (value === OptionsCreateDocument.BY_ATTACHMENT)
      history.push('/document-management/attach');
    if (value === OptionsCreateDocument.CUSTOM)
      history.push('/document-management/create');
    if (value === OptionsCreateDocument.TEMPLATES) {
      history.push('/document-management/template');
    }
  };

  return (
    <Stack spacing={2} direction="row" justifyContent="center">
      <DocumentDialog />
      <span style={{ display: navigationDisplay }}>
        <SplitButton
          preventSelection
          handleSelection={handleSplitButtonSelection}
          color="secondary"
          startIcon={<AddIcon />}
          options={DropdownOptions}
          defaultOption="Create Document"
        />
      </span>
      {!hideBtnView && (
        <YellowButton
          disabled={disabled}
          style={{ display: viewDisplay }}
          variant="contained"
          color="warning"
          startIcon={<DownloadIcon />}
          onClick={onViewClick}
        >
          {viewText}
        </YellowButton>
      )}
      {!hideBtnSend && (
        <Button
          disabled={disabled}
          style={{ display: sendDisplay }}
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          onClick={onSendClick}
        >
          {sendText}
        </Button>
      )}
      {!hideBtnAux && (
        <Button variant="contained" color="secondary" onClick={onAuxClick}>
          Cancel
        </Button>
      )}
    </Stack>
  );
};
