export const WORKSPACE_ID = process.env.REACT_APP_WORKSPACE_ID;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME || '';
export const WORKSPACE_ENDPOINT = `https://api.8base.com/${WORKSPACE_ID}${
  ENVIRONMENT_NAME.toLowerCase() === 'master' || ENVIRONMENT_NAME === ''
    ? ''
    : `_${ENVIRONMENT_NAME}`
}`;
export const AUTH_PROFILE_ID = process.env.REACT_APP_AUTH0_PROFILE_ID;
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH_CLIENT_DOMAIN =
  process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '';
export const EIGHTBASE_WS_ENDPOINT = 'wss://ws.8base.com';
export const AUTH_CLIENT_REDIRECT_URI = `${window.location.origin}/auth/callback`;
export const AUTH_CLIENT_REDIRECT_LOGOUT = `${window.location.origin}/logout`;
export const AUTH0_CONNECTION_NAME =
  process.env.REACT_APP_AUTH0_CONNECTION_NAME || '';
export const COMMINT_HASH = process.env.COMMINT_HASH || '';
export const ADMIN_TOKEN = process.env.ADMIN_TOKEN || '';
export const FIELDWORKER_TOKEN = process.env.FIELDWORKER_TOKEN || '';
export const WEBHOOK_EMAIL_RESET_PASSWORD_AUTH0 = `${WORKSPACE_ENDPOINT}/webhook/email-reset-password-auth0`;

export const EVERSIGN_API_KEY = process.env.REACT_APP_EVERSIGN_API_KEY || '';
export const EVERSIGN_API_URL = process.env.REACT_APP_EVERSIGN_API_URL || '';
export const EVERSIGN_BUSINESS_ID = process.env.REACT_APP_EVERSIGN_BUSINESS_ID
  ? parseInt(process.env.REACT_APP_EVERSIGN_BUSINESS_ID)
  : 0;

// Circular Process sizes
export const CIRCULAR_PROCESS_LARGE_SIZE = 100;
export const CIRCULAR_PROCESS_MEDIUM_SIZE = 50;

// Form Messages
export const REQUIRED_FORM_MESSAGE = 'This field is required';
export const INVALID_EMAIL_MESSAGE = 'Invalid email format';
