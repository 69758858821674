import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

/**
 * @description - Component to display unsupported states code message.
 * @returns {JSX.Element} - Unsupported states code message.
 */
export const UnsupportedStatesCode: React.FC = (): JSX.Element => (
  <Alert severity="warning">
    <AlertTitle>Warning</AlertTitle>
    This MVR cannot be requested. Please make your request in{' '}
    <a href="https://www.mvrnow.com" target="_blank" rel="noopener noreferrer">
      <strong>mvrnow.com</strong>
    </a>
  </Alert>
);
