import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { createStyles, withStyles } from '@mui/styles';

export const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      padding: '10px 30px',
      borderBottom: '0px',
      color: '#181518',
      fontfamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      letterSpacing: '0.01em',
    },
    body: {
      borderBottom: '0px',
      padding: '10px 30px',
      color: grey[500],
      fontSize: '14px',
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
  }),
)(TableRow);
