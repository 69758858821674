import { useForm, UseFormReturn, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackbar } from '../../../shared/components/Snackbar';
import { useSubscribeError } from '../../../shared/hooks/hooks';
import { ATTACH_DOCUMENT_DIALOG_FORM_VALIDATOR_SCHEMA } from './Document-attach-models';
import { AttachDocumentDialogFormType } from './Document-attach-types';

/**
 * @param {UseFormProps} formOptions - Form props.
 * @returns Form hook state.
 */
export function useDocumentAttachmentForm(
  formOptions?: UseFormProps,
): UseFormReturn<AttachDocumentDialogFormType> {
  const data = useForm<AttachDocumentDialogFormType>({
    ...formOptions,
    resolver: yupResolver(ATTACH_DOCUMENT_DIALOG_FORM_VALIDATOR_SCHEMA, {}),
  });

  useSubscribeError(data, () => {
    snackbar.error('Validation error');
  });

  return data;
}
