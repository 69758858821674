import React from 'react';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { ROUTES } from '../../routes/routes-model';
import { useUserSessionQuery } from '../types/generated';
import { Notifications } from '../../modules/notifications/Notifications';
import { version } from '../../../package.json';

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(0)]: {
    display: 'none',
  },
  [theme.breakpoints.up(500)]: {
    display: 'initial',
  },
}));

/**
 * @returns Component.
 */
export const Topbar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { data: currentUserData } = useUserSessionQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * @returns {void} - Nothing.
   */
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  /**
   * @returns NavBarTitle.
   */
  const getTitle = (): string => {
    const title = Object.values(ROUTES).find(
      (i) => i.path.slice(0, 3) === location.pathname.slice(0, 3),
    );
    if (title) {
      return title.text;
    }
    return '404';
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          gridArea: 'topbar',
          background: '#3A526A',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: ['space-between'],
          flexDirection: 'row',
          padding: '10px 20px',
          paddingLeft: '80px',
          alignItems: 'center',
          color: 'icons.main',
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.white?.main }}>
          {getTitle()}
        </Typography>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box display="flex" alignItems="center">
            <Wrapper textAlign="right">
              <Typography
                sx={{ fontSize: '14px', color: theme.palette.white?.main }}
              >
                {currentUserData?.user.firstName}{' '}
                {currentUserData?.user.lastName}
              </Typography>
              <Typography
                sx={{ fontSize: '12px', color: theme.palette.white?.main }}
              >
                {currentUserData?.user.roles.items[0].name}
              </Typography>
            </Wrapper>
            <Avatar
              alt="Remy Sharp"
              src={currentUserData?.user?.avatar?.downloadUrl}
              sx={{ width: '50px', height: '50px', marginLeft: '10px' }}
            />
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted={false}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  history.push('/user-management/settings/my-account');
                }}
              >
                My Account
              </MenuItem>
              <MenuItem>Version: {version}</MenuItem>
            </Menu>
          </Box>

          <Divider orientation="vertical" />

          <Notifications />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => history.push('/logout')}
            color="inherit"
          >
            <ExitToAppOutlinedIcon />
          </IconButton>
        </Box>
      </AppBar>
    </>
  );
};
