import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateCaseMutation, useUserSessionQuery } from '../types/generated';

interface CaseLogParams {
  caseId?: string;
}

interface CaseLogReturns {
  createALogUpdatedBy: () => void;
}

/**
 * @function useCaseLog - A hook to record the log action over the case in particular.
 * @description - A hook to record the log action over the case in particular.
 * @param {CaseLogParams} props - Properties.
 * @returns {CaseLogReturns} - Functions.
 */
export const useCaseLog = (props?: CaseLogParams): CaseLogReturns => {
  const { id } = useParams<{ id: string }>();
  const { data } = useUserSessionQuery({}); // User information.

  const [caseId, setCaseId] = useState<string | undefined>(props?.caseId || id);
  const [updateCaseMutation] = useUpdateCaseMutation();

  // If the case id if not explicit set, then check the url param.
  useEffect(() => {
    if (!props?.caseId) {
      setCaseId(id);
    }
    // eslint-disable-next-line
  }, [props?.caseId]);

  /**
   * @returns {void} - Nothing to return.
   */
  const createALogUpdatedBy = (): void => {
    if (!caseId || !data) return;

    updateCaseMutation({
      variables: {
        caseInput: {
          id: caseId,
          updatedBy: data?.user.id,
        },
      },
    });
  };

  return { createALogUpdatedBy };
};
