import React from 'react';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { Controller, useFormContext } from 'react-hook-form';
import {
  customerDriverSubs,
  customerDriverSubsSelectOptions,
} from '../../customer/customer-models';
import { CustomSelect } from '../../../shared/components/selects/CustomSelect';
import { CustomerDriverFormType } from '../customer-driver-types';
import { Title } from '../../../shared/components/typography/Title';

/**
 * @returns {JSX.Element} - Customer driver subs form.
 */
export const CustomerDriverSubsForm: React.FC = (): JSX.Element => {
  // Form state.
  const methods = useFormContext<CustomerDriverFormType>();
  const { formState, control } = methods;

  return (
    <Stack direction="column" spacing={3} marginTop={3}>
      <Title>Subscription</Title>
      <FormControl fullWidth variant="outlined">
        <Controller
          name="subscription"
          control={control}
          render={({ field }) => (
            <CustomSelect
              size="medium"
              {...field}
              defaultValue={customerDriverSubs.basic}
              options={customerDriverSubsSelectOptions}
              helperText={formState.errors.subscription?.message}
              error={Boolean(formState.errors.subscription?.message)}
              style={{ margin: 0, marginBottom: 24 }}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
};
