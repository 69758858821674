import { IconButton, Badge } from '@mui/material';
import * as React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useHistory } from 'react-router-dom';
import { NotificationMenu, NotificationMenuRef } from './NotificationMenu';
import {
  Notification,
  NotificationFilter,
  NotificationListSubscriptionDocument,
  NotificationOrderBy,
  useNotificationsListQuery,
  useNotificationUpdateMutation,
  useUserSessionQuery,
} from '../../shared/types/generated';
import { clearQueriesFromCache } from '../../shared/utils';
import { ROUTES } from '../../routes/routes-model';
import { NotificationStatusEnum } from './notification-types';

/**
 * @returns {JSX.Element} - Component.
 */
export const Notifications: React.FC = () => {
  // Notification menu ref.
  const notificationMenuRef = React.useRef<NotificationMenuRef>(null);

  const history = useHistory();

  // User data.
  const { data: userData } = useUserSessionQuery();

  // eslint-disable-next-line
  const filter: NotificationFilter = {
    user: {
      id: {
        equals: userData?.user.id,
      },
    },
  };

  // Notifications data.
  const { data, subscribeToMore, refetch } = useNotificationsListQuery({
    variables: {
      filter,
      orderBy: [NotificationOrderBy.CreatedAtDesc],
    },
    pollInterval: 5000,
  });

  // Mutate notifications.
  const [mutationNotificationUpdate] = useNotificationUpdateMutation({
    update: clearQueriesFromCache(['notification', 'notificationsList']),
  });

  /**
   * @param {Notification} notification - Notification.
   * @returns {void} - Nothing.
   */
  const redirection = (notification: Notification): void => {
    if (!notification || !notification.object) return;

    if (notification.object.case) {
      history.push(
        ROUTES.CASES.internalPaths.CASE_DETAILS.path.replace(
          ':id',
          String(notification.object.case.id),
        ),
      );
    }

    if (notification.object.customer) {
      history.push(
        ROUTES.Customers.internalPaths.CUSTOMER_DETAIL.path.replace(
          ':id',
          String(notification.object.customer.id),
        ),
      );
    }

    mutationNotificationUpdate({
      variables: {
        id: notification.id as string,
        data: {
          status: NotificationStatusEnum.VIEWED,
        },
      },
    });
  };

  React.useEffect(() => {
    subscribeToMore({
      document: NotificationListSubscriptionDocument,
      variables: {
        filter,
      },
      /**
       * @param {any} newData - Data.
       * @returns Data.
       */
      updateQuery: (newData) => {
        refetch();
        return newData;
      },
    });
  }, [refetch, subscribeToMore, filter]);

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={notificationMenuRef.current?.open}
      >
        <Badge
          badgeContent={
            data?.notificationsList.items.filter(
              (notification) => notification.status === 'UNVIEWED',
            ).length
          }
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <NotificationMenu
        ref={notificationMenuRef}
        onClick={redirection}
        notifications={
          data
            ? data.notificationsList.items.filter(
                (notification) => notification.status === 'UNVIEWED',
              )
            : []
        }
      />
    </div>
  );
};
