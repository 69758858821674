import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/routes-model';
import { Pagination } from '../../shared/components/table/Pagination';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../shared/components/table/TableRowStyled';
import { PaperStyled } from '../../shared/components/card/Card';
import {
  SortOrder,
  useCustomersListQuery,
  useUpdateCustomerMutation,
} from '../../shared/types/generated';
import { Pill } from '../../shared/components/Pills';
import { clearQueriesFromCache, humanizeDate } from '../../shared/utils';
import { usePagination, useUrlParams } from '../../shared/hooks/hooks';
import { CustomersListFilters, CustomerStatusEnum } from './customer-types';
import { createCustomerListFilter } from './customer-utils';
import { ModalFilter } from './components/ModalFilters';
import { TableActions } from '../../shared/components/table/TableActions';
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
} from '../../shared/components/ConfirmationDialog';
import { TableRowLoading } from '../../shared/components/table/TableRowLoading';
import { snackbar } from '../../shared/components/Snackbar';
import {
  DefaultRowSize,
  TableToolbar,
} from '../../shared/components/table/TableToolbar';

/**
 * @returns {JSX.Element} - Customers View.
 */
export const Customers: React.FC = () => {
  // History hooks.
  const history = useHistory();

  // Hook to manage url params for filter.
  const { setUrlFilterParams, clearSpecificUrlParams, query } = useUrlParams();

  // Ref.
  const confirmationDialogRef = React.useRef<ConfirmationDialogRef | null>(
    null,
  );

  // Menu.
  const [menu, setMenu] = useState<{
    anchorEl: HTMLElement | null;
    open: boolean;
    customer: string;
  }>({
    anchorEl: null,
    open: false,
    customer: '',
  });

  // Menu filters.
  const [menuFilters, setMenuFilters] = useState<{
    anchorEl: HTMLElement | null;
    open: boolean;
  }>({
    anchorEl: null,
    open: false,
  });

  // Pagination logic.
  const [{ page, pageSize }, setPage] = usePagination({
    defaultPage: 1,
  });

  // Filter.
  const [filters, setFilters] = useState<CustomersListFilters>({
    search: '',
    from: '',
    to: '',
    status: '',
  });

  // Setup the default url params values
  useEffect(() => {
    setUrlFilterParams([
      { key: 'pageSize', value: query.get('pageSize') ?? String(pageSize) },
      {
        key: 'search',
        value: query.get('search'),
      },
      {
        key: 'from',
        value: query.get('from'),
      },
      {
        key: 'to',
        value: query.get('to'),
      },
      {
        key: 'status',
        value: query.get('status'),
      },
    ]);

    // Set default values come from url params.
    setFilters({
      search: query.get('search') ?? '',
      from: query.get('from') ?? '',
      to: query.get('to') ?? '',
      status: query.get('status') ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {string} id - Driver id.
   */
  const navigateToCustomerDetails = (id: string): void => {
    history.push(
      ROUTES.Customers.internalPaths.CUSTOMER_DETAIL.path.replace(
        ':id',
        String(id),
      ),
    );
  };

  /**
   * @param type - Type of input.
   * @param value - Value.
   */
  const handleChange = (
    type: keyof CustomersListFilters,
    value: CustomersListFilters[keyof CustomersListFilters],
  ): void => {
    setUrlFilterParams({ key: type, value });
    setFilters((prev) => ({ ...prev, [type]: value }));
  };

  // Request customer list.
  const { data } = useCustomersListQuery({
    variables: {
      first: pageSize,
      skip: (page - 1) * pageSize,
      filter: createCustomerListFilter(filters),
      sort: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
    },
  });

  // To update status.
  const [updateCustomerMutation] = useUpdateCustomerMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      confirmationDialogRef.current?.onClose();
      snackbar.success('Customer status updated successfully');
    },
    /**
     * @param cache - Apollo cache.
     */
    update: clearQueriesFromCache(['customersList']),
  });

  // Reset pagination after filters change. ♻️
  useEffect(() => {
    // Reset pagination
    setPage({
      page: 1,
    });
    setUrlFilterParams({ key: 'page', value: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pageSize]);

  return (
    <Box mt="20px" padding="0px 10px">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableToolbar
            defaultRow={pageSize as DefaultRowSize}
            onSelectRow={(v) => {
              setPage({ pageSize: v });
            }}
            onSearch={(v) => handleChange('search', v)}
            rightComponent={
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  history.push(
                    ROUTES.Customers.internalPaths.CUSTOMER_CREATE.path,
                  )
                }
                sx={{
                  float: 'right',
                  width: {
                    xs: '100%',
                    md: 200,
                  },
                }}
                startIcon={<AddIcon />}
              >
                New customer
              </Button>
            }
            menuButtonComponent={
              <IconButton
                onClick={(e) => {
                  setMenuFilters({ anchorEl: e.currentTarget, open: true });
                }}
              >
                <AddRoadIcon sx={{ color: '#000' }} />
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PaperStyled>
            <TableContainer sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Customer ID</StyledTableCell>
                    <StyledTableCell>Company Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell width="auto">
                      Customer Since
                    </StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.customersList ? (
                    data.customersList?.items.map((customer) => (
                      <StyledTableRow key={customer.id} hover>
                        <StyledTableCell
                          onClick={() =>
                            navigateToCustomerDetails(String(customer.id))
                          }
                        >
                          {customer.customerID}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigateToCustomerDetails(String(customer.id))
                          }
                        >
                          {customer.name}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigateToCustomerDetails(String(customer.id))
                          }
                        >
                          {customer.email}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigateToCustomerDetails(String(customer.id))
                          }
                          style={
                            {
                              // backgroundColor: 'red',
                            }
                          }
                          sx={
                            {
                              // // backgroundColor: 'red'
                              // width: '50px !important',
                              // maxWidth: '50px !important',
                            }
                          }
                          align="center"
                        >
                          <Pill
                            text={customer.status.toUpperCase()}
                            size="medium"
                            sx={{
                              width: '100%',
                              maxWidth: '100%',
                              minWidth: '100%',
                            }}
                            type={
                              customer.status ===
                              CustomerStatusEnum.Disabled.toLocaleLowerCase()
                                ? 2
                                : 0
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigateToCustomerDetails(String(customer.id))
                          }
                        >
                          {humanizeDate(String(customer.createdAt))}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TableActions
                            options={[
                              {
                                label: 'Edit',
                                value: 'edit',
                              },
                              {
                                label:
                                  customer.status ===
                                  CustomerStatusEnum.Active.toLocaleLowerCase()
                                    ? 'Disabled'
                                    : 'Active',
                                value: customer.status,
                              },
                            ]}
                            onChange={(option) => {
                              switch (option.value) {
                                case 'edit':
                                  history.push(
                                    `${ROUTES.Customers.path}/edit/${customer.id}`,
                                  );
                                  break;
                                default:
                                  setMenu({
                                    ...menu,
                                    customer: customer.id,
                                    open: false,
                                  });
                                  confirmationDialogRef.current?.onOpen();
                                  break;
                              }
                            }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRowLoading loading colSpan={6} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperStyled>
          <Pagination
            page={page}
            pageSize={pageSize}
            totalCount={data?.customersList.count ?? 0}
            onChange={(value) => setPage({ page: value })}
          />
        </Grid>
        <ConfirmationDialog
          ref={confirmationDialogRef}
          title="Are you sure?"
          description={`This customer will be ${
            data?.customersList.items.find(
              (customer) => customer.id === menu.customer,
            )?.status === CustomerStatusEnum.Active.toLocaleLowerCase()
              ? 'Disabled'
              : 'Active'
          }`}
          confirmButtonText={
            data?.customersList.items.find(
              (customer) => customer.id === menu.customer,
            )?.status === CustomerStatusEnum.Active.toLocaleLowerCase()
              ? 'Disabled'
              : 'Active'
          }
          onConfirm={() => {
            const customer = data?.customersList.items.find(
              (element) => element.id === menu.customer,
            );

            // Update customer status.
            if (customer)
              updateCustomerMutation({
                variables: {
                  data: {
                    id: customer.id,
                    status:
                      customer.status ===
                      CustomerStatusEnum.Active.toLocaleLowerCase()
                        ? CustomerStatusEnum.Disabled.toLocaleLowerCase()
                        : CustomerStatusEnum.Active.toLocaleLowerCase(),
                  },
                },
              });
          }}
        />
        <ModalFilter
          open={menuFilters.open}
          onClose={() => {
            // Clear url params.
            clearSpecificUrlParams(['to', 'from', 'status']);

            // Clear filters state.
            setFilters({
              from: '',
              to: '',
              status: '',
            });

            // Close popover filter.
            setMenuFilters({ ...menuFilters, open: false });
          }}
          anchorEl={menuFilters.anchorEl}
          filters={filters}
          setFilters={(v) => {
            setFilters(v);
            setUrlFilterParams(
              Object.entries(v).map(([key, value]) => ({ key, value })),
            );
            setMenuFilters({ ...menuFilters, open: false });
          }}
        />
      </Grid>
    </Box>
  );
};
