import { useRef, useState, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { isValidEmail } from '@cobuildlab/validation-utils';
import draftToHtml from 'draftjs-to-html';
import type { RawDraftContentState } from 'react-draft-wysiwyg';

import { cleanHTMLFromString } from '../../../shared/utils';
import { useUrlParams } from '../../../shared/hooks/hooks';
import { snackbar } from '../../../shared/components/Snackbar';
import {
  useCustomersListQuery,
  SortOrder,
  Customer,
  Driver,
  useCreateDocumentDraftMutation,
  CreateDocumentDraftMutation,
  useUpdateDocumentDraftMutation,
  useGetDocumentDraftByIdLazyQuery,
  useDeleteDocumentDraftMutation,
  useUserSessionQuery,
} from '../../../shared/types/generated';

import { useUserHasRole } from '../../session/session-hooks';
import { useCustomDriversByCustomerIdLazyQuery } from '../document-hooks';

import { DocumentCreationFormType } from './document-creation-types';
import { createPdfDocument } from '../document-actions';
import {
  previewPdf,
  handleDriverSelection,
  shouldAddSigner,
} from '../document-utils';
import { CustomDocumentForm } from './components/CustomDocumentForm';
import { DraftListDialog } from './components/DraftListDialog';
import { DocumentDialog } from '../components/DocumentDialog';

import { useDocumentCreationForm } from './document-creation-hooks';
import {
  createVariablesCreateDocumentDraft,
  createVariablesUpdateDocumentDraft,
} from './document-creation-utils';

/**
 * @returns {JSX.Element} - Customers View.
 */
export const CreateDocumentsView = (): JSX.Element => {
  const [defaultEditorStateRef, setDefaultEditorStateRef] =
    useState<RawDraftContentState>();
  const [uploadingDoc, setUploadingDoc] = useState<boolean>(false);
  const editorRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [opeDialog, setOpenDialog] = useState<boolean>(false);
  const [driverInputSwitch, setDriverInputSwitch] = useState<boolean>(false);

  const history = useHistory();
  const { query } = useUrlParams();
  const [draftId, setDraftId] = useState(query.get('draftId'));
  const { data: userData } = useUserSessionQuery();
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');
  const formMethods = useDocumentCreationForm();

  const { handleSubmit, watch, setValue, getValues, setError, clearErrors } =
    formMethods;

  const {
    customer,
    signerEmail,
    signerName,
    signerLastName,
    driver,
    description,
    documentTitle,
    signers,
  } = watch();

  const { driversCalled, driversData, loadingDrivers } =
    useCustomDriversByCustomerIdLazyQuery(customer);

  const { loading: customersLoading, data: customersData } =
    useCustomersListQuery({
      variables: {
        sort: {
          name: SortOrder.Asc,
        },
      },
    });

  const [getDraftyId, { refetch, called: draftCalled }] =
    useGetDocumentDraftByIdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [createDraft] = useCreateDocumentDraftMutation({
    /**
     *
     * @param {CreateDocumentDraftMutation} data - Mutation data.
     */
    onCompleted: (data) => {
      snackbar.success('Document drafted');
      history.push({ search: `draftId=${data.documentDraftCreate.id}` });
    },
    /**
     *
     */
    onError: () => {
      snackbar.error('Error drafting the document');
    },
  });

  const [deleteDraft] = useDeleteDocumentDraftMutation({
    /**
     *
     */
    onError: () => {
      snackbar.error('Error deleting the draft');
    },
  });

  const [updateDraft] = useUpdateDocumentDraftMutation({
    /**
     *
     */
    onCompleted: () => {
      snackbar.success('Document updated');
    },
    /**
     *
     */
    onError: () => {
      snackbar.error('Error drafting the document');
    },
  });

  useEffect(() => {
    if (isCustomerElite) setValue('customer', userData?.user.customer.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite, setValue]);

  /**
   * Load the draft from 8base and populate the inputs.
   *
   * @param {string} id - Draft id.
   */
  const loadDraft = async (id: string): Promise<void> => {
    let data;

    if (draftCalled) {
      const { data: dataRefetch } = await refetch({ id });
      data = dataRefetch;
    } else {
      const { data: firstData } = await getDraftyId({ variables: { id } });
      data = firstData;
    }

    if (!data) return;
    const { documentDraft } = data;
    if (!documentDraft) return;

    if (documentDraft.signers)
      setValue('signers', JSON.parse(documentDraft.signers as string));
    if (documentDraft.documentTitle)
      setValue('documentTitle', documentDraft.documentTitle);
    if (documentDraft.editorState) {
      const defaultState = JSON.parse(
        documentDraft.editorState as string,
      ) as RawDraftContentState;
      setDefaultEditorStateRef(defaultState);
    }

    // since setState doesn't run asynchronously, a setTimeout is necessary for setting the loading state at the end.
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (customersLoading || loadingDrivers || !loading) return;

    if (!draftId) {
      setLoading(false);
    } else {
      loadDraft(draftId);
    }

    // eslint-disable-next-line
  }, [customersLoading, loadingDrivers, loading, draftId]);

  const isEmptyDescription = useMemo(() => {
    if (!description) return true;

    const draftHTML = draftToHtml(description);
    const cleanText = cleanHTMLFromString(draftHTML);

    return cleanText === '';
  }, [description]);

  /**
   * Submit.
   *
   * @param {DocumentCreationFormType} data - Data.
   * @param {boolean} saveInDraft - SaveInDraft.
   */
  const submit = async (
    data: DocumentCreationFormType,
    saveInDraft: boolean,
  ): Promise<void> => {
    setUploadingDoc(true);

    const shouldCreate = saveInDraft && !draftId;
    const shouldUpdate = saveInDraft && draftId;

    if (shouldCreate) {
      const res = await createDraft({
        variables: {
          data: createVariablesCreateDocumentDraft(data),
        },
      });
      setDraftId(res.data?.documentDraftCreate.id as string);
    }

    if (shouldUpdate) {
      await updateDraft({
        variables: {
          data: createVariablesUpdateDocumentDraft(data),
          id: draftId,
        },
      });
    }

    if (!saveInDraft) {
      await createPdfDocument({
        ...data,
        isDraft: false,
        HTMLElement: editorRef.current as HTMLElement,
      });
      if (draftId) {
        await deleteDraft({
          variables: {
            id: draftId,
          },
        });
      }
    }

    setUploadingDoc(false);
  };

  /**
   * Handles the preview pdf action.
   */
  const onPreviewClick = (): void => {
    const draftHTML = draftToHtml(description);
    const div = document.createElement('div');
    div.innerHTML = draftHTML;
    previewPdf(div);
  };

  /**
   * @param {Customer} customerInput - Event.
   */
  const onCustomerSelection = (customerInput: Customer | null): void => {
    if (customerInput) {
      setValue('customer', customerInput?.id as string);
      setValue('driver', '');
    }
  };

  /**
   * Handles the pdf creation.
   *
   * @param {boolean} isDraft - Should be drafted.
   */
  const savePdfToDraft = (isDraft: boolean): void => {
    handleSubmit((data) => submit(data, isDraft))();
  };

  /**
   * @param {Driver} driverInput - Event.
   */
  const onDriverSelection = (driverInput: Driver | null): void => {
    const previousSigners = getValues('signers') || [];

    const { selectedDriver, signer } = handleDriverSelection({
      driverInput,
      driversData,
      previousSigners,
    });

    if (selectedDriver) setValue('driver', selectedDriver.id);

    if (signer && selectedDriver) {
      setValue('signerName', selectedDriver.name as string);
      setValue('signerEmail', selectedDriver.email as string);
      setValue('signerLastName', selectedDriver.lastName as string);
    }
  };

  /**
   *
   */
  const handleClearCustomerData = (): void => {
    setValue('signerEmail', undefined);
    setValue('signerName', undefined);
    setValue('driver', undefined);
    if (!isCustomerElite) setValue('customer', undefined);
  };

  /**
   *
   * @param {string} value - Draft id.
   */
  const onDraftSelected = (value: string): void => {
    setDraftId(value);
    setLoading(true);
    history.push({ search: `draftId=${value}` });
    setOpenDialog(false);
  };

  /**
   */
  const handleAddAdditionalSigner = (): void => {
    const previousSigners = getValues('signers') || [];

    const signerDataComplete = signerEmail && signerName;

    if (!signerDataComplete) return;

    if (!isValidEmail(signerEmail)) {
      setError('signerEmail', { message: 'Invalid email format' });
      return;
    }

    clearErrors('signerEmail');

    const shouldAdd = shouldAddSigner({
      previousSigners,
      signerEmail,
    });

    if (shouldAdd) {
      setValue('signers', [
        ...previousSigners,
        {
          email: signerEmail.trim(),
          name: signerName.trim(),
          lastName: signerLastName?.trim(),
          driverId: driver,
        },
      ]);
      handleClearCustomerData();
    }
  };

  /**
   * @param {string} email - Signer email.
   */
  const handleDeleteAdditionalSigner = (email: string): void => {
    const previousSigners = getValues('signers') || [];

    const filterList = previousSigners.filter((e) => e.email !== email);

    setValue('signers', filterList);
  };

  /**
   *
   */
  const toggleDriverSwitch = (): void => {
    clearErrors();
    handleClearCustomerData();
    setDriverInputSwitch(!driverInputSwitch);
  };

  /**
   *
   */
  const onCancel = (): void => {
    history.push('/document-management');
  };

  const driversSelectIsDisabled =
    !driversCalled || loadingDrivers || !customer || customersLoading;

  const sendDisabled =
    isEmptyDescription ||
    uploadingDoc ||
    !documentTitle ||
    !signers ||
    signers.length === 0;

  return (
    <FormProvider {...formMethods}>
      <DocumentDialog onCloseCallback={onCancel} />
      <DraftListDialog
        onConfirm={onDraftSelected}
        open={opeDialog}
        onCancel={() => setOpenDialog(false)}
      />
      <CustomDocumentForm
        sendDisabled={sendDisabled}
        toggleDriverSwitch={toggleDriverSwitch}
        driverInputSwitch={driverInputSwitch}
        handleDeleteAdditionalSigner={handleDeleteAdditionalSigner}
        handleAddAdditionalSigner={handleAddAdditionalSigner}
        loading={loading}
        defaultState={defaultEditorStateRef}
        driversSelectIsDisabled={driversSelectIsDisabled}
        editorRef={editorRef}
        onOpenDraftClick={() => setOpenDialog(true)}
        onCustomerSelection={onCustomerSelection}
        onDriverSelection={onDriverSelection}
        onPreviewClick={onPreviewClick}
        savePdfToDraft={savePdfToDraft}
        uploadingDoc={uploadingDoc}
        customersData={customersData}
        driversData={driversData}
      />
    </FormProvider>
  );
};
