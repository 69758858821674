import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { PRIMARY_LIGHT } from '../../../shared/css/theme';

interface Props {
  driverId: string;
}

/**
 * @param {Props} Props - Props.
 * @returns {JSX.Element} DriverAutoCompleteError.
 */
export const DriverAutoCompleteError: FC<Props> = ({
  driverId,
}): JSX.Element => (
  <>
    This Driver does not have an associated email in the system, please add a
    valid email to the driver&apos;s profile.{' '}
    <Link style={{ color: PRIMARY_LIGHT }} to={`/drivers/edit/${driverId}`}>
      Go to driver&apos;s Profile
    </Link>
  </>
);
