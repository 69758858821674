import * as yup from 'yup';
import { REQUIRED_FORM_MESSAGE } from '../../../shared/constants';

export const DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA = yup
  .object({
    signerEmail: yup.string().email('Invalid email format'),
    signerName: yup.string(),
    signerLastName: yup.string().notRequired(),
    customerId: yup.string(),
    driver: yup.mixed(),
    documentTitle: yup.string().required(REQUIRED_FORM_MESSAGE),
    templateId: yup.string().required(REQUIRED_FORM_MESSAGE),
    role: yup.string(),
    signers: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required(),
          lastName: yup.string().notRequired(),
          email: yup.string().email('Invalid email format').required(),
          role: yup.string(),
          driverId: yup.string().optional(),
        }),
      )
      .required(),
  })
  .required();
