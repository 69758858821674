import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { useCustomDriversByCustomerIdLazyQuery } from '../../document-hooks';
import { DriverAutoCompleteError } from '../../components/DriverAutocompleteError';
import { DocumentTemplateFormType } from '../document-template-types';
import { useCustomersListQuery } from '../../../../shared/types/generated';

/**
 * @returns {JSX.Element} - Component.
 */
export const TemplateDriversAutocomplete: React.FC = (): JSX.Element => {
  const methods = useFormContext<DocumentTemplateFormType>();
  const [driverId, setDriverId] = useState<string>('');
  const { control, formState, watch, setError, clearErrors } = methods;
  const { errors } = formState;

  const { customerId, role } = watch();

  const { loading: customersLoading } = useCustomersListQuery();
  const { driversData, driversCalled, loadingDrivers } =
    useCustomDriversByCustomerIdLazyQuery(customerId);

  const driverErrorMsg = !!errors.driver && (
    <DriverAutoCompleteError driverId={driverId} />
  );

  const disabled =
    !driversCalled ||
    loadingDrivers ||
    !customerId ||
    customersLoading ||
    !role;

  return (
    <Controller
      name="driver"
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            // There's no clean way of clearing the autocomplete, so a new key is assigned to reset its state.
            key={`${customerId}-key`}
            clearIcon={null}
            disabled={disabled}
            renderOption={(_props, option) => (
              <Box {..._props} key={option.id} component="li">
                {option.name} {option.lastName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.driver)}
                helperText={driverErrorMsg}
                label="Drivers"
              />
            )}
            options={driversData?.driversList.items || []}
            getOptionLabel={(e) => `${e.name} ${e.lastName || ''}`}
            onChange={(_: React.SyntheticEvent<Element, Event>, newValue) => {
              if (!newValue?.email) {
                setDriverId(newValue?.id as string);
                setError('driver', {
                  message: 'Driver Email Required',
                  type: 'required',
                });
              } else {
                clearErrors('driver');
                onChange(newValue);
              }
            }}
          />
        </FormControl>
      )}
    />
  );
};
