import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface CDSubmitBtnsProps {
  submitBtn?: {
    text?: string;
    loading?: boolean;
  };
}

/**
 * @param {CDSubmitBtnsProps} props - Props.
 * @returns {JSX.Element} - Component.
 */
export const CDSubmitBtns: React.FC<CDSubmitBtnsProps> = ({
  submitBtn,
}): JSX.Element => {
  const history = useHistory();
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        justifyContent: ['space-between', 'flex-end'],
        gridColumn: 'span 2',
        marginTop: '20px',
        marginBottom: '20px',
        '&>*': {
          marginLeft: 2,
        },
      }}
    >
      <Button
        variant="outlined"
        onClick={() => {
          history.goBack();
        }}
      >
        Cancel
      </Button>
      <LoadingButton
        loading={submitBtn?.loading}
        variant="contained"
        color="secondary"
        style={{ marginLeft: 24 }}
        type="submit"
      >
        {submitBtn?.text || 'Submit'}
      </LoadingButton>
    </Box>
  );
};
