import { UseFormProps, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackbar } from '../../../shared/components/Snackbar';
import { useSubscribeError } from '../../../shared/hooks/hooks';
import { DocumentCreationFormType } from './document-creation-types';
import { DOCUMENT_CREATION_FORM_VALIDATOR_SCHEMA } from './document-creation-models';

/**
 * @param {UseFormProps} formOptions - Form props.
 * @returns Form hook state.
 */
export function useDocumentCreationForm(
  formOptions?: UseFormProps,
): UseFormReturn<DocumentCreationFormType> {
  const data = useForm<DocumentCreationFormType>({
    ...formOptions,
    resolver: yupResolver(DOCUMENT_CREATION_FORM_VALIDATOR_SCHEMA, {}),
  });

  useSubscribeError(data, (e) => {
    console.log(data, e);
    snackbar.error('Validation error');
  });

  return data;
}
