import { useEffect, useState, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isValidEmail } from '@cobuildlab/validation-utils';
import { useUserSessionQuery } from '../../../shared/types/generated';
import { useUserHasRole } from '../../session/session-hooks';

import { useCustomDriversByCustomerIdLazyQuery } from '../document-hooks';
import { handleDriverSelection, shouldAddSigner } from '../document-utils';
import { sendDocument } from './document-template-actions';
import { DocumentTemplateForm } from './components/DocumentTemplateForm';
import {
  useDocumentTemplateForm,
  useEversingTemplates,
} from './document-template-hooks';
import { mapRoles } from './document-template-utils';

/**
 * DocumentTemplateView.
 *
 * @returns {JSX.Element} DocumentTemplateView.
 */
export const DocumentTemplateView = (): JSX.Element => {
  const [roles, setRoles] = useState<string[]>([]);
  const [sendingDoc, setSendingDoc] = useState<boolean>(false);
  const [driverInputSwitch, setDriverInputSwitch] = useState<boolean>(false);
  const formMethods = useDocumentTemplateForm();
  const { data: userData } = useUserSessionQuery();
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');

  const { watch, setValue, getValues, handleSubmit, setError, clearErrors } =
    formMethods;

  const {
    templateId,
    signers,
    customerId,
    role: roleSelected,
    driver,
    signerEmail,
    signerName,
    signerLastName,
  } = watch();

  const {
    data: templates,
    refetch: getTemplates,
    isFetching,
    isLoading,
  } = useEversingTemplates();

  const history = useHistory();

  const { driversData } = useCustomDriversByCustomerIdLazyQuery(customerId);

  useEffect(() => {
    if (isCustomerElite) setValue('customerId', userData?.user.customer.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite, setValue]);

  // Update the roles when a template is selected.
  useEffect(() => {
    if (templateId && templates) {
      setValue('signers', []);
      const result = templates.find(
        (template) => template?.templateId?.documentHash === templateId,
      );

      if (result) {
        setRoles([...new Set(mapRoles(result))]);
      }
    }
  }, [templateId, templates, setValue]);

  useEffect(() => {
    if (driver) {
      const previousSigners = getValues('signers') || [];

      const { selectedDriver, signer } = handleDriverSelection({
        driverInput: driver,
        driversData,
        previousSigners,
        selectedRole: roleSelected,
      });

      if (selectedDriver)
        setValue('driver', selectedDriver as Record<string, never>);

      if (signer && selectedDriver) {
        setValue('signerName', selectedDriver.name as string);
        setValue('signerEmail', selectedDriver.email as string);
        setValue('signerLastName', selectedDriver.lastName as string);
      }
    }
  }, [driver, driversData, getValues, roleSelected, setValue]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  /**
   *
   */
  const handleClearCustomerData = (): void => {
    setValue('signerEmail', undefined);
    setValue('signerName', undefined);
    setValue('driver', undefined);
    if (!isCustomerElite) setValue('customerId', undefined);
  };

  /**
   */
  const handleAddAdditionalSigner = (): void => {
    const previousSigners = getValues('signers') || [];

    const signerDataComplete = signerEmail && signerName && roleSelected;

    if (!signerDataComplete) return;

    if (!isValidEmail(signerEmail)) {
      setError('signerEmail', { message: 'Invalid email format' });
      return;
    }

    clearErrors('signerEmail');

    const shouldAdd = shouldAddSigner({
      previousSigners,
      roleSelected,
      signerEmail,
    });

    if (shouldAdd) {
      setValue('signers', [
        ...previousSigners,
        {
          email: signerEmail.trim(),
          name: signerName.trim(),
          lastName: signerLastName?.trim(),
          role: roleSelected,
          driverId: driver ? driver.id : undefined,
        },
      ]);

      setValue('role', undefined);
      handleClearCustomerData();
    }
  };

  /**
   * @param {string} email - Signer email.
   */
  const handleDeleteAdditionalSigner = (email: string): void => {
    const previousSigners = getValues('signers') || [];

    const filterList = previousSigners.filter((e) => e.email !== email);

    setValue('signers', filterList);
  };

  const allRolesSelected = useMemo(() => {
    if (!roles || !signers) return false;
    if (roles.length === 0 || signers.length === 0) return false;

    const allRolesFound = roles.every((role) =>
      signers.some((signer) => signer.role === role),
    );

    return allRolesFound;
  }, [roles, signers]);

  /**
   * @description - On submit event.
   * @returns {void} - Nothing.
   */
  const onSubmit = async (): Promise<void> => {
    setSendingDoc(true);
    await handleSubmit(sendDocument)();
    history.push('/document-management');
    setSendingDoc(false);
  };

  /**
   *
   */
  const toggleDriverSwitch = (): void => {
    clearErrors();
    handleClearCustomerData();
    setDriverInputSwitch(!driverInputSwitch);
  };

  const sendButtonDisabled = Boolean(
    !allRolesSelected || isLoading || isFetching,
  );

  const submitHelperText =
    !allRolesSelected && signers
      ? `Select all roles before submit ${signers?.length}/${roles.length}`
      : null;

  return (
    <FormProvider {...formMethods}>
      <DocumentTemplateForm
        sendingDoc={sendingDoc}
        templates={templates}
        roles={roles}
        handleAddAdditionalSigner={handleAddAdditionalSigner}
        handleDeleteAdditionalSigner={handleDeleteAdditionalSigner}
        toggleDriverSwitch={toggleDriverSwitch}
        onSubmit={onSubmit}
        sendButtonDisabled={sendButtonDisabled}
        driverInputSwitch={driverInputSwitch}
        submitHelperText={submitHelperText}
      />
    </FormProvider>
  );
};
