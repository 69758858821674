import { createEvent } from '@cobuildlab/react-simple-state';
import { DocumentTypeEnum, DocumentUIStateProps } from './document-types';

type DialogTypes = null | 'attachment' | 'prepare';

export const OnDocumentModal = createEvent<{
  modalType: DialogTypes;
  embeddedClaimUrl?: null | string;
}>({
  initialValue: {
    modalType: null,
    embeddedClaimUrl: null,
  },
});

export const OnDocumentUIStateEvent = createEvent<DocumentUIStateProps>({
  initialValue: {
    filter: {
      search: '',
      rows: 10,
    },
    documentsSelected: [],
    type: DocumentTypeEnum.ALL,
  },
});
