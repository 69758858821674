import { createDocument } from '../../shared/eversign/eversign-actions';
import { snackbar } from '../../shared/components/Snackbar';
import { OnDocumentModal } from './documents-events';
import { AttachDocumentDialogFormType } from './document-attach/Document-attach-types';
import { HTMLToPdf } from './document-utils';

/**
 * Submit.
 *
 * @param {AttachDocumentDialogFormType} data - Data.
 */
export const createDraftDocument = async (
  data: AttachDocumentDialogFormType,
): Promise<void> => {
  try {
    const res = await createDocument({
      file: data.file,
      signers: data.signers,
      isDraft: true,
      documentTitle: data.documentTitle,
    });

    OnDocumentModal.dispatch({
      modalType: 'prepare',
      embeddedClaimUrl: res.embeddedClaimUrl,
    });
  } catch (e) {
    const customError = e as Record<string, unknown>;

    const hasType =
      customError.hasOwnProperty('message') &&
      customError.message === 'Conversion to PDF failed for user upload.';

    if (hasType) {
      snackbar.error('Eversing error, failed converting file');
    } else {
      snackbar.error('An error has ocurred while uploading the document');
    }
  }
};

interface Signers {
  name: string;
  email: string;
}

interface Params {
  signers?: Signers[];
  documentTitle: string;
  HTMLElement: HTMLElement;
  isDraft: boolean;
}

/**
 * Submit.
 *
 * @param {AttachDocumentDialogFormType} data - Data.
 */
export const createPdfDocument = async ({
  signers,
  documentTitle,
  HTMLElement,
}: Params): Promise<void> => {
  const pdfBlob = await HTMLToPdf(HTMLElement);
  const pdfFile = new File([pdfBlob], 'file');

  try {
    const res = await createDocument({
      file: pdfFile,
      signers,
      isDraft: true,
      documentTitle,
    });
    OnDocumentModal.dispatch({
      modalType: 'prepare',
      embeddedClaimUrl: res.embeddedClaimUrl,
    });
  } catch (e) {
    console.log(e);
    snackbar.error('An error has ocurred while uploading the document');
  }
};
