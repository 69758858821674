import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DocumentTemplateFormType,
  EverSignTemplate,
} from '../../document-types';

type TemplateAutocompleteProps = {
  templates?: EverSignTemplate[];
};

/**
 * @param props - Properties.
 * @param props.templates - Templates.
 * @returns {JSX.Element} - Component.
 */
export const TemplateAutocomplete: React.FC<TemplateAutocompleteProps> = ({
  templates,
}): JSX.Element => {
  const methods = useFormContext<DocumentTemplateFormType>();
  const { control, formState } = methods;
  const { errors } = formState;

  /**
   * @param {EverSignTemplate} template - Template.
   * @returns {string} - Template's title.
   */
  const getOptionLabel = (template: EverSignTemplate): string =>
    template.templateId.title;

  return (
    <Controller
      control={control}
      name="templateId"
      render={({ field: { onChange } }) => (
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            disabled={!templates?.length}
            clearIcon={null}
            options={templates ?? []}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.templateId)}
                helperText={errors.templateId?.message}
                label="Templates"
              />
            )}
            onChange={(_, value) => {
              onChange(value?.templateId.documentHash);
            }}
          />
        </FormControl>
      )}
    />
  );
};
