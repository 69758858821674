import React from 'react';
import { Alert, Grid } from '@mui/material';

/**
 * @param props - Properties.
 * @param props.text - Text.
 * @returns {JSX.Element} - Component.
 */
export const DocListAlert: React.FC<{
  text?: string | null;
}> = ({ text }): JSX.Element => (
  <Grid item xs={12}>
    <Alert variant="outlined" severity="error">
      {text || (
        <>
          There is some kind of error - <strong>contact with support!</strong>
        </>
      )}
    </Alert>
  </Grid>
);
