import React from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton } from '../../../shared/components/buttons';

/**
 * @returns {JSX.Element} - Customer back button.
 */
export const CustomersBackBtn: React.FC = (): JSX.Element => {
  const history = useHistory();
  return (
    <BackButton
      onClick={() => history.push('/customers')}
      text="Back to Customers"
    />
  );
};
