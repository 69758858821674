import { FC, useState, useEffect, useRef } from 'react';
import { Grid, Autocomplete, Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import type {
  DriversByCustomerIdQuery,
  Driver,
  CustomersListQuery,
  Customer,
} from '../../../shared/types/generated';
import { useUserHasRole } from '../../session/session-hooks';
import type { AttachDocumentDialogFormType } from '../document-attach/Document-attach-types';
import type { DocumentCreationFormType } from '../document-creation/document-creation-types';
import { DriverAutoCompleteError } from './DriverAutocompleteError';

interface Props {
  driversData?: DriversByCustomerIdQuery;
  onDriverSelection: (driverInput: Driver | null) => void;
  driversSelectIsDisabled: boolean;
  customersData?: CustomersListQuery;
  onCustomerSelection: (customerInput: Customer | null) => void;
  show: boolean;
}

/**
 *
 * @param {Props} Props - Props.
 * @returns {JSX.Element} JSX.Element.
 */
export const SignerAutocompletes: FC<Props> = ({
  driversSelectIsDisabled,
  onCustomerSelection,
  onDriverSelection,
  customersData,
  driversData,
  show,
}) => {
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');
  const [driverId, setDriverId] = useState<string>('');
  const [customerKey, setCustomerKey] = useState<string>('');

  const signerLength = useRef<number>(0);

  const { watch, setError, formState, clearErrors, setValue } = useFormContext<
    AttachDocumentDialogFormType | DocumentCreationFormType
  >();

  const { errors } = formState;

  const driverErrorMsg = !!errors.driver && (
    <DriverAutoCompleteError driverId={driverId} />
  );

  const { customer, signers } = watch();

  useEffect(() => {
    // This will clear the customer auto complete each time that a new signer is added.

    if (!signers) return;

    if (signers.length > signerLength.current) {
      const key = (Math.random() + 1).toString(36).substring(7);
      setCustomerKey(key);
    }

    signerLength.current = signers.length;
  }, [signers, signerLength]);

  const driverInputMdSize = isCustomerElite ? 12 : 6;
  const customerInputDisplay = isCustomerElite ? 'none' : 'block';

  if (!show) return null;

  return (
    <>
      <Grid item xs={12} sm={6} display={customerInputDisplay}>
        <Autocomplete
          key={`customer-autocomplete-${customerKey}`}
          clearIcon={null}
          renderOption={(props, option) => (
            <Box {...props} key={option.id} component="li">
              {option.name}
            </Box>
          )}
          renderInput={(params) => <TextField {...params} label="Customer" />}
          options={customersData?.customersList.items || []}
          getOptionLabel={(e) => e.name}
          onChange={(_: React.SyntheticEvent<Element, Event>, newValue) => {
            clearErrors('driver');
            onCustomerSelection(newValue as Customer);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={driverInputMdSize}>
        <Autocomplete
          // There's no clean way of clearing the autocomplete, so a new key is assigned to reset its state.
          key={`${customer}-key`}
          clearIcon={null}
          disabled={driversSelectIsDisabled}
          renderOption={(props, option) => (
            <Box {...props} key={option.id} component="li">
              {option.name} {option.lastName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.driver)}
              label="Drivers"
              helperText={driverErrorMsg}
            />
          )}
          options={driversData?.driversList.items || []}
          getOptionLabel={(e) => `${e.name} ${e.lastName || ''}`}
          onChange={(_: React.SyntheticEvent<Element, Event>, newValue) => {
            setValue('signerEmail', undefined);
            setValue('signerName', undefined);

            if (!newValue?.email) {
              setDriverId(newValue?.id as string);
              setError('driver', {
                message: 'Driver Email Required',
                type: 'required',
              });
            } else {
              clearErrors('driver');
              onDriverSelection(newValue);
            }
          }}
        />
      </Grid>
    </>
  );
};
