import React from 'react';
import { Container, Typography, Link } from '@mui/material';

/**
 * @returns {JSX.Element} - Component.
 */
export const AuthUserDisabled = (): JSX.Element => (
  <Container
    sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div>
      <Typography textAlign="center" variant="h3" mb={3}>
        Your user is disabled, please contact your administrator.
      </Typography>
      <Typography textAlign="center" variant="h5">
        <Link href="/">Go back to login</Link>
      </Typography>
    </div>
  </Container>
);
