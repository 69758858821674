import React from 'react';
import { useQuery } from 'react-query';
import { Document } from 'eversign-enhance';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import { StyledTableRow } from '../../../shared/components/table/TableRowStyled';
import { everSignClient } from '../../../shared/eversign';
import { snackbar } from '../../../shared/components/Snackbar';
import { printTheDocumentStatus } from '../document-utils';
import { READABLE_DOC_STATUS } from '../document-types';

export interface DocModalProps {
  doc?: Document;
}

export interface DocModalRef {
  onClose: () => void;
  onOpen: () => void;
}

export const DocModal = React.forwardRef<DocModalRef, DocModalProps>(
  (props, ref): React.ReactElement => {
    const [show, setShow] = React.useState<boolean>(false);

    const {
      isLoading,
      isFetching,
      refetch: sendReminder,
    } = useQuery(
      'Reminder',
      async () => {
        try {
          await Promise.all([
            props.doc
              ?.getSigners()
              .map((signer) =>
                everSignClient.sendReminderForDocument(
                  props.doc as Document,
                  signer,
                ),
              ),
          ]);
          snackbar.success('Reminder sent.');
        } catch (error) {
          snackbar.error('Problems to send the reminder.');
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

    /**
     * @description - Close dialog.
     * @returns {void}
     */
    const handleClose = (): void => setShow(false);

    /**
     * @description - Open dialog.
     * @returns {void}
     */
    const handleOpen = (): void => setShow(true);

    React.useImperativeHandle(ref, () => ({
      onClose: handleClose,
      onOpen: handleOpen,
    }));

    return (
      <Dialog fullWidth maxWidth="sm" open={show} onBackdropClick={handleClose}>
        <DialogTitle>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            Document details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table
              size="small"
              sx={{ width: '100%' }}
              aria-label="customized table"
            >
              <TableBody>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Title:
                  </TableCell>
                  <TableCell align="right">
                    {props.doc?.getTitle() || '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Message:
                  </TableCell>
                  <TableCell align="right">
                    {props.doc?.getMessage() || '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Requester email:
                  </TableCell>
                  <TableCell align="right">
                    {props.doc?.getRequesterEmail() || '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Created:
                  </TableCell>
                  <TableCell align="right">
                    {Number(
                      (
                        props.doc?.toObject() as unknown as {
                          created?: number;
                        }
                      )?.created as number,
                    )
                      ? dayjs
                          .unix(
                            Number(
                              (
                                props.doc?.toObject() as unknown as {
                                  created: number;
                                }
                              ).created as number,
                            ),
                          )
                          .format('MMMM D, YYYY h:mm A')
                      : '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Expires:
                  </TableCell>
                  <TableCell align="right">
                    {Number(
                      (props.doc?.toObject() as unknown as { expires?: number })
                        ?.expires as number,
                    )
                      ? dayjs
                          .unix(
                            Number(
                              (
                                props.doc?.toObject() as unknown as {
                                  expires: number;
                                }
                              )?.expires as number,
                            ),
                          )
                          .format('MMMM D, YYYY h:mm A')
                      : '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Subject:
                  </TableCell>
                  <TableCell align="right">
                    {((props.doc?.toObject() as unknown as { subject?: string })
                      ?.subject as string)
                      ? ((
                          props.doc?.toObject() as unknown as {
                            subject: string;
                          }
                        )?.subject as string)
                      : '-'}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Signers:
                  </TableCell>
                  <TableCell align="right">
                    <List>
                      {props.doc?.getSigners().map((signer) => (
                        <ListItem
                          sx={{
                            p: 0,
                            justifyContent: 'flex-end',
                          }}
                        >
                          {`${signer.getName()} - ${signer.getEmail()}`}
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell component="th" scope="row">
                    Status:
                  </TableCell>
                  <TableCell align="right">
                    {printTheDocumentStatus(props.doc as Document)}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            style={{
              textTransform: 'none',
              color: grey[500],
            }}
            onClick={handleClose}
          >
            Close
          </Button>
          {printTheDocumentStatus(props.doc as Document) ===
            READABLE_DOC_STATUS.IN_PROGRESS && (
            <Button
              disabled={isLoading || isFetching}
              variant="text"
              style={{ textTransform: 'none' }}
              onClick={() => {
                sendReminder();
                handleClose();
              }}
              autoFocus
              color="primary"
            >
              Send reminder
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
);
