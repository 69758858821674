import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Driver, useDriversListQuery } from '../../types/generated';
import { useDelay } from '../../hooks/delay';

interface DriversAutocompleteProps {
  label?: string;
  maxItems?: number;
  onDriverSelected: (driver: Driver | undefined) => void;
}

/**
 * @param {DriversAutocompleteProps} props - Props.
 * @returns {JSX.Element} - Component.
 */
export const DriversAutocomplete: React.FC<DriversAutocompleteProps> = ({
  label,
  maxItems = 10,
  onDriverSelected,
}): JSX.Element => {
  const [search, setSearch] = useState<string>();
  const { data, loading, refetch } = useDriversListQuery({
    variables: {
      first: 100,
    },
  });

  const { delayExecution } = useDelay();

  // Listen search changes.
  useEffect(() => {
    let str = search;
    str = str?.trim();
    const strArray = str?.split(' ');

    if (!strArray?.length) return;

    refetch({
      first: maxItems,
      filter: {
        OR: [
          ...strArray.map((value) => ({
            name: {
              contains: value,
            },
          })),
          ...strArray.map((value) => ({
            lastName: {
              contains: value,
            },
          })),
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  /**
   * @param {React.SyntheticEvent<Element, Event>} event - Event.
   * @param {string | null} value - Value.
   * @returns {void} - Nothing.
   */
  const handleSearch = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
  ): void => {
    delayExecution(() => {
      setSearch(value || '');
    }, 300);
  };

  /**
   * @param event - Event.
   * @param value - Value.
   * @returns {void} - Nothing.
   */
  const handleSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: Driver,
  ): void => {
    onDriverSelected(value);
  };

  return (
    <Box>
      <Autocomplete
        id="drivers-autocomplete"
        options={data?.driversList.items || []}
        getOptionLabel={(option) => `${option.name} ${option.lastName || ''}`}
        loading={loading}
        onInputChange={handleSearch}
        onChange={(e, v) => handleSelect(e, v as Driver)}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Box>
  );
};
