import * as React from 'react';
import * as filestack from 'filestack-js';
import {
  FileUploadInfoQuery,
  useFileUploadInfoQuery,
} from '../types/generated';

interface FileStackResponse {
  // File stack reponse in the attachment.
  filesStackResponse: Array<filestack.PickerFileMetadata>;
  // Client file stack api.
  clientFileStack: filestack.Client | undefined;
  // Response from eight base about FileStack credentials.
  eightBaseFileStackResponse: FileUploadInfoQuery | undefined;
  // Call the attachment modal.
  handleAttachFiles: () => void;
  //
  clearFilesStackResponse: () => void;
}

type AcceptType =
  | '.pdf'
  | 'image/jpeg'
  | 'image/*'
  | 'video/*'
  | 'audio/*'
  | 'application/*'
  | 'text/*';

/**
 * @param root0 - Props.
 * @param root0.maxFiles - Max files.
 * @param root0.accept - Accept.
 * @returns {FileStackResponse} - Hook state.
 */
export function useFileStack({
  maxFiles = 1,
  accept = [
    '.pdf',
    'image/jpeg',
    'image/*',
    'video/*',
    'audio/*',
    'application/*',
    'text/*',
  ],
}: {
  maxFiles?: number;
  accept?: Array<AcceptType> | AcceptType;
}): FileStackResponse {
  // Files selected.
  const [filesStackResponse, setFilesStackResponse] = React.useState<
    Array<filestack.PickerFileMetadata>
  >([]);

  // Client file stack instance.
  const [clientFileStack, setClientFileStack] =
    React.useState<filestack.Client>();

  // Get credentials from 8base.
  const { data: eightBaseFileStackResponse } = useFileUploadInfoQuery();

  // Init set 8base credentials to file stack.
  React.useEffect(() => {
    if (eightBaseFileStackResponse?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(eightBaseFileStackResponse?.fileUploadInfo.apiKey, {
          security: {
            signature: eightBaseFileStackResponse?.fileUploadInfo
              .signature as string,
            policy: eightBaseFileStackResponse?.fileUploadInfo.policy as string,
          },
        }),
      );
    }
  }, [eightBaseFileStackResponse]);

  /**
   * @description - Handle file stack response.
   * @param {filestack.PickerResponse} files - Files.
   * @returns {void} - Nothing.
   */
  const onUploadDone = (files: filestack.PickerResponse): void =>
    setFilesStackResponse(files.filesUploaded);

  /**
   * @description - Attach file to the input.
   * @returns {void} - Nothing.
   */
  const handleAttachFiles = (): void => {
    // Check fileStack instance client.
    if (!clientFileStack) return;

    // Open client.
    clientFileStack
      .picker({
        fromSources: ['local_file_system'],
        maxFiles,
        accept,
        onUploadDone,
        storeTo: {
          path: eightBaseFileStackResponse?.fileUploadInfo.path as string,
        },
      })
      .open();
  };

  /**
   * @description - Clear files stack response.
   * @returns {void} - Nothing.
   */
  const clearFilesStackResponse = (): void => setFilesStackResponse([]);

  return {
    filesStackResponse,
    eightBaseFileStackResponse,
    clientFileStack,
    handleAttachFiles,
    clearFilesStackResponse,
  };
}
