import { Document, Template, Signer } from 'eversign-enhance';
import { snackbar } from '../../../shared/components/Snackbar';
import { everSignClient } from '../../../shared/eversign';
import { createDocumentFromTemplate } from '../../../shared/eversign/eversign-actions';
import type { DocumentTemplateFormType } from './document-template-types';

/**
 * Retrieves templates from the EverSign API using the configured EverSign client.
 *
 * @async
 * @function getTemplates
 * @returns {Promise<Document[] | undefined>} A Promise that resolves with an array of Document objects, or undefined if there was an error.
 * @throws {Error} If there was an error retrieving templates from the EverSign API.
 */
export const getTemplates = async (): Promise<Document[] | undefined> => {
  try {
    const result = await everSignClient.getTemplates();
    return result;
  } catch (err) {
    snackbar.error('Problem getting templates');
    console.log(err);
    return undefined;
  }
};

/**
 * @param data - Data.
 * @description - On submit event.
 * @returns {void} - Nothing.
 */
export const sendDocument = async (
  data: DocumentTemplateFormType,
): Promise<void> => {
  const { documentTitle } = data;

  // Create a template.
  const newTemplate = new Template();
  newTemplate.setTemplateId(data.templateId);
  newTemplate.setTitle(documentTitle);

  data.signers.forEach((e) => {
    // Added signers.
    const signer = new Signer();
    signer.setRole(e.role || '');
    signer.setName(e.name || '');
    signer.setEmail(e.email || '');

    // Append signer.
    newTemplate.appendSigner(signer);
  });

  // Create the document.
  await createDocumentFromTemplate({
    documentTitle,
    signers: data.signers,
    templateId: data.templateId,
  });
};
