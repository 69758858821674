import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEvent } from '@cobuildlab/react-simple-state';
import { styled } from '@mui/system';
import { sentenceCase } from 'change-case';
import { PaperStyled } from '../../../shared/components/card/Card';
import { useEversingDocumentListQuery } from '../../../shared/types/generated';
import { useUserHasRole } from '../../session/session-hooks';
import { OnDocumentUIStateEvent } from '../documents-events';
import { useGetDocumentList } from '../document-hooks';
import { DocListSkeleton } from './DocListSkeleton';
import { DocListAlert } from './DocListAlert';
import { DocSimplePagination } from './DocSimplePagination';
import { DocCardTitle } from './DocCardTitle';
import { DocCard } from './DocCard';
import { DocCardFooter } from './DocCardFooter';

export const DocWrapper = styled(Box)(() => ({
  '#icon-btn-eye': {
    display: 'none',
  },
  '&:hover': {
    '#icon-btn-eye': {
      display: 'inline-flex',
    },
  },
}));

/**
 * @returns {JSX.Element} - Component.
 */
export const DocumentList: React.FC = (): JSX.Element => {
  const state = useEvent(OnDocumentUIStateEvent);
  const {
    filter: { rows: limit, search },
    type,
  } = state;

  // 🎮️ To control the pagination.
  const [page, setPage] = useState(1);

  const isEliteCustomer = useUserHasRole('CUSTOMER_ELITE');
  const { data: hashesData, loading: hashesAreLoading } =
    useEversingDocumentListQuery({
      skip: !isEliteCustomer,
      fetchPolicy: 'no-cache',
    });

  const customerDocsAreLoading = hashesAreLoading && isEliteCustomer;

  const { data, error, refetch, isLoading, isFetching, isRefetching, isError } =
    useGetDocumentList({
      limit,
      page,
      search,
      type,
      hashesData,
    });

  useEffect(
    () => () => {
      // Clear the search.
      OnDocumentUIStateEvent.dispatch({
        ...state,
        filter: {
          ...state.filter,
          search: '',
        },
      });
    },
    // eslint-disable-next-line
    [],
  );

  const globalIsLoading = useMemo(
    (): boolean =>
      isLoading || isFetching || isRefetching || customerDocsAreLoading,
    [isLoading, isFetching, isRefetching, customerDocsAreLoading],
  );

  // 🦻 Detect the pagination change to refresh the data.
  useEffect(() => {
    if (globalIsLoading) return;
    refetch();
    // eslint-disable-next-line
  }, [page, limit, search, type, customerDocsAreLoading]);

  return (
    <Box mt="20px">
      <PaperStyled>
        <Box width="100%" padding={10} sx={{ mt: 4 }}>
          <Grid container spacing={10}>
            {globalIsLoading ? <DocListSkeleton amount={limit} /> : null}
            {isError && !globalIsLoading ? (
              <DocListAlert
                text={
                  error ? sentenceCase((error as { type: string }).type) : null
                }
              />
            ) : null}
            {!isError &&
              !globalIsLoading &&
              data?.map((doc, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${doc.getTitle()}-${i}`}
                >
                  <DocWrapper>
                    <DocCardTitle title={doc.getTitle()} />
                    <DocCard doc={doc} />
                    <DocCardFooter document={doc} />
                  </DocWrapper>
                </Grid>
              ))}
          </Grid>
        </Box>
      </PaperStyled>
      <DocSimplePagination
        showing={data?.length}
        prevBtn={{
          disabled: page <= 1 || isLoading || isFetching,
          /**
           * @returns {void} - Nothing.
           */
          onClick: () => setPage(page - 1),
        }}
        nextBtn={{
          disabled: Number(data?.length) < limit || isLoading || isFetching,
          /**
           * @returns {void} - Nothing.
           */
          onClick: () => setPage(page + 1),
        }}
      />
    </Box>
  );
};
