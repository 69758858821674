import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomerDriverFormType } from '../customer-driver-types';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { Title } from '../../../shared/components/typography/Title';
import {
  UNSUPPORTED_STATES_CODES,
  statesCodes,
} from '../../../shared/mvrnow/mvrnow-models';
import { UnsupportedStatesCode } from '../../drivers/components/UnsupportedStatesCode';
import { FCPCheckbox } from '../../../shared/components/checkbox/Checkbox';

/**
 * @returns {JSX.Element} - Components.
 */
export const CustomerDriverLicenseForm: React.FC = (): JSX.Element => {
  const methods = useFormContext<CustomerDriverFormType>();
  const { control, formState, setValue, watch } = methods;

  const license = watch('license');
  const importLicenseReportCheck = watch('requestMVR');
  const unsupportedStateSelected = watch('unsupportedStateSelected');

  const licenseReportCheckboxDisable = !license?.number || !license?.state;

  useEffect(() => {
    setValue(
      'unsupportedStateSelected',
      UNSUPPORTED_STATES_CODES.includes(license?.state || ''),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license?.state]);

  return (
    <Stack>
      <Title style={{ marginBottom: 24 }}>License</Title>
      <FormControl fullWidth variant="outlined">
        <Controller
          name="license.number"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomInput
              defaultValue={license?.number || ''}
              label="License Number"
              size="medium"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              helperText={formState.errors.license?.number?.message}
              error={Boolean(formState.errors.license?.number?.message)}
              style={{ margin: 0, marginBottom: 24 }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <Controller
          name="license.state"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomInput
              select
              label="License State"
              size="medium"
              defaultValue={license?.state || ''}
              onBlur={onBlur}
              value={value || ''}
              onChange={onChange}
              helperText={formState.errors.license?.state?.message}
              error={Boolean(formState.errors.license?.state?.message)}
              style={{ margin: 0, marginBottom: 24 }}
            >
              <MenuItem>Select...</MenuItem>
              {Object.values(statesCodes).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </CustomInput>
          )}
        />
      </FormControl>
      {unsupportedStateSelected && (
        <Box style={{ marginBottom: 24 }}>
          <UnsupportedStatesCode />
        </Box>
      )}
      <Box>
        <Controller
          name="requestMVR"
          render={({ field: { value } }) => (
            <FormControlLabel
              label="Request MVR"
              value={value}
              control={
                <FCPCheckbox
                  disabled={Boolean(
                    licenseReportCheckboxDisable || unsupportedStateSelected,
                  )}
                  size="medium"
                  checked={importLicenseReportCheck}
                  onChange={() => {
                    setValue('requestMVR', !importLicenseReportCheck);
                  }}
                />
              }
            />
          )}
        />

        <Typography variant="body2" component="p" color="white">
          The driver&apos;s license information must be included to request the
          MVR.
        </Typography>
      </Box>
    </Stack>
  );
};
