import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

interface DocListSkeletonProps {
  amount?: number;
}

/**
 * @param props - Properties.
 * @param props.amount - Amount.
 * @returns {JSX.Element} - Component.
 */
export const DocListSkeleton: React.FC<DocListSkeletonProps> = ({
  amount = 10,
}): JSX.Element => (
  <>
    {new Array(amount)
      .fill(Math.random().toString(36).substr(2, 9))
      .map((id, i) => (
        <Grid
          // eslint-disable-next-line react/no-array-index-key
          key={`${id}-skeleton-${i}`}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
        >
          <Box>
            <Skeleton
              sx={{
                mb: 1.8,
              }}
              height={24}
            />
            <Card>
              <CardContent>
                <Skeleton
                  sx={{
                    height: '100px',
                  }}
                  variant="rectangular"
                  width="100%"
                />
              </CardContent>
            </Card>
            <Box>
              <Skeleton height={42} />
            </Box>
          </Box>
        </Grid>
      ))}
  </>
);
