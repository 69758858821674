import React, { useState } from 'react';
import * as filestack from 'filestack-js';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Loader from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { PaperStyled } from '../../../shared/components/card/Card';
import {
  CustomerDocumentCreateManyInput,
  useCustomerDocumentCreateMutation,
  useCustomerDocumentDeleteMutation,
  useCustomerDocumentsListQuery,
  useCustomerDocumentUpdateMutation,
  useFileCreateManyMutation,
  useFileDeleteMutation,
} from '../../../shared/types/generated';
import { useFileStack } from '../../../shared/hooks/filestack';
import {
  ButtonBoxFile,
  ButtonBoxFilePreview,
} from '../../cases/case-details/case-tabs/attach-documents/AttachDocumentsTab';
import { clearQueriesFromCache } from '../../../shared/utils';
import { theme } from '../../../shared/css/theme';
import { snackbar } from '../../../shared/components/Snackbar';
import { useDelay } from '../../../shared/hooks/delay';

interface CustomerDocument {
  file: filestack.PickerFileMetadata;
  description?: string;
}

/**
 * @returns {JSX.Element} - Component.
 */
const AttachDocumentsZone: React.FC = () => {
  const [files, setFiles] = useState<Array<CustomerDocument>>([]);

  const { filesStackResponse, handleAttachFiles, clearFilesStackResponse } =
    useFileStack({
      maxFiles: 100,
    });

  // Customer id.
  const { id } = useParams<{ id: string }>();

  const [customerDocumentCreateMutation] = useCustomerDocumentCreateMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      setFiles([]);
      clearFilesStackResponse();
    },
    update: clearQueriesFromCache([
      'customerDocument',
      'customerDocumentsList',
    ]),
  });

  // Create files on StackFiles
  const [fileCreateManyMutation, { loading }] = useFileCreateManyMutation();

  // Listen the attachment.
  React.useEffect(() => {
    if (!filesStackResponse.length) return; // No file attached

    // map the list of files.
    const newFiles: Array<CustomerDocument> = filesStackResponse.map(
      (file) => ({
        file,
      }),
    );

    // Update state.
    setFiles([...files, ...newFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesStackResponse]);

  /**
   * @param {string} text - Description text.
   * @param {number} index - Index of the file.
   * @returns {void} - Nothing.
   */
  const handleChangeDescription = (text: string, index: number): void => {
    setFiles((v) => {
      const newFiles = [...v];
      newFiles[index].description = text;
      return newFiles;
    });
  };

  /**
   * @description - Handles remove file.
   * @param {any} uploadId - Upload id.
   * @returns {void} - Nothing.
   */
  // eslint-disable-next-line
  const removeFileOnThePreview = (uploadId: any): void => {
    setFiles((v) => v.filter((file) => file.file.uploadId !== uploadId));
  };

  /**
   * @description - Handles the submit upload documents to 8base.
   * @returns {void} - Nothing.
   */
  const uploadDocumentsTo8base = (): void => {
    const preparedFiles = files.map((file) => ({
      fileId: file.file.handle,
      filename: file.file.filename,
    }));

    // File create many.
    fileCreateManyMutation({
      variables: {
        data: preparedFiles,
      },
    }).then((res) => {
      if (!res.data) return res;

      // Prepared the documents to connect to the customer.
      const preparedCustomerDocumentsConnection: CustomerDocumentCreateManyInput[] =
        res.data.fileCreateMany.items.map((item) => ({
          customerRelation: {
            connect: {
              id,
            },
          },
          file: {
            connect: {
              fileId: item.fileId,
            },
          },
        }));

      // Iterable request customer documents create.
      Promise.all(
        preparedCustomerDocumentsConnection.map(async (item) => {
          await customerDocumentCreateMutation({
            variables: {
              data: item,
            },
          });
        }),
      );

      return res;
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {
          // File list attachment.
        }
        {files.map((value, index) => (
          <ButtonBoxFilePreview
            key={value.file.uploadId}
            filename={value.file.filename}
            description={value.description}
            onRemoveFile={() => removeFileOnThePreview(value.file.uploadId)}
            onChangeDescription={(e) =>
              handleChangeDescription(e.target.value, index)
            }
          />
        ))}
        {
          // Input file box.
        }
        <ButtonBoxFile onClick={handleAttachFiles} />
      </Box>

      {
        // Button confirm upload documents.
      }
      <Button
        onClick={uploadDocumentsTo8base}
        disabled={Boolean(!files.length || loading)}
      >
        Upload documents
      </Button>
    </>
  );
};

/**
 * @returns {JSX.Element} - Component.
 */
export const CustomerAttachDocuments: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useCustomerDocumentsListQuery({
    variables: {
      filter: {
        customerRelation: {
          id: {
            equals: id,
          },
        },
      },
    },
  });

  const { delayExecution } = useDelay();

  // Customer document destroy.
  const [customerDocumentDeleteMutation] = useCustomerDocumentDeleteMutation({
    update: clearQueriesFromCache([
      'customerDocument',
      'customerDocumentsList',
    ]),
  });

  const [customerDocumentUpdateMutation] = useCustomerDocumentUpdateMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      snackbar.success('Document description updated');
    },
    update: clearQueriesFromCache([
      'customerDocument',
      'customerDocumentsList',
    ]),
  });

  // File destroy on 8base.
  const [fileDeleteMutation] = useFileDeleteMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      snackbar.info('Document has been delete it.');
    },
    update: clearQueriesFromCache([
      'customerDocument',
      'customerDocumentsList',
    ]),
  });

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box padding="0px 0px">
      <PaperStyled>
        <Box padding="30px 30px 50px 30px">
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" color="white">
                Attach Documents
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AttachDocumentsZone />
              <Divider sx={{ my: 2 }}>
                {!data?.customerDocumentsList.count && !loading
                  ? 'Without documents.'
                  : 'Documents'}
              </Divider>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Loader
                    type="ThreeDots"
                    color={theme.palette.primary.main}
                    width={40}
                    height={40}
                  />
                </Box>
              ) : null}
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                {data?.customerDocumentsList.items.map((value) => (
                  <ButtonBoxFilePreview
                    key={value.id}
                    filename={value.file.filename}
                    description={value.description}
                    downloadUrl={value.file.downloadUrl}
                    onChangeDescription={(e) => {
                      delayExecution(() => {
                        // Nothing to update.
                        if (value.description === e.target.value) return;

                        customerDocumentUpdateMutation({
                          variables: {
                            id: value.id,
                            data: {
                              description: e.target.value,
                            },
                          },
                        });
                      }, 500);
                    }}
                    onRemoveFile={async () => {
                      // Case document destroy.
                      await customerDocumentDeleteMutation({
                        variables: {
                          filter: {
                            id: value.id,
                          },
                        },
                      });

                      // File destroy.
                      await fileDeleteMutation({
                        variables: {
                          id: value.file.id,
                        },
                      });
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </PaperStyled>
    </Box>
  );
};
