import * as yup from 'yup';
import {
  REQUIRED_FORM_MESSAGE,
  INVALID_EMAIL_MESSAGE,
} from '../../../shared/constants';

export const validMimeTypes = [
  'text/plain',
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const ATTACH_DOCUMENT_DIALOG_FORM_VALIDATOR_SCHEMA = yup
  .object({
    signerEmail: yup.string().email(INVALID_EMAIL_MESSAGE),
    signerName: yup.string(),
    signerLastName: yup.string().notRequired(),
    customer: yup.string(),
    driver: yup.string(),
    file: yup.mixed().required(REQUIRED_FORM_MESSAGE),
    documentTitle: yup.string().required(REQUIRED_FORM_MESSAGE),
    signers: yup.array().of(
      yup.object({
        name: yup.string().required(),
        lastName: yup.string().notRequired(),
        email: yup.string().email(INVALID_EMAIL_MESSAGE).required(),
        driverId: yup.string().optional(),
      }),
    ),
  })
  .required();
