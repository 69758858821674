import * as React from 'react';
import { Autocomplete, TextFieldProps } from '@mui/material';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { useUsStatesListQuery, UsState } from '../../../shared/types/generated';

export interface Option {
  id: string;
  label: string;
}

interface StateSelectorProps {
  onChange?: (value: string) => void;
  onChangeText?: () => void;
  value?: UsState;
  // label?: string;
  // inputStyle?: React.CSSProperties | undefined;
  inputProps?: TextFieldProps;
}

/**
 * @param {StateSelectorProps} props - Statet selector properties.
 * @returns {JSX.Element} - Component.
 */
export const StateSelector: React.FC<StateSelectorProps> = (props) => {
  // Option selected.
  const [stateSelected, setStateSelected] = React.useState<Option>({
    id: '',
    label: '',
  });
  // Options.
  const [statesList, setStatesList] = React.useState<Array<Option>>([]);
  // Get list of states.
  const { data } = useUsStatesListQuery();

  // Default value.
  React.useEffect(() => {
    if (props.value) {
      setStateSelected({
        id: props.value.id as string,
        label: props.value.name as string,
      });
    }
  }, [props.value]);

  // Init.
  React.useEffect(() => {
    // Map the options.
    const values: Array<Option> | undefined = data?.usStatesList.items.map(
      (item) => ({
        id: item.id,
        label: `${item.code} - ${item.name}`,
      }),
    );

    if (values && values.length) {
      setStatesList([...values]);
    }
  }, [data]);

  /**
   * @param {Option | null} v - Value.
   * @returns {void} - Nothing.
   */
  const handleStateSelected = (v: Option | null): void => {
    // No value selected, emite for clear.
    if (!v && props.onChangeText) props.onChangeText();
    // Value selected, emite for change.
    if (v && props.onChange) {
      setStateSelected(v);
      props.onChange(String(v?.id));
    }
  };

  /**
   * @param {string} v - Value.
   * @returns {void} - Nothing.
   */
  const handleStateText = (v: string): void => {
    if (!v && props.onChangeText) {
      setStateSelected({ id: '', label: '' });
      props.onChangeText();
    }
  };

  return (
    <Autocomplete
      id="state-selector"
      value={stateSelected}
      options={statesList}
      onChange={(e, v) => handleStateSelected(v)}
      onInputChange={(e, v) => handleStateText(v)}
      style={{ display: 'flex' }}
      renderInput={(params) => (
        <CustomInput
          {...params}
          {...props.inputProps}
          value={stateSelected.label}
          variant="outlined"
          label={props.inputProps?.label ?? 'State/Province'}
        />
      )}
    />
  );
};
