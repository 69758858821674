import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useUserSessionQuery } from '../shared/types/generated';
import { USER_ROLES } from '../modules/session/session-model';
import { ROUTES } from './routes-model';

interface RoutesInitialRedirectionReturns {
  isLoading: boolean;
  isAuthenticated: boolean;
  error: Error | undefined;
}

/**
 * @returns {RoutesInitialRedirectionReturns} - States.
 */
export const useRoutesInitialRedirection =
  (): RoutesInitialRedirectionReturns => {
    const { isLoading, isAuthenticated, error } = useAuth0();
    const { data, loading: userSessionLoading } = useUserSessionQuery();
    const history = useHistory();

    useEffect(() => {
      if (data) {
        const notCustomer = data.user.roles.items.some((r) =>
          ([USER_ROLES.ADMIN, USER_ROLES.AGENT] as Array<string>).includes(
            r.name,
          ),
        );

        if (history.location.pathname === '/') {
          history.push(
            notCustomer
              ? ROUTES.DASHBOARD.path
              : ROUTES.CUSTOMER_DASHBOARD.path,
          );
        }
      }
      // eslint-disable-next-line
    }, [data]);

    return {
      isLoading: isLoading || userSessionLoading,
      isAuthenticated,
      error,
    };
  };
