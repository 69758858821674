import { useEffect, useState, FC } from 'react';
import { useEvent } from '@cobuildlab/react-simple-state';
import { CircularProgress, Stack } from '@mui/material';
import { ContentDialog } from '../../../shared/components/dialogs/ContentDialog';
import { loadEversingDoc } from '../../../shared/eversign/eversign-utils';
import { snackbar } from '../../../shared/components/Snackbar';
import { clearQueriesFromCache } from '../../../shared/utils';
import { OnDocumentModal } from '../documents-events';

interface Props {
  onCloseCallback?: () => void;
}

/**
  @param {Props} Props - Props.
 * @returns {JSX.Element} DocumentDialog.
 */
export const DocumentDialog: FC<Props> = ({
  onCloseCallback,
}): JSX.Element | null => {
  const [loading, setLoading] = useState<boolean>(true);
  const { embeddedClaimUrl, modalType } = useEvent(OnDocumentModal);

  /**
   * Close modal function.
   */
  const closeModal = (): void => {
    OnDocumentModal.dispatch({
      modalType: null,
      embeddedClaimUrl: null,
    });
    if (onCloseCallback) onCloseCallback();
    setLoading(true);
  };

  /**
   * On submit handler.
   */
  const onSubmit = (): void => {
    closeModal();
    snackbar.success('Document sent');
  };

  /**
   * On error handler.
   */
  const onError = (): void => {
    snackbar.error('The was an error while sending the document');
  };

  /**
   * On load doc callback.
   */
  const onLoad = (): void => {
    setLoading(false);
  };

  /**
   * "x" handler.
   */
  const handleCancel = (): void => {
    closeModal();
  };

  useEffect(() => {
    if (embeddedClaimUrl) {
      clearQueriesFromCache('everSignDocsList');
      loadEversingDoc({
        embeddedClaimUrl,
        iframeID: 'eversingDoc',
        onLoad,
        onSubmit,
        onError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedClaimUrl]);

  const shouldBeOpen = modalType === 'prepare';
  const divStyle = loading ? { display: 'none' } : {};

  if (!shouldBeOpen) return null;

  return (
    <ContentDialog
      hideButtons
      fullWidth
      maxWidth="xl"
      open={shouldBeOpen}
      loading={false}
      onCancel={handleCancel}
      onConfirm={() => null}
    >
      {loading && (
        <Stack height={500} alignItems="center" justifyContent="center">
          <CircularProgress size={80} />
        </Stack>
      )}
      <div id="eversingDoc" style={divStyle} />
    </ContentDialog>
  );
};
