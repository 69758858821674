import { Maybe } from 'graphql/jsutils/Maybe';
import * as yup from 'yup';

export const CUSTOMER_FORM_VALIDATOR_SCHEMA = yup
  .object({
    id: yup.string(),
    name: yup.string().required('Name is required'),
    subscription: yup.string().required('Subscription is required'),
    contactName: yup.string().nullable().max(100, 'Max 100 characters'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup
      .string()
      .min(10, 'Please enter a valid number')
      .required('Phone is required')
      .default(''),
    customerAddressRelation: yup.array().of(
      yup.object({
        id: yup.string(),
        // country: yup.string(),
        state: yup.string().nullable().required('State is required'),
        street: yup.string().required('Street is required'),
        city: yup
          .string()
          .nullable()
          .max(100, 'Max 100 characters')
          .required('City is required')
          .default(''),
        apartment: yup.string().nullable(),
        zipCode: yup
          .string()
          .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Please enter a valid zipcode')
          .default('')
          .required('Zipcode is required'),
      }),
    ),
  })
  .required();

export type Address = {
  // country: string;
  id?: string;
  state: string;
  street: string;
  city: string;
  apartment: string;
  zipCode: string;
};
export type AddressEdit = {
  id?: string;
  // country: string;
  state: string;
  street: string;
  city: string;
  apartment: string;
  zipCode: string;
};
export type CustomersListFilters = {
  search?: string;
  from?: string;
  to?: string;
  status?: string;
};
export type EditCustomerFormType = {
  name: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  subscription: string;
  customerAddressRelation: Array<AddressEdit>;
};

export enum CustomerStatusEnum {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}

export type CustomerType = {
  __typename?: 'Customer';
  id: Maybe<string>;
  name: Maybe<string>;
  contactName: Maybe<string>;
  createdAt: Maybe<string>;
  email: Maybe<string>;
  archived: Maybe<string>;
  status: Maybe<string>;
};
