import {
  DocumentDraftCreateInput,
  DocumentDraftUpdateInput,
} from '../../../shared/types/generated';

import { DocumentCreationFormType } from './document-creation-types';

/**
 *
 * @param {DocumentCreationFormType} data - Data.
 * @returns {DocumentDraftCreateInput} Input.
 */
export const createVariablesCreateDocumentDraft = (
  data: DocumentCreationFormType,
): DocumentDraftCreateInput => ({
  editorState: JSON.stringify(data.description),
  signers: JSON.stringify(data.signers),
  documentTitle: data.documentTitle,
  customer: data.customer,
  driver: data.driver,
});

/**
 *
 * @param {DocumentCreationFormType} data - Data.
 * @returns {DocumentDraftUpdateInput} Input.
 */
export const createVariablesUpdateDocumentDraft = (
  data: DocumentCreationFormType,
): DocumentDraftUpdateInput => ({
  editorState: JSON.stringify(data.description),
  signers: JSON.stringify(data.signers),
  documentTitle: data.documentTitle,
  customer: data.customer,
  driver: data.driver,
});
