import { lazy } from 'react';

// Dashboard

export const Dashboard = lazy(() =>
  import('../modules/dashboard/DashboardView').then((module) => ({
    default: module.Dashboard,
  })),
);

export const CustomerDashboard = lazy(() =>
  import('../modules/dashboard/customer-dashboard/CustomerDashboardView').then(
    (module) => ({
      default: module.CustomerDashboardView,
    }),
  ),
);

// Cases

export const Cases = lazy(() =>
  import('../modules/cases/CasesView').then((module) => ({
    default: module.Cases,
  })),
);

export const CasesRefactor = lazy(() =>
  import('../modules/cases/case-view-refactor/CaseViewRefactor').then(
    (module) => ({
      default: module.CaseViewRefactor,
    }),
  ),
);

export const CaseDetailsView = lazy(() =>
  import('../modules/cases/case-details/CaseDetailsView').then((module) => ({
    default: module.CaseDetailsView,
  })),
);

export const CreateCaseView = lazy(() =>
  import('../modules/cases/components/CreateCaseView').then((module) => ({
    default: module.CreateCaseView,
  })),
);

export const UpdateCaseView = lazy(() =>
  import('../modules/cases/components/UpdateCaseView').then((module) => ({
    default: module.UpdateCaseView,
  })),
);

// Drivers

export const DriversLazy = lazy(() =>
  import('../modules/drivers/DriversView').then((module) => ({
    default: module.Drivers,
  })),
);

export const CreateDriverLazyView = lazy(() =>
  import('../modules/drivers/components/CreateDriverView').then((module) => ({
    default: module.CreateDriverView,
  })),
);

export const UpdateDriverLazyView = lazy(() =>
  import('../modules/drivers/components/UpdateDriverView').then((module) => ({
    default: module.UpdateDriverView,
  })),
);

export const DriverDetailsLazyView = lazy(() =>
  import('../modules/drivers/driver-details/DriverDetailsView').then(
    (module) => ({
      default: module.DriverDetailsView,
    }),
  ),
);

export const MergeDriversLazyView = lazy(() =>
  import('../modules/drivers/merge-drivers/MergeDriversView').then(
    (module) => ({
      default: module.MergeDriversView,
    }),
  ),
);

// User

export const MyAccountView = lazy(() =>
  import('../modules/settings/my-account/MyAccountView').then((module) => ({
    default: module.MyAccountView,
  })),
);

export const UserManagementView = lazy(() =>
  import('../modules/user-management/UserManagementView').then((module) => ({
    default: module.UserManagementView,
  })),
);

export const CreateUserCaseView = lazy(() =>
  import('../modules/user-management/components/CreateUserView').then(
    (module) => ({
      default: module.CreateUserView,
    }),
  ),
);

export const UpdateUserCaseView = lazy(() =>
  import('../modules/user-management/components/UpdateUserView').then(
    (module) => ({
      default: module.UpdateUserView,
    }),
  ),
);
