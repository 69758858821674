import * as React from 'react';
import { FormControl, MenuItem, StandardTextFieldProps } from '@mui/material';
import { CustomInput } from '../inputs/CustomInput';

interface ItemProps {
  label?: string;
  value: string | number | undefined;
}

export interface CustomSelectProps extends StandardTextFieldProps {
  options?: Array<ItemProps> | null | undefined;
}

/**
 * @description - CustomSelect component.
 * @param {CustomSelectProps} props - Props for the component.
 * @returns {JSX.Element} - CustomSelect component.
 */
export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  let items;

  if (props.options && props.options.length) {
    items = props?.options.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  } else {
    items = null;
  }

  return (
    <FormControl fullWidth variant="outlined">
      <CustomInput select {...props}>
        {items}
      </CustomInput>
    </FormControl>
  );
};
