import { Document } from 'eversign-enhance';
import * as yup from 'yup';
import { DriversByCustomerIdQuery } from '../../shared/types/generated';

const requiredFieldMsg = 'This field is required';

export const DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA = yup
  .object({
    customerId: yup.string(),
    driverId: yup.string(),
    documentTitle: yup.string().required(requiredFieldMsg),
    templateId: yup.string().required(requiredFieldMsg),

    signers: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required(),
          email: yup.string().email('Invalid email format').required(),
          role: yup.string().optional(),
          driverId: yup.string().optional(),
        }),
      )
      .required(),

    role: yup.string().required(requiredFieldMsg),
  })
  .required();

export const SIGNERS_FORM_VALIDATOR_SCHEMA = yup.object({
  name: yup.string().required(requiredFieldMsg),
  email: yup.string().email('Invalid email format').required(requiredFieldMsg),
});

export type SignersFormType =
  typeof SIGNERS_FORM_VALIDATOR_SCHEMA['__outputType'];

export type DocumentTemplateFormType =
  typeof DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA['__outputType'];

export enum DocumentTypeEnum {
  ALL = 'all',
  MY_ACTION_REQUIRED = 'my_action_required',
  WAITING_FOR_OTHERS = 'waiting_for_others',
  COMPLETED = 'completed',
  DRAFTS = 'drafts',
  CANCELLED = 'cancelled',
  TEMPLATES = 'templates',
}

export enum OptionsCreateDocument {
  BY_ATTACHMENT = 'by Attachment',
  CUSTOM = 'Custom',
  TEMPLATES = 'Templates',
}

export const READABLE_DOC_STATUS = {
  DRAFT: 'Draft',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  IN_PROGRESS: 'In Progress',
  I_NEED_TO_SIGN: 'I need to sign',
};

export interface DocumentUIStateProps {
  filter: {
    search: string;
    rows: number;
  };
  documentsSelected: Document[];
  // To handle the document selection.
  type: `${DocumentTypeEnum}`;
}

export interface UseCustomDriversByCustomerIdLazyQueryResult {
  loadingDrivers: boolean;
  driversData: DriversByCustomerIdQuery | undefined;
  driversCalled: boolean;
}

export interface EverSignTemplate {
  templateId: Template;
}

export interface Template {
  documentHash: string;
  requesterEmail: string;
  customRequesterName: string;
  customRequesterEmail: string;
  isDraft: number;
  isTemplate: number;
  isCompleted: number;
  isArchived: number;
  isDeleted: number;
  isTrashed: number;
  isCancelled: number;
  isExpired: number;
  embedded: number;
  inPerson: number;
  embeddedSigningEnabled: number;
  flexibleSigning: number;
  permission: number;
  templateId: string;
  title: string;
  subject: string;
  message: string;
  useSignerOrder: number;
  reminders: number;
  requireAllSigners: number;
  redirect: string;
  redirectDecline: string;
  client: string;
  created: number;
  completed: string;
  files: File[];
  signers: Signer[];
  recipients: Recipient[];
  fields: Field[][];
  log: Log[];
  meta: Meta[];
}

export interface File {
  name: string;
  file_id: string;
  pages: number;
}

export interface Signer {
  id: number;
  name: string;
  email: string;
  role: string;
  order: number;
  pin: string;
  signer_authentication_sms_enabled: number;
  signer_authentication_phone_number: string;
  message: string;
  signed: string;
  signed_timestamp: string;
  required: number;
  deliver_email: number;
  language: string;
}

export interface Field {
  merge: number;
  identifier: string;
  name: string;
  options: string;
  group: string;
  value: string;
  type: string;
  x: number;
  y: number;
  page: number;
  width: number;
  height: number;
  signer: number;
  validation_type: string;
  required: number;
  readonly: number;
  text_size: number;
  text_color: string;
  text_style: string;
  text_font: string;
}

export interface Log {
  event: string;
  signer: string;
  timestamp: number;
}

export interface Recipient {
  name: string;
  email: string;
  language: string;
}

export interface Meta {
  some_key: string;
  another_key: string;
}

export interface SignerDataShape {
  name: string;
  lastName?: string;
  email: string;
  role?: string;
  driverId?: string;
}
