export const USER_ROLES = {
  ADMIN: 'App Admin',
  AGENT: 'App Agent',
  CUSTOMER: 'App Customer',
  CUSTOMER_PRO_DRIVER: 'App Customer Pro Driver',
  CUSTOMER_PRO_LEGACY: 'App Customer Pro Legacy',
  CUSTOMER_BASIC: 'App Customer Basic',
  CUSTOMER_ELITE: 'App Customer Elite',
  CUSTOMER_PREMIUM: 'App Customer Premium',
} as const;

export type RolesType = keyof typeof USER_ROLES;
