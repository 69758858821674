import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';
import friendsImg from '../../../shared/assets/truck.svg';
import { BackButton } from '../../../shared/components/buttons';
import { FormImageContainer } from '../../../shared/components/Containers';
import { CardBox } from '../../../shared/components/card/CardBox';
import { Title } from '../../../shared/components/typography/Title';
import { ROUTES } from '../../../routes/routes-model';
import { AddAddressView } from './AddressView';
import {
  useCreateCustomerMutation,
  useUserByEmailLazyQuery,
} from '../../../shared/types/generated';
import { useCustomerForm, createVariablesNewCustomer } from '../customer-utils';
import { Address } from '../customer-types';
import { clearQueriesFromCache } from '../../../shared/utils';
import { snackbar } from '../../../shared/components/Snackbar';
import { TextFormatPhone } from '../../../shared/components/inputs/TextFormatPhone';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { CustomSelect } from '../../../shared/components/selects/CustomSelect';
import {
  customerSubscriptionsSelectOptions,
  customerSubscriptions,
} from '../customer-models';

/**
 * @returns {JSX.Element} - Cases view.
 */
export const CreateCustomerView: React.FC = () => {
  // React router
  const history = useHistory();

  // Form state.
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    watch,
    setValue,
    control,
  } = useCustomerForm();
  const [createCustomer, { loading }] = useCreateCustomerMutation({
    /**
     * @returns {void} - Result.
     */
    onCompleted: () => {
      snackbar.success('Customer created successfully');
      history.push('/customers');
    },
    /**
     * @param cache - Apollo cache.
     */
    update: clearQueriesFromCache(['customer', 'customersList']),
    /**
     * @param error - Error.
     * @returns {void} - Nothing.
     */
    onError: (error): void => {
      snackbar.error(error.message);
    },
  });

  const [getUserByEmail] = useUserByEmailLazyQuery();

  useEffect(() => {
    setValue('subscription', customerSubscriptions.pro);
  }, [setValue]);

  /**
   * @returns {void} - Add more addresses.
   */
  const pushAddress = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      apartment: '',
      // country: '',
      state: '',
      street: '',
      city: '',
      zipCode: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @returns {void} - Remove Address.
   * @param {number} index - Array position to delete.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);

    setValue('customerAddressRelation', addresses);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <BackButton
          onClick={() => history.push('/customers')}
          text="Back to Customers"
        />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => {
            history.push(
              ROUTES.Customers.internalPaths.CUSTOMER_DRIVER_CREATE.path,
            );
          }}
        >
          New Customer-Driver
        </Button>
      </Box>

      <CardBox>
        <FormImageContainer
          imagePath={friendsImg}
          alt="img"
          onSubmit={handleSubmit(async (data) => {
            // To prevent if an user is already registered with the same email.
            const response = await getUserByEmail({
              variables: {
                email: data.email,
              },
            });

            if (response.data?.user) {
              snackbar.error('This email is already registered');
              return;
            }

            if (data.customerAddressRelation !== undefined) {
              createCustomer({
                variables: createVariablesNewCustomer(
                  data.name,
                  data.contactName,
                  data.email ?? '',
                  data.phoneNumber ?? '',
                  data.customerAddressRelation as Array<Address>,
                  data.subscription,
                ),
              });
            }
          })}
        >
          <Stack direction="column" spacing={3}>
            <Title>Create Customer</Title>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                label="Company name"
                {...register('name')}
                helperText={formState.errors.name?.message}
                error={Boolean(formState.errors.name)}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                label="Contact Name"
                {...register('contactName')}
                helperText={formState.errors.contactName?.message}
                error={Boolean(formState.errors.contactName)}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                label="Email"
                {...register('email')}
                helperText={formState.errors.email?.message}
                error={Boolean(formState.errors.email)}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ gridColumn: 'span 2' }}
            >
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <CustomInput
                    label="Phone"
                    size="medium"
                    value={value || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={formState.errors.phoneNumber?.message}
                    error={Boolean(formState.errors.phoneNumber?.message)}
                    InputProps={{
                      // eslint-disable-next-line
                      inputComponent: TextFormatPhone as any,
                    }}
                    style={{ margin: 0 }}
                  />
                )}
              />
            </FormControl>
          </Stack>

          {getValues('customerAddressRelation').map((_, index) => (
            <Box sx={{ gridColumn: 'span 2', margin: 0, marginTop: 2 }}>
              <AddAddressView
                index={index}
                add={pushAddress}
                remove={removeAddress}
                formState={formState}
                control={control}
                setValue={setValue}
                watch={watch}
              />
            </Box>
          ))}

          <Stack direction="column" spacing={3} marginTop={3}>
            <Title>Subscription</Title>

            <FormControl fullWidth variant="outlined">
              <Controller
                name="subscription"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    size="medium"
                    {...field}
                    defaultValue={customerSubscriptions.pro}
                    options={customerSubscriptionsSelectOptions}
                    helperText={formState.errors.subscription?.message}
                    error={Boolean(formState.errors.subscription?.message)}
                    style={{ margin: 0, marginBottom: 24 }}
                  />
                )}
              />
            </FormControl>
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: ['space-between', 'flex-end'],
              gridColumn: 'span 2',
              marginTop: '20px',
              marginBottom: '20px',
              '&>*': {
                marginLeft: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 24 }}
              type="submit"
            >
              Create
            </LoadingButton>
          </Box>
        </FormImageContainer>
      </CardBox>
    </>
  );
};
