import {
  AvailableOptionsResult,
  DropDownValidOptions,
  GenerateAvailableOptions,
  validInlineOptions,
} from './rich-text-editor-types';

/**
 * Generate Available Options.
 *
 * @param {string} inlineOptions - Available inline options.
 * @param {DropDownValidOptions[]} options - Options.
 * @returns {any} Available option objects.
 */
export const generateAvailableOptions = (
  inlineOptions: string,
  options?: DropDownValidOptions[],
): GenerateAvailableOptions => {
  if (!options)
    return {
      optionsArray: undefined,
      availableOptionsResult: undefined,
      inline: undefined,
    };

  const dropDownIsTrue = {};
  const mapOptions = options.map((e) => ({ [e]: { inDropdown: true } }));

  mapOptions.forEach((e) => {
    Object.assign(dropDownIsTrue, e);
  });

  const result = {
    optionsArray: options,
    availableOptionsResult: dropDownIsTrue as AvailableOptionsResult,
  };

  if (inlineOptions === 'all') {
    result.optionsArray.push('inline');
    Object.assign(result, { inline: { options: validInlineOptions } });
  }

  return result;
};
