import React from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { AddAddressView } from '../../customer/components/AddressView';
import { EditCustomerFormType } from '../../customer/customer-types';
import {
  CustomerAddressType,
  CustomerDriverFormType,
} from '../customer-driver-types';
import { useCustomerDetailQuery } from '../../../shared/types/generated';

/**
 * @returns {JSX.Element} - Customer Driver Address.
 */
export const CustomerDriverAddressesForm: React.FC = (): JSX.Element => {
  const methods = useFormContext<CustomerDriverFormType>();
  const { formState, getValues, watch, setValue, control } = methods;

  // Customers

  const { id } = useParams<{ id: string }>();
  const { data } = useCustomerDetailQuery({
    variables: { id },
  });

  // End customer query.

  /**
   * @returns {void} - Add more addresses.
   */
  const pushAddress = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      id: '',
      apartment: '',
      state: '',
      street: '',
      city: '',
      zipCode: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @returns {void} - Remove Address.
   * @param {number} index - Array position to delete.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);

    setValue('customerAddressRelation', addresses);
  };

  return (
    <Box>
      {data &&
        data.customer.address.items.length &&
        getValues('customerAddressRelation')?.map(
          (d: CustomerAddressType, index: number) => (
            <Box sx={{ gridColumn: 'span 2', margin: 0, marginTop: 2 }}>
              <AddAddressView
                index={index}
                add={pushAddress}
                remove={removeAddress}
                formState={formState}
                control={control as unknown as Control<EditCustomerFormType>}
                setValue={
                  setValue as unknown as UseFormSetValue<EditCustomerFormType>
                }
                watch={watch as unknown as UseFormWatch<EditCustomerFormType>}
                defaultValues={data.customer.address.items[index]}
              />
            </Box>
          ),
        )}
    </Box>
  );
};
