import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Typography,
} from '@mui/material';
import { useUserHasRole } from '../../session/session-hooks';

interface DocSimplePaginationProps {
  prevBtn?: ButtonProps;
  nextBtn?: ButtonProps;
  showing?: number;
}

/**
 * @param props - Properties.
 * @param props.prevBtn - Previous button.
 * @param props.nextBtn - Next button.
 * @param props.showing - Showing.
 * @returns {JSX.Element} - Component.
 */
export const DocSimplePagination: React.FC<DocSimplePaginationProps> = ({
  prevBtn,
  nextBtn,
  showing = 0,
}): JSX.Element | null => {
  const isEliteCustomer = useUserHasRole('CUSTOMER_ELITE');

  if (isEliteCustomer) return null;

  return (
    <Box
      mt={2}
      alignItems="center"
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="body2">Showing {showing}</Typography>
      <ButtonGroup>
        <Button color="inherit" {...prevBtn}>
          Previous
        </Button>
        <Button color="inherit" {...nextBtn}>
          Next
        </Button>
      </ButtonGroup>
    </Box>
  );
};
