import dayjs from 'dayjs';
import {
  CaseFilter,
  RelativePredicateOpEnum,
  RelativePredicateUnitEnum,
} from '../../shared/types/generated';
import { DriverType } from '../cases/case-types';

export type DashboardFilters = {
  driver?: DriverType;
  startDate?: string | undefined;
  customerId?: string | undefined;
};

/**
 * @param filters - Input filter object.
 * @returns Filters mapped to 8base vars.
 */
export function createCaseStatusFilter(filters: DashboardFilters): CaseFilter {
  const resultFilter: CaseFilter[] = [];
  if (filters.driver) {
    resultFilter.push({
      OR: [
        {
          driver: {
            id: {
              equals: filters.driver.id as string,
            },
          },
        },
      ],
    });
  }

  if (filters.customerId) {
    resultFilter.push({
      OR: [
        {
          driver: {
            customer: {
              id: {
                equals: filters.customerId as string,
              },
            },
          },
        },
      ],
    });
  }

  if (filters.startDate && filters.startDate !== '') {
    resultFilter.push({
      createdAt: {
        gte: dayjs(filters.startDate).toISOString(),
      },
    });
  } else {
    resultFilter.push({
      createdAt: {
        relative: {
          gte: {
            unit: RelativePredicateUnitEnum.Month,
            value: '11',
            op: RelativePredicateOpEnum.Sub,
          },
        },
      },
    });
  }

  return { AND: resultFilter };
}
