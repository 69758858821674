import { EverSignTemplate } from '../document-types';

/**
 * @param {EverSignTemplate} template - Template.
 * @returns {string[]} - Roles mapped.
 */
export const mapRoles = (template: EverSignTemplate): string[] => {
  const mappedData = template.templateId.signers.map(
    (signer) => signer?.role ?? '',
  );
  return mappedData ?? [];
};
