import React from 'react';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomerDriverFormType } from '../customer-driver-types';
import { Title } from '../../../shared/components/typography/Title';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { CustomSelect } from '../../../shared/components/selects/CustomSelect';
import { useDriverLanguageQuery } from '../../../shared/types/generated';
import { TextFormatPhone } from '../../../shared/components/inputs/TextFormatPhone';

/**
 * @returns {JSX.Element} - Component.
 */
export const CustomerDriverDetailsForm: React.FC = (): JSX.Element => {
  const methods = useFormContext<CustomerDriverFormType>();
  const { register, formState, control, setValue, watch, setError } = methods;

  const dateOfBirth = watch('dateOfBirth');

  const { data: driverLanguagesData } = useDriverLanguageQuery();

  // Map driver languages for select.
  const driverLanguageOptions =
    driverLanguagesData?.driverLanguagesList.items.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  return (
    <Stack direction="column" spacing={3}>
      <Title>Customer-Driver details</Title>
      <Stack direction="row" spacing={2}>
        <FormControl fullWidth variant="outlined" sx={{ gridColumn: 'span 2' }}>
          <TextField
            label="First Name"
            {...register('name')}
            helperText={formState.errors.name?.message}
            error={Boolean(formState.errors.name)}
          />
        </FormControl>
        <FormControl fullWidth variant="outlined" sx={{ gridColumn: 'span 2' }}>
          <TextField
            label="Last Name"
            {...register('lastName')}
            helperText={formState.errors.lastName?.message}
            error={Boolean(formState.errors.lastName)}
          />
        </FormControl>
      </Stack>

      <FormControl fullWidth variant="outlined">
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            label="Date of birth"
            onChange={(date: Date | null) => {
              const newDate = new Date(date as Date);

              if (date)
                setValue(
                  'dateOfBirth',
                  dayjs(newDate).format('YYYY-MM-DD') as string,
                );
              if (!date)
                setError('dateOfBirth', {
                  message: 'Introduce a valid date',
                });
            }}
            value={dateOfBirth || null}
            renderInput={(params) => (
              <CustomInput
                {...params}
                helperText={formState.errors.dateOfBirth?.message}
                error={Boolean(formState.errors.dateOfBirth?.message)}
                style={{ margin: 0, marginBottom: 0 }}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={{ gridColumn: 'span 2' }}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              label="Email"
              {...field}
              helperText={formState.errors.email?.message}
              error={Boolean(formState.errors.email)}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ gridColumn: 'span 2' }}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <CustomInput
              label="Phone"
              size="medium"
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              helperText={formState.errors.phoneNumber?.message}
              error={Boolean(formState.errors.phoneNumber?.message)}
              InputProps={{
                // eslint-disable-next-line
                inputComponent: TextFormatPhone as any,
              }}
              style={{ margin: 0 }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <Controller
          name="language"
          control={control}
          render={({ field }) => (
            <CustomSelect
              label="Language"
              size="medium"
              {...field}
              options={driverLanguageOptions}
              helperText={formState.errors.language?.message}
              error={Boolean(formState.errors.language?.message)}
              style={{ margin: 0, marginBottom: 24 }}
            />
          )}
        />
      </FormControl>
    </Stack>
  );
};
