import * as React from 'react';
import * as filestack from 'filestack-js';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';

import Loader from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  useFileCreateManyMutation,
  useCaseDocumentsListQuery,
  useCaseDocumentUpdateMutation,
  CaseDocumentCreateManyInput,
  useCaseDocumentCreateMutation,
  useCaseDocumentDeleteManagementMutation,
  useUserSessionQuery,
} from '../../../../../shared/types/generated';
import { clearQueriesFromCache } from '../../../../../shared/utils';
import { useDelay } from '../../../../../shared/hooks/delay';
import { CustomInput } from '../../../../../shared/components/inputs/CustomInput';
import { theme } from '../../../../../shared/css/theme';
import { useFileStack } from '../../../../../shared/hooks/filestack';
import { snackbar } from '../../../../../shared/components/Snackbar';
import {
  ImagenIcon,
  PdfIcon,
} from '../../../../../shared/components/icons/SVGIcons';
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
} from '../../../../../shared/components/ConfirmationDialog';
import { useCaseLog } from '../../../../../shared/hooks/caseLog';

const useStyles = makeStyles(() => ({
  addFile: {
    border: '3px solid #CCCCCC',
    width: '200px',
    height: '200px',
    margin: '20px 20px 20px 0px',
    borderRadius: '12px',
  },
  fileBox: {
    border: '3px solid #CCCCCC',
    width: '200px',
    height: '200px',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '12px',
    position: 'relative',
  },
}));

/**
 * @param {ButtonProps} props - Props for the button.
 * @returns {JSX.Element} - React element.
 */
export const ButtonBoxFile: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.addFile}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton {...props}>
        <AddIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};

export interface ButtonFoxFilePreviewProps {
  onRemoveFile?: () => void;
  filename: string;
  description?: string;
  onChangeDescription?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  downloadUrl?: string | null | undefined;
  enabledDescription?: boolean;
  loading?: boolean;
}

/**
 * @param {ButtonFoxFilePreviewProps} props - Props for the button.
 * @returns {JSX.Element} - React element.
 */
export const ButtonBoxFilePreview: React.FC<ButtonFoxFilePreviewProps> = ({
  enabledDescription = true,
  loading = false,
  ...props
}) => {
  const classes = useStyles();

  // Confirmation dialog ref.
  const confirmationDialogRef = React.useRef<ConfirmationDialogRef | null>(
    null,
  );

  // Popover anchorEl.
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  /**
   * @description - Handles the popover open.
   * @param {React.MouseEvent<HTMLButtonElement>} event - Event.
   * @returns {void} - Nothing.
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @description - Handles the popover close.
   * @returns {void} - Nothing.
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box style={{ margin: '20px 20px 5px 0px', width: '200px' }}>
        <Box
          className={classes.fileBox}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <IconButton
            onClick={() => {
              confirmationDialogRef.current?.onOpen();
            }}
          >
            <ClearIcon fontSize="large" />
          </IconButton>
          {props.filename.substring(
            props.filename?.length - 3,
            props.filename?.length,
          ) === 'pdf' ? (
            <>
              <PdfIcon style={{ color: '#3A526A' }} />
            </>
          ) : (
            <>
              <ImagenIcon style={{ color: '#3A526A' }} />
            </>
          )}

          <a
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
            href={props.downloadUrl ? props.downloadUrl : '#'}
          >
            <Link
              component="div"
              underline={props.downloadUrl ? 'hover' : 'none'}
            >
              {props.filename ? props.filename : 'Unnamed file'}
            </Link>
          </a>

          <Button
            sx={{ mt: 1 }}
            style={{ display: enabledDescription ? 'initial' : 'none' }}
            onClick={handleClick}
            size="small"
          >
            Description
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2 }}>
              <CustomInput
                fullWidth
                placeholder="Document description..."
                multiline
                rows={5}
                defaultValue={props.description}
                onChange={props.onChangeDescription}
                style={{ margin: 0 }}
              />
            </Box>
          </Popover>
        </Box>
        <p>{props.description}</p>
      </Box>

      {
        // Confirmation dialog delete.
      }
      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Delete file"
        description="This file is going to be deleted forever"
        isLoading={loading}
        onConfirm={() => {
          if (props.onRemoveFile) {
            props.onRemoveFile();
          }
        }}
      />
    </>
  );
};

interface CaseDocument {
  file: filestack.PickerFileMetadata;
  description?: string;
}

/**
 * @returns {JSX.Element} - Component.
 */
const AttachDocumentsZone: React.FC = () => {
  const [files, setFiles] = React.useState<Array<CaseDocument>>([]);

  const { filesStackResponse, handleAttachFiles, clearFilesStackResponse } =
    useFileStack({
      maxFiles: 100,
    });

  // Case id.
  const { id } = useParams<{ id: string }>();
  const { createALogUpdatedBy } = useCaseLog();

  // Create document.
  const [caseDocumentCreateMutation] = useCaseDocumentCreateMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      setFiles([]);
      clearFilesStackResponse();
      createALogUpdatedBy();
    },
    update: clearQueriesFromCache(['caseDocument', 'caseDocumentsList']),
  });

  // Create files on StackFiles
  const [fileCreateManyMutation, { loading }] = useFileCreateManyMutation();

  // Listen the attachment.
  React.useEffect(() => {
    if (!filesStackResponse.length) return; // No file attached

    // map the list of files.
    const newFiles: Array<CaseDocument> = filesStackResponse.map((file) => ({
      file,
      description: '',
    }));

    // Update state.
    setFiles([...files, ...newFiles]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesStackResponse]);

  /**
   * @description - Handles remove file.
   * @param {any} uploadId - Upload id.
   * @returns {void} - Nothing.
   */
  // eslint-disable-next-line
  const removeFileOnThePreview = (uploadId: any): void => {
    setFiles((v) => v.filter((file) => file.file.uploadId !== uploadId));
  };

  /**
   * @param {string} text - Description text.
   * @param {number} index - Index of the file.
   * @returns {void} - Nothing.
   */
  const handleChangeDescription = (text: string, index: number): void => {
    setFiles((v) => {
      const newFiles = [...v];
      newFiles[index].description = text;
      return newFiles;
    });
  };

  /**
   * @description - Handles the submit upload documents to 8base.
   * @returns {void} - Nothing.
   */
  const uploadDocumentsTo8base = (): void => {
    const preparedFiles = files.map((file) => ({
      fileId: file.file.handle,
      filename: file.file.filename,
    }));

    // File create many.
    fileCreateManyMutation({
      variables: {
        data: preparedFiles,
      },
    }).then((res) => {
      if (!res.data) return res;

      // Prepared the documents to connect to the case.
      const preparedCaseDocumentsConnection: CaseDocumentCreateManyInput[] =
        res.data.fileCreateMany.items.map((item, index) => ({
          caseRelation: {
            connect: {
              id,
            },
          },
          file: {
            connect: {
              fileId: item.fileId,
            },
          },
          description: files[index].description,
          type: 'GENERAL',
        }));

      // Iterable request case documents create.
      Promise.all(
        preparedCaseDocumentsConnection.map(async (item) => {
          await caseDocumentCreateMutation({
            variables: {
              data: item,
            },
          });
        }),
      );

      return res;
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {
          // File list attachment.
        }
        {files.map((value, index) => (
          <ButtonBoxFilePreview
            key={value.file.uploadId}
            filename={value.file.filename}
            description={value.description}
            onChangeDescription={(e) =>
              handleChangeDescription(e.target.value, index)
            }
            onRemoveFile={() => removeFileOnThePreview(value.file.uploadId)}
          />
        ))}
        {
          // Input file box.
        }
        <ButtonBoxFile onClick={handleAttachFiles} />
      </Box>

      {
        // Button confirm upload documents.
      }
      <Button
        onClick={uploadDocumentsTo8base}
        disabled={Boolean(!files.length || loading)}
      >
        Upload documents
      </Button>
    </>
  );
};

/**
 * @returns {JSX.Element} - Component.
 */
export const AttachDocumentsTab: React.FC = () => {
  // Case id.
  const { id } = useParams<{ id: string }>();
  const { createALogUpdatedBy } = useCaseLog();

  const { data: session } = useUserSessionQuery();

  const { data, loading, refetch } = useCaseDocumentsListQuery({
    variables: {
      filter: {
        caseRelation: {
          id: {
            equals: id,
          },
        },
      },
    },
  });

  const { delayExecution } = useDelay();

  const [
    caseDocumentDeleteManagement,
    { loading: caseDocumentDeleteManagementLoading },
  ] = useCaseDocumentDeleteManagementMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      createALogUpdatedBy();
    },
    /**
     * @param error - Error.
     * @returns {void} - Nothing.
     */
    onError: (error) => {
      snackbar.error(error.message.replace('ApolloError: ', ''));
    },
    update: clearQueriesFromCache(['caseDocument', 'caseDocumentsList']),
  });

  const [caseDocumentUpdateMutation] = useCaseDocumentUpdateMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      snackbar.success('Document description updated');
      createALogUpdatedBy();
    },
    update: clearQueriesFromCache(['caseDocument', 'caseDocumentsList']),
  });

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AttachDocumentsZone />
      <Divider sx={{ my: 2 }}>
        {!data?.caseDocumentsList.count && !loading
          ? 'Without documents.'
          : 'Documents'}
      </Divider>

      {
        // Spinner.
      }
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Loader
            type="ThreeDots"
            color={theme.palette.primary.main}
            width={40}
            height={40}
          />
        </Box>
      ) : null}

      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {data?.caseDocumentsList.items.map((value) => (
          <ButtonBoxFilePreview
            key={value.id}
            filename={value.file.filename}
            description={value.description}
            downloadUrl={value.file.downloadUrl}
            loading={caseDocumentDeleteManagementLoading}
            onChangeDescription={(e) => {
              delayExecution(() => {
                // Nothing to update.
                if (value.description === e.target.value) return;

                caseDocumentUpdateMutation({
                  variables: {
                    id: value.id,
                    data: {
                      description: e.target.value,
                    },
                  },
                });
              }, 500);
            }}
            onRemoveFile={async () => {
              await caseDocumentDeleteManagement({
                variables: {
                  filter: {
                    caseDocumentId: value.id,
                    userId: String(session?.user.id),
                  },
                },
              });
            }}
          />
        ))}
      </Box>
    </>
  );
};
