import * as React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Notification } from '../../shared/types/generated';

export interface NotificationMenuRef {
  open: (event: React.MouseEvent<HTMLButtonElement>) => void;
  close: () => void;
}

interface NotificationMenuProps {
  notifications: Array<Notification>;
  onClick?: (notification: Notification) => void;
  onClose?: () => void;
}

/**
 * @param {NotificationMenuProps} props - Properties.
 * @returns {JSX.Element} - Component.
 */
export const NotificationMenu = React.forwardRef<
  NotificationMenuRef,
  NotificationMenuProps
>((props, ref): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /**
   * @param {React.MouseEvent<HTMLButtonElement>} event - Event.
   * @returns {void} - Nothing.
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @returns {void} - Nothing.
   */
  const handleClose = (): void => {
    setAnchorEl(null);
    if (props.onClose) props.onClose(); // Emite close event.
  };

  React.useImperativeHandle(ref, () => ({
    /**
     * @returns {void} - Nothing.
     */
    open: handleClick,
    close: handleClose,
  }));

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {props.notifications.map((notification) => (
        <MenuItem
          key={notification.id}
          onClick={() => {
            // Emit.
            if (props.onClick) props.onClick(notification);
            // Close.
            handleClose();
          }}
        >
          {notification.content}
        </MenuItem>
      ))}

      {!props.notifications.length && (
        <Typography p={1}>There is no notifications</Typography>
      )}
    </Menu>
  );
});
