/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import {
  FormState,
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { Title } from '../../../shared/components/typography/Title';
import { StateSelector } from '../../settings/my-account/StateSelector';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { Address } from '../../../shared/types/generated';

/**
 * @param root0 - Props.
 * @param root0.index - Childer.
 * @param root0.add - Childer.
 * @param root0.remove - Childer.
 * @param root0.formState - Childer.
 * @param root0.control - Childer.
 * @param root0.setValue - Childer.
 * @param root0.watch - Childer.
 * @param root0.defaultValues - Childer.
 * @returns Component.
 */
export function AddAddressView({
  index,
  add,
  remove,
  formState,
  control,
  setValue,
  watch,
  defaultValues,
}: {
  index: number;
  add: () => void;
  remove: (number: number) => void;
  formState: FormState<{
    name: string;
    email: string;
    subscription: string;
    contactName: string;
    phoneNumber: string;
    customerAddressRelation: {
      apartment: string;
      state: string;
      street: string;
      city: string;
      zipCode: string;
    }[];
  }>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  defaultValues?: Address;
}): JSX.Element {
  const customerAddressRelationError =
    typeof formState.errors?.customerAddressRelation !== 'undefined' &&
    typeof formState.errors?.customerAddressRelation[index] !== 'undefined'
      ? formState.errors?.customerAddressRelation[index]
      : {};

  const state = watch('customerAddressRelation')?.[index]?.state;

  useFieldArray({
    control,
    name: 'customerAddressRelation',
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} alignItems="center" display="flex">
          <Title>Address</Title>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="end">
          {index === 0 ? (
            <Button
              variant="text"
              onClick={add}
              sx={{
                color: '#5BC2BA',
              }}
            >
              Add Address
            </Button>
          ) : (
            <Button variant="text" onClick={() => remove(index)} color="error">
              Remove
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`customerAddressRelation.${index}.state`}
            control={control}
            render={() => (
              <StateSelector
                value={defaultValues?.state}
                inputProps={{
                  style: { margin: 0 },
                  helperText:
                    customerAddressRelationError?.state?.message && !state
                      ? customerAddressRelationError.state?.message
                      : '',
                  error: Boolean(!state && customerAddressRelationError?.state),
                }}
                onChange={(v) => {
                  // set the value
                  setValue(`customerAddressRelation.${index}.state`, v);
                }}
                onChangeText={() => {
                  // Clear.
                  setValue(`customerAddressRelation.${index}.state`, '');
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`customerAddressRelation.${index}.city`}
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <CustomInput
                label="City"
                size="medium"
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                style={{ margin: 0 }}
                fullWidth
                helperText={customerAddressRelationError?.city?.message}
                error={Boolean(customerAddressRelationError?.city)}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name={`customerAddressRelation.${index}.street`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <CustomInput
                  label="Street Address"
                  size="medium"
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={{ margin: 0 }}
                  helperText={customerAddressRelationError?.street?.message}
                  error={Boolean(customerAddressRelationError?.street)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name={`customerAddressRelation.${index}.apartment`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <CustomInput
                  label="apartment Number"
                  size="medium"
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={{ margin: 0 }}
                  helperText={customerAddressRelationError?.apartment?.message}
                  error={Boolean(customerAddressRelationError?.apartment)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name={`customerAddressRelation.${index}.zipCode`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <CustomInput
                  label="Zip Code"
                  size="medium"
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={{ margin: 0 }}
                  helperText={customerAddressRelationError?.zipCode?.message}
                  error={Boolean(customerAddressRelationError?.zipCode)}
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
