import { PaletteColorOptions, PaletteColor } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { LinkBehavior } from '../components/buttons';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    delete: PaletteColorOptions;
    accent: PaletteColorOptions;
    surfaceOverlay: PaletteColorOptions;
    variant: PaletteColorOptions;
    black?: PaletteOptions['primary'];
    gray?: PaletteColorOptions;
    white?: PaletteColorOptions;
    caution?: PaletteColorOptions;
    icons: PaletteColorOptions;
  }

  interface PaperPropsVariantOverrides {
    card: true;
  }
  interface Palette {
    surfaceOverlay: PaletteColor;
    icons: PaletteColor;
    delete: PaletteColor;
    variant: PaletteColor;
    accent: PaletteColor;
    black: Palette['primary'];
    white: PaletteColor;
    gray: PaletteColor;
    caution: PaletteColor;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    card: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    delete: true;
    default: true;
  }
}

export const LAYOUT_SIZE = 60;

// Colors.
export const PRIMARY = '#3A526A';
export const PRIMARY_LIGHT = '#5BC2BA';

export const GRAY = '#969CBA';
export const DARK_GRAY = '#18151880';
export const LIGHT_GRAY = '#A6ACBE';
export const LIGHT_GRAY_2 = '#CCCCCC';

export const WARNING = '#FFB800';
export const ORANGE = '#FE5722';

export const SUCCESS = '#5BC2BA';
export const SUCCESS_LIGHT = '';

export const DANGER = '#EF5350';
export const CONTRAST_TEXT = '#FFFFFF';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: PRIMARY,
        contrastText: CONTRAST_TEXT,
      },
      icons: {
        main: '#C9CED6',
      },
      accent: {
        main: '#E62021',
      },
      secondary: {
        main: PRIMARY_LIGHT,
        light: '#7b4bc9',
      },
      success: {
        main: SUCCESS,
        contrastText: CONTRAST_TEXT,
      },
      error: {
        main: DANGER,
      },
      warning: {
        main: WARNING,
      },
      caution: { main: ORANGE },
      delete: {
        main: DANGER,
      },
      variant: {
        main: '#FFB800',
        light: '#5BC2BA',
      },
      surfaceOverlay: {
        main: '#21212108',
      },
      white: {
        main: WHITE,
      },
      black: {
        main: BLACK,
        dark: '#181518',
      },
      gray: {
        main: GRAY,
        light: LIGHT_GRAY_2,
        contrastText: DARK_GRAY,
      },
      text: {
        primary: GRAY,
        secondary: LIGHT_GRAY,
      },
    },
    typography: {
      body1: {
        color: DARK_GRAY,
      },
      body2: {
        color: DARK_GRAY,
      },
      h4: {
        color: BLACK,
      },
      h5: { color: BLACK },
      allVariants: {
        fontFamily: [
          'Poppins',
          'Roboto',
          'Helvetica',
          'Arial',
          'sans-serif',
        ].join(','),
        color: DARK_GRAY,
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            color: 'rgba(0,0,0,.75)',
          },
          colorPrimary: {
            color: CONTRAST_TEXT,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            left: '100px !important',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            minHeight: '100%',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            minHeight: '100%',
            overflowY: 'auto',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          groupLabel: {
            color: '#000',
            fontWeight: 'bold',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            maxHeight: '100%',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {},
        variants: [
          {
            props: {
              variant: 'card',
            },
            style: {
              borderRadius: '10px',
              padding: 20,
              boxShadow: '0px 3px 0px #EDEDF6',
              maxHeight: '100%',
              overflowY: 'auto',
            },
          },
        ],
      },
      MuiInputLabel: {
        defaultProps: {},
        styleOverrides: {
          outlined: {
            '&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
              top: '-7px',
            },
            // color: 'rgba(0,0,0,.75)',
          },
        },
      },
      MuiTableCell: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            color: DARK_GRAY,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            lineHeight: '1.5rem',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderColor: 'red',
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            '& fieldset': {
              border: '3px solid #CCCCCC',
              boxSizing: 'borderbox',
              borderRadius: '12px',
            },
            '& .MuiInputAdornment-root': {
              color: '#CCCCCC',
            },
            background: '#fff',
            color: 'rgba(0,0,0,.75)',
            lineHeight: '1rem',
            '& input': {
              border: 'none',
            },
            'label[data-shrink="false"] + &': {
              '& input': {
                color: 'transparent',
              },
            },
          },
        },
        // variants: {},
      },
      MuiFab: {
        styleOverrides: {
          circular: {
            borderRadius: '24px',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'large',
          disableElevation: true,
          color: 'primary',
          variant: 'contained',
          style: {
            textTransform: 'none',
          },
        },
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
        variants: [
          {
            props: {
              variant: 'delete',
            },
            style: {
              color: DANGER,
              '&:hover': {
                backgroundColor: '#EF53503B',
              },
            },
          },
          {
            props: {
              variant: 'default',
            },
            style: {
              color: GRAY,
              '&:hover': {
                backgroundColor: '#b6b6b62d',
              },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'error',
            },
            style: {
              color: '#EF5350',
              backgroundColor: '#EF53503B',
              borderRadius: '12px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#EF5350',
                color: '#fafafa',
                boxShadow: 'none',
              },
            },
          },
          {
            props: {
              variant: 'contained',
              color: 'secondary',
            },
            style: {
              color: '#fff',
              backgroundColor: '#5BC2BA',
              borderRadius: '12px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#207f77',
                color: '#fafafa',
                boxShadow: 'none',
              },
            },
          },
          {
            props: {
              variant: 'text',
              color: 'primary',
            },
            style: {
              color: ' #3A526A',
              fontWeight: 'bold',
            },
          },
        ],
      },
      MuiTypography: {},
    },
  },
  {
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
        variants: [
          {
            props: {
              color: 'blue',
            },
            style: {
              color: GRAY,
              '&:hover': {
                color: PRIMARY,
              },
            },
          },
          {
            props: {
              color: 'gray',
            },
            style: {
              textAlign: 'left',
              svg: { fontSize: '12px' },
            },
          },
        ],
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiChip: {
        variants: [
          {
            props: {
              variant: 'outlined',
            },
            style: {
              border: 'none',
              fontSize: '15px',
              svg: {
                width: '10px',
              },
            },
          },
          {
            props: {
              color: 'error',
            },
            style: {
              color: DANGER,
              svg: {
                color: DANGER,
              },
            },
          },
          {
            props: {
              color: 'secondary',
            },
            style: {
              color: PRIMARY_LIGHT,
              svg: {
                color: PRIMARY_LIGHT,
              },
            },
          },
          {
            props: {
              color: 'warning',
            },
            style: {
              color: '#FFB800',
              svg: {
                color: '#FFB800',
              },
            },
          },
        ],
      },
    },
  },
);
