import { FC, useEffect } from 'react';
import { Box, Grid, Button, TextField, Divider } from '@mui/material';
import { useHistory } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormContext } from 'react-hook-form';
import { BackButton } from '../../../../shared/components/buttons';
import { PaperStyled } from '../../../../shared/components/card/Card';
import { PRIMARY_LIGHT, PRIMARY } from '../../../../shared/css/theme';
import { useUserHasRole } from '../../../session/session-hooks';
import { SignersForm } from '../../components/SignersForm';
import type { EverSignTemplate } from '../../document-types';
import type { DocumentTemplateFormType } from '../document-template-types';
import { TemplateAutocomplete } from './TemplateAutocomplete';
import { TemplateRoleSelect } from './TemplateRoleSelect';
import { AutocompleteForm } from './AutocompleteForm';

interface DocumentTemplateFormProps {
  templates?: EverSignTemplate[];
  roles: string[];
  sendButtonDisabled: boolean;
  sendingDoc: boolean;
  driverInputSwitch: boolean;
  handleDeleteAdditionalSigner: (email: string) => void;
  handleAddAdditionalSigner: () => void;
  toggleDriverSwitch: () => void;
  onSubmit: () => Promise<void>;
  submitHelperText: string | null;
}

/**
 * DocumentTemplateForm.
 *
 * @param {DocumentTemplateFormProps} props - Props.
 * @returns {JSX.Element} DocumentTemplateForm.
 */
export const DocumentTemplateForm: FC<DocumentTemplateFormProps> = ({
  templates,
  roles,
  handleAddAdditionalSigner,
  handleDeleteAdditionalSigner,
  toggleDriverSwitch,
  onSubmit,
  sendingDoc,
  sendButtonDisabled,
  driverInputSwitch,
  submitHelperText,
}): JSX.Element => {
  const { register, formState, watch } =
    useFormContext<DocumentTemplateFormType>();
  const { errors } = formState;
  const { signers, signerEmail, signerName, role, driver } = watch();
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');
  const history = useHistory();
  const signerDataComplete = !!signerEmail && !!signerName && !!role;
  const disableSignersInput = !!driverInputSwitch || !role;
  const driverInputSwitchText = driverInputSwitch
    ? 'Remove Driver'
    : 'Add driver as Signer';
  const driverInputSwitchTextDisplay = isCustomerElite ? 'none' : 'flex';

  useEffect(() => {
    if (isCustomerElite) toggleDriverSwitch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite]);

  return (
    <Box mt="20px" padding="0px 10px">
      <Grid container spacing={1} padding={1}>
        <Box
          width={{ xs: '100%', sm: 'auto' }}
          display="flex"
          alignItems="center"
        >
          <BackButton
            onClick={() => history.push('/document-management')}
            text="Back to Document Management"
          />
        </Box>
        <Grid container marginBottom={2} alignItems="center">
          <Grid item sm={12} md={4}>
            <Box color={PRIMARY} fontSize="18px" component="h2">
              Create document from Template
            </Box>
          </Grid>

          <Grid container item sm={12} md={8} justifyContent="flex-end">
            <LoadingButton
              disabled={sendButtonDisabled}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={onSubmit}
              loading={sendingDoc}
            >
              Send Document
            </LoadingButton>
          </Grid>
          <Grid container item sm={12} justifyContent="flex-end">
            {submitHelperText}
          </Grid>
        </Grid>
      </Grid>
      <PaperStyled>
        <Box padding={3}>
          <TextField
            type="text"
            error={Boolean(errors.documentTitle)}
            helperText={errors.documentTitle?.message}
            fullWidth
            label="Document Title"
            {...register('documentTitle')}
          />
        </Box>
        <Divider />
        <Grid padding={3} spacing={3} container>
          <Grid item xs={12}>
            <TemplateAutocomplete templates={templates} />
          </Grid>
          <Grid item xs={12}>
            <TemplateRoleSelect roles={roles} />
          </Grid>

          <Grid item xs={12} container alignItems="center" spacing={3}>
            <Grid item color={PRIMARY} component="h2" fontSize="18px">
              Signers
            </Grid>
            <Grid
              display={driverInputSwitchTextDisplay}
              item
              color={PRIMARY_LIGHT}
              component="p"
              fontSize="16px"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={toggleDriverSwitch}
            >
              {driverInputSwitchText}
            </Grid>
          </Grid>

          <AutocompleteForm show={driverInputSwitch} />
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={disableSignersInput}
              key={`signerName-${driver}`}
              label="Signer's Name"
              type="text"
              fullWidth
              {...register('signerName')}
            />
          </Grid>
          <Grid item xs={12} sm={6} container alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <TextField
                disabled={disableSignersInput}
                key={`signerEmail-${driver}`}
                label="Signer's Email"
                type="text"
                fullWidth
                helperText={formState.errors.signerEmail?.message}
                error={Boolean(formState.errors.signerEmail?.message)}
                {...register('signerEmail')}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                disabled={!signerDataComplete}
                variant="contained"
                color="secondary"
                onClick={handleAddAdditionalSigner}
                sx={{
                  padding: '5px',
                  minWidth: 'unset',
                }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box padding={3}>
          <SignersForm
            signers={signers}
            roles={roles || []}
            onDelete={handleDeleteAdditionalSigner}
          />
        </Box>
      </PaperStyled>
    </Box>
  );
};
