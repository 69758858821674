import React from 'react';
import { Tooltip, Typography } from '@mui/material';

interface DocCardTitleProps {
  title: string;
}

/**
 * @param props - Properties.
 * @param props.title - Title.
 * @returns {JSX.Element} - Component.
 */
export const DocCardTitle: React.FC<DocCardTitleProps> = ({
  title,
}): JSX.Element => (
  <Tooltip title={title}>
    <Typography
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      color="lightgray"
      mb={1.8}
    >
      {title}
    </Typography>
  </Tooltip>
);
