import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DocumentTemplateFormType } from '../document-template-types';

/**
 * @param props - Properties.
 * @param props.roles - Roles.
 * @returns {JSX.Element} - Component.
 */
export const TemplateRoleSelect: React.FC<{
  roles: string[];
}> = ({ roles }): JSX.Element => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<DocumentTemplateFormType>();

  const role = watch('role');

  return (
    <Controller
      control={control}
      name="role"
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth>
          <InputLabel>Roles</InputLabel>
          <Select
            key={`role-select-key-${role}`}
            disabled={!roles.length}
            label="Roles"
            value={value}
            onChange={onChange}
            error={Boolean(errors.role)}
          >
            {roles.map((signer) => (
              <MenuItem key={signer} value={signer}>
                {signer}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
