import * as yup from 'yup';
import { INVALID_EMAIL_MESSAGE } from '../../../shared/constants';

const requiredFieldMsg = 'This field is required';

export const DOCUMENT_CREATION_FORM_VALIDATOR_SCHEMA = yup
  .object({
    signerEmail: yup.string().email(INVALID_EMAIL_MESSAGE),
    signerLastName: yup.string().notRequired(),
    signerName: yup.string(),
    // only required for filtering the drivers. Not Required
    customer: yup.string(),
    // only used for setting the signer email and name. Not Required
    driver: yup.string(),
    documentTitle: yup
      .string()
      .max(100, 'Max 100 characters.')
      .required(requiredFieldMsg),
    description: yup.mixed().required(requiredFieldMsg),
    signers: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required(),
          lastName: yup.string().notRequired(),
          email: yup.string().email(INVALID_EMAIL_MESSAGE).required(),
          driverId: yup.string().optional(),
        }),
      )
      .required(requiredFieldMsg),
  })
  .required();
