import { Document } from 'eversign-enhance';
import {
  EVERSIGN_API_KEY,
  EVERSIGN_API_URL,
  EVERSIGN_BUSINESS_ID,
} from '../../shared/constants';

/**
 * @param doc - Doc.
 * @returns {Promise<void>} - Nothing.
 */
export const downloadFinalDocumentService = async (
  doc: Document,
): Promise<void> =>
  fetch(
    `${EVERSIGN_API_URL}/download_final_document?${new URLSearchParams({
      access_key: EVERSIGN_API_KEY,
      business_id: String(EVERSIGN_BUSINESS_ID),
      document_hash: doc.getDocumentHash(),
      audit_trail: '0',
    })}`,
  )
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${doc.getTitle()}.pdf`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });

/**
 * @description - Sends a document to the Eversign API.
 * @param doc - The document to send.
 * @returns {Promise<void>} - A promise that resolves when the document has been sent.
 */
export const updateDocumentToInProgressService = (
  doc: Document,
): Promise<void> => {
  // Define constants for the endpoint URL and headers.
  const endpointUrl = `${EVERSIGN_API_URL}/document?${new URLSearchParams({
    access_key: EVERSIGN_API_KEY,
    business_id: String(EVERSIGN_BUSINESS_ID),
  })}`;

  const headers = {
    'Content-Type': 'application/json',
  };

  // Send the document to the API using fetch.
  return fetch(endpointUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      title: doc.getTitle(),
      document_hash: doc.getDocumentHash(),
      is_draft: 0,
      files: doc.getFiles().map((file) => ({
        name: file.toObject().name,
        file_id: file.toObject().fileId,
      })),
      signers: doc.getSigners().map(({ getId, getName, getEmail }) => ({
        id: getId(),
        name: getName(),
        email: getEmail(),
      })),
    }),
  })
    .then((res) => res.json())
    .catch((err) => {
      // Add error handling for any errors that occur during the request.
      console.error('Error sending document to Eversign:', err);
      throw err;
    });
};
