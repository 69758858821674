export enum NotificationObjectResourcesEnum {
  CASE = 'CASE',
  CASE_COMMENT = 'CASE_COMMENT',
  CASE_DOCUMENT = 'CASE_DOCUMENT',
}

export enum NotificationStatusEnum {
  UNVIEWED = 'UNVIEWED',
  VIEWED = 'VIEWED',
}
