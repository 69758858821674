import { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';

type Props = {
  defaultPage?: number | undefined;
  defaultPageSize?: number | undefined;
};

export type OnChangeParams = {
  page?: number;
  pageSize?: number;
};
/**
 * @param {number} defaultPage  - Default page to render.
 * @returns {[number,Function]} - State tuple.
 */
export function usePagination({
  defaultPage = 1,
  defaultPageSize = 20,
}: Props = {}): [
  { page: number; pageSize: number },
  (n: OnChangeParams) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState(() => {
    const query = new URLSearchParams(location.search);
    const currentPage = query.get('page')
      ? Number(query.get('page'))
      : defaultPage;
    const currentPageSize = query.get('pageSize')
      ? Number(query.get('pageSize'))
      : defaultPageSize;

    return {
      page: currentPage,
      pageSize: currentPageSize,
    };
  });

  /**
   * @param value - Params.
   */
  const onChange = (value: OnChangeParams): void => {
    const query = new URLSearchParams(location.search);
    if (value.page) {
      query.set('page', value.page.toString());
    }
    if (value.pageSize) {
      query.set('pageSize', value.pageSize.toString());
    }

    const push = {
      pathname: location.pathname,
      search: query.toString(),
    };
    history.push(push);

    setPage((prev) => ({ ...prev, ...value }));
  };
  return [page, onChange];
}

/**
 * @param state - Errors.
 * @param callback - Callback.
 */
export function useSubscribeError<T>(
  state: UseFormReturn<T>,
  callback: (errors: UseFormReturn<T>['formState']['errors']) => void,
): void {
  const callBackRef = useRef(callback);
  callBackRef.current = callback;

  useEffect(() => {
    if (Object.keys(state.formState.errors).length > 0) {
      callBackRef.current(state.formState.errors);
    }
  }, [state.formState.errors]);
}

interface UrlParamsValues {
  key: string;
  value: string | null | undefined;
}

interface UseUrlParamsReturn {
  setUrlFilterParams: (
    params: UrlParamsValues | Array<UrlParamsValues>,
  ) => void;
  clearSpecificUrlParams: (keys: string | Array<string>) => void;
  query: URLSearchParams;
}

/**
 * @returns {Function} - Callback.
 */
export function useUrlParams(): UseUrlParamsReturn {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  /**
   * @description - Set url params.
   * @param {UrlParamsValues | Array<UrlParamsValues>} params - Params.
   * @returns {void} - Nothing.
   */
  const setUrlFilterParams = (
    params: UrlParamsValues | Array<UrlParamsValues>,
  ): void => {
    if (Array.isArray(params)) {
      params.forEach((param) => {
        if (param.value) {
          query.set(param.key, param.value);
        } else {
          query.delete(param.key);
        }
      });
    }

    if (!Array.isArray(params)) {
      if (params.value) {
        query.set(params.key, params.value);
      } else {
        query.delete(params.key);
      }
    }

    const push = {
      pathname: location.pathname,
      search: query.toString(),
    };

    history.push(push);
  };

  /**
   * @description - Clear specific url params.
   * @param {string | Array<string>} keys - Params.
   * @returns {void} - Nothing.
   */
  const clearSpecificUrlParams = (keys: string | Array<string>): void => {
    if (Array.isArray(keys)) {
      keys.forEach((key) => {
        query.delete(key);
      });
    }

    if (!Array.isArray(keys)) {
      query.delete(keys);
    }

    const push = {
      pathname: location.pathname,
      search: query.toString(),
    };

    history.push(push);
  };

  return {
    setUrlFilterParams,
    clearSpecificUrlParams,
    query,
  };
}
