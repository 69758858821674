import { useRef } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const caseDetailsRegex = /cases\/details/g;

/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @returns Route component.
 */
export const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth0();
  const queryParam = useRef<string | undefined>();

  const { pathname } = useLocation();

  if (pathname.match(caseDetailsRegex))
    queryParam.current = `caseId=${pathname.split('/').pop()}`;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              search: queryParam.current,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
