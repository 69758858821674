/**
 * {@link https://eversign.com/api/documentation/embedded-requesting#client-side Documentation}.
 */

interface EmbedDocIntoIframeParams {
  embeddedClaimUrl: string;
  iframeID: string;
  width?: number;
  height?: number;
  onLoad: () => unknown;
  onSubmit: () => unknown;
  onError?: () => unknown;
}

declare const eversignEmbeddedRequesting: {
  open: (params: { [key: string]: unknown }) => unknown;
};

/**
 * Loads the Eversing script and appends a script element in the body if this does'nt exits.
 *
 * @param {EmbedDocIntoIframeParams} params - Params.
 */
export const loadEversingDoc = (params: EmbedDocIntoIframeParams): void => {
  let existingScript = document.getElementById('evesingScript');

  let script: HTMLScriptElement;

  if (existingScript) {
    existingScript.remove();
    existingScript = null;
  }

  if (!existingScript) {
    const {
      embeddedClaimUrl,
      iframeID,
      height,
      width,
      onLoad,
      onError,
      onSubmit,
    } = params;
    const docHeight = height || 800;
    const docWidth = width || 1300;

    script = document.createElement('script');
    script.src = 'https://static.eversign.com/js/embedded-requesting.js';
    script.id = 'evesingScript';
    document.body.appendChild(script);

    // eslint-disable-next-line
    script.onerror = () => {
      if (onError) onError();
    };

    /**
     * On script load handler.
     */
    script.onload = () => {
      eversignEmbeddedRequesting.open({
        url: embeddedClaimUrl,
        containerID: iframeID,
        width: docWidth,
        height: docHeight,
        events: {
          // eslint-disable-next-line
          loaded: () => {
            onLoad();
          },
          // eslint-disable-next-line
          submit: () => {
            onSubmit();
          },
          // eslint-disable-next-line
          error: () => {
            if (onError) onError();
          },
        },
      });
    };
  }
};
