import { useMemo, FC, useState, ChangeEvent, useEffect } from 'react';
import {
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from '@mui/material';
import { useUrlParams } from '../../../../shared/hooks/hooks';
import { useGetDraftsQuery } from '../../../../shared/types/generated';
import { ContentDialog } from '../../../../shared/components/dialogs/ContentDialog';
import { CIRCULAR_PROCESS_MEDIUM_SIZE } from '../../../../shared/constants';

interface Props {
  onCancel: () => void;
  onConfirm: (value: string) => void;
  open: boolean;
}

/**
 *
 * @param {Props} props - Props.
 * @returns {JSX.Element} DraftListDialog.
 */
export const DraftListDialog: FC<Props> = ({
  onCancel,
  open,
  onConfirm,
}): JSX.Element => {
  const { query } = useUrlParams();
  const { data, loading, refetch, called } = useGetDraftsQuery({
    // adding notifyOnNetworkStatusChange: true, triggers loading to change even when calling refetch.
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [value, setValue] = useState<string | null>(query.get('draftId'));

  useEffect(() => {
    // refetch every time that the dialog opens
    if (open && called) refetch();
  }, [open, called, refetch]);

  /**
   *
   * @param {ChangeEvent<HTMLInputElement>} event - Event.
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value);
  };

  const renderList = useMemo(() => {
    if (loading || !data)
      return (
        <Stack minWidth={500} alignItems="center" justifyContent="center">
          <CircularProgress size={CIRCULAR_PROCESS_MEDIUM_SIZE} />
        </Stack>
      );

    const listData = data.documentDraftsList.items;

    if (listData.length === 0) return <p> Draft list is empty </p>;

    if (listData.length > 0)
      return (
        <Stack
          direction="column"
          height={200}
          minWidth={500}
          overflow="scroll"
          style={{ overflowX: 'hidden' }}
        >
          {listData.map((e) => (
            <FormControl key={e.id}>
              <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel
                  value={e.id}
                  control={<Radio />}
                  label={e.documentTitle}
                />
              </RadioGroup>
            </FormControl>
          ))}
        </Stack>
      );

    return <p> Something went wrong </p>;
  }, [data, loading, value]);

  /**
   *
   */
  const handleConfirm = (): void => {
    if (value) onConfirm(value);
  };

  return (
    <ContentDialog
      title="Custom Drafts"
      subTitle="Select a previous draft to create a custom document"
      open={open}
      loading={false}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      {renderList}
    </ContentDialog>
  );
};
