import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useGetUserListQuery } from '../../types/generated';
import { USER_ROLES } from '../../../modules/session/session-model';
import { generateAvailableOptions } from './rich-text-editor-utils';
import { RichTextEditorProps } from './rich-text-editor-types';

/**
 * @param {RichTextEditorProps} props - Editor Properties.
 * @returns {JSX.Element} - Component.
 */
export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  helperText,
  error,
  dropDownOptions,
  editorRef,
  defaultState,
  fullHeight = false,
  getRawDraftContentState = false,
  inlineOptions = 'all',
}) => {
  const { availableOptionsResult, optionsArray } = useMemo(
    () => generateAvailableOptions(inlineOptions, dropDownOptions),
    [inlineOptions, dropDownOptions],
  );

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const theme = useTheme();

  const { data } = useGetUserListQuery({
    variables: {
      filter: {
        roles: {
          some: {
            name: {
              equals: USER_ROLES.AGENT,
            },
          },
        },
      },
    },
  });

  /**
   *
   * @param {EditorState} e - Event value.
   * @returns {void} - Nothing.
   */
  const onEditorStateChange = (e: EditorState): void => {
    setEditorState(e);
  };

  // Initialize values.
  useEffect(() => {
    if (!value) {
      return;
    }
    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (value) return;
    setEditorState(EditorState.createEmpty());
  }, [value]);

  useEffect(() => {
    if (defaultState)
      setEditorState(
        EditorState.createWithContent(convertFromRaw(defaultState)),
      );
  }, [defaultState]);

  useEffect(() => {
    if (onChange) {
      if (getRawDraftContentState) {
        onChange(convertToRaw(editorState.getCurrentContent()));
      } else {
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }
    }

    // eslint-disable-next-line
  }, [editorState]);

  /**
   * SetEditorReference.
   *
   * @param {object} ref - Ref.
   */
  const setEditorReference = (ref: HTMLElement): void => {
    // eslint-disable-next-line
    if (editorRef) editorRef.current = ref;
  };

  const wrapperHeight = fullHeight ? '100%' : '80%';

  return (
    <Box width="100%" height="550px">
      <Editor
        editorRef={setEditorReference}
        toolbar={{
          options: optionsArray,
          ...availableOptionsResult,
        }}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        wrapperStyle={{
          width: '100%',
          height: wrapperHeight,
        }}
        editorStyle={{
          border: `1px solid ${error ? theme.palette.error.main : '#F1F1F1'}`,
          width: '100%',
          height: wrapperHeight,
          padding: '6px 5px 0px',
        }}
        onEditorStateChange={onEditorStateChange}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: data?.usersList.items.map((user) => ({
            text: user.firstName
              ? `${user.firstName} ${user.lastName ? user.lastName : ''}`
              : user.email,
            value: user.firstName
              ? `${user.firstName} ${user.lastName ? user.lastName : ''}`
              : user.email,
            url: user.email,
          })),
        }}
      />
      <Typography
        variant="caption"
        color={error ? 'error' : 'CaptionText'}
        style={{ marginTop: '20px', display: helperText ? 'block' : 'none' }}
      >
        {helperText}
      </Typography>
    </Box>
  );
};
