import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEvent } from '@cobuildlab/react-simple-state';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { DocumentButtonNavigation } from './DocumentButtonNavigation';
import {
  DefaultRowSize,
  TableToolbar,
} from '../../../shared/components/table/TableToolbar';
import { useUserHasRole } from '../../session/session-hooks';
import {
  downloadFinalDocumentService,
  updateDocumentToInProgressService,
} from '../document-services';
import { OnDocumentUIStateEvent } from '../documents-events';
import { DocumentTypeEnum } from '../document-types';
import { snackbar } from '../../../shared/components/Snackbar';
import { useDelay } from '../../../shared/hooks/delay';

/**
 * @returns {JSX.Element} - Component.
 */
export const DocToolbar: React.FC = (): JSX.Element => {
  const queryClient = useQueryClient();
  const state = useEvent(OnDocumentUIStateEvent);
  const { type, documentsSelected } = state;
  const { delayExecution } = useDelay();
  const showToolbar = useUserHasRole(['ADMIN', 'AGENT']);

  // Query to download docs.
  const { isLoading, isFetching, refetch } = useQuery(
    'Download',
    async () => {
      try {
        await Promise.all([
          ...documentsSelected.map((item) =>
            downloadFinalDocumentService(item),
          ),
        ]);
        snackbar.success('Successful download');
      } catch (error) {
        // ...
        snackbar.error('There are problems to download some documents.');
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  /**
   * @description - Reset the selection and the filter.
   * @returns {void} - Nothing.
   */
  const resetState = (): void => {
    OnDocumentUIStateEvent.dispatch({
      ...state,
      documentsSelected: [],
      type: DocumentTypeEnum.ALL,
    });
  };

  const { mutate, isLoading: isLoadingSendDocuments } = useMutation(
    'SendDocuments',
    async (): Promise<void[]> =>
      Promise.all(
        documentsSelected.map((doc) => updateDocumentToInProgressService(doc)),
      ),
    {
      retry: false,
      /**
       * @returns {void} - Nothing.
       */
      onSuccess: (): void => {
        queryClient.invalidateQueries('Documents');
        resetState();
        snackbar.success('Doc(s) sent successfully!');
      },
      /**
       * @returns {void} - Nothing.
       */
      onError: () => {
        snackbar.error('Error sending the docs.');
      },
    },
  );

  /**
   * @param {string} text - Text.
   * @returns {void} - Nothing.
   */
  const onSearch = (text: string): void => {
    OnDocumentUIStateEvent.dispatch({
      ...state,
      filter: {
        ...state.filter,
        search: text,
      },
    });
  };

  /**
   * @param {DefaultRowSize} rows - Rows.
   * @returns {void} - Nothing.
   */
  const onSelectRow = (rows: DefaultRowSize): void => {
    OnDocumentUIStateEvent.dispatch({
      ...state,
      filter: {
        ...state.filter,
        rows: Number(rows),
      },
    });
  };

  /**
   * @returns {Promise<void>} - Nothing.
   */
  const handleTheDownloadCompletedDocuments = async (): Promise<void> => {
    // Type changes to select the completed docs to download.
    if (type === DocumentTypeEnum.ALL) {
      OnDocumentUIStateEvent.dispatch({
        ...state,
        type: DocumentTypeEnum.COMPLETED,
      });
      return;
    }

    if (documentsSelected.length) {
      // Download all documents selected.
      await refetch();
      resetState();
    }
  };

  /**
   * @returns {Promise<void>} - Nothing.
   */
  const handleSendDocumentsInDraft = async (): Promise<void> => {
    // on send click.
    if (type === DocumentTypeEnum.ALL) {
      OnDocumentUIStateEvent.dispatch({
        ...state,
        type: DocumentTypeEnum.DRAFTS,
      });
      return;
    }

    if (documentsSelected.length) {
      // Submit to send the documents in status draft.
      await mutate();
    }
  };

  return (
    <Grid item xs={12}>
      <TableToolbar
        showTheRowSelector={showToolbar}
        showTheRowSearch={showToolbar}
        rightComponentIsLarger
        rightComponent={
          <DocumentButtonNavigation
            viewText={
              type === DocumentTypeEnum.COMPLETED ? 'Submit' : 'Download'
            }
            sendText={
              type === DocumentTypeEnum.DRAFTS ? 'Submit' : 'Send Document'
            }
            hideBtnView={
              ![DocumentTypeEnum.ALL, DocumentTypeEnum.COMPLETED].includes(
                type as DocumentTypeEnum,
              )
            }
            hideBtnSend={
              ![DocumentTypeEnum.ALL, DocumentTypeEnum.DRAFTS].includes(
                type as DocumentTypeEnum,
              )
            }
            hideBtnAux={
              ![DocumentTypeEnum.COMPLETED, DocumentTypeEnum.DRAFTS].includes(
                type as DocumentTypeEnum,
              )
            }
            onAuxClick={resetState}
            onViewClick={handleTheDownloadCompletedDocuments}
            onSendClick={handleSendDocumentsInDraft}
            disableButtons={Boolean(
              !documentsSelected.length && type !== DocumentTypeEnum.ALL,
            )}
            options={['Send', 'View', 'Navigation']}
          />
        }
        onSearch={(text) => delayExecution(() => onSearch(text), 300)}
        onSelectRow={onSelectRow}
      />
      <Backdrop
        sx={{
          /**
           * @param theme - Theme.
           * @returns {void} - Nothing.
           */
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={Boolean(isLoading || isFetching || isLoadingSendDocuments)}
      >
        <CircularProgress />
      </Backdrop>
    </Grid>
  );
};
