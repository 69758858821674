import { ROUTES } from '../../routes/routes-model';
import { useUserSessionQuery } from '../../shared/types/generated';
import { RolesType, USER_ROLES } from './session-model';
import type { UserRoute } from './session-types';

/**
 * @param roleToCheck - Roles.
 * @param userRoles - User roles.
 * @returns Result.
 */
export function check(
  roleToCheck: RolesType,
  userRoles: {
    __typename?: 'Role' | undefined;
    name: string;
  }[],
): boolean {
  const role = userRoles.find((item) => item.name === USER_ROLES[roleToCheck]);

  return Boolean(role);
}
/**
 * @param roles - Input roles.
 * @returns Boolean if the user has the roles.
 */
export function useUserHasRole(
  roles: RolesType | RolesType[] | undefined,
): boolean {
  const sessionState = useUserSessionQuery();

  if (roles === undefined) {
    return true;
  }
  if (!sessionState?.data) {
    return false;
  }

  const userRoles = sessionState.data.user.roles.items;

  return typeof roles === 'string'
    ? check(roles, userRoles)
    : roles.some((roleItem) => check(roleItem, userRoles));
}

/**
 * @returns Boolean if the user has the roles.
 */
export function useUserRoutes(): UserRoute {
  const sessionState = useUserSessionQuery();

  if (!sessionState?.data) {
    return [];
  }

  const userRoles = sessionState.data.user.roles.items;
  return Object.values(ROUTES).filter((route) =>
    route.rolePermission
      ? route.rolePermission.some((role) => check(role, userRoles))
      : true,
  );
}

/**
 * @param userRoles - User roles.
 * @returns Result.
 */
export function useUserRole(
  userRoles: {
    __typename?: 'Role' | undefined;
    name: string;
  }[],
): string | null {
  const values = Object.values(USER_ROLES);
  let role: { __typename?: 'Role' | undefined; name: string } = { name: '' };
  values.forEach((val) => {
    const roleFiltered = userRoles.find((item) => item.name === val);
    if (roleFiltered) role = roleFiltered;
  });
  if (role) return role?.name;

  return null;
}
