import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Color, IconButton, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';

interface ConfirmationDialogProps {
  title: string;
  description: string | React.ReactNode;
  onConfirm: () => void;
  onClose?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  disableBackdropClick?: boolean;
  isLoading?: boolean;
}

export interface ConfirmationDialogRef {
  onClose: () => void;
  onOpen: () => void;
}

/**
 * @param {ConfirmationDialogProps} props - Properties.
 * @returns {JSX.Element} - Confirmation dialog.
 */
export const ConfirmationDialog = React.forwardRef<
  ConfirmationDialogRef,
  ConfirmationDialogProps
>((props, ref): React.ReactElement => {
  const [show, setShow] = React.useState<boolean>(false);

  /**
   * @description - Close dialog.
   * @returns {void}
   */
  const handleClose = (): void => setShow(false);

  /**
   * @description - Open dialog.
   * @returns {void}
   */
  const handleOpen = (): void => setShow(true);

  React.useImperativeHandle(ref, () => ({
    onClose: handleClose,
    onOpen: handleOpen,
  }));

  return (
    <Dialog
      open={show}
      onClose={() => {
        if (!props.disableBackdropClick && !props.isLoading) {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        {!props.disableBackdropClick && !props.isLoading ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              /**
               * @param {theme} theme - Theme.
               * @returns {Color} - Color.
               */
              color: (theme: Theme) => theme.palette.grey[800],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: grey[500] }}
        >
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.isLoading ? (
          <Button
            variant="text"
            style={{ textTransform: 'none', color: grey[500] }}
            onClick={() => {
              if (props.onClose) props.onClose();
              handleClose();
            }}
          >
            {props.cancelButtonText || 'Cancel'}
          </Button>
        ) : null}

        <LoadingButton
          disabled={props.isLoading}
          variant="text"
          style={{ textTransform: 'none' }}
          onClick={props.onConfirm}
          autoFocus
          color="error"
          loading={props.isLoading}
        >
          {props.confirmButtonText || 'Delete'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
