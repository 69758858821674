import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useUserHasRole } from '../../../session/session-hooks';
import { TemplateCustomersAutocomplete } from './TemplateCustomersAutocomplete';
import { TemplateDriversAutocomplete } from './TemplateDriversAutocomplete';

interface Props {
  show: boolean;
}

/**
 * @param {Props} props - Props.
 * @returns {JSX.Element} AutocompleteForm.
 */
export const AutocompleteForm: FC<Props> = ({ show }): JSX.Element | null => {
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');
  const driverInputMdSize = isCustomerElite ? 12 : 6;
  const customerInputDisplay = isCustomerElite ? 'none' : 'block';
  if (!show) return null;
  return (
    <>
      <Grid item xs={12} sm={6} display={customerInputDisplay}>
        <TemplateCustomersAutocomplete />
      </Grid>
      <Grid item xs={12} sm={driverInputMdSize}>
        <TemplateDriversAutocomplete />
      </Grid>
    </>
  );
};
