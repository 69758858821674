import { snackbar } from '../components/Snackbar';
import { WORKSPACE_ENDPOINT } from '../constants';
import { StateCodes } from './mvrnow-types';
import { OnImportLicense } from '../../modules/drivers/drivers-events';

interface Payload {
  licenseNumber: string;
  licenseState: StateCodes;
  driverId: string;
  dateOfBirth: string;
  firstName: string;
  lastName?: string;
}

/**
 * Get License.
 *
 * @param {Payload} param0 - Payload.
 */
export const getLicense = async ({
  licenseNumber,
  licenseState,
  ...rest
}: Payload): Promise<void> => {
  const result = await fetch(`${WORKSPACE_ENDPOINT}/webhook/driver-license`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      LicenseNo: licenseNumber,
      StateCode: licenseState,
      ...rest,
    }),
  })
    .then((data) => data.json())
    .catch((err) => {
      console.log(err);
    });

  if (result.data) {
    snackbar.info('MVR generated, scroll down to see it');
    OnImportLicense.dispatch(true);
  } else if (result.message) {
    snackbar.error(result.message);
    OnImportLicense.dispatch(true);
  } else {
    snackbar.error('error generating MVR');
  }
};
