import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';
import { FormEventHandler } from 'react';
import { CardBox } from './card/CardBox';

/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @param root0.relative - Relative.
 * @returns Component.
 */
export function ListViewContainer({
  children,
  relative,
}: {
  children: React.ReactNode;
  relative?: boolean;
}): JSX.Element {
  return (
    <Box
      display="grid"
      rowGap={2}
      gridTemplateColumns="100%"
      gridTemplateRows="auto auto 1fr"
      height="100%"
      sx={{
        position: relative ? 'relative' : 'initial',
      }}
    >
      {children}
    </Box>
  );
}
/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @param root0.title  - Title.
 * @param root0.subtitle - Subtitle.
 * @param root0.sx - Material ui sx prop.
 * @returns Component.
 */
export function FormViewContainer({
  children,
  title,
  subtitle,
  sx,
}: {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  sx?: SxProps<Theme>;
}): JSX.Element {
  const styleProps = sx ?? {};

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </Box>
      <Paper
        variant="card"
        sx={{
          maxHeight: ['none', '100%'],
          height: ['none', '100%'],
          width: ['100%', 'auto'],
          paddingLeft: {
            xs: '15px',
            md: '7%',
          },
          paddingRight: {
            xs: '15px',
            md: '7%',
          },
          overflowY: ['visible', 'auto'],
          ...styleProps,
        }}
      >
        {children}
      </Paper>
    </>
  );
}

const StyledImg = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  display: 'block',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    width: '100%',
  },
}));
/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @param root0.imagePath - Logo.
 * @param root0.alt - Alt for image.
 * @param root0.onSubmit - Form submit.
 * @returns Component.
 */
export function FormImageContainer({
  children,
  imagePath,
  alt,
  onSubmit,
}: {
  children: React.ReactNode;
  imagePath: string;
  alt?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}): JSX.Element {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <StyledImg src={imagePath} alt={alt ?? 'logo'} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        {children}
      </Grid>
    </Grid>
  );
}

// Styles for create form container.
const StyledImgCreateFormContainer = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    height: 'auto',
    width: '100%',
    marginBottom: '10px',
  },
  [theme.breakpoints.up('sm')]: {
    height: 'auto',
    width: '300px',
  },
  [theme.breakpoints.up('md')]: {
    height: 'auto',
    width: '400px',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(204, 204, 204, 0.5)',
  color: 'rgba(24, 21, 24, 0.5)', // #181518a
  '&:hover': {
    color: '#FFFFFF!important',
  },
  marginRight: '5px',
  textTransform: 'none',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5BC2BA',
  marginLeft: '5px',
  textTransform: 'none',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));

const ButtonContainer = styled(Grid)({
  justifyContent: 'flex-end',
  alignItems: 'baseline',
  display: 'flex',
});

/**
 * @description - Component that container two sides, the image and the form to create.
 * @param root0 - Props.
 * @param root0.children - Children.
 * @param root0.imgSrc - Image src.
 * @param root0.imgAlt - Alt for image.
 * @param root0.confirmText - Confirm text.
 * @param root0.cancelText - Cancel text.
 * @param root0.loading - Loading.
 * @param root0.onSubmit - Call back on submit.
 * @param root0.onCancel - Call back on cancel.
 * @returns {JSX.Element} - Component.
 */
export function CreateFormContainer({
  children,
  imgSrc,
  imgAlt,
  confirmText,
  cancelText,
  loading,
  onSubmit,
  onCancel,
}: {
  children: React.ReactNode;
  imgSrc: string;
  imgAlt: string;
  confirmText?: string;
  cancelText?: string;
  loading?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  onCancel: () => void;
}): JSX.Element {
  return (
    <CardBox>
      <Grid container>
        {
          // Left side image
        }
        <Grid item sm={12} md={6} style={{ textAlign: 'center' }}>
          <StyledImgCreateFormContainer src={imgSrc} alt={imgAlt} />
        </Grid>

        {
          // Right side image
        }
        <Grid item sm={12} md={6}>
          <form onSubmit={onSubmit}>
            <Grid container width="100%">
              <Grid item xs={12}>
                {children}
              </Grid>
              <ButtonContainer item xs={12}>
                <CancelButton
                  variant="contained"
                  disabled={loading}
                  onClick={onCancel}
                >
                  {cancelText}
                </CancelButton>
                <ConfirmButton
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ marginRight: 5 }} />
                  )}
                  {confirmText}
                </ConfirmButton>
              </ButtonContainer>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </CardBox>
  );
}
