import { useState, useRef, FC, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Box,
} from '@mui/material';

interface SplitButtonProps {
  options: string[];
  defaultOption: string;
  handleSelection: (e: string) => unknown;
  startIcon?: JSX.Element;
  color?: 'secondary';
  preventSelection?: boolean;
}

/**
 * SplitButton.
 *
 * @param {Props} Props - Props.
 * @returns {JSX.Element} SplitButton.
 */
export const SplitButton: FC<SplitButtonProps> = ({
  options,
  defaultOption,
  startIcon,
  color,
  handleSelection,
  preventSelection,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  /**
   * HandleMenuItemClick.
   *
   * @param {React.MouseEvent<HTMLLIElement, MouseEvent>} _ - Event.
   * @param {number} index - Index.
   */
  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ): void => {
    if (!preventSelection) setSelectedIndex(index);
    setOpen(false);
    handleSelection(options[index]);
  };

  /**
   * HandleToggle.
   */
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * HandleClose.
   *
   * @param {Event} event - Event.
   */
  const handleClose = (event: Event): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const buttonText = options[selectedIndex]
    ? options[selectedIndex]
    : defaultOption;

  const StartIconMemo = useMemo(() => {
    if (startIcon)
      return (
        <Box display="flex" marginRight={1}>
          {startIcon}
        </Box>
      );
    return null;
  }, [startIcon]);

  const dropdownWidth = anchorRef.current
    ? `${anchorRef.current.offsetWidth}px`
    : '0';

  /**
   * Returns a lighter version of the color passed on the props.
   *
   * @returns {string} Menu background color.
   */
  const menuBackgroundColor = (): string => {
    switch (color) {
      case 'secondary':
        return '#8de3dc';
      default:
        return '#fff';
    }
  };

  return (
    <>
      <ButtonGroup
        style={{ boxShadow: 'unset' }}
        color={color}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button color={color} onClick={handleToggle}>
          {StartIconMemo}
          {buttonText}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                width: dropdownWidth,
                backgroundColor: menuBackgroundColor(),
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      style={{ color: '#fff' }}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.defaultProps = {
  color: 'secondary',
  preventSelection: false,
};
