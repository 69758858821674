import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material';

const CustomIcon = styled('span')(({ theme }) => ({
  borderRadius: '4px',
  width: '24px',
  height: '24px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? `0 0 0 1px ${theme.palette.primary.main}`
      : `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#f0f0f0' : '#f5f5f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const CustomCheckedIcon = styled(CustomIcon)(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundImage: `
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23${theme.palette.primary.main.slice(
        1,
      )}'/%3E%3C/svg%3E")
    `,
    content: '""',
  },
  color: theme.palette.primary.main,
  'input:hover ~ &': {
    backgroundColor: theme.palette.grey[300],
  },
}));

/**
 * @description - Custom checkbox.
 * @param {CheckboxProps} props - Checkbox props.
 * @returns {JSX.Element} - Checkbox.
 */
export const FCPCheckbox: React.FC<CheckboxProps> = (props): JSX.Element => (
  <Checkbox
    icon={<CustomIcon />}
    checkedIcon={<CustomCheckedIcon />}
    {...props}
  />
);
