import { FC } from 'react';
import {
  SxProps,
  Theme,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PRIMARY_LIGHT } from '../../../shared/css/theme';
import { SignerDataShape } from '../document-types';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    alignItems: 'center',
    gap: '1rem',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const small: SxProps<Theme> | undefined = {
  fontSize: '0.875rem',
};

interface SignersProps {
  onDelete?: (email: string) => void;
  roles?: string[];
  signers?: SignerDataShape[];
}

/**
 *
 * @param {SignersProps} Props - Props.
 * @returns {JSX.Element} - AdditionalSignersForm.
 */
export const SignersForm: FC<SignersProps> = ({
  signers = [],
  onDelete,
  roles,
}): JSX.Element => {
  /**
   * @param {string} email - Email.
   */
  const handleDelete = (email: string): void => {
    if (onDelete) onDelete(email);
  };

  const badgeDisplay = signers.length > 0 ? 'flex' : 'none';
  const roleCellDisplay = roles ? 'table-cell' : 'none';

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Signers List
        <Chip
          style={{
            display: badgeDisplay,
            background: PRIMARY_LIGHT,
            color: '#fff',
          }}
          label={signers.length}
        />
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>

                <TableCell style={{ display: roleCellDisplay }}>Role</TableCell>
                <TableCell align="center">Driver</TableCell>
                <TableCell align="center">Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {signers.map((signer) => (
                <TableRow key={`table-row-${signer.email}`}>
                  <TableCell>{signer.email}</TableCell>
                  <TableCell>
                    {signer.name} {signer.lastName}
                  </TableCell>
                  <TableCell style={{ display: roleCellDisplay }}>
                    {signer.role}
                  </TableCell>

                  <TableCell align="center">
                    {signer.driverId ? <CheckCircleIcon /> : <CancelIcon />}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={{ ...small }}
                      size="small"
                      onClick={() => handleDelete(signer.email)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
