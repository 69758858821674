import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import friendsImg from '../../../shared/assets/truck.svg';
import { BackButton } from '../../../shared/components/buttons';
import { FormImageContainer } from '../../../shared/components/Containers';
import { CardBox } from '../../../shared/components/card/CardBox';
import { Title } from '../../../shared/components/typography/Title';
import { AddAddressView } from './AddressView';
import {
  useCustomerDetailQuery,
  useUpdateCustomerMutation,
} from '../../../shared/types/generated';
import {
  useCustomerForm,
  createVariablesUpdateCustomer,
} from '../customer-utils';
import { EditCustomerFormType } from '../customer-types';
import { clearQueriesFromCache } from '../../../shared/utils';
import { snackbar } from '../../../shared/components/Snackbar';
import { TextFormatPhone } from '../../../shared/components/inputs/TextFormatPhone';
import { CustomInput } from '../../../shared/components/inputs/CustomInput';
import { CustomSelect } from '../../../shared/components/selects/CustomSelect';
import { customerSubscriptionsSelectOptions } from '../customer-models';
import { USER_ROLES } from '../../session/session-model';
import { UpdateCustomerDriverView } from '../../customer-driver/UpdateCustomerDriver';

/**
 * @returns {JSX.Element} - Cases view.
 */
const EditRegularCustomerView: React.FC = () => {
  // React router dom.
  const history = useHistory();

  // Customer id.
  const { id } = useParams<{ id: string }>();

  const [once, setOnce] = useState(false);

  // React hook form.
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    watch,
    setValue,
    control,
  } = useCustomerForm();

  // Customer query details.
  const { data: customerData, loading: loadingData } = useCustomerDetailQuery({
    variables: { id: id ?? '' },
  });

  // Update customer mutation.
  const [updateCustomer, { loading }] = useUpdateCustomerMutation({
    /**
     *
     * @param {object} err  - Error.
     */
    onError: (err) => {
      snackbar.error(err.message);
    },
    // eslint-disable-next-line
    onCompleted: () => {
      snackbar.success('Customer updated successfully');
      history.push('/customers');
    },
    /**
     * @param cache - Apollo cache.
     */
    update: clearQueriesFromCache(['customer', 'customersList']),
  });

  /**
   * @description - Add more addresses.
   * @returns {void} - Nothing.
   */
  const pushAddres = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      apartment: '',
      // country: '',
      state: '',
      street: '',
      city: '',
      zipCode: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @param {number} index - Array position to delete.
   * @description - Remove Address.
   * @returns {void} - Nothing.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);

    setValue('customerAddressRelation', addresses);
  };

  useEffect(() => {
    if (customerData !== undefined && once === false) {
      setOnce(true);
      setValue('name', customerData.customer.name);
      setValue('subscription', customerData.customer.subscription);
      setValue('email', customerData.customer.email);
      setValue('contactName', customerData.customer.contactName);
      setValue('phoneNumber', customerData.customer.phoneNumber);
      setValue(
        'customerAddressRelation',
        customerData?.customer?.address?.items.map((address) => ({
          id: address.id,
          state: address.state.id,
          city: address.city,
          apartment: address.apartment,
          street: address.street,
          zipCode: address.zipCode,
        })),
      );
    }
  }, [customerData, setValue, once]);

  return (
    <>
      <BackButton
        onClick={() => history.push('/customers')}
        text="Back to Customers"
      />
      <CardBox>
        <FormImageContainer
          imagePath={friendsImg}
          alt="img"
          onSubmit={handleSubmit((data: EditCustomerFormType) => {
            if (customerData) {
              updateCustomer({
                variables: createVariablesUpdateCustomer(
                  id ?? '',
                  customerData,
                  // customer => user => auth0s
                  // When the customer email is updated
                  // A new user will be created
                  data,
                ),
              });
            }
          })}
        >
          <Stack direction="column" spacing={3}>
            <Title>Edit Customer</Title>
            {loadingData ? (
              <Skeleton animation="wave" sx={{ gridColumn: 'span 2' }} />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ gridColumn: 'span 2' }}
              >
                <TextField
                  label="Company name"
                  defaultValue={customerData?.customer?.name}
                  {...register('name')}
                  helperText={formState.errors.name?.message}
                  error={Boolean(formState.errors.name)}
                />
              </FormControl>
            )}
            {loadingData ? (
              <Skeleton animation="wave" sx={{ gridColumn: 'span 2' }} />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ gridColumn: 'span 2' }}
              >
                <TextField
                  label="Contact Name"
                  {...register('contactName')}
                  helperText={formState.errors.contactName?.message}
                  error={Boolean(formState.errors.contactName)}
                />
              </FormControl>
            )}
            {loadingData ? (
              <Skeleton animation="wave" sx={{ gridColumn: 'span 2' }} />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ gridColumn: 'span 2' }}
              >
                <TextField
                  label="Email"
                  defaultValue={customerData?.customer?.email}
                  helperText={formState.errors.email?.message}
                  error={Boolean(formState.errors.email)}
                  {...register('email')}
                />
              </FormControl>
            )}
            {loadingData ? (
              <Skeleton animation="wave" sx={{ gridColumn: 'span 2' }} />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ gridColumn: 'span 2' }}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <CustomInput
                      label="Phone"
                      size="medium"
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      helperText={formState.errors.phoneNumber?.message}
                      error={Boolean(formState.errors.phoneNumber?.message)}
                      InputProps={{
                        // eslint-disable-next-line
                        inputComponent: TextFormatPhone as any,
                      }}
                      style={{ margin: 0 }}
                    />
                  )}
                />
              </FormControl>
            )}
          </Stack>
          {customerData &&
            customerData?.customer?.address?.items &&
            getValues('customerAddressRelation').map((address, index) => (
              <Box sx={{ gridColumn: 'span 2', margin: 0, marginTop: 2 }}>
                <AddAddressView
                  index={index}
                  add={pushAddres}
                  remove={removeAddress}
                  formState={formState}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  defaultValues={customerData.customer.address.items[index]}
                />
              </Box>
            ))}
          <Stack direction="column" spacing={3} marginTop={3}>
            <Title>Subscription</Title>

            <FormControl fullWidth variant="outlined">
              <Controller
                name="subscription"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    size="medium"
                    {...field}
                    defaultValue={customerData?.customer.subscription}
                    options={customerSubscriptionsSelectOptions}
                    helperText={formState.errors.subscription?.message}
                    error={Boolean(formState.errors.subscription?.message)}
                    style={{ margin: 0, marginBottom: 24 }}
                  />
                )}
              />
            </FormControl>
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: ['space-between', 'flex-end'],
              gridColumn: 'span 2',
              marginTop: '20px',
              marginBottom: '20px',
              '&>*': {
                marginLeft: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 24 }}
              type="submit"
            >
              Save
            </LoadingButton>
          </Box>
        </FormImageContainer>
      </CardBox>
    </>
  );
};

/**
 * @returns {JSX.Element} - Cases view.
 */
export const EditCustomerView: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { data } = useCustomerDetailQuery({
    variables: { id },
  });

  const isCustomerDriver = data?.customer?.user?.roles?.items.some(
    ({ name }) =>
      name === USER_ROLES.CUSTOMER_BASIC ||
      name === USER_ROLES.CUSTOMER_PRO_DRIVER,
  );

  if (isCustomerDriver) {
    return <UpdateCustomerDriverView />;
  }

  return <EditRegularCustomerView />;
};
