import React from 'react';
import { Box, Grid } from '@mui/material';
import { DocumentList } from './components/DocumentList';
import { DocToolbar } from './components/DocToolbar';

/**
 * @returns {JSX.Element} - Customers View.
 */
export const DocumentsListView: React.FC = (): JSX.Element => (
  <Box mt="20px" padding="0px 10px">
    <Grid container spacing={1}>
      <DocToolbar />
    </Grid>
    <DocumentList />
  </Box>
);
