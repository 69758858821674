import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { MainLoader } from '../../shared/components/MainLoader';

// TODO add loading component
/**
 * @returns {JSX.Element} - Auth component.
 */
export function Auth(): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);
  const caseId = urlParams.get('caseId');

  useEffect(() => {
    loginWithRedirect({
      appState: {
        caseId,
      },
    });
  }, [loginWithRedirect, caseId]);

  return <MainLoader />;
}
