import * as yup from 'yup';

const customerAddressRelationSchema = yup.object({
  id: yup.string().optional().notRequired(),
  state: yup.string().nullable().required('State is required'),
  street: yup.string().required('Street is required'),
  city: yup
    .string()
    .nullable()
    .max(100, 'Max 100 characters')
    .required('City is required')
    .default(''),
  apartment: yup.string().nullable(),
  zipCode: yup
    .string()
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Please enter a valid zipcode')
    .default('')
    .required('Zipcode is required'),
});

const licenseSchema = yup.object({
  number: yup.string().optional(),
  state: yup.string(),
});

export const CUSTOMER_DRIVER_FORM_VALIDATOR_SCHEMA = yup
  .object({
    id: yup.string(),
    name: yup.string().required('Name is required'),
    lastName: yup.string().required('Last name is required'),
    dateOfBirth: yup.string().required('Date of birth is required').nullable(),
    subscription: yup.string().required('Subscription is required'),
    language: yup.string().required('Language is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup
      .string()
      .min(10, 'Please enter a valid number')
      .required('Phone is required')
      .default(''),
    customerAddressRelation: yup.array().of(customerAddressRelationSchema),
    license: licenseSchema,
    requestMVR: yup.boolean().default(false),
    unsupportedStateSelected: yup.boolean().default(false),
  })
  .required();

export type CustomerDriverFormType =
  typeof CUSTOMER_DRIVER_FORM_VALIDATOR_SCHEMA['__outputType'];
export type CustomerAddressType =
  typeof customerAddressRelationSchema['__outputType'];
