import type { MutableRefObject } from 'react';
import type { RawDraftContentState } from 'react-draft-wysiwyg';

export const validInlineOptions = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'monospace',
  'superscript',
  'subscript',
];

export type InlineValidOptions =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'monospace'
  | 'superscript'
  | 'subscript';

export type DropDownValidOptions =
  | 'inline'
  | 'blockType'
  | 'fontSize'
  | 'fontFamily'
  | 'list'
  | 'textAlign'
  | 'colorPicker'
  | 'link'
  | 'embedded'
  | 'emoji'
  | 'image'
  | 'remove'
  | 'history';

export type AvailableOptionsResult = {
  [key in DropDownValidOptions]: { inDropdown: true };
};

export interface GenerateAvailableOptions {
  availableOptionsResult?: AvailableOptionsResult;
  optionsArray?: DropDownValidOptions[];
  inline?: {
    options: InlineValidOptions[];
  };
}

export interface RichTextEditorProps {
  value?: string;
  onChange?: (value: string | RawDraftContentState) => void;
  helperText?: string;
  error?: boolean;
  dropDownOptions?: DropDownValidOptions[];
  editorRef?: MutableRefObject<HTMLElement | null>;
  fullHeight?: boolean;
  inlineOptions?: 'all';
  defaultState?: RawDraftContentState;
  getRawDraftContentState?: boolean;
}
