import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Controller,
  Control,
  UseFormSetValue,
  UseFormWatch,
  useFormContext,
} from 'react-hook-form';
import friendsImg from '../../shared/assets/orange-truck.svg';
import { BackButton } from '../../shared/components/buttons';
import { FormImageContainer } from '../../shared/components/Containers';
import { CardBox } from '../../shared/components/card/CardBox';
import { Title } from '../../shared/components/typography/Title';
import { AddAddressView } from '../customer/components/AddressView';
import {
  useCreateCustomerMutation,
  useCustomerDetailQuery,
  useUserByEmailLazyQuery,
} from '../../shared/types/generated';
import { prepareTheCustomerDriverCreationData } from '../customer/customer-utils';
import { EditCustomerFormType } from '../customer/customer-types';
import { clearQueriesFromCache } from '../../shared/utils';
import { snackbar } from '../../shared/components/Snackbar';
import { CustomSelect } from '../../shared/components/selects/CustomSelect';
import {
  customerDriverSubs,
  customerDriverSubsSelectOptions,
} from '../customer/customer-models';
import {
  CustomerAddressType,
  CustomerDriverFormType,
} from './customer-driver-types';
import { CustomerDriverDetailsForm } from './components/CustomerDriverDetailsForm';
import { CustomerDriverLicenseForm } from './components/CustomerDriverLicenseForm';
import { getLicense } from '../../shared/mvrnow/mvrnow-actions';
import { StateCodes } from '../../shared/mvrnow/mvrnow-types';
import { CustomerDriverFormProvider } from './CustomerDriverFormProvider';

/**
 * @returns {JSX.Element} - Cases view.
 */
export const CreateCustomerDriverForm: React.FC = () => {
  // React router
  const history = useHistory();

  // Form state.
  const methods = useFormContext<CustomerDriverFormType>();
  const { handleSubmit, formState, getValues, watch, setValue, control } =
    methods;

  const { refetch } = useCustomerDetailQuery({});

  const [createCustomer, { loading }] = useCreateCustomerMutation({
    /**
     * @param result - Result.
     * @returns {Promise<void>} - Result.
     */
    onCompleted: async (result): Promise<void> => {
      const { requestMVR, unsupportedStateSelected, ...data } = getValues();
      snackbar.success('Customer created successfully');

      if (unsupportedStateSelected || !requestMVR) {
        history.push('/customers');
        return;
      }

      snackbar.info(
        'Now the MVR is being generated, this may take a few seconds',
      );

      const { data: customerDriver } = await refetch({
        id: result.customerCreate.id,
      });

      snackbar.success(
        `Driver ${data.name} created, now the MVR is being generated, this may take a few seconds`,
      );

      await getLicense({
        licenseNumber: data.license.number || '',
        licenseState: data.license.state as StateCodes,
        driverId: customerDriver.customer.driverCustomerRelation.items[0].id,
        dateOfBirth: data.dateOfBirth as string,
        firstName: data.name as string,
        lastName: data.lastName as string,
      });

      history.push('/customers');
    },
    /**
     * @param cache - Apollo Cache.
     */
    update: clearQueriesFromCache(['customer', 'customersList']),
    /**
     * @param error - Error.
     * @returns {void} - Nothing.
     */
    onError: (error): void => {
      snackbar.error(error.message);
    },
  });

  const [getUserByEmail] = useUserByEmailLazyQuery();

  useEffect(() => {
    setValue('subscription', customerDriverSubs.basic);
  }, [setValue]);

  /**
   * @returns {void} - Add more addresses.
   */
  const pushAddress = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      id: '',
      apartment: '',
      state: '',
      street: '',
      city: '',
      zipCode: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @returns {void} - Remove Address.
   * @param {number} index - Array position to delete.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);

    setValue('customerAddressRelation', addresses);
  };

  return (
    <>
      <BackButton
        onClick={() => history.push('/customers')}
        text="Back to Customers"
      />
      <CardBox>
        <FormImageContainer
          imagePath={friendsImg}
          alt="img"
          onSubmit={handleSubmit(async (data) => {
            // To prevent if an user is already registered with the same email.
            const response = await getUserByEmail({
              variables: {
                email: data.email,
              },
            });

            if (response.data?.user) {
              snackbar.error('This email is already registered');
              return;
            }

            if (data.customerAddressRelation !== undefined) {
              createCustomer({
                variables: prepareTheCustomerDriverCreationData(data),
              });
            }
          })}
        >
          <CustomerDriverDetailsForm />
          <CustomerDriverLicenseForm />
          {getValues('customerAddressRelation')?.map(
            (_: CustomerAddressType, index: number) => (
              <Box sx={{ gridColumn: 'span 2', margin: 0, marginTop: 2 }}>
                <AddAddressView
                  index={index}
                  add={pushAddress}
                  remove={removeAddress}
                  formState={formState}
                  control={control as unknown as Control<EditCustomerFormType>}
                  setValue={
                    setValue as unknown as UseFormSetValue<EditCustomerFormType>
                  }
                  watch={watch as unknown as UseFormWatch<EditCustomerFormType>}
                />
              </Box>
            ),
          )}
          <Stack direction="column" spacing={3} marginTop={3}>
            <Title>Subscription</Title>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="subscription"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    size="medium"
                    {...field}
                    defaultValue={customerDriverSubs.basic}
                    options={customerDriverSubsSelectOptions}
                    helperText={formState.errors.subscription?.message}
                    error={Boolean(formState.errors.subscription?.message)}
                    style={{ margin: 0, marginBottom: 24 }}
                  />
                )}
              />
            </FormControl>
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: ['space-between', 'flex-end'],
              gridColumn: 'span 2',
              marginTop: '20px',
              marginBottom: '20px',
              '&>*': {
                marginLeft: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 24 }}
              type="submit"
            >
              Create
            </LoadingButton>
          </Box>
        </FormImageContainer>
      </CardBox>
    </>
  );
};

/**
 * @returns {JSX.Element} - Create Customer Driver View.
 */
export const CreateCustomerDriverView: React.FC = (): JSX.Element => (
  <CustomerDriverFormProvider>
    <CreateCustomerDriverForm />
  </CustomerDriverFormProvider>
);
