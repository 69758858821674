import { useQuery } from 'react-query';
import { UseFormProps, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackbar } from '../../../shared/components/Snackbar';
import { useSubscribeError } from '../../../shared/hooks/hooks';
import { everSignClient } from '../../../shared/eversign';
import { DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA } from './document-template-models';
import type { EverSignTemplate } from '../document-types';
import type { DocumentTemplateFormType } from './document-template-types';

const QUERY_KEY = 'Templates';
const SNACK_BAR_ERR_MESSAGE = 'Problem getting the templates';

interface UseEverSignTemplatesReturn {
  data?: EverSignTemplate[];
  refetch: () => void;
  isLoading: boolean;
  isFetching: boolean;
}

/**
 * A React hook for fetching EverSign templates using react-query.
 *
 * @returns {UseEverSignTemplatesReturn} Returns an object with the fetched templates data, a refetch function, and loading state flags.
 */
export const useEversingTemplates = (): UseEverSignTemplatesReturn => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    QUERY_KEY,
    async (): Promise<EverSignTemplate[] | undefined> => {
      try {
        const result = await everSignClient.getTemplates();
        const mappedData = result.map((e) => e.toObject());
        return mappedData as unknown as EverSignTemplate[];
      } catch (err) {
        snackbar.error(SNACK_BAR_ERR_MESSAGE);
        return undefined;
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};

/**
 * @param {UseFormProps} formOptions - Form options.
 * @returns Form hook state.
 */
export function useDocumentTemplateForm(
  formOptions?: UseFormProps,
): UseFormReturn<DocumentTemplateFormType> {
  const data = useForm<DocumentTemplateFormType>({
    ...formOptions,
    resolver: yupResolver(DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA, {}),
  });

  useSubscribeError(data, () => {
    snackbar.error('Validation error');
  });

  return data;
}
