import { FC, RefObject, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  FormControl,
  TextField,
  Stack,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import type { RawDraftContentState } from 'react-draft-wysiwyg';
import { CIRCULAR_PROCESS_LARGE_SIZE } from '../../../../shared/constants';

import { PaperStyled } from '../../../../shared/components/card/Card';
import { PRIMARY, PRIMARY_LIGHT } from '../../../../shared/css/theme';
import { BackButton } from '../../../../shared/components/buttons';
import { RichTextEditor } from '../../../../shared/components/rich-text-editor/RichTextEditor';
import type {
  CustomersListQuery,
  Customer,
  Driver,
  DriversByCustomerIdQuery,
} from '../../../../shared/types/generated';
import { useUserHasRole } from '../../../session/session-hooks';
import { SignersForm } from '../../components/SignersForm';
import { SignerAutocompletes } from '../../components/SignerAutocompletes';

import { ActionBUttons } from './ActionButtons';
import { DocumentCreationFormType } from '../document-creation-types';

interface Props {
  toggleDriverSwitch: () => void;
  onPreviewClick: () => void;
  onOpenDraftClick: () => void;
  uploadingDoc: boolean;
  driverInputSwitch: boolean;
  savePdfToDraft: (isDraft: boolean) => void;
  customersData?: CustomersListQuery;
  onCustomerSelection: (customerInput: Customer | null) => void;
  driversSelectIsDisabled: boolean;
  driversData?: DriversByCustomerIdQuery;
  onDriverSelection: (driverInput: Driver | null) => void;
  editorRef: RefObject<HTMLElement>;
  defaultState?: RawDraftContentState;
  loading: boolean;
  sendDisabled: boolean;
  handleAddAdditionalSigner: () => void;
  handleDeleteAdditionalSigner: (email: string) => void;
}

/**
 *
 * @param {Props} props - Props.
 * @returns {JSX.Element} Form Component.
 */
export const CustomDocumentForm: FC<Props> = ({
  driverInputSwitch,
  onPreviewClick,
  uploadingDoc,
  sendDisabled,
  toggleDriverSwitch,
  savePdfToDraft,
  customersData,
  onCustomerSelection,
  driversSelectIsDisabled,
  driversData,
  onDriverSelection,
  editorRef,
  defaultState,
  loading,
  onOpenDraftClick,
  handleAddAdditionalSigner,
  handleDeleteAdditionalSigner,
}): JSX.Element => {
  const { register, formState, watch, control } =
    useFormContext<DocumentCreationFormType>();

  const { signers, signerName, signerEmail, driver } = watch();

  const history = useHistory();

  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');

  const disableAddSigner = !signerName || !signerEmail;

  const driverInputSwitchText = driverInputSwitch
    ? 'Remove Driver'
    : 'Add driver as Signer';

  const driverInputSwitchTextDisplay = isCustomerElite ? 'none' : 'flex';

  useEffect(() => {
    if (isCustomerElite) toggleDriverSwitch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress size={CIRCULAR_PROCESS_LARGE_SIZE} />
      </Box>
    );

  return (
    <Box mt="20px" padding="0px 10px">
      <Grid container spacing={1} padding={1}>
        <Box
          width={{ xs: '100%', sm: 'auto' }}
          display="flex"
          alignItems="center"
          marginBottom={2}
        >
          <BackButton
            onClick={() => history.push('/document-management')}
            text="Back to Document Management"
          />
        </Box>
        <Grid container marginBottom={2}>
          <Grid item sm={12} md={4}>
            <Box color="#3A526A" fontSize="18px" fontWeight="bold">
              Create custom document
            </Box>
          </Grid>

          <Grid container item sm={12} md={8} justifyContent="space-around">
            <ActionBUttons
              sendDisabled={sendDisabled}
              onOpenDraftClick={onOpenDraftClick}
              disableButtons={uploadingDoc}
              onSendClick={() => savePdfToDraft(false)}
              onViewClick={onPreviewClick}
            />
          </Grid>
        </Grid>
      </Grid>

      <PaperStyled>
        <PaperStyled>
          <Box padding={3} color="#3A526A" fontSize="18px" fontWeight="bold">
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                label="Document Title"
                required
                {...register('documentTitle')}
                helperText={formState.errors.documentTitle?.message}
                error={Boolean(formState.errors.documentTitle?.message)}
              />
            </FormControl>
          </Box>
        </PaperStyled>

        <Grid container spacing={2} padding={3}>
          <Grid item xs={12} container alignItems="center" spacing={3}>
            <Grid item color={PRIMARY} component="h2" fontSize="18px">
              Signers
            </Grid>
            <Grid
              item
              display={driverInputSwitchTextDisplay}
              color={PRIMARY_LIGHT}
              component="p"
              fontSize="16px"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={toggleDriverSwitch}
            >
              {driverInputSwitchText}
            </Grid>
          </Grid>

          <SignerAutocompletes
            show={driverInputSwitch}
            driversSelectIsDisabled={driversSelectIsDisabled}
            onCustomerSelection={onCustomerSelection}
            onDriverSelection={onDriverSelection}
            customersData={customersData}
            driversData={driversData}
          />

          <Grid item xs={12} sm={6}>
            <TextField
              disabled={!!driverInputSwitch}
              key={`signerName-${driver}`}
              label="Signer's Name"
              type="text"
              fullWidth
              {...register('signerName')}
            />
          </Grid>
          <Grid item xs={12} sm={6} container alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <TextField
                disabled={!!driverInputSwitch}
                key={`signerEmail-${driver}`}
                label="Signer's Email"
                type="text"
                fullWidth
                helperText={formState.errors.signerEmail?.message}
                error={Boolean(formState.errors.signerEmail?.message)}
                {...register('signerEmail')}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                disabled={disableAddSigner}
                variant="contained"
                color="secondary"
                onClick={handleAddAdditionalSigner}
                sx={{
                  padding: '5px',
                  minWidth: 'unset',
                }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <SignersForm
              signers={signers}
              onDelete={handleDeleteAdditionalSigner}
            />
          </Grid>
        </Grid>

        <Box paddingX={6} color="#000">
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RichTextEditor
                defaultState={defaultState}
                fullHeight
                editorRef={editorRef}
                getRawDraftContentState
                dropDownOptions={[
                  'fontFamily',
                  'link',
                  'textAlign',
                  'fontSize',
                  'blockType',
                  'colorPicker',
                  'history',
                ]}
                inlineOptions="all"
                value={value}
                onChange={onChange}
                helperText={formState.errors.description?.message}
                error={Boolean(formState.errors.description?.message)}
              />
            )}
          />
          <Stack
            spacing={2}
            direction="row"
            paddingY={7}
            justifyContent="flex-end"
          >
            <Button
              disabled={uploadingDoc}
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={() => savePdfToDraft(true)}
            >
              Save
            </Button>
            <Button
              disabled={sendDisabled}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={() => savePdfToDraft(false)}
            >
              Send Document
            </Button>
          </Stack>
        </Box>
      </PaperStyled>
    </Box>
  );
};
