import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { YellowButton } from '../../../../shared/components/buttons';

interface Params {
  onViewClick?: () => unknown;
  onSendClick?: () => unknown;
  onOpenDraftClick?: () => unknown;
  disableButtons?: boolean;
  sendDisabled: boolean;
}

/**
 * Document Button Navigation.
 *
 * @param {string[]} options - Options.
 * @returns {JSX.Element} - Customers View.
 */
export const ActionBUttons: FC<Params> = ({
  onViewClick,
  onSendClick,
  onOpenDraftClick,
  disableButtons = false,
  sendDisabled,
}): JSX.Element => (
  <Stack spacing={2} direction="row" justifyContent="center">
    <Button
      disabled={disableButtons}
      variant="contained"
      color="secondary"
      startIcon={<FormatListBulletedIcon />}
      onClick={onOpenDraftClick}
    >
      Custom Drafts
    </Button>
    <YellowButton
      disabled={disableButtons}
      variant="contained"
      color="warning"
      startIcon={<VisibilityIcon />}
      onClick={onViewClick}
    >
      Preview Document
    </YellowButton>

    <Button
      disabled={sendDisabled}
      variant="contained"
      color="primary"
      startIcon={<SendIcon />}
      onClick={onSendClick}
    >
      Send Document
    </Button>
  </Stack>
);
