import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { Auth } from '../modules/auth/Auth';
import { AuthCallback } from '../modules/auth/auth-callback/AuthCallback';
import { Logout } from '../modules/auth/Logout';
import { MainLoader } from '../shared/components/MainLoader';
import { Layout } from '../shared/components/Layout';
import { Session } from '../modules/session/Session';
import { ROUTES } from './routes-model';
import { Dashboard } from '../modules/dashboard/DashboardView';
import {
  CaseDetailsView,
  CreateCaseView,
  CreateUserCaseView,
  MyAccountView,
  UpdateCaseView,
  UpdateUserCaseView,
  DriversLazy,
  CreateDriverLazyView,
  UserManagementView,
  DriverDetailsLazyView,
  UpdateDriverLazyView,
  MergeDriversLazyView,
  CustomerDashboard,
  CasesRefactor,
} from './LazyComponents';
import { Customers } from '../modules/customer/CustomerView';
import { CreateCustomerView } from '../modules/customer/components/CreateCustomerView';
import { CreateCustomerDriverView } from '../modules/customer-driver/CreateCustomerDriverView';
import { CustomerDetail } from '../modules/customer/components/CustomerDetail';
import { EditCustomerView } from '../modules/customer/components/EditCustomerView';
import { RoleRoute } from './RoleRoute';
import { AuthUserDisabled } from '../modules/auth/AuthUserDisabled';
import { AuthRecoverPassword } from '../modules/auth/AuthRecoverPassword';
import { IdleTimerSession } from '../modules/auth/IdleTimerSession';
import { DocumentsListView } from '../modules/documents/DocumentsListView';
import { CreateDocumentsView } from '../modules/documents/document-creation/DocumentCreationView';
import { DocumentTemplateView } from '../modules/documents/document-template/DocumentTemplateView';
import { DocumentAttachView } from '../modules/documents/document-attach/DocumentAttachView';
import { useRoutesInitialRedirection } from './routes-hooks';

/*
WARNING: It is not necessary to add a protected route to each component within session,
an example of how to add the routes I will leave below

  <ProtectedRoute path="/">
    <Session>
     <Route path="/1" component={AuthCallback} />
     <Route path="/2/4" component={AuthCallback} />
     <Route path="/3/5" component={AuthCallback} />
    </Session>
  </ProtectedRoute>
*/

/**
 * @returns Routes.
 */
export const Routes: React.FC = () => {
  const { isLoading, error } = useRoutesInitialRedirection();

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <>
      <Switch>
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/recover-password" component={AuthRecoverPassword} />
        <Route
          exact
          path="/auth/callback"
          component={
            !error ? () => <AuthCallback /> : () => <AuthUserDisabled />
          }
        />
        <Route path="/logout" component={Logout} />
        <ProtectedRoute path="/">
          <Session>
            <Layout>
              <Suspense fallback={<MainLoader />}>
                <Switch>
                  <RoleRoute
                    roles={ROUTES.DASHBOARD.rolePermission}
                    path={ROUTES.DASHBOARD.path}
                    exact
                    component={Dashboard}
                  />
                  <RoleRoute
                    roles={ROUTES.CUSTOMER_DASHBOARD.rolePermission}
                    path={ROUTES.CUSTOMER_DASHBOARD.path}
                    exact
                    component={CustomerDashboard}
                  />
                  <RoleRoute
                    roles={ROUTES.CASES.rolePermission}
                    path={ROUTES.CASES.path}
                    exact
                    component={CasesRefactor}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.CASES.internalPaths.CASE_DETAILS.rolePermission
                    }
                    path={ROUTES.CASES.internalPaths.CASE_DETAILS.path}
                    exact
                    component={CaseDetailsView}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.CASES.internalPaths.CASE_CREATE.rolePermission
                    }
                    path={ROUTES.CASES.internalPaths.CASE_CREATE.path}
                    exact
                    component={CreateCaseView}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.CASES.internalPaths.CASE_UPDATE.rolePermission
                    }
                    path={ROUTES.CASES.internalPaths.CASE_UPDATE.path}
                    exact
                    component={UpdateCaseView}
                  />
                  <RoleRoute
                    roles={ROUTES.DRIVERS.rolePermission}
                    path={ROUTES.DRIVERS.path}
                    exact
                    component={DriversLazy}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.DRIVERS.internalPaths.DRIVER_CREATE.rolePermission
                    }
                    path={ROUTES.DRIVERS.internalPaths.DRIVER_CREATE.path}
                    exact
                    component={CreateDriverLazyView}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.DRIVERS.internalPaths.DRIVER_DETAIL.rolePermission
                    }
                    path={ROUTES.DRIVERS.internalPaths.DRIVER_DETAIL.path}
                    exact
                    component={DriverDetailsLazyView}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.DRIVERS.internalPaths.DRIVER_UPDATE.rolePermission
                    }
                    path={ROUTES.DRIVERS.internalPaths.DRIVER_UPDATE.path}
                    exact
                    component={UpdateDriverLazyView}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.DRIVERS.internalPaths.DRIVER_MERGE.rolePermission
                    }
                    path={ROUTES.DRIVERS.internalPaths.DRIVER_MERGE.path}
                    exact
                    component={MergeDriversLazyView}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.UserManagement.internalPaths.MY_ACCOUNT
                        .rolePermission
                    }
                    path={ROUTES.UserManagement.internalPaths.MY_ACCOUNT.path}
                    exact
                    component={MyAccountView}
                  />
                  <RoleRoute
                    roles={ROUTES.UserManagement.rolePermission}
                    path={ROUTES.UserManagement.path}
                    exact
                    component={UserManagementView}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.UserManagement.internalPaths.USER_CREATE
                        .rolePermission
                    }
                    path={ROUTES.UserManagement.internalPaths.USER_CREATE.path}
                    exact
                    component={CreateUserCaseView}
                  />

                  <RoleRoute
                    roles={
                      ROUTES.UserManagement.internalPaths.USER_UPDATE
                        .rolePermission
                    }
                    path={ROUTES.UserManagement.internalPaths.USER_UPDATE.path}
                    exact
                    component={UpdateUserCaseView}
                  />
                  <RoleRoute
                    roles={ROUTES.Customers.rolePermission}
                    path={ROUTES.Customers.path}
                    exact
                    component={Customers}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.Customers.internalPaths.CUSTOMER_CREATE
                        .rolePermission
                    }
                    path={ROUTES.Customers.internalPaths.CUSTOMER_CREATE.path}
                    exact
                    component={CreateCustomerView}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.Customers.internalPaths.CUSTOMER_DRIVER_CREATE
                        .rolePermission
                    }
                    path={
                      ROUTES.Customers.internalPaths.CUSTOMER_DRIVER_CREATE.path
                    }
                    exact
                    component={CreateCustomerDriverView}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.Customers.internalPaths.CUSTOMER_DETAIL
                        .rolePermission
                    }
                    path={ROUTES.Customers.internalPaths.CUSTOMER_DETAIL.path}
                    exact
                    component={CustomerDetail}
                  />
                  <RoleRoute
                    roles={
                      ROUTES.Customers.internalPaths.CUSTOMER_EDIT
                        .rolePermission
                    }
                    path={ROUTES.Customers.internalPaths.CUSTOMER_EDIT.path}
                    exact
                    component={EditCustomerView}
                  />

                  <RoleRoute
                    roles={ROUTES.DOCUMENT_MANAGEMENT.rolePermission}
                    path={ROUTES.DOCUMENT_MANAGEMENT.path}
                    exact
                    component={DocumentsListView}
                  />
                  <RoleRoute
                    roles={ROUTES.DOCUMENT_MANAGEMENT.rolePermission}
                    path={
                      ROUTES.DOCUMENT_MANAGEMENT.internalPaths.CREATE_DOCUMENT
                        .path
                    }
                    exact
                    component={CreateDocumentsView}
                  />
                  <RoleRoute
                    roles={ROUTES.DOCUMENT_MANAGEMENT.rolePermission}
                    path={
                      ROUTES.DOCUMENT_MANAGEMENT.internalPaths
                        .DOCUMENT_FROM_TEMPLATE.path
                    }
                    exact
                    component={DocumentTemplateView}
                  />
                  <RoleRoute
                    roles={ROUTES.DOCUMENT_MANAGEMENT.rolePermission}
                    path={
                      ROUTES.DOCUMENT_MANAGEMENT.internalPaths
                        .DOCUMENT_FROM_ATTACH.path
                    }
                    exact
                    component={DocumentAttachView}
                  />
                </Switch>
              </Suspense>
              <IdleTimerSession />
            </Layout>
          </Session>
        </ProtectedRoute>
      </Switch>
    </>
  );
};
