import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Document } from 'eversign-enhance';
import { DocumentStatusPill } from './DocumentStatusPill';

/**
 * @param props - Properties.
 * @param props.doc - Document.
 * @returns {JSX.Element} - Component.
 */
export const DocCard: React.FC<{ doc: Document }> = ({ doc }): JSX.Element => (
  <Card>
    <CardContent>
      <Stack direction="column" alignItems="center">
        <TextSnippetIcon
          sx={{
            fontSize: 100,
          }}
        />
        <DocumentStatusPill doc={doc} />
      </Stack>
    </CardContent>
  </Card>
);
