import React, { useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
} from '../../shared/components/ConfirmationDialog';

/**
 * @returns {JSX.Element} - Timer session component.
 */
export const IdleTimerSession: React.FC = (): React.ReactElement => {
  const history = useHistory();
  const ref = useRef<ConfirmationDialogRef | null>(null);

  /**
   * @returns {void} - Nothing.
   */
  const onPrompt = (): void => {
    // Fire a Modal Prompt
    ref.current?.onOpen();
  };

  /**
   * @returns {void} - Nothing.
   */
  const onIdle = (): void => {
    // Modal Prompt times out.
    history.push('/logout');
  };

  const { reset } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 30, // <= 30 minutes
    promptTimeout: 1000 * 10 * 3, // <= 30 seconds
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  /**
   * @returns {void} - Nothing to do.
   */
  const onStayLoggedIn = (): void => {
    reset();
    ref.current?.onClose();
  };

  return (
    <ConfirmationDialog
      ref={ref}
      title="Session Timeout"
      onConfirm={onStayLoggedIn}
      disableBackdropClick
      onClose={() => history.push('/logout')}
      description="You will be logout in 30 seconds."
      confirmButtonText="Stay logged in."
      cancelButtonText="Logout"
    />
  );
};
