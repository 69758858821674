/* eslint-disable @typescript-eslint/ban-types */
import { USER_ROLES } from '../modules/session/session-model';
import { IconNames } from '../shared/components/icons/Icon';

type InternalRoutes<T> = {
  [P in keyof T]: Route<T[P]>;
};
export interface Route<T> {
  icon?: IconNames;
  path: `/${string}`;
  text: string;
  internalPaths: InternalRoutes<T>;
  rolePermission?: (keyof typeof USER_ROLES)[];
}

export type Routes<T> = {
  [P in keyof T]: Route<T[P]>;
};

/**
 * @param input - Input objects.
 * @returns Keys.
 */
export function getKeys<T extends {}>(input: T): (keyof T)[] {
  const keys = Object.keys(input) as (keyof T)[];
  return keys;
}
/**
 * @param parentPath - Path.
 * @param internalPaths - Paths.
 * @returns Paths.
 */
export function mapInternalPaths<T>(
  parentPath: `/${string}`,
  internalPaths: InternalRoutes<T>,
): InternalRoutes<T> {
  const mainKeys = getKeys(internalPaths);

  const result = mainKeys.reduce((prev, current) => {
    const route = { ...internalPaths[current] };

    const newPath = (parentPath + route.path) as `/${string}`;

    route.path = newPath;

    route.internalPaths = mapInternalPaths(newPath, route.internalPaths);

    return {
      ...prev,
      [current]: route,
    };
  }, internalPaths);

  return result;
}

/**
 * @param routes - Input routes.
 * @returns Output routes.
 */
export function createRoutes<T>(routes: Routes<T>): Routes<T> {
  return mapInternalPaths('' as `/${string}`, routes);
}

export const ROUTES = createRoutes({
  DASHBOARD: {
    path: '/dashboard',
    text: 'Dashboard',
    icon: 'dashboard',
    internalPaths: {},
    rolePermission: ['ADMIN', 'AGENT'],
  },
  CUSTOMER_DASHBOARD: {
    path: '/customer-dashboard',
    text: 'Customer Dashboard',
    icon: 'dashboard',
    internalPaths: {},
    rolePermission: [
      'CUSTOMER',
      'CUSTOMER_PRO_DRIVER',
      'CUSTOMER_PRO_LEGACY',
      'CUSTOMER_BASIC',
      'CUSTOMER_ELITE',
      'CUSTOMER_PREMIUM',
    ],
  },
  CASES: {
    path: '/cases',
    text: 'Cases',
    icon: 'ticket',
    // rolePermission: ['ADMIN', 'CUSTOMER','AGENT'],
    internalPaths: {
      CASE_CREATE: {
        path: '/create',
        text: 'Create Case',
        internalPaths: {},
      },
      CASE_UPDATE: {
        path: '/edit/:id',
        text: 'Update Case',
        rolePermission: ['ADMIN', 'AGENT'],
        internalPaths: {},
      },
      CASE_DETAILS: {
        path: '/details/:id',
        text: 'Case Details',
        internalPaths: {},
      },
    },
  },
  Customers: {
    path: '/customers',
    text: 'Customers',
    icon: 'people',
    rolePermission: ['ADMIN', 'AGENT'],
    internalPaths: {
      CUSTOMER_CREATE: {
        path: '/create',
        text: 'Create Customer',
        rolePermission: ['ADMIN', 'AGENT'],
        internalPaths: {},
      },
      CUSTOMER_DRIVER_CREATE: {
        path: '/create-customer-driver',
        text: 'Create Customer',
        rolePermission: ['ADMIN', 'AGENT'],
        internalPaths: {},
      },
      CUSTOMER_EDIT: {
        path: '/edit/:id',
        text: 'Edit Customer',
        rolePermission: ['ADMIN', 'AGENT'],
        internalPaths: {},
      },
      CUSTOMER_DETAIL: {
        path: '/detail/:id',
        text: 'Detail Customer',
        rolePermission: ['ADMIN', 'AGENT'],
        internalPaths: {},
      },
    },
  },
  DRIVERS: {
    path: '/drivers',
    text: 'Drivers',
    icon: 'truck',
    rolePermission: [
      'ADMIN',
      'AGENT',
      'CUSTOMER',
      'CUSTOMER_PRO_LEGACY',
      'CUSTOMER_PREMIUM',
      'CUSTOMER_ELITE',
    ],
    internalPaths: {
      DRIVER_CREATE: {
        path: '/create',
        text: 'Create Driver',
        internalPaths: {},
      },
      DRIVER_DETAIL: {
        path: '/detail/:id',
        text: 'Detail Driver',
        internalPaths: {},
      },
      DRIVER_UPDATE: {
        path: '/edit/:id',
        text: 'Edit Driver',
        internalPaths: {},
      },
      DRIVER_MERGE: {
        path: '/merge',
        text: 'Merge Drivers',
        internalPaths: {},
      },
    },
  },
  UserManagement: {
    path: '/user-management',
    text: 'User Management',
    icon: 'person',
    rolePermission: ['ADMIN'],
    internalPaths: {
      MY_ACCOUNT: {
        path: '/settings/my-account',
        text: 'My Account',
        internalPaths: {},
      },
      NOTIFICATIONS: {
        path: '/settings/notifications',
        text: 'My Account',
        internalPaths: {},
      },
      USER_CREATE: {
        path: '/create',
        internalPaths: {},
        text: 'Create User',
        rolePermission: ['ADMIN', 'AGENT'],
      },
      USER_UPDATE: {
        path: '/:id',
        internalPaths: {},
        text: 'Update User',
      },
    },
  },
  DOCUMENT_MANAGEMENT: {
    path: '/document-management',
    text: 'Document Management',
    icon: 'document',
    rolePermission: ['ADMIN', 'AGENT', 'CUSTOMER_ELITE'],
    internalPaths: {
      CREATE_DOCUMENT: {
        path: '/create',
        internalPaths: {},
        text: 'Create Custom Document',
      },
      DOCUMENT_FROM_TEMPLATE: {
        path: '/template',
        internalPaths: {},
        text: 'Document From Template',
      },
      DOCUMENT_FROM_ATTACH: {
        path: '/attach',
        internalPaths: {},
        text: 'Document From Attach',
      },
    },
  },
});
