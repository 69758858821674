import { ApolloCache, ApolloError } from '@apollo/client';
import dayjs from 'dayjs';
import { snackbar } from '../components/Snackbar';
import { Query } from '../types/generated';

/**
 * @param str - Input string.
 * @returns String humanized.
 */
export function humanize(str: string): string {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (m) => m.toUpperCase());
}

/**
 * @param query - Query to remove from the cache.
 * @returns The function that will handle the cache.
 */
export function clearQueriesFromCache<T>(
  query: keyof Query | Array<keyof Query>,
) {
  return (cache: ApolloCache<T>): void => {
    if (typeof query === 'string') {
      cache.evict({ id: 'ROOT_QUERY', fieldName: query });
    } else {
      query.forEach((item) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: item });
      });
    }

    cache.gc();
  };
}

/**
 * @param rawDate - Date to format.
 * @returns Formatted date.
 */
export function humanizeDate(rawDate: string): string {
  const date = dayjs(rawDate);

  return date.isValid() ? date.format('MM/DD/YYYY') : '-';
}
/**
 * @param phoneNumber - PhoneNumber.
 * @returns Formatted date.
 */
export function validatePhone(phoneNumber: string): string {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
}

/**
 * @param {string} text - Text.
 * @returns {string} - Sentence Case Text.
 */
export const camelCaseToSentenceCaseText = (text: string): string => {
  const result: string = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * @param e - Error input.
 */
export function onErrorMixin(e: Error | ApolloError): void {
  if (e instanceof ApolloError) {
    console.log(JSON.stringify(e, null, 2));

    snackbar.error('apollo error');
  } else {
    snackbar.error(e.message);
  }
}

/**
 * @function titleCase - Convert a string to title case.
 * @param str - String to be converted.
 * @returns {string} - Title.
 */
export const titleCase = (str: string): string =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

/**
 *
 * @param {string} str - Text to clean.
 * @returns {string} Parsed string.
 */
export const cleanHTMLFromString = (str: string): string =>
  str
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/&nbsp;/g, '')
    .trim();
