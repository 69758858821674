import React from 'react';
import { Box } from '@mui/system';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  Link,
  PaperProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PaperStyled } from '../card/Card';
import { StyledTableCell } from '../table/TableRowStyled';
import { Case } from '../../types/generated';
import { humanizeDate } from '../../utils';
import { TableRowLoading } from '../table/TableRowLoading';
import { CaseStatus } from '../../../modules/cases/case-models';
import { Pill } from '../Pills';
import { ROUTES } from '../../../routes/routes-model';

const useStyles = makeStyles((theme: Theme) => ({
  pills: {
    color: '#fff',
    borderRadius: '14px',
    fontWeight: 'bold',
    padding: theme.spacing(1),
    maxWidth: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px auto',
    [theme.breakpoints.down('md')]: {
      minWidth: '110px',
      textAlign: 'center',
    },
  },
}));

/**
 * @param {Object} props - Component props.
 * @param {boolean} props.showTitle - Show title.
 * @param {Object} props.paperProps - Paper props.
 * @param {Object} props.casesList - Cases list.
 * @param {Object} props.loading - Cases list.
 * @returns Component.
 */
export function CasesList({
  showTitle = true,
  paperProps,
  casesList,
  loading,
}: {
  showTitle?: boolean;
  paperProps?: PaperProps;
  casesList: Array<Case> | undefined;
  loading: boolean;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Box marginBottom="30px">
      <Box>
        <PaperStyled {...paperProps}>
          <TableContainer>
            {showTitle && (
              <Box width="100%" padding="30px 30px 30px 30px">
                <Typography variant="h5" component="h2" color="white">
                  Cases list
                </Typography>
              </Box>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Case ID</StyledTableCell>
                  <StyledTableCell>Court Date</StyledTableCell>
                  <StyledTableCell>State</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && !casesList?.length && (
                  <TableRowLoading loading={loading} colSpan={9} />
                )}
                {!loading && casesList && (
                  <>
                    {casesList.map((caseItem) => (
                      <TableRow key={caseItem.id}>
                        <StyledTableCell>
                          {caseItem.caseID ? caseItem.caseID : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {humanizeDate(caseItem?.citationDueDate ?? '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {caseItem.inspectionCitationState?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {caseItem.status === CaseStatus.NEW && (
                            <Pill text="NEW" type={0} />
                          )}
                          {caseItem.status === CaseStatus.IN_PROGRESS_DOL && (
                            <Box className={classes.pills} bgcolor="#000">
                              <LocalShippingIcon
                                fontSize="small"
                                sx={{ marginRight: '5px' }}
                              />
                              IN PROGRESS
                            </Box>
                          )}

                          {caseItem.status === CaseStatus.CLOSED && (
                            <Box
                              className={classes.pills}
                              style={{ color: '#18151880' }}
                              bgcolor="#CCCCCC80"
                            >
                              CLOSED
                            </Box>
                          )}
                          {caseItem.status === CaseStatus.IN_PROGRESS && (
                            <Pill text="IN PROGRESS" type={1} />
                          )}

                          {caseItem.status === CaseStatus.IN_REVIEW && (
                            <Pill text="IN REVIEW" type={5} />
                          )}

                          {caseItem.status === CaseStatus.AWAITING_PM_OR_PW && (
                            <Pill text="AWAITING" type={4} />
                          )}

                          {caseItem.status ===
                            CaseStatus.AWAITING_RES_CLIENT && (
                            <Box className={classes.pills} bgcolor="#FE5722">
                              <PersonIcon
                                fontSize="small"
                                sx={{ marginRight: '5px' }}
                              />
                              IN PROGRESS
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link
                            target="_self"
                            href={`${ROUTES.CASES.path}/details/${caseItem.id}`}
                          >
                            <IconButton color="primary">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </>
                )}
                {!loading && !casesList?.length && (
                  <TableRow>
                    <StyledTableCell
                      colSpan={9}
                      textCenter="center"
                      align="center"
                    >
                      No cases found
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </PaperStyled>
      </Box>
    </Box>
  );
}
