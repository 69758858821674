import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';

import { useEvent } from '@cobuildlab/react-simple-state';
import { Document } from 'eversign-enhance';
import { DocumentTypeEnum } from '../document-types';
import { OnDocumentModal, OnDocumentUIStateEvent } from '../documents-events';
import { ExtendedResponse } from '../../../shared/eversign/eversign-actions';
import { downloadFinalDocumentService } from '../document-services';
import { DocModal, DocModalRef } from './DocModal';
import { FCPCheckbox } from '../../../shared/components/checkbox/Checkbox';

/**
 * The footer for the document card.
 *
 * @param props - Properties.
 * @param props.document - Document.
 * @returns {JSX.Element} - Component.
 */
export const DocCardFooter: React.FC<{ document: Document }> = ({
  document,
}): JSX.Element => {
  const state = useEvent(OnDocumentUIStateEvent);
  const { documentsSelected, type } = state;

  const [docSelected, setDocSelected] = useState<Document>();
  const docModalRef = useRef<DocModalRef | null>(null);

  /**
   * Handle the click on the eye icon button.
   *
   * @param {Document} doc - Doc.
   * @returns {Promise<void>} - Nothing.
   */
  const handleTheClickOnEyeIconButton = async (
    doc: Document,
  ): Promise<void> => {
    if (doc.getIsDraft()) {
      // Open the iframe modal.
      OnDocumentModal.dispatch({
        modalType: 'prepare',
        embeddedClaimUrl: (doc.toObject() as ExtendedResponse).embeddedClaimUrl,
      });
      return;
    }

    if (doc.getIsCompleted()) {
      // Download the doc.
      await downloadFinalDocumentService(doc);
      return;
    }

    // Open the modal to show the data...
    setDocSelected(doc);
    docModalRef.current?.onOpen();
  };

  /**
   * @description - Handle the document selected.
   * @param {Document} doc - Document selected.
   * @returns {void} - Nothing.
   */
  const handleDocumentSelected = (doc: Document): void => {
    // Do we have it, yet?
    const isSelected = documentsSelected.some(
      (item) => item.getDocumentHash() === doc.getDocumentHash(),
    );

    // Yes, then removed from the list.
    if (isSelected) {
      const newResult = documentsSelected.filter(
        (item) => item.getDocumentHash() !== doc.getDocumentHash(),
      );
      OnDocumentUIStateEvent.dispatch({
        ...state,
        documentsSelected: newResult,
      });
      return;
    }

    // Added.
    const newResult = documentsSelected;
    newResult.push(doc);
    OnDocumentUIStateEvent.dispatch({
      ...state,
      documentsSelected: newResult,
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box height="40px">
        <IconButton
          id="icon-btn-eye"
          onClick={() => handleTheClickOnEyeIconButton(document)}
        >
          {!document.getIsCompleted() ? <VisibilityIcon /> : <DownloadIcon />}
        </IconButton>
        {type !== DocumentTypeEnum.ALL && (
          <FCPCheckbox
            checked={documentsSelected.some(
              (item) => document.getDocumentHash() === item.getDocumentHash(),
            )}
            onChange={() => handleDocumentSelected(document)}
          />
        )}
      </Box>
      <DocModal ref={docModalRef} doc={docSelected} />
    </Box>
  );
};
