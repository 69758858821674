import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import { StandardTextFieldProps } from '@mui/material';
import * as React from 'react';
import { useDelay } from '../../hooks/delay';
import { useTableToolbarDimensions } from './table-hooks';

export type DefaultRowSize = 10 | 20 | 50;

interface RowSelectorProps {
  onSelectRow?: (row: DefaultRowSize) => void;
  defaultRow?: DefaultRowSize;
  rightComponentIsLarger?: boolean;
}

/**
 * @param {StandardTextFieldProps} props - Material ui standard text field properties.
 * @returns {JSX.Element} - Table row per page options.
 */
export const RowSelector: React.FC<StandardTextFieldProps> = (props) => {
  const options = [
    { label: '10 Row(s)', value: 10 },
    { label: '20 Row(s)', value: 20 },
    { label: '50 Row(s)', value: 50 },
  ];

  return (
    <FormControl variant="outlined" fullWidth>
      <TextField select label="Rows" {...props}>
        {options.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

/**
 * @param {StandardTextFieldProps} props - Properties.
 * @returns {JSX.Element} - Searcher table component.
 */
export const SearchInput: React.FC<StandardTextFieldProps> = (props) => (
  <FormControl variant="outlined" fullWidth>
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label="Search"
      {...props}
    />
  </FormControl>
);

interface TableToolbarProps extends RowSelectorProps {
  rightComponent?: React.ReactNode | null | undefined;
  onSearch?: (value: string) => void;
  menuButtonComponent?: React.ReactNode | null | undefined;
  showTheRowSelector?: boolean;
  showTheRowSearch?: boolean;
}

/**
 * @param {TableToolbarProps} props - Properties.
 * @returns {JSX.Element} - Toolbar.
 */
export const TableToolbar: React.FC<TableToolbarProps> = ({
  showTheRowSelector = true,
  showTheRowSearch = true,
  rightComponentIsLarger = false,
  ...props
}) => {
  const { delayExecution } = useDelay();
  const { leftComponent, rightComponent, searchComponent, rowComponent } =
    useTableToolbarDimensions(rightComponentIsLarger);

  /**
   * @returns {number} - Width.
   */
  const preparedTheSearchWidth = (): number => {
    if (!props.menuButtonComponent && !showTheRowSelector) {
      return 12;
    }
    return 6;
  };

  const searchDisplay = showTheRowSearch ? 'flex' : 'none';

  return (
    <Toolbar variant="dense" disableGutters>
      <Grid container direction="row" spacing={0}>
        {
          // Left component
        }
        <Grid
          container
          item
          xs={leftComponent.xs}
          md={leftComponent.md}
          lg={leftComponent.lg}
          alignContent="center"
          spacing={3}
        >
          {
            // Search component.
          }
          <Grid
            display={searchDisplay}
            item
            xs={searchComponent.xs}
            sm={preparedTheSearchWidth()}
            md={searchComponent.md}
            lg={searchComponent.lg}
          >
            <SearchInput
              onChange={(e) => {
                // Delay execution to avoid the search to be executed on every key stroke.
                delayExecution(() => {
                  if (props.onSearch) props.onSearch(e.target.value);
                }, 300);
              }}
            />
          </Grid>

          {
            // Rows selector.
          }
          {showTheRowSelector ? (
            <Grid
              item
              xs={rowComponent.xs}
              sm={rowComponent.sm}
              lg={rowComponent.lg}
              sx={{
                marginBottom: {
                  xs: '24px',
                  md: '0px',
                },
              }}
            >
              <RowSelector
                defaultValue={props.defaultRow || 10}
                onChange={(e) => {
                  if (props.onSelectRow)
                    props?.onSelectRow(
                      Number(e.target.value) as DefaultRowSize,
                    );
                }}
              />
            </Grid>
          ) : null}

          {
            // Filter component.
          }
          {props.menuButtonComponent ? (
            <Grid item xs sm md lg>
              {props.menuButtonComponent || props.menuButtonComponent}
            </Grid>
          ) : null}
        </Grid>
        {
          // Right Button.
        }
        {props.rightComponent ? (
          <Grid
            container
            item
            xs={rightComponent.xs}
            md
            lg
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12} sm justifyContent="flex-end" display="flex">
              {props.rightComponent}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Toolbar>
  );
};
