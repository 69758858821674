interface Dimension {
  xs?: number;
  md?: number;
  lg?: number;
  sm?: number;
}

interface UseTableToolbarDimensionsReturn {
  leftComponent: Dimension;
  rightComponent: Dimension;
  searchComponent: Dimension;
  rowComponent: Dimension;
}

/**
 * Hook that returns the table toolbar grids proportions.
 *
 * @param {boolean} rightComponentIsLarger - IsEven.
 * @returns {UseTableToolbarDimensionsReturn} Grids proportions.
 */
export const useTableToolbarDimensions = (
  rightComponentIsLarger: boolean,
): UseTableToolbarDimensionsReturn => {
  const leftComponent = {
    xs: 12,
    md: rightComponentIsLarger ? 12 : 8,
    lg: rightComponentIsLarger ? 5 : 8,
  };

  const rightComponent = {
    xs: 12,
    md: rightComponentIsLarger ? 12 : 4,
    lg: rightComponentIsLarger ? 7 : 4,
  };

  const searchComponent = {
    xs: 12,
    md: 6,
    lg: rightComponentIsLarger ? 7 : 3,
  };

  const rowComponent = {
    xs: 8,
    sm: 4,
    lg: rightComponentIsLarger ? 5 : 2,
  };

  return { leftComponent, rightComponent, searchComponent, rowComponent };
};
