import { FC, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useFormContext } from 'react-hook-form';
import type {
  CustomersListQuery,
  Driver,
  Customer,
  DriversByCustomerIdQuery,
} from '../../../../shared/types/generated';
import { BackButton } from '../../../../shared/components/buttons';
import { PaperStyled } from '../../../../shared/components/card/Card';
import { PRIMARY, PRIMARY_LIGHT } from '../../../../shared/css/theme';
import { useUserHasRole } from '../../../session/session-hooks';
import { SignersForm } from '../../components/SignersForm';

import { SignerAutocompletes } from '../../components/SignerAutocompletes';

import { AttachDocumentDialogFormType } from '../Document-attach-types';

interface Props {
  onDriverSelection: (driverInput: Driver | null) => void;
  onCustomerSelection: (customerInput: Customer | null) => void;
  handleDeleteAdditionalSigner: (email: string) => void;
  driversData?: DriversByCustomerIdQuery;
  customersData?: CustomersListQuery;
  driversSelectIsDisabled: boolean;
  driverInputSwitch: boolean;
  handleFileClick: () => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileRef: React.RefObject<HTMLInputElement>;
  submit: (data: AttachDocumentDialogFormType) => Promise<void>;
  toggleDriverSwitch: () => void;
  handleAddAdditionalSigner: () => void;
  uploadingDoc: boolean;
}

/**
 * @param {Props} props - Props.
 * @returns {JSX.Element} DocumentAttachForm.
 */
export const DocumentAttachForm: FC<Props> = ({
  onCustomerSelection,
  onDriverSelection,
  handleDeleteAdditionalSigner,
  driversData,
  driversSelectIsDisabled,
  customersData,
  handleFileClick,
  handleFileChange,
  fileRef,
  submit,
  driverInputSwitch,
  toggleDriverSwitch,
  handleAddAdditionalSigner,
  uploadingDoc,
}) => {
  const { register, formState, watch, handleSubmit } =
    useFormContext<AttachDocumentDialogFormType>();

  const { file, signers, signerName, signerEmail, driver } = watch();
  const history = useHistory();

  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');

  const { errors } = formState;

  const fileName = file ? file.name : '';

  const disableAddSigner = !signerName || !signerEmail;

  const sendButtonDisabled =
    uploadingDoc || !signers || signers.length === 0 || !file;

  const driverInputSwitchText = driverInputSwitch
    ? 'Remove Driver'
    : 'Add driver as Signer';

  const driverInputSwitchTextDisplay = isCustomerElite ? 'none' : 'flex';

  useEffect(() => {
    if (isCustomerElite) toggleDriverSwitch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite]);

  return (
    <Box mt="20px" padding="0px 10px">
      <Grid container spacing={1} padding={1}>
        <Box
          width={{ xs: '100%', sm: 'auto' }}
          display="flex"
          alignItems="center"
        >
          <BackButton
            onClick={() => history.push('/document-management')}
            text="Back to Document Management"
          />
        </Box>
        <Grid container marginBottom={2} alignItems="center">
          <Grid item sm={12} md={4}>
            <Box color={PRIMARY} fontSize="18px" component="h2">
              Create document by Attachment
            </Box>
          </Grid>

          <Grid container item sm={12} md={8} justifyContent="flex-end">
            <LoadingButton
              disabled={sendButtonDisabled}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={handleSubmit(submit)}
              loading={uploadingDoc}
            >
              Send Document
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <PaperStyled>
        <form>
          <Box padding={3}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={Boolean(errors.documentTitle)}
                helperText={errors.documentTitle?.message}
                label="Document Title"
                {...register('documentTitle')}
              />
            </FormControl>
          </Box>
          <Divider />
          <Grid padding={3} spacing={3} container>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Attach Document"
                  value={fileName}
                  error={Boolean(errors.file)}
                  helperText={errors.file?.message}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleFileClick}>
                          <AttachFileIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  style={{ display: 'none', visibility: 'hidden' }}
                  onChange={handleFileChange}
                  ref={fileRef}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} container alignItems="center" spacing={3}>
              <Grid item color={PRIMARY} component="h2" fontSize="18px">
                Signers
              </Grid>
              <Grid
                display={driverInputSwitchTextDisplay}
                item
                color={PRIMARY_LIGHT}
                component="p"
                fontSize="16px"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={toggleDriverSwitch}
              >
                {driverInputSwitchText}
              </Grid>
            </Grid>

            <SignerAutocompletes
              show={driverInputSwitch}
              driversSelectIsDisabled={driversSelectIsDisabled}
              onCustomerSelection={onCustomerSelection}
              onDriverSelection={onDriverSelection}
              customersData={customersData}
              driversData={driversData}
            />

            <Grid item xs={12} sm={6}>
              <TextField
                disabled={!!driverInputSwitch}
                key={`signerName-${driver}`}
                label="Signer's Name"
                type="text"
                fullWidth
                {...register('signerName')}
              />
            </Grid>
            <Grid item xs={12} sm={6} container alignItems="center" spacing={1}>
              <Grid item xs={11}>
                <TextField
                  disabled={!!driverInputSwitch}
                  key={`signerName-${driver}`}
                  label="Signer's Email"
                  type="text"
                  fullWidth
                  helperText={errors.signerEmail?.message}
                  error={Boolean(errors.signerEmail?.message)}
                  {...register('signerEmail')}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  disabled={disableAddSigner}
                  variant="contained"
                  color="secondary"
                  onClick={handleAddAdditionalSigner}
                  sx={{
                    padding: '5px',
                    minWidth: 'unset',
                  }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <SignersForm
                  signers={signers}
                  onDelete={handleDeleteAdditionalSigner}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </PaperStyled>
    </Box>
  );
};
