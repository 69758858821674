import { useEffect } from 'react';
import { Document } from 'eversign-enhance';
import { useQuery, UseQueryResult } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm, UseFormReturn } from 'react-hook-form';

import {
  useDriversByCustomerIdLazyQuery,
  EversingDocumentListQuery,
} from '../../shared/types/generated';
import {
  UseCustomDriversByCustomerIdLazyQueryResult,
  DocumentTemplateFormType,
  DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA,
  SIGNERS_FORM_VALIDATOR_SCHEMA,
  SignersFormType,
  DocumentTypeEnum,
} from './document-types';
import { snackbar } from '../../shared/components/Snackbar';
import { everSignClient } from '../../shared/eversign';
import { useSubscribeError } from '../../shared/hooks/hooks';
import { getManyDocumentByHash } from '../../shared/eversign/eversign-actions';

interface UseGetDocumentListProps {
  type: `${DocumentTypeEnum}`;
  limit: number;
  page: number;
  search: string;
  hashesData?: EversingDocumentListQuery;
}

/**
 *
 * @param {UseGetDocumentListProps} Props - Props.
 * @returns {UseQueryResult<Document[]>} Documents.
 */
export function useGetDocumentList({
  limit,
  page,
  search,
  type,
  hashesData,
}: UseGetDocumentListProps): UseQueryResult<Document[], unknown> {
  const ids = hashesData?.everSignDocsList.items.map((e) => e.hash);

  const promise = hashesData
    ? () => getManyDocumentByHash(ids as string[])
    : () => everSignClient.getDocuments(type, 'Document', limit, page, search);

  const queryData = useQuery('Documents', promise, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });

  return queryData;
}

/**
 * @param {UseFormProps} formOptions - Form options.
 * @returns Form hook state.
 */
export function useDocumentTemplateForm(
  formOptions?: UseFormProps,
): UseFormReturn<DocumentTemplateFormType> {
  const data = useForm<DocumentTemplateFormType>({
    ...formOptions,
    resolver: yupResolver(DOCUMENT_TEMPLATE_DIALOG_FORM_VALIDATOR_SCHEMA, {}),
  });

  useSubscribeError(data, () => {
    snackbar.error('Validation error');
  });

  return data;
}

/**
 * @param {UseFormProps} formOptions - Form options.
 * @returns Form hook state.
 */
export function useSignersForm(
  formOptions?: UseFormProps,
): UseFormReturn<SignersFormType> {
  const data = useForm<SignersFormType>({
    ...formOptions,
    resolver: yupResolver(SIGNERS_FORM_VALIDATOR_SCHEMA, {}),
  });

  useSubscribeError(data, () => {
    snackbar.error('Validation error');
  });

  return data;
}

/**
 * Custom useCustomDriversByCustomerIdLazyQuery hook.
 *
 * @param {string} customerId - CustomerId.
 * @returns {UseCustomDriversByCustomerIdLazyQueryResult} DriversData.
 */
export const useCustomDriversByCustomerIdLazyQuery = (
  customerId?: string,
): UseCustomDriversByCustomerIdLazyQueryResult => {
  const [
    getDrivers,
    { loading: loadingDrivers, data: driversData, called: driversCalled },
  ] = useDriversByCustomerIdLazyQuery();

  useEffect(() => {
    if (customerId)
      getDrivers({
        variables: {
          customerID: customerId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return { loadingDrivers, driversData, driversCalled };
};
