import {
  CustomerDetailQuery,
  CustomerUpdateInput,
} from '../../shared/types/generated';
import { CustomerDriverFormType } from './customer-driver-types';

/**
 * @description - Prepare customer driver data to update.
 * @param inputData - Input Data.
 * @param previousData - Previous Data.
 * @returns {CustomerUpdateInput} - Prepared data.
 */
export const prepareCustomerDriverDataToUpdate = (
  inputData: CustomerDriverFormType,
  previousData: CustomerDetailQuery | undefined,
): CustomerUpdateInput => {
  // Extract the driver from the previous data
  const driver = previousData?.customer.driverCustomerRelation.items[0];

  // Prepare new addresses by filtering out those without an id and with at least one address field filled
  const newAddresses = inputData?.customerAddressRelation?.filter(
    (address) =>
      !address.id &&
      (address.apartment ||
        address.city ||
        address.state ||
        address.street ||
        address.zipCode),
  );

  // Filter out existing addresses that have an id
  const updateExistingAddresses = inputData?.customerAddressRelation?.filter(
    (address) => address.id,
  );

  // Prepare the data to be updated
  const preparedData: CustomerUpdateInput = {
    name: `${inputData.name} ${inputData.lastName}`,
    contactName: `${inputData.name} ${inputData.lastName}`,
    phoneNumber: inputData.phoneNumber,
    email: inputData.email,
    subscription: inputData.subscription,

    // Update driver details
    driverCustomerRelation: {
      update: [
        {
          filter: {
            id: driver?.id,
          },
          data: {
            name: inputData.name,
            lastName: inputData.lastName,
            email: inputData.email,
            dateOfBirth: inputData.dateOfBirth,
            phoneNumber: {
              number: inputData.phoneNumber,
            },
            preferredLanguage: {
              reconnect: {
                id: inputData.language,
              },
            },
            licenseNumber: inputData.license.number,
            licenseState: inputData.license.state,
          },
        },
      ],
    },

    // Update address details
    address: {
      create: newAddresses?.map((address) => ({
        city: address.city || '',
        apartment: address.apartment || '',
        street: address.street || '',
        zipCode: address.zipCode || '',
        state: {
          connect: {
            id: address.state || '',
          },
        },
      })),
      update: updateExistingAddresses?.map((address) => ({
        filter: {
          id: address.id,
        },
        data: {
          city: address.city || '',
          apartment: address.apartment || '',
          street: address.street || '',
          zipCode: address.zipCode || '',
          state: {
            connect: {
              id: address.state || '',
            },
          },
        },
      })),
    },

    user: {
      update: {
        firstName: inputData.name,
        lastName: inputData.lastName,
        phoneNumber: inputData.phoneNumber,
      },
    },
  };

  return preparedData;
};
