import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { capitalCase } from 'change-case';
import { Edit } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { PaperStyled } from '../../../shared/components/card/Card';
import { BackButton } from '../../../shared/components/buttons';
import {
  useCustomerDetailQuery,
  useUpdateCustomerMutation,
} from '../../../shared/types/generated';
import { clearQueriesFromCache, humanizeDate } from '../../../shared/utils';
import { ROUTES } from '../../../routes/routes-model';
import { CasesList } from '../../../shared/components/CasesAsigned/CasesList';
import { DriversList } from './DriversList';
import { createCaseList } from '../customer-utils';
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
} from '../../../shared/components/ConfirmationDialog';
import { snackbar } from '../../../shared/components/Snackbar';
import { CustomerStatusEnum } from '../customer-types';
import { CaseStatus } from '../../cases/case-models';
import { CustomerAttachDocuments } from './CustomerAttachDocuments';

/**
 * @returns Component.
 */
export function CustomerDetail(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, loading } = useCustomerDetailQuery({
    variables: {
      id: id ?? '',
    },
  });
  const confirmationDialogRef = React.useRef<ConfirmationDialogRef | null>(
    null,
  );

  const caseList = createCaseList(
    data?.customer.driverCustomerRelation.items ?? [],
  );

  // To update status.
  const [updateCustomerMutation] = useUpdateCustomerMutation({
    /**
     * @returns {void} - Nothing.
     */
    onCompleted: (): void => {
      confirmationDialogRef.current?.onClose();
      snackbar.success('Customer status updated successfully');
    },
    /**
     * @param cache - Apollo chache.
     */
    update: clearQueriesFromCache(['customersList', 'customer']),
  });

  return (
    <>
      <Grid container display="flex" justifyContent="space-between">
        <Box
          width={{ xs: '100%', md: 'auto' }}
          display="flex"
          alignItems="center"
        >
          <BackButton
            onClick={() => history.push('/customers')}
            text="Back to Customers"
          />
        </Box>
        <Box
          width={{ xs: '100%', md: 'auto' }}
          sx={{ marginTop: { xs: 2, md: 'auto' } }}
        >
          <Stack direction="row" spacing={3}>
            <Button
              variant="contained"
              color="error"
              sx={{
                width: { xs: '100%', md: 'auto' },
              }}
              onClick={() => confirmationDialogRef.current?.onOpen()}
            >
              {data?.customer.status ===
              CustomerStatusEnum.Active.toLocaleLowerCase()
                ? 'Disable Customer'
                : 'Active Customer'}
            </Button>
            <Button
              variant="contained"
              sx={{
                width: { xs: '100%', md: 'auto' },
              }}
              startIcon={<Edit />}
              color="secondary"
              onClick={() => {
                history.push(`${ROUTES.Customers.path}/edit/${id}`);
              }}
            >
              Edit Customer
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Box mt="50px">
        <PaperStyled>
          <Box padding="30px 30px 50px 30px">
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" color="white">
                  Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  Company name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.customer?.name
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  Contact Name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.customer?.contactName
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  ID
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.customer?.customerID
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  Customer Since
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    humanizeDate(data?.customer?.createdAt ?? '')
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  # Cases
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading && <Skeleton animation="wave" />}
                  {data?.customer.driverCustomerRelation.items.length &&
                    data?.customer.driverCustomerRelation.items
                      .map((item) => item.caseDriverRelation.count)
                      .reduce((a, b) => a + b)
                      .toString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  Cases Solved
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    data?.customer.driverCustomerRelation.items
                      .map((item) =>
                        item.caseDriverRelation.items.map((i) => i.status),
                      )
                      .flat()
                      .filter((status) => status === CaseStatus.CLOSED).length
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h5" component="h2" color="white">
                  Subscription
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {loading && <Skeleton animation="wave" />}
                  {data?.customer.subscription
                    ? capitalCase(data?.customer.subscription)
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </PaperStyled>
      </Box>
      <DriversList />
      <CasesList casesList={caseList} loading={loading} />
      <CustomerAttachDocuments />
      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Are you sure?"
        description={`This customer will be ${
          data?.customer.status ===
          CustomerStatusEnum.Active.toLocaleLowerCase()
            ? 'Disable Customer'
            : 'Active Customer'
        }`}
        confirmButtonText={
          data?.customer.status ===
          CustomerStatusEnum.Active.toLocaleLowerCase()
            ? 'Disable Customer'
            : 'Active Customer'
        }
        onConfirm={() => {
          updateCustomerMutation({
            variables: {
              data: {
                id: data?.customer.id,
                status:
                  data?.customer.status ===
                  CustomerStatusEnum.Active.toLocaleLowerCase()
                    ? CustomerStatusEnum.Disabled.toLocaleLowerCase()
                    : CustomerStatusEnum.Active.toLocaleLowerCase(),
              },
            },
          });
        }}
      />
    </>
  );
}
