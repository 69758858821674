import {
  Button,
  CircularProgress,
  Dialog,
  Box,
  DialogActions,
  DialogTitle,
  Stack,
  IconButton,
  DialogProps,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from '../buttons';

interface ConfirmDialogProps extends DialogProps {
  open: boolean;
  title?: string;
  subTitle?: string;
  loading: boolean;
  hideButtons?: boolean;
  submitOnConfirm?: boolean;
  confirmText?: React.ReactNode;
  cancelText?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
}

/**
 * @param {boolean} open - Props for open the dialog.
 * @returns {JSX.Element} - Dialog.
 */
export const ContentDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  subTitle,
  loading,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  children,
  maxWidth = 'md',
  submitOnConfirm,
  hideButtons,
  ...rest
}) => {
  const buttonType = submitOnConfirm ? 'submit' : 'button';

  const buttonSubmitText = loading ? (
    <CircularProgress size={20} color="secondary" />
  ) : (
    confirmText
  );

  return (
    <Dialog open={open} maxWidth={maxWidth} {...rest}>
      <DialogTitle style={{ color: '#3A526A', paddingBottom: '10px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p style={{ fontSize: '18px' }}>{title}</p>

          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <p style={{ fontSize: '14px', margin: 0 }}>{subTitle}</p>
      </DialogTitle>

      <Box paddingBottom={3} paddingX={3}>
        {children}
      </Box>
      {!hideButtons && (
        <DialogActions>
          <Button
            variant="contained"
            sx={{ background: '#CCCCCC' }}
            disabled={loading}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <PrimaryButton
            type={buttonType}
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={onConfirm}
          >
            {buttonSubmitText}
          </PrimaryButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

ContentDialog.defaultProps = {
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  hideButtons: false,
};
