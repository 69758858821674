export enum CaseStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_PROGRESS_DOL = 'IN_PROGRESS_DOL',
  CLOSED = 'CLOSED',
  IN_REVIEW = 'IN_REVIEW',
  AWAITING_PM_OR_PW = 'AWAITING_PM_OR_PW',
  AWAITING_RES_CLIENT = 'AWAITING_RES_CLIENT',
}

export enum DataQsStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  SUBMITTED = 'SUBMITTED',
  CLOSED = 'CLOSED',
}

export const CitationStatus = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  OPEN_FILLED: 'Closed/Filed in DataQs',
} as const;

export enum CaseCitationStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
  OPEN_FILLED = 'Closed/Filed in DataQs',
}

export type DataQsStatusTypes = `${DataQsStatus}`;
export type CaseStatusTypes = `${CaseStatus}`;
export type CaseCitationStatusType = `${CaseCitationStatus}`;

export const CitationStatusOptions = [
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
  {
    label: 'Closed/Filed in DataQs',
    value: 'Closed/Filed in DataQs',
  },
];
