import { useState, useRef, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { isValidEmail } from '@cobuildlab/validation-utils';

import {
  Customer,
  Driver,
  useCustomersListQuery,
  SortOrder,
  useUserSessionQuery,
} from '../../../shared/types/generated';
import { useUserHasRole } from '../../session/session-hooks';
import { handleDriverSelection, shouldAddSigner } from '../document-utils';
import { useCustomDriversByCustomerIdLazyQuery } from '../document-hooks';
import { createDraftDocument } from '../document-actions';
import { DocumentAttachForm } from './components/DocumentAttachForm';
import { DocumentDialog } from '../components/DocumentDialog';
import { useDocumentAttachmentForm } from './Document-attach-hooks';
import { validMimeTypes } from './Document-attach-models';
import type { AttachDocumentDialogFormType } from './Document-attach-types';

/**
 * DocumentTemplateView.
 *
 * @returns {JSX.Element} DocumentTemplateView.
 */
export const DocumentAttachView = (): JSX.Element => {
  const formMethods = useDocumentAttachmentForm();
  const fileRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const [uploadingDoc, setUploadingDoc] = useState<boolean>(false);
  const [driverInputSwitch, setDriverInputSwitch] = useState<boolean>(false);
  const { data: userData } = useUserSessionQuery();
  const isCustomerElite = useUserHasRole('CUSTOMER_ELITE');

  const { watch, setValue, getValues, setError, clearErrors } = formMethods;

  const { customer, signerEmail, signerName, signerLastName, driver } = watch();

  const { driversCalled, driversData, loadingDrivers } =
    useCustomDriversByCustomerIdLazyQuery(customer);

  const { loading: customersLoading, data: customersData } =
    useCustomersListQuery({
      variables: {
        sort: {
          name: SortOrder.Asc,
        },
      },
    });

  useEffect(() => {
    if (isCustomerElite) setValue('customer', userData?.user.customer.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerElite, setValue]);

  /**
   *
   */
  const onCancel = (): void => {
    history.push('/document-management');
  };

  /**
   * OnAutocompleteSelection.
   *
   * @param {Customer} customerInput - Event.
   */
  const onCustomerSelection = (customerInput: Customer | null): void => {
    if (customerInput) {
      setValue('customer', customerInput?.id as string);
      setValue('driver', undefined);
    }
  };

  /**
   * @param {Driver} driverInput - Event.
   */
  const onDriverSelection = (driverInput: Driver | null): void => {
    const previousSigners = getValues('signers') || [];

    const { selectedDriver, signer } = handleDriverSelection({
      driverInput,
      driversData,
      previousSigners,
    });

    if (selectedDriver) setValue('driver', selectedDriver.id);

    if (signer && selectedDriver) {
      setValue('signerName', selectedDriver.name as string);
      setValue('signerEmail', selectedDriver.email as string);
      setValue('signerLastName', selectedDriver.lastName as string);
    }
  };

  /**
   * Submit.
   *
   * @param {AttachDocumentDialogFormType} data - Data.
   */
  const submit = async (data: AttachDocumentDialogFormType): Promise<void> => {
    setUploadingDoc(true);
    await createDraftDocument(data);
    setUploadingDoc(false);
  };

  /**
   * HandleFileChange.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - Event.
   * @returns {void} Void.
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) {
      return;
    }

    const fileInput = e.target.files[0];

    const mimeType = fileInput.type;
    const isValidMimeType = validMimeTypes.some((i) => i === mimeType);
    if (isValidMimeType) {
      setValue('file', fileInput);
      clearErrors('file');
    } else {
      setError('file', { message: 'Invalid file type', type: 'validate' });
    }
  };

  /**
   * HandleFileClick.
   */
  const handleFileClick = (): void => {
    if (fileRef.current) fileRef.current.click();
  };

  /**
   *
   */
  const handleClearCustomerData = (): void => {
    setValue('signerEmail', undefined);
    setValue('signerName', undefined);
    setValue('driver', undefined);
    if (!isCustomerElite) setValue('customer', undefined);
  };

  /**
   */
  const handleAddAdditionalSigner = (): void => {
    const previousSigners = getValues('signers') || [];

    const signerDataComplete = signerEmail && signerName;

    if (!signerDataComplete) return;

    if (!isValidEmail(signerEmail)) {
      setError('signerEmail', { message: 'Invalid email format' });
      return;
    }

    clearErrors('signerEmail');

    const shouldAdd = shouldAddSigner({
      previousSigners,
      signerEmail,
    });

    if (shouldAdd) {
      setValue('signers', [
        ...previousSigners,
        {
          email: signerEmail.trim(),
          name: signerName.trim(),
          lastName: signerLastName?.trim(),
          driverId: driver,
        },
      ]);
      handleClearCustomerData();
    }
  };

  /**
   *
   */
  const toggleDriverSwitch = (): void => {
    clearErrors(['signerEmail', 'driver']);
    handleClearCustomerData();
    setDriverInputSwitch(!driverInputSwitch);
  };

  /**
   * @param {string} email - Signer email.
   */
  const handleDeleteAdditionalSigner = (email: string): void => {
    const previousSigners = getValues('signers') || [];

    const filterList = previousSigners.filter((e) => e.email !== email);

    setValue('signers', filterList);
  };

  const driversSelectIsDisabled =
    !driversCalled || loadingDrivers || !customer || customersLoading;

  return (
    <FormProvider {...formMethods}>
      <DocumentDialog onCloseCallback={onCancel} />
      <DocumentAttachForm
        customersData={customersData}
        uploadingDoc={uploadingDoc}
        handleAddAdditionalSigner={handleAddAdditionalSigner}
        toggleDriverSwitch={toggleDriverSwitch}
        driverInputSwitch={driverInputSwitch}
        submit={submit}
        fileRef={fileRef}
        handleFileChange={handleFileChange}
        handleFileClick={handleFileClick}
        driversData={driversData}
        onCustomerSelection={onCustomerSelection}
        onDriverSelection={onDriverSelection}
        handleDeleteAdditionalSigner={handleDeleteAdditionalSigner}
        driversSelectIsDisabled={driversSelectIsDisabled}
      />
    </FormProvider>
  );
};
