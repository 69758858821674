import {
  Document,
  File as EversignFile,
  Signer,
  Template,
} from 'eversign-enhance';
import { everSignClient } from '.';
import {
  EverSignDocCreateManyDocument,
  EverSignDocCreateManyInput,
} from '../types/generated';
import { apolloClient } from '../apollo';
import {
  ExtendedDoc,
  CreateDocumentParams,
  CreateDocumentFromTemplateParams,
} from './eversign-types';
import { snackbar } from '../components/Snackbar';

/**
 *
 * @param {string} signersIds - Driver ids.
 * @param {string} documentHash - Eversing Document Hash.
 */
const createSigners = async (
  signersIds: string[],
  documentHash: string,
): Promise<void> => {
  const docInput: EverSignDocCreateManyInput[] = signersIds.map((e) => ({
    driver: {
      connect: {
        id: e,
      },
    },
    hash: documentHash,
  }));

  if (docInput.length > 0) {
    try {
      await apolloClient.mutate({
        mutation: EverSignDocCreateManyDocument,
        variables: {
          data: docInput,
        },
      });
    } catch (e) {
      console.log(e);
      snackbar.error('Error creating signers');
    }
  }
};

/**
 * Coverts a file to base64.
 *
 * @param {File} file - File.
 * @returns {string | ArrayBuffer | null} Result.
 */
const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line jsdoc/require-jsdoc
    reader.onload = () => resolve(reader.result);
    // eslint-disable-next-line jsdoc/require-jsdoc
    reader.onerror = (error) => reject(error);
  });

export type ExtendedResponse = {
  // embeddedClaimUrl is only available in archived documents.
  embeddedClaimUrl?: string;
} & Document.IObject &
  Document;

/**
 * Create a document using a file as an input.
 *
 * @param {CreateDocumentParams} params - Params.
 * @returns {Document} Document.
 */
export const createDocument = async (
  params: CreateDocumentParams,
): Promise<ExtendedResponse> => {
  const { documentTitle, file: inputFile, signers, isDraft = false } = params;

  const title = documentTitle || 'New Document';

  const document = new Document();

  document.setTitle(title);

  const signersIds: string[] = [];

  if (signers) {
    signers.forEach((e) => {
      const signer = new Signer();
      signer.setName(e.name);
      signer.setEmail(e.email);
      document.appendSigner(signer);
      if (e.driverId) signersIds.push(e.driverId);
    });
  }

  const base64File = (await toBase64(inputFile)) as string;
  const formattedResult = base64File.split(',')[1];
  const file = new EversignFile({
    name: 'file',
    fileBase64: formattedResult,
  });

  document.setIsDraft(isDraft);
  document.appendFile(file);

  const documentRes = await everSignClient.createDocument(document);
  const documentObj = documentRes.toObject();

  await createSigners(signersIds, documentObj.documentHash);

  return documentObj;
};

/**
 * Get document by hash.
 *
 * @param {string} hash - Document hash.
 * @returns {Object} - Document.
 */
export const getDocumentByHash = async (hash: string): Promise<ExtendedDoc> => {
  const result = await everSignClient.getDocumentByHash(hash);
  return result.toObject();
};

/**
 * Get document by hash.
 *
 * @param {string[]} hashes - Document hashes.
 * @returns {Object} - Document.
 */
export const getManyDocumentByHash = async (
  hashes: string[],
): Promise<ExtendedDoc[]> => {
  const promises = hashes.map((e) => everSignClient.getDocumentByHash(e));

  const result = await Promise.all(promises);
  const mapResult = result.map((e) => e.toObject());
  return mapResult;
};

/**
 * @param {CreateDocumentFromTemplateParams} data - Data.
 */
export const createDocumentFromTemplate = async ({
  documentTitle,
  signers,
  templateId,
}: CreateDocumentFromTemplateParams): Promise<void> => {
  const newTemplate = new Template();
  newTemplate.setTemplateId(templateId);
  newTemplate.setTitle(documentTitle);

  const signersIds: string[] = [];

  signers.forEach((e) => {
    // Added signers.
    const signer = new Signer();
    signer.setRole(e.role || '');
    signer.setName(e.name || '');
    signer.setEmail(e.email || '');

    // Append signer.
    newTemplate.appendSigner(signer);

    if (e.driverId) signersIds.push(e.driverId);
  });

  // Create the document.
  try {
    const document = await everSignClient.createDocumentFromTemplate(
      newTemplate,
    );
    await createSigners(signersIds, document.getDocumentHash());
    snackbar.success('Document created successfully');
  } catch (error) {
    snackbar.error('Error creating the document');
  }
};
