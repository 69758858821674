import * as React from 'react';
import NumberFormat from 'react-number-format';

interface TextFormatPhoneProps {
  onChange: (event: { target: { value: string } }) => void;
}

export const TextFormatPhone = React.forwardRef<
  NumberFormat,
  TextFormatPhoneProps
>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format="(###) ###-####"
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
});
