import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDayjs';
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Box } from '@mui/system';
import * as React from 'react';
import { CustomersListFilters } from '../customer-types';

/**
 * @description - Options menu component.
 * @param  props - Props for the component.
 * @param  props.anchorEl - Reference where show menu.
 * @param  props.open - Menu state.
 * @param  props.onClose - Function close menu.
 * @param  props.setFilters - Function delete button action.
 * @param  props.filters - Function delete button action.
 * @param  props.filters.from - Function delete button action.
 * @param  props.filters.to - Function delete button action.
 * @param  props.filters.search - Function delete button action.
 * @param  props.filters.status - Function delete button action.
 * @returns {JSX.Element} - Options menu component.
 */
export function ModalFilter({
  anchorEl,
  onClose,
  setFilters,
  filters,
  open,
}: {
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
  filters: {
    search?: string;
    from?: string;
    to?: string;
    status?: string;
  };
  setFilters: React.Dispatch<React.SetStateAction<CustomersListFilters>>;
}): JSX.Element {
  const [currentFilters, setCurrentFilters] = React.useState<{
    date: DateRange<Date>;
    status: string;
  }>({
    date: [null, null],
    status: '',
  });
  return (
    <Menu anchorEl={anchorEl} keepMounted={false} open={open} onClose={onClose}>
      <Box display="grid" padding="10px">
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>
          Creation Date
        </Typography>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DateRangePicker
            startText="From"
            endText="To"
            value={currentFilters.date}
            onChange={(newValue) => {
              setCurrentFilters({
                ...currentFilters,
                date: newValue,
              });
            }}
            renderInput={(startProps, endProps) => (
              <Box
                display="grid"
                gridTemplateColumns="40% 40%"
                justifyContent="space-between"
                sx={{ width: '300px' }}
              >
                <TextField {...startProps} />
                <TextField {...endProps} />
              </Box>
            )}
          />
        </LocalizationProvider>
        <Typography variant="subtitle2" sx={{ margin: '10px 0' }}>
          Status
        </Typography>
        <FormControl variant="outlined">
          <InputLabel>To Select</InputLabel>
          <Select
            label="To Select"
            name="status"
            value={currentFilters.status}
            onChange={(e) => {
              setCurrentFilters({
                ...currentFilters,
                [e.target.name]: e.target.value,
              });
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="disabled">Disabled</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="space-between" mt="10px">
          <Button
            variant="default"
            onClick={() => {
              setCurrentFilters({ date: [null, null], status: '' });
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setFilters({
                ...filters,
                status: currentFilters.status,
                from: currentFilters.date[0]?.toISOString(),
                to: currentFilters.date[1]?.toISOString(),
              });
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
