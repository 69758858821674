import dayjs from 'dayjs';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { updatedDiff } from 'deep-object-diff';
import { useForm } from 'react-hook-form';
import {
  CUSTOMER_FORM_VALIDATOR_SCHEMA,
  Address,
  CustomersListFilters,
  EditCustomerFormType,
} from './customer-types';
import {
  CreateCustomerMutationVariables,
  CustomerFilter,
  Customer_AddressCreateInput,
  Customer_AddressUpdateInput,
  UpdateCustomerMutationVariables,
  UpdateAddressMutationVariables,
  CustomerAddressUpdateRelationInput,
  CustomersListQueryVariables,
  CustomerDetailQuery,
  Case,
  CustomerCreateInput,
} from '../../shared/types/generated';
import { CustomerDriverFormType } from '../customer-driver/customer-driver-types';

/**
 * @returns Form hook state.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCustomerForm() {
  const data = useForm({
    resolver: yupResolver(CUSTOMER_FORM_VALIDATOR_SCHEMA, {}),
    defaultValues: {
      subscription: '',
      name: '',
      contactName: '',
      email: '',
      phoneNumber: '',
      customerAddressRelation: [
        {
          apartment: '',
          state: '',
          street: '',
          city: '',
          zipCode: '',
        },
      ],
    },
  });
  return data;
}

/**
 * @param {string} name - Name.
 * @param {string} contactName - Contact name.
 * @param {string} email - Email.
 * @param {string} phoneNumber - Phone Number.
 * @param {Array<Address>} addresses - Name Address.
 * @param {string} subscription - Subscription.
 * @returns {CreateCustomerMutationVariables} - Variables.
 */
export const createVariablesNewCustomer = (
  name: string,
  contactName: string,
  email: string,
  phoneNumber: string,
  addresses: Array<Address>,
  subscription: string,
): CreateCustomerMutationVariables => {
  const customerAddressesMapped: Customer_AddressCreateInput[] = addresses.map(
    (address) => ({
      ...address,
      state: {
        connect: {
          id: address.state,
        },
      },
      city: address.city,
    }),
  );

  const obj: CreateCustomerMutationVariables = {
    data: {
      name,
      contactName,
      email,
      phoneNumber,
      subscription,
      address: {
        create: customerAddressesMapped,
      },
    },
  };

  return obj;
};

/**
 * @param {string} name - Name.
 * @param {string} email - Email.
 * @param {string} phoneNumber - Phone Number.
 * @param {Array<Address>} addresses - Name Address.
 * @param {string} language - Language.
 * @param {string} subscription - Subscription.
 * @returns {CreateCustomerMutationVariables} - Variables.
 */
export const createVariablesNewCustomerDriver = (
  name: string,
  email: string,
  phoneNumber: string,
  addresses: Array<Address>,
  language: string,
  subscription: string,
): CreateCustomerMutationVariables => {
  const customerData = createVariablesNewCustomer(
    name,
    name,
    email,
    phoneNumber,
    addresses,
    subscription,
  );

  const data: CreateCustomerMutationVariables = {
    data: {
      ...customerData.data,
      // -- blocks the creation of additional drivers
      isCustomerDriver: true,
      // ----☝️
      phoneNumber,
      driverCustomerRelation: {
        create: [
          {
            email,
            name,
            phoneNumber: {
              number: phoneNumber,
              code: '1',
            },
            preferredLanguage: {
              connect: {
                id: language,
              },
            },
          },
        ],
      },
    },
  };

  return data;
};

/**
 * @param root0 - Params.
 * @param root0.name - Name.
 * @param root0.email - Email.
 * @param root0.lastName - Last name.
 * @param root0.phoneNumber - Phone number.
 * @param root0.dateOfBirth - Date of birth.
 * @param root0.language - Language.
 * @param root0.subscription - Subscription.
 * @param root0.customerAddressRelation - Customer address relation.
 * @param root0.license - License.
 * @returns {{data: CustomerCreateInput}} - Data.
 */
export const prepareTheCustomerDriverCreationData = ({
  name,
  email,
  lastName,
  phoneNumber,
  dateOfBirth,
  language,
  subscription,
  customerAddressRelation,
  license,
}: CustomerDriverFormType): { data: CustomerCreateInput } => {
  const inputs: CustomerCreateInput = {
    name,
    contactName: lastName,
    email,
    phoneNumber,
    subscription,
    isCustomerDriver: true,
    address: {
      create: customerAddressRelation?.map((address) => ({
        ...address,
        state: {
          connect: {
            id: address.state,
          },
        },
        city: address.city,
      })) as Customer_AddressCreateInput[],
    },
    // Driver
    driverCustomerRelation: {
      create: [
        {
          name,
          email,
          lastName,
          dateOfBirth,
          phoneNumber: {
            number: phoneNumber,
            code: '1',
          },
          preferredLanguage: {
            connect: {
              id: language,
            },
          },
          licenseNumber: license.number,
          licenseState: license.state,
        },
      ],
    },
  };

  return { data: inputs };
};

/**
 * @param filters - Input filter object.
 * @returns Filters mapped to 8base vars.
 */
export function createCustomerListFilter(
  filters: CustomersListFilters,
): CustomerFilter {
  const resultFilter: CustomerFilter[] = [];

  // By search
  if (filters.search && filters.search !== '') {
    resultFilter.push({
      OR: [
        {
          name: {
            contains: filters.search,
          },
        },
        {
          email: {
            contains: filters.search,
          },
        },
        {
          customerID: {
            contains: filters.search,
          },
        },
      ],
    });
  }

  // By from date
  if (filters.from && filters.from !== '') {
    resultFilter.push({
      createdAt: {
        gte: dayjs(filters.from).toISOString(),
      },
    });
  }

  // By to date
  if (filters.to && filters.to !== '') {
    resultFilter.push({
      createdAt: {
        lte: dayjs(filters.to).toISOString(),
      },
    });
  }

  // By status
  if (filters.status && filters.status !== 'all')
    resultFilter.push({
      status: {
        equals: filters.status,
      },
    });

  return { AND: resultFilter };
}
/**
 * @returns {CustomersListQueryVariables} - Variables.
 * @param {string} phoneNumber - Phone number.
 * @param {string} email - Email.
 * @param {string} name - Name.
 * @param {string} id - ID.
 */
export const createVariablesUpdateUser = (
  phoneNumber: string,
  email: string,
  name: string,
  id: string,
): UpdateAddressMutationVariables => {
  const obj = {
    data: {
      phoneNumber: [
        {
          set: phoneNumber,
        },
      ],
      email: [
        {
          set: email,
        },
      ],
      name: [
        {
          set: name,
        },
      ],
    },
    filter: {
      id: {
        equals: id,
      },
    },
  };

  return obj;
};
/**
 * @returns {Array<Customer_AddressCreateInput>} - Variables.
 * @param {EditCustomerFormType} form - New Data.
 */
const createAddressUpdateCostumer = (
  form: EditCustomerFormType,
): Array<Customer_AddressCreateInput> => {
  const data: Array<Customer_AddressCreateInput> = [];
  form.customerAddressRelation.forEach((item) => {
    if (item.id === '' || item.id === undefined) {
      const obj = {
        ...item,
        state: {
          connect: {
            id: item.state,
          },
        },
        city: item.city,
      };
      data.push(obj);
    }
  });
  return data;
};
/**
 * @returns {Array<string>} - Variables.
 * @param {CustomerDetailQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 */
const deletedElements = (
  oldData: CustomerDetailQuery,
  newData: EditCustomerFormType,
): Array<string> => {
  const arr: Array<string> = [];
  oldData.customer.address.items.forEach((item) => {
    let deleted = true;
    for (
      let index = 0;
      index < newData.customerAddressRelation.length;
      index += 1
    ) {
      if (item.id === newData.customerAddressRelation[index].id) {
        deleted = false;
        break;
      }
    }
    if (deleted === true) {
      arr.push(item.id);
    }
  });
  return arr;
};
/**
 * @returns {Array<Customer_AddressUpdateInput> | boolean} - Variables.
 * @param {CustomerDetailQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 */
const updateAddressUpdateAddress = (
  oldData: CustomerDetailQuery,
  newData: EditCustomerFormType,
): Array<Customer_AddressUpdateInput> => {
  // Truly addresses to update
  const update: Array<Customer_AddressUpdateInput> = [];

  // This map is necessary to compare the data.
  // Which addresses has been changed.
  const olderAddresses = oldData.customer.address.items.map((item) => ({
    apartment: item.apartment,
    city: item.city,
    id: item.id,
    state: item.state.id,
    street: item.street,
    zipCode: item.zipCode,
  }));

  // Map the new data that really has been changed.
  const newDataMapped = newData.customerAddressRelation.filter(
    (address, index) =>
      Object.keys(updatedDiff(olderAddresses[index], address)).length,
  );

  // This is to prevent updating addresses that haven't really changed
  newDataMapped.forEach((item) => {
    if (item.id !== '' && item.id !== undefined) {
      const obj = {
        filter: {
          id: item?.id,
        },
        data: {
          city: item.city,
          state: {
            connect: {
              id: item.state,
            },
          },
          zipCode: item.zipCode,
          apartment: item.apartment,
          street: item.street,
        },
      };
      update.push(obj);
    }
  });

  const deleted = deletedElements(oldData, newData);
  if (deleted.length > 0) {
    deleted.forEach((element) => {
      const obj: Customer_AddressUpdateInput = {
        data: {
          archived: dayjs(new Date()).toISOString(),
        },
        filter: {
          id: element,
        },
      };
      update.push(obj);
    });
  }

  return update;
};
/**
 * @returns {UpdateAddressMutationVariables} - Variables.
 * @param {string} id - Id.
 * @param {CustomerDetailQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 */
export const createVariablesUpdateCustomer = (
  id: string,
  oldData: CustomerDetailQuery,
  newData: EditCustomerFormType,
): UpdateCustomerMutationVariables => {
  const address: CustomerAddressUpdateRelationInput = {};
  const create = createAddressUpdateCostumer(newData);
  const update = updateAddressUpdateAddress(oldData, newData);

  if (create.length > 0) {
    address.create = create;
  }

  if (update.length > 0) {
    address.update = update;
  }

  const variables: UpdateCustomerMutationVariables = {
    filter: {
      id,
    },
    data: {
      name: newData.name,
      contactName: newData.contactName,
      email: newData.email,
      phoneNumber: newData.phoneNumber,
      address,
      subscription: newData.subscription,
    },
  };
  return variables;
};
type Drivers =
  CustomerDetailQuery['customer']['driverCustomerRelation']['items'];
/**
 * @returns {Array<Case>} - Variables.
 * @param {Drivers} list - Id.
 */
export const createCaseList = (list: Drivers): Array<Case> => {
  const CaseList: Array<Case> = [];
  list.forEach((item) => {
    item.caseDriverRelation?.items.forEach((c: Case) => {
      CaseList.push(c);
    });
  });
  return CaseList;
};
