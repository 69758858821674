import { capitalCase } from 'change-case';

/**
 * @param str - Str.
 * @returns {string} - Capitalized string with symbols.
 */
function capitalizeWithSymbols(str: string): string {
  const words = str.split(' ');
  const capitalizedWords = words.map((word) =>
    word.includes('(') || word.includes(')') ? word : capitalCase(word),
  );
  const finalStr = capitalizedWords.join(' ');
  return finalStr;
}

export const customerSubscriptions = {
  pro: 'pro',
  proLegacy: 'pro (legacy)',
  premium: 'premium',
  elite: 'elite',
} as const;

export const customerSubscriptionsSelectOptions = Object.values(
  customerSubscriptions,
).map((e) => ({
  value: e,
  label: capitalizeWithSymbols(e),
}));

export const customerDriverSubs = {
  basic: 'basic',
  proDriver: 'pro driver',
} as const;
export const customerDriverSubsSelectOptions = Object.values(
  customerDriverSubs,
).map((e) => ({
  value: e,
  label: capitalCase(e),
}));
