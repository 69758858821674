import { UseFormProps, UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CUSTOMER_DRIVER_FORM_VALIDATOR_SCHEMA,
  CustomerDriverFormType,
} from './customer-driver-types';
import { customerDriverSubs } from '../customer/customer-models';

/**
 * @param {UseFormProps<CustomerDriverFormType>} formOptions - Form options.
 * @returns {UseFormReturn<CustomerDriverFormType>} Form.
 */
export function useCustomerDriverForm(
  formOptions?: UseFormProps<CustomerDriverFormType>,
): UseFormReturn<CustomerDriverFormType> {
  const data = useForm<CustomerDriverFormType>({
    ...formOptions,
    resolver: yupResolver(CUSTOMER_DRIVER_FORM_VALIDATOR_SCHEMA, {}),
    defaultValues: {
      subscription: customerDriverSubs.basic,
      name: '',
      language: '',
      email: '',
      phoneNumber: '',
      unsupportedStateSelected: false,
      requestMVR: false,
      customerAddressRelation: [
        {
          apartment: '',
          state: '',
          street: '',
          city: '',
          zipCode: '',
        },
      ],
    },
  });
  return data;
}
