import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCustomerDriverForm } from './customer-driver-hooks';

/**
 * @param root0 - Props.
 * @param root0.children - Children.
 * @returns {JSX.Element} - Provider component.
 */
export const CustomerDriverFormProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const methods = useCustomerDriverForm({ mode: 'all' });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
