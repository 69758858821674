import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Document } from 'eversign-enhance';
import { printTheDocumentStatus } from '../document-utils';
import { READABLE_DOC_STATUS } from '../document-types';
import { Pill, PillsTypeProp } from '../../../shared/components/Pills';

/**
 * A chip component that displays the status of a document.
 *
 * @param {object} props - The component's properties.
 * @param {Document} props.doc - The document to display the status for.
 * @returns {JSX.Element} - The rendered component.
 */
export const DocumentStatusPill: React.FC<{ doc: Document }> = ({ doc }) => {
  const [type, setType] = useState<PillsTypeProp>(0);

  useEffect(() => {
    // Set the color based on the document status
    const status = printTheDocumentStatus(doc);
    switch (status) {
      case READABLE_DOC_STATUS.IN_PROGRESS:
        setType(1);
        break;
      case READABLE_DOC_STATUS.COMPLETED:
        setType(4);
        break;
      case READABLE_DOC_STATUS.DRAFT:
        setType(0);
        break;
      case READABLE_DOC_STATUS.I_NEED_TO_SIGN:
        setType(5);
        break;
      default:
        // Cancelled
        setType(6);
        break;
    }
  }, [doc]);

  return (
    <Box alignItems="center" display="flex" textAlign="center">
      <Pill
        size="small"
        type={type}
        text={printTheDocumentStatus(doc).toUpperCase()}
      />
    </Box>
  );
};
